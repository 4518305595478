const prefix = "fac"
const iconName = "Hyper-V-Host"
const width = 200
const height = 200
const ligatures = []
const unicode = "019"
const svgPathData =
  "M71.37,13.62,2.51,23.09V80H71.37Zm-13.1,53.3H15.61V34.51l42.66-5.86Z M197.49,112.57v-80l-26.94,3.72V0L79.23,12.61V80h27v8.24H79.23v32.56H71.37V88.25H2.51v56.1l26.94,3.7v28.86l68.87,9.47V157.46l7.86,1.09v28.84L197.49,200V120.81H170.55v-8.24ZM92.33,24l65.12-9V66.92H92.33Zm26.94,56h51.28V49.5l13.84-1.91V99.48H170.55V88.25H119.27ZM92.33,101.35h65.12v51.06l-65.12-9Zm-76.72,0H58.27v37.44l-42.66-5.86Zm69.61,70-42.67-5.86V149.85l28.82,4V133.91h7.86v20.92l6,.82Zm99.17-37.44V185l-65.12-9V160.36l51.28,7.08V133.91Z"

const faHyperVHost = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export default faHyperVHost
