import $ from "jquery"
import _ from "underscore"
import { setupDataTableClickEvents, destroyDataTableClickEvents } from "../../../includes/common/_data_table"

const scrolltablebody = {
  routine: function(el, value) {
    _.defer(function() {
      if (value && $(el).parent().length) {
        $(el).scrollTableBody()
        setupDataTableClickEvents($(el))
      }
    })
  },

  unbind: function(el) {
    $(el).off()
    $(el).scrollTableBody("destroy")
    destroyDataTableClickEvents($(el))
    return true
  },
}

export default scrolltablebody
