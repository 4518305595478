import { groupBy } from "ramda"

export const splitActiveAndInactive = groupBy(item => (item.active ? "active" : "inactive"))

export const ticketingConfigurationSegments = {
  general: "general",
  inboundEmailAddresses: "inboundEmailAddresses",
  allowlist: "allowlist",
  blocklist: "blocklist",
  boards: "boards",
  activeFields: "activeFields",
  inactiveFields: "inactiveFields",
  activeForms: "activeForms",
  inactiveForms: "inactiveForms",
  tags: "tags",
  activityRules: "activityRules",
  conditionRules: "conditionRules",
  scriptRules: "scriptRules",
  eventBased: "eventBased",
  timeBased: "timeBased",
  responseTemplates: "responseTemplates",
  customStatus: "customStatus",
}
