import { Range } from "slate"
import { LIST_TYPES, toggleBlock } from "../utils"

export const withDeleteEmptyBlock = editor => {
  const { deleteBackward } = editor

  editor.deleteBackward = unit => {
    const { selection } = editor

    if (selection && selection.focus.offset === 0 && selection.anchor.offset === 0 && Range.isCollapsed(selection)) {
      const block = editor.children[selection.anchor.path[0]]

      if (LIST_TYPES.includes(block?.type) && block.children.length === 1) {
        toggleBlock(editor, block?.type)
      }

      deleteBackward(unit)
    } else {
      deleteBackward(unit)
    }
  }

  return editor
}
