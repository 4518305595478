import React from "react"
import { connect } from "react-redux"
import { localizationKey, localized } from "js/includes/common/utils"
import { getSelectedFilteredDevicesIds } from "js/state/selectors/deviceSearch/devices"
import { disableMaintenanceOnDevices } from "js/state/actions/deviceSearch/maintenance"

const token = localizationKey("Disable")

const DisableMaintenanceButton = ({
  nodesIds,
  selectedFilteredDevicesIds,
  deviceName,
  disableMaintenanceOnDevices,
  fetchDevicesInMaintenance,
  ButtonComponent,
  fromDeviceSearch,
  activeSearch,
}) => {
  const handleDisable = async () => {
    const deviceIds = !!nodesIds ? nodesIds : selectedFilteredDevicesIds
    await disableMaintenanceOnDevices({
      deviceIds,
      deviceName,
      fetchDevicesInMaintenance,
      fromDeviceSearch,
      activeSearch,
    })
  }

  return ButtonComponent ? (
    <ButtonComponent {...{ onClick: handleDisable, token }} />
  ) : (
    <button className="btn-link" onClick={handleDisable}>
      {localized(token)}
    </button>
  )
}

export default connect(
  ({ deviceSearch }) => ({
    activeSearch: deviceSearch,
    selectedFilteredDevicesIds: getSelectedFilteredDevicesIds(deviceSearch),
  }),
  {
    disableMaintenanceOnDevices,
  },
)(DisableMaintenanceButton)
