import rivets from "rivets"

// ak: Fix for issue #696 - ability to use 'call' even when 'executeFunctions' is enabled
rivets._.Binding.prototype.set = function(value) {
  var _ref1
  if (value instanceof Function && !this.binder["function"] && rivets.executeFunctions) {
    value =
      this.formatters.length && this.formatters[0].indexOf("call") === 0
        ? this.formattedValue(value)
        : this.formattedValue(value.call(this.model))
  } else {
    value = this.formattedValue(value)
  }
  return (_ref1 = this.binder.routine) != null ? _ref1.call(this, this.el, value) : void 0
}

rivets._.View.prototype.buildBinding = function(binding, node, type, declaration) {
  var context, ctx, dependencies, keypath, options, pipe, pipes
  options = {}
  pipes = (function() {
    var _i, _len, _ref1, _results
    // eslint-disable-next-line no-useless-escape
    _ref1 = declaration.match(/((?:'[^']*')*(?:(?:[^\|']*(?:'[^']*')+[^\|']*)+|[^\|]+))|^$/g)
    _results = []
    for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
      pipe = _ref1[_i]
      _results.push(pipe.trim())
    }
    return _results
  })()
  context = (function() {
    var _i, _len, _ref1, _results
    // ryan: Fix for issue #695 - change observer '<' to '::' to be html friendly
    _ref1 = pipes.shift().split("::")
    _results = []
    for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
      ctx = _ref1[_i]
      _results.push(ctx.trim())
    }
    return _results
  })()
  keypath = context.shift()
  options.formatters = pipes
  // eslint-disable-next-line no-cond-assign
  if ((dependencies = context.shift())) {
    options.dependencies = dependencies.split(/\s+/)
  }
  return this.bindings.push(new rivets._[binding](this, node, type, keypath, options))
}

rivets.binders.if.unbind = function() {
  if (this.nested) {
    this.nested.unbind()
    // ak: Fix for issue #622 - remove el from DOM if it was bound
    if (this.bound && this.el?.parentNode?.contains(this.el)) {
      this.el.parentNode.removeChild(this.el)
    }
    return (this.bound = false)
  }
}
