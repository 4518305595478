const hour12Regex = new RegExp("^(1[0-2]|[1-9])$")
const hour24Regex = new RegExp("^(0?[0-9]|1[0-9]|2[0-3])$")
const minuteRegex = new RegExp("^[0-5]?[0-9]$")

export const getDisplayTime = value => {
  return value < 10 ? value.toString().padStart(2, "0") : value.toString()
}

export const getDisplayHour = ({ hour, format }) => {
  const remainder = hour % format
  if (format === 24) return getDisplayTime(hour < 0 ? 23 : remainder)
  return getDisplayTime(remainder < 1 ? 12 : remainder)
}

export const getTimeString = ({ hour, minute, ampm, format, hourOnly }) => {
  const paddedMinute = getDisplayTime(minute)
  const paddedHour = getDisplayHour({ hour, format })
  const timePart = hourOnly ? paddedHour : `${paddedHour}:${paddedMinute}`
  const ampmPart = format === 12 ? ` ${ampm}` : ""

  return `${timePart}${ampmPart}`
}

export const setTimeChangeByTypeAndUnit = ({ initialDay, time, type, unit }) => {
  if (unit === "hour" && type === "increment") time.setHours(time.getHours() + 1)
  if (unit === "hour" && type === "decrement") time.setHours(time.getHours() - 1)
  if (unit === "minute" && type === "increment") time.setMinutes(time.getMinutes() + 1)
  if (unit === "minute" && type === "decrement") time.setMinutes(time.getMinutes() - 1)
  if (time.getDate() !== initialDay) time.setDate(initialDay)
}

export const isTimeValid = ({ format, unit, value }) => {
  const parsedValue = parseInt(value)
  if (unit === "minute") return minuteRegex.test(parsedValue)
  if (unit === "hour" && format === 24) return hour24Regex.test(parsedValue)
  if (unit === "hour") return hour12Regex.test(parsedValue)
}

export const getLocaleTimeFormat = locale => {
  const formatter = new Intl.DateTimeFormat(locale, { hour: "numeric" })
  const options = formatter.resolvedOptions()
  return options.hourCycle.includes("h23") ? 24 : 12
}

export const getTimezone = () => {
  return Intl.DateTimeFormat(undefined, { timeZoneName: "short" })
    .formatToParts()
    .find(p => p.type === "timeZoneName").value
}
