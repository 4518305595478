import _ from "underscore"
import Backbone from "backbone"

//Global structures for debug only. Uncomment if needed
//var allocs = [];
//var deallocs = [];
//var allocCounter = 0;
const ModelBase = Backbone.RelationalModel.extend({
  initialize: function() {
    // Call the super constructor
    Backbone.RelationalModel.prototype.initialize.call(this)

    //For debug only, uncomment if necessary:
    //allocs.push(this.defaults.classname);
    //allocCounter++;
    //console.log('allocations: ' + allocCounter);
    //console.log('alloc: ' + this.defaults.classname);

    //Use the string below to test models memory leaks
    //this.set('heavyLoad_for_' + this.defaults.classname, this.defaults.classname + new Array(10000).join('x'));//allocating ~0.01 mb of memory
  },
  deallocate: function() {
    if (this.get("preventDeallocation") === true) {
      return
    }

    // For debugging only, uncomment if necessary:
    // try{
    //     console.log('deallocate started: ' + this.defaults.classname);
    // } catch(e){
    //     console.log('deallocate started for unknown class');
    // }

    // Tidy up occupied resources by all related models
    for (var i = 0; i < this.relations.length; i++) {
      var relation = this.relations[i]
      // If this is not a reverse relationship
      if (!relation.isAutoRelation) {
        var model = this.get(relation.key)
        if (model) {
          if (model instanceof Backbone.Collection) {
            for (var j = model.models.length - 1; j >= 0; j--) {
              var subModel = model.models[j]
              if (subModel instanceof ModelBase) {
                subModel.deallocate()
              }
            }
          } else if (model instanceof ModelBase) {
            model.deallocate()
          }
        }
      }
    }

    this.stopListening()

    Backbone.Relational.store.unregister(this)

    //For debug only, uncomment if necessary:
    //allocCounter--;
    //console.log('allocations: ' + allocCounter);
    //deallocs.push(this.defaults.classname);

    // try{
    //     console.log('deallocate ended: ' + this.defaults.classname);
    // } catch(e){
    //     console.log('deallocate ended for unknown class');
    // }
  },
  set: function(key, value, preventDeallocation) {
    // If the model is not explicitly set to prevent deallocation, deallocate memory for any existing values
    // eg. SetupPageModel.js for cloud monitors uses the openedPage value to keep track of the current page.
    // We can't quite destroy the page being unset in this action.
    if (preventDeallocation !== true) {
      var existing = this.get(key)
      if (existing && existing instanceof ModelBase) {
        // Ignore deallocation if we're re-setting the same model
        if (!value || existing.cid !== value.cid) {
          existing.deallocate()
        }
      }
    }

    // Set the value
    Backbone.RelationalModel.prototype.set.call(this, key, value)
  },
  // Currently not in use but left here for future use
  // Used in cases of array type child
  update: function(object) {
    for (var i = 0; i < this.relations.length; i++) {
      var relation = this.relations[i]
      if (!relation.isAutoRelation) {
        var currentModel = this.get(relation.key)
        var arr = object[relation.key]
        var model
        // eslint-disable-next-line
        if (arr != undefined) {
          for (let j = 0; j < currentModel.models.length; j++) {
            model = currentModel.models[j]
            var found = false
            // eslint-disable-next-line
            _.each(arr, function(element) {
              if (_.isEqual(model.toJSON(), element)) {
                found = true
                element.found = true
              }
            })
            if (!found) {
              currentModel.models[j--].destroy()
            }
          }

          // eslint-disable-next-line
          arr.forEach(function(element) {
            if (!element.found) {
              delete element.found
              model = new ModelBase(element)
              currentModel.add(model)
            }
          })
        }
      }
    }
  },
})

export default ModelBase
