import { localizationKey } from "js/includes/common/utils"

export const statusKeyMapper = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  DELETED: "DELETED",
  SYNCING: "SYNCING",
  PENDING_SYNC: "PENDING_SYNC",
  IN_QUEUE: "IN_QUEUE",
}

export const clientSyncStatusTokenMapper = {
  ACTIVE: localizationKey("Active"),
  SYNCING: localizationKey("Syncing"),
  PENDING_SYNC: localizationKey("Pending Sync"),
  IN_QUEUE: localizationKey("In Queue"),
}

export const incidentTemplateStatusTokenMapper = {
  ACTIVE: localizationKey("Active"),
  INACTIVE: localizationKey("Inactive in ServiceNow"),
  DELETED: localizationKey("Deleted in ServiceNow"),
}

export const statusTokenMapper = {
  ...clientSyncStatusTokenMapper,
  ...incidentTemplateStatusTokenMapper,
}
