import { useState, useCallback } from "react"
import { mapObjIndexed, has, path as _path, assocPath, assoc } from "ramda"
import { isNotNilOrEmpty } from "../utils"

const _normalize = value => value
const _validate = value => ({
  success: true,
  message: "",
})

const validateFields = ({ fields, values, validate, validation, validationData }) => {
  if (Array.isArray(fields)) {
    let validFieldsCount = 0
    let success = {}
    let message = {}

    const validator = name => {
      const path = isNotNilOrEmpty(name) ? name.toString().split(".") : []

      if (path.length > 1) {
        const nestedValue = _path(path, values)
        const validationFn = _path(path, validate)
        const validated =
          typeof validationFn === "function"
            ? validationFn(nestedValue, values, validationData)
            : _validate(nestedValue)

        success = assocPath(path, _path(path, validation?.success), { ...validation?.success, ...success })
        message = assocPath(path, _path(path, validation?.message), { ...validation?.message, ...message })

        success = assocPath(path, validated.success, success)
        message = assocPath(path, validated.message, message)

        validated.success && validFieldsCount++
      } else {
        // TODO: refactor to do the same from line 51 to line 92
        const value = values[name]
        const validated =
          typeof validate[name] === "function" ? validate[name](value, values, validationData) : _validate(value)
        success[name] = validated.success
        message[name] = validated.message
        validated.success && validFieldsCount++
      }
    }

    fields.forEach(validator)

    const validated = fields.length === validFieldsCount
    return { success, message, validated }
  } else {
    let validated = true

    function validateObj(validations, values, source) {
      const result = {}
      for (let key in values) {
        const transformation = validations[key]
        const type = typeof transformation
        const value = values[key]
        if (type === "function") {
          result[key] = transformation(value, source ?? values)
        } else if (type === "object") {
          result[key] = validateObj(transformation, value, source ?? values)
        } else {
          if (value && typeof value === "object" && !Array.isArray(value) && Object.keys(value).length) {
            result[key] = validateObj(_validate, value, source ?? values)
          } else {
            result[key] = _validate(value)
          }
        }
      }
      return result
    }

    function pickValidationObjProp({ targetProp, defaultValue, obj }) {
      const extractTargetValues = mapObjIndexed((value, key) => {
        if (has("success", value) || has("message", value)) {
          if (targetProp === "success" && value[targetProp] === false) {
            validated = false
          }
          return value[targetProp] ?? defaultValue
        } else {
          return extractTargetValues(value)
        }
      })
      return extractTargetValues(obj)
    }

    const validatedObj = validateObj(validate, values)
    const success = pickValidationObjProp({ targetProp: "success", defaultValue: true, obj: validatedObj })
    const message = pickValidationObjProp({ targetProp: "message", defaultValue: "", obj: validatedObj })

    return { success, message, validated }
  }
}

const initializeForm = form => ({
  values: form.fields || {},
  validation: {
    success: {},
    message: {},
  },
  validate: form.validate || {},
  normalize: form.normalize || {},
  validateOnChange: form.validateOnChange || false,
})

export const useForm = (form = {}) => {
  const [{ values, validation, validate }, setState] = useState(initializeForm(form))

  const handleSingleChange = useCallback((name, value) => {
    return setState(state => {
      const { values, validation, validate, normalize, validateOnChange } = state

      const path = name.split(".")

      if (path.length > 1) {
        const normalizedFn = _path(path, normalize)
        const normalized = typeof normalizedFn === "function" ? normalizedFn(value, values) : _normalize(value)
        const validationSuccess = _path(path, validation.success)
        const validationFn = _path(path, validate)
        const validated = typeof validationFn === "function" ? validationFn(value, values) : _validate(value)
        const shouldValidate = validateOnChange || validationSuccess !== undefined

        return {
          ...state,
          values: assocPath(path, normalized, values),
          validation: shouldValidate
            ? {
                success: assocPath(path, validated.success, validation.success),
                message: assocPath(path, validated.message, validation.message),
              }
            : validation,
        }
      } else {
        const normalized = typeof normalize[name] === "function" ? normalize[name](value, values) : _normalize(value)
        const validated =
          typeof validate[name] === "function" ? validate[name](normalized, values) : _validate(normalized)
        const shouldValidate = validateOnChange || validation.success[name] !== undefined

        return {
          ...state,
          values: assoc(name, normalized, values),
          validation: shouldValidate
            ? {
                success: assoc(name, validated.success, validation.success),
                message: assoc(name, validated.message, validation.message),
              }
            : validation,
        }
      }
    })
  }, [])

  const handleMultipleChange = useCallback((_values = {}) => {
    return setState(state => {
      const { values, validation, validate, normalize, validateOnChange } = state
      let newValues = {}
      const newValidation = {
        success: {},
        message: {},
      }

      for (let name in _values) {
        const path = name.split(".")
        const value = _values[name]

        if (path.length > 1) {
          const normalizedFn = _path(path, normalize)
          const normalized = typeof normalizedFn === "function" ? normalizedFn(value, values) : _normalize(value)
          const validationSuccess = _path(path, validation.success)
          const validationFn = _path(path, validate)
          const shouldValidate = validateOnChange || validationSuccess !== undefined

          newValues = assocPath(path, normalized, newValues)

          if (shouldValidate) {
            const validated = typeof validationFn === "function" ? validationFn(value, values) : _validate(value)

            newValidation.success = assoc(name, validated.success, newValidation.success)
            newValidation.message = assoc(name, validated.message, newValidation.message)
          }
        } else {
          const shouldValidate = validateOnChange || validation.success[name] !== undefined
          const normalized = typeof normalize[name] === "function" ? normalize[name](value, values) : _normalize(value)

          newValues[name] = normalized

          if (shouldValidate) {
            const validated =
              typeof validate[name] === "function" ? validate[name](normalized, values) : _validate(normalized)

            newValidation.success[name] = validated.success
            newValidation.message[name] = validated.message
          }
        }
      }

      return {
        ...state,
        values: {
          ...values,
          ...newValues,
        },
        validation: {
          success: {
            ...validation.success,
            ...newValidation.success,
          },
          message: {
            ...validation.message,
            ...newValidation.message,
          },
        },
      }
    })
  }, [])

  const validateFormFields = useCallback((specificFieldNames, validationData) => {
    setState(state => {
      const { validate, validation } = state
      const fields = specificFieldNames || validate
      const { success, message } = validateFields({ ...state, fields, validationData })

      return {
        ...state,
        validation: {
          success: {
            ...validation.success,
            ...success,
          },
          message: {
            ...validation.message,
            ...message,
          },
        },
      }
    })
  }, [])

  const validateForm = useCallback(
    (specificFieldNames, validationData) => {
      const fields = specificFieldNames || validate
      const { success, message, validated } = validateFields({ fields, values, validate, validation, validationData })

      setState(state => {
        return {
          ...state,
          validation: {
            success: {
              ...state.validation.success,
              ...success,
            },
            message: {
              ...state.validation.message,
              ...message,
            },
          },
        }
      })

      return validated
    },
    [values, validate, validation],
  )

  const onChange = useCallback(
    function() {
      const type = typeof arguments[0]

      switch (type) {
        case "string":
          return handleSingleChange(arguments[0], arguments[1])
        case "object":
          if (arguments[0].target instanceof Node) {
            const { type, name, value, files, checked } = arguments[0].target
            switch (type) {
              case "checkbox":
                return handleSingleChange(name, checked)
              case "file":
                return handleSingleChange(name, files)
              default:
                return handleSingleChange(name, value)
            }
          }
          return handleMultipleChange(arguments[0])
        default:
          return
      }
    },
    [handleSingleChange, handleMultipleChange],
  )

  const updateValidate = useCallback(validate => {
    if (typeof validate === "function") {
      return setState(state => ({ ...state, validate: validate(state.validate) }))
    }

    return setState(state => ({ ...state, validate }))
  }, [])

  const updateNormalize = useCallback(normalize => {
    if (typeof normalize === "function") {
      return setState(state => ({ ...state, normalize: normalize(state.normalize) }))
    }

    return setState(state => ({ ...state, normalize }))
  }, [])

  const resetForm = (optionalNewFormData = {}) => {
    setState(initializeForm({ ...form, ...optionalNewFormData }))
  }

  return {
    values,
    validate,
    validation,
    validateForm,
    validateFormFields,
    resetForm,
    onChange,
    updateValidate,
    updateNormalize,
  }
}
