import { Text, WizardBreadcrumb, Tooltip } from "@ninjaone/components"
import styled from "@emotion/styled"
import { Flex } from "js/includes/components/Styled"
import showModal from "js/includes/common/services/showModal"
import { localized, localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import { useNetworkDiscovery } from "./NetworkDiscoveryContext"
import { StopDiscoveryModal } from "./modals/StopDiscoveryModal"
import { steps } from "./utils"

const StyledFlex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};
`

export const DiscoveryDescription = () => {
  const {
    currentIndex,
    setCurrentIndex,
    values: { networkProbe, org },
    loading,
    stopDiscovery,
    discoveryComplete,
    devicesForReview,
    delegateVersion,
  } = useNetworkDiscovery()
  const currentStep = steps[currentIndex]
  return (
    <StyledFlex justifyContent="space-between">
      <WizardBreadcrumb
        currentIndex={currentIndex}
        marginBottom="12px"
        handleClick={({ historyIndex }) => {
          if (currentStep === "DISCOVERY" && loading) {
            showModal(
              <StopDiscoveryModal
                stopDiscovery={stopDiscovery}
                goToDiscovery={() => setCurrentIndex(0)}
                messageToken={localizationKey("Navigating back to Setup will stop the current discovery process.")}
              />,
            )
          } else {
            setCurrentIndex(historyIndex)
          }
        }}
        history={[
          { name: "Set up", path: "/setup" },
          { name: "Run discovery", path: "/discovery", disabled: !discoveryComplete },
          { name: "Review", path: "/review", disabled: !devicesForReview.length },
        ]}
        width="400px"
      />
      {currentStep !== "SETUP" && (
        <Text size="sm" color="colorTextWeakest">
          <Tooltip label={`${localized("Delegate version")} ${delegateVersion}`}>{networkProbe.nodeName}</Tooltip> -{" "}
          {org.name}
        </Text>
      )}
    </StyledFlex>
  )
}
