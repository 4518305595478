import { Text, ButtonGroup } from "@ninjaone/components"
import { ArrowRightIcon, ArrowLeftIcon, CheckIcon } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import { localized, localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import showModal from "js/includes/common/services/showModal"
import { Flex } from "js/includes/components/Styled"
import Loading from "js/includes/components/Loading"
import { useNetworkDiscovery } from "./NetworkDiscoveryContext"
import { AddDevicesConfirmationModal } from "./modals/AddDevicesConfirmationModal"
import { StopDiscoveryModal } from ".//modals/StopDiscoveryModal"
import { steps } from "./utils"

export const DiscoveryButtons = () => {
  const {
    loading,
    devicesForReview,
    status,
    startDelegateInstallation,
    currentIndex,
    setCurrentIndex,
    discoveryComplete,
    setDiscoveryComplete,
    stopDiscovery,
    validateForm,
    addDiscoveryDevices,
    setSelectedDevicesError,
    values,
    validateReviewPage,
  } = useNetworkDiscovery()

  switch (steps[currentIndex]) {
    case "REVIEW":
      const { org, location } = values
      return (
        <ButtonGroup
          buttons={[
            {
              variant: "secondary",
              labelToken: localizationKey("Back"),
              onClick: () => setCurrentIndex(1),
              Icon: () => <ArrowLeftIcon />,
            },
            {
              variant: "primary",
              labelToken: localizationKey("Finish"),
              onClick: () => {
                const hasErrors = validateReviewPage(devicesForReview)
                if (!hasErrors) {
                  showModal(
                    <AddDevicesConfirmationModal
                      onConfirm={addDiscoveryDevices}
                      devicesForReview={devicesForReview}
                      loading={loading}
                      org={org}
                      location={location}
                    />,
                  )
                }
              },
            },
          ]}
        />
      )
    case "DISCOVERY":
      return (
        <Flex alignItems="center">
          {discoveryComplete && (
            <Text size="sm" color="colorTextStrong">
              <Flex alignItems="center" gap={sizer(1)}>
                <CheckIcon color="colorAlertSuccess" />
                {localized("Discovery completed")}
              </Flex>
            </Text>
          )}
          <ButtonGroup
            buttons={[
              ...(loading
                ? [
                    {
                      variant: "tertiary",
                      labelToken: localizationKey("Stop discovery"),
                      onClick: () => {
                        showModal(
                          <StopDiscoveryModal
                            stopDiscovery={() => {
                              stopDiscovery()
                              setDiscoveryComplete(true)
                            }}
                            messageToken={localizationKey("Are you sure you want to stop network discovery?")}
                          />,
                        )
                      },
                    },
                  ]
                : []),
              {
                variant: "secondary",
                labelToken: localizationKey("Back"),
                Icon: () => <ArrowLeftIcon />,
                onClick: () =>
                  loading
                    ? showModal(
                        <StopDiscoveryModal
                          stopDiscovery={stopDiscovery}
                          goToDiscovery={() => setCurrentIndex(0)}
                          messageToken={localizationKey(
                            "Navigating back to Setup will stop the current discovery process.",
                          )}
                        />,
                      )
                    : setCurrentIndex(0),
              },
              {
                variant: "primary",
                labelToken: localizationKey("Review"),
                onClick: () => {
                  if (devicesForReview.length) {
                    setCurrentIndex(2)
                  } else {
                    setSelectedDevicesError(true)
                  }
                },
                Icon: () => <ArrowRightIcon />,
                iconPlacement: "right",
                disabled: loading,
              },
            ]}
          />
        </Flex>
      )
    default:
      return (
        <Flex alignItems="center">
          {loading && (
            <Loading
              loadingText={status}
              fontSize={getTextSize("sm")}
              color="colorTextStrong"
              spinnerColor="colorTextAction"
              withExtraSpace
            />
          )}
          <ButtonGroup
            buttons={[
              {
                onClick: () => (discoveryComplete && validateForm() ? setCurrentIndex(1) : startDelegateInstallation()),
                variant: "primary",
                labelToken: discoveryComplete
                  ? localizationKey("View discovery results")
                  : localizationKey("Run discovery"),
                Icon: () => <ArrowRightIcon />,
                iconPlacement: "right",
                disabled: loading,
              },
            ]}
          />
        </Flex>
      )
  }
}
