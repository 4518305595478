import { faFileCheck } from "@fortawesome/pro-light-svg-icons"
import { Modal, Text } from "@ninjaone/components"
import { getTextSize, sizer } from "@ninjaone/utils"
import { Box, Flex, StyledFontAwesomeIcon, StyledSpan } from "js/includes/components/Styled"
import { getReadableBytes } from "js/includes/common/_conversions"
import InstallerButtonRow from "js/includes/components/DeviceModal/InstallerButtonRow"

const LinkCard = ({ url, installerSize }) => {
  const formattedName = url?.split(/\//).pop()

  const indexOfLastDash = formattedName.lastIndexOf("-")

  const prefix = formattedName.slice(0, indexOfLastDash)
  const suffix = formattedName.slice(indexOfLastDash)

  return (
    <Box
      borderRadius={sizer(1)}
      width="100%"
      backgroundColor="colorBackgroundSubtle"
      padding={sizer(3)}
      margin={sizer(1, 0)}
    >
      <Flex flex={1} alignItems="center">
        <Flex>
          <StyledFontAwesomeIcon color="colorTextWeakest" icon={faFileCheck} fixedWidth fontSize="32px" />
        </Flex>
        <Box width="calc(100% - 50px)">
          <Flex>
            <Box className="text-ellipsis">
              <StyledSpan color="colorTextStrong" fontSize={getTextSize("sm")} width="100%">
                {prefix}
              </StyledSpan>
            </Box>
            <Box whiteSpace="nowrap">
              <StyledSpan color="colorTextStrong" fontSize={getTextSize("sm")} width="100%">
                {suffix}
              </StyledSpan>
            </Box>
          </Flex>
          {installerSize && (
            <Text color="colorTextWeakest" size="xs">
              {getReadableBytes(installerSize)}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

const DownloadInstallerModal = ({ unmount, url, installerSize }) => {
  return (
    <Modal
      {...{
        size: "sm",
        unmount,
        withCloseX: true,
        titleGroup: {
          TitleComponent: () => (
            <Text token="addDevices.successfullyGenerated" size="lg" bold color="colorTextStrong" />
          ),
        },
        buttonRenderer: () => <InstallerButtonRow {...{ url }} />,
      }}
    >
      <LinkCard {...{ url, installerSize }} />
    </Modal>
  )
}

export default DownloadInstallerModal
