import { useMountedState } from "@ninjaone/utils"
import { getItamTags } from "js/includes/common/client"
import { reportErrorAndShowMessage } from "js/includes/common/utils"
import { useEffect } from "react"

export const useItamTags = detailed => {
  const [loading, setLoading] = useMountedState(true)
  const [itamTags, setItamTags] = useMountedState([])

  useEffect(() => {
    ;(async () => {
      try {
        const response = await getItamTags(detailed)

        setItamTags(response.tags)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [detailed, setItamTags, setLoading])

  return {
    loading,
    itamTags,
    setItamTags,
  }
}
