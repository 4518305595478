import styled from "@emotion/styled"
import { Input, Text, Button, Checkbox, InfoTooltip, Tooltip } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { VARIANTS } from "@ninjaone/components/src/Button"
import { useMountedState } from "js/includes/common/hooks"
import { Box, Flex } from "js/includes/components/Styled"
import { localized, localizationKey, isEnterKey } from "js/includes/common/utils"
import { clearIllegalCharacters } from "js/includes/configuration/scripting/ScriptsSubTabModel/common"
import ScriptListRow from "js/includes/configuration/scripting/ScriptsSubTabModel/ScriptEditor/ScriptListRow"
import ScriptDragableList from "js/includes/configuration/scripting/ScriptsSubTabModel/ScriptEditor/ScriptDraggableList"

const PARAMETER_VALUE_MAX_LENGTH = 30000
const PARAMETER_LIST_MAX_SIZE = 300

const StyledButton = styled(Button)`
  height: 42px;
  &:disabled {
    color: ${({ theme }) => theme.colorTextWeakest};
    background-color: ${({ theme }) => theme.colorBackgroundCtaDisabled};
  }
`

const ParameterInput = ({ parameter, parameters, handleEnter, setParameter }) => (
  <Box className="automation-params-input">
    <Input
      ariaLabel={localized("Preset Parameters")}
      value={parameter}
      onKeyPress={handleEnter}
      placeholder={localized("Enter Preset Parameters")}
      onChange={e => setParameter(clearIllegalCharacters(e.target.value))}
      maxLength={PARAMETER_VALUE_MAX_LENGTH}
      disabled={parameters?.length >= PARAMETER_LIST_MAX_SIZE}
      minWidth="150px"
    />
  </Box>
)

const AddParameterButton = ({ addParameter, parameter, parameters }) => (
  <StyledButton
    variant={VARIANTS.SECONDARY}
    labelToken={localizationKey("Add")}
    onClick={addParameter}
    overrideMinWidth
    disabled={!parameter || parameters?.length >= PARAMETER_LIST_MAX_SIZE}
  />
)

const AddParameterSubForm = ({ parameters, Component, inputAndButtonProps }) =>
  parameters?.length >= PARAMETER_LIST_MAX_SIZE ? (
    <Tooltip label={localized("Maximum limit of 300 reached")} align="center">
      <Component {...inputAndButtonProps} />
    </Tooltip>
  ) : (
    <Component {...inputAndButtonProps} />
  )

export const Parameters = ({
  parameters,
  onParametersChanged,
  labelText = localized("Parameters"),
  useFirstParametersOptionAsDefault = false,
  onFirstParametersOptionChanged,
}) => {
  const [parameter, setParameter] = useMountedState("")

  const removeParameter = parameter => {
    const parametersList = parameters.filter(param => param !== parameter)
    onParametersChanged(parametersList)
    if (!parametersList.length) {
      onFirstParametersOptionChanged(false)
    }
  }

  const addParameter = () => {
    const currentParameter = parameter.trim()
    const isExisting = parameters.includes(currentParameter)
    if (currentParameter.length && !isExisting) {
      onParametersChanged([...parameters, currentParameter])
    }
    setParameter("")
  }

  const handleEnter = e => {
    if (isEnterKey(e)) {
      addParameter(e)
    }
  }

  const inputAndButtonProps = { parameter, parameters, handleEnter, setParameter, addParameter }

  return (
    <Box className="automation-params-container">
      <Text
        type="body"
        fontWeight={tokens.typography.fontWeight.medium}
        marginBottom={tokens.spacing[1]}
        className="automation-params-label"
      >
        {labelText}
        <InfoTooltip token={localizationKey("Maximum of 300 parameters allowed")} />
      </Text>
      <Flex justifyContent="space-between" width="100%" gap={tokens.spacing[2]} alignItems="center">
        <Box flexGrow={1}>
          <AddParameterSubForm {...{ parameters, Component: ParameterInput, inputAndButtonProps }} />
        </Box>
        <AddParameterSubForm {...{ parameters, Component: AddParameterButton, inputAndButtonProps }} />
      </Flex>
      <Box marginTop={tokens.spacing[2]}>
        <Checkbox
          checked={useFirstParametersOptionAsDefault}
          label={localized("Set first option as the default value")}
          disabled={!parameters?.length}
          onChange={({ isChecked }) => onFirstParametersOptionChanged(isChecked)}
        />
      </Box>
      <ScriptDragableList
        {...{
          elements: parameters,
          noRowsLabel: () => (
            <Flex justifyContent="center">
              <Text size="sm" color="gray" token={localizationKey("No parameters have been added")} />
            </Flex>
          ),
          onChange: onParametersChanged,
          renderElement: (parameter, _, __, index) => {
            return (
              <ScriptListRow
                showDefaultLabel={useFirstParametersOptionAsDefault && index === 0}
                name={parameter}
                onDelete={() => removeParameter(parameter)}
              />
            )
          },
        }}
      />
    </Box>
  )
}

export default Parameters
