import { Form } from "react-bootstrap"
import { Modal, Text } from "@ninjaone/components"
import { Box } from "js/includes/components/Styled"
import {
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  showSuccessMessage,
  runDeviceSearchToolbarAction,
} from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"
import { sendActionMultipleDevices } from "js/includes/common/client"

export default function LockDeviceModal({ nodeIds, unmount, runnerActionName }) {
  const [loading, setLoading] = useMountedState(false)

  const handleLockAction = async () => {
    setLoading(true)
    try {
      if (runnerActionName) {
        await runDeviceSearchToolbarAction({ action: runnerActionName })
      } else {
        await sendActionMultipleDevices(nodeIds, { type: "LOCK_DEVICE" })
      }
      showSuccessMessage(localized("The action was sent successfully"))
      unmount()
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        reportErrorAndShowMessage(error, localizationKey("The action could not be sent"))
      }
    } finally {
      setLoading(false)
    }

    // TODO: Add support for others locking process(admin, lost-mode) when BE will be ready
  }

  return (
    <Modal
      size="md"
      titleGroup={{
        titleToken: localizationKey("Lock Device"),
      }}
      cancelable
      unmount={unmount}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Lock"),
          onClick: handleLockAction,
          disabled: loading,
        },
      ]}
    >
      <Box>
        <Form>
          <Box>
            <Text
              textWrap
              token={localizationKey(
                "Remote Lock immediately locks the device and requires an authorized user to enter their passcode.",
              )}
            />
          </Box>
        </Form>
      </Box>
    </Modal>
  )
}
