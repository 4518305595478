import { useEffect, useState } from "react"
import { Provider, Root, Trigger, Portal, Content } from "@radix-ui/react-tooltip"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import PropTypes from "prop-types"

import { CopyIconLight, CheckIconRegular } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import { localized, localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"

const StyledTrigger = styled(Trigger, {
  shouldForwardProp: isPropValid,
})`
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 4px;
  color: ${({ theme, iconColor, increaseContrast }) =>
    increaseContrast ? theme.colorTextActionStrong : theme[iconColor]};
  background-color: ${({ theme, transparent }) => (transparent ? "transparent" : theme.colorBackground)};
  cursor: pointer;
  ${({ positioningStyles }) => positioningStyles}

  &:hover {
    background-color: ${({ theme, transparent }) => (transparent ? "transparent" : theme.colorForegroundHover)};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }

  &[aria-disabled="true"] {
    color: ${({ theme }) => theme.colorTextDisabled};
    background-color: ${({ theme }) => theme.colorBackgroundInputDisabled};
    cursor: not-allowed;
  }
`

const StyledContent = styled(Content)`
  z-index: 9999;
  padding: ${sizer(1, 2)};
  border-radius: 4px;
  font-size: ${getTextSize("xs")};
  color: ${({ theme }) => theme.colorTextHighContrast};
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralDarker};
`

const DEFAULT_TEXT_TOKEN = localizationKey("Copy")
const COPIED_TEXT_TOKEN = localizationKey("Copied")

const Copied = () => (
  <Flex gap="2px" alignItems="center">
    <CheckIconRegular size="xs" />
    {localized(COPIED_TEXT_TOKEN)}
  </Flex>
)

const ClipboardCopy = ({
  alignTooltip,
  disabled,
  iconColor = "colorTextAction",
  increaseContrast,
  positioningStyles,
  positionTooltip,
  tooltipText,
  transparent,
  value,
  getValue,
}) => {
  const [copied, setCopied] = useState(false)

  const handleClick = async e => {
    e.preventDefault() // necessary to persist the tooltip's open state on click

    try {
      const textToCopy = value || (getValue && (await getValue()))
      await navigator.clipboard.writeText(textToCopy)
      setCopied(true)
    } catch (err) {
      alert(`${localized("Could not copy text")}: ${err}`)
    }
  }
  useEffect(() => {
    copied && setTimeout(() => setCopied(false), 3000)
  }, [copied])

  return (
    <Provider>
      <Root>
        <StyledTrigger
          onClick={handleClick}
          iconColor={iconColor}
          tabIndex={0}
          aria-label={copied ? localized(COPIED_TEXT_TOKEN) : tooltipText || localized(DEFAULT_TEXT_TOKEN)}
          aria-live="polite"
          aria-disabled={disabled}
          data-ninja-clipboard-copy
          {...{ disabled, increaseContrast, positioningStyles, transparent }}
        >
          <CopyIconLight />
        </StyledTrigger>

        {!disabled && (
          <Portal>
            <StyledContent
              align={alignTooltip}
              side={positionTooltip}
              sideOffset={4}
              onPointerDownOutside={e => e.preventDefault()} // necessary to persist the tooltip's open state on trigger click
            >
              {copied ? <Copied /> : tooltipText || localized(DEFAULT_TEXT_TOKEN)}
            </StyledContent>
          </Portal>
        )}
      </Root>
    </Provider>
  )
}

ClipboardCopy.defaultProps = {
  alignTooltip: "center",
  positionTooltip: "bottom",
}

ClipboardCopy.propTypes = {
  /**
   * The horizontal alignment of the tooltip.
   */
  alignTooltip: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * Disables the trigger and sets disabled styles.
   */
  disabled: PropTypes.bool,
  /**
   * The position of the tooltip relative to the copy button.
   */
  positionTooltip: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * The value of the input.
   */
  value: PropTypes.string.isRequired,
}

export default ClipboardCopy
