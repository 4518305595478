import { useEffect, useState } from "react"
import { connect } from "react-redux"
import CreatableSelect from "react-select/creatable"
import { components } from "react-select"
import { compose, map, prepend, propEq, when } from "ramda"
import { Text, Select } from "@ninjaone/components"
import { getTextSize, sizer } from "@ninjaone/utils"
import { localized, sortByFieldNameCaseInsensitive } from "js/includes/common/utils"
import { getCredentialSelectOptions } from "./common/credentials"
import { Box } from "js/includes/components/Styled"
import { useMounted } from "js/includes/common/hooks"
import RunModalScriptVariables from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/RunModalScriptVariables"

const creatableSelectStyles = {
  control: styles => ({ ...styles, fontSize: getTextSize("sm"), padding: "6px" }),
  placeholder: styles => ({ ...styles, margin: "0px" }),
}

const ParameterOption = props => {
  return (
    <div>
      <components.Option {...props} />
    </div>
  )
}

const ScriptParameters = ({ onChange, parameterModal, conditionScriptSelector, validation, values }) => {
  const { runAs, scriptParam } = values
  const [credentialOptions, setCredentialOptions] = useState([])
  const { deviceType, scriptParameters, scriptVariables } = parameterModal

  const mounted = useMounted()
  useEffect(() => {
    const fetchAndSetCredentials = async () => {
      const credentials = await getCredentialSelectOptions(deviceType, "labelText")

      mounted.current && setCredentialOptions(credentials)
    }
    fetchAndSetCredentials()
  }, [deviceType, mounted, setCredentialOptions])

  useEffect(() => {
    if (!runAs) {
      const defaultRunAsOption = credentialOptions[0]?.value
      defaultRunAsOption && onChange({ runAs: defaultRunAsOption })
    }
  }, [runAs, credentialOptions, onChange])

  const scriptParameterOptions =
    scriptParameters &&
    compose(
      sortByFieldNameCaseInsensitive("label", "ASC"),
      map(p => ({ label: p, value: p })),
      when(() => scriptParam && !scriptParameters.includes(scriptParam), prepend(scriptParam)),
    )(scriptParameters)

  const handleParamChange = selection => {
    const scriptParam = selection?.value.trim()
    if (scriptParam) {
      return onChange({ scriptParam })
    }
    return onChange({ scriptParam: "" })
  }

  return (
    parameterModal && (
      <>
        {!conditionScriptSelector && (
          <Box marginBottom={sizer(3)}>
            <Text bold size="sm">
              {localized("Run As")}
            </Text>
            <Select
              matchWidth
              options={credentialOptions}
              value={runAs || ""}
              onChange={selection => onChange({ runAs: selection })}
              errorMessage={validation.message?.runAs}
              triggerAriaLabel={localized("Run As")}
            />
          </Box>
        )}
        <Box marginBottom={sizer(4)}>
          <Text bold size="sm">
            {localized("Preset Parameter")}
          </Text>
          <CreatableSelect
            {...{
              styles: creatableSelectStyles,
              options: scriptParameterOptions,
              placeholder: localized("Choose preset or type in custom parameter"),
              onChange: handleParamChange,
              formatCreateLabel: value => localized('Create "{{value}}"', { value: value.trim() }),
              isValidNewOption: value => value.trim(),
              matchProp: "label",
              isClearable: true,
              value: scriptParameterOptions.find(propEq("value", scriptParam)),
              components: { Option: ParameterOption },
            }}
          />
        </Box>
        <RunModalScriptVariables {...{ scriptVariables, onChange, validation, values }} />
      </>
    )
  )
}

export default connect(state => ({
  parameterModal: state.policyEditor.scripting.parameterModal,
  conditionScriptSelector: state.policyEditor.scripting.conditionScriptSelector,
}))(ScriptParameters)
