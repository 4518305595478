import { useCallback, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { includes, filter, compose, reject } from "ramda"
import { nanoid } from "nanoid"
import { Modal } from "@ninjaone/components"
import { Flex } from "js/includes/components/Styled"
import { fetchResources, getAutomationsWithCategories } from "js/includes/components/scripting/utils"
import { getScriptCategories } from "js/state/selectors/scripting/scriptCategories"
import ScriptsDataTable from "js/includes/components/scripting/ScriptsDataTable"
import { getParameterComponent, setScriptName } from "js/includes/components/scripting/ScriptsSelector/ScriptsTable"
import showModal from "js/includes/common/services/showModal"
import { setValue } from "js/state/actions/common/value"
import { isUid, findByUid, findById } from "js/includes/common/utils"
import ParameterModal from "js/includes/components/scripting/ScriptsSelector/ParameterModal"
import { requestAllScriptCategories as requestAllCategories } from "js/state/actions/scripting/scriptCategories"
import { requestAllScripts as requestAllAutomations } from "js/state/actions/scripting/scripts"

const AdditionalInstallApplicationScriptModal = ({
  unmount,
  scriptsList,
  titleToken,
  setScriptSelected,
  os,
  setParameterModal,
  requestAllCategories,
  requestAllAutomations,
}) => {
  const fetchAutomations = useCallback(async () => {
    await fetchResources({
      requestResources: [requestAllCategories, requestAllAutomations],
    })
  }, [requestAllCategories, requestAllAutomations])

  useEffect(() => {
    fetchAutomations()
  }, [fetchAutomations])

  const scriptsListData = useMemo(
    () =>
      compose(
        filter(({ deviceType }) => includes(deviceType, os)),
        filter(({ language }) => includes(language, ["vbscript", "sh", "powershell", "javascript", "batchfile"])),
        reject(({ language }) => includes(language, ["binary_install", "binary_run"])),
      )(scriptsList),
    [scriptsList, os],
  )

  const handleSelectScript = ({ script }) => {
    const updatedScript = buildUpdatedScript(script)
    setScriptSelected(updatedScript)
    unmount()
  }

  const buildUpdatedScript = script => {
    const { scriptId, parameters, scriptVariables } = script
    const { name } = isUid(scriptId) ? findByUid(scriptId, scriptsList) : findById(scriptId, scriptsList)
    return {
      name,
      scriptId,
      parameters,
      scriptVariables,
    }
  }

  const handleRowSelected = rowData => {
    const scriptId = rowData.language === "native" ? rowData.uid : rowData.id
    const {
      deviceType,
      scriptParameters,
      parameters,
      scriptVariables,
      description,
      name,
      language,
      useFirstParametersOptionAsDefault,
    } = rowData

    const parameterModal = {
      parameterComponent: getParameterComponent(rowData),
      scriptName: name,
      scriptDescription: description,
      scriptLanguage: language,
      scriptId,
      deviceType,
      scriptParameters,
      parameters: {
        ...parameters,
        ...(useFirstParametersOptionAsDefault && { scriptParam: scriptParameters[0] }),
      },
      scriptVariables,
    }
    if (parameterModal.parameterComponent) {
      setParameterModal(parameterModal)
      showModal(
        <ParameterModal onAfterApply={unmount} {...{ applyScript: handleSelectScript, isInstallScript: true }} />,
        {
          withProvider: true,
        },
      )
    } else {
      const script = {
        id: nanoid(10),
        scriptId,
        scriptName: setScriptName(rowData),
      }
      handleSelectScript({ script })
    }
  }

  return (
    <Modal
      unmount={unmount}
      size="lg"
      titleGroup={{
        titleToken,
      }}
      buttonRenderer={() => <></>}
    >
      <Flex height="555px">
        <ScriptsDataTable
          scriptsList={scriptsListData}
          onRowSelected={handleRowSelected}
          showAddButton={false}
          isEditableRows={false}
          showAutomationTypeFilter={false}
          hideSettingsButton
          automationTypesExcept={["BinaryInstallAutomation", "BinaryRunAutomation"]}
          defaultDeviceTypes={os}
          defaultAutomationTypes={["ScriptAutomation"]}
          sortBy={[
            {
              id: "deviceType",
            },
          ]}
        />
      </Flex>
    </Modal>
  )
}

export default connect(
  state => ({
    scriptsList: getAutomationsWithCategories({
      automations: state.scripting.scripts,
      categories: getScriptCategories(state),
    }),
  }),
  {
    setParameterModal: setValue("parameterModal"),
    requestAllCategories,
    requestAllAutomations,
  },
)(AdditionalInstallApplicationScriptModal)
