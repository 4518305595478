import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { DataTable, Modal } from "@ninjaone/components"
import { cellTypes } from "@ninjaone/components/src/DataTable"
import { Box } from "js/includes/components/Styled"
import { getTicketingUrl, rejectInactive } from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import { fetchTicketForms as _fetchTicketForms } from "js/state/actions/ticketing"
import { getNewTicketPagePath } from "js/includes/ticketing/shared/utils"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"

const getColumns = ({ nameColumnProps = {} }) => [
  {
    Header: localized("Name"),
    id: "name",
    accessor: "name",
    ...nameColumnProps,
  },
]

function _CreateTicketModal({ unmount, ticketFormsList, ticketFormsLoading, fetchTicketForms }) {
  useEffect(() => {
    fetchTicketForms()
  }, [fetchTicketForms])

  const history = useHistory()
  const forms = rejectInactive(ticketFormsList ?? [])

  function onCreateNewTicket(form) {
    const { isDefault, id: formId } = form

    unmount()
    history.push(
      getNewTicketPagePath({
        queryParams: {
          ...(!isDefault && { formId }),
        },
      }),
    )
  }

  return (
    <Modal
      {...{
        unmount,
        withCloseX: true,
        titleGroup: {
          titleText: localized("Ticket forms"),
        },
      }}
    >
      <Box height="297px">
        <DataTable
          columns={getColumns({
            nameColumnProps: {
              getCellTypeProps: row => ({
                type: cellTypes.BUTTON_LINK,
                props: {
                  onClick: () => {
                    onCreateNewTicket(row)
                  },
                },
              }),
            },
          })}
          rows={forms}
          actions={{
            row: {
              action: row => {
                onCreateNewTicket(row)
              },
            },
          }}
          initialSortBy={[{ id: "name" }]}
          loading={ticketFormsLoading}
          hideSettingsButton
        />
      </Box>
    </Modal>
  )
}

const CreateTicketModal = connect(
  ({ ticketing: { forms } }) => ({
    ticketFormsList: forms.list,
    ticketFormsLoading: forms.loading,
  }),
  {
    fetchTicketForms: _fetchTicketForms,
  },
)(_CreateTicketModal)

export const showCreateTicketModal = () =>
  showModal(<CreateTicketModal />, {
    withProvider: true,
    withGraphQLProvider: true,
    withReactRouter: true,
    graphQLUrl: getTicketingUrl(),
  })
