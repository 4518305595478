import $ from "jquery"
import _ from "underscore"
import { localized } from "js/includes/common/utils"

const chosen = {
  routine: function(el, value) {
    if (value) {
      if (!$(el).data("rivets.binders.chosen.bound")) {
        _.defer(function() {
          if (!$(el).data("rivets.binders.chosen.bound")) {
            var width = $(el).data("width") ? $(el).data("width") : "100%"
            var createOption = $(el).data("create-option") ? $(el).data("create-option") : false
            // eslint-disable-next-line
            var disableSearch = $(el).data("searchable") == false
            var propagateChange = $(el).data("propagate-change")
            var fuzzySearch = !!$(el).data("fuzzy-search")

            // If the display name is different from the reference value and create-option === true;
            // This will prevent users from creating the same item.
            // Use this data attr on the binder and data-exact-match="{STRING}" on the list item.
            var exactMatch = !!$(el).data("exact-match")

            var instance = $(el).chosen({
              create_option: createOption,
              disable_search: disableSearch,
              search_fuzzy: fuzzySearch,
              width: width,
              exact_match: exactMatch,
              single_text: localized("Select some options"),
              multiple_text: localized("Select some options"),
              no_results_text: localized("No results match"),
              create_option_text: localized("Add"),
            })

            $(el).data("rivets.binders.chosen.instance", instance)

            if (propagateChange) {
              value.listenTo(value, "change", function() {
                var instance = $(el).data("rivets.binders.chosen.instance")
                if (instance) {
                  instance.trigger("chosen:updated")
                }
              })
            }
          }

          $(el).data("rivets.binders.chosen.bound", true)
        })
      }
    }
  },

  unbind: function(el) {
    if ($(el).data("rivets.binders.chosen.instance")) {
      if ($(el).data("propagate-change")) {
        //var adapter = rivets.adapters[':'];
        var paths = this.keypath.split(":")
        this.model.get(paths[paths.length - 1]).stopListening()
      }
      $(el).chosen("destroy")
      $(el).removeData()
      $(el).off()
    }
  },
}

export default chosen
