import $ from "jquery"
import rivets from "rivets"
import moment from "moment"
import DateModel from "../../../includes/editors/Common/DateModel"
import "jquery-ui/ui/widgets/datepicker"
import { localized } from "js/includes/common/utils"

const datepicker = {
  routine: function(el, value) {
    var isDisabled = $(el).data("rivets.binders.datepicker.isDisabled")
    if (!isDisabled && value && value.date) {
      $(el).data("rivets.binders.datepicker.isDisabled", true)

      var initialized = $(el).data("rivets.binders.datepicker.initialized")
      if (!initialized) {
        $(el).datepicker()
        $(el).data("rivets.binders.datepicker.initialized", true)
      }

      if (!value.isEmpty()) {
        $(el).datepicker("update", value.date())
      }

      $(el).data("rivets.binders.datepicker.isDisabled", false)
    }
  },

  bind: function(el) {
    const localeDateFormat = moment.localeData()._longDateFormat["L"]

    // Get the date format from moment.js
    $(el).attr("data-date-format", localeDateFormat.toLowerCase())
    $(el).attr("placeholder", localized("Date"))

    var adapter = rivets.adapters[":"]
    var self = this
    this.callback = function(e) {
      var isDisabled = $(el).data("rivets.binders.datepicker.isDisabled")
      if (!isDisabled) {
        $(el).data("rivets.binders.datepicker.isDisabled", true)
        var dateString = e.target.value
        const date = moment(dateString, localeDateFormat)
        if (date.isValid()) {
          self.model.get("errors").date = undefined
          self.model.trigger("change:errors")
        } else {
          self.model.get("errors").date = localized("Please enter a valid date")
          self.model.trigger("change:errors")
        }
        adapter.set(
          self.model,
          "date",
          new DateModel({
            year: date.year(),
            month: date.month(),
            day: date.date(),
          }),
        )
        $(el).data("rivets.binders.datepicker.isDisabled", false)
      }
    }
    $(el).data("rivets.binders.datepicker.initialized", false)
    $(el)
      .datepicker()
      .on("change", this.callback)
    $(el)
      .datepicker()
      .on("changeDate", function(e) {
        $(el).datepicker("hide")
      })
    $(el)
      .datepicker()
      .on("show", function() {
        if (self.model.get("errors").date) {
          $(el).datepicker("hide")
        }
      })
    return true
  },

  unbind: function(el) {
    this.model.get("errors").date = undefined
    $(el).off()
    return true
  },
}

export default datepicker
