import React, { useState } from "react"
import { equals } from "ramda"
import styled from "@emotion/styled"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { Box, StyledSpan } from "js/includes/components/Styled"
import { StyledFormGroup, StyledCol } from "js/includes/components/Styled/Form"
import { colors } from "js/includes/common/theme"
import showModal from "js/includes/common/services/showModal"
import ScheduledDiscoveryScheduleModal from "../modals/ScheduledDiscoveryScheduleModal"
import { TaskScheduleSummary } from "js/includes/configuration/scheduledTasks/TaskScheduleSummary"
import SelectableKeyboardShortcutsTable from "js/includes/components/SelectableKeyboardShortcutsTable"
import SetLocationAndStatusModal, { getApprovalStatusScheduledOptions } from "../modals/SetLocationAndStatusModal"

const isWindowsServer = equals("windows_servers")

const getApprovalModeLabel = approvalMode => {
  if (!approvalMode) return "-"
  return approvalMode === "PENDING_APPROVAL" ? localized("Pending") : localized("Approved")
}

const windowsDeploymentSettings = [
  {
    id: "windows_servers",
    token: localizationKey("Windows Servers"),
  },
  {
    id: "windows_workstations",
    token: localizationKey("Windows Workstations"),
  },
]

const StyledSelectableKeyboardShortcutsTableContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  .list-actions {
    display: flex;
    align-items: center;
    height: 32px;
    margin-left: 0;

    button {
      padding: 8px;
      border-radius: 4px;
      &:hover {
        background: ${colors.ninjaWhite};
      }
    }
  }

  .ReactVirtualized__Table__headerRow {
    background: ${colors.adDiscoveryHeader};
  }
`

export default function ScheduledDiscovery({
  locations,
  values: {
    schedule,
    windowsServers,
    windowsServersLocation,
    windowsServersApprovalMode,
    windowsWorkstations,
    windowsWorkstationsLocation,
    windowsWorkstationsApprovalMode,
  },
  onChange,
  validation,
  defaultLocation: dcLocation,
}) {
  const [selectedWindowsDeploymentSettings, setSelectedWindowsDeploymentSettings] = useState({ rows: {}, length: 0 })

  const getColumns = () => [
    {
      dataKey: "token",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Deployment"),
      cellRenderer: ({ cellData }) => localized(cellData),
    },
    {
      dataKey: "status",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Status"),
      cellRenderer: ({ rowData: { id } }) => (
        <Box>
          {getApprovalModeLabel(isWindowsServer(id) ? windowsServersApprovalMode : windowsWorkstationsApprovalMode)}
        </Box>
      ),
    },
    {
      dataKey: "location",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Location"),
      cellRenderer: ({ rowData: { id } }) => (
        <Box>{(isWindowsServer(id) ? windowsServersLocation?.name : windowsWorkstationsLocation?.name) || "-"}</Box>
      ),
    },
  ]

  const findOptionByValue = (options, value) => options.find(option => option.value === value)

  const getDefaultSelectedValues = selected => {
    const approvalOptions = getApprovalStatusScheduledOptions()
    if (selected.length === 1) {
      return {
        defaultLocation: selected.rows.windows_servers ? windowsServersLocation : windowsWorkstationsLocation,
        defaultStatus: findOptionByValue(
          approvalOptions,
          selected.rows.windows_servers ? windowsServersApprovalMode : windowsWorkstationsApprovalMode,
        ),
      }
    }
    return {
      defaultLocation: equals(windowsServersLocation, windowsWorkstationsLocation)
        ? windowsServersLocation || dcLocation
        : null,
      defaultStatus: equals(windowsServersApprovalMode, windowsWorkstationsApprovalMode)
        ? findOptionByValue(approvalOptions, windowsServersApprovalMode) || approvalOptions[0]
        : null,
    }
  }

  const getActions = selected => {
    const SET_LOCATION_AND_STATUS = {
      id: "set-location-and-status",
      token: localizationKey("Set Status and Location"),
      type: "SET_LOCATION_AND_STATUS",
      action: async (selected, dispatchCallback) => {
        const { defaultLocation, defaultStatus } = getDefaultSelectedValues(selected)
        showModal(
          <SetLocationAndStatusModal
            {...{
              locations,
              defaultLocation,
              defaultStatus,
              onSetLocationAndStatus: ({ location, status }) => {
                if (selected.rows.windows_servers) {
                  onChange({
                    windowsServers: !!status?.value,
                    windowsServersLocation: location,
                    windowsServersApprovalMode: status?.value,
                  })
                }
                if (selected.rows.windows_workstations) {
                  onChange({
                    windowsWorkstations: !!status?.value,
                    windowsWorkstationsLocation: location,
                    windowsWorkstationsApprovalMode: status?.value,
                  })
                }
              },
              statusOptions: getApprovalStatusScheduledOptions(),
            }}
          />,
        )
      },
    }
    if (selected.length) {
      return [SET_LOCATION_AND_STATUS]
    }
    return []
  }

  return (
    <Box textAlign="left" marginTop={2}>
      <Box marginBottom={4}>
        <StyledFormGroup
          margin="0 !important"
          display="block"
          controlId="schedule"
          height="30px"
          validationState={validation.success.schedule === false ? "error" : null}
        >
          <StyledCol xs={3} padding="0">
            <StyledSpan fontSize="16px">{localized("Schedule")}</StyledSpan>
          </StyledCol>
          <StyledCol xs={8} padding="0">
            <Box
              className="btn-link"
              onClick={() => {
                showModal(
                  <ScheduledDiscoveryScheduleModal
                    {...{
                      schedule,
                      onSave: schedule => {
                        onChange({ schedule })
                      },
                    }}
                  />,
                )
              }}
            >
              {schedule ? (
                <TaskScheduleSummary {...{ schedule, visibleTimeZone: false }} />
              ) : (
                <StyledSpan fontSize="14px">{localized("Set Schedule")}</StyledSpan>
              )}
            </Box>
          </StyledCol>
        </StyledFormGroup>
      </Box>
      <Box display="block" height="250px">
        <Box display="flex" height="100%" width="100%">
          <StyledSelectableKeyboardShortcutsTableContainer>
            <SelectableKeyboardShortcutsTable
              {...{
                columns: getColumns,
                data: windowsDeploymentSettings,
                rowHeight: 45,
                headerHeight: 45,
                actions: getActions(selectedWindowsDeploymentSettings),
                onAction: ({ type, action }, selected, { dispatchCallback }) => {
                  action(selected, dispatchCallback, true)
                },
                onCheck: ({ selected }) => {
                  setSelectedWindowsDeploymentSettings(selected)
                },
                hideResultCount: true,
              }}
            />
          </StyledSelectableKeyboardShortcutsTableContainer>
        </Box>
      </Box>
    </Box>
  )
}
