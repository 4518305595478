import $ from "jquery"

const addclass = function(el, value) {
  if (el.addedClass) {
    $(el).removeClass(el.addedClass)
    delete el.addedClass
  }

  if (value) {
    var retainCase = $(el).data("retaincase")
    if (!retainCase) {
      value = value.toLowerCase()
    }
    $(el).addClass(value)
    el.addedClass = value
  }
}

export default addclass
