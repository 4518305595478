import React, { useEffect, useCallback } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { noop } from "@ninjaone/utils"
import { localized, showSuccessMessage, showErrorMessage, isValidCors } from "js/includes/common/utils"
import { getOrganizationCredentials } from "js/state/actions/credentials"
import SecureFrame from "js/includes/components/SecureFrame"
import { CREDENTIAL_TYPES } from "./common"

const root = "secure_forms/credentials"

const credentialParams = {
  [CREDENTIAL_TYPES.BASIC]: {
    height: "370px",
    src: `${root}/usernamePassword.html`,
  },
  [CREDENTIAL_TYPES.SNMPV1]: {
    height: "240px",
    src: `${root}/SNMPV1.html`,
  },
  [CREDENTIAL_TYPES.SNMPV2]: {
    height: "240px",
    src: `${root}/SNMPV2C.html`,
  },
  [CREDENTIAL_TYPES.SNMPV12C]: {
    height: "240px",
    src: `${root}/SNMPV12C.html`,
  },
  [CREDENTIAL_TYPES.SNMPV3]: {
    height: "690px",
    src: `${root}/SNMPV3.html`,
  },
  [CREDENTIAL_TYPES.TELNET_SSH]: {
    height: "566px",
    src: `${root}/telnetSSH.html`,
  },
}

const CredentialModalForm = ({
  closeModal,
  setCredentials,
  initialValues,
  type,
  setType = noop,
  options = [],
  clientId,
  sessionPrefix,
  showTypeSelector = true,
}) => {
  const eventHandler = useCallback(
    async event => {
      if (event.data) {
        if (isValidCors(event.origin) && event.data === "SUCCESS") {
          showSuccessMessage(initialValues ? localized("Credential updated") : localized("Credential created"))
          closeModal()
          const credentials = await getOrganizationCredentials(clientId)
          setCredentials(credentials)
        } else if (event.data === "FAILURE") {
          showErrorMessage(localized("Error saving credential"))
        }
      }
    },
    [clientId, initialValues, closeModal, setCredentials],
  )

  useEffect(() => {
    window.addEventListener("message", eventHandler)
    return () => {
      window.removeEventListener("message", eventHandler)
    }
  }, [eventHandler])

  const additionalParams = {
    clientId,
    initialValues,
    sessionPrefix,
  }

  const lang = window.store.getState().application.language

  const credentialParam = credentialParams[type.value]

  return (
    <div className="max-modal-body">
      {showTypeSelector && (
        <Select
          isDisabled={!!initialValues}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!type.value}
          openMenuOnFocus={!type.value}
          value={type}
          onChange={setType}
          className="m-b-lg"
          options={options}
        />
      )}
      {credentialParam && (
        <SecureFrame
          {...{
            lang,
            additionalParams,
            ...credentialParam,
          }}
        />
      )}
    </div>
  )
}

export default connect(({ application }) => ({
  sessionPrefix: application.serverCallPrefix,
}))(CredentialModalForm)
