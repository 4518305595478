import styled from "@emotion/styled"

const StyledModalContainer = styled.div`
  .modal {
    background: #211f3380;
  }
  .modal-footer {
    border-top: 0px !important;
  }
`

export default StyledModalContainer
