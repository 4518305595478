const faVmHost = {
  prefix: "fac",
  iconName: "vmHost",
  icon: [
    200,
    200,
    [],
    "0029",
    `M175.88,0H72.39C59.07,0,48.28,10.8,48.28,24.12v24.16H24.12C10.8,48.28,0,59.07,0,72.39v103.49
     C0,189.2,10.8,200,24.12,200h103.49c13.32,0,24.12-10.8,24.12-24.12v-24.16h24.16c13.32,0,24.12-10.8,24.12-24.12V24.12
     C200,10.8,189.2,0,175.88,0z`,
  ],
}

export default faVmHost
