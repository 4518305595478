import styled from "@emotion/styled"
import { getTextSize, sizer } from "@ninjaone/utils"

export const StyledOption = styled.button`
  width: 100%;
  display: flex;
  color: ${({ theme, active }) => (active ? theme.colorTextAction : theme.colorTextStrong)};
  border-radius: ${sizer(1)};
  padding: ${sizer(3, 6)};
  margin: ${sizer(2, 0)};
  &:first-of-type {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  cursor: pointer;
  border: ${({ theme, active }) => `1px solid ${active ? theme.colorBorderDecorativeStrong : "transparent"}`};
  background-color: ${({ theme, active }) => `${active ? theme.colorForegroundSelected : "transparent"}`};
  &:hover {
    background-color: ${({ theme, active }) => `${theme.colorForegroundSelected}`};
  }
  &:disabled {
    background-color: ${({ active }) => (active ? "" : "transparent")};
  }
`

export const AddDeviceGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(180px, 1fr) 2fr;
  grid-gap: ${sizer(6)};
  min-height: ${({ minHeight }) => minHeight ?? "350px"};
`
export const StyledLabel = styled.label`
  font-size: ${getTextSize("sm")};
  font-weight: 400;
  line-height: 1.5;
  color: ${({ theme, errorMessage }) => (errorMessage ? theme.colorTextDanger : theme.colorTextStrong)};
  padding-bottom: ${sizer(1)};
  margin-bottom: inherit;
`
