import styled from "@emotion/styled"
import PropTypes from "prop-types"

import { sizer } from "@ninjaone/utils"
import Text from "./Text"

const StyledTitleGroup = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: ${sizer(1)};
  ${({ overflowHidden }) => (overflowHidden ? "overflow: hidden;" : "")};
`

const StyledTextRow = styled.div`
  color: ${({ theme }) => theme.colorTextStrong};
`

export const StyledTextGroup = styled.div`
  display: flex;

  > *:first-of-type {
    margin-right: ${sizer(2)};
  }

  align-items: center;
`

export default function TitleGroup({
  titleText,
  titleToken,
  titleTokenArgs,
  TitleTailComponent,
  TitleComponent,
  descriptionText,
  descriptionToken,
  descriptionColor,
  descriptionTokenArgs,
  DescriptionComponent,
  SubheaderComponent,
  size,
  textWrap = true,
  textWrapLineLimit,
  wordWrap,
  width,
  color,
  overflowHidden,
  type,
}) {
  return (
    <StyledTitleGroup {...{ overflowHidden }}>
      <StyledTextRow>
        {titleText && (
          <StyledTextGroup>
            <Text bold {...{ textWrap, textWrapLineLimit, color, wordWrap, width, type }}>
              {titleText}
            </Text>
            {TitleTailComponent && <TitleTailComponent />}
          </StyledTextGroup>
        )}
        {titleToken && (
          <StyledTextGroup>
            <Text
              token={titleToken}
              tokenArgs={titleTokenArgs}
              bold
              {...{ textWrap, textWrapLineLimit, color, wordWrap, type }}
            />
            {TitleTailComponent && <TitleTailComponent />}
          </StyledTextGroup>
        )}
        {TitleComponent && <TitleComponent />}
      </StyledTextRow>
      {(descriptionText || descriptionToken || DescriptionComponent) && (
        <StyledTextRow>
          {descriptionText && (
            <Text {...{ textWrap, textWrapLineLimit, color: descriptionColor || color, size: "sm", wordWrap }}>
              {descriptionText}
            </Text>
          )}
          {descriptionToken && (
            <Text
              token={descriptionToken}
              tokenArgs={descriptionTokenArgs}
              {...{ textWrap, textWrapLineLimit, color, size: "sm", wordWrap }}
            />
          )}
          {DescriptionComponent && <DescriptionComponent />}
        </StyledTextRow>
      )}
      {SubheaderComponent && <SubheaderComponent />}
    </StyledTitleGroup>
  )
}

TitleGroup.propTypes = {
  titleText: PropTypes.string,
  titleToken: PropTypes.string,
  titleTokenArgs: PropTypes.any,
  TitleTailComponent: PropTypes.any,
  TitleComponent: PropTypes.any,
  descriptionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  ]),
  descriptionToken: PropTypes.string,
  descriptionTokenArgs: PropTypes.any,
  DescriptionComponent: PropTypes.any,
  SubheaderComponent: PropTypes.any,
  size: PropTypes.string,
  textWrap: PropTypes.bool,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(["headingL", "headingM", "headingS", "headingXs", "body", "bodyXs"]),
}
