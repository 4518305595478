import EditorRow from "../Editor/EditorRow"
import Input from "../Input"

export default function EditorInputRow({
  id,
  onChange,
  onBlur,
  value,
  placeholder,
  maxLength,
  errorMessage,
  type,
  readOnly,
  min,
  max,
  disabled,
  ariaLabel,
  ...rest
}) {
  return (
    <EditorRow {...rest}>
      <Input
        {...{
          id,
          readOnly,
          onChange,
          onBlur,
          value,
          placeholder,
          maxLength,
          errorMessage,
          type,
          min,
          max,
          disabled,
          ariaLabel,
        }}
      />
    </EditorRow>
  )
}
