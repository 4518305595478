import { Text, ConfirmationModal } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { useMountedState } from "js/includes/common/hooks"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { Box } from "js/includes/components/Styled"
import { localizationKey } from "js/includes/common/utils"

const ScriptsFromPolicyModal = ({ unmount, scripts, handleOnClick }) => {
  const [scriptToRun, setScriptToRun] = useMountedState(null)
  const scriptsWithAdditionalFields = scripts.map(script => ({
    ...script,
    value: script.actionsetScheduleName,
    label: script.actionsetScheduleName,
  }))

  return (
    <ConfirmationModal
      size="sm"
      unmount={unmount}
      cancelable
      actionToken="devices.runScript"
      titleToken="devices.runScriptFromPolicy"
      onConfirm={({ unmount }) => {
        handleOnClick(scriptToRun)
        unmount()
      }}
      disabled={!scriptToRun}
    >
      <Box marginBottom={sizer(1)}>
        <Text color="inputText" size="sm" token="general.script" />
      </Box>
      <SearchableDropDown
        width="100%"
        options={scriptsWithAdditionalFields}
        onSelect={value => {
          setScriptToRun(value)
        }}
        searchPlaceholderToken={localizationKey("Search Automations")}
        keepDropdownInView
      />
    </ConfirmationModal>
  )
}

export default ScriptsFromPolicyModal
