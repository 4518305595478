import { connect } from "react-redux"
import { HelpHoverTooltip } from "@ninjaone/components"
import Link from "./Link"

const HelpTooltip = ({
  articles,
  description,
  footerDescription,
  isBranded,
  triggerSize,
  helpSettingsEnabled,
  helpNinjaArticlesEnabled,
}) => {
  const showHelpNinjaArticles = isBranded ? helpSettingsEnabled && helpNinjaArticlesEnabled : true

  return showHelpNinjaArticles ? (
    <HelpHoverTooltip {...{ articles, triggerSize, description, footerDescription, Link }} />
  ) : null
}

export default connect(({ websiteBranding, application: { isBranded } }) => ({
  isBranded,
  helpSettingsEnabled: websiteBranding.brandingNode.general.helpSettings.enabled,
  helpNinjaArticlesEnabled: websiteBranding.brandingNode.general.helpSettings.ninjaArticlesEnabled,
}))(HelpTooltip)
