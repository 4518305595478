import NodeRolePicker from "js/includes/components/NodeRolePicker"
import OrganizationPicker from "js/includes/components/OrganizationLocationPicker/OrganizationPicker"
import LocationPicker from "js/includes/components/OrganizationLocationPicker/LocationPicker"

const AddDeviceOrganizationLocation = ({
  generatingInstaller,
  hideNodeRolePicker,
  installerNodeClasses,
  setOrganization,
  setLocation,
  setSelectedNodeRole,
  location,
  organization,
  selectedNodeRole,
  searchParams,
}) => {
  return (
    <div>
      <OrganizationPicker
        {...{
          changeOrganization: setOrganization,
          organization,
          disabled: generatingInstaller,
          openOnFocus: true,
          labelAbove: true,
          searchPlaceholderToken: "addDevices.selectOrganization",
          searchParams,
        }}
      />
      <LocationPicker
        {...{
          changeLocation: setLocation,
          organization,
          location,
          disabled: !organization?.id || generatingInstaller,
          labelAbove: true,
        }}
      />
      {!hideNodeRolePicker && (
        <NodeRolePicker
          nodeClasses={installerNodeClasses}
          selected={selectedNodeRole}
          setSelected={setSelectedNodeRole}
          disabled={generatingInstaller}
        />
      )}
    </div>
  )
}

export default AddDeviceOrganizationLocation
