import { clone, filter, where } from "ramda"
import FullModel from "./FullModel"
import { convertToServerFormat, convertToClientFormat, removeWebProperties } from "./common"
import {
  isFeatureEnabled,
  isLinuxDevice,
  isMacDevice,
  isNotNilOrEmpty,
  localized,
  showErrorMessage,
} from "js/includes/common/utils"
import { handlEditorSaveMfaException } from "js/includes/common/services/mfa"

export const DeviceModel = FullModel.extend(
  {
    urlRoot: "/node",
    parseParam: "node",
    enforceMfa: true,

    isUnhandledError(resultCode) {
      return !["MFA_REQUIRED", "INCORRECT_MFA_CODE"].includes(resultCode)
    },

    onSaveError({ responseJSON }) {
      if (!responseJSON || this.isUnhandledError(responseJSON.resultCode)) {
        showErrorMessage(localized("Error saving device"))
      }
    },

    parse(response) {
      if (response.resultCode === "SUCCESS") {
        const deviceJson = response[this.parseParam]

        deviceJson.policyContent = convertToClientFormat(deviceJson.policyContent)
        this.unsetFieldsThatAreNoLongerPresent(deviceJson)
        return deviceJson
      } else {
        this.unsetFieldsThatAreNoLongerPresent(response)

        return JSON.stringify(response)
      }
    },

    async save({ showSavingMessage, useMfaUrl, onMfaSubmit } = {}) {
      // Deep copy the JSON in order to prevent this model from transforming
      var deviceJson = clone(this.toJSON())

      if (deviceJson.policyContent?.actionsetSchedules) {
        deviceJson.policyContent.actionsetSchedules = filter(
          where({ actionsetScheduleName: isNotNilOrEmpty }),
          deviceJson.policyContent.actionsetSchedules,
        )
      }
      if (deviceJson.policyContent?.conditions) {
        deviceJson.policyContent.conditions = filter(
          where({ conditionUid: isNotNilOrEmpty }),
          deviceJson.policyContent.conditions,
        )
      }

      deviceJson.policyContent = convertToServerFormat(deviceJson.policyContent)

      // Remove web properties from JSON being sent to server
      removeWebProperties(deviceJson.policyContent)

      try {
        return await this.saveAsJson({
          json: JSON.stringify(deviceJson),
          showSavingMessage,
          useMfaUrl,
        })
      } catch (err) {
        if (err.responseJSON?.resultCode === "MFA_REQUIRED") {
          await handlEditorSaveMfaException({
            caller: this,
            err,
            onMfaSubmit: onMfaSubmit ?? (() => this.save({ showSavingMessage, useMfaUrl: true })),
          })
        }
      }
    },

    getName() {
      return this.get("displayName")
    },

    isAntivirusEnabled() {
      return (
        this.get("policyContent") &&
        this.get("policyContent").antivirus &&
        this.get("policyContent").antivirus.general &&
        (!!this.get("policyContent").antivirus.general.productCode ||
          (this.get("policyContent").antivirus.general.productCode === undefined &&
            this.get("policyContent").antivirus.general.enabled))
      )
    },

    getFormattedAvName() {
      const productCode = this?.attributes?.policyContent?.antivirus?.general?.productCode
      return (
        {
          WEBROOT: "Webroot",
          BITDEFENDER: "Bitdefender",
          GRAVITYZONE: "Bitdefender GravityZone",
          SENTINEL_ONE: "SentinelOne",
          CROWDSTRIKE: "CrowdStrike",
        }[productCode] || localized("Disabled")
      )
    },

    isPatchManagementEnabled() {
      return (
        this.get("policyContent") &&
        this.get("policyContent").patchManagement &&
        this.get("policyContent").patchManagement.general.enabled
      )
    },

    isMacOrLinuxPatchingAvailable() {
      return (
        (isLinuxDevice(this.get("nodeClass")) && isFeatureEnabled("linux_patching")) ||
        (isMacDevice(this.get("nodeClass")) && isFeatureEnabled("mac_os_patching"))
      )
    },

    isSoftwarePatchManagementEnabled() {
      return (
        this.get("policyContent") &&
        this.get("policyContent").softwarePatchManagement &&
        this.get("policyContent").softwarePatchManagement.general.enabled
      )
    },

    isPatchManagementControlEnabled() {
      return (
        this.get("policyContent") &&
        this.get("policyContent").patchManagement &&
        this.get("policyContent").patchManagement.general.enabled &&
        this.get("policyContent").patchManagement.controlPatchManagement.general.enabled
      )
    },

    isPatchManagementConfigureEnabled() {
      return (
        this.get("policyContent") &&
        this.get("policyContent").patchManagement &&
        this.get("policyContent").patchManagement.general.enabled &&
        this.get("policyContent").patchManagement.configureWindowsUpdate.general.enabled
      )
    },
  },
  {
    getModel(id) {
      return DeviceModel.findOrCreate({ id: id })
    },
  },
)
