import $ from "jquery"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const checkforchangesandclose = {
  routine: function(el, model) {
    var initialized = $(el).data("rivets.binders.checkforchangesandclose.initialized")

    if (!initialized) {
      var handler = async function(e) {
        if (model.get("mode") === "CREATE") {
          model.close()
        } else if (model.accept()) {
          if (model.compareDataContracts() && !model.hasChangesOutsideOfDataContract()) {
            model.close()
          } else {
            await manageUserConfirmationDialog()
          }
        } else {
          await manageUserConfirmationDialog(true)
        }
        e && e.preventDefault()
      }

      const checkEventType = $(el).data("check-event-type")
      if (checkEventType) {
        model.on(checkEventType, handler)
      } else {
        $(el)
          .off("click")
          .bind("click", handler)
      }
    }

    async function manageUserConfirmationDialog(isClosingWithErrors = false) {
      const buttonPressed = await ShowMessageDialog({
        icon: { icon: faEdit, type: "critical" },
        title: localizationKey("You have unsaved changes"),
        ...(isClosingWithErrors ? {} : { message: localizationKey("Would you like to save your changes?") }),
        buttons: [
          { id: "CANCEL", label: localizationKey("Cancel") },
          ...(isClosingWithErrors ? [] : [{ id: "SAVE", label: localizationKey("Save") }]),
          { id: "DISCARD", label: localizationKey("Discard"), type: "critical" },
        ],
      })
      switch (buttonPressed) {
        case "DISCARD":
          model.close()
          break
        case "SAVE":
          await model.save()
          model.close(buttonPressed)
          break
        default:
          break
      }
    }
  },
  bind: function(el) {
    $(el).data("rivets.binders.checkforchangesandclose.initialized", false)
    return true
  },

  unbind: function(el, model) {
    const checkEventType = $(el).data("check-event-type")
    if (!checkEventType) {
      $(el).off("click")
    }
    return true
  },
}

export default checkforchangesandclose
