import { memo } from "react"
import PropTypes from "prop-types"
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons"
import { localized, localizationKey } from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { StyledAnchor } from "js/includes/components/Styled"

export async function openExternalLinkDialog(url, showUrl, removeQueryString) {
  const buttonPressed = await ShowMessageDialog({
    icon: { icon: faExternalLink, type: "critical" },
    title: localizationKey("You are about to navigate away"),
    ...(showUrl && {
      MessageComponent: () => <p>{localized("URL: {{url}}", { url: removeQueryString ? url.split("?")[0] : url })}</p>,
    }),
    buttons: [
      { id: true, label: localizationKey("Continue") },
      { id: false, label: localizationKey("Cancel") },
    ],
  })
  buttonPressed && window.open(url, "_blank", "noopener noreferrer")
}

const ExternalLink = memo(({ url, labelToken, className, styles, children, titleToken, showUrl = true }) => {
  if (!url) return null

  function renderChildren() {
    if (children) {
      return children
    }
    return labelToken ? localized(labelToken) : url
  }

  return (
    <StyledAnchor
      {...{
        style: styles,
        className,
        href: url,
        title: titleToken ? localized(titleToken) : null,
        onMouseDown: e => {
          if (e.button === 1) {
            openExternalLinkDialog(url, showUrl)
          }
        },
        onClick: e => {
          e.preventDefault()
          e.stopPropagation()
          openExternalLinkDialog(url, showUrl)
        },
      }}
    >
      {renderChildren()}
    </StyledAnchor>
  )
})

export default ExternalLink

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  labelToken: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]),
  titleToken: PropTypes.string,
  showUrl: PropTypes.bool,
}
