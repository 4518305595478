import styled from "@emotion/styled"
import { faUser } from "@fortawesome/pro-regular-svg-icons"
import { Avatar, Dropdown, IconButton, Text } from "@ninjaone/components"
import { SignOutIconLight, UserCrownIconLight } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { localized, localizationKey, initials } from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import { Box, Flex } from "./Styled"
import LogoutModal from "./LogoutModal"

const StyledWrapper = styled(Flex)`
  cursor: pointer;
  padding: ${sizer(2, 4)};
  align-items: center;
  color: ${({ theme }) => theme.colorTextStrong};
  ${({ withBorderTop, theme }) => withBorderTop && `border-top: 1px solid ${theme.colorBorderWeak}`};
`

const UserInfoItem = ({ user }) => {
  const { firstName, lastName, email } = user
  return (
    <StyledWrapper>
      <Avatar {...{ avatar: initials(user) }} />
      <Box marginLeft={sizer(2)} maxWidth="250px">
        <Text size="sm" color="colorTextStrong" bold tooltipText={`${firstName} ${lastName}`}>
          {firstName} {lastName}
        </Text>
        <Text size="xs" color="colorTextWeakest" tooltipText={email}>
          {email}
        </Text>
      </Box>
    </StyledWrapper>
  )
}

const DropdownItem = ({ icon, token }) => {
  return (
    <StyledWrapper {...{ withBorderTop: true }}>
      <Flex width={28} alignItems="center" justifyContent="center">
        {icon}
      </Flex>
      <Box marginLeft={sizer(2)}>
        <Text {...{ token, size: "sm" }} />
      </Box>
    </StyledWrapper>
  )
}

const StyledGlobalActionDropdownContainer = styled.div`
  [aria-expanded="true"] {
    border-radius: ${sizer(1)};
    background: ${({ theme }) => theme.colorBackgroundWidget};
  }
`

const StyledPopoverContainer = styled.div`
  [data-ninja-dropdown-item] {
    padding: 0;
    margin: 0;

    &[data-highlighted] {
      background: ${({ theme }) => theme.colorForegroundHover};
    }
  }

  box-shadow: ${({ theme }) => theme.elevationWeak};
  border-radius: ${sizer(1)};
  min-width: 250px;
`

export const StyledGlobalActionDropdown = ({ ...props }) => {
  return (
    <StyledGlobalActionDropdownContainer>
      <Dropdown {...props} contentZIndex={1001} />
    </StyledGlobalActionDropdownContainer>
  )
}

const UserDropdown = () => {
  const user = window.application.get("user")
  const firstName = user.get("firstName")
  const lastName = user.get("lastName")
  const email = user.get("email")
  return (
    <StyledGlobalActionDropdown
      {...{
        portal: false,
        alignRight: true,
        childIsButton: true,
        PopoverContainer: StyledPopoverContainer,
        options: [
          {
            LabelComponent: () => <UserInfoItem {...{ user: { firstName, lastName, email } }} />,
            action: () => user.edit(),
            id: "edit",
          },
          ...(user.get("ninjaAdmin")
            ? [
                {
                  LabelComponent: () => (
                    <DropdownItem {...{ icon: <UserCrownIconLight />, token: localizationKey("Super Admin") }} />
                  ),
                  action: () => (window.location.href = `${window.location.origin}/superAdmin/`),
                  id: localizationKey("Super Admin"),
                },
              ]
            : []),
          {
            LabelComponent: () => (
              <DropdownItem {...{ icon: <SignOutIconLight />, token: localizationKey("Logout") }} />
            ),
            action: () => showModal(<LogoutModal />),
            id: localizationKey("Logout"),
          },
        ],
      }}
    >
      <IconButton
        {...{
          icon: faUser,
          tooltip: localized("User"),
          tooltipZIndex: 999,
          size: "lg",
        }}
      />
    </StyledGlobalActionDropdown>
  )
}

export default UserDropdown
