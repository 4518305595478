import $ from "jquery"

export function setupDataTableClickEvents(table) {
  $(table)
    .find("tbody:not(.prevent-expand) tr")
    .off("click")
    .click(function(e) {
      if (!$(e.target).closest("a[rel=popover]").length) {
        if ($(this).hasClass("expanded")) {
          $(this).removeClass("expanded")
        } else {
          $(this).addClass("expanded")
        }
      }
    })

  $(table)
    .find("tbody .dropdown-toggle")
    .off("click")
    .click(function(e) {
      var icon = $(e.target)
      var dropdownMenu = icon.parent().next()
      if (dropdownMenu.hasClass("dropdown-menu")) {
        var td = dropdownMenu.parent()
        if (td.is("td")) {
          dropdownMenu.css("top", td.position().top + icon.outerHeight())
          var widthDiff = dropdownMenu.outerWidth() - td.outerWidth()
          var leftMove = widthDiff > 0 ? widthDiff : 0
          dropdownMenu.css("left", td.position().left - leftMove)
        }
      }
    })

  $(table)
    .find("[rel=popover]")
    .popover()
}

export function destroyDataTableClickEvents(table) {
  $(table)
    .find("tbody tr")
    .off("click")
  $(table)
    .find("tbody .dropdown-toggle")
    .off("click")
}
