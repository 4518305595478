import $ from "jquery"
import rivets from "rivets"
import "jquery-ui/ui/widgets/spinner"

const incrementspinner = {
  routine: function(el, value) {
    var model = this.model
    var isDisabled = $(el).data("rivets.binders.inecrementspinner.isDisabled")
    if (!isDisabled) {
      $(el).data("rivets.binders.inecrementspinner.isDisabled", true)

      var initialized = $(el).data("rivets.binders.inecrementspinner.initialized")
      if (!initialized && model) {
        $(el)
          .spinner({
            step: model.get("step"),
            min: model.get("minValue"),
            max: model.get("maxValue"),
          })
          .val(value)
        $(el).data("rivets.binders.inecrementspinner.initialized", true)
      } else {
        $(el)
          .spinner()
          .val(value)
      }
      $(el).data("rivets.binders.inecrementspinner.isDisabled", false)
    }
  },

  bind: function(el) {
    var adapter = rivets.adapters[":"]
    var self = this
    this.callback = function(e) {
      var isDisabled = $(el).data("rivets.binders.inecrementspinner.isDisabled")
      if (!isDisabled) {
        $(el).data("rivets.binders.inecrementspinner.isDisabled", true)
        var paths = self.keypath.split(":")
        var spinnerValue = $(el).spinner("value")
        adapter.set(
          self.model,
          paths[paths.length - 1],
          spinnerValue == null || ~~spinnerValue !== spinnerValue ? "Invalid" : $(el).spinner("value"),
        )
        $(el).data("rivets.binders.inecrementspinner.isDisabled", false)
      }
    }
    $(el).data("rivets.binders.inecrementspinner.initialized", false)
    $(el).spinner({
      change: this.callback,
    })
    return true
  },

  unbind: function(el) {
    $(el).spinner({
      change: null,
    })
    $(el).spinner("destroy")
    return true
  },
}

export default incrementspinner
