import styled from "@emotion/styled"
import { Item } from "@radix-ui/react-radio-group"

import RadioIndicator from "./RadioIndicator"
import { getRadioCircleStyles } from "./utils"

const StyledItem = styled(Item)`
  all: unset;

  ${({ theme }) => getRadioCircleStyles({ theme })}

  &:focus {
    outline-offset: -0.5px;
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

function RadioItem({ value, disabled, ariaLabel }) {
  return (
    <StyledItem {...{ "aria-label": ariaLabel, id: value, value, disabled }}>
      <RadioIndicator />
    </StyledItem>
  )
}

export default RadioItem
