import $ from "jquery"
import rivets from "rivets"
import "ion-rangeslider"

const jqueryslider = {
  routine: function(el, value) {
    var model = this.model
    var isDisabled = $(el).data("rivets.binders.jqueryslider.isDisabled")
    if (!isDisabled) {
      $(el).data("rivets.binders.jqueryslider.isDisabled", true)

      var initialized = $(el).data("rivets.binders.jqueryslider.initialized")
      if (!initialized) {
        $(el).ionRangeSlider({
          type: "single",
          min: model.get("minValue"),
          max: model.get("maxValue"),
          from: value,
        })
        $(el).data("rivets.binders.jqueryslider.initialized", true)
      }
      $(el).data("rivets.binders.jqueryslider.isDisabled", false)
    }
  },

  bind: function(el) {
    var adapter = rivets.adapters[":"]
    var self = this
    this.callback = function(e) {
      var isDisabled = $(el).data("rivets.binders.jqueryslider.isDisabled")
      if (!isDisabled) {
        $(el).data("rivets.binders.jqueryslider.isDisabled", true)
        var paths = self.keypath.split(":")
        var $this = $(this),
          value = $this.prop("value")
        adapter.set(self.model, paths[paths.length - 1], value == null ? "Invalid" : +value)
        $(el).data("rivets.binders.jqueryslider.isDisabled", false)
      }
    }
    $(el).data("rivets.binders.jqueryslider.initialized", false)
    $(el).on("change", this.callback)
    return true
  },

  unbind: function(el) {
    $(el).off("change")
    var ionRangeSlider = $(el).data("ionRangeSlider")
    if (ionRangeSlider) {
      ionRangeSlider.destroy()
    }
    return true
  },
}

export default jqueryslider
