import moment from "moment"
import PropTypes from "prop-types"
import { mergeDeepRight } from "ramda"
import { Select, DatePicker, TimePicker } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Flex, Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"
import { getUpdatedDateTimeComponents } from "./dateUtils"

const options = [
  {
    labelToken: localizationKey("Now"),
    value: "NOW",
  },
  {
    labelToken: localizationKey("Custom date and time"),
    value: "AT",
  },
]

const StartShedule = ({ schedule: { start }, validation, updateStart }) => {
  const changeStartType = type => {
    const change = mergeDeepRight(start, { type })
    updateStart(change)
  }

  const changeStartDateTime = (currentUpdateDateTime, updateType) => {
    const updatedDateTimeComponents = getUpdatedDateTimeComponents({
      currentUpdateDateTime,
      currentDateTime: new Date(start.datetime),
      updateType,
    })
    const updatedDateTimeObj = new Date(...updatedDateTimeComponents)
    updateStart(mergeDeepRight(start, { datetime: moment(updatedDateTimeObj).valueOf() }))
  }

  return (
    <Flex flexDirection="column">
      <Select
        value={start?.type}
        labelId="maintenance-start-select"
        labelText={localized("Start")}
        options={options}
        onChange={changeStartType}
      />
      {start?.type === "AT" && (
        <Flex justifyContent="space-between" gap={tokens.spacing[2]} width="100%" marginTop={tokens.spacing[2]}>
          <Box flex="1">
            <DatePicker
              labelText={localized("Start date")}
              selectedDays={new Date(start?.datetime)}
              disabledDays={{
                before: moment().toDate(),
              }}
              onDayChange={({ date }) => {
                changeStartDateTime(date, "date")
              }}
              useDropdownNavigation
              disableClear
              fullWidth
              readOnly
              // errorMessage={validation?.message?.start} <--- TODO: unmuting it when Datepicker is fixed
            />
          </Box>
          <Box flex="1">
            <TimePicker
              value={new Date(start?.datetime)}
              placeholder={localized("Select start time")}
              labelText={localized("Start time")}
              onTimeChange={date => changeStartDateTime(date, "time")}
              disableClear
              useTimezone
              errorMessage={validation?.message?.start}
            />
          </Box>
        </Flex>
      )}
    </Flex>
  )
}

StartShedule.propTypes = {
  schedule: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  updateStart: PropTypes.func.isRequired,
}

export default StartShedule
