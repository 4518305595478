import { Button, Text } from "@ninjaone/components"
import { DownloadIcon, LinkIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { clipboardWriteText } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"

const InstallerButtonRow = ({ url }) => {
  return (
    <Flex width="100%" justifyContent="flex-end">
      <Button
        variant="secondary"
        id="installer-copy-button"
        data-clipboard-text={url}
        onClick={() => clipboardWriteText(url)}
      >
        <Box marginRight={sizer(1)} marginTop="2px">
          <LinkIcon />
        </Box>
        <Text lineHeight="initial" token="general.copyLink" size="sm" />
      </Button>
      <Box marginLeft={sizer(3)}>
        <Button variant="primary" id="installer-download-button" onClick={() => window.open(url, "_self")}>
          <Box marginRight={sizer(1)} marginTop="2px">
            <DownloadIcon />
          </Box>
          <Text lineHeight="initial" token="general.download" size="sm" />
        </Button>
      </Box>
    </Flex>
  )
}

export default InstallerButtonRow
