import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { mergeDeepRight } from "ramda"
import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { startOptions, endOptions } from "./scheduleDataDefaults"
import StartSchedule from "./StartSchedule"
import EndSchedule from "./EndSchedule"

const ScheduleStyled = styled.div`
  display: flex;
  flex-flow: column;
  overflow: visible;
  margin-bottom: ${tokens.spacing[2]};
`

const Schedule = ({ schedule, validation, onScheduleUpdate }) => {
  const updateStart = start => {
    const startDateTime = moment(start.datetime)
    const endDateTime = moment(schedule.end.datetime)
    if (startDateTime.isAfter(endDateTime)) {
      onScheduleUpdate({
        ...schedule,
        start,
        end: mergeDeepRight(schedule.end, {
          datetime: startDateTime.add(6, "minutes").valueOf(),
        }),
      })
    } else {
      onScheduleUpdate({
        ...schedule,
        start: mergeDeepRight(start, {
          datetime: start.datetime,
        }),
      })
    }
  }

  const updateEnd = end => {
    onScheduleUpdate({
      ...schedule,
      end: mergeDeepRight(end, {
        datetime: end.datetime,
      }),
    })
  }

  return (
    <ScheduleStyled>
      <StartSchedule
        {...{
          schedule,
          updateStart,
          validation,
          options: startOptions,
        }}
      />
      <EndSchedule
        {...{
          schedule,
          updateEnd,
          validation,
          options: endOptions,
        }}
      />
    </ScheduleStyled>
  )
}

Schedule.propTypes = {
  validation: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired,
  onScheduleUpdate: PropTypes.func.isRequired,
}

export default Schedule
