import { fetchJson, getVersionHash } from "js/includes/common/utils"

export const getAvailableOidsToImport = () => fetchJson(`/custom-snmp/config?cacheKey=${getVersionHash()}`)

export const importOids = oidConfigs =>
  fetchJson("/snmpoid/bulk", {
    options: {
      method: "POST",
      body: JSON.stringify(oidConfigs),
    },
  })

export const getImportedOids = () => fetchJson("/snmpoid/list")

export const addOid = oidConfig =>
  fetchJson("/snmpoid", {
    options: {
      method: "POST",
      body: JSON.stringify(oidConfig),
    },
  })

export const editOid = ({ oid, ...rest }) =>
  fetchJson(`/snmpoid/${oid}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(rest),
    },
  })

export const deleteOid = oid =>
  fetchJson(`/snmpoid/${oid}`, {
    options: {
      method: "DELETE",
    },
  })
