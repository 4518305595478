import { createSelector } from "reselect"
import { compose, map, propEq, filter, findIndex, move } from "ramda"
import { filterByPropContainsCaseInsensitive, sortByFieldNameCaseInsensitive, user } from "js/includes/common/utils"

const isUncategorized = propEq("id", 1)
const userCanRunScript = category =>
  user("canRunScripts", [category.id]) || user("getRunOnlyScriptCategories").includes(category.id)

export const getScriptCategories = createSelector([({ scripting }) => scripting.scriptCategories], scriptCategories => {
  const categories = compose(sortByFieldNameCaseInsensitive("name", "ASC"), filter(userCanRunScript))(scriptCategories)
  const uncategorizedIndex = findIndex(isUncategorized, categories)
  return uncategorizedIndex > 0 ? move(uncategorizedIndex, 0, categories) : categories
})

export const getFiltererdScriptCategories = createSelector(
  [
    getScriptCategories,
    ({
      configuration: {
        scripting: { scriptCategoriesList },
      },
    }) => scriptCategoriesList.scriptCategoryNameFilter,
    ({
      configuration: {
        scripting: { scriptCategoriesList },
      },
    }) => scriptCategoriesList.sort.sortBy,
    ({
      configuration: {
        scripting: { scriptCategoriesList },
      },
    }) => scriptCategoriesList.sort.sortDirection,
  ],
  (scriptCategories, scriptCategoryNameFilter, sortBy, sortDirection) =>
    compose(
      sortByFieldNameCaseInsensitive(sortBy, sortDirection),
      filterByPropContainsCaseInsensitive(sortBy, scriptCategoryNameFilter),
    )(scriptCategories),
)

export const getScriptCategoriesSelectOptions = compose(
  map(({ id, name }) => ({ value: id.toString(), labelText: name })),
  getScriptCategories,
)
