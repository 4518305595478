import { memo, useCallback, useEffect, useMemo, useRef } from "react"
import { connect, batch } from "react-redux"
import styled from "@emotion/styled"
import { Tooltip } from "@ninjaone/components"
import { useMountedState } from "@ninjaone/utils"
import {
  debounce,
  isBackspaceKey,
  isEnterKey,
  isEscapeKey,
  localizationKey,
  localized,
  localizedWith,
  ninjaReportError,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { GLOBAL_SEARCH_WIDTH } from "js/includes/application/constants"
import OutsideClickAlerter from "js/includes/components/OutsideClickAlerter"
import { getGlobalSearchRecentResults, saveGlobalSearchRecentResult } from "js/includes/common/client/qsearch"
import {
  setGlobalSearchPageQuery as _setGlobalSearchPageQuery,
  resetGlobalSearchPageResults as _resetGlobalSearchPageResults,
  setGlobalSearchPersistedResults as _setGlobalSearchPersistedResults,
  setGlobalSearchPersistedQuery as _setGlobalSearchPersistedQuery,
} from "js/state/actions/globalSearch"
import {
  defaultFilterOptionValue,
  StyledContainer,
  StyledDivider,
  StyledInput,
  StyledInputContainer,
  SearchIcon,
  debounceTimeout,
  minimumLoaderTime,
} from "./common"
import {
  getFilterLabel,
  getFilterFromInput,
  getGlobalSearchResults,
  getGlobalSearchPageUrl,
  getInputHasActualValue,
  getRecentResultsWithUpToDateDevices,
  reduceResultData,
} from "./utils"
import GlobalSearchPopover from "./GlobalSearchPopover"
import GlobalSearchRecentsPopover from "./GlobalSearchRecentsPopover"
import GlobalSearchFilterTag from "./GlobalSearchFilterTag"
import GlobalSearchFilterSelect from "./GlobalSearchFilterSelect"
import GlobalSearchClearButton from "./GlobalSearchClearButton"

const StyledWrapper = styled.div`
  position: relative;
  min-width: 437px;
  max-width: ${GLOBAL_SEARCH_WIDTH}px;
  z-index: 2000;
`

const StyledAbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
`

const GlobalSearch = memo(
  ({
    searchValueFromQuery,
    searchFilterFromQuery,
    persistedValue,
    persistedFilter,
    pageValue,
    pageFilter,
    persistedExactResults,
    persistedSuggestedResults,
    setGlobalSearchPageQuery,
    resetGlobalSearchPageResults,
    setGlobalSearchPersistedResults,
    setGlobalSearchPersistedQuery,
    isOnGlobalSearchPage,
    isOnEditorPage,
    showGlobalSearchGuideTooltip,
    administrationPages,
  }) => {
    const [isSearchBarPersisted, setIsSearchBarPersisted] = useMountedState(false)
    const searchBarTakesYouToResultsPage = isSearchBarPersisted && !isOnGlobalSearchPage
    const [inputValue, setInputValue] = useMountedState("")
    const valueToUse = searchBarTakesYouToResultsPage ? persistedValue : inputValue
    const [filter, setFilter] = useMountedState(defaultFilterOptionValue)
    const filterToUse = isSearchBarPersisted ? persistedFilter : filter
    const [exactResults, setExactResults] = useMountedState([])
    const exactResultsToUse = isSearchBarPersisted ? persistedExactResults : exactResults
    const [suggestedResults, setSuggestedResults] = useMountedState([])
    const suggestedResultsToUse = isSearchBarPersisted ? persistedSuggestedResults : suggestedResults
    const [recentResults, setRecentResults] = useMountedState([])
    const [showPopover, setShowPopover] = useMountedState(false)
    const [isLoading, setIsLoading] = useMountedState(false)
    const [showFocusRing, setShowFocusRing] = useMountedState(false)
    const [showError, setShowError] = useMountedState(false)

    const inputValueRef = useRef()
    const containerRef = useRef()
    const inputRef = useRef()

    const isFilterVisible = !!filterToUse && filterToUse !== defaultFilterOptionValue
    const showRecentPopover = !!recentResults.length && showFocusRing && inputValue.length <= 0
    const isMinimized = (inputValue || isFilterVisible) && !showPopover && !showFocusRing
    const showClearButton = inputValue || isFilterVisible || searchBarTakesYouToResultsPage
    const showMainTooltip = !showFocusRing && !showPopover && !showRecentPopover
    const inputHasActualValue = getInputHasActualValue(inputValue)

    const handleHideLoader = useCallback(
      (delayHideLoader, timeElapsed) => {
        if (delayHideLoader && timeElapsed < minimumLoaderTime) {
          setTimeout(() => {
            setIsLoading(false)
          }, minimumLoaderTime - timeElapsed)
        } else {
          setIsLoading(false)
        }
      },
      [setIsLoading],
    )

    const loadSearchResults = useCallback(
      async (searchValue, filter, delayHideLoader) => {
        const startTime = Date.now()
        try {
          const { exact, suggested } = await getGlobalSearchResults({
            query: searchValue,
            filter,
            limit: 50,
            administrationPages,
          })
          const searchIsOutdated = searchValue !== inputValueRef.current

          if (searchIsOutdated) return

          if (!exact || !suggested) {
            throw new Error("Invalid data for exact and suggested results")
          }

          setExactResults(exact)
          setSuggestedResults(suggested)
          handleHideLoader(delayHideLoader, Date.now() - startTime)
        } catch (error) {
          setShowError(true)
          ninjaReportError(error)
          handleHideLoader(delayHideLoader, Date.now() - startTime)
        }
      },
      [administrationPages, handleHideLoader, setExactResults, setShowError, setSuggestedResults],
    )

    const loadDebouncedSearchResults = useMemo(() => debounce(loadSearchResults, debounceTimeout), [loadSearchResults])

    const handleInputOnChange = ({ target }) => {
      const newInputValue = target.value

      setShowError(false)
      setInputValue(newInputValue)
      inputValueRef.current = newInputValue

      if (!getInputHasActualValue(newInputValue)) {
        setExactResults([])
        setSuggestedResults([])
        setShowPopover(false)
        return
      }

      setIsLoading(true)

      if (isSearchBarPersisted) {
        setIsSearchBarPersisted(false)

        // sync up filter + results
        setFilter(persistedFilter)
        loadSearchResults(newInputValue, persistedFilter, true)

        return
      }

      const filterFromInput = getFilterFromInput(newInputValue)

      if (filterFromInput) {
        setFilter(filterFromInput)
        setInputValue("")
        inputValueRef.current = ""
        setIsLoading(false)
        setExactResults([])
        setSuggestedResults([])
        setShowPopover(false)

        return
      }

      if (newInputValue) {
        loadDebouncedSearchResults(newInputValue, filter)
        setShowPopover(true)
      } else {
        setIsLoading(false)
        setExactResults([])
        setSuggestedResults([])
        setShowPopover(false)
      }
    }

    const handleInputOnKeyDown = event => {
      const userTryingToHideFilterTag = inputValue === "" && isFilterVisible && isBackspaceKey(event)

      if (userTryingToHideFilterTag) {
        setFilter(defaultFilterOptionValue)
        setIsSearchBarPersisted(false)
      }

      if (isEnterKey(event) && (exactResults.length || suggestedResults.length)) {
        batch(() => {
          setGlobalSearchPersistedResults(exactResults, suggestedResults)
          setGlobalSearchPersistedQuery(inputValue, filter)
          resetGlobalSearchPageResults()
          setGlobalSearchPageQuery(inputValue, filter)
        })

        window.location.href = getGlobalSearchPageUrl({ searchValue: inputValue, searchFilter: filter })
      }
    }

    const closePopover = () => {
      setShowPopover(false)
      setShowFocusRing(false)
    }

    const focusOnInput = () => {
      inputRef.current?.focus()
    }

    const fetchRecentSearches = useCallback(async () => {
      try {
        const recentResults = await getGlobalSearchRecentResults()
        const recentResultsWithUpToDateDevices = await getRecentResultsWithUpToDateDevices(recentResults)
        const mappedRecentResults = reduceResultData(recentResultsWithUpToDateDevices)

        setRecentResults(mappedRecentResults)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Unable to get recent global searches"))
      }
    }, [setRecentResults])

    const addRecentResult = async result => {
      try {
        const { id, name, type, description, details, route } = result

        await saveGlobalSearchRecentResult({
          id,
          name,
          type,
          description,
          ...(type !== "DEVICE" && {
            details: type === "ADMINISTRATION" ? { route } : details,
          }),
        })
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Unable to add recent global search"))
      }
    }

    const resetSearch = focusesOnInput => {
      setFilter(defaultFilterOptionValue)
      setInputValue("")
      setIsLoading(false)
      setExactResults([])
      setSuggestedResults([])
      setShowError(false)
      setShowPopover(false)
      setIsSearchBarPersisted(false)

      if (focusesOnInput) {
        focusOnInput()
      }
    }

    const filterLabel = getFilterLabel(filterToUse)
    const activeFilterIsMissing = !filterLabel && isFilterVisible

    if (activeFilterIsMissing) {
      resetSearch()
    }

    useEffect(() => {
      if (showFocusRing) {
        fetchRecentSearches()
      } else {
        setRecentResults([])
      }
    }, [showFocusRing, fetchRecentSearches, setRecentResults])

    useEffect(() => {
      const enablePersistedBar = isOnGlobalSearchPage && inputValue === persistedValue && filter === persistedFilter

      if (enablePersistedBar) {
        setIsSearchBarPersisted(true)
      }
    }, [filter, inputValue, isOnGlobalSearchPage, persistedFilter, persistedValue, setIsSearchBarPersisted])

    useEffect(() => {
      const isSearchFromURLQueryParams =
        isOnGlobalSearchPage && !inputValueRef.current && searchValueFromQuery && searchFilterFromQuery

      if (isSearchFromURLQueryParams) {
        setIsSearchBarPersisted(true)
        setInputValue(searchValueFromQuery)
        inputValueRef.current = searchValueFromQuery
      }
    }, [searchFilterFromQuery, isOnGlobalSearchPage, searchValueFromQuery, setInputValue, setIsSearchBarPersisted])

    useEffect(() => {
      const detectKeyboardShortcut = event => {
        if (event.ctrlKey || event.metaKey) return

        if (event.key === "/") {
          const eventCameFromDialog =
            event.target.getAttribute("role") === "dialog" || !!event.target.closest("[role=dialog]")

          const eventCameFromTypeableTarget =
            /textarea|select|input/i.test(event.target.nodeName) ||
            /textbox|combobox|searchbox|input|select/i.test(event.target.role)

          if (eventCameFromDialog || eventCameFromTypeableTarget) return

          if (!eventCameFromTypeableTarget && !searchBarTakesYouToResultsPage) {
            event.preventDefault()

            setShowFocusRing(true)
            focusOnInput()

            if (isSearchBarPersisted || isMinimized) {
              setShowPopover(true)
            }
          }
        }
      }

      if (isOnEditorPage) return

      window.addEventListener("keydown", detectKeyboardShortcut)

      return () => {
        window.removeEventListener("keydown", detectKeyboardShortcut)
      }
    }, [
      isMinimized,
      isSearchBarPersisted,
      isOnEditorPage,
      searchBarTakesYouToResultsPage,
      setShowFocusRing,
      setShowPopover,
    ])

    return (
      <StyledWrapper>
        <StyledAbsoluteWrapper>
          <OutsideClickAlerter
            useKeydown={false}
            handleClickOutside={() => {
              if (showPopover || showRecentPopover) {
                closePopover()
              } else {
                setShowFocusRing(false)
              }
            }}
            useClick
          >
            <StyledContainer
              ref={containerRef}
              onKeyDown={event => {
                const eventCameFromSelect = event.target.hasAttribute("data-ninja-select-item")
                if (isEscapeKey(event) && !eventCameFromSelect) {
                  closePopover()
                  inputRef.current.blur()
                }
              }}
              onBlur={({ relatedTarget }) => {
                if (!relatedTarget) return
                const isFocusedOutside =
                  !relatedTarget.hasAttribute?.("data-ninja-hover-dropdown-item") &&
                  !relatedTarget.hasAttribute?.("data-ninja-select-item") &&
                  !relatedTarget.hasAttribute?.("data-ninja-select-trigger") &&
                  !containerRef.current.contains(relatedTarget)
                if (isFocusedOutside) {
                  closePopover()
                }
              }}
            >
              <StyledInputContainer
                {...{
                  "data-global-search-input-container": "",
                  onClick: event => {
                    event.stopPropagation()

                    if (searchBarTakesYouToResultsPage) {
                      window.location.href = getGlobalSearchPageUrl({
                        searchValue: pageValue,
                        searchFilter: pageFilter,
                      })
                      return
                    }

                    focusOnInput()

                    if (inputHasActualValue) {
                      setShowPopover(true)
                      setShowFocusRing(true)
                    }
                  },
                  onMouseDown: event => {
                    event.preventDefault() // prevent blur on input if user clicks
                  },
                  "data-testid": "global-search-input-container",
                  ...(showFocusRing && { "data-active": true }),
                  ...((isSearchBarPersisted || isMinimized) && { "data-persisted": true }),
                }}
              >
                {showMainTooltip && (
                  <Tooltip
                    labelRenderer={() => (
                      <>
                        {searchBarTakesYouToResultsPage
                          ? localized("Go back to search results")
                          : localizedWith("Press <%shortcut>/<%> for shortcut", {
                              shortcut: ({ localizedText }) => <em>{localizedText}</em>,
                            })}
                      </>
                    )}
                    className="main-tooltip"
                    portal={false}
                    align="center"
                  />
                )}
                <SearchIcon />
                {isFilterVisible && (
                  <GlobalSearchFilterTag
                    label={filterLabel}
                    onClose={() => {
                      setFilter(defaultFilterOptionValue)
                      setIsSearchBarPersisted(false)

                      if (inputHasActualValue) {
                        setIsLoading(true)
                        loadSearchResults(inputValue, defaultFilterOptionValue, true)
                      }
                    }}
                  />
                )}
                <StyledInput
                  aria-label={localized("Search")}
                  ref={inputRef}
                  type="text"
                  onKeyDown={handleInputOnKeyDown}
                  onMouseDown={event => {
                    event.stopPropagation()
                  }}
                  maxLength={1024}
                  placeholder={localized("Search")}
                  value={valueToUse}
                  onChange={handleInputOnChange}
                  onFocus={() => {
                    setShowFocusRing(true)
                  }}
                  onBlur={event => {
                    const { relatedTarget } = event
                    const clickedSelect =
                      relatedTarget?.hasAttribute?.("data-ninja-select-item") ||
                      relatedTarget?.hasAttribute?.("data-ninja-select-trigger")
                    if (!showPopover && !showRecentPopover && !clickedSelect) {
                      setShowFocusRing(false)
                    }
                  }}
                  {...(searchBarTakesYouToResultsPage && { disabled: true })}
                />
                {showFocusRing && (
                  <GlobalSearchFilterSelect
                    {...{
                      onChange: filterValue => {
                        setFilter(filterValue)

                        if (inputHasActualValue) {
                          setIsLoading(true)

                          if (isSearchBarPersisted) {
                            setIsSearchBarPersisted(false)
                          }

                          loadSearchResults(inputValue, filterValue)
                        }
                        setTimeout(() => {
                          focusOnInput()
                        }, 0)
                      },
                      onBlur: event => {
                        if (!showPopover && !event.relatedTarget) {
                          setShowFocusRing(false)
                        }
                      },
                      filter: filterToUse,
                    }}
                  />
                )}
                {showFocusRing && showClearButton && <StyledDivider />}
                {showClearButton && (
                  <GlobalSearchClearButton
                    onClick={event => {
                      event.stopPropagation()
                      resetSearch()
                    }}
                  />
                )}
              </StyledInputContainer>
              {showRecentPopover ? (
                <GlobalSearchRecentsPopover {...{ recentResults, closePopover, addRecentResult }} />
              ) : (
                showPopover && (
                  <GlobalSearchPopover
                    {...{
                      searchValue: inputValue,
                      exactResults: exactResultsToUse,
                      suggestedResults: suggestedResultsToUse,
                      isLoading,
                      filter: filterToUse,
                      showError,
                      addRecentResult,
                      closePopover,
                      resetSearch,
                    }}
                  />
                )
              )}
            </StyledContainer>
          </OutsideClickAlerter>
        </StyledAbsoluteWrapper>
      </StyledWrapper>
    )
  },
)

export default connect(
  ({ globalSearch, session, general: { guideTooltips } }) => ({
    persistedValue: globalSearch.persistedValue,
    persistedFilter: globalSearch.persistedFilter,
    pageValue: globalSearch.pageValue,
    pageFilter: globalSearch.pageFilter,
    persistedExactResults: globalSearch.persistedExactResults,
    persistedSuggestedResults: globalSearch.persistedSuggestedResults,
    showGlobalSearchGuideTooltip: guideTooltips.globalSearch,
  }),
  {
    setGlobalSearchPageQuery: _setGlobalSearchPageQuery,
    resetGlobalSearchPageResults: _resetGlobalSearchPageResults,
    setGlobalSearchPersistedResults: _setGlobalSearchPersistedResults,
    setGlobalSearchPersistedQuery: _setGlobalSearchPersistedQuery,
  },
)(GlobalSearch)
