import { createSelector } from "reselect"

export const getParameterModalScript = createSelector(
  [({ scripting }) => scripting.scripts, ({ policyEditor: { scripting } }) => scripting.parameterModal],
  (scripts, parameterModal) => {
    if (!parameterModal) return undefined

    const parameterModalScript = scripts.find(s => (s.language === "native" ? s.uid : s.id) === parameterModal.scriptId)

    return (
      parameterModalScript && {
        ...parameterModal,
        name: parameterModalScript.name,
        scriptName: parameterModalScript.scriptName,
      }
    )
  },
)
