import { groupBy, prop } from "ramda"
import pathParse from "path-parse"
import {
  localizationKey,
  localized,
  validations,
  reportErrorAndShowMessage,
  isNilOrEmpty,
} from "js/includes/common/utils"
import { toMB } from "js/includes/common/_conversions"
import { fetchResources } from "js/includes/components/scripting/utils"
import { getAllAutomationsUnderReview } from "js/includes/common/client/automation"
import { saveScanAttachmentAutomation, uploadFileAttachmentAutomation } from "js/state/actions/scripting/scripts"

export const INSTALLER_FILE = "FILE"
export const INSTALLER_URL = "URL"
export const ARCHITECTURE_ALL = "ALL"
export const ARCHITECTURE_64 = "64"
export const ARCHITECTURE_32 = "32"

export const getInstallerOptions = () => [
  {
    labelToken: localizationKey("Upload File"),
    value: INSTALLER_FILE,
  },
  {
    labelToken: localizationKey("URL"),
    value: INSTALLER_URL,
  },
]

export const getArchitectureOptions = () => [
  {
    labelToken: "general.all",
    value: ARCHITECTURE_ALL,
  },
  {
    labelToken: "general.sixtyFourBit",
    value: ARCHITECTURE_64,
  },
  {
    labelToken: "general.thirtyTwoBit",
    value: ARCHITECTURE_32,
  },
]

export const WINDOWS_OS = "Windows"
export const MAC_OS = "Mac"
export const getOSOptions = () => [
  {
    labelText: WINDOWS_OS,
    value: WINDOWS_OS,
  },
  {
    labelText: MAC_OS,
    value: MAC_OS,
  },
]

export const fileExtensions = {
  Windows: ".exe, .msi, .msix",
  Mac: ".dmg, .pkg",
}

const MAX_FILE_SIZE_MB = 1000

export const validateSizeFile = (fileSize, maxFileSizeMb = MAX_FILE_SIZE_MB) => {
  const fileSizeMB = toMB(fileSize)
  const maxFile = maxFileSizeMb >= MAX_FILE_SIZE_MB ? maxFileSizeMb / MAX_FILE_SIZE_MB : maxFileSizeMb
  const unit = maxFileSizeMb >= MAX_FILE_SIZE_MB ? "GB" : "MB"

  if (parseInt(fileSize) <= 0) {
    return {
      success: false,
      message: localizationKey("File too small. File size must be more than 1 byte"),
    }
  }

  if (fileSizeMB > maxFileSizeMb) {
    return {
      success: false,
      message: `${localized("general.fileExceedsLimit")} ${maxFile} ${unit}`,
    }
  }

  return { success: true, message: "" }
}

export const validateFileExtension = (filename = "", os) => {
  const { ext } = pathParse(filename) || {}
  const isValidExtension = fileExtensions[os].split(", ").includes(ext?.toLowerCase())

  return {
    success: isValidExtension,
    message: isValidExtension
      ? ""
      : localized(
          "You've attempted to attach an invalid file. Only the following file types are allowed: {{extensions}}",
          { extensions: fileExtensions[os] ?? "" },
        ),
  }
}

export const validateFile = ({ file, os }) => {
  if (!file) {
    return { success: false }
  }

  const validateSize = validateSizeFile(file.size)
  if (!validateSize.success) {
    return validateSize
  }
  return validateFileExtension(file?.name, os)
}

export const installApplicationValidations = uploadType => {
  return {
    name: validations.required,
    ...(uploadType === INSTALLER_URL
      ? { url: value => validations.installApplicationUrlAutomation(value) }
      : {
          file: file => {
            if (isNilOrEmpty(file)) {
              return {
                success: false,
                message: localized("administration.library.automation.installApplication.fileRequired"),
              }
            }
            return { success: true, message: "" }
          },
        }),
  }
}

export const fetchAutomationsResource = async ({
  requestAllScriptCategories,
  requestAllScripts,
  setAutomationsUnderReview,
}) => {
  const fetchAutomationsErrorKey = localizationKey("Error fetching automations")
  const [_automationsUnderReview = []] = await fetchResources({
    requestResources: [getAllAutomationsUnderReview, requestAllScriptCategories, requestAllScripts],
    errorKey: fetchAutomationsErrorKey,
  })

  const automationsUnderReviewFetchResults = groupBy(prop("status"), _automationsUnderReview)
  setAutomationsUnderReview(automationsUnderReviewFetchResults?.fulfilled?.flatMap(({ value }) => value) || [])

  if (automationsUnderReviewFetchResults?.rejected) {
    reportErrorAndShowMessage(automationsUnderReviewFetchResults.rejected[0].reason, fetchAutomationsErrorKey)
  }
}

export const uploadFile = async (fileData, setProgress, setSuccess) => {
  if (!fileData) {
    return null
  }
  const { file, metadata, id } = fileData

  if (id) return id

  try {
    const { xhr } = await uploadFileAttachmentAutomation(
      file,
      progress => {
        if (!setProgress) return

        setProgress(Math.round(progress), fileData.key)
      },
      async response => {
        const data = {
          name: metadata.name,
          mimeType: metadata.mimeType,
          size: file.size,
          extension: metadata.extension,
        }

        const id = await saveScanAttachmentAutomation(response.resourceId, data)
        setSuccess(id, fileData.key)
      },
    )

    return xhr
  } catch (error) {
    throw error
  }
}
