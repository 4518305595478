import PropTypes from "prop-types"
import { Modal, TitleGroup, Input, InlineWarning } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { Box } from "js/includes/components/Styled"
import { validations, localized, applyMultipleValidations } from "js/includes/common/utils"
import { useForm, useMountedState } from "js/includes/common/hooks"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export default function NameModal({ title, onSubmit, initialValues, unmount, warningLabelToken, maxLength }) {
  const [isSaving, setIsSaving] = useMountedState(false)

  const { values, validateForm, validation, onChange } = useForm({
    fields: {
      name: initialValues?.name || "",
    },
    validate: {
      name: maxLength
        ? applyMultipleValidations([validations.required, validations.maxLength(maxLength)])
        : validations.required,
    },
  })

  const handleSubmit = () => {
    if (!validateForm()) return

    setIsSaving(true)
    onSubmit(values, unmount, setIsSaving)
  }

  return (
    <Modal
      cancelable
      titleGroup={{
        titleToken: title,
      }}
      buttons={[
        {
          labelToken: localizationKey("Save"),
          onClick: handleSubmit,
          type: "save",
          disabled: isSaving,
        },
      ]}
      unmount={unmount}
    >
      <Box>
        <TitleGroup titleToken={localizationKey("Name")} size="sm" />
        <Input
          value={values.name}
          maxLength={maxLength ?? 100}
          useCharacterCount={!!maxLength}
          onChange={e => onChange("name", e.target.value)}
          errorMessage={validation.message.name}
          ariaLabel={localized("Name")}
        />
        {warningLabelToken && (
          <Box marginTop={sizer(4)}>
            <InlineWarning>{localized(warningLabelToken)}</InlineWarning>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

NameModal.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  unmount: PropTypes.func,
  warningLabelToken: PropTypes.string,
  maxLength: PropTypes.number,
}
