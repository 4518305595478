import { isNil } from "ramda"
import { enumToReadableValue } from "js/includes/common/_enums"

const enumvalue = function(value, cmd, param) {
  if (!isNil(value)) {
    if (cmd && param) {
      switch (cmd) {
        case "IGNORE":
          return enumToReadableValue(value, false, param)
        default:
          break
      }
    } else {
      return enumToReadableValue(value)
    }
  }
}

export default enumvalue
