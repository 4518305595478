import { useRef } from "react"
import { connect, batch } from "react-redux"
import styled from "@emotion/styled"
import { CircleExclamationIconLight, SearchIcon } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import { Box } from "js/includes/components/Styled"
import {
  resetGlobalSearchPageResults as _resetGlobalSearchPageResults,
  setGlobalSearchPageQuery as _setGlobalSearchPageQuery,
  setGlobalSearchPersistedQuery as _setGlobalSearchPersistedQuery,
  setGlobalSearchPersistedResults as _setGlobalSearchPersistedResults,
} from "js/state/actions/globalSearch"
import { localized } from "js/includes/common/utils"
import { ResultsHeader, StyledAlert, StyledPopover } from "./common"
import GlobalSearchSkeletons from "./GlobalSearchSkeletons"
import GlobalSearchNoResultsAlert from "./GlobalSearchNoResultsAlert"
import GlobalSearchItem from "./GlobalSearchItem"
import { getGlobalSearchItemKey, getGlobalSearchPageUrl } from "./utils"

const StyledResultsLink = styled.a`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 38px;
  font-size: ${getTextSize("sm")};
  margin-bottom: ${sizer(2)};
  border-radius: 4px;
  padding-left: ${sizer(3)};
  padding-right: ${sizer(3)};

  && {
    color: ${({ theme }) => theme.colorTextStrong};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &:focus,
  &:focus-visible {
    outline-offset: -2px;
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }

  svg {
    margin-right: 18px;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const ariaAttributes = { "aria-live": "polite", role: "region", "aria-atomic": true }

const GlobalSearchPopover = ({
  searchValue,
  exactResults,
  suggestedResults,
  isLoading,
  closePopover,
  filter,
  showError,
  addRecentResult,
  resetSearch,
  resetGlobalSearchPageResults,
  setGlobalSearchPageQuery,
  setGlobalSearchPersistedQuery,
  setGlobalSearchPersistedResults,
}) => {
  const popoverRef = useRef()
  const hasNoResults = exactResults?.length <= 0 && suggestedResults?.length <= 0

  const handleAddRecentResult = resultData => {
    const { matchAttr, matchAttrValue, ...restResultData } = resultData
    addRecentResult(restResultData)
  }

  if (isLoading)
    return (
      <StyledPopover {...{ isLoading: true, "data-testid": "global-search-popover", ...ariaAttributes }}>
        <GlobalSearchSkeletons />
      </StyledPopover>
    )

  if (showError)
    return (
      <StyledPopover {...{ showAlert: true, "data-testid": "global-search-popover", ...ariaAttributes }}>
        <StyledAlert>
          <CircleExclamationIconLight />
          <h3>{localized("Something went wrong on our end")}</h3>
          <p>{localized("Please refresh your page and try again")}</p>
        </StyledAlert>
      </StyledPopover>
    )

  if (hasNoResults)
    return (
      <StyledPopover {...{ showAlert: true, "data-testid": "global-search-popover", ...ariaAttributes }}>
        <GlobalSearchNoResultsAlert {...{ filter, closePopover }} />
      </StyledPopover>
    )

  return (
    <StyledPopover ref={popoverRef} data-testid="global-search-popover">
      <div data-testid="global-search-results">
        <StyledResultsLink
          href={getGlobalSearchPageUrl({ searchValue, searchFilter: filter })}
          onClick={event => {
            const openingNewTab =
              event.ctrlKey || event.shiftKey || event.metaKey || (event.button && event.button === 1)

            if (!openingNewTab) {
              batch(() => {
                setGlobalSearchPersistedResults(exactResults, suggestedResults)
                setGlobalSearchPersistedQuery(searchValue, filter)
                resetGlobalSearchPageResults()
                setGlobalSearchPageQuery(searchValue, filter)
              })
              closePopover()
            }
          }}
        >
          <SearchIcon fixedWidth={false} fontSize="20px" />
          <span>{localized(`View all results for "{{searchValue}}"`, { searchValue })}</span>
        </StyledResultsLink>
        {!!exactResults?.length && (
          <>
            <ResultsHeader breakoutOfContainer>{localized("Exact results")}</ResultsHeader>
            <Box marginTop={sizer(3)} marginBottom={sizer(3)}>
              {exactResults.map(resultData => (
                <GlobalSearchItem
                  {...{
                    data: resultData,
                    key: getGlobalSearchItemKey(resultData),
                    closePopover,
                    searchValue,
                    onClick: () => {
                      handleAddRecentResult(resultData)
                      resetSearch(false)
                    },
                    containerRef: popoverRef,
                    itemIsInPopover: true,
                  }}
                />
              ))}
            </Box>
          </>
        )}
        {!!suggestedResults?.length && (
          <>
            <ResultsHeader breakoutOfContainer>{localized("Suggested results")}</ResultsHeader>
            <Box marginTop={sizer(3)}>
              {suggestedResults.map(resultData => (
                <GlobalSearchItem
                  {...{
                    data: resultData,
                    key: getGlobalSearchItemKey(resultData),
                    closePopover,
                    searchValue,
                    onClick: () => {
                      handleAddRecentResult(resultData)
                      resetSearch(false)
                    },
                    containerRef: popoverRef,
                    itemIsInPopover: true,
                  }}
                />
              ))}
            </Box>
          </>
        )}
      </div>
    </StyledPopover>
  )
}

export default connect(null, {
  resetGlobalSearchPageResults: _resetGlobalSearchPageResults,
  setGlobalSearchPageQuery: _setGlobalSearchPageQuery,
  setGlobalSearchPersistedQuery: _setGlobalSearchPersistedQuery,
  setGlobalSearchPersistedResults: _setGlobalSearchPersistedResults,
})(GlobalSearchPopover)
