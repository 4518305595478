import React, { memo } from "react"
import styled from "@emotion/styled"
import { colors } from "js/includes/common/theme"
import { StyledLabel as _StyledLabel } from "./Styled/Form"
import { Box } from "./Styled"

const CheckboxContainer = styled.label`
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  margin: 0;
`
// Accessible checkbox
const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
const StyledCheckbox = styled(Box)`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  width: 18px;
  height: 18px;
  background-color: ${({ checked, disabled }) =>
    !disabled && checked ? colors.ninjaBlueSaturated : colors.ninjaLight};
  border: ${({ error }) => (error ? `1px solid ${colors.ninjaRed}` : "0")};
  border-radius: 3px;

  svg {
    fill: none;
    stroke: white;
    stroke-width: 2px;
    visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
  }
`
const StyledLabel = styled(_StyledLabel)`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  user-select: none;
  margin: 0 0 0 5px;
  color: ${({ disabled }) => (disabled ? `${colors.ninjaMedium} !important` : colors.ninjaDark)};
`
export default memo(
  ({ id, name, checked, disabled, label, onChange, error, readOnly, checkboxStyle = {}, labelStyle = {} }) => (
    <CheckboxContainer>
      <HiddenCheckbox type="checkbox" {...{ id, name, checked, onChange, disabled, readOnly }} />
      <StyledCheckbox {...{ checked, error, disabled, ...checkboxStyle }}>
        <svg viewBox="0 0 18 18">
          <polyline points="14 4 8 12 4 8" />
        </svg>
      </StyledCheckbox>
      <StyledLabel {...{ disabled, htmlFor: id, ...labelStyle }}>{label}</StyledLabel>
    </CheckboxContainer>
  ),
)
