import styled from "@emotion/styled"
import { faXmark } from "@fortawesome/pro-regular-svg-icons"
import { IconButton } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"

const StyledClearButtonWrapper = styled.div`
  position: relative;
  min-width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.colorTextStrong};

  button {
    &:hover {
      background-color: ${({ theme }) => theme.colorForegroundHover};
    }

    &:focus,
    &:focus-visible {
      outline-offset: -2px;
      outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    }
  }
`

const GlobalSearchClearButton = ({ onClick }) => {
  return (
    <StyledClearButtonWrapper>
      <IconButton
        {...{
          onClick,
          icon: faXmark,
          tooltip: localized("Clear"),
          size: "sm",
          tooltipZIndex: 10000,
        }}
      />
    </StyledClearButtonWrapper>
  )
}

export default GlobalSearchClearButton
