import { capitalizeFirstLetter, localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const statusesTokenMap = {
  PROBLEM: localizationKey("Problem"),
  QUESTION: localizationKey("Question"),
  INCIDENT: localizationKey("Incident"),
  TASK: localizationKey("Task"),
  CRITICAL: localizationKey("Critical"),
  MAJOR: localizationKey("Major"),
  MODERATE: localizationKey("Moderate"),
  MINOR: localizationKey("Minor"),
  HIGH: localizationKey("High"),
  MEDIUM: localizationKey("Medium"),
  LOW: localizationKey("Low"),
}

const sourceTokenMap = {
  WEB_FORM: localizationKey("Web Form"),
  EMAIL: localizationKey("Email"),
  TECHNICIAN: localizationKey("Technician"),
  HELP_REQUEST: localizationKey("Systray Help Request"),
  END_USER: localizationKey("End User"),
  CONDITION: localizationKey("Condition"),
  SCHEDULED_SCRIPT: localizationKey("Scheduled Automation"),
  ACTIVITY: localizationKey("Activity"),
}

export const localizeStatuses = status => {
  const token = statusesTokenMap[status]
  return token ? capitalizeFirstLetter(localized(token)) : "-"
}

export const localizeSource = source => {
  const token = sourceTokenMap[source]
  return token ? localized(token) : "-"
}
