import { whereEq } from "ramda"

export default class CommonHashTable extends Map {
  getRaw(id) {
    return super.get(id)
  }

  get length() {
    return this.size
  }

  where(attrs) {
    return this.filter(whereEq(attrs)).map(({ id }) => this.get(id))
  }

  filter(fn) {
    const results = []
    for (const [, node] of this) {
      if (fn(node)) {
        results.push(node)
      }
    }
    return results
  }

  find(fn) {
    for (const [id, node] of this) {
      if (fn(node)) {
        return this.get(id)
      }
    }
  }

  toJSON() {
    return Array.from(this.values())
  }
}
