import { connect } from "react-redux"
import styled from "@emotion/styled"
import { faGrid2 } from "@fortawesome/pro-regular-svg-icons"
import { IconButton, Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import {
  DesktopIconLight,
  FileLinesIconLight,
  MobileIconLight,
  ReceiptIconLight,
  ServerIconLight,
  TicketIconLight,
} from "@ninjaone/icons"
import { Box, Flex } from "js/includes/components/Styled"
import {
  isSystemAdmin,
  isFeatureEnabled,
  isITCloud,
  isUserAllowedUseTicketingAdministrativeActions,
  localized,
  localizationKey,
} from "js/includes/common/utils"
import { hasDocumentationConfigurationAccess } from "js/includes/common/services/userPermissions"
import { closeQuickMenu } from "js/state/actions/general/quickMenus"
import { StyledGlobalActionDropdown } from "./UserDropdown"

const RowItem = ({ token, descriptionToken, icon, text }) => {
  return (
    <Flex alignItems="center" width="100%" cursor="pointer" padding={`6px ${sizer(3)}`}>
      <Flex
        alignItems="center"
        justifyContent="center"
        height={sizer(9)}
        width={sizer(9)}
        borderRadius={sizer(1)}
        marginRight={sizer(2)}
        backgroundColor="colorBackgroundProduct"
        color="colorTextHighContrast"
      >
        {icon}
      </Flex>
      <Box>
        {token ? (
          <Text {...{ token }} size="sm" color="colorTextStrong" />
        ) : text ? (
          <Text size="sm" color="colorTextStrong">
            {text}
          </Text>
        ) : null}
        {descriptionToken && <Text {...{ token: descriptionToken, color: "colorTextWeakest", size: "xs" }} />}
      </Box>
    </Flex>
  )
}

const StyledPopoverContainer = styled.div`
  padding: ${sizer(1)};

  [data-ninja-dropdown-item] {
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.colorTextStrong};

    &[data-highlighted] {
      background: ${({ theme }) => theme.colorForegroundHover};
    }
  }
  box-shadow: ${({ theme }) => theme.elevationWeak};
  border-radius: ${sizer(1)};
  min-width: 270px;
`

const AppsDropdown = ({ helpSettings: _helpSettings, quickMenus, closeQuickMenu }) => {
  return (
    <StyledGlobalActionDropdown
      {...{
        portal: false,
        alignRight: true,
        childIsButton: true,
        PopoverContainer: StyledPopoverContainer,
        isExternalOpening: quickMenus.apps,
        handleExternalClose: () => closeQuickMenu("apps"),
        options: [
          {
            TitleComponent: () => (
              <Box padding={sizer(2, 3)}>
                <Text token={localizationKey("NinjaOne apps")} size="sm" color="colorTextStrong" bold />
              </Box>
            ),
            id: localizationKey("NinjaOne apps"),
          },
          ...(isSystemAdmin() && !isITCloud()
            ? [
                {
                  LabelComponent: () => (
                    <RowItem
                      {...{
                        text: "NinjaOne Backup",
                        descriptionToken: localizationKey("Manage device backups"),
                        icon: <ServerIconLight fontSize="18px" />,
                      }}
                    />
                  ),
                  action: () => (window.location.hash = "#/administration/apps/backup"),
                  id: "NinjaOne Backup",
                },
              ]
            : []),
          ...(hasDocumentationConfigurationAccess()
            ? [
                {
                  LabelComponent: () => (
                    <RowItem
                      {...{
                        text: "NinjaOne Documentation",
                        descriptionToken: localizationKey("Safely document IT environments"),
                        icon: <FileLinesIconLight fontSize="18px" />,
                      }}
                    />
                  ),
                  action: () => (window.location.hash = "#/administration/apps/documentation"),
                  id: "NinjaOne Documentation",
                },
              ]
            : []),
          ...(isSystemAdmin() && isFeatureEnabled("mdm")
            ? [
                {
                  LabelComponent: () => (
                    <RowItem
                      {...{
                        text: "NinjaOne MDM",
                        descriptionToken: localizationKey("Manage mobile devices"),
                        icon: <MobileIconLight fontSize="18px" />,
                      }}
                    />
                  ),
                  action: () => (window.location.hash = "#/administration/apps/mdm"),
                  id: "mdm",
                },
              ]
            : []),
          ...(isSystemAdmin() && isFeatureEnabled("ninja_psa")
            ? [
                {
                  LabelComponent: () => (
                    <RowItem
                      {...{
                        text: "NinjaOne PSA",
                        descriptionToken: localizationKey("Single pane billing"),
                        icon: <ReceiptIconLight fontSize="18px" />,
                      }}
                    />
                  ),
                  action: () => (window.location.hash = "#/administration/apps/ninjaPSA/getStarted"),
                  id: "NinjaOne PSA",
                },
              ]
            : []),
          ...(isSystemAdmin() && isFeatureEnabled("remotesupport")
            ? [
                {
                  LabelComponent: () => (
                    <RowItem
                      {...{
                        token: localizationKey("Remote Support"),
                        descriptionToken: localizationKey("Quick & easy remote access"),
                        icon: <DesktopIconLight fontSize="18px" />,
                      }}
                    />
                  ),
                  action: () => (window.location.hash = "#/administration/apps/remoteSupport"),
                  id: localizationKey("Remote Support"),
                },
              ]
            : []),
          ...(isUserAllowedUseTicketingAdministrativeActions()
            ? [
                {
                  LabelComponent: () => (
                    <RowItem
                      {...{
                        text: "NinjaOne Ticketing",
                        descriptionToken: localizationKey("Automated IT ticketing"),
                        icon: <TicketIconLight fontSize="18px" />,
                      }}
                    />
                  ),
                  action: () => (window.location.hash = "#/administration/apps/ticketing"),
                  id: "NinjaOne Ticketing",
                },
              ]
            : []),
          {
            LabelComponent: () => (
              <Box padding={sizer(2, 3)}>
                <Text token={localizationKey("View all apps")} size="sm" color="colorTextAction" bold />
              </Box>
            ),
            action: () => (window.location.hash = "#/administration/apps"),
            id: localizationKey("View all apps"),
          },
        ],
      }}
    >
      <IconButton
        {...{
          icon: faGrid2,
          tooltip: localized("NinjaOne apps"),
          size: "lg",
        }}
      />
    </StyledGlobalActionDropdown>
  )
}

export default connect(({ general: { quickMenus } }) => ({ quickMenus }), { closeQuickMenu })(AppsDropdown)
