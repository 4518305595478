import FullModel from "./FullModel"
import "js/includes/common/services/mfa"
import { showErrorMessage, localized } from "js/includes/common/utils"
import { buildMfaBackboneModelUrl } from "js/includes/common/services/mfa"

export const UserModel = FullModel.extend(
  {
    urlRoot: "/appuser",
    parseParam: "appUser",
    enforceMfa: true,

    url() {
      return buildMfaBackboneModelUrl(this)
    },

    parse(response) {
      this.unsetFieldsThatAreNoLongerPresent(response)
      switch (response.resultCode) {
        case "SUCCESS":
          return response[this.parseParam]
        case "FAILURE":
          return JSON.stringify(response)
        default:
          return response
      }
    },

    isUnhandledError(resultCode) {
      return !["EMAIL_ALREADY_IN_USE", "MFA_REQUIRED", "INCORRECT_MFA_CODE"].includes(resultCode)
    },

    onSaveError({ responseJSON }) {
      if (!responseJSON || this.isUnhandledError(responseJSON.resultCode)) {
        showErrorMessage(localized("Error creating/updating user"))
      }
    },

    getName() {
      return this.get("firstName") + " " + this.get("lastName")
    },

    clearNotificationLegacyProperties() {
      const { notification } = this.get("content")
      delete notification.smsEnabled
      delete notification.emailEnabled
      delete notification.priority
      delete notification.severity
      delete notification.pushNotificationEnabled
      delete notification.schedule
    },
  },
  {
    getModel(id) {
      return UserModel.findOrCreate({ id: id })
    },
  },
)
