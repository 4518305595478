const prefix = "fac"
const iconName = "Hyper-V-Guest"
const width = 200
const height = 200
const ligatures = []
const unicode = "020"
const svgPathData =
  "M69.62,30.74V83.25H12.36V38.62l57.26-7.88M82,16.59,0,27.85V95.59H82v-79Z M69.62,117.73v51.53l-57.26-7.88V117.73H69.62M82,105.39H0v66.76L82,183.41v-78Z M187.64,117.73v67.73l-84-11.6V117.73h84M200,105.39H91.32v79.23l108.66,15V105.39Z M187.64,14.54V83.25h-84V26.14l84-11.6M200,.38l-108.66,15V95.59H200V.38Z"

const faHyperVGuest = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export default faHyperVGuest
