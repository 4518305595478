import PropTypes from "prop-types"

import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"

import ClipboardCopy from "./ClipboardCopy"
import Input from "./Input"

const CopyInput = ({ alignTooltip, disabled, label, labelTooltipText, positionTooltip, value, width }) => (
  <Box position="relative" maxWidth="980px" {...{ width }}>
    <Input
      labelText={label}
      tooltipText={labelTooltipText}
      minWidth={width}
      readOnly
      rightSidePadding="35px"
      {...{
        disabled,
        value,
      }}
    />

    <Box position="absolute" right="3px" bottom="3px">
      <ClipboardCopy
        {...{
          alignTooltip,
          disabled,
          increaseContrast: true,
          positionTooltip,
          transparent: true,
          value,
        }}
      />
    </Box>
  </Box>
)

CopyInput.propTypes = {
  /**
   * The horizontal alignment of the tooltip.
   */
  alignTooltip: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * Sets the disabled state for the component.
   */
  disabled: PropTypes.bool,
  /**
   * The label text for the component.
   */
  label: PropTypes.string.isRequired,
  /**
   * The text for the Tooltip in the Label of the the component.
   */
  labelTooltipText: PropTypes.string,
  /**
   * The position of the tooltip relative to the copy button.
   */
  positionTooltip: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * The value of the input.
   */
  value: PropTypes.string.isRequired,
  /**
   * The width of the input.
   */
  width: PropTypes.number,
}

export default CopyInput
