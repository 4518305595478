import $ from "jquery"
import rivets from "rivets"

import "./adapters/at"

// Binders
import addclass from "./binders/addclass"
import jqueryslider from "./binders/jqueryslider"
import checkforchangesandclose from "./binders/checkforchangesandclose"
import overviewaction from "./binders/overviewaction"
import childselectoption from "./binders/childselectoption"
import chosen from "./binders/chosen"
import tooltip from "./binders/tooltip"
import flot from "./binders/flot"
import bytes from "./binders/bytes"
import popover from "./binders/popover"
import datatable from "./binders/datatable"
import scrolltablebody from "./binders/scrolltablebody"
import datepicker from "./binders/datepicker"
import icheckbox from "./binders/icheckbox"
import icheckradiobuttons from "./binders/icheckradiobuttons"
import incrementspinner from "./binders/incrementspinner"
import intltelinput from "./binders/intltelinput"
import linkspeed from "./binders/linkspeed"
import livevalidation from "./binders/livevalidation"
import livevalue from "./binders/livevalue"
import name from "./binders/name"
import parentenabled from "./binders/parentenabled"
import scrollspy from "./binders/scrollspy"
import portmap from "./binders/portmap"
import switchery from "./binders/switchery"
import template from "./binders/template"
import timepicker from "./binders/timepicker"
import modal from "./binders/modal"
import tags from "./binders/tags"
import timezone from "./binders/timezone"

// Formatters
import and from "./formatters/operators/and"
import or from "./formatters/operators/or"
import add from "./formatters/add"
import any from "./formatters/any"
import compare from "./formatters/compare"
import concat from "./formatters/concat"
import contains from "./formatters/contains"
import default_to from "./formatters/default_to"
import enumvalue from "./formatters/enumvalue"
import eq from "./formatters/eq"
import eq_condition from "./formatters/eq_condition"
import eq_monitor_condition from "./formatters/eq_monitor_condition"
import eq_nms_condition from "./formatters/eq_nms_condition"
import eq_mode from "./formatters/eq_mode"
import eq_then_else from "./formatters/eq_then_else"
import is_null from "./formatters/is_null"
import is_nil from "./formatters/is_nil"
import filter_by_attr from "./formatters/filter_by_attr"
import get from "./formatters/get"
import gt from "./formatters/gt"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import log_value from "./formatters/log_value"
import { livestamp } from "js/includes/common/utils"
import matches from "./formatters/matches"
import multiply from "./formatters/multiply"
import { time, date, dateTimeTz, dateTimeLong, dayDateTime } from "./formatters/date_time"
import not_eq from "./formatters/not_eq"
import not_eq_length from "./formatters/not_eq_length"
import not_eq_many from "./formatters/not_eq_many"
import eq_one_of from "./formatters/eq_one_of"
import notempty from "./formatters/notempty"
import percent from "./formatters/percent"
import substring_of from "./formatters/substring_of"
import starts_with from "./formatters/starts_with"
import set_width from "./formatters/set_width"
import to_lower_case from "./formatters/to_lower_case"
import to_upper_case from "./formatters/to_upper_case"
import to_pascal from "./formatters/to_pascal"
import length from "./formatters/length"
import includes from "./formatters/includes"
import reverse from "./formatters/reverse"
import { getReadableSyslogFacility, getReadableSyslogSeverity } from "js/includes/common/_enums"

$.extend(true, rivets.binders, {
  addclass,
  jqueryslider,
  checkforchangesandclose,
  overviewaction,
  childselectoption,
  chosen,
  tooltip,
  flot,
  bytes,
  popover,
  datatable,
  scrolltablebody,
  datepicker,
  icheckbox,
  icheckradiobuttons,
  incrementspinner,
  intltelinput,
  linkspeed,
  livevalidation,
  livevalue,
  name,
  parentenabled,
  scrollspy,
  portmap,
  switchery,
  template,
  timepicker,
  modal,
  tags,
  timezone,
})

$.extend(true, rivets.formatters, {
  and,
  or,
  add,
  any,
  compare,
  concat,
  contains,
  default_to,
  enumvalue,
  eq,
  eq_condition,
  eq_monitor_condition,
  eq_nms_condition,
  eq_mode,
  eq_then_else,
  eq_one_of,
  is_null,
  is_nil,
  filter_by_attr,
  get,
  gt,
  localized,
  log_value,
  livestamp,
  matches,
  multiply,
  time,
  date,
  dateTimeTz,
  dateTimeLong,
  dayDateTime,
  not_eq,
  not_eq_length,
  not_eq_many,
  notempty,
  percent,
  substring_of,
  starts_with,
  set_width,
  to_lower_case,
  to_pascal,
  to_upper_case,
  length,
  includes,
  reverse,
  getReadableSyslogFacility,
  getReadableSyslogSeverity,
})
