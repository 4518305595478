import $ from "jquery"
import rivets from "rivets"

const timezone = {
  bind: function(el) {},
  routine: function(el, model) {
    var bound = $(el).data("rivets.binders.timezone.bound")
    if (!bound) {
      var $timeZoneList = $("#template-time-zone-list").html()
      $(el).append($timeZoneList)

      var binding = rivets.bind($(el).find(".time-zone-dropdown-menu"), {
        model: model,
      })

      $(el)
        .find("button")
        .click(function(e) {
          $(el)
            .find("ul")
            .width($(el).width() - 4)
        })

      $(el)
        .find("a.tz-more-locations")
        .on("click", function(e) {
          e.preventDefault()
          e.stopPropagation()
          $(e.currentTarget)
            .parent()
            .nextAll()
            .toggle()

          if ($(e.currentTarget).hasClass("expanded")) {
            $(e.currentTarget).removeClass("expanded")
            $(e.currentTarget)
              .children("#show-more")
              .removeClass("hide")
            $(e.currentTarget)
              .children("#show-less")
              .addClass("hide")
          } else {
            $(e.currentTarget).addClass("expanded")
            $(e.currentTarget)
              .children("#show-more")
              .addClass("hide")
            $(e.currentTarget)
              .children("#show-less")
              .removeClass("hide")
          }
        })

      $(el)
        .find("a:not(.tz-more-locations)")
        .on("click", function(e) {
          e.preventDefault()
          model.set("name", $(e.currentTarget).data("name"))
          model.set("value", $(e.currentTarget).data("value"))
        })

      $(el).data("rivets.binders.timezone.bound", true)
      $(el).data("rivets.binders.timezone.binding", binding)
    }
  },
  unbind: function(el) {
    $(el)
      .find("a")
      .off("click")
    $(el)
      .find("button")
      .off("click")
    var binding = $(el).data("rivets.binders.timezone.binding")
    if (binding) {
      binding.unbind()
    }
    $(el).removeData()
  },
}

export default timezone
