import $ from "jquery"
import Backbone from "backbone"
import rivets from "rivets"

const modal = {
  routine: function(el, model) {
    // This routine is called when a model (or parent model) is removed.
    // If there is no model to bind to, we can safely return.
    if (!model) return

    var handler = function(param) {
      // If a newModel object was passed in, we may be creating a new entity which wasn't available
      // at the time of the click event. Use this model instead. We do need to check instanceof here
      // as sometimes the click event is passed in as a parameter.
      if (param && param instanceof Backbone.Model) {
        model = param
      }
      // If we received a click event, preventDefault
      else if (param && param.type === "click") {
        param.preventDefault()
      }

      var placeHolder = $("#" + $(el).attr("data-modal-placeholder"))
      var template = $("#" + $(el).attr("data-modal-template"))

      placeHolder.html(template.html())

      var view = rivets.bind(placeHolder, {
        model: model,
      })

      var closeMethod = $(el).data("rv-close-modal-method")
      if (!closeMethod) {
        closeMethod = "closeModal"
      }

      model[closeMethod] = function() {
        placeHolder.modal("hide")
        view.unbind()
      }

      placeHolder.modal("show")
    }

    var openMethod = $(el).data("rv-open-modal-method")
    if (!openMethod) {
      openMethod = "openModal"
    }
    model[openMethod] = handler
  },
}

export default modal
