import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"

import tokens from "@ninjaone/tokens"

import { shouldIgnoreProps } from "./utils"
import { variants } from "./constants"
import Body from "../Typography/Body"

const StyledRadioLabel = styled("label", {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  margin: 0;
  user-select: none;

  display: flex;
  flex-direction: column;

  width: 100%;
  color: ${({ theme }) => theme.colorTextStrong};

  line-height: ${tokens.typography.lineHeight};
  font-size: ${tokens.typography.fontSize.body};
  font-weight: ${({ descriptionMode }) =>
    descriptionMode ? tokens.typography.fontWeight.medium : tokens.typography.fontWeight.regular};

  [data-ninja-tooltip-trigger] {
    display: block;
  }
`

const StyledExtraContent = styled.div`
  width: 100%;
  margin-top: ${tokens.spacing[2]};
`

const StyledDescription = styled(Body)`
  margin: 0;
  user-select: none;
`

function RadioLabel({ value, descriptionMode, label, description, extraContentRenderer, variant }) {
  return (
    <StyledRadioLabel {...{ htmlFor: value, descriptionMode }}>
      {label}

      {description && (
        <StyledDescription
          {...{
            type: "body",
            textWrap: true,
            color: "colorTextWeakest",
            textWrapLineLimit: variant === variants.CARD ? 1 : 2,
          }}
        >
          {description}
        </StyledDescription>
      )}

      {extraContentRenderer && <StyledExtraContent>{extraContentRenderer()}</StyledExtraContent>}
    </StyledRadioLabel>
  )
}

export default RadioLabel
