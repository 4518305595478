import { Modal } from "@ninjaone/components"
import { localizationKey } from "js/includes/common/utils"

const AutomationSubmittedModal = ({ unmount }) => {
  const viewStatus = () => {
    window.location.href = "#/administration/library/scripting/underReview"
  }
  return (
    <Modal
      unmount={unmount}
      size="md"
      titleGroup={{
        titleToken: localizationKey("Automation processing"),
        descriptionToken: localizationKey(
          "Your file has been submitted for processing. This process may take a few minutes. Navigate to “Under review” tab to view more information or click View status.",
        ),
      }}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("View status"),
          onClick: viewStatus,
        },
      ]}
    ></Modal>
  )
}

export default AutomationSubmittedModal
