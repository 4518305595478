import { ConfirmationModal, Text } from "@ninjaone/components"
import { localizationKey } from "js/includes/common/utils"

export const StopDiscoveryModal = ({ unmount, unmountParentModal, stopDiscovery, goToDiscovery, messageToken }) => (
  <ConfirmationModal
    type="danger"
    titleToken={localizationKey("Stop discovery?")}
    actionToken={localizationKey("Stop discovery")}
    onConfirm={async ({ unmount }) => {
      try {
        await stopDiscovery()
        goToDiscovery?.()
        unmount()
        unmountParentModal?.()
      } catch (error) {
        // error gets thrown from stopDiscovery
      }
    }}
    unmount={unmount}
  >
    <Text type="body" token={messageToken} textWrap />
  </ConfirmationModal>
)
