import moment from "moment"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

import {
  TOKEN_ERROR_MSG_INVALID,
  TOKEN_ERROR_MSG_INVALID_RANGE,
  TOKEN_ERROR_MSG_OUTSIDE,
} from "../../components/src/Picker/errorMessages"

export const validateDateConstraints = ({ startDate, endDate, disabledDays, fromMonth, toMonth }) => {
  const withinBefore = disabledDays?.before && moment(startDate) >= moment(disabledDays.before).startOf("day")
  const withinAfter = disabledDays?.after && moment(endDate) <= moment(disabledDays.after).startOf("day")
  const withinFromTo =
    moment(startDate).isSameOrBefore(disabledDays?.to) && moment(endDate).isSameOrAfter(disabledDays?.from)
  const withinFromMonth = fromMonth && startDate >= moment(fromMonth).startOf("month")
  const withinToMonth = toMonth && endDate < moment(toMonth).endOf("month")
  const withinExcluded = disabledDays && moment.isDate(disabledDays) && moment(disabledDays).isSame(startDate, "day")

  if (
    withinFromTo ||
    withinExcluded ||
    withinBefore === false ||
    withinAfter === false ||
    withinFromMonth === false ||
    withinToMonth === false
  )
    return false

  return true
}

export const parseAndValidateDateRange = ({
  value,
  localeFormat,
  disabledDays,
  fromMonth,
  toMonth,
  useSameDayRange,
}) => {
  const dateRange = value.replace(/\s+/g, "").split("-")
  if (dateRange.length !== 2) return { error: "dateInvalid" }
  const [startString, endString] = dateRange
  const startDate = moment(startString, localeFormat, true)
  const endDate = moment(endString, localeFormat, true)

  if (startDate.isBefore(moment("1970-01-01"))) {
    return { error: "dateOutsideConstraints" }
  }

  if (!useSameDayRange && startDate.isSame(endDate)) {
    return { error: "dateIsSame" }
  }

  if (startDate.isSameOrBefore(endDate)) {
    const startDateObj = moment(startDate).toDate()
    const endDateObj = moment(endDate).toDate()
    const dateRangeObj = { from: startDateObj, to: endDateObj }

    if (!fromMonth && !toMonth && !disabledDays) return dateRangeObj

    const disabledDaysArray = Array.isArray(disabledDays) ? disabledDays : [disabledDays]
    const withinConstraints = disabledDaysArray.every(_disabledDays =>
      validateDateConstraints({
        startDate,
        endDate,
        disabledDays: _disabledDays,
        fromMonth,
        toMonth,
      }),
    )

    if (withinConstraints) {
      return dateRangeObj
    }

    return { error: "dateOutsideConstraints" }
  }

  return { error: "dateInvalid" }
}

export const parseAndValidateDate = ({ value, localeFormat, disabledDays, fromMonth, toMonth }) => {
  const date = moment(value, localeFormat, true)

  if (date.isBefore(moment("1970-01-01"))) {
    return { error: "dateOutsideConstraints" }
  }

  if (date.isValid()) {
    const dateObj = moment(date).toDate()

    if (!fromMonth && !toMonth && !disabledDays) return dateObj

    const disabledDaysArray = Array.isArray(disabledDays) ? disabledDays : [disabledDays]
    const withinConstraints = disabledDaysArray.every(_disabledDays =>
      validateDateConstraints({
        startDate: date,
        endDate: date,
        disabledDays: _disabledDays,
        fromMonth,
        toMonth,
      }),
    )

    if (withinConstraints) {
      return dateObj
    }

    return { error: "dateOutsideConstraints" }
  }

  return { error: "dateInvalid" }
}

export const parseDateErrorResponse = error => {
  let errorMessage
  switch (error) {
    case "incompleteRange": {
      errorMessage = TOKEN_ERROR_MSG_INVALID_RANGE
      break
    }
    case "dateOutsideConstraints": {
      errorMessage = TOKEN_ERROR_MSG_OUTSIDE
      break
    }
    case "invalidDate":
    default: {
      errorMessage = TOKEN_ERROR_MSG_INVALID
    }
  }

  return errorMessage
}

export const getInitialDateInputValue = ({ selectedDays, localeFormat }) => {
  return selectedDays?.from && selectedDays?.to
    ? `${moment(selectedDays.from).format(localeFormat)} - ${moment(selectedDays.to).format(localeFormat)}`
    : selectedDays
    ? moment(selectedDays).format(localeFormat)
    : ""
}
