import { Terminal } from "xterm"
import { AttachAddon } from "xterm-addon-attach"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import { downloadFile, showSuccessMessage } from "js/includes/common/utils"
import { localized, localizationKey, ninjaReportError } from "js/includes/common/utils/ssrAndWebUtils"
import showModal from "js/includes/common/services/showModal"
import CommandLineModal from "js/includes/deviceDashboard/CommandLineModal"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { getTerminalWebSocketTechnicianUrl, getTerminalWebSocketNms } from "js/includes/common/client"

const CHARHEIGHT = 18
export const CHARWIDTH = 9
export const SCROLLBARWIDTH = 15
const FONTSIZE = 15
const LINEHEIGHT = 1.0
const POWERSHELL_COLOR = "#012456"
const POWERSHELL_PATH = "C:\\Windows\\System32\\WindowsPowerShell\\v1.0\\powershell.exe"

const CLI_LEVEL = {
  DEELEVATED: 0,
  ELEVATED: 1,
}

class NinjaTerminal extends Terminal {
  constructor(opts) {
    super(opts)
    const { terminalType, shellPath, hideModal, nodeId, cols, rows, userEndpointUrl, protocol, delegateNodeId } = opts
    this.terminalType = terminalType
    this.nodeId = nodeId
    this.hideModal = hideModal
    this.historyPointer = 0
    this.pid = null
    this.destroyed = false
    this.graciousClose = false
    this.icols = cols
    this.irows = rows
    this.shellPath = shellPath || "DEFAULT"
    this.userEndpointUrl = userEndpointUrl
    this.protocol = protocol
    this.delegateNodeId = delegateNodeId
    this.isElevatable = false
  }

  initialize(terminalContainerNode) {
    this.open(terminalContainerNode)
    this.startTerminal()
    this.focus()
  }

  getUserEndpointUrl = async (isElevated = true) => {
    try {
      if (this.delegateNodeId) {
        const content = { delegateNodeId: this.delegateNodeId, targetNodeId: this.nodeId, protocol: this.protocol }
        const response = await getTerminalWebSocketNms(content, "WS")
        this.userEndpointUrl = response?.url
      } else {
        const content = {
          user: this.terminalType,
          shell: this.shellPath,
          nodeId: this.nodeId,
          cliLevel: isElevated ? CLI_LEVEL.ELEVATED : CLI_LEVEL.DEELEVATED,
        }
        const response = await getTerminalWebSocketTechnicianUrl(content, "WS")
        this.userEndpointUrl = response?.url
        this.isElevatable = response?.isElevatable
      }
    } catch (error) {
      throw error
    }
  }

  startTerminal = () => {
    try {
      this.socket = new WebSocket(this.userEndpointUrl)
      this.attachAddon = new AttachAddon(this.socket)
      this.socket.onopen = () => this.loadAddon(this.attachAddon)
      this.socket.onclose = e => {
        !this.graciousClose && !e.wasClean && this.terminalClosed(e.reason)
        this.attachAddon.dispose()
        this.hideModal && this.hideModal()
        this.destroyed = true
      }
    } catch (error) {
      this.graciousClose = true
      handleTerminalError(error, this.hideModal)
    }
  }

  terminalClosed = reason => {
    showErrorAndHideModal(this.hideModal, {
      icon: { icon: faExclamation, type: "critical" },
      title: localizationKey("The terminal has closed"),
      message: () => reason,
    })
  }

  executionError = () => {
    showErrorAndHideModal(this.hideModal, {
      icon: { icon: faExclamation, type: "critical" },
      title: localizationKey("Could not execute command"),
      message: localizationKey("Could not execute command. Make sure the device is online and try again."),
    })
  }

  destroy = () => {
    if (!this.destroyed) {
      this.disconnect()
    }
  }

  disconnect = async () => {
    try {
      this.socket && this.socket.close()
    } catch (error) {
      this.executionError()
      throw error
    }
  }

  toggleAccessLevel = async ({ unmount, node, terminalType, shellPath, isElevated }) => {
    try {
      this.graciousClose = true
      this.destroy()
      unmount && unmount()

      showModal(
        <CommandLineModal
          {...{
            node,
            terminalType,
            shellPath,
            isElevated,
          }}
        />,
      )
    } catch (error) {
      ninjaReportError(error)
    }
  }

  sendControlCommand = async () => {
    try {
      this.socket && this.socket.send("\x03")
    } catch (error) {
      this.executionError()
      throw error
    }
  }

  close = async unmount => {
    const buttonPressed = await ShowMessageDialog({
      icon: { icon: faExclamation, type: "critical" },
      title: localizationKey("Exit terminal"),
      message: localizationKey("Are you sure you want to close your session and disconnect?"),
      buttons: [
        { id: "YES", label: localizationKey("Yes") },
        { id: "NO", label: localizationKey("No") },
      ],
    })

    if (buttonPressed === "YES") {
      this.graciousClose = true
      this.destroy()
      unmount && unmount()
    }
  }

  mouseUp = () => {
    if (this.hasSelection()) {
      try {
        // eslint-disable-next-line
        document.execCommand("copy")
        showSuccessMessage(localized("Copied"))
        this.clearSelection()
      } catch (error) {
        // do nothing, unsupported browser
        throw error
      }
    }
  }

  downloadAll = () => {
    this.selectAll()
    const text = this.getSelection()
    this.clearSelection()
    const cliFileName = window.cliFileNameCtx ?? "cli.txt"
    downloadFile(`data:text/plain;charset=utf-8,${encodeURIComponent(text)}`, cliFileName)
  }

  copyAll = () => {
    this.selectAll()
    this.mouseUp()
  }
}

const showErrorAndHideModal = async (hideModal, { icon, title, message }) => {
  await ShowMessageDialog({ icon, title, message, buttons: [{ id: "OK", label: localizationKey("OK") }] })
  hideModal && hideModal()
}

const notLoggedInError = hideModal => {
  showErrorAndHideModal(hideModal, {
    icon: { icon: faExclamation, type: "critical" },
    title: localizationKey("Could not execute command"),
    message: localizationKey("No User logged in"),
  })
}

const terminalError = hideModal => {
  showErrorAndHideModal(hideModal, {
    icon: { icon: faExclamation, type: "critical" },
    title: localizationKey("Could not connect to terminal"),
    message: localizationKey("Unable to start terminal..."),
  })
}

const terminalPathError = hideModal => {
  showErrorAndHideModal(hideModal, {
    icon: { icon: faExclamation, type: "critical" },
    title: localizationKey("Could not connect to Powershell"),
    message: localizationKey(
      "Please make sure that Powershell is installed in the correct default destination: C:\\Windows\\System32\\WindowsPowerShell\\v1.0\\powershell.exe",
    ),
  })
}

export const handleTerminalError = (error, hideModal) => {
  const errorParam = error?.params?.error
  switch (errorParam) {
    case "ERROR_NO_LOGGEDIN_USER":
      return notLoggedInError(hideModal)
    case "ERROR_SHELL_PATH":
      return terminalPathError(hideModal)
    default:
      return terminalError(hideModal)
  }
}

export default function _Terminal({ width, height, shellPath, background = "#000000", cursorAccent, ...rest }) {
  return new NinjaTerminal({
    cols: Math.floor(width / CHARWIDTH),
    rows: Math.floor(height / CHARHEIGHT),
    theme: {
      background: shellPath === POWERSHELL_PATH ? POWERSHELL_COLOR : background,
      cursorAccent,
    },
    fontSize: FONTSIZE,
    lineHeight: LINEHEIGHT,
    fontWeight: "bold",
    shellPath,
    rendererType: "dom",
    allowTransparency: true,
    ...rest,
  })
}
