import { useMemo } from "react"
import PropTypes from "prop-types"
import { Modal } from "@ninjaone/components"
import { useForm, useMountedState } from "js/includes/common/hooks"
import CategoriesSelect from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/common/CategoriesSelect"
import { getCategoriesOptions } from "js/includes/configuration/scripting/scriptingUtils"

export default function ScriptCategoriesModal({ title, onSubmit, initialValues, scriptCategories = [], unmount }) {
  const [isSaving, setIsSaving] = useMountedState(false)

  const { values, onChange } = useForm({
    fields: {
      categories: initialValues?.categories || null,
    },
  })
  const formattedScriptCategories = useMemo(
    () => scriptCategories.map(({ name, id }) => ({ labelText: name, value: `${id}` })),
    [scriptCategories],
  )

  const formattedOptions = useMemo(() => getCategoriesOptions(formattedScriptCategories), [formattedScriptCategories])

  const handleSubmit = () => {
    setIsSaving(true)
    onSubmit(values, setIsSaving, unmount)
  }

  const handleChange = ({ categories }) => {
    const categoriesValues = categories.map(c => +c)
    onChange("categories", categoriesValues)
  }

  return (
    <Modal
      size="md"
      titleGroup={{
        titleText: title,
      }}
      buttons={
        formattedOptions?.length > 0
          ? [
              {
                labelToken: "general.save",
                onClick: handleSubmit,
                type: "save",
                disabled: isSaving,
              },
            ]
          : []
      }
      unmount={unmount}
    >
      <CategoriesSelect
        {...{
          classNamePrefix: "react-select",
          labelId: "categories",
          labelToken: "configuration.scripting.categories.title",
          matchWidth: true,
          labelAsCount: false,
          scriptCategories: formattedScriptCategories,
          categories: values.categories.map(vc => `${vc}`),
          onChange: handleChange,
        }}
      />
    </Modal>
  )
}

ScriptCategoriesModal.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  scriptCategories: PropTypes.array,
  unmount: PropTypes.func,
}
