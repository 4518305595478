import { always, cond, lt, T } from "ramda"
import styled from "@emotion/styled"
import { EditorInputRow, Modal, Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { Box } from "js/includes/components/Styled"
import {
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  showSuccessMessage,
  showErrorMessage,
  runDeviceSearchToolbarAction,
} from "js/includes/common/utils"
import { useForm, useMountedState } from "js/includes/common/hooks"
import { sendActionMultipleDevices } from "js/includes/common/client"
import { StyledForm } from "js/includes/components/Styled/Form"
import AlertMessage from "@ninjaone/components/src/AlertMessage"
import { VARIANTS } from "@ninjaone/components/src/Button"

const AlertMessageContainer = styled(Box)`
  visibility: ${({ isValid }) => (isValid ? "hidden" : "inherit")};
`

export default function ResetPasscodeModal({ nodeIds, unmount, runnerActionName }) {
  const [loading, setLoading] = useMountedState(false)

  const validatePasscode = value => {
    const limit = 4
    const isEmpty = !value.trim()
    const isLowerThanLimit = lt(value?.length, limit)
    const isNumeric = !isNaN(Number(value))

    const message = cond([
      [always(isEmpty), always(localized("Required"))],
      [always(isLowerThanLimit), always(localized("Passcode must be a minimum of 4 characters"))],
      [always(!isNumeric), always(localized("Passcode must contain only numeric values"))],
      [T, always("")],
    ])()

    return {
      success: !isEmpty && !isLowerThanLimit && isNumeric,
      message,
    }
  }

  const validatePasscodeConfirmation = (val, values) => ({
    success: val === values?.passcode,
    message: val !== values?.passcode ? localized("Passcodes do not match") : "",
  })

  const { values, validateForm, validation, onChange } = useForm({
    fields: {
      passcode: "",
      confirmPasscode: "",
    },
    validate: {
      passcode: validatePasscode,
      confirmPasscode: validatePasscodeConfirmation,
    },
  })

  const isPasscodeValid = passcodeSuccess => {
    return passcodeSuccess || passcodeSuccess === undefined
  }

  const handleResetPasscodeAction = async () => {
    setLoading(true)
    try {
      if (validateForm()) {
        if (runnerActionName) {
          await runDeviceSearchToolbarAction({
            action: runnerActionName,
            data: { newPasscode: values.confirmPasscode },
          })
        } else {
          await sendActionMultipleDevices(nodeIds, {
            type: "RESET_PASSCODE_DEVICE",
            newPasscode: values.confirmPasscode,
          })
        }
      } else {
        showErrorMessage(localized("Please enter a valid value"))
        return
      }
      showSuccessMessage(localized("The action was sent successfully"))
      unmount()
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        reportErrorAndShowMessage(error, localizationKey("The action could not be sent"))
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal
      size="md"
      withCloseX
      titleGroup={{
        titleToken: localizationKey("Reset Passcode"),
        DescriptionComponent: () => {
          return <Text color="black" token={localizationKey("Set a new device passcode for this device.")} />
        },
      }}
      cancelable
      unmount={unmount}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Reset"),
          onClick: handleResetPasscodeAction,
          disabled: loading,
        },
      ]}
    >
      <Box>
        <Text
          textWrap={true}
          token={localizationKey(
            "Passcode must be a minimum of 4 characters, must contain only numeric values and cannot be any of the previous 10 passcodes.",
          )}
        />
        <StyledForm>
          <Box>
            <Box width="100%" display="grid" gridGap={sizer(4)}>
              <EditorInputRow
                type="password"
                titleToken={localizationKey("New Passcode")}
                TitleTailComponent={() => <span>*</span>}
                maxLength={100}
                minLength={4}
                value={values.passcode}
                onChange={e => {
                  validateForm(["passcode"])
                  onChange("passcode", e.target.value)
                }}
                errorMessage={validation.message.passcode}
                displayAsColumns
              />
              <EditorInputRow
                type="password"
                titleToken={localizationKey("Confirm Passcode")}
                TitleTailComponent={() => <span>*</span>}
                value={values.confirmPasscode}
                onChange={e => {
                  validateForm(["confirmPasscode"])
                  onChange("confirmPasscode", e.target.value)
                }}
                maxLength={100}
                minLength={4}
                errorMessage={validation.message.confirmPasscode}
                displayAsColumns
              />
            </Box>
            <AlertMessageContainer
              marginTop={3}
              isValid={
                isPasscodeValid(validation.success.passcode) && isPasscodeValid(validation.success.confirmPasscode)
              }
            >
              <AlertMessage variant={VARIANTS.DANGER} titleToken={localizationKey("Invalid Passcode")}>
                {!isPasscodeValid(validation.success.passcode) && <Text token={validation.message.passcode} />}
                {!isPasscodeValid(validation.success.confirmPasscode) && (
                  <Text token={validation.message.confirmPasscode} />
                )}
              </AlertMessage>
            </AlertMessageContainer>
          </Box>
        </StyledForm>
      </Box>
    </Modal>
  )
}
