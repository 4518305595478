import { useMemo } from "react"
import { groupBy, compose, reject } from "ramda"
import { Text, TextArea, TitleGroup, StyledButtonLink, AlertMessage, Button } from "@ninjaone/components"
import { ExternalLinkIconLight, PlusIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import OrganizationPicker from "js/includes/components/OrganizationLocationPicker/OrganizationPicker"
import { Box, Flex } from "js/includes/components/Styled"
import { reportErrorAndShowMessage, sortByFieldNameCaseInsensitive, user } from "js/includes/common/utils"
import { localized, localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import { getNetworkProbes } from "js/includes/common/client"
import { getLocationsByOrganizationId } from "js/state/actions/deviceSearch/locations"
import showModal from "js/includes/common/services/showModal"
import { getOrganizationCredentials } from "js/state/actions/credentials"
import { AdvancedSettingsModal } from "./modals/AdvancedSettingsModal"
import { AddCredentialModal } from "./modals/AddCredentialModal"
import { useNetworkDiscovery } from "./NetworkDiscoveryContext"
import { createSelectOptions, getNetworkDiscoveryDojoLink, networkDiscoveryDefaults } from "./utils"
import Link from "js/includes/components/Link"

const snmpTypes = ["SNMPV1", "SNMPV2", "SNMPV3"]
const byCredentialType = groupBy(({ credentialType }) => {
  if (snmpTypes.includes(credentialType)) return "snmpCredentials"
  if (credentialType === "TELNET_SSH") return "telnetCredentials"
})
const getCredentialVersion = ({ credentialType }) => {
  switch (credentialType) {
    case "SNMPV1":
      return "(V1)"
    case "SNMPV2":
      return "(V2)"
    case "SNMPV3":
      return "(V3)"
    default:
      return ""
  }
}
const getProtocolValue = ({ credentialType }) => {
  if (credentialType === "SNMPV1") {
    return { value: 1 }
  } else if (credentialType === "SNMPV2") {
    return { value: 2 }
  } else if (credentialType === "SNMPV3") {
    return { value: 3 }
  }
}

export const Setup = () => {
  const { values, onChange, validation, errorMessage, resetForm } = useNetworkDiscovery()

  const {
    org,
    location,
    networkProbe,
    ipTargets,
    snmpCredOne,
    snmpCredTwo,
    telnetSshCred,
    credentials,
    networkProbes,
    locations,
  } = values

  const { snmpCredentials = [], telnetCredentials = [] } = useMemo(
    () => compose(byCredentialType, createSelectOptions)(credentials),
    [credentials],
  )
  const formattedSnmpCredentials = useMemo(
    () =>
      snmpCredentials.reduce((acc, credential) => {
        if (credential.value !== snmpCredOne?.value && credential.value !== snmpCredTwo?.value) {
          acc.push({
            ...credential,
            label: `${credential.label} ${getCredentialVersion(credential)}`,
          })
        }

        return acc
      }, []),
    [snmpCredOne?.value, snmpCredTwo?.value, snmpCredentials],
  )

  const noOrg = !org

  const handleOrgChange = async org => {
    try {
      const [creds, locations] = await Promise.all([
        getOrganizationCredentials(org.id),
        getLocationsByOrganizationId(org.id),
      ])

      const filteredCreds = reject(({ credentialType }) => credentialType === "SNMPV12C", creds)
      const publicCred = filteredCreds.find(({ name }) => name.toLowerCase() === "public")
      const locationOptions = createSelectOptions(locations)

      resetForm({ fields: { ...networkDiscoveryDefaults, org } })
      onChange({
        credentials: filteredCreds,
        locations: locationOptions,
        ...(publicCred?.credentialType?.includes("SNMP") && {
          snmpCredOne: {
            ...publicCred,
            label: `${publicCred.name} ${getCredentialVersion(publicCred)}`,
            value: publicCred.id,
          },
          protocolOne: getProtocolValue(publicCred),
        }),
      })
      if (locationOptions.length === 1) handleLocationChange({ location: locationOptions[0], org })
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error fetching credentials and locations"))
    }
  }

  const handleLocationChange = async ({ location, org }) => {
    try {
      const networkProbes = await getNetworkProbes(org.id, location.value)
      onChange({
        location,
        networkProbe: null,
        networkProbes: [
          {
            nodeId: "existing",
            label: localized("Existing probes"),
            options: sortByFieldNameCaseInsensitive("nodeName", "ASC", networkProbes.existingDelegates),
          },

          {
            nodeId: "potential",
            label: localized("Potential probes"),
            options: sortByFieldNameCaseInsensitive("nodeName", "ASC", networkProbes.potentialDelegates),
          },
        ],
      })
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error fetching network probes"))
    }
  }

  const canUpdateOrg = org?.id && user("canUpdateCustomers", org.id)
  const disabledCredentialButtonTooltip = () => {
    if (noOrg) return { tooltip: localized("Select an organization to create a credential") }
    if (!canUpdateOrg)
      return {
        tooltip: localized(
          "Your user permissions do not support the creation of NMS credentials. Please contact your NinjaOne administrator for assistance.",
        ),
      }
    return {}
  }

  return (
    <>
      <Box marginTop="24px" marginBottom="16px">
        <Text size="sm" color="colorTextWeakest">
          {localized("Find devices within a network to add to an organization.")}{" "}
          <Link href={getNetworkDiscoveryDojoLink()}>
            {localized("Learn more")} <ExternalLinkIconLight size="xs" />
          </Link>
        </Text>
      </Box>

      <Box display="grid" gridTemplateColumns="1fr 1fr" gap="12px">
        <OrganizationPicker
          {...{
            changeOrganization: handleOrgChange,
            value: org,
            organization: org,
            openOnFocus: true,
            validationState: validation.message?.org ? "error" : "",
            labelAbove: true,
            hasMarginTop: false,
            customDropdownOptions: {
              useSelectStyling: true,
            },
          }}
        />

        <Flex flexDirection="column" gap={sizer(1)}>
          <Text size="sm" color={validation.message?.delegate ? "colorTextDanger" : "colorTextStrong"}>{`${localized(
            "Location",
          )} *`}</Text>
          <SearchableDropDown
            useSelectStyling
            width="100%"
            searchPlaceholderToken={localizationKey("Select")}
            disabled={noOrg}
            value={location}
            options={locations}
            onSelect={location => handleLocationChange({ location, org })}
            validationState={validation.message?.location ? "error" : ""}
            errorMessage={validation.message?.location}
          />
        </Flex>

        <Flex flexDirection="column" gap={sizer(1)}>
          <Text size="sm" color={validation.message?.delegate ? "colorTextDanger" : "colorTextStrong"}>{`${localized(
            "Network probe",
          )} *`}</Text>
          <SearchableDropDown
            useSelectStyling
            width="100%"
            searchableKey="nodeName"
            valueSelectorKey="nodeId"
            searchPlaceholderToken={localizationKey("Select")}
            disabled={!location}
            value={networkProbe}
            options={networkProbes}
            onSelect={networkProbe => onChange("networkProbe", networkProbe)}
            validationState={validation.message?.networkProbe ? "error" : ""}
            errorMessage={validation.message?.networkProbe}
          />
        </Flex>
      </Box>

      <Flex flexDirection="column" gap={sizer(1)} marginTop={sizer(3)}>
        <Text size="sm" color={validation.message?.ipTargets ? "colorTextDanger" : "colorTextStrong"}>{`${localized(
          "IP targets",
        )} *`}</Text>
        <TextArea
          placeholder={`${localized("Example")}: 192.168.1.10, 192.168.1.11 - 21, 192.16.17.30/20`}
          value={ipTargets}
          onChange={e => onChange("ipTargets", e.target.value)}
          errorMessage={validation.message?.ipTargets}
          disableMaxLength
          ariaLabel={localized("Text box for comma separated ip addresses")}
        />
      </Flex>
      <Box>
        <Text
          token={localizationKey("Comma-separated IP addresses, IP ranges or subnet(s) allowed")}
          size="xs"
          color="colorTextWeakest"
        />
      </Box>

      <Flex justifyContent="space-between" alignItems="center" marginBottom={sizer(4)} marginTop={sizer(6)}>
        <TitleGroup
          TitleComponent={() => <Text size="sm" bold token={localizationKey("Credentials")} />}
          descriptionToken={localizationKey(
            "Provide at least 1 SNMP credential type. The order of selection will be honored by server.",
          )}
        />
        <Button
          disabled={noOrg || !canUpdateOrg}
          size="sm"
          maxHeight="32px"
          variant={noOrg || !canUpdateOrg ? "primary" : "tertiary"}
          Icon={PlusIcon}
          {...disabledCredentialButtonTooltip()}
          labelToken={localizationKey("Create credential")}
          onClick={() =>
            showModal(
              <AddCredentialModal
                clientId={org?.id}
                setCredentials={credentials => onChange("credentials", credentials)}
              />,
              { withProvider: true },
            )
          }
        />
      </Flex>

      <Box display="grid" gridTemplateColumns="1fr 1fr" gridTemplateRows="repeat(3, 1fr)" gap={sizer(3)}>
        <Flex flexDirection="column" gap={sizer(1)}>
          <Text size="sm" color={validation.message?.snmpCredOne ? "colorTextDanger" : "colorTextStrong"}>{`${localized(
            "1st SNMP Credential",
          )} *`}</Text>
          <SearchableDropDown
            useSelectStyling
            width="100%"
            searchPlaceholderToken={localizationKey("Select")}
            disabled={noOrg}
            value={snmpCredOne}
            options={formattedSnmpCredentials}
            onSelect={credential => {
              onChange({
                snmpCredOne: credential,
                protocolOne: getProtocolValue(credential),
              })
            }}
            validationState={validation.message?.snmpCredOne ? "error" : ""}
            errorMessage={validation.message?.snmpCredOne}
            keepDropdownInView
            activeScrollEventListener
          />
        </Flex>

        <Flex flexDirection="column" gap={sizer(1)} gridRowStart="2">
          <Text size="sm" color="colorTextStrong" token={localizationKey("2nd SNMP Credential (Optional)")} />
          <SearchableDropDown
            useSelectStyling
            width="100%"
            searchPlaceholderToken={localizationKey("Select")}
            disabled={noOrg}
            value={snmpCredTwo}
            options={[{ label: localized("None"), value: "NONE" }, ...formattedSnmpCredentials]}
            onSelect={credential => {
              onChange({
                snmpCredTwo: credential.value === "NONE" ? null : credential,
                protocolTwo: credential.value === "NONE" ? null : getProtocolValue(credential),
              })
            }}
            keepDropdownInView
            activeScrollEventListener
          />
        </Flex>

        <Flex flexDirection="column" gap={sizer(1)} gridRowStart="3">
          <Text size="sm" color="colorTextStrong" token={localizationKey("Telnet/SSH (Optional)")} />
          <SearchableDropDown
            useSelectStyling
            width="100%"
            searchPlaceholderToken={localizationKey("Select")}
            disabled={noOrg}
            value={telnetSshCred}
            options={[{ label: localized("None"), value: "NONE" }, ...telnetCredentials]}
            onSelect={telnetSshCred => onChange("telnetSshCred", telnetSshCred.value === "NONE" ? null : telnetSshCred)}
            keepDropdownInView
            activeScrollEventListener
          />
        </Flex>
      </Box>

      <Box marginTop={sizer(6)} marginBottom={sizer(4)}>
        <StyledButtonLink
          onClick={() =>
            showModal(
              <AdvancedSettingsModal
                {...{
                  changeParentValues: onChange,
                  values,
                }}
              />,
            )
          }
        >
          <Text size="sm" token={localizationKey("Advanced settings")} />
        </StyledButtonLink>
      </Box>

      {errorMessage && (
        <Box position="sticky" bottom="-1px">
          <AlertMessage variant="danger">{errorMessage}</AlertMessage>
        </Box>
      )}
    </>
  )
}
