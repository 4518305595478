import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"
import { AlertMessage, ButtonGroup, Modal, Text } from "@ninjaone/components"
import { ExternalLinkIconLight } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import { localized } from "js/includes/common/utils"
import { Box, Flex, StyledSpan } from "js/includes/components/Styled"
import Loading from "js/includes/components/Loading"
import { AddDeviceGrid, StyledOption } from "./style"
import Link from "js/includes/components/Link"

const StyledExternalLinkIcon = styled(ExternalLinkIconLight)`
  margin-left: 2px;
`

const StyledDiv = styled.div`
  font-size: ${getTextSize("sm")};
  cursor: pointer;
`

const TitleComponent = ({ titleToken, descriptionToken, link }) => (
  <Box data-testid="add-device-title-box">
    <Text token={titleToken} color="colorTextStrong" size="lg" bold />
    <Flex>
      <Text textWrap color="colorTextStrong" size="sm">
        {localized(descriptionToken)}
        {link && (
          <StyledSpan marginLeft={sizer(1)}>
            <Link href={link}>
              <StyledDiv>
                {localized("general.learnMore")}
                <StyledExternalLinkIcon size="sm" />
              </StyledDiv>
            </Link>
          </StyledSpan>
        )}
      </Text>
    </Flex>
  </Box>
)

const OptionItem = ({ labelToken, labelText, icon, active, onClick, disabled }) => (
  <StyledOption {...{ active, onClick, disabled }} data-testid="styled-options">
    {icon}
    <Box marginLeft={sizer(2)}>
      <Text size="sm">{labelText ?? localized(labelToken)}</Text>
    </Box>
  </StyledOption>
)

const ButtonRow = ({ buttons, showLoader, loaderToken }) => {
  const theme = useTheme()
  return (
    <Flex width="100%" justifyContent="space-between" alignItems="center" float="right" data-testid="button-row">
      {showLoader && (
        <Loading
          loadingText={loaderToken}
          fontSize={getTextSize("sm")}
          color={theme.colorTextStrong}
          spinnerColor={theme.colorTextAction}
          withExtraSpace
        />
      )}
      <ButtonGroup {...{ buttons }} />
    </Flex>
  )
}

const DeviceModal = ({
  unmount,
  titleGroup,
  options,
  buttons,
  installerType,
  setInstallerType,
  OptionsComponent,
  optionsTitleToken,
  showLoader = false,
  loaderToken = "addDevices.generatingMessage",
  message = { show: false, token: "general.error" },
  minHeight,
}) => {
  return (
    <Modal
      {...{
        size: "md",
        unmount,
        withCloseX: true,
        titleGroup: {
          TitleComponent: () => <TitleComponent {...titleGroup} />,
        },
        buttonRenderer: () => (
          <ButtonRow
            {...{
              buttons: [{ type: "cancel", onClick: unmount, variant: "secondary" }, ...buttons],
              showLoader,
              loaderToken,
            }}
          />
        ),
      }}
    >
      <AddDeviceGrid {...{ minHeight }}>
        <Box>
          {options.map(({ value, icon, labelToken, labelText }) => {
            const active = value === installerType
            return (
              <OptionItem
                {...{
                  key: value,
                  active,
                  labelToken,
                  labelText,
                  icon,
                  onClick: () => setInstallerType(value),
                  disabled: showLoader,
                }}
              />
            )
          })}
        </Box>
        <Box data-testid="add-device-detail">
          {optionsTitleToken && <Text bold token={optionsTitleToken} size="sm" color="colorTextStrong" />}
          {OptionsComponent}
          {message.show && (
            <Box marginTop={sizer(4)} data-testid="alert-message">
              <AlertMessage
                {...{
                  variant: message.variant ?? "danger",
                  labelToken: message.token,
                }}
              />
            </Box>
          )}
        </Box>
      </AddDeviceGrid>
    </Modal>
  )
}

export default DeviceModal

DeviceModal.propTypes = {
  titleGroup: PropTypes.shape({
    titleToken: PropTypes.string.isRequired,
    descriptionToken: PropTypes.string.isRequired,
    link: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      labelToken: PropTypes.string,
      labelText: PropTypes.string,
      value: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
    }),
  ).isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object),
  installerType: PropTypes.string.isRequired,
  setInstallerType: PropTypes.func.isRequired,
  optionsTitleToken: PropTypes.string,
  OptionsComponent: PropTypes.element.isRequired,
  showLoader: PropTypes.bool,
  loaderToken: PropTypes.string,
  minHeight: PropTypes.string,
  message: PropTypes.shape({
    show: PropTypes.bool,
    variant: PropTypes.string,
    token: PropTypes.string,
  }),
}
