import ReactMarkdown from "react-markdown"
import styled from "@emotion/styled"

import { Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"

import { localized } from "js/includes/common/utils"

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${sizer(4)};
  color: ${({ theme }) => theme.color.black["075"]};

  p {
    margin-bottom: 0;
  }
`

const allowedElements = ["p", "em", "strong", "a"]

const TrialCountdownTitle = ({ expirationDate, accountCreatedDate }) => {
  const utcSeconds = expirationDate
  const serverDate = new Date(0)
  serverDate.setUTCSeconds(utcSeconds)

  const currentDate = new Date()
  const totalHours = Math.floor((serverDate - currentDate) / 3600000)
  const daysLeft = Math.max(Math.floor(totalHours / 24), 0)
  const accountCreated = new Date(accountCreatedDate * 1000)
  const afterJanuaryTenth = new Date(2023, 0, 10)
  let title = ""

  if (expirationDate && accountCreated >= afterJanuaryTenth && daysLeft <= 14) {
    title = localized("Your trial is ending soon")
  } else {
    title = localized("Welcome to your NinjaOne trial!")
  }

  return (
    <StyledContainer daysLeft={daysLeft} data-testid="trial-countdown-text-content">
      <Text lineHeight={sizer(4)} size="sm" bold={!daysLeft}>
        <ReactMarkdown
          {...{
            skipHtml: true,
            allowedElements,
            linkTarget: "_blank",
          }}
        >
          {title}
        </ReactMarkdown>
      </Text>
    </StyledContainer>
  )
}

export default TrialCountdownTitle
