import { memo } from "react"
import { HoverDropdown, HoverDropdownItem } from "@ninjaone/components"
import { runAdhocJob } from "js/includes/components/DeviceContextMenu/utils"
import {
  user,
  getOsPatchJobCategory,
  isPatchingFeatureEnabled,
  isWindowsDevice,
  localizationKey,
} from "js/includes/common/utils"
import { renderJobItems } from "js/includes/common/_jobs"

export default memo(function DeviceOsPatchesSubMenu({ node }) {
  const osPatchesEnabled = node?.policyContent?.patchManagement?.general?.enabled || false
  const configureWindowsUpdateEnabled = isWindowsDevice(node.nodeClass)
    ? node?.policyContent?.patchManagement?.configureWindowsUpdate?.general?.enabled || false
    : false
  const jobCategory = getOsPatchJobCategory(node.nodeClass)

  const showOSUpdate =
    osPatchesEnabled &&
    !configureWindowsUpdateEnabled &&
    node.isUp &&
    user("canUpdateDevices", node) &&
    isPatchingFeatureEnabled(node.nodeClass)

  return (
    <>
      {showOSUpdate ? (
        <HoverDropdown
          buttonRenderer={({ isOpen }) => (
            <HoverDropdownItem token={localizationKey("OS Update")} isOpen={isOpen} showArrow isDropdownButton />
          )}
          dropdownRenderer={() => (
            <ul>
              {renderJobItems(
                jobCategory,
                undefined,
                job => runAdhocJob(job, node),
                ({ jobName, onClick }) => (
                  <HoverDropdownItem plainText={jobName} onClick={onClick} />
                ),
              )}
            </ul>
          )}
          placement="right"
          fullWidth
        />
      ) : null}
    </>
  )
})
