import PropTypes from "prop-types"
import { useCallback, useEffect, useState } from "react"

import Skeleton, { skeletonElementProps, skeletonProps } from "./Skeleton"

const skeletonModes = {
  HIDDEN: "hidden",
  VISIBLE: "visible",
}

const additionalSkeletonVisibilityTime = 1000 // Additional time to show the skeleton

function SkeletonWrapper(props) {
  const { delay, children, loading, ...rest } = props

  const [skeletonMode, setSkeletonMode] = useState(skeletonModes.HIDDEN)
  const [startLoadingTime, setStartLoadingTime] = useState(null)

  const [initialDelayTimer, setInitialDelayTimer] = useState(null)
  const [additionalSkeletonVisibilityTimer, setAdditionalSkeletonVisibilityTimer] = useState(null)

  const startStates = () => {
    setSkeletonMode(skeletonModes.VISIBLE)
    setStartLoadingTime(Date.now())
  }

  const resetStates = () => {
    setStartLoadingTime(null)
    setSkeletonMode(skeletonModes.HIDDEN)
  }

  const startSkeletonDisplayTimer = useCallback(() => {
    if (!delay) {
      startStates()
      return
    }

    const timer = setTimeout(() => {
      startStates()
    }, delay)

    setInitialDelayTimer(timer)
  }, [delay])

  useEffect(() => {
    if (loading) {
      startSkeletonDisplayTimer()
    } else {
      clearTimeout(initialDelayTimer)
      clearTimeout(additionalSkeletonVisibilityTimer)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (startLoadingTime && skeletonMode === skeletonModes.VISIBLE && !loading) {
      const loadingTime = Date.now() - startLoadingTime

      if (loadingTime < delay) {
        const timer = setTimeout(() => {
          resetStates()
        }, additionalSkeletonVisibilityTime)

        setAdditionalSkeletonVisibilityTimer(timer)
      } else {
        resetStates()
      }
    }
  }, [startLoadingTime, loading, skeletonMode, delay])

  if (!loading && !startLoadingTime) return children
  if (skeletonMode === skeletonModes.HIDDEN) return null

  return <Skeleton {...rest} />
}

SkeletonWrapper.defaultProps = {
  delay: 800,
}

SkeletonWrapper.propTypes = {
  ...skeletonElementProps,
  ...skeletonProps,
  /**
   * Delay to render the component. Only available for the default mode.
   */
  delay: PropTypes.number,
  /**
   * The children to be rendered conditionally if the loading is false.
   */
  children: PropTypes.node.isRequired,
  /**
   * Controls if the children should be rendered or not.
   * This prop is only used if the children is defined.
   */
  loading: PropTypes.bool,
}

export default SkeletonWrapper
