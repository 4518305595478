import { reportErrorAndShowMessage } from "js/includes/common/utils"
import { getPendingDevices, getApprovedDevices, getRejectedDevices } from "js/includes/common/client"
import { startLoading, stopLoading } from "js/state/actions/common/loading"

export default function tabsHandler(dispatch) {
  return {
    onChangeTab: activeTab =>
      dispatch({
        type: "NODE_APPROVALS_CHANGE_TAB",
        activeTab,
      }),
    onChangeSelected: (selected, tabName) =>
      dispatch({
        type: "NODE_APPROVALS_CHANGE_SELECTED",
        selected,
        tabName,
      }),
    onChangeFilterText: (filterText, tabName) =>
      dispatch({
        type: "NODE_APPROVALS_CHANGE_FILTER_TEXT",
        filterText,
        tabName,
      }),
    refreshTabs: async clientId => {
      try {
        dispatch(startLoading("loadingNodeApprovalsStatusTabs")())

        const [pending = [], approved = [], rejected = []] = clientId
          ? await Promise.all([getPendingDevices(clientId), getApprovedDevices(clientId), getRejectedDevices(clientId)])
          : []

        await dispatch({
          type: "NODE_APPROVALS_REFRESH",
          nodes: {
            pending,
            approved,
            rejected,
          },
        })
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        dispatch(stopLoading("loadingNodeApprovalsStatusTabs")())
      }
    },
  }
}
