import React, { memo, Fragment } from "react"
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box } from "js/includes/components/Styled"

export default memo(function SubMenu({ justification, label, children, customStyle }) {
  function getJustification() {
    const orientation = justification === "right" ? justification : "left"
    return `${orientation}-justify`
  }

  function getJustifiedContent() {
    const justification = getJustification()
    if (justification === "left-justify") {
      return (
        <Fragment>
          {label}
          <FontAwesomeIcon icon={faChevronRight} fixedWidth />
        </Fragment>
      )
    } else if (justification === "right-justify") {
      return (
        <Fragment>
          <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
          {label}
        </Fragment>
      )
    }
    throw new Error(`"${justification}" is invalid`)
  }

  return (
    <li className="nested-hover-dropdown no-padding">
      <button className="btn-link nested-hover-dropdown justify-content-between">{getJustifiedContent()}</button>
      <Box {...{ ...customStyle }} className="nested-hover-dropdown-content-container">
        <ul className="hover-dropdown-content no-padding no-margin">{children}</ul>
      </Box>
    </li>
  )
})
