import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"

import tokens from "@ninjaone/tokens"

import RadioItem from "./RadioItem"
import RadioLabel from "./RadioLabel"

import { getRadioAriaLabel, shouldIgnoreProps } from "./utils"

const OptionContainer = styled("div", {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  width: 100%;

  display: flex;
  gap: ${tokens.spacing[3]};
  align-items: ${({ descriptionMode }) => (!descriptionMode ? "center" : "")};
`

function RadioOption({ descriptionMode, value, label, description, extraContentRenderer, disabled }) {
  const ariaLabel = getRadioAriaLabel({ label, description })

  return (
    <OptionContainer {...{ descriptionMode }}>
      <RadioItem {...{ value, disabled, ariaLabel }} />

      <RadioLabel {...{ value, label, description, extraContentRenderer, descriptionMode }} />
    </OptionContainer>
  )
}

export default RadioOption
