import $ from "jquery"
import _ from "underscore"
import rivets from "rivets"
import "jquery-ui/ui/widgets/tooltip"

const tooltip = {
  routine: function(el, value) {
    const bound = $(el).data("rivets.binders.tooltip.bound")
    if (!bound) {
      _.defer(function() {
        const templatePath = $(el).data("tooltip-template")
        if (templatePath) {
          const template = _.template($("#" + templatePath).html())
          $(el).data("title", template())
        }

        const delay = $(el).data("delay")
        const placement = $(el).data("placement")
        $(el)
          .tooltip({
            placement: placement || "top",
            container: "body",
            delay: {
              show: delay && delay.show ? delay.show : 500,
              hide: delay && delay.hide ? delay.hide : 250,
            },
          })
          .on("shown.bs.tooltip", function() {
            const tooltip = $("#" + $(this).attr("aria-describedby"))
            const binding = rivets.bind($(tooltip), {
              model: value,
            })
            $(el).data("rivets.binders.tooltip.binding", binding)
          })
          .on("hidden.bs.tooltip", function() {
            const binding = $(el).data("rivets.binders.tooltip.binding")
            if (binding) {
              binding.unbind()
            }
          })

        $(el).data("rivets.binders.tooltip.bound", true)
      })
    }
  },
  unbind: function(el) {
    const bound = $(el).data("rivets.binders.tooltip.bound")
    if (bound) {
      $(el).tooltip("destroy")
      $(el).removeData()
      $(el).off()
      return true
    }
  },
}

export default tooltip
