const faVmGuest = {
  prefix: "fac",
  iconName: "vmGuest",
  icon: [
    200,
    200,
    [],
    "0028",
    `M175.88,0H72.39C59.07,0,48.28,10.8,48.28,24.12v24.16H24.12C10.8,48.28,0,59.07,0,72.39v103.49
     C0,189.2,10.8,200,24.12,200h103.49c13.32,0,24.12-10.8,24.12-24.12v-24.16h24.16c13.32,0,24.12-10.8,24.12-24.12V24.12
     C200,10.8,189.2,0,175.88,0z M121.63,156.54c0,7.38-5.99,13.37-13.37,13.37h-64.8c-7.38,0-13.37-5.99-13.37-13.37v-64.8
     c0-7.38,5.99-13.37,13.37-13.37h4.81v49.24c0,13.32,10.8,24.12,24.12,24.12h49.24V156.54z M121.63,121.63H91.74
     c-7.38,0-13.37-5.99-13.37-13.37V78.37h29.89c7.38,0,13.37,5.99,13.37,13.37V121.63z M169.91,108.26c0,7.38-5.99,13.37-13.37,13.37
     h-4.81V72.39c0-13.32-10.8-24.12-24.12-24.12H78.37v-4.81c0-7.38,5.99-13.37,13.37-13.37h64.8c7.38,0,13.37,5.99,13.37,13.37
     V108.26z`,
  ],
}

export default faVmGuest
