import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { HoverDropdownItem } from "@ninjaone/components"
import { updateDeviceDetails as _updateDeviceDetails } from "js/state/actions/deviceSearch/search"
import { setPerformingDeviceAction as _setPerformingDeviceAction } from "js/state/actions/deviceSearch/performingDeviceAction"
import { getSelectedFilteredDevicesIds } from "js/state/selectors/deviceSearch/devices"
import { clearAllItems as _clearAllItems } from "js/state/actions/common/selectedListItems"
import { resetAlerts } from "js/includes/common/services/deviceHealth"
import { localizationKey } from "js/includes/common/utils"

const ResetAlertsButton = ({
  selectedFilteredDevicesIds,
  setPerformingDeviceAction,
  clearAllItems,
  updateDeviceDetails,
  fromDeviceSearch,
}) => (
  <HoverDropdownItem
    onClick={() => {
      resetAlerts(
        selectedFilteredDevicesIds,
        setPerformingDeviceAction,
        clearAllItems,
        updateDeviceDetails,
        fromDeviceSearch,
      )
    }}
    token={localizationKey("Reset Alerts")}
  />
)

export default connect(
  ({ deviceSearch }, ownProps) => ({
    selectedFilteredDevicesIds: deviceSearch.selectedDevices?.length
      ? getSelectedFilteredDevicesIds(deviceSearch)
      : ownProps.selectedFilteredDevicesIds,
  }),
  {
    setPerformingDeviceAction: _setPerformingDeviceAction,
    clearAllItems: _clearAllItems("devices"),
    updateDeviceDetails: _updateDeviceDetails,
  },
)(ResetAlertsButton)

ResetAlertsButton.propTypes = {
  selectedFilteredDevicesIds: PropTypes.arrayOf(PropTypes.number),
  allSelectedDevicesHaveAlerts: PropTypes.bool,
  setPerformingDeviceAction: PropTypes.func,
  updateDeviceDetails: PropTypes.func,
}
