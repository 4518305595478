/** @jsxImportSource @emotion/react */
import { PureComponent } from "react"

import styled from "@emotion/styled"
import moment from "moment"
import Datetime from "react-datetime"
import { FormGroup, Col, ControlLabel, FormControl } from "react-bootstrap"
import { mergeDeepRight } from "ramda"
import Select from "react-select"
import { localized, serverScheduleDateFormat, getDateLocale, getNMonthsDateFromNow } from "js/includes/common/utils"

const ScheduleEndStyled = styled.div`
  display: flex;
  flex-flow: column;
`

export default class ScheduleEnd extends PureComponent {
  changeOccurrencesEnd = value => {
    const { schedule, updateSchedule } = this.props
    const end = { occurenceCount: value || 1 }
    updateSchedule(mergeDeepRight(schedule, { end }))
  }

  changeEndType = (type, dateFormat) => {
    const { schedule, updateSchedule } = this.props
    const end = {
      type,
      ...(["NEVER", "AFTER"].includes(type) && { endDate: null }),
      ...(type === "ON" && { endDate: getNMonthsDateFromNow(1, dateFormat) }),
    }
    updateSchedule(mergeDeepRight(schedule, { end }))
  }

  changeEndDate = datetime => {
    const { schedule, updateSchedule, dateFormat = serverScheduleDateFormat, isValidEndDate } = this.props
    const date = getDateLocale(datetime)
    if (date.isValid() && (!isValidEndDate || isValidEndDate(date))) {
      const end = { endDate: date.format(dateFormat) }
      updateSchedule(mergeDeepRight(schedule, { end }))
    } else {
      updateSchedule(schedule)
    }
  }

  render() {
    const { schedule, endOptions, dateFormat = serverScheduleDateFormat, isValidEndDate } = this.props
    const { endDate, type, occurenceCount } = schedule.end
    const endDateTime = moment(endDate, dateFormat)

    return (
      <ScheduleEndStyled>
        <FormGroup>
          <Col componentClass={ControlLabel} xs={3} className="text-align-left">
            <span>{localized("Ends")}</span>
          </Col>
          <Col xs={9}>
            <Select
              name="ends"
              value={endOptions.find(option => option.value === type)}
              options={endOptions}
              onChange={e => this.changeEndType(e.value, dateFormat)}
              placeholder={localized("Select...")}
              getOptionLabel={option => option.text()}
            />
          </Col>
        </FormGroup>
        {type === "AFTER" && (
          <FormGroup>
            <Col componentClass={ControlLabel} xs={3} className="text-align-left">
              <span>{localized("Occurrences")}</span>
            </Col>
            <Col xs={7}>
              <FormControl
                className="form-control"
                type="number"
                min={1}
                value={occurenceCount}
                onChange={e => this.changeOccurrencesEnd(Number(e.target.value))}
              />
            </Col>
          </FormGroup>
        )}
        {type === "ON" && (
          <FormGroup>
            <Col componentClass={ControlLabel} xs={3} className="text-align-left">
              <span>{localized("End Date")}</span>
            </Col>
            <Col xs={9}>
              <Datetime
                value={getDateLocale(endDateTime)}
                timeFormat={false}
                onChange={this.changeEndDate}
                isValidDate={isValidEndDate}
              />
            </Col>
          </FormGroup>
        )}
      </ScheduleEndStyled>
    )
  }
}
