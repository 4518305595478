import { PureComponent } from "react"
import { ninjaReportError } from "js/includes/common/utils"

export default class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    ninjaReportError({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}
