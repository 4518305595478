import {
  defaultTo,
  prop,
  ifElse,
  find,
  has,
  propEq,
  __,
  assoc,
  assocPath,
  compose,
  dissoc,
  evolve,
  map,
  mapObjIndexed,
  pick,
  filter,
  not,
  isEmpty,
  when,
  isNil,
  identity,
} from "ramda"
import {
  fetch,
  fetchJson,
  showErrorMessage,
  showSuccessMessage,
  localized,
  localizationKey,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { startLoading, stopLoading } from "js/state/actions/common/loading"
import { show, hide } from "js/state/actions/common/visibility"
import { getConnectWiseTicketValueOptions } from "js/state/selectors/psa/ConnectWise/ticketing"
import { getAutotaskTicketValueOptions } from "js/state/selectors/psa/Autotask/ticketing"
import { getAutoTaskBillingCodes } from "js/includes/common/client"

export const setTicketTemplateList = payload => ({
  type: "SET_TICKET_TEMPLATE_LIST",
  payload,
})

export const setDefaultTicketTemplateId = payload => ({
  type: "SET_DEFAULT_TICKET_TEMPLATE_ID",
  payload,
})

export function saveDefaultTicketTemplateId(id) {
  return async dispatch => {
    try {
      dispatch(startLoading("PSATicketTemplateModal")())

      const response = await fetchJson("/divisionconfig/psa")

      const body = compose(
        JSON.stringify,
        assocPath(["content", "defaultTicketTemplateId"], id),
        dissoc("resultCode"),
      )(response)

      await fetch("/divisionconfig/psa", { options: { method: "PUT", body } })

      dispatch(setDefaultTicketTemplateId(id))
    } catch (error) {
      showErrorMessage(localized("Error saving default ticket template"))
      throw error
    } finally {
      dispatch(hide("PSADefaultTicketTemplateModal")())
      dispatch(stopLoading("PSATicketTemplateModal")())
    }
  }
}

function getMatchingValueFromOptions(value) {
  return options => {
    if (!value) return value

    const getValueFromOptions = compose(find(__, options), propEq("id"), prop("id"))

    if (Array.isArray(value)) {
      return map(getValueFromOptions, value)
    } else if (has("changeTo", value)) {
      return evolve(
        {
          changeTo: getValueFromOptions,
          ifMatches: map(getValueFromOptions),
        },
        value,
      )
    } else if (has("action", value)) {
      // For onRetrigger
      return value
    } else {
      return getValueFromOptions(value)
    }
  }
}

export function getTicketTemplate(ticketTemplateId) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: "CLEAR_TICKET_TEMPLATE_EDITOR_VALUES" })
      dispatch(startLoading("PSATicketTemplateModal")())
      dispatch(show("PSATicketTemplateModal")())
      await dispatch(getTicketSetupValues())

      const {
        ticketTemplate: { id, name, content },
      } = await fetchJson(`/tickettemplate/${ticketTemplateId}`)
      const { ticketTitleCustomText } = content

      // Value validation before rendering editor
      dispatch({ type: "SET_TICKET_TEMPLATE", payload: content })

      const editorValues = compose(
        when(_ => !!ticketTitleCustomText, assoc("ticketTitleCustomText", ticketTitleCustomText)),
        mapObjIndexed((value, key) =>
          compose(
            ifElse(
              isNil,
              defaultTo(null),
              when(
                has("changeTo"),
                evolve({
                  changeTo: defaultTo(null),
                  ifMatches: map(defaultTo(null)),
                }),
              ),
            ),
            getMatchingValueFromOptions(value),
            ifElse(
              window.psaConnect.isAutotask,
              getAutotaskTicketValueOptions(getState()),
              getConnectWiseTicketValueOptions(getState()),
            ),
          )(key),
        ),
        dissoc("id"),
        dissoc("ticketTitleCustomText"),
      )(content)

      dispatch({
        type: "SET_TICKET_TEMPLATE_EDITOR_VALUES",
        editorValues: { id: id, name: name, ...editorValues },
      })

      dispatch(stopLoading("PSATicketTemplateModal")())
    } catch (error) {
      showErrorMessage(localized("Error fetching ticket template"))
      throw error
    }
  }
}

export function deleteTicketTemplate(ticketTemplateId) {
  return async dispatch => {
    try {
      await fetch(`/tickettemplate/${ticketTemplateId}`, { options: { method: "DELETE" } })
      window.application
        .get("infoCollectionContainer")
        .get("psaTicketTemplates")
        .fetch()
    } catch (error) {
      showErrorMessage(localized("Error deleting ticket template"))
      throw error
    }
  }
}

export function testTicketTemplate(ticketTemplateId, clientId) {
  const psaType = window.psaConnect.get("psaType") === "CONNECTWISE" ? "psacw" : "psaat"
  return async dispatch => {
    try {
      await fetch(`/${psaType}/v2/gentestticket/${ticketTemplateId}/${clientId}`)
      showSuccessMessage(localized("Test ticket submitted for creation"))
    } catch (error) {
      showErrorMessage(localized("Error creating test ticket"))
      throw error
    }
  }
}

function getTicketSetupValues() {
  return async (dispatch, getState) => {
    if (getState().psa.ticketSetupValues) {
      return
    }
    try {
      let response = {}

      if (window.psaConnect.isAutotask()) {
        // TODO remove /psaat/v2/setupvalues/billing-code when it supports pagination (use it in the dropdown).
        const [setupValues, billingCodesConfig] = await Promise.all([
          fetchJson("/psaat/v2/setupvalues/ticket"),
          getAutoTaskBillingCodes(),
        ])
        response = {
          ...setupValues,
          fieldConfig: {
            ...setupValues.fieldConfig,
            ...billingCodesConfig.fieldConfig,
          },
        }
      } else {
        response = await fetchJson("/psacw/v2/service/setupvalues")
      }

      dispatch({
        type: "SET_TICKET_SETUP_VALUES",
        payload: response,
      })
    } catch (error) {
      showErrorMessage(localized("Error fetching setup values"))
      throw error
    }
  }
}

export function openNewTicketTemplateModal(isNew) {
  return async (dispatch, getState) => {
    dispatch(startLoading("PSATicketTemplateModal")())
    dispatch(show("PSATicketTemplateModal")())
    dispatch({ type: "CLEAR_TICKET_TEMPLATE_EDITOR_VALUES" })
    await dispatch(getTicketSetupValues())
    dispatch(stopLoading("PSATicketTemplateModal")())
  }
}

const isNotNil = compose(not, isNil)
const isNotEmpty = compose(not, isEmpty)
const prepare = when(isNotNil, pick(["id", "name"]))

const prepareConnectwiseValues = evolve({
  serviceBoard: prepare,
  boardStatus: prepare,
  boardPriority: prepare,
  boardType: prepare,
  boardSubType: prepare,
  boardItem: prepare,
  boardTeam: prepare,
  source: prepare,
  severity: prepare,
  priority: prepare,
  impact: prepare,
  createAndClose: prepare,
  onReset: {
    changeTo: prepare,
    ifMatches: map(prepare),
  },
})

const prepareAutotaskValues = evolve({
  IssueType: prepare,
  Priority: prepare,
  QueueID: prepare,
  Source: prepare,
  Status: prepare,
  SubIssueType: prepare,
  ServiceLevelAgreementID: prepare,
  TicketType: prepare,
  ChangeApprovalType: prepare,
  ChangeApprovalStatus: prepare,
  MonitorTypeID: prepare,
  TicketCategory: prepare,
  allocationCode: prepare,
  createAndClose: prepare,
  ticketTitleElements: map(prepare),
  onReset: {
    changeTo: prepare,
    ifMatches: map(prepare),
  },
  onRetrigger: {
    action: identity,
    fields: evolve({
      Priority: prepare,
      QueueID: prepare,
      Status: prepare,
      allocationCode: prepare,
      IssueType: prepare,
      SubIssueType: prepare,
      Source: prepare,
      ServiceLevelAgreementID: prepare,
      TicketType: prepare,
      ChangeApprovalType: prepare,
      ChangeApprovalStatus: prepare,
      MonitorTypeID: prepare,
      TicketCategory: prepare,
    }),
  },
})

function prepareTicketTemplate(name) {
  return compose(
    JSON.stringify,
    assoc("content", __, { name }),
    when(window.psaConnect.isConnectWise, prepareConnectwiseValues),
    when(window.psaConnect.isAutotask, prepareAutotaskValues),
    filter(isNotEmpty),
    dissoc("name"),
    dissoc("id"),
  )
}

function updateTicketTemplate(id, body) {
  return fetchJson(`/tickettemplate/${id}`, { options: { method: "PUT", body } })
}

function createTicketTemplate(body) {
  return fetchJson("/tickettemplate/", { options: { method: "POST", body } })
}

export function saveTicketTemplate(values) {
  return async dispatch => {
    dispatch(startLoading("PSATicketTemplateModal")())

    const { name, id } = values
    const body = prepareTicketTemplate(name)(values)

    try {
      const { ticketTemplateId, resultCode, errorMessage } = id
        ? await updateTicketTemplate(id, body)
        : await createTicketTemplate(body)
      if (resultCode === "FAILURE") {
        throw new Error(errorMessage)
      }
      const ticketTemplates = await window.application
        .get("infoCollectionContainer")
        .get("psaTicketTemplates")
        .fetch()
      if (ticketTemplates && ticketTemplates.length === 1) {
        // If saving first ticket template, set it as the default
        dispatch(saveDefaultTicketTemplateId(id || ticketTemplateId))
      }

      dispatch(hide("PSATicketTemplateModal")())
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error saving ticket template"))
      throw error
    } finally {
      dispatch(stopLoading("PSATicketTemplateModal")())
    }
  }
}
