import { ConfirmationModal, Text } from "@ninjaone/components"

import { sendActionMultipleDevices } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import {
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  runDeviceSearchToolbarAction,
  showSuccessMessage,
} from "js/includes/common/utils"

export const RenewProfileModal = ({ unmount, nodeIds, runnerActionName }) => {
  const [loading, setLoading] = useMountedState(false)

  const renewProfile = async () => {
    setLoading(true)
    try {
      if (runnerActionName) {
        await runDeviceSearchToolbarAction({ action: runnerActionName })
      } else {
        await sendActionMultipleDevices(nodeIds, { type: "RENEW_ENROLLMENT_PROFILE" })
      }
      showSuccessMessage(localized("Renew action sent successfully"))
      unmount?.()
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        reportErrorAndShowMessage(error, localizationKey("Error sending the renew profile action"))
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <ConfirmationModal
      {...{ unmount }}
      titleToken={localizationKey("Renew the enrollment profile for this device?")}
      actionToken={localizationKey("Renew")}
      onConfirm={renewProfile}
      disabled={loading}
      onCancel={unmount}
    >
      <Text
        token={localizationKey(
          "This action will re-install the enrollment profile with the latest certificate. The device functionality will not be affected.",
        )}
        textWrapLineLimit={3}
        textWrap
      />
    </ConfirmationModal>
  )
}
