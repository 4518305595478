import $ from "jquery"
import _ from "underscore"

const icheckbox = {
  routine: function(el, value) {
    var isDisabled = $(el).data("rivets.binders.icheckbox.isDisabled")
    var minimalSkin = false
    var options
    if ($(el).attr("disabled")) {
      $(el)
        .parent()
        .addClass("disabled")
    } else {
      $(el)
        .parent()
        .removeClass("disabled")
    }

    if (!isDisabled) {
      $(el).data("rivets.binders.icheckbox.isDisabled", true)
      var initialized = $(el).data("rivets.binders.icheckbox.initialized")
      minimalSkin = $(el).data("rv-minimal-skin")
      if (minimalSkin) {
        options = {
          checkboxClass: "icheckbox_minimal",
        }
      } else {
        options = {
          colorBlue: $(el).data("color-blue"),
          checkboxClass: "icheckbox_square-blue",
        }
      }
      if (!initialized) {
        $(el).iCheck(options)
        $(el).data("rivets.binders.icheckbox.initialized", true)
      }
      $(el).iCheck(value ? "check" : "uncheck")
      $(el).data("rivets.binders.icheckbox.isDisabled", false)
    }
  },

  bind: function(el) {
    //var adapter = rivets.adapters[':'];
    var self = this
    var paths = self.keypath.split(":")

    this.callback = function(e) {
      var isDisabled = $(el).data("rivets.binders.icheckbox.isDisabled")
      if (!isDisabled) {
        $(el).data("rivets.binders.icheckbox.isDisabled", true)
        self.model.set(paths[paths.length - 1], $(el).prop("checked"))
        $(el).data("rivets.binders.icheckbox.isDisabled", false)
      }
    }
    $(el).data("rivets.binders.icheckbox.initialized", false)
    $(el).on("ifChanged", this.callback)

    // We need to defer the following functionality as we need to wait
    // for the el to render and calculate the disabled attribute via rivets
    // before its updated value is available
    _.defer(function() {
      if ($(el).attr("disabled")) {
        $(el)
          .parent()
          .addClass("disabled")
      } else {
        $(el)
          .parent()
          .removeClass("disabled")
      }
    })
  },

  unbind: function(el) {
    $(el).off("ifChanged")
    $(el).iCheck("destroy")
    $(el).removeData()
  },
}

export default icheckbox
