import { useEffect, useMemo } from "react"
import { T, always, cond } from "ramda"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { AndroidIcon, AppleIcon, ExternalLinkIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { AlertMessage } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"

import Loading from "js/includes/components/Loading"
import { useMounted, useMountedState } from "js/includes/common/hooks"
import {
  FeatureFlag,
  isAndroidDevice,
  isAndroidMDMAppEnabled,
  isAndroidMDMFeatureEnabled,
  isAppleMDMAppEnabled,
  isAppleMDMFeatureEnabled,
  isAppleMobileDevice,
  localizationKey,
  localized,
  localizedWith,
  ninjaReportError,
  user,
} from "js/includes/common/utils"
import { getAdvancedInstallerOptionsStatus, getAndroidEnrollmentToken } from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import MobileDeviceTypePicker from "js/includes/components/MobileDeviceTypePicker"
import DeviceModal from "js/includes/components/DeviceModal"
import AddDeviceOrganizationLocation from "js/includes/dashboards/AddDeviceOrganizationLocation"
import MobileQRModal from "js/includes/components/DeviceModal/MobileQRModal"
import { generateAppleEnrollmentFile } from "js/includes/common/client"
import { Box, Flex } from "js/includes/components/Styled"
import MDMConnectionPicker from "./MDMConnectionPicker"

const getComputerHeadingToken = installerType =>
  installerType === "ANDROID" ? localizationKey("Android configuration") : localizationKey("Apple configuration")

const showQRCodeModal = ({ data, organization, location, isIos, nodeRoleId, isPersonalAndWorkUsage, connectionId }) =>
  showModal(
    <MobileQRModal {...{ data, organization, location, isIos, nodeRoleId, isPersonalAndWorkUsage, connectionId }} />,
  )

const getInstallerNodeClasses = cond([
  [isAndroidDevice, always(["ANDROID"])],
  [isAppleMobileDevice, always(["APPLE_IOS", "APPLE_IPADOS"])],
  [T, always([])],
])

const initialNodeRoleOption = { label: localized("Auto"), value: "auto" }

const ConfigAlertLink = ({ localizedText, to }) => (
  <Link {...{ to }} target="_blank">
    {localizedText} <ExternalLinkIcon size="xs" />
  </Link>
)

const ConfigAlertTypes = {
  appIsDisabled: "appIsDisabled",
}

const configAlertText = appFlag => {
  const isApple = appFlag === FeatureFlag.mdm_apple
  const app = isApple ? "Apple" : "Android"
  const route = isApple ? "mdmApple" : "mdmAndroid"

  const linkText = localizedWith("NinjaOne MDM <%app> <%> app", { app })

  return {
    [ConfigAlertTypes.appIsDisabled]: {
      titleToken: isApple
        ? localizationKey("Enable NinjaOne MDM Apple app")
        : localizationKey("Enable NinjaOne MDM Android app"),
      message: localizedWith(
        "To be able to enroll <%app> <%> mobile devices you first need to enable the <%link> <%> and proceed with its configuration.",
        {
          app,
          link: () => <ConfigAlertLink {...{ localizedText: linkText }} to={`/administration/apps/${route}`} />,
        },
      ),
    },
    // TODO: App enabled without connections NJ-71082, NJ-73822
    // appIsEnabledWithoutConnections
  }
}

export const OrgSetToRejectWarning = ({ organization }) => (
  <AlertMessage variant="warning">
    {localized("The selected organization is set to reject new devices by default.")}
    &nbsp;
    {user("canUpdateCustomers", organization.id) &&
      localizedWith("Go to <%link>org's configurations<%> to change this behavior", {
        link: ({ localizedText }) => (
          <Link to={`/editor/customer/${organization.id}/security-node-approvals`}>{localizedText}</Link>
        ),
      })}
  </AlertMessage>
)

const AddMobileDeviceModal = ({ unmount, mdmConfiguration }) => {
  const deviceOptions = useMemo(
    () => [
      ...(isAndroidMDMFeatureEnabled()
        ? [
            {
              labelText: "Android",
              value: "ANDROID",
              icon: <AndroidIcon fontSize="20px" />,
            },
          ]
        : []),
      ...(isAppleMDMFeatureEnabled()
        ? [
            {
              labelText: "Apple",
              value: "APPLE_IOS",
              icon: <AppleIcon fontSize="20px" />,
            },
          ]
        : []),
    ],
    [],
  )
  const mobileTypeOptions = useMemo(
    () => [
      { value: false, label: localized("For work") },
      { value: true, label: localized("For personal and work") },
    ],
    [],
  )
  const [installerType, setInstallerType] = useMountedState(deviceOptions?.[0]?.value)
  const [isGenerating, setIsGenerating] = useMountedState(false)
  const [location, setLocation] = useMountedState({})
  const [organization, setOrganization] = useMountedState({})
  const [mobileType, setMobileType] = useMountedState({})
  const [generationError, setGenerationError] = useMountedState({ show: false })
  const [selectedNodeRole, setSelectedNodeRole] = useMountedState(initialNodeRoleOption)
  const [isAdvancedInstallerEnabled, setIsAdvancedInstallerEnabled] = useMountedState(false)
  const [isLoading, setIsLoading] = useMountedState(true)
  const [mdmConnection, setMDMConnection] = useMountedState(null)
  const mounted = useMounted()

  const isIos = isAppleMobileDevice(installerType)
  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        // TODO: data optimization, everytime this modal opens we do this fetch
        const advancedInstallerStatus = await getAdvancedInstallerOptionsStatus()
        setIsAdvancedInstallerEnabled(advancedInstallerStatus)
      } catch (error) {
        ninjaReportError(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [setIsAdvancedInstallerEnabled, setIsLoading])

  useEffect(() => {
    setMDMConnection(null)
  }, [isIos, setMDMConnection])

  const configAlert = useMemo(() => {
    // App disabled
    if (isIos ? !isAppleMDMAppEnabled() : !isAndroidMDMAppEnabled()) {
      return configAlertText(isIos ? FeatureFlag.mdm_apple : FeatureFlag.mdm_android).appIsDisabled
    }

    // TODO: App enabled without connections NJ-71082, NJ-73822

    return null
  }, [isIos])

  const handleGeneration = async () => {
    setGenerationError({ show: false })
    setIsGenerating(true)
    try {
      const nodeRoleId = isAdvancedInstallerEnabled ? selectedNodeRole?.value : "auto"
      const data = isIos
        ? await generateAppleEnrollmentFile({
            clientId: organization.id,
            locationId: location.value,
            nodeRole: nodeRoleId,
            pushCertificateId: mdmConnection.value,
          })
        : await getAndroidEnrollmentToken({
            organizationId: organization.id,
            locationId: location.value,
            allowPersonalUsage: mobileType.value,
            nodeRole: nodeRoleId,
            connectionId: mdmConnection.value,
          })
      mounted.current &&
        showQRCodeModal({
          data,
          organization,
          location,
          isIos,
          nodeRoleId: selectedNodeRole.value,
          isPersonalAndWorkUsage: mobileType.value,
          connectionId: mdmConnection.value,
        })
    } catch (error) {
      setGenerationError({
        show: true,
        messageToken:
          error.resultCode === "client_push_certificate_expired"
            ? localizationKey("Error generating QR code. The Apple Push Notification certificate has expired.")
            : localizationKey("Error generating QR code. Please try again."),
      })
      ninjaReportError(error)
    } finally {
      setIsGenerating(false)
    }
  }

  const orgRejectEnrollment = organization?.approvalMode === "REJECT"
  const installerNodeClasses = getInstallerNodeClasses(installerType)
  const shouldDisabledButton =
    (!organization?.id && !location?.id) ||
    isGenerating ||
    orgRejectEnrollment ||
    (!isIos && !mobileType?.label) ||
    !!configAlert ||
    (!isIos && !mdmConnection)

  return (
    <DeviceModal
      {...{
        unmount,
        titleGroup: {
          titleToken: localizationKey("Add a mobile device"),
          descriptionToken: localizationKey("Generate a QR code to install NinjaOne on mobile devices."),
          // TODO: add links once added to the Dojo
        },
        buttons: [
          {
            labelToken: localizationKey("Generate QR code"),
            disabled: shouldDisabledButton,
            onClick: handleGeneration,
            variant: "primary",
          },
        ],
        showLoader: isGenerating,
        options: deviceOptions,
        installerType,
        setInstallerType: type => {
          setGenerationError({ show: false })
          setInstallerType(type)
          setSelectedNodeRole(initialNodeRoleOption)
        },
        optionsTitleToken: getComputerHeadingToken(installerType),
        message: {
          show: generationError.show,
          token: generationError.messageToken,
        },
        OptionsComponent: isLoading ? (
          <Flex height="100%" justifyContent="center" alignItems="center">
            <Loading withExtraSpace />
          </Flex>
        ) : (
          <>
            {!!configAlert && (
              <Box margin={[spacing[4], spacing[0]]}>
                <AlertMessage titleToken={configAlert.titleToken}>{configAlert.message}</AlertMessage>
              </Box>
            )}
            <AddDeviceOrganizationLocation
              {...{
                location,
                setLocation: location => {
                  setGenerationError({ show: false })
                  setLocation(location)
                },
                organization,
                setOrganization: org => {
                  setGenerationError({ show: false })
                  setOrganization(org)
                },
                generatingInstaller: isGenerating,
                hideNodeRolePicker: !isAdvancedInstallerEnabled,
                selectedNodeRole,
                setSelectedNodeRole,
                installerNodeClasses,
                searchParams: ({ query }) => ({
                  ...(query && { name: query }),
                }),
              }}
            />
            {!isIos && (
              <MobileDeviceTypePicker
                {...{
                  disabled: isGenerating,
                  options: mobileTypeOptions,
                  selected: mobileType,
                  onChange: mobileType => {
                    setGenerationError({ show: false })
                    setMobileType(mobileType)
                  },
                  required: true,
                }}
              />
            )}
            <MDMConnectionPicker
              {...{
                installerType,
                disabled: isGenerating,
                selected: mdmConnection,
                onChange: setMDMConnection,
              }}
            />
            {orgRejectEnrollment && (
              <Box marginTop={sizer(4)}>
                <OrgSetToRejectWarning {...{ organization }} />
              </Box>
            )}
          </>
        ),
      }}
    />
  )
}

export default connect(({ session }) => ({
  mdmConfiguration: session.divisionConfigs?.mdm,
}))(AddMobileDeviceModal)
