import { useCallback, useEffect } from "react"
import { connect } from "react-redux"
import { reduce, pick, omit } from "ramda"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { sizer } from "@ninjaone/utils"
import { AlertMessage, Modal, Tabs, Text } from "@ninjaone/components"
import ButtonGroup from "@ninjaone/components/src/ButtonGroup"
import tokens from "@ninjaone/tokens"
import { useForm, useMountedState } from "js/includes/common/hooks"
import { Box, Flex, StyledFontAwesomeIcon } from "js/includes/components/Styled"
import { getScriptCategoriesSelectOptions } from "js/state/selectors/scripting/scriptCategories"
import {
  bytes,
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import { saveUrlAttachmentAutomation } from "js/state/actions/scripting/scripts"
import {
  ARCHITECTURE_ALL,
  ARCHITECTURE_64,
  ARCHITECTURE_32,
  installApplicationValidations,
  WINDOWS_OS,
  MAC_OS,
  fetchAutomationsResource,
  INSTALLER_URL,
  INSTALLER_FILE,
} from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/utils"
import InstallApplicationGeneralTab from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/InstallApplicationGeneralTab"
import InstallApplicationAdditionalSettingsTab from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/InstallApplicationAdditionalSettingsTab"
import AutomationSubmittedModal from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/AutomationSubmittedModal"
import UploadingFilesModal from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/UploadingFilesModal"
import {
  getScriptAttachments,
  getSignedUrlFromAttachmentId,
  createApplicationAutomationPreUpload,
  createInstallApplicationAutomation,
  updateInstallApplicationAutomation,
} from "js/includes/common/client"
import { requestAllScriptCategories as _requestAllScriptCategories } from "js/state/actions/scripting/scriptCategories"
import { requestAllScripts as _requestAllScripts } from "js/state/actions/scripting/scripts"
import showModal from "js/includes/common/services/showModal"
import { uncategorizedIfNoCategories } from "js/includes/configuration/scripting/scriptingUtils"

function InstallApplicationModal({
  editingInstallApplication = {},
  scriptCategories = [],
  scriptsList,
  unmount,
  requestAllScripts,
  requestAllScriptCategories,
  setAutomationsUnderReview,
}) {
  const {
    values: {
      idInstallApplication,
      name,
      description,
      file,
      url,
      urlId,
      categories,
      architecture,
      parameters,
      useFirstParametersOptionAsDefault,
      credential,
      os,
      helpers,
      installerIcon,
      pre,
      post,
    },
    validation,
    validateForm,
    onChange,
    updateValidate,
    validateFormFields,
  } = useForm({
    fields: {
      idInstallApplication: "",
      name: editingInstallApplication.name || "",
      description: editingInstallApplication.description || "",
      file: editingInstallApplication.file || null,
      url: "",
      urlId: null,
      categories: editingInstallApplication.categoriesIds?.map(cat => cat.toString()) || [],
      architecture:
        editingInstallApplication.architecture?.length > 1
          ? ARCHITECTURE_ALL
          : editingInstallApplication.architecture?.[0] || ARCHITECTURE_ALL,
      parameters: editingInstallApplication.scriptParameters || [],
      useFirstParametersOptionAsDefault: editingInstallApplication.useFirstParametersOptionAsDefault || false,
      credential: editingInstallApplication.binaryInstallSettings?.runAs || "",
      os: editingInstallApplication.operatingSystems?.[0] || WINDOWS_OS,
      helpers: [],
      installerIcon: null,
      pre: null,
      post: null,
      uploadType: "",
    },
    validate: installApplicationValidations(),
  })

  const [installerErrorMessage, setInstallerErrorMessage] = useMountedState("")
  const [errorMessage, setErrorMessage] = useMountedState("")
  const [duplicateNameMessage, setDuplicateNameMessage] = useMountedState("")
  const [missingData, setMissingData] = useMountedState(false)
  const [saving, setSaving] = useMountedState(false)
  const mfaCancel = "mfa-cancel"

  const getAttachmentData = useCallback(data => {
    return {
      id: data.id,
      metadata: {
        name: data.fileName,
        extension: data.extension,
        size: bytes(data.size),
        sizeBytes: data.size,
        mimeType: data.mimeType,
      },
      uploadStatus: "SUCCESS",
    }
  }, [])

  const getIconData = useCallback(
    async data => {
      if (!data) {
        return null
      }

      const icon = getAttachmentData(data)
      const { signedUrl } = await getSignedUrlFromAttachmentId(data.id)

      return { ...icon, signedUrl }
    },
    [getAttachmentData],
  )

  useEffect(() => {
    if (!editingInstallApplication?.id) return

    async function getAttachments(scriptId) {
      const findScript = script => {
        if (!script) return null
        const scriptData = scriptsList.find(({ id }) => id === script.scriptId)
        const { name } = scriptData

        return { ...script, name }
      }

      try {
        const attachments = await getScriptAttachments(scriptId)
        let uploadType = INSTALLER_FILE

        if (attachments.runnableAttachment?.url) {
          uploadType = INSTALLER_URL
          onChange({ url: attachments.runnableAttachment.url, urlId: attachments.runnableAttachment.id, uploadType })
        } else {
          onChange({ file: getAttachmentData(attachments.runnableAttachment), uploadType })
        }
        const { preExecutionScript, postExecutionScript } = editingInstallApplication?.binaryInstallSettings
        updateValidate(installApplicationValidations(uploadType))
        onChange({
          idInstallApplication: editingInstallApplication.id,
          helpers: attachments.helperAttachments.length > 0 ? attachments.helperAttachments.map(getAttachmentData) : [],
          installerIcon: await getIconData(attachments.customIconAttachment),
          pre: findScript(preExecutionScript),
          post: findScript(postExecutionScript),
        })
      } catch (error) {
        reportErrorAndShowMessage(error)
      }
    }

    !idInstallApplication && getAttachments(editingInstallApplication.id)
  }, [
    editingInstallApplication,
    idInstallApplication,
    scriptsList,
    onChange,
    getIconData,
    getAttachmentData,
    updateValidate,
  ])

  useEffect(() => {
    onChange({ file: null, ...(os === MAC_OS && { architecture: ARCHITECTURE_64 }) })
    setInstallerErrorMessage("")
  }, [os, onChange, setInstallerErrorMessage])

  const cancelUpload = () => setSaving(false)

  const parseScriptToPayload = script => {
    if (!script) return script
    return {
      ...omit(["name"], script),
      ...(script.scriptVariables?.length
        ? {
            scriptVariables: script.scriptVariables.map(scriptVariable =>
              pick(["name", "calculatedName", "type", "value", "source"], scriptVariable),
            ),
          }
        : {}),
    }
  }

  const completeSaveData = async completedUploadFiles => {
    let runnableAttachmentIdUrl = urlId
    if (url && !urlId) {
      runnableAttachmentIdUrl = await saveUrlAttachmentAutomation(url)
    }

    const idHelpers = reduce((data, helper) => (helper?.id ? [...data, helper.id] : data), [], helpers)

    let binaryInstallSettings = {
      ...(runnableAttachmentIdUrl && { runnableAttachmentId: runnableAttachmentIdUrl }),
      ...(file?.id && { runnableAttachmentId: file.id }),
      ...(installerIcon?.id && { customIconAttachmentId: installerIcon.id }),
      helperFilesAttachmentIds: idHelpers,
    }

    completedUploadFiles.forEach(data => {
      const { key, id } = data

      switch (key) {
        case "installer":
          binaryInstallSettings.runnableAttachmentId = id
          break
        case "icon":
          binaryInstallSettings.customIconAttachmentId = id
          break
        default:
          binaryInstallSettings.helperFilesAttachmentIds = [...binaryInstallSettings.helperFilesAttachmentIds, id]
      }
    })

    const body = {
      architecture: architecture === ARCHITECTURE_ALL ? [ARCHITECTURE_64, ARCHITECTURE_32] : [architecture],
      scriptParameters: parameters,
      useFirstParametersOptionAsDefault,
      name,
      description,
      categoriesIds: uncategorizedIfNoCategories(categories),
      language: "binary_install",
      operatingSystems: [os],
      binaryInstallSettings: {
        ...binaryInstallSettings,
        preExecutionScript: parseScriptToPayload(pre),
        postExecutionScript: parseScriptToPayload(post),
        runAs: credential,
      },
    }

    try {
      editingInstallApplication.id
        ? await updateInstallApplicationAutomation({
            ...body,
            id: editingInstallApplication.id,
          })
        : await createInstallApplicationAutomation(body)

      unmount()
      showSuccessMessage()
      showModal(<AutomationSubmittedModal />)
      await fetchAutomationsResource({ requestAllScripts, requestAllScriptCategories, setAutomationsUnderReview })
    } catch (error) {
      setSaving(false)
      setErrorMessage(localized("Something went wrong. Please try again."))
    }
  }

  const callPreUploadMfa = async () => {
    try {
      const bodyPre = {
        architecture: architecture === ARCHITECTURE_ALL ? [ARCHITECTURE_64, ARCHITECTURE_32] : [architecture],
        name,
        categoriesIds: uncategorizedIfNoCategories(categories),
        operatingSystems: [os],
        runAs: credential,
      }

      await createApplicationAutomationPreUpload(bodyPre)
    } catch (error) {
      throw new Error(mfaCancel)
    }
  }

  const validateData = () => {
    setErrorMessage("")

    !url && !file && setInstallerErrorMessage(localized("Choose an installer file"))

    const isValid = validateForm() && !duplicateNameMessage && (url || file)
    setMissingData(!isValid)

    return isValid
  }

  const saveInstallApplication = async () => {
    if (!validateData()) return

    setSaving(true)

    try {
      await callPreUploadMfa()
      let filesToUpload = []

      if (!url && !file?.id) {
        filesToUpload.push({ key: "installer", ...file })
      }
      if (helpers.length) {
        helpers.forEach((fileData, i) => !fileData.id && filesToUpload.push({ key: `helper-${i}`, ...fileData }))
      }
      if (installerIcon && !installerIcon.id) {
        filesToUpload.push({ key: "icon", ...installerIcon })
      }

      showModal(<UploadingFilesModal {...{ filesToUpload, cancelUpload, afterUpload: completeSaveData }} />)
    } catch (error) {
      setSaving(false)
      if (error.message !== mfaCancel) {
        setErrorMessage(localized("Something went wrong. Please try again."))
      }
    }
  }

  return (
    <Modal
      unmount={unmount}
      size="lg"
      titleGroup={{
        titleToken: localizationKey("Install Application"),
        descriptionToken: localizationKey("Automate app installation by defining an install automation"),
      }}
      buttonRenderer={() => (
        <Flex width="100%">
          <Box>
            {saving && (
              <Flex>
                <StyledFontAwesomeIcon icon={faSpinner} spin fontSize={sizer(6)} className="txt-color-blue m-r-sm" />
                <Text size="sm" bold color="black" token={localizationKey("Processing… This may take a minute")} />
              </Flex>
            )}
          </Box>
          <ButtonGroup
            {...{
              buttons: [
                { type: "cancel", onClick: unmount, variant: "secondary" },
                {
                  type: "save",
                  labelToken: localizationKey("Submit"),
                  onClick: saveInstallApplication,
                  disabled: saving,
                },
              ],
            }}
          />
        </Flex>
      )}
    >
      <Box height="740px">
        <Tabs
          tabs={[
            {
              labelToken: localizationKey("General"),
              displayError: missingData,
              renderer: () => (
                <InstallApplicationGeneralTab
                  {...{
                    scriptCategories,
                    automationId: editingInstallApplication?.id,
                    name,
                    description,
                    file,
                    url,
                    categories,
                    architecture,
                    parameters,
                    useFirstParametersOptionAsDefault,
                    credential,
                    os,
                    onChange,
                    validation,
                    updateValidate,
                    validateFormFields,
                    installApplicationValidations,
                    installerErrorMessage,
                    setInstallerErrorMessage,
                    duplicateNameMessage,
                    setDuplicateNameMessage,
                  }}
                />
              ),
            },
            {
              labelToken: localizationKey("Additional Settings"),
              renderer: () => (
                <InstallApplicationAdditionalSettingsTab
                  setAdditionalSettings={data => onChange(data)}
                  additionalSettings={{ helpers, installerIcon, pre, post }}
                  os={[os]}
                />
              ),
            },
          ]}
        />
      </Box>
      {errorMessage && (
        <Box marginTop={tokens.spacing[5]}>
          <AlertMessage variant="danger" labelToken={errorMessage} />
        </Box>
      )}
    </Modal>
  )
}

export default connect(
  state => ({
    scriptCategories: getScriptCategoriesSelectOptions(state),
    scriptsList: state.scripting.scripts,
  }),
  {
    requestAllScripts: _requestAllScripts,
    requestAllScriptCategories: _requestAllScriptCategories,
  },
)(InstallApplicationModal)
