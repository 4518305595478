const faNinja = {
  prefix: "fac",
  iconName: "ninja",
  icon: [
    82,
    101,
    [],
    "0100",
    `M40.932,2.091c-3.048,1.048 -7.524,3.048 -10,4.476c-5.715,3.238 -5.905,3.238 -5.905,-0.952c0,-4.381 0.952,-4.667 -13.048,2.762l-12,6.19l0,30.572c0,16.857 0.133,55.187 0.133,55.187l26.201,-0.043l0.143,-34.954c0.761,-38.666 0.381,-36.571 7.904,-40.476c2.191,-1.048 6.476,-2.19 9.524,-2.476c10.476,-0.952 11.048,1.333 11.524,44.667l0.372,33.047l25.342,0.095l-0.381,-39.809c-0.381,-45.714 -0.19,-44.857 -9.333,-53.143c-8,-7.333 -18.857,-9.143 -30.476,-5.143Z`,
  ],
}

export default faNinja
