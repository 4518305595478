import { useRef, useMemo, useEffect, useCallback } from "react"
import { useMountedState } from "js/includes/common/hooks"

export const useCountDown = (timeToCount = 60 * 1000, interval = 1000, onComplete) => {
  const [timeLeft, setTimeLeft] = useMountedState(0)
  const timer = useRef({})

  const run = useCallback(
    startTime => {
      if (!timer.current.started) {
        timer.current.started = startTime
        timer.current.lastInterval = startTime
      }

      const localInterval = Math.min(interval, timer.current.timeLeft || Infinity)
      if (startTime - timer.current.lastInterval >= localInterval) {
        timer.current.lastInterval += localInterval
        setTimeLeft(timeLeft => {
          timer.current.timeLeft = timeLeft - localInterval
          return timer.current.timeLeft
        })
      }

      if (startTime - timer.current.started < timer.current.timeToCount) {
        timer.current.requestId = window.requestAnimationFrame(run)
      } else {
        timer.current = {}
        setTimeLeft(0)
        onComplete?.()
      }
    },
    [interval, onComplete, setTimeLeft],
  )

  const start = useCallback(
    timeToComplete => {
      window.cancelAnimationFrame(timer.current.requestId)

      const newTimeToCount = timeToComplete !== undefined ? timeToComplete : timeToCount
      timer.current.started = null
      timer.current.lastInterval = null
      timer.current.timeToCount = newTimeToCount
      timer.current.requestId = window.requestAnimationFrame(run)

      setTimeLeft(newTimeToCount)
    },
    [run, timeToCount, setTimeLeft],
  )

  const reset = useCallback(() => {
    if (timer.current.timeLeft) {
      window.cancelAnimationFrame(timer.current.requestId)
      timer.current = {}
      setTimeLeft(0)
    }
  }, [setTimeLeft])

  const pause = useCallback(() => {
    window.cancelAnimationFrame(timer.current.requestId)
    timer.current.started = null
    timer.current.lastInterval = null
    timer.current.timeToCount = timer.current.timeLeft
  }, [])

  const resume = useCallback(() => {
    if (!timer.current.started && timer.current.timeLeft > 0) {
      window.cancelAnimationFrame(timer.current.requestId)
      timer.current.requestId = window.requestAnimationFrame(run)
    }
  }, [run])

  const actions = useMemo(() => ({ start, reset, pause, resume }), [start, reset, pause, resume])

  useEffect(() => reset, [reset])

  return [timeLeft, actions]
}
