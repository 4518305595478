import $ from "jquery"

const linkspeed = function(el, value) {
  // eslint-disable-next-line
  if (value != undefined && value != null) {
    if (value >= 1000000000) {
      $(el).text((value / 1000000000).toFixed(1) + " Gbps")
    } else if (value >= 1000000) {
      $(el).text((value / 1000000).toFixed(1) + " Mbps")
    } else {
      $(el).text((value / 1000).toFixed(1) + " Kbps")
    }
  }
}

export default linkspeed
