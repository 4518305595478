import { Modal, TitleGroup } from "@ninjaone/components"
import { localizationKey } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import showModal from "js/includes/common/services/showModal"
import { steps } from "./utils"
import { Setup } from "./Setup"
import { Discovery } from "./Discovery"
import { Review } from "./Review"
import { DiscoveryButtons } from "./DiscoveryButtons"
import { DiscoveryDescription } from "./DiscoveryDescription"
import { useNetworkDiscovery } from "./NetworkDiscoveryContext"
import { StopDiscoveryModal } from "./modals/StopDiscoveryModal"

export const NetworkDiscoveryModal = () => {
  const { unmount: unmountParentModal, currentIndex, stopDiscovery, loading } = useNetworkDiscovery()

  return (
    <Modal
      id="network-discovery"
      size="lg"
      unmount={unmountParentModal}
      buttonRenderer={DiscoveryButtons}
      closeAction={() => {
        if (!loading) {
          return unmountParentModal()
        }

        showModal(
          <StopDiscoveryModal
            messageToken={localizationKey(
              "Closing this window will cause all progress to be lost. Are you sure you wish to continue?",
            )}
            stopDiscovery={stopDiscovery}
            unmountParentModal={unmountParentModal}
          />,
        )
      }}
    >
      <Box padding="0 2px">
        <TitleGroup
          color="colorTextStrong"
          titleToken={localizationKey("Network discovery (Ad Hoc)")}
          DescriptionComponent={DiscoveryDescription}
        />
      </Box>

      <Flex height="596px" flexDirection="column" overflow="auto" padding="0px 2px">
        {(() => {
          switch (steps[currentIndex]) {
            case "DISCOVERY":
              return <Discovery />
            case "REVIEW":
              return <Review />
            default:
              return <Setup />
          }
        })()}
      </Flex>
    </Modal>
  )
}
