import { fetchJson, localized, ninjaReportError, showErrorMessage } from "js/includes/common/utils"

export const fetchCustomerDashboard = async (customerId, location, throwError) => {
  try {
    const response = await fetchJson(`/query/v2/customerdashboard/${customerId}?location=${location}`)
    const { runningJobs, systemHealth } = response
    const { dispatch } = window.store

    dispatch({
      type: "SET_RUNNING_JOBS_AND_DEVICE_HEALTH",
      runningJobs,
      systemHealth,
    })
  } catch (error) {
    showErrorMessage(localized("Error"))
    ninjaReportError(error)
    if (throwError) {
      throw error
    }
  }
}

export const fetchCustomerDevicesCount = async customerId => {
  try {
    const response = await fetchJson(`/client/${customerId}/deviceCount`)
    const { deviceCount } = response
    return deviceCount
  } catch (error) {
    showErrorMessage(localized("Error"))
    ninjaReportError(error)
  }
}
