import { memo } from "react"
import { faFileExclamation, faInfoCircle, faSpinner, faTimes } from "@fortawesome/pro-solid-svg-icons"
import styled from "@emotion/styled"
import pathParse from "path-parse"
import { always, cond, equals, T } from "ramda"

import { Text } from "@ninjaone/components"
import { color } from "@ninjaone/themes/base"
import { sizer } from "@ninjaone/utils"

import { getReadableBytes } from "js/includes/common/_conversions"
import { getSignedUrlFromAttachmentId } from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import {
  bytes,
  downloadFile,
  getIconProps,
  IMAGE_EXTENSIONS,
  localizationKey,
  localized,
  ninjaReportError,
} from "js/includes/common/utils"
import Indicator from "js/includes/components/Indicator"
import { Flex, StyledFontAwesomeIcon } from "js/includes/components/Styled"
import { StyledButton } from "js/includes/components/Styled/Form"
import Tooltip from "js/includes/components/Tooltip"

import { ImagePreviewModal } from "./ImagePreviewModal"

const ErrorStatus = ({ icon, token = localizationKey("There was an error while uploading the attachment") }) => (
  <Tooltip output={localized(token)}>
    <StyledFontAwesomeIcon fontSize={7} icon={icon} marginRight={1} color="ninjaRed" />
  </Tooltip>
)

export const getUploadStatusIcon = (attachmentData, customIcons) =>
  cond([
    [
      ({ uploadStatus }) => equals(uploadStatus, "PROCESSING"),
      always(customIcons?.PROCESSING ? <customIcons.PROCESSING /> : <Indicator noText />),
    ],
    [({ uploadStatus }) => equals(uploadStatus, "FAILURE"), always(<ErrorStatus icon={faInfoCircle} />)],
    [
      ({ uploadStatus }) => equals(uploadStatus, "SUSPICIOUS"),
      always(
        <ErrorStatus
          icon={faFileExclamation}
          token={localizationKey("This attachment has been flagged as suspicious")}
        />,
      ),
    ],
    [T, ({ extension }) => <StyledFontAwesomeIcon {...getIconProps(extension, 7)} color="ninjaBlueSaturated" />],
  ])(attachmentData)

const getFileLabel = (extension, size) => {
  return `${extension.toUpperCase()} - ${!!Number(size) ? getReadableBytes(size) : size}`
}

const StyledDeleteButton = styled(StyledButton)`
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

export const Attachment = memo(
  ({ attachment, file, onDelete, download = true, customIcons = {}, width = "100%", loading }) => {
    const MAX_FILE_SIZE_IN_BYTES = 104857600
    const fileSize = file?.size
    const defaultAttachment = {
      id: null,
      metadata: {
        name: file?.name || "",
        mimeType: file?.type,
        size: bytes(fileSize) || "",
        extension:
          (file?.name &&
            pathParse(file?.name)
              .ext.replace(".", "")
              .toLowerCase()) ||
          "",
      },
      uploadStatus: fileSize > MAX_FILE_SIZE_IN_BYTES ? "FAILURE" : "SUCCESS",
    }

    const {
      id: attachmentId,
      uploadStatus,
      contentId,
      metadata: { name, size, extension },
    } = attachment || defaultAttachment

    const isProcessing = uploadStatus === "PROCESSING"
    const isFailure = ["FAILURE", "SUSPICIOUS"].includes(uploadStatus)

    const onDownload = async () => {
      try {
        const { signedUrl } = await getSignedUrlFromAttachmentId(attachmentId)

        if (signedUrl) {
          downloadFile(signedUrl, name)
        }
      } catch (error) {
        ninjaReportError(error)
      }
    }

    const handleClick = () => {
      if (IMAGE_EXTENSIONS.includes(extension.toLowerCase())) {
        showModal(<ImagePreviewModal {...{ onDownload, attachmentId, contentId }} />)
      } else {
        onDownload()
      }
    }

    return (
      <Flex
        width={width}
        padding="1px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor={isFailure ? "ninjaRed" : "ninjaLight"}
        borderRadius="4px"
        minWidth="56px"
        backgroundColor={color.background}
        alignItems="center"
        justifyContent="center"
        minHeight="56px"
        marginBottom={sizer(1)}
        {...{
          ...(!isProcessing &&
            !isFailure && {
              hoverCursor: "pointer",
              hoverBorderColor: color.gray,
              ...(download && { onClick: handleClick }),
            }),
          ...(isProcessing && { opacity: 0.5 }),
        }}
      >
        <Flex
          borderRadius="4px"
          minWidth={sizer(14)}
          width="56px"
          height="56px"
          backgroundColor={color.lightGray}
          alignItems="center"
          justifyContent="center"
          color="ninjaBlueDark"
        >
          {loading ? (
            <StyledFontAwesomeIcon fontSize={7} icon={faSpinner} spin={true} />
          ) : (
            getUploadStatusIcon({ uploadStatus, extension }, customIcons)
          )}
        </Flex>
        <Flex
          flex={1}
          flexDirection="column"
          justifyContent="center"
          marginLeft={sizer(4)}
          marginRight={sizer(4)}
          className="text-ellipsis"
          width="0"
        >
          {loading ? (
            <Text bold size="sm" color="text" token={localizationKey("Loading...")} />
          ) : (
            <>
              <Text color={isFailure ? "red" : "text"} size="sm">
                {name}
              </Text>
              <Text fontSize={2} color="darkGray" size="sm">
                {getFileLabel(extension, size)}
              </Text>
            </>
          )}
        </Flex>
        {onDelete && !loading && (
          <StyledDeleteButton
            aria-label={localized("Delete")}
            width={40}
            alignSelf="center"
            padding={2}
            marginLeft={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            hoverTextColor="ninjaRed"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              onDelete(attachment)
            }}
            data-testid="delete-attachment-button"
          >
            <StyledFontAwesomeIcon size="md" icon={faTimes} />
          </StyledDeleteButton>
        )}
      </Flex>
    )
  },
)
