import PropTypes from "prop-types"
import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"

import Button from "./Button"
import Body from "./Typography/Body"
import Heading from "./Typography/Heading"
import SkeletonWrapper from "./Skeleton/SkeletonWrapper"

const StyledCard = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colorBackground};
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  border-radius: ${tokens.borderRadius[1]};

  padding: ${tokens.spacing[6]};

  max-width: 1200px;

  & > div {
    max-width: 80%;
  }

  & > div > p {
    margin-top: ${tokens.spacing[1]};
  }
`

const StyledParamsContainer = styled.dl`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacing[2]};

  // Overrinding the bootstrap style.
  margin-bottom: unset;

  margin-top: ${tokens.spacing[4]};

  & > div {
    display: flex;
  }

  dt {
    max-width: 200px;

    width: ${({ customWidth }) => customWidth || "160px"};
    min-width: ${({ customWidth }) => customWidth || "160px"};

    display: flex;
    align-items: center;
  }
`

const StyledActionsContainer = styled.div`
  display: flex;
  gap: ${tokens.spacing[1]};

  margin-top: -${tokens.spacing[2]};
  margin-right: -${tokens.spacing[2]};
`

const SettingsCard = ({ title, description, params, actions, options }) => (
  <StyledCard>
    <div>
      <Heading
        {...{ level: 2, type: "headingS", fontWeight: tokens.typography.fontWeight.medium, color: "colorTextStrong" }}
      >
        {title}
      </Heading>
      {description && <Body {...{ as: "p", color: "colorTextWeakest", textWrap: true }}>{description}</Body>}

      <StyledParamsContainer {...{ customWidth: options?.labelWidth }}>
        {params.map((param, index) => (
          <div {...{ key: `${index}-${param.value}` }}>
            <Body {...{ as: "dt", color: "colorTextWeakest", textWrap: true, textWrapLineLimit: 2 }}>
              {param.label}
            </Body>

            <SkeletonWrapper {...{ loading: param?.loading, width: "400px" }}>
              <Body {...{ as: "dd", color: "colorTextStrong", textWrap: true, textWrapLineLimit: 1 }}>
                {param.value}
              </Body>
            </SkeletonWrapper>
          </div>
        ))}
      </StyledParamsContainer>
    </div>

    <StyledActionsContainer>
      {actions.map((currentAction, index) => (
        <Button
          {...{
            onClick: currentAction.action,
            labelText: currentAction.label,
            disabled: currentAction?.disabled,
            key: `${index}-${currentAction.label}`,
            type: currentAction?.type || "tertiary",
          }}
        />
      ))}
    </StyledActionsContainer>
  </StyledCard>
)

SettingsCard.propTypes = {
  /**
   * Object containing params to add custom options.
   */
  options: PropTypes.shape({ labelWidth: PropTypes.string }),
  /**
   * The title of the card.
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /**
   * The description of the card.
   */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Parameters array for the card component.
   */
  params: PropTypes.arrayOf(
    PropTypes.shape({
      loading: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  /**
   * Array of action buttons for the card.
   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Text to be displayed on the action button.
       */
      label: PropTypes.string.isRequired,
      /**
       * Type of the action button.
       */
      type: PropTypes.string,
      /**
       * Function to be called when the action button is clicked.
       */
      action: PropTypes.func.isRequired,
      /**
       * The disabled state for the button
       */
      disabled: PropTypes.bool,
    }).isRequired,
  ),
}

export default SettingsCard
