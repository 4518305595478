import { memo, useMemo } from "react"
import { sizer } from "@ninjaone/utils"
import { Box } from "js/includes/components/Styled"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { localized } from "js/includes/common/utils"
import { StyledLabel } from "js/includes/components/DeviceModal/style"

const MonitorDelegatePicker = memo(
  ({ disabled, onChange, validateForm, selected, clientId, isHyperV, validationState }) => {
    const [endpoint, token] = useMemo(() => {
      return isHyperV
        ? [`/virtualization/hyperv/delegates/${clientId}`, "general.hyperVHost"]
        : [`/virtualization/esxi/delegates/${clientId}`, "addDevices.monitorDelegate"]
    }, [isHyperV, clientId])
    return (
      <Box marginTop={sizer(3)}>
        <StyledLabel {...{ htmlFor: "monitor-delegate-selector", errorMessage: validationState === "error" }}>
          {localized(token)} *
        </StyledLabel>
        <SearchableDropDown
          {...{
            id: "monitor-delegate-selector",
            isMulti: false,
            onSelect: onChange,
            value: selected,
            openOnFocus: true,
            searchableKey: "displayName",
            valueSelectorKey: "displayName",
            dataFormatter: value => ({ ...value, label: value.displayName }),
            endpoint,
            pageSize: 50,
            noRowsRendererToken: "editorsCustomerCustomerEditor.noConnectedDevices",
            searchPlaceholderToken: "addDevices.selectDelegate",
            validationState,
            getInitialValue: () => selected?.displayName,
            keepInView: false,
            disabled,
            width: "100%",
            minHeight: "42px",
            rowHeight: 40,
            onBlur: () => validateForm(["monitorDelegate"]),
            errorMessage: localized("general.required"),
          }}
        />
      </Box>
    )
  },
)

export default MonitorDelegatePicker
