import { createSelector } from "reselect"
import { prop, unionWith, eqBy } from "ramda"

export const getSystemHealthStatusConfigFor = healthStatusName => state =>
  getSystemHealthStatusConfig(state).find(i => i.name === healthStatusName)

export const getSystemHealthStatusConfig = createSelector(
  state => state.general.customHealthStatus,
  state => state.application.defaultDivisionConfig.defaultCustomHealth,
  (customHealth, defaultCustomHealth) => unionWith(eqBy(prop("name")), customHealth, defaultCustomHealth),
)
