/** @jsxImportSource @emotion/react */
import { Component } from "react"

import styled from "@emotion/styled"
import { range, prop, mergeRight, min, compose, defaultTo, when, lt, always, __ } from "ramda"
import Select from "react-select"
import { FormGroup, Col, ControlLabel, FormControl } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import WeekDaysCheckboxes from "js/includes/components/WeekDaysCheckboxes"
import { StyledFormControl } from "js/includes/components/Styled/Form"

const ScheduleRepeatsStyled = styled.div`
  display: flex;
  flex-flow: column;
`

const getIntervalOptions = () => [{ value: "HOURLY", text: localized("Hours"), min: 1, max: 23 }]

export default class ScheduleRepeats extends Component {
  changeRepeatsType = repeats => {
    const { schedule, updateSchedule } = this.props
    updateSchedule(mergeRight(schedule, { repeats, repeatsEveryCount: 1 }))
  }

  changeWeekDaysSelection = days => {
    if (!days.length) return

    const { schedule, updateSchedule } = this.props
    schedule.daysOfWeek = days
    updateSchedule(mergeRight(schedule, { daysOfWeek: days }))
  }

  getDaysOfMonth = () => {
    const days = range(1, 31).map(num => {
      return { value: num, text: num }
    })
    days.push({ value: 0, text: localized("last") })
    return days
  }

  changeDayOfMonth = day => {
    const { schedule, updateSchedule } = this.props
    schedule.dayOfMonth = Number(day)
    updateSchedule(mergeRight(schedule, { dayOfMonth: Number(day) }))
  }

  changeRecurrence = (value, repeats) => {
    const { schedule, updateSchedule } = this.props
    const integerValue = Number(value.replace(/\D/g, ""))
    const repeatsEveryCount = compose(
      when(lt(__, 1), always(1)),
      min(repeats.maxRecurrence),
      defaultTo(1),
    )(integerValue)
    updateSchedule(mergeRight(schedule, { repeatsEveryCount }))
  }

  changeInterval = value => {
    const { schedule, updateSchedule } = this.props
    updateSchedule(mergeRight(schedule, { repeatsEveryCount: parseInt(value, 10) }))
  }
  changeScheduleInterval = scheduleInterval => {
    const { schedule, updateSchedule } = this.props
    updateSchedule(mergeRight(schedule, { scheduleInterval }))
  }

  render() {
    const { schedule, repeatsOptions, repeatsDisabled, visibleRecurEvery } = this.props
    const repeatsValue = repeatsOptions.find(option => option.value === schedule.repeats) ?? repeatsOptions[0]
    const intervalValue = getIntervalOptions()?.find(({ value }) => value === schedule.scheduleInterval || "HOURLY")

    return (
      <ScheduleRepeatsStyled>
        <FormGroup>
          <Col componentClass={ControlLabel} xs={3} className="text-align-left">
            <span>{localized("Repeats")}</span>
          </Col>
          <Col xs={9}>
            <Select
              name="repeats"
              value={repeatsValue}
              options={repeatsOptions}
              onChange={e => this.changeRepeatsType(e.value)}
              placeholder={localized("Select...")}
              getOptionLabel={option => option.text()}
              isDisabled={repeatsDisabled}
            />
          </Col>
        </FormGroup>
        {schedule.repeats === "WEEKLY" && (
          <FormGroup>
            <Col componentClass={ControlLabel} xs={3} className="text-align-left">
              <span>{localized("On Days")}</span>
            </Col>
            <Col xs={9}>
              <WeekDaysCheckboxes selectedDays={schedule.daysOfWeek} changeSelection={this.changeWeekDaysSelection} />
            </Col>
          </FormGroup>
        )}
        {schedule.repeats === "MONTHLY" && (
          <FormGroup>
            <Col componentClass={ControlLabel} xs={3} className="text-align-left">
              <span>{localized("Day of Month")}</span>
            </Col>
            <Col xs={9}>
              <Select
                name="day"
                value={this.getDaysOfMonth().find(option => option.value === schedule.dayOfMonth)}
                options={this.getDaysOfMonth()}
                onChange={e => this.changeDayOfMonth(e.value)}
                placeholder={localized("Select...")}
                getOptionLabel={prop("text")}
              />
            </Col>
          </FormGroup>
        )}
        {schedule.repeats === "EVERY" && (
          <FormGroup>
            <Col componentClass={ControlLabel} xs={3} className="text-align-left">
              <span>{localized("Recur Every")}</span>
            </Col>
            <Col xs={9} className="p-l-none p-r-none">
              <Col xs={6}>
                <StyledFormControl
                  height="38px"
                  className="form-control"
                  type="number"
                  min={intervalValue.min}
                  max={intervalValue.max}
                  value={schedule.repeatsEveryCount || ""}
                  onChange={e => {
                    const value = e.target.value
                    if (value === "") {
                      this.changeInterval("")
                    } else {
                      const constrainedValue = Math.max(
                        intervalValue.min,
                        Math.min(intervalValue.max, parseInt(e.target.value, 10)),
                      )
                      this.changeInterval(constrainedValue)
                    }
                  }}
                  onBlur={e => e.target.value === "" && this.changeInterval(intervalValue.min)}
                  onKeyDown={e => ["e", "E", "-", "+", "."].includes(e.key) && e.preventDefault()}
                />
              </Col>
              <Col xs={6}>
                <Select
                  name="intervalOptions"
                  value={intervalValue}
                  options={getIntervalOptions()}
                  onChange={e => this.changeScheduleInterval(e.value)}
                  getOptionLabel={prop("text")}
                />
              </Col>
            </Col>
          </FormGroup>
        )}
        {visibleRecurEvery && repeatsValue.hasRecurrence && (
          <FormGroup>
            <Col componentClass={ControlLabel} xs={3} className="text-align-left">
              <span>{localized("Recur every")}</span>
            </Col>
            <Col xs={4}>
              <FormControl
                className="form-control"
                type="number"
                min={1}
                max={repeatsValue && repeatsValue.maxRecurrence}
                value={schedule.repeatsEveryCount}
                onChange={e => this.changeRecurrence(e.target.value, repeatsValue)}
              />
            </Col>
            <Col xs={2} className="p-t-md">
              <p>{repeatsValue && repeatsValue.recurrenceText()}</p>
            </Col>
          </FormGroup>
        )}
      </ScheduleRepeatsStyled>
    )
  }
}
