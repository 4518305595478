import PropTypes from "prop-types"
import { find, map, propEq } from "ramda"
import { HoverDropdown, HoverDropdownItem, Text } from "@ninjaone/components"
import {
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  runDeviceSearchToolbarAction,
  showSuccessMessage,
} from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import showModal from "js/includes/common/services/showModal"
import { sendActionMultipleDevices } from "js/includes/common/client"
import { ActionId } from "js/includes/mobileDashboard/actionModals/util"
import { AddIOSAppModal } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/applications/AddIOSAppModal"

export default function MobileDeviceSubMenuButtons({
  actions,
  isAppleDevice,
  nodeIds,
  userCanDeleteDevice = false,
  userCanUpdateDevice = false,
  fromDeviceSearch = false,
}) {
  const addApp = async payload => {
    const action = {
      type: "INSTALL_APPLICATION",
      bundleId: payload?.bundleId,
      allowUserUninstallation: payload?.allowUserUninstallation ?? true,
      forceManagement: payload?.forceManagement ?? false,
      removeAppOnUnenrollment: payload?.removeAppOnUnenrollment ?? false,
    }
    try {
      if (fromDeviceSearch) {
        await runDeviceSearchToolbarAction({
          action: "RUN_SOFTWARE_INSTALL_APPS",
          data: { mobileAction: action },
        })
      } else {
        await sendActionMultipleDevices(nodeIds, action)
      }
      showSuccessMessage(localized("App installation activity scheduled successfully"))
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        reportErrorAndShowMessage(
          error,
          localizationKey("The App Installation Activity could not be scheduled at this time"),
        )
      }
    }
  }

  const eraseAction = find(propEq("id", ActionId.ERASE_DEVICE), actions)
  const filterAction = action => {
    switch (action.id) {
      case ActionId.ERASE_DEVICE:
        return userCanDeleteDevice
      case ActionId.SYNC_POLICY:
        return userCanUpdateDevice
      default:
        return true
    }
  }
  const installAction = {
    token: localizationKey("Install apps"),
    id: "INSTALL_APPS",
    className: "nested-hover-dropdown",
    onClick: () =>
      showModal(<AddIOSAppModal onAddApp={addApp} type="ACTION" />, {
        withProvider: true,
      }),
  }

  return (
    <Box>
      {actions.length > 0 && (
        <HoverDropdown
          buttonRenderer={({ isOpen }) => (
            <HoverDropdownItem token={localizationKey("Security actions")} isOpen={isOpen} showArrow isDropdownButton />
          )}
          dropdownRenderer={() => (
            <>
              {map(
                ({ id, token, onClick, disabled }) =>
                  id === ActionId.ERASE_DEVICE ? (
                    <HoverDropdownItem
                      {...{
                        key: id,
                        textRenderer: () => <Text {...{ token: eraseAction.token, color: "red", size: "sm" }} />,
                        onClick,
                        disabled,
                        id,
                        splitBefore: true,
                      }}
                    />
                  ) : (
                    <HoverDropdownItem {...{ key: id, token, onClick, disabled, id }} />
                  ),
                actions.filter(filterAction),
              )}
            </>
          )}
          placement="right"
          fullWidth
        />
      )}
      {isAppleDevice && (
        <HoverDropdown
          buttonRenderer={({ isOpen }) => (
            <HoverDropdownItem token={localizationKey("Software")} isOpen={isOpen} showArrow isDropdownButton />
          )}
          dropdownRenderer={() => (
            <HoverDropdownItem
              {...{
                key: installAction.id,
                token: installAction.token,
                onClick: installAction.onClick,
                disabled: installAction.disabled,
                id: installAction.id,
              }}
            />
          )}
          placement="right"
          fullWidth
        />
      )}
    </Box>
  )
}

MobileDeviceSubMenuButtons.propTypes = {
  isAppleDevice: PropTypes.bool.isRequired,
  nodeIds: PropTypes.arrayOf(PropTypes.number),
  userCanDeleteDevice: PropTypes.bool,
  userCanUpdateDevice: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      token: PropTypes.string,
      id: PropTypes.string,
      disabled: PropTypes.bool,
      className: PropTypes.string,
      onClick: PropTypes.func,
    }).isRequired,
  ),
}
