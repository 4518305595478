import _ from "underscore"
import ModelBase from "./ModelBase"

const DateModel = ModelBase.extend({
  defaults: {
    classname: "DateModel",
  },
  initialize: function() {
    ModelBase.prototype.initialize.call(this)
    _.bindAll(this, "dateStringUS", "date", "setFromString")
  },
  date: function() {
    return new Date(this.get("year"), this.get("month"), this.get("day"))
  },
  setFromString: function(dateString) {
    var date = new Date(dateString)
    this.set("year", date.getFullYear())
    this.set("month", date.getMonth())
    this.set("day", date.getDate())
  },
  setToToday: function() {
    var date = new Date()
    this.set("year", date.getFullYear())
    this.set("month", date.getMonth())
    this.set("day", date.getDate())
  },
  isEmpty: function() {
    return !this.get("year")
  },
  dateStringUS: function() {
    return +this.get("month") + 1 + "/" + this.get("day") + "/" + this.get("year")
  },
})

export default DateModel
