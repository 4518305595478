import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import DragableList from "js/includes/components/DragableList"

const StyledDraggableListContainer = styled.div`
  max-height: ${({ maxHeight }) => maxHeight ?? "initial"};
  padding: ${sizer(2)};
  border-radius: ${sizer(1)};
  background-color: ${({ theme }) => theme.color.black["005"]};
  overflow-y: auto;
  cursor: grab;
`

//this component accept same props as DragableList + containerId
const ScriptDraggableList = props => {
  return (
    <StyledDraggableListContainer id={props.containerId}>
      <DragableList {...props} />
    </StyledDraggableListContainer>
  )
}

export default ScriptDraggableList
