import rivets from "rivets"

const livevalue = Object.create(rivets.binders.value)

livevalue.bind = function(el) {
  this.handler = this.handler || this.publish.bind(this)
  el.addEventListener("keyup", this.handler)
}

livevalue.unbind = function(el) {
  if (this.handler) {
    el.removeEventListener("keyup", this.handler)
  }
}

export default livevalue
