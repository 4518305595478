import React from "react"
import { connect } from "react-redux"
import { Modal } from "react-bootstrap"
import { isEmpty } from "ramda"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons"
import tokens from "@ninjaone/tokens"
import { Text } from "@ninjaone/components"
import { Box } from "js/includes/components/Styled"
import Portal from "js/includes/components/Portal"
import { localized } from "js/includes/common/utils"
import { closeWakeDeviceModal as _closeWakeDeviceModal } from "js/state/actions/deviceSearch/wakeDevice"

const WakeDeviceModal = ({
  filteredSelectedDevices,
  closeWakeDeviceModal,
  devicesWakeModal,
  loading,
  remainingSuccessDevicesCount = 0,
  remainingFailDevicesCount = 0,
  unmount,
}) => {
  const remainingDevicesMessage = count => (
    <Box textAlign="left" paddingLeft={tokens.spacing[5]} marginBottom={tokens.spacing[4]}>
      <Text type="body">
        {count === 1 ? localized("and 1 more device...") : localized("and {{count}} more devices...", { count })}
      </Text>
    </Box>
  )

  const onClose = () => {
    closeWakeDeviceModal()
    unmount()
  }

  return (
    !isEmpty(devicesWakeModal) && (
      <Portal>
        <Modal.Dialog className="ninja-editor inmodal">
          <Modal.Header>
            <Modal.Title>{localized("Wake-on-LAN devices")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!!devicesWakeModal.devices.success.length && (
              <>
                <div className="row form-group">
                  <label className="col-xs-12 top-label">
                    {localized("We will attempt to send a Wake-on-LAN magic packet to the following device(s):")}
                  </label>
                  <ul
                    className="col-xs-12"
                    style={{ textAlign: "left", paddingLeft: "35px", margin: 0, maxHeight: 200, overflowY: "auto" }}
                  >
                    {filteredSelectedDevices.length &&
                      filteredSelectedDevices.map(
                        d =>
                          devicesWakeModal.devices.success.includes(d.id) && <li {...{ key: d.id }}>{`${d.name}`}</li>,
                      )}
                  </ul>
                </div>
                {remainingSuccessDevicesCount > 0 && remainingDevicesMessage(remainingSuccessDevicesCount)}
              </>
            )}
            {!!devicesWakeModal.devices.fail.length && (
              <>
                <div className="row form-group">
                  <label className="col-xs-12 top-label">
                    <p>
                      <span>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning p-r-xs" size="2x" />
                      </span>
                      {localized(
                        "The following devices will NOT be attempted as there are no agents online in the same subnet:",
                      )}
                    </p>
                  </label>
                  <ul
                    className="col-xs-12"
                    style={{ textAlign: "left", paddingLeft: "35px", margin: 0, maxHeight: 200, overflowY: "auto" }}
                  >
                    {filteredSelectedDevices.length &&
                      filteredSelectedDevices.map(
                        d => devicesWakeModal.devices.fail.includes(d.id) && <li {...{ key: d.id }}>{`${d.name}`}</li>,
                      )}
                  </ul>
                </div>
                {remainingFailDevicesCount > 0 && remainingDevicesMessage(remainingFailDevicesCount)}
              </>
            )}
            <div className="row">
              <div className="col-xs-12">
                <p className="text-justify">
                  <span>
                    <b>{localized("Note:")}</b>
                  </span>{" "}
                  {localized(
                    "Please make sure Wake-On-LAN is enabled in the BIOS and also in the Networks Adapter section in Device Manager.",
                  )}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-white" disabled={loading} onClick={onClose}>
              {localized("Close")}
            </button>
          </Modal.Footer>
        </Modal.Dialog>
      </Portal>
    )
  )
}

export default connect(
  ({ deviceSearch }) => ({
    devicesWakeModal: deviceSearch.wakeDevice.modal,
    loading: deviceSearch.deleteDevice.loading,
  }),
  {
    closeWakeDeviceModal: _closeWakeDeviceModal,
  },
)(WakeDeviceModal)
