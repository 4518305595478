import { useTheme } from "@emotion/react"
import { Button, Modal, Text } from "@ninjaone/components"
import { HyperVGuestIcon, VmGuestIcon } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import { localized } from "js/includes/common/utils"
import { Box, Flex, StyledSpan } from "js/includes/components/Styled"

const iconMapper = {
  HYPERV: <HyperVGuestIcon fontSize="18px" />,
  ESXI: <VmGuestIcon fontSize="18px" />,
}

const ButtonRow = ({ url, unmount }) => (
  <Flex width="100%" justifyContent="flex-end">
    <Button
      variant="secondary"
      id="vm-dashboard-button"
      labelToken="addDevices.goToVMHostDashboard"
      onClick={() => (window.location.hash = url)}
    />
    <Box marginLeft={sizer(3)}>
      <Button variant="primary" labelToken="general.done" onClick={unmount} />
    </Box>
  </Flex>
)

const VmInfoCard = ({ deviceInfo, organization }) => {
  const theme = useTheme()
  return (
    <Flex
      alignItems="center"
      color="colorTextStrong"
      border={`1px solid ${theme.colorBorderWeakest}`}
      padding={sizer(4, 3)}
      borderRadius={sizer(1)}
    >
      <Flex alignItems="center" marginRight={sizer(2)}>
        {iconMapper[deviceInfo.type]}
      </Flex>
      <StyledSpan fontSize={getTextSize("sm")}>
        <StyledSpan fontWeight="500">{deviceInfo.name} </StyledSpan>
        {localized("addDevices.addedToOrg", { orgName: organization.name })}
      </StyledSpan>
    </Flex>
  )
}

const AddVMSuccessModal = ({ unmount, url, deviceInfo, organization, resetData }) => {
  const handleClose = () => {
    resetData()
    unmount()
  }
  return (
    <Modal
      {...{
        size: "sm",
        unmount,
        withCloseX: true,
        closeAction: handleClose,
        titleGroup: {
          TitleComponent: () => (
            <Text token="addDevices.successfullyAddedHost" size="lg" bold color="colorTextStrong" />
          ),
        },
        buttonRenderer: () => <ButtonRow {...{ url, unmount: handleClose }} />,
      }}
    >
      <VmInfoCard {...{ deviceInfo, organization }} />
    </Modal>
  )
}

export default AddVMSuccessModal
