import { fetchJson } from "js/includes/common/utils"

export const getAutomation = id => fetchJson(`/scripting/scripts/${id}`)

export const getAutomationsUnderReview = status => fetchJson(`/scripting/scripts/malwareScanStatus/${status}`)

export const getAllAutomationsUnderReview = () =>
  Promise.allSettled([
    getAutomationsUnderReview("PROCESSING"),
    getAutomationsUnderReview("INFECTED"),
    getAutomationsUnderReview("FAILED"),
  ])

export const createApplicationAutomation = automation =>
  fetchJson("/scripting/scripts", {
    options: {
      method: "POST",
      body: JSON.stringify(automation),
    },
  })

export const updateApplicationAutomation = automation =>
  fetchJson("/scripting/scripts", {
    options: {
      method: "PUT",
      body: JSON.stringify(automation),
    },
  })

export const deleteApplicationAutomation = ({ id }) =>
  fetchJson(`/scripting/scripts/${id}`, {
    options: {
      method: "DELETE",
    },
  })

export const getScriptAttachments = async scriptId =>
  await fetchJson(`/scripting/scripts/binaryScriptAttachments/${scriptId}`)

export const createApplicationAutomationPreUpload = automation =>
  fetchJson("/scripting/scripts/mfa-pre-upload", {
    options: {
      method: "POST",
      body: JSON.stringify(automation),
    },
  })

export const createInstallApplicationAutomation = automation =>
  fetchJson("/scripting/scripts/upload-application", {
    options: {
      method: "POST",
      body: JSON.stringify(automation),
    },
  })

export const updateInstallApplicationAutomation = automation =>
  fetchJson("/scripting/scripts/upload-application", {
    options: {
      method: "PUT",
      body: JSON.stringify(automation),
    },
  })
