import React, { memo, useMemo } from "react"
import ReactMarkdown from "react-markdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { NINJAONE_ZENDESK_BASE_URL, fetch } from "js/includes/common/utils"
import { Flex } from "./Styled"
import Link from "./Link"

const allowedElements = ["p", "em", "strong", "a"]

function markAnnouncementAsRead(id) {
  window.store.dispatch({ type: "HIDE_APP_ANNOUNCEMENT" })

  fetch("/appuser/application-announcement", {
    options: {
      method: "PATCH",
      body: id,
    },
  })
}

export default memo(({ id, message }) => {
  const components = useMemo(() => {
    return {
      a: ({ href, children }) => {
        return <Link href={href}>{children}</Link>
      },
      p: ({ node, children, ...props }) => {
        const hasChildZendeskLink = node.children.some(
          child => child.tagName === "a" && child.properties.href.startsWith(NINJAONE_ZENDESK_BASE_URL),
        )
        if (hasChildZendeskLink) {
          return (
            <Flex gap={1} {...props}>
              {children}
            </Flex>
          )
        }
        return <p {...props}>{children}</p>
      },
    }
  }, [])

  return (
    <div id="content">
      <span id="content">
        <ReactMarkdown
          {...{
            skipHtml: true,
            allowedElements,
            linkTarget: "_blank",
            components,
          }}
        >
          {message}
        </ReactMarkdown>
      </span>
      <button id="action" onClick={() => markAnnouncementAsRead(id)}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  )
})
