import { useEffect } from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { reject, includes } from "ramda"
import pathParse from "path-parse"
import { Box } from "js/includes/components/Styled"
import { Input, Select, Text, AlertMessage } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { useMountedState } from "js/includes/common/hooks"
import { localized, localizationKey, localizedWith } from "js/includes/common/utils"
import { getCredentialSelectOptions } from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/common/credentials"
import {
  ARCHITECTURE_64,
  getArchitectureOptions,
  getOSOptions,
  MAC_OS,
} from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/utils"
import InstallerSection from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/InstallerSection"
import CategoriesSelect from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/common/CategoriesSelect"
import InstallRunApplicationModalContent from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/common/InstallRunApplicationModalContent"
import Parameters from "js/includes/configuration/scripting/ScriptsSubTabModel/ScriptEditor/Parameters"

const StyledFormLabelContainer = styled.div`
  margin: ${sizer(2, 0, 1)};
`

function InstallApplicationGeneralTab({
  scriptCategories,
  automationId,
  name,
  description,
  url,
  categories,
  architecture,
  parameters,
  useFirstParametersOptionAsDefault,
  credential,
  os,
  onChange,
  validation,
  updateValidate,
  installApplicationValidations,
  file,
  scriptsList,
  installerErrorMessage,
  setInstallerErrorMessage,
  duplicateNameMessage,
  setDuplicateNameMessage,
}) {
  const [credentialOptions, setCredentialOptions] = useMountedState([])

  useEffect(() => {
    const setCredentials = async () => {
      const credentials = await getCredentialSelectOptions(os, "labelText")
      setCredentialOptions(credentials)
      !credential && onChange({ credential: credentials[0]?.value || "" })
    }
    setCredentials()
  }, [os, setCredentialOptions, onChange, credential])

  const getArchitectures = () => {
    const options = getArchitectureOptions()
    if (os === MAC_OS) {
      return reject(({ value }) => !includes(value, [ARCHITECTURE_64]), options)
    }
    return options
  }

  const validateName = () => {
    const findName = scriptsList.find(script => script.name === name && script.id !== automationId)
    setDuplicateNameMessage(findName ? localizationKey("This name already exists") : "")
  }

  const getInfoMessage = (extension = "") => {
    return extension.includes("msi")
      ? localizedWith(
          "NinjaOne has detected this is an MSI file, it will be executed with <%command>command<%> automatically.",
          {
            command: () => <code>/quiet /qn /norestart</code>,
          },
        )
      : localized(
          "If you intend to run this installer silently, you may need to enter the appropriate command-line arguments.",
        )
  }

  const extension = file ? file.metadata?.extension : pathParse(url)?.ext
  const isMsix = extension?.includes("msix")

  return (
    <InstallRunApplicationModalContent padding={sizer(3)}>
      <StyledFormLabelContainer>
        <Text bold size="sm" token={localizationKey("Name")} />
      </StyledFormLabelContainer>
      <Input
        ariaLabel={localized("Name")}
        value={name}
        onBlur={validateName}
        onChange={e => onChange({ name: e.target.value })}
        errorMessage={duplicateNameMessage || validation.message.name}
      />
      <StyledFormLabelContainer>
        <Text bold size="sm" token={localizationKey("Description (Optional)")} />
      </StyledFormLabelContainer>
      <Input
        ariaLabel={localized("Description (Optional)")}
        value={description}
        onChange={e => onChange({ description: e.target.value })}
      />

      <StyledFormLabelContainer>
        <Text bold size="sm" token={localizationKey("Operating System")} />
      </StyledFormLabelContainer>
      <Select
        triggerAriaLabel={localized("Operating System")}
        matchWidth
        options={getOSOptions()}
        onChange={os => onChange({ os })}
        value={os}
      />

      <StyledFormLabelContainer>
        <Text bold size="sm" token={localizationKey("Architecture")} />
      </StyledFormLabelContainer>
      <Select
        triggerAriaLabel={localized("Architecture")}
        matchWidth
        options={getArchitectures()}
        onChange={architecture => onChange({ architecture })}
        value={architecture}
      />

      <InstallerSection
        {...{
          validation,
          os,
          url,
          onChange,
          updateValidate,
          installApplicationValidations,
          fileForAttachment: file,
          installerErrorMessage,
          setInstallerErrorMessage,
        }}
      />

      <StyledFormLabelContainer>
        <Text bold size="sm" token={localizationKey("Categories")} />
      </StyledFormLabelContainer>
      <CategoriesSelect
        {...{
          matchWidth: true,
          categories,
          scriptCategories,
          onChange,
        }}
      />

      <StyledFormLabelContainer>
        <Text bold size="sm" token={localizationKey("Run As")} />
      </StyledFormLabelContainer>
      <Select
        matchWidth
        options={credentialOptions}
        onChange={credential => onChange({ credential })}
        value={credential}
        triggerAriaLabel={localized("Run As")}
      />

      {(file || url) && !isMsix && (
        <Box marginTop={sizer(2)}>
          <AlertMessage variant="info">{getInfoMessage(extension)}</AlertMessage>
        </Box>
      )}
      <Parameters
        parameters={parameters}
        onParametersChanged={params => onChange({ parameters: params })}
        labelText={localized("Parameters (Optional)")}
        useFirstParametersOptionAsDefault={useFirstParametersOptionAsDefault}
        onFirstParametersOptionChanged={useFirstParametersOptionAsDefault => {
          onChange({ useFirstParametersOptionAsDefault })
        }}
      />
    </InstallRunApplicationModalContent>
  )
}

export default connect(state => ({
  scriptsList: state.scripting.scripts,
}))(InstallApplicationGeneralTab)
