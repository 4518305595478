import { useMemo } from "react"
import { useQuery } from "urql"
import { filter, propEq, find, compose, map } from "ramda"
import { reportErrorAndShowMessage, localized, localizationKey, WithGraphql } from "js/includes/common/utils"
import { getTicketRuleset } from "js/includes/configuration/integrations/ticketing/ticketAutomation/ticketRuleset/graphql"
import Loading from "js/includes/components/Loading"
import SelectFormGroup from "js/includes/components/Form/SelectFormGroup"

const ActivityRuleSelectFormGroupContainer = ({ ticketRulesetId, onChange }) => {
  const [{ fetching, error, data }] = useQuery({
    query: getTicketRuleset,
  })

  if (error) {
    reportErrorAndShowMessage(error, localizationKey("Error fetching ruleset"))
  }

  const ticketRulesets = useMemo(
    () => [
      { id: "NONE", name: localized("None") },
      ...compose(
        map(({ id, name, enabled }) => ({
          id,
          name: `${name}${enabled ? "" : ` (${localized("Disabled")})`}`,
        })),
        filter(propEq("type", "ACTIVITY_RULE")),
      )(data?.ticketRuleset ?? []),
    ],
    [data?.ticketRuleset],
  )

  return fetching ? (
    <Loading />
  ) : (
    <SelectFormGroup
      label={localizationKey("Ticketing Rule")}
      options={ticketRulesets}
      value={find(propEq("id", ticketRulesetId), ticketRulesets)}
      onChange={({ id: ticketRulesetId }) =>
        onChange({ ticketRulesetId, createNinjaTicket: ticketRulesetId === "NONE" ? false : true })
      }
    />
  )
}

export const ActivityRuleSelectFormGroup = WithGraphql(ActivityRuleSelectFormGroupContainer)
