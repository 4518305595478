import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { localized, localizedWith, user } from "js/includes/common/utils"
import { allTicketsBoardId } from "js/includes/configuration/integrations/ticketing/utils"
import { StyledAlert } from "./common"

const GlobalSearchNoResultsAlert = ({ filter, closePopover }) => {
  return (
    <StyledAlert>
      <FontAwesomeIcon icon={faSearch} fixedWidth />
      <h3>{localized("No results found")}</h3>
      {filter === "organizations" && (
        <p>
          {localizedWith("Try searching in <%link>Organization page<%>", {
            link: ({ localizedText }) => (
              <a onClick={closePopover} href="#/administration/customers/list">
                {localizedText}
              </a>
            ),
          })}
        </p>
      )}
      {filter === "devices" && (
        <p>
          {localizedWith("Try searching in <%link>Devices<%>", {
            link: ({ localizedText }) => (
              <a onClick={closePopover} href="#/deviceSearch">
                {localizedText}
              </a>
            ),
          })}
        </p>
      )}
      {filter === "groups" && (
        <p>
          {localizedWith("Try searching in <%link>Groups page<%>", {
            link: ({ localizedText }) => (
              <a onClick={closePopover} href="#/administration/devices/groups">
                {localizedText}
              </a>
            ),
          })}
        </p>
      )}
      {filter === "policies" && (
        <p>
          {localizedWith("Try searching in <%link>Policies page<%>", {
            link: ({ localizedText }) => (
              <a onClick={closePopover} href="#/administration/policies/agent">
                {localizedText}
              </a>
            ),
          })}
        </p>
      )}
      {filter === "users" && (
        <p>
          {user("canCRUDEndUserSharing")
            ? localizedWith(
                "Try searching in <%techniciansLink>Technicians page<%> or <%endUsersLink>End Users page<%>",
                {
                  techniciansLink: ({ localizedText }) => (
                    <a onClick={closePopover} href="#/administration/users/technicians">
                      {localizedText}
                    </a>
                  ),
                  endUsersLink: ({ localizedText }) => (
                    <a onClick={closePopover} href="#/administration/users/endUsers">
                      {localizedText}
                    </a>
                  ),
                },
              )
            : localizedWith("Try searching in <%techniciansLink>Technicians page<%>", {
                techniciansLink: ({ localizedText }) => (
                  <a onClick={closePopover} href="#/administration/users/technicians">
                    {localizedText}
                  </a>
                ),
              })}
        </p>
      )}
      {filter === "ticketing" && (
        <p>
          {localizedWith("Try searching in <%link>Ticketing page<%>", {
            link: ({ localizedText }) => (
              <a onClick={closePopover} href={`#/ticketing/board/${allTicketsBoardId}`}>
                {localizedText}
              </a>
            ),
          })}
        </p>
      )}
      {filter === "kb_document" && (
        <p>
          {localizedWith("Try searching in <%link>Knowledge Base page<%>", {
            link: ({ localizedText }) => (
              <a onClick={closePopover} href={`#/systemDashboard/knowledgeBase`}>
                {localizedText}
              </a>
            ),
          })}
        </p>
      )}
    </StyledAlert>
  )
}

export default GlobalSearchNoResultsAlert
