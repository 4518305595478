import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { Text } from "@ninjaone/components"
import { EditIcon, CloseIcon, GripLinesIcon } from "@ninjaone/icons"
import { Flex, Box } from "js/includes/components/Styled"

const StyledRow = styled(Flex)`
  padding: ${sizer(2)};
  margin-bottom: ${sizer(2)};
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.black["025"]};
  border-radius: 4px;
  .script-variable-edit-icon {
    opacity: 0;
  }
  div > div,
  div > svg {
    margin-left: ${sizer(2)};
  }
  svg {
    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    .script-variable-edit-icon {
      opacity: 1;
    }
  }
`
const StyledRowName = styled(Flex)`
  align-items: center;
  max-width: 83%;
  overflow-x: hidden;
`

const StyledDefaultLabel = styled.div`
  background-color: ${({ theme }) => theme.color.black["010"]};
  color: ${({ theme }) => theme.color.black["035"]};
  font-size: ${sizer(2)};
  padding: ${sizer(1)};
  border-radius: ${sizer(1)};
  white-space: nowrap;
`

const ScriptListRow = ({ name, onEdit, onDelete, showEditButton = false, showDefaultLabel = false }) => {
  return (
    <StyledRow>
      <StyledRowName>
        <GripLinesIcon />
        <Text>{name}</Text>
        {showDefaultLabel && <StyledDefaultLabel>Default Value</StyledDefaultLabel>}
      </StyledRowName>
      <Box>
        {showEditButton && <EditIcon className="script-variable-edit-icon" onClick={onEdit} />}
        <CloseIcon onClick={onDelete} />
      </Box>
    </StyledRow>
  )
}

export default ScriptListRow
