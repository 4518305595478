import React, { PureComponent } from "react"
import { always, cond, T, mapObjIndexed, values, compose, pick, isEmpty } from "ramda"
import {
  localized,
  isMacDevice,
  isWindowsDevice,
  isRmmDevice,
  user,
  isFeatureEnabled,
  isLinuxDevice,
  localizationKey,
} from "js/includes/common/utils"
import { linuxToolsOptions, macToolsOptions, windowsToolsOptions } from "js/includes/dashboards/siteMap"
import { HoverDropdown, HoverDropdownItem } from "@ninjaone/components"
import DeviceTerminalHoverDropdown from "js/includes/components/DeviceTerminalHoverDropdown"

const getLinkButton = (deviceId, option, showADButton) => {
  if (option.tool === "activeDirectory" && !(showADButton && user("canAccessActiveDirectoryManagement"))) {
    return null
  }

  const text = option.token ? localized(option.token) : option.plainText

  return (
    <li key={`${deviceId}-${option.token}-option`}>
      <HoverDropdownItem
        route={`#/deviceDashboard/${deviceId}/tools/${option.tool}`}
        {...(text && { plainText: text })}
        {...(option.textRenderer && { textRenderer: option.textRenderer })}
      />
    </li>
  )
}

const getAllowedTools = () => [
  user("canUseRemoteToolsRemoteRegistry") && !isFeatureEnabled("disable_remote_tools_registry") && "remoteRegistry",
  user("canUseRemoteToolsTaskManager") && "taskManager",
  user("canUseRemoteToolsFileExplorer") && !isFeatureEnabled("disable_remote_tools_file_browser") && "fileBrowser",
  user("canUseRemoteToolsServicesManager") && "services",
]

const getMenu = compose(
  _ => pick(getAllowedTools(), _),
  cond([
    [isWindowsDevice, always(windowsToolsOptions)],
    [isMacDevice, always(macToolsOptions)],
    [isLinuxDevice, always(linuxToolsOptions)],
    [T, always([])],
  ]),
)

export default class DeviceToolsContextSubMenu extends PureComponent {
  state = {
    showSubmenu: false,
  }

  async componentDidMount() {
    const canUseRemoteTools = await user("canUseRemoteTools")
    this.setState({ canUseRemoteTools })
  }

  render() {
    const {
      device: { id, nodeClass, isUp },
      showADButton,
    } = this.props
    const hasCommandLinePermission =
      user("canUseRemoteToolsCommandLine") && !isFeatureEnabled("disable_remote_tools_command_prompt")

    const menu = getMenu(nodeClass)
    const showMenu =
      isUp && this.state.canUseRemoteTools && isRmmDevice(nodeClass) && (!isEmpty(menu) || hasCommandLinePermission)

    return showMenu ? (
      <HoverDropdown
        buttonRenderer={({ isOpen }) => (
          <HoverDropdownItem token={localizationKey("Remote Tools")} isOpen={isOpen} showArrow isDropdownButton />
        )}
        dropdownRenderer={() => (
          <ul>
            <li key={`${id}-cli-options`}>
              {hasCommandLinePermission && <DeviceTerminalHoverDropdown {...this.props} />}
            </li>
            {values(mapObjIndexed((token, tool) => getLinkButton(id, { token, tool }, showADButton), menu))}
          </ul>
        )}
        placement="right"
        fullWidth
      />
    ) : null
  }
}
