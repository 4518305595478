import { connect } from "react-redux"
import { omit, includes } from "ramda"
import { faTrash } from "@fortawesome/pro-solid-svg-icons"
import ScriptsDataTable from "js/includes/components/scripting/ScriptsDataTable"
import { requestDeleteScript, requestUpdateScript } from "js/state/actions/scripting/scripts"
import { user, localized, reportErrorAndShowMessage, showSuccessMessage } from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import NameModal from "js/includes/components/NameModal"
import { getAutomationsWithCategories } from "js/includes/components/scripting/utils"
import showModal from "js/includes/common/services/showModal"
import { copyScript } from "js/includes/common/client"
import NativeScriptCategoriesModal from "js/includes/components/scripting/NativeScriptCategoriesModal"
import { getScriptCategories } from "js/state/selectors/scripting/scriptCategories"
import InstallApplicationModal from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/InstallApplicationModal"
import RunApplicationModal from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/RunApplicationModal"

const isUserScript = script => script.language !== "native"

const isEditingEnabled = script => user("canReadScripts", script.categoriesIds)
const isDeletingEnabled = script => isUserScript(script) && user("canDeleteScripts", script.categoriesIds)
const isCopyingEnabled = script =>
  // TODO: remove the filter that excludes the "binary_install" and "binary_run" when backend will ready to copy an Install App
  !includes(script.language, ["binary_install", "binary_run"]) &&
  isUserScript(script) &&
  user("canCreateScripts", script.categoriesIds)

const editScript = (script, scriptCategories, automationType, setAutomationsUnderReview) => {
  const { id, name } = script

  switch (automationType) {
    case "NativeAutomation":
      showModal(
        <NativeScriptCategoriesModal
          title={name}
          initialValues={{
            categories: script.categoriesIds,
          }}
          scriptCategories={scriptCategories}
          onSubmit={async ({ categories }, setIsSaving, close) => {
            try {
              const updateScript = omit(["deviceType", "parameters", "customName"], {
                ...script,
                categoriesIds: categories,
              })
              updateScript.code = ""

              await requestUpdateScript(updateScript)(window.store.dispatch)
              showSuccessMessage()
              close()
            } catch (error) {
              if (error?.reason !== "USER_CANCELLED_MFA") {
                reportErrorAndShowMessage(error)
              }
            } finally {
              setIsSaving(false)
            }
          }}
        />,
      )
      break
    case "ScriptAutomation":
      window.location.hash = `#/editor/script/${id}`
      break
    case "BinaryInstallAutomation":
      showModal(
        <InstallApplicationModal
          editingInstallApplication={script}
          setAutomationsUnderReview={setAutomationsUnderReview}
        />,
        { withProvider: true },
      )
      break
    case "BinaryRunAutomation":
      showModal(
        <RunApplicationModal editingRunApplication={script} setAutomationsUnderReview={setAutomationsUnderReview} />,
        { withProvider: true },
      )
      break
    default:
      break
  }
}

export default connect(
  state => ({
    scriptsList: getAutomationsWithCategories({
      automations: state.scripting.scripts,
      categories: getScriptCategories(state),
      filterNewInstallRunScripts: state.policyEditor.scripting.conditionScriptSelector,
    }),
    isEditingEnabled,
    isDeletingEnabled,
    isCopyingEnabled,
    onEditClick(script, setAutomationsUnderReview) {
      if (!isEditingEnabled(script)) return
      editScript(
        state.scripting.scripts.find(s => s.id === script.id),
        getScriptCategories(state),
        state.scripting.languages.find(l => l.id === script.language)?.automationType,
        setAutomationsUnderReview,
      )
    },
    onCopyClick({ name, id }) {
      showModal(
        <NameModal
          title={localized("Copy Automation")}
          initialValues={{
            name: `${name} ${localized("general.copy")}`,
          }}
          onSubmit={async ({ name }, unmount, setIsSaving) => {
            try {
              const { id: newId, language } = await copyScript(id, name)
              if (["binary_install", "binary_run"].includes(language)) {
                showSuccessMessage(localized("Success"))
                unmount()
              } else {
                window.location.hash = `#/editor/script/${newId}`
              }
            } catch (error) {
              reportErrorAndShowMessage(error)
            } finally {
              setIsSaving(false)
            }
          }}
        />,
      )
    },
  }),
  dispatch => ({
    async onDeleteClick(script) {
      const buttonClicked = await ShowMessageDialog({
        icon: { icon: faTrash, type: "critical" },
        title: "general.delete",
        message: localized(
          "Are you sure that you want to delete this automation? Policies, Scheduled Tasks and Systrays referencing this automation will be affected.",
        ),
        buttons: [
          { id: "DELETE", label: "general.delete", type: "critical" },
          { id: "CANCEL", label: "general.cancel" },
        ],
      })
      if (buttonClicked === "DELETE") {
        requestDeleteScript(script)(dispatch)
      }
    },
  }),
)(ScriptsDataTable)
