import CommonHashTable from "./CommonHashTable"
import { UserInfoModel } from "js/infrastructure/backbone/InfoModels"
import { fetchJson } from "js/includes/common/utils"
import { sortBy } from "ramda"

export default class UsersHashTable extends CommonHashTable {
  get(id) {
    const _id = parseInt(id, 10)
    return this._getModel(_id)
  }

  getModels() {
    const models = Array.from(super.values()).map(({ id }) => this._getModel(id))
    return sortBy(model => `${model.get("firstName")} ${model.get("lastName")}`.toLowerCase(), models)
  }

  async fetch() {
    const users = await fetchJson("/appuser?userType=TECHNICIAN")
    users.forEach(u => {
      const currentUser = this.getRaw(u.id) || {}
      this.set(u.id, Object.assign(currentUser, u))
    })
  }

  _getModel(id) {
    return UserInfoModel.findOrCreate(this.getRaw(id))
  }
}
