import $ from "jquery"
import _ from "underscore"

const livevalidation = {
  publishes: true,
  bind: function(el) {
    var $el = $(el)
    var adapter = this.view.adapters[this.observer.key.i]
    var relativePath = this.observer.key.path
    var self = this

    $el.data("live-validation", false)
    $el.data("live-validation-lock", false)

    function setValue(val) {
      $el.data("live-validation-lock", true)
      // adapter.set is a dynamic version of model.set – allowing us to use different adapters
      adapter.set(self.model, relativePath, val)
      $el.data("live-validation-lock", false)
    }

    $el.on(
      "keyup",
      _.debounce(function() {
        if ($el.data("live-validation")) {
          setValue(el.value)
        }
      }, 50),
    )

    $el.on("change", function() {
      $el.data("live-validation", true)
      setValue(el.value)
    })
  },
  routine: function(el, value) {
    if (!$(el).data("live-validation-lock") && value !== el.value) {
      $(el).data("live-validation", false)
      el.value = value || ""
    }
  },
  unbind: function(el) {
    $(el)
      .off()
      .removeData()
  },
}

export default livevalidation
