import { localized } from "js/includes/common/utils"

export const validateMonitorDelegate = (val, values) => {
  if (!val) {
    return {
      success: false,
      message: localized("Select a delegate"),
    }
  }
  return { success: true, message: "" }
}

export const validateDnsIp = (val, values) => {
  if (!val.trim()) {
    return {
      success: false,
      message: localized("Enter a DNS/IP"),
    }
  }
  return { success: true, message: "" }
}

export const validateApiPort = (val, values) => {
  if (isNaN(val)) {
    return {
      success: false,
      message: localized("The API port needs to be a number"),
    }
  }
  if (!val) {
    return {
      success: false,
      message: localized("Enter an API port"),
    }
  }
  return { success: true, message: "" }
}

export const validateCredential = (val, values) => {
  if (isNaN(val)) {
    return {
      success: false,
      message: localized("Credential needs to be a number"),
    }
  }
  if (!val) {
    return {
      success: false,
      message: localized("Enter a credential"),
    }
  }
  return { success: true, message: "" }
}
