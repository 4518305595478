import { localized, userProp, isNinja, isDateInFuture } from "js/includes/common/utils"
import moment from "moment"

const getFutureDateErrorMessage = () => localized("Date and time must be in the future")

export const modalValidation = {
  start: start => {
    if (start.type === "NOW") return { success: true, message: "" }
    const isValidStartDateTime = isDateInFuture(start.datetime)
    const success = isValidStartDateTime
    const isValidStartDateTimeMsg = !isValidStartDateTime ? getFutureDateErrorMessage() : ""
    return { success, message: isValidStartDateTimeMsg }
  },
  end: (end, { start }) => {
    if (end.type === "NEVER") return { success: true, message: "" }
    const isEndDateInTheFuture = isDateInFuture(end.datetime)

    if (!isEndDateInTheFuture) {
      return { success: false, message: getFutureDateErrorMessage() }
    }
    const isStartAT = start.type === "AT"
    const minimumMaintenanceModeWindowInMinutes = isNinja(userProp("email")) ? 0 : 5
    const startDateTime = isStartAT ? moment(start.datetime) : moment()
    const isValidDateTimeFrame = startDateTime.isBefore(moment(end.datetime))
    const isValidDateTimeMinimum = moment(end.datetime).isSameOrAfter(
      startDateTime.add(minimumMaintenanceModeWindowInMinutes, "minutes"),
    )

    const success = isValidDateTimeFrame && isValidDateTimeMinimum
    const minimumMinutesMessage = minimumMaintenanceModeWindowInMinutes
      ? localized("Minimum of 5 minutes required after Start date")
      : localized("End date should be after Start date")
    const isValidDateTimeMinimumMsg = success ? "" : minimumMinutesMessage
    return { success, message: isValidDateTimeMinimumMsg }
  },
}
