import { useRef } from "react"
import styled from "@emotion/styled"
import { BarsFilterIconRegular } from "@ninjaone/icons"
import { Select } from "@ninjaone/components"
import { getFilterSelectOptions } from "./common"
import { sizer } from "@ninjaone/utils"
import { localized } from "js/includes/common/utils"

const selectOptionHeight = "32px"

const StyledWrapper = styled.div`
  [data-ninja-select-content] {
    padding: 4px;
    border: 1px solid ${({ theme }) => theme.colorBorderWeak};
    box-shadow: 0px 2px 4px 0px rgba(33, 31, 51, 0.1);
  }

  &&& {
    [data-ninja-select-content] {
      width: 180px;
      min-width: 180px;
    }
  }

  [data-ninja-select-item] {
    font-size: 14px;
    color: ${({ theme }) => theme.colorTextStrong};
    height: ${selectOptionHeight};
    padding: 0 ${sizer(2)};

    & > span {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }

    &:not([data-highlighted]) {
      [data-ninja-tooltip-trigger] {
        visibility: hidden;
      }
    }

    svg {
      color: ${({ theme }) => theme.colorTextWeakest};
    }

    &[data-highlighted] {
      background-color: ${({ theme }) => theme.colorForegroundHover};
    }

    &[data-state="checked"] {
      background-color: ${({ theme }) => theme.colorForegroundSelected};
    }
  }

  [data-ninja-select-trigger] {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &:focus,
    &:focus-visible {
      outline-offset: -2px;
      outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    }

    &:hover,
    &[data-state="open"] {
      background-color: ${({ theme }) => theme.colorForegroundHover};
    }
  }
`

const GlobalSearchFilterSelect = ({ onChange, filter, onBlur }) => {
  const preventOnCloseAutoFocus = useRef(false)

  return (
    <StyledWrapper
      onClick={event => {
        event.stopPropagation()
      }}
    >
      <Select
        value={filter}
        onChange={value => {
          preventOnCloseAutoFocus.current = true
          onChange(value)
        }}
        onBlur={onBlur}
        options={getFilterSelectOptions()}
        buttonRenderer={() => <BarsFilterIconRegular />}
        listboxMinWidth={180}
        listboxMaxHeight="auto"
        listboxZIndex={10001}
        popoverProps={{ portal: false }}
        alignRight="right"
        sideOffset={2}
        triggerAriaLabel={localized("Filter")}
        triggerTooltipLabel={localized("Filter")}
        onPointerDownOutside={event => {
          const eventCameFromGlobalSearch = !!event.target.closest("#global-search")

          if (!eventCameFromGlobalSearch) {
            preventOnCloseAutoFocus.current = true
          }
        }}
        onCloseAutoFocus={event => {
          if (preventOnCloseAutoFocus.current) {
            event.preventDefault()
            preventOnCloseAutoFocus.current = false
          }
        }}
        preventValueChangeOnTriggerKeyDown
      />
    </StyledWrapper>
  )
}

export default GlobalSearchFilterSelect
