import { units } from "js/includes/common/_constants"

const bytes = function(el, value) {
  if (value < units.bytes_in_mb) {
    el.innerHTML = (value / units.bytes_in_kb).toFixed(2) + " KB"
  } else if (value < units.bytes_in_gb) {
    el.innerHTML = (value / units.bytes_in_mb).toFixed(2) + " MB"
  } else {
    el.innerHTML = (value / units.bytes_in_gb).toFixed(2) + " GB"
  }
}

export default bytes
