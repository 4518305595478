import { compose, find, prop, reduce, uniqBy } from "ramda"
import moment from "moment"
import {
  commonSuppressOptions,
  macSuppressOptions,
  windowsSuppressOptions,
  vmWareSuppressOptions,
  linuxSuppressOptions,
  nmsSuppressOptions,
} from "../StartEndSchedule/scheduleDataDefaults"
import { isLinuxDevice, isMacDevice, isWindowsDevice, isVMHostOrGuest, isNms } from "js/includes/common/utils"

export const showMaintenanceModeDisableButton = (nodes = []) => {
  const isSingleSelection = nodes.length === 1
  return !!(isSingleSelection && nodes[0].maintenanceMode) || findDeviceWithMaintenanceMode(nodes)
}

export const isExpiredTime = datetime => {
  const currentTime = moment.unix()
  return !!datetime && currentTime.isAfter(moment.unix(datetime))
}

export const getDisabledOptions = (disabledOptions = [], nodeClass) => {
  const { notifications, alerts, patching, avScans, tasks, notificationChannels } = getSuppressOptionsByDeviceType(
    nodeClass,
  )

  return {
    notifications: notifications && disabledOptions.includes("NOTIFICATIONS"),
    alerts: alerts && disabledOptions.includes("ALERTS"),
    patching: patching && disabledOptions.includes("PATCHING"),
    avScans: avScans && disabledOptions.includes("AVSCANS"),
    tasks: tasks && disabledOptions.includes("TASKS"),
    notificationChannels: notificationChannels && disabledOptions.includes("NOTIFICATION_CHANNELS"),
  }
}

export const getSuppressOptionsByDeviceType = nodeClass => {
  if (isWindowsDevice(nodeClass)) {
    return { ...commonSuppressOptions, ...windowsSuppressOptions }
  }
  if (isMacDevice(nodeClass)) {
    return { ...commonSuppressOptions, ...macSuppressOptions }
  }
  if (isLinuxDevice(nodeClass)) {
    return { ...commonSuppressOptions, ...linuxSuppressOptions }
  }
  if (isVMHostOrGuest(nodeClass)) {
    return { ...commonSuppressOptions, ...vmWareSuppressOptions }
  }
  if (isNms(nodeClass)) {
    return { ...commonSuppressOptions, ...nmsSuppressOptions }
  }
  return commonSuppressOptions
}

export const getSuppressOptionsForMultipleDevices = compose(
  reduce((acc, { nodeClass }) => ({ ...acc, ...getSuppressOptionsByDeviceType(nodeClass) }), {}),
  uniqBy(prop("nodeClass")),
)

export const areAllDevicesTheSameType = nodes => {
  const [firstNode, ...otherNodes] = nodes
  return otherNodes.every(node => node.nodeClass === firstNode.nodeClass)
}

export const findDeviceWithMaintenanceMode = nodes => {
  return find(node => node.maintenanceMode, nodes)
}
