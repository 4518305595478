import React from "react"
import { Form } from "react-bootstrap"
import styled from "@emotion/styled"
import { localized, localizationKey, validations } from "js/includes/common/utils"
import { useForm } from "js/includes/common/hooks"
import { StyledFormGroup, StyledCol, StyledModal } from "js/includes/components/Styled/Form"
import { StyledSpan } from "js/includes/components/Styled"
import Schedule from "js/includes/components/Schedule/serverBased/Schedule"
import { defaultSchedule, repeatsOptionsMap } from "js/includes/components/Schedule/serverBased/scheduleDataDefaults"
import ADStyledModalContainer from "../components/ADStyledModalContainer"

const ScheduleContainerStyled = styled.div`
  width: 100%;
  > div {
    height: 200px;
    width: 100%;

    h3 {
      display: none;
    }

    .control-label {
      padding-left: 0;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .btn-toolbar {
      height: 38px;
    }
  }
`

export default function ScheduledDiscoveryScheduleModal({ schedule: initialSchedule, onSave, unmount }) {
  const {
    values: { schedule },
    onChange,
  } = useForm({
    fields: {
      schedule: initialSchedule || defaultSchedule,
    },
    validate: {
      schedule: validations.required,
    },
  })

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        onSave(schedule)
        unmount()
      }}
    >
      <ADStyledModalContainer>
        <StyledModal
          close={unmount}
          submittable
          overflow
          saveText={localizationKey("Set")}
          noHeader
          backgroundColor="#fff !important"
          borderRadius="5px"
          overflowY="visible !important"
        >
          <StyledSpan
            fontSize="20px"
            fontWeight="600"
            margin={[2, 0, 4]}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            {localized("Set Schedule")}
          </StyledSpan>
          <StyledFormGroup textAlign="left" marginBottom={0}>
            <StyledCol>
              <ScheduleContainerStyled>
                <Schedule
                  {...{
                    schedule,
                    onScheduleUpdate: schedule => onChange({ schedule }),
                    visibleEnd: false,
                    visibleTimeZone: false,
                    startAtInfo: localized("Agent time"),
                    repeatsOptions: [
                      repeatsOptionsMap.DAILY,
                      repeatsOptionsMap.HOURLY,
                      repeatsOptionsMap.WEEKLY,
                      repeatsOptionsMap.MONTHLY,
                    ],
                  }}
                />
              </ScheduleContainerStyled>
            </StyledCol>
          </StyledFormGroup>
        </StyledModal>
      </ADStyledModalContainer>
    </Form>
  )
}
