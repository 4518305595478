import {
  WINDOWS_SERVER,
  WINDOWS_WORKSTATION,
  MAC,
  MAC_SERVER,
  LINUX_SERVER,
  LINUX_WORKSTATION,
  localizationKey,
  DOJO_ARTICLES_PREFIX,
} from "js/includes/common/utils"

export const computerHeadingTokenMapper = {
  WINDOWS_MSI: localizationKey("Windows OS configuration"),
  MAC: localizationKey("Mac OS configuration"),
  LINUX: localizationKey("Linux OS configuration"),
}

export const linkMapper = {
  WINDOWS_MSI: `${DOJO_ARTICLES_PREFIX}/360003497591-How-to-install-Ninja-on-a-device`,
  MAC: `${DOJO_ARTICLES_PREFIX}/4404968856461-How-to-install-NinjaRMM-on-a-Mac-device`,
  LINUX: `${DOJO_ARTICLES_PREFIX}/4404954737421-How-to-install-NinjaRMM-on-a-Linux-device`,
}

export const nodeClassesFromInstallerType = {
  WINDOWS_MSI: [WINDOWS_WORKSTATION, WINDOWS_SERVER],
  MAC: [MAC, MAC_SERVER],
  LINUX: [LINUX_WORKSTATION, LINUX_SERVER],
}
