import React, { PureComponent } from "react"
import { values, all, propEq } from "ramda"
import styled from "@emotion/styled"
import { Box } from "js/includes/components/Styled"
import { noRowsRenderer, localized, localizationKey } from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import SelectableKeyboardShortcutsTable from "js/includes/components/SelectableKeyboardShortcutsTable"
import showModal from "js/includes/common/services/showModal"
import { colors } from "js/includes/common/theme"
import { StyledSpan } from "js/includes/components/Styled"
import SetLocationAndStatusModal from "../modals/SetLocationAndStatusModal"
import StatusInfoTooltip from "./StatusInfoTooltip"

const StyledSelectableKeyboardShortcutsTableContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  .ad-adhoc-filters {
    width: auto;
    min-width: 140px;
    height: 36px;

    .filters-container {
      margin-left: -5px;

      .filter-header {
        border: 1px solid ${colors.ninjaLight};
        border-radius: 4px;
        padding: 10px;

        + button {
          position: absolute;
          right: 8px;
          top: 6px;
        }
      }

      + div {
        display: none;
      }
    }
  }

  .ad-adhoc-searchbar {
    flex: 1;

    input {
      height: 36px;
    }
  }

  .ReactVirtualized__Table__headerRow {
    background: ${colors.adDiscoveryHeader};
  }

  .list-actions {
    display: flex;
    align-items: center;
    height: 32px;

    button {
      padding: 8px;
      border-radius: 4px;
      &:hover {
        background: ${colors.ninjaWhite};
      }
    }
  }
`

const devicesStatusTokens = {
  new: localizationKey("Discovered"),
  pending: localizationKey("Pending"),
  approved: localizationKey("Approved"),
  rejected: localizationKey("Rejected"),
}

const getDeviceStatusFilterOptions = () => [
  {
    label: localized("All"),
    value: "ALL",
  },
  {
    label: localized("Discovered"),
    value: "NEW",
  },
  {
    label: localized("Pending"),
    value: "PENDING",
  },
  {
    label: localized("Approved"),
    value: "APPROVED",
  },
  {
    label: localized("Rejected"),
    value: "REJECTED",
  },
]

export default class AdhocDiscovery extends PureComponent {
  state = {
    filters: {
      deviceStatus: [],
    },
  }

  filterOptions = [
    {
      type: "deviceStatus",
      label: () => {
        const {
          filters: { deviceStatus },
        } = this.state

        return (
          <span className="m-r-sm">
            {`${localized("Filter")}`}
            {!!deviceStatus.length ? (
              <StyledSpan
                margin={[0, 1, 0, 2]}
                padding={[0, 1]}
                color="white"
                backgroundColor="ninjaBlueSaturated"
                borderRadius="4px"
              >
                {deviceStatus.length}
              </StyledSpan>
            ) : (
              <StyledSpan margin={[0, 1, 0, 2]}>{localized("All")}</StyledSpan>
            )}
          </span>
        )
      },
      renderer: ({ type, onFilterChange, setActiveFilter }) => (
        <SearchableDropDown
          {...{
            openOnFocus: true,
            autoFocus: true,
            isMulti: true,
            valueSelectorKey: "value",
            options: getDeviceStatusFilterOptions(),
            value: this.state.filters[type],
            onSelect: selected => {
              onFilterChange({
                [type]: selected,
              })
            },
          }}
        />
      ),
    },
  ]

  getDefaultFilters() {
    return {
      deviceStatus: [],
    }
  }

  filterRowData = (data, filters) => {
    const { newStatus } = data
    const { deviceStatus } = filters

    return deviceStatus.some(
      ({ value }) => [newStatus?.value, "ALL"].includes(value) || (value === "NEW" && !newStatus),
    )
  }

  onFilter = filters => {
    this.setState({
      filters,
    })
  }

  getActions = selected => {
    const { locations, defaultLocation: dcLocation, onSetLocationAndStatus, onRefresh, isLoading } = this.props
    const REFRESH_DEVICES = {
      id: "refresh-devices",
      token: localizationKey("Refresh"),
      type: "REFRESH_DEVICES",
      action: onRefresh,
    }
    const SET_LOCATION_AND_STATUS = {
      id: "set-location-and-status",
      token: localizationKey("Set Status and Location"),
      type: "SET_LOCATION_AND_STATUS",
      action: async (selected, dispatchCallback) => {
        const devices = values(selected.rows)
        const firstDeviceLocation = devices?.[0]?.newLocation
        const sameLocations = all(propEq("newLocation", firstDeviceLocation))(devices)
        const defaultLocation = sameLocations ? firstDeviceLocation || dcLocation : null

        const firstDeviceStatus = devices?.[0]?.newStatus
        const sameStatuses = all(propEq("newStatus", firstDeviceStatus))(devices)
        const defaultStatus = sameStatuses ? firstDeviceStatus : null

        showModal(
          <SetLocationAndStatusModal
            {...{
              locations,
              defaultLocation,
              defaultStatus,
              onSetLocationAndStatus,
              showStatusInfoTooltip: true,
            }}
          />,
        )
      },
    }
    if (isLoading) {
      return []
    }
    if (selected.length) {
      const devices = values(selected.rows)
      const allNew = all(propEq("status", "NEW"))(devices)
      if (allNew) {
        return [REFRESH_DEVICES, SET_LOCATION_AND_STATUS]
      }
    }
    return [REFRESH_DEVICES]
  }

  getColumns = () => [
    {
      dataKey: "name",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Name"),
    },
    {
      dataKey: "status",
      width: 1,
      flexGrow: 1,
      label: () => (
        <>
          {localized("Status")}
          <StatusInfoTooltip />
        </>
      ),
      cellRenderer: ({ cellData: status, rowData: { newStatus } }) => (
        <Box>
          {newStatus ? (
            <span className="font-bold">{newStatus.label}</span>
          ) : (
            localized(devicesStatusTokens[status.toLowerCase()])
          )}
        </Box>
      ),
    },
    {
      dataKey: "location",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Location"),
      cellRenderer: ({ cellData: location, rowData: { newLocation } }) => (
        <Box>{newLocation ? <span className="font-bold">{newLocation.name}</span> : location?.name ?? "-"}</Box>
      ),
    },
  ]

  render() {
    const { devices, isLoading, selectedDevices, setSelectedDevices } = this.props
    const {
      state: { filters },
      getDefaultFilters,
      filterRowData,
      filterOptions,
      onFilter,
      getActions,
      getColumns,
    } = this

    const actions = getActions(selectedDevices)

    return (
      <StyledSelectableKeyboardShortcutsTableContainer>
        <SelectableKeyboardShortcutsTable
          {...{
            columns: getColumns,
            dataKey: "objectGuid",
            data: devices,
            rowHeight: 45,
            headerHeight: 45,
            searchable: ["name"],
            noRowsRenderer: noRowsRenderer(
              localizationKey("No devices found"),
              isLoading,
              "no-padding text-align-left",
            ),
            filters,
            filterOptions,
            filterRowData,
            getDefaultFilters,
            onFilter,
            actions,
            onAction: ({ type, action }, selectedDevices, { dispatchCallback }) => {
              action(selectedDevices, dispatchCallback, true)
            },
            onCheck: ({ selected }) => {
              setSelectedDevices(selected)
            },
            filtersSearchBarSingleRow: true,
            filtersContainerClassName: "ad-adhoc-filters",
            searchBarContainerClassName: "ad-adhoc-searchbar",
          }}
        />
      </StyledSelectableKeyboardShortcutsTableContainer>
    )
  }
}
