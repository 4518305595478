export const getUpdatedDateTimeComponents = ({ currentUpdateDateTime, currentDateTime, updateType = "date" }) => {
  const dateToExtract = updateType === "date" ? currentUpdateDateTime : currentDateTime
  const timeToExtract = updateType === "time" ? currentUpdateDateTime : currentDateTime
  const year = dateToExtract.getFullYear()
  const month = dateToExtract.getMonth()
  const day = dateToExtract.getDate()
  const hours = timeToExtract.getHours()
  const minutes = timeToExtract.getMinutes()
  const seconds = timeToExtract.getSeconds()
  return [year, month, day, hours, minutes, seconds]
}
