import React, { useState } from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getTextSize, sizer } from "@ninjaone/utils"
import { Highlighter, Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"
import { getAdminBreadcrumbs } from "js/includes/configuration/AdministrationView"
import GlobalSearchDeviceStatus from "./GlobalSearchDeviceStatus"
import GlobalSearchDeviceActionsMenu from "./GlobalSearchDeviceActionsMenu"
import { getReadablePolicyRole } from "js/includes/common/_enums"

const StyledSearchItem = styled.a`
  display: flex;
  gap: ${sizer(2)};
  align-items: center;
  padding: ${sizer(1, 4, 1, 1)};
  cursor: pointer;
  border-radius: 4px;
  min-height: 48px;

  && {
    color: ${({ theme }) => theme.colorTextWeakest};
  }

  &[data-hovered="true"],
  [data-ninja-hover-dropdown-button] button,
  [data-ninja-hover-dropdown-button] button:hover,
  [data-ninja-hover-dropdown-button] button:focus {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &:focus,
  &:focus-visible,
  [data-ninja-hover-dropdown-button] button:focus,
  [data-ninja-hover-dropdown-button] button:focus-visible {
    outline-offset: -2px;
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }

  &:not(:last-of-type) {
    margin-bottom: ${sizer(2)};
  }

  .search-item-icon {
    font-size: 20px;
  }

  .search-item-highlight {
    background-color: transparent;
    font-weight: ${tokens.typography.fontWeight.semiBold};
    color: ${({ theme }) => theme.colorTextStrong};
  }
`

const StyledIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ellipsisText = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledSubText = styled.div`
  font-size: ${getTextSize("xs")};
  line-height: 18px;
  ${ellipsisText}

  .dot-seperator {
    color: ${({ theme }) => theme.textDecorator};
    font-size: 0.75em;
    vertical-align: middle;
  }
`

const searchMatchingFieldsTokenMap = {
  assignedUser: localizationKey("Assigned User"),
  name: localizationKey("Name"),
  email: localizationKey("Email"),
  systemName: localizationKey("System Name"),
  friendlyName: localizationKey("Friendly Name"),
  dnsName: localizationKey("DNS Name"),
  serialNumber: localizationKey("Serial Number"),
  biosSerialNumber: localizationKey("Bios Serial Number"),
  lastLoggedOnUser: localizationKey("Last logged in user"),
  publicIp: localizationKey("Public IP"),
  ipAddress: localizationKey("IP Address"),
  macAddress: localizationKey("MAC Address"),
  serviceTag: localizationKey("Service Tag"),
  id: "ID",
}

const getDescription = ({ type, description, clientName, nodeClass, id }) => {
  switch (type) {
    case "POLICY":
      return getReadablePolicyRole(nodeClass)
    case "GROUP":
      return localized("Group")
    case "BOARD":
      return localized("Board")
    case "TICKET":
      return `${localized("Ticket")} #${id}`
    case "ORGANIZATION":
      return localized("Organization")
    case "LOCATION":
    case "DEVICE":
      return clientName
    case "TECHNICIAN_USER":
    case "END_USER":
      return description
    case "KB_DOCUMENT":
      return description
    case "CHECKLIST":
      return description
    case "APPS_AND_SERVICES":
      return clientName
    default:
      return ""
  }
}

const getHref = url => {
  const isCompleteUrl = /^(https):\/\/[^\s/$.?#]+[^\s]*$/i.test(url)

  if (isCompleteUrl) return url

  if (url.startsWith("#")) {
    return window.location.origin + url
  }

  return `${window.location.origin}#${url}`
}

const descriptionLabelRenderer = type => {
  const descriptionLabelMapper = {
    POLICY: localized("Policy"),
    LOCATION: localized("Location"),
    TECHNICIAN_USER: localized("Technician"),
    END_USER: localized("End User"),
    KB_DOCUMENT: localized("Knowledge Base Article"),
    CHECKLIST: localized("Checklist"),
    APPS_AND_SERVICES: localized("Apps & Services Document"),
  }
  const descriptionLabel = descriptionLabelMapper[type]

  return (
    descriptionLabel && (
      <>
        {descriptionLabel} <span className="dot-seperator">•</span>{" "}
      </>
    )
  )
}

const GlobalSearchAppsMultiPageDetails = ({ data }) => {
  const { description, details = {} } = data
  if (!details?.documentTemplateAllowMultiple) return null

  return (
    <Flex gap={sizer(1)} alignItems="center">
      <StyledSubText>{localized("Template: {{description}}", { description })}</StyledSubText>
    </Flex>
  )
}

const GlobalSearchItem = ({
  data,
  closePopover,
  searchValue = "",
  onClick,
  containerRef,
  isRecentsItem,
  divisionConfig,
  itemIsInPopover,
}) => {
  const {
    icon,
    name,
    iconRenderer,
    description: serverDescription,
    type,
    matchAttr = "",
    matchAttrValue = "",
    route,
    details = {},
    onClick: _handleItemOnClick,
  } = data
  const [isHovered, setIsHovered] = useState(false)
  const [hoveredItemId, setHoveredItemId] = useState(null)
  const matchingField = searchMatchingFieldsTokenMap[matchAttr]
  const localizedMatchingField = matchingField ? localized(matchingField) : matchAttr
  const showMatchingFieldWhenItsNotAlreadyDisplayed =
    matchAttrValue &&
    ![name, serverDescription].includes(matchAttrValue) &&
    type !== "ADMINISTRATION" &&
    type !== "TICKET"

  const matchedByName = ["name", "systemName", "dnsName", "friendlyName"].includes(matchAttr)
  const matchedByDescription = ["email", "id"].includes(matchAttr)
  const description = getDescription(data)
  const lastLoggedOnUser = details?.lastLoggedOnUser || details?.lastLoggedInUser

  const administrationBreadcrumbs =
    type === "ADMINISTRATION" &&
    getAdminBreadcrumbs({
      divisionConfig,
      pathname: route,
      initialAppsBreadcrumbs: [{ name: localized("Administration") }],
    })

  return (
    <StyledSearchItem
      {...{
        ...(route && { href: getHref(route) }),
        onClick: async () => {
          closePopover?.()
          if (_handleItemOnClick) {
            await _handleItemOnClick?.()
          }
          onClick?.()
        },
        onMouseEnter: () => {
          setIsHovered(true)

          if (type === "DEVICE") {
            setHoveredItemId(data.id)
          }
        },
        onMouseLeave: () => {
          setIsHovered(false)

          if (type === "DEVICE") {
            setHoveredItemId(null)
          }
        },
        "data-testid": "global-search-item",
        "data-type": data.type.toLowerCase(),
        "data-hovered": isHovered,
      }}
    >
      <StyledIconWrapper>
        {icon && <FontAwesomeIcon icon={icon} className="search-item-icon" fixedWidth />}
        {iconRenderer && iconRenderer(data)}
      </StyledIconWrapper>
      <Box
        {...{
          [`data-test-${type.toLowerCase()}-id`]: data.id,
          overflow: "hidden",
          flex: "1",
          "data-testid": data.id,
        }}
      >
        <Text
          size="sm"
          lineHeight="21px"
          color="colorTextStrong"
          tooltipZIndex={itemIsInPopover && 10000}
          tooltipText={name}
        >
          {matchedByName ? (
            <span aria-label={name}>
              <Highlighter text={name} highlight={searchValue} highlightedItemClass="search-item-highlight" />
            </span>
          ) : (
            <span aria-label={name}>{name}</span>
          )}
        </Text>

        {type === "DEVICE" && (
          <div>
            <Flex gap={sizer(2)} alignItems="center">
              <StyledSubText>{description}</StyledSubText>
              <GlobalSearchDeviceStatus {...{ data }} />
            </Flex>
            {lastLoggedOnUser && matchAttr !== "lastLoggedOnUser" && (
              <StyledSubText>
                {localized("Last logged-in user: {{lastLoggedOnUser}}", { lastLoggedOnUser })}
              </StyledSubText>
            )}
          </div>
        )}
        {type === "APPS_AND_SERVICES" && (
          <div>
            <StyledSubText>
              {descriptionLabelRenderer(type)}
              {description}
            </StyledSubText>
            <GlobalSearchAppsMultiPageDetails {...{ data }} />
          </div>
        )}
        {[
          "POLICY",
          "END_USER",
          "TECHNICIAN_USER",
          "GROUP",
          "ORGANIZATION",
          "LOCATION",
          "BOARD",
          "TICKET",
          "KB_DOCUMENT",
          "CHECKLIST",
          "ADMINISTRATION",
        ].includes(type) && (
          <StyledSubText>
            {descriptionLabelRenderer(type)}
            {matchedByDescription ? (
              <span aria-label={description}>
                <Highlighter text={description} highlight={searchValue} highlightedItemClass="search-item-highlight" />
              </span>
            ) : (
              description
            )}
          </StyledSubText>
        )}
        {administrationBreadcrumbs && (
          <StyledSubText>
            {administrationBreadcrumbs.map(
              ({ name }, index) => `${name}${index !== administrationBreadcrumbs.length - 1 ? " > " : ""}`,
            )}
          </StyledSubText>
        )}
        {showMatchingFieldWhenItsNotAlreadyDisplayed && (
          <StyledSubText>
            {localizedMatchingField}
            {`: "`}
            {matchedByName ? (
              matchAttrValue
            ) : (
              <span aria-label={matchAttrValue}>
                <Highlighter
                  text={matchAttrValue}
                  highlight={searchValue}
                  highlightedItemClass="search-item-highlight"
                />
              </span>
            )}

            {`"`}
          </StyledSubText>
        )}
      </Box>
      {type === "DEVICE" && (
        <Box
          marginLeft="auto"
          onClick={event => {
            // prevent StyledSearchItem onClick & href from firing
            event.stopPropagation()
            event.preventDefault()
          }}
        >
          <GlobalSearchDeviceActionsMenu
            {...{
              containerRef,
              device: data,
              closePopover,
              hoveredItemId,
              ...(isRecentsItem && { initialIsVisible: true }),
            }}
          />
        </Box>
      )}
    </StyledSearchItem>
  )
}

export default connect(({ session }) => ({
  divisionConfig: session.divisionConfig,
}))(GlobalSearchItem)
