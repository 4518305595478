import { fetchJson } from "js/includes/common/utils"

export const copyScript = (id, name) =>
  fetchJson(`/scripting/scripts/copy`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        fromId: id,
        name,
      }),
    },
  })
