import { useEffect, useRef, useState } from "react"
import DeviceQuickActionsContextMenu from "js/includes/components/DeviceContextMenu/DeviceQuickActionsContextMenu"

const GlobalSearchDeviceActionsMenu = ({
  containerRef,
  initialIsVisible = false,
  device,
  closePopover,
  hoveredItemId,
}) => {
  const eleRef = useRef()
  const [isVisible, setIsVisible] = useState(initialIsVisible)

  useEffect(() => {
    const container = containerRef?.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      {
        root: container,
      },
    )

    if (container) {
      observer.observe(eleRef.current)
    }

    return () => {
      if (container) {
        observer.disconnect()
      }
    }
  }, [containerRef])

  return (
    <div
      ref={eleRef}
      data-testid="global-search-device-actions-menu"
      id="fs-id-global-search-device-actions-menu"
      onMouseDown={event => {
        // prevent onBlur from firing on GlobalSearch StyledContainer
        event.preventDefault()
      }}
    >
      {isVisible && <DeviceQuickActionsContextMenu {...{ device, closePopover, hoveredItemId, showOnHover: true }} />}
    </div>
  )
}

export default GlobalSearchDeviceActionsMenu
