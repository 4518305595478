import { useMemo } from "react"
import { clone, pluck } from "ramda"
import { useTheme } from "@emotion/react"
import { filterTypes, cellTypes } from "@ninjaone/components/src/DataTable"
import { DataTable, Text, AlertMessage } from "@ninjaone/components"
import { ExternalLinkIconLight } from "@ninjaone/icons"
import { Flex, StyledSpan } from "js/includes/components/Styled"
import { localizationKey, localized, localizedWith, localizeMappingName } from "js/includes/common/utils"
import { useNetworkDiscovery } from "./NetworkDiscoveryContext"
import { getSnmpCredentialCol, getNetworkDiscoveryDojoLink } from "./utils"
import Link from "../components/Link"

export const Discovery = () => {
  const {
    loading,
    discoveryDevices,
    devicesForReview,
    setDevicesForReview,
    values,
    restartDiscovery,
    selectedDevicesError,
    setSelectedDevicesError,
    setDevicesForReviewError,
    discoveryComplete,
  } = useNetworkDiscovery()
  const { snmpCredOne, snmpCredTwo, protocolOne, protocolTwo, telnetSshCred, org } = values
  const selectedRowIds = useMemo(() => pluck("ip")(devicesForReview), [devicesForReview])
  const theme = useTheme()

  const getColumns = () => {
    return [
      {
        Header: "IP/DNS",
        id: "ip",
        accessor: "ip",
        maxWidth: "150px",
        getDescription: ({ dnsName, netbiosName }) => dnsName || "-",
      },
      {
        Header: localized("Ping"),
        id: "pingMS",
        accessor: ({ pingMS }) => (pingMS ? `${pingMS} ms` : null),
      },
      {
        Header: localized("Port"),
        id: "ports",
        accessor: ({ ports }) => ports?.join(", "),
        disableSortBy: true,
      },
      ...(snmpCredOne
        ? [getSnmpCredentialCol({ credential: snmpCredOne, protocol: protocolOne.value, theme, cellTypes })]
        : []),
      ...(snmpCredTwo
        ? [getSnmpCredentialCol({ credential: snmpCredTwo, protocol: protocolTwo.value, theme, cellTypes })]
        : []),
      ...(telnetSshCred
        ? [
            {
              Header: "Telnet/SSH",
              id: "telnetssh",
              accessor: ({ telnet, ssh }) => (telnet || ssh ? true : null),
              disableSortBy: true,
              tooltipToken: telnetSshCred.label,
              getCellTypeProps: ({ telnet, ssh }) =>
                telnet || ssh
                  ? {
                      type: cellTypes.ICON,
                      props: {
                        svgIconName: "CheckIconSvg",
                        fill: theme.color.success["100"],
                        isCenter: true,
                      },
                    }
                  : {
                      props: {
                        isCenter: true,
                      },
                    },
            },
          ]
        : []),
      {
        Header: localized("Device role"),
        id: "role",
        accessor: row => {
          if (loading && !row?.role) return null
          if (row?.role) return localizeMappingName(row.role)
          return localized("Unknown")
        },
      },
      {
        Header: localized("OS"),
        id: "os",
        accessor: "os",
      },
      {
        Header: localized("Model"),
        id: "model",
        accessor: "model",
      },
      {
        Header: localized("Vendor"),
        id: "vendor",
        accessor: "vendor",
      },
      {
        Header: localized("Firmware"),
        id: "firmware",
        accessor: "firmware",
      },
    ]
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" margin="16px 0">
        <Text size="sm" color="colorTextWeakest">
          {localizedWith("Once discovery is complete, select at least 1 device to add to <%org>x<%>.", {
            org: () => <StyledSpan fontWeight="bold">{org.name}</StyledSpan>,
          })}
        </Text>
        <Link href={getNetworkDiscoveryDojoLink()}>
          {localized("Help")} <ExternalLinkIconLight size="xs" />
        </Link>
      </Flex>

      <DataTable
        tableId="network-discovery-table"
        loading={loading}
        isReactive
        hideCheckboxes={loading}
        rows={discoveryDevices}
        columns={getColumns()}
        {...(discoveryComplete && {
          onRefresh: () => {
            setSelectedDevicesError(false)
            restartDiscovery()
          },
        })}
        onSelectRows={devices => {
          setSelectedDevicesError(false)
          setDevicesForReviewError(false)
          const newDevices = clone(devices.filter(({ nodeId }) => !nodeId))
          setDevicesForReview(newDevices)
        }}
        getRowId={({ ip }) => ip}
        selectedRowIds={selectedRowIds}
        hideSettingsButton
        filters={{
          primary: [
            {
              name: "type",
              type: filterTypes.SINGLE_SELECT,
              defaultValue: "all",
              labelToken: localizationKey("Type"),
              componentProps: {
                options: [
                  { label: localized("All IPs"), value: "all" },
                  { label: localized("All responding"), value: "allResponding" },
                  { label: localized("All SNMP responding"), value: "allSnmpResponding" },
                  { label: localized("Existing devices"), value: "existing" },
                  { label: localized("Offline devices"), value: "offline" },
                ],
              },
            },
          ],
        }}
        getCustomRowProps={({ nodeId }) => ({
          disabled: !!nodeId,
          disabledTooltipLabel: localized("This device has already been added to an organization"),
        })}
        autoResetSelectedRows={false}
      />

      {selectedDevicesError && (
        <AlertMessage
          variant="danger"
          labelToken={localizationKey("Select at least 1 device to add to the organization")}
        />
      )}
    </>
  )
}
