export const isNumber = num => !isNaN(num) && num !== Infinity && typeof num === "number"

export const getDecimal = exp => Math.pow(10, exp)
export const getPartition = (perc, num, decimal) => Math.round((perc / 100) * num * decimal) / decimal
export const getPercent = (part, target, decimal) => Math.round((part / target) * 100 * decimal) / decimal

export const percentage = {
  of: (perc, num, exp = 0) => {
    const decimal = getDecimal(exp)
    const part = getPartition(perc, num, decimal)
    return isNumber(part) ? part : 0
  },
  from: (part, target, exp = 0) => {
    const decimal = getDecimal(exp)
    const percent = getPercent(part, target, decimal)
    return isNumber(percent) ? percent : 0
  },
}
