import { createSelector } from "reselect"
import { defaultTo, compose, find, ifElse, filter, propOr, propEq, pathOr } from "ramda"
import { sortListByName } from "js/state/helpers/listSort"
import { memoize } from "js/includes/common/utils"

export const getFields = pathOr([], ["psa", "ticketSetupValues", "fieldConfig", "pickListFields"])
const getWorkTypeField = pathOr([], ["psa", "ticketSetupValues", "fieldConfig", "referenceEntityFields"])

export const ticketTitleContentsList = [
  { name: "Body", id: 6 },
  { name: "Category", id: 4 },
  { name: "Custom text", id: 10 },
  { name: "Device name", id: 3 },
  { name: "Device role", id: 2 },
  { name: "Organization name", id: 1 },
  { name: "Policy name", id: 7 },
  { name: "Private IP", id: 9 },
  { name: "Public IP", id: 8 },
  { name: "Sub-category", id: 5 },
]

const getSelectedIssueTypeFromResponse = state => state.psa.ticketTemplate.IssueType

export const getAutotaskFieldOptions = state => name =>
  compose(
    sortListByName,
    propOr([], "values"),
    defaultTo({}),
    find(propEq("name", name)),
    ifElse(_ => name === "allocationCode", getWorkTypeField, getFields),
  )(state)

export const getTicketTitleHasCustomText = ticketTitleElements =>
  find(
    // 10 === 'Custom Text' option
    val => val && propEq("id", 10, val),
    ticketTitleElements,
  )

function getSubIssueTypeFromResponse(state) {
  return selectedIssueType =>
    selectedIssueType &&
    compose(
      sortListByName,
      filter(propEq("parentId", selectedIssueType.id)),
      getAutotaskFieldOptions(state),
    )("SubIssueType")
}

const getSubIssueTypeOptionsFromResponse = state =>
  createSelector([getSelectedIssueTypeFromResponse], getSubIssueTypeFromResponse(state))(state)

export const getSubIssueTypeOptionsCreator = state =>
  memoize(issueType => getSubIssueTypeFromResponse(state)(issueType))

export function getAutotaskTicketValueOptions(state) {
  return key => {
    const getFieldOptions = getAutotaskFieldOptions(state)
    switch (key) {
      case "SubIssueType":
        return getSubIssueTypeOptionsFromResponse(state)
      case "ticketTitleElements":
        return ticketTitleContentsList
      case "createAndClose":
      case "onReset":
        return getFieldOptions("Status")
      default:
        return getFieldOptions(key)
    }
  }
}
