import { useEffect, useRef, useState, useCallback, useMemo } from "react"
import styled from "@emotion/styled"
import {
  Root as PopoverRoot,
  Anchor as PopoverAnchor,
  Content as PopoverContent,
  Portal as PopoverPortal,
} from "@radix-ui/react-popover"
import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden"
import { v4 as uuidv4 } from "uuid"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import PropTypes from "prop-types"

import tokens from "@ninjaone/tokens"
import { CaretDownIcon, CaretUpIcon, ClockIconLight } from "@ninjaone/icons"
import { isRequiredIf } from "@ninjaone/utils"
import {
  getDisplayHour,
  getDisplayTime,
  getLocaleTimeFormat,
  getTimezone,
  getTimeString,
  isTimeValid,
  setTimeChangeByTypeAndUnit,
} from "@ninjaone/utils/helpers"
import {
  isDownKey,
  isEnterKey,
  isSpaceKey,
} from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/charsets"
import {
  initialDefaultLanguage,
  localized,
} from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization"

import { PickerInputWithActions } from "../Components/PickerInputWithActions"
import { Label } from "../../Form/Label"
import { TOKEN_ERROR_MSG_REQUIRED } from "../errorMessages"

const StyledPopoverContent = styled(PopoverContent)`
  z-index: 9999;
  display: flex;
  gap: ${tokens.spacing[4]};
  padding: ${tokens.spacing[1]} ${tokens.spacing[3]};
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  box-shadow: 0px 2px 4px 0px rgba(33, 31, 51, 0.1); /* TODO: Replace with elevation token */
`

const StyledInputField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.spacing[1]};
  width: 40px;
`

const StyledSeparator = styled.span`
  position: absolute;
  left: 58px;
  top: calc(50% - 24px / 2);
  font-size: ${tokens.typography.fontSize.body};
  color: ${({ theme }) => theme.colorTextStrong};
`

const StyledButton = styled.button`
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: ${tokens.borderRadius[1]};
  padding: 0;
  color: ${({ theme }) => theme.colorTextWeakest};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};

  &:hover {
    color: ${({ theme }) => theme.colorTextStrong};
    background-color: ${({ theme }) => theme.colorForegroundHover};
    cursor: pointer;
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const StyledInput = styled.input`
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  border: 1px solid ${({ error, theme }) => (error ? theme.colorBorderError : theme.colorBorderWeak)};
  border-radius: ${tokens.borderRadius[1]};
  font-size: ${tokens.typography.fontSize.body};
  text-align: center;
  color: ${({ theme }) => theme.colorTextStrong};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  -moz-appearance: textfield;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    outline-offset: 0;
  }
`

const StyledSelect = styled.select`
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 0 9px; // safari doesn't properly align text when given a width, padding corrects overall size on focus
  border: 0;
  font-size: ${tokens.typography.fontSize.body};
  text-align: center;
  color: ${({ theme }) => theme.colorTextStrong};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  appearance: none;
  pointer-events: none;

  &:focus-visible {
    border-radius: ${tokens.borderRadius[1]};
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const SINGLE_DIGIT_INPUT_THRESHOLD = 3
const DOUBLE_DIGIT_INPUT_THRESHOLD = 2
const DEFAULT_HOUR = 9
const DEFAULT_MINUTE = 0

const getTime = ({ value, useDefaultTime }) => {
  if (!value) {
    const newDate = new Date()
    newDate.setHours(DEFAULT_HOUR)
    newDate.setMinutes(DEFAULT_MINUTE)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate
  }

  if (value && useDefaultTime) {
    const initialDateWithDefaultTime = new Date(value)
    initialDateWithDefaultTime.setHours(DEFAULT_HOUR)
    initialDateWithDefaultTime.setMinutes(DEFAULT_MINUTE)
    initialDateWithDefaultTime.setSeconds(0)
    initialDateWithDefaultTime.setMilliseconds(0)
    return initialDateWithDefaultTime
  }

  return new Date(value)
}

const TimePicker = ({
  ariaErrorId,
  ariaLabel,
  collisionBoundary,
  compact = false,
  disabled = false,
  disableClear = false,
  errorMessage,
  locale,
  format = getLocaleTimeFormat(locale ?? navigator.language ?? initialDefaultLanguage),
  hourOnly = false,
  isDateTime,
  labelText,
  onClose,
  onFocus,
  onOpen,
  onTimeChange,
  placeholder,
  popoverAlignment = "start",
  popoverPosition = "bottom",
  portal = true,
  required = false,
  timezone = getTimezone(),
  tooltipAlignment = "end",
  tooltipPosition = "bottom",
  tooltipText,
  useDefaultTime = false,
  useTimezone = false,
  value,
  width = 176,
}) => {
  const [mainInputValue, setInputValue] = useState("")
  const [hourInput, setHourInput] = useState("")
  const [minuteInput, setMinuteInput] = useState("")
  const [currentErrorMessage, setErrorMessage] = useState("")
  const [hourError, setHourError] = useState(false)
  const [minuteError, setMinuteError] = useState(false)
  const [ampm, setAmPm] = useState("")
  const [isPopoverOpen, setPopoverOpen] = useState(false)
  const inputRef = useRef(null)
  const time = useRef(getTime({ value, useDefaultTime }))
  const initialDay = time.current.getDate()
  const is12HourFormat = format === 12

  const ariaId = useMemo(() => {
    return uuidv4()
  }, [])

  const handleBlur = () => {
    if (isDateTime) return
    !mainInputValue && setErrorMessage(TOKEN_ERROR_MSG_REQUIRED)
  }

  const handleClearClick = () => {
    setInputValue("")
    onTimeChange(null)
    time.current = getTime({ value, useDefaultTime })
    inputRef.current.focus()
  }

  const handleOpen = () => {
    const hour = time.current.getHours()
    const minute = time.current.getMinutes()
    const currentAmPm = hour < 12 ? "AM" : "PM"
    time.current.setSeconds(0)
    time.current.setMilliseconds(0)

    setInputValue(
      getTimeString({
        hour,
        minute,
        ampm,
        format,
        hourOnly,
      }),
    )
    onOpen?.(time.current)
    onTimeChange(time.current)
    setHourInput(getDisplayHour({ hour, format }))
    setMinuteInput(getDisplayTime(minute))
    setAmPm(currentAmPm)
    setErrorMessage("")
    setPopoverOpen(true)
  }

  const handleClose = () => {
    onClose?.(time.current)
    setPopoverOpen(false)
    setHourError(false)
    setMinuteError(false)
    inputRef.current.focus()
  }

  const handleInputChange = ({ unit, value }) => {
    const newValue = parseInt(value)
    const isValueValid = isTimeValid({ unit, value, format })

    if (unit === "hour") {
      if (format === 24 && isValueValid) time.current.setHours(newValue)
      if (format === 12 && isValueValid) {
        const currentHourOffset = time.current.getHours() >= 12 ? 12 : 0
        time.current.setHours(currentHourOffset + newValue)
      }

      const hourValue = isValueValid && newValue > 0 ? getDisplayHour({ hour: newValue, format }) : newValue
      setHourError(newValue > 0 && !isValueValid)
      setHourInput(hourValue)
    }

    if (unit === "minute") {
      const minuteValue = isValueValid && newValue > 0 ? getDisplayTime(newValue) : newValue
      isValueValid && time.current.setMinutes(newValue)
      setMinuteError(!isValueValid)
      setMinuteInput(minuteValue)
    }

    setAmPm(time.current.getHours() < 12 ? "AM" : "PM")
    time.current.getDate() !== initialDay && time.current.setDate(initialDay)
    time.current.setSeconds(0)
    time.current.setMilliseconds(0)
    onTimeChange(time.current)
  }

  const handleUnitChange = ({ type, unit }) => {
    setTimeChangeByTypeAndUnit({ initialDay, time: time.current, type, unit })

    unit === "hour" && setHourError(false)
    if (unit === "minute") {
      setMinuteInput(getDisplayTime(time.current.getMinutes()))
      setMinuteError(false)
    }

    const hour = time.current.getHours()
    setHourInput(getDisplayHour({ hour, format }))
    setAmPm(hour < 12 ? "AM" : "PM")
    onTimeChange(time.current)
  }

  const handleHourBlur = () => {
    setHourInput(getDisplayHour({ hour: time.current.getHours(), format }))
    setHourError(false)
  }

  const handleMinuteBlur = () => {
    setMinuteInput(getDisplayTime(time.current.getMinutes()))
    setMinuteError(false)
  }

  const handleToggleAMPM = () => {
    const currentHour = time.current.getHours()
    time.current.setHours(currentHour > 12 ? currentHour - 12 : currentHour + 12)
    setAmPm(time.current.getHours() < 12 ? "AM" : "PM")
    time.current.getDate() !== initialDay && time.current.setDate(initialDay)
    onTimeChange(time.current)
  }

  const updateInputValue = useCallback(
    time => {
      setInputValue(
        getTimeString({
          hour: time.current.getHours(),
          minute: time.current.getMinutes(),
          ampm: time.current.getHours() >= 12 ? "PM" : "AM",
          format,
          hourOnly,
        }),
      )
    },
    [format, hourOnly],
  )

  useEffect(() => {
    updateInputValue(time)
  }, [hourInput, minuteInput, ampm, updateInputValue])

  useEffect(() => {
    time.current = getTime({ value, useDefaultTime })

    if (value) {
      updateInputValue(time)
    }

    if (useDefaultTime || !value) {
      setInputValue("")
    }
  }, [useDefaultTime, value, updateInputValue])

  const popoverContent = (
    <StyledPopoverContent
      align={popoverAlignment}
      collisionBoundary={collisionBoundary?.current}
      onEscapeKeyDown={handleClose}
      onInteractOutside={e => e.target !== inputRef.current && handleClose()}
      position={popoverPosition}
      sideOffset={4}
      alignOffset={isDateTime && 11}
    >
      {hourError && (
        <VisuallyHidden id={`${ariaId}-hourError`} aria-live="polite">
          {localized("Error invalid hour")}
        </VisuallyHidden>
      )}

      {minuteError && (
        <VisuallyHidden id={`${ariaId}-minuteError`} aria-live="polite">
          {localized("Error invalid minute")}
        </VisuallyHidden>
      )}

      <StyledInputField>
        <StyledButton
          aria-label={localized("Increment hour")}
          data-ninja-time-picker-hour-increment=""
          onClick={() => handleUnitChange({ unit: "hour", type: "increment" })}
          type="button"
        >
          <CaretUpIcon />
        </StyledButton>

        <StyledInput
          aria-errormessage={`${ariaId}-hourError`}
          aria-invalid={!!hourError}
          aria-label={localized("Hour")}
          data-ninja-time-picker-hour-input
          error={hourError}
          max={is12HourFormat ? 12 : 23}
          min={is12HourFormat ? 1 : 0}
          maxLength={parseInt(hourInput) < 10 ? SINGLE_DIGIT_INPUT_THRESHOLD : DOUBLE_DIGIT_INPUT_THRESHOLD}
          onBlur={handleHourBlur}
          onChange={e => handleInputChange({ unit: "hour", value: e.target.value })}
          type="number"
          value={hourInput || ""}
          {...{ required }}
        />

        <StyledButton
          aria-label={localized("Decrement hour")}
          data-ninja-time-picker-hour-decrement=""
          onClick={() => handleUnitChange({ unit: "hour", type: "decrement" })}
          type="button"
        >
          <CaretDownIcon />
        </StyledButton>
      </StyledInputField>

      {!hourOnly && (
        <>
          <StyledSeparator>:</StyledSeparator>

          <StyledInputField>
            <StyledButton
              aria-label={localized("Increment minute")}
              data-ninja-time-picker-minute-increment=""
              onClick={() => handleUnitChange({ unit: "minute", type: "increment" })}
              type="button"
            >
              <CaretUpIcon />
            </StyledButton>

            <StyledInput
              aria-errormessage={`${ariaId}-minuteError`}
              aria-invalid={!!minuteError}
              aria-label={localized("Minute")}
              data-ninja-time-picker-minute-input
              error={minuteError}
              max="59"
              min="0"
              maxLength={parseInt(minuteInput) < 10 ? SINGLE_DIGIT_INPUT_THRESHOLD : DOUBLE_DIGIT_INPUT_THRESHOLD}
              onBlur={handleMinuteBlur}
              onChange={e => handleInputChange({ unit: "minute", value: e.target.value })}
              type="number"
              value={minuteInput || ""}
              {...{ required }}
            />

            <StyledButton
              aria-label={localized("Decrement minute")}
              data-ninja-time-picker-minute-decrement=""
              onClick={() => handleUnitChange({ unit: "minute", type: "decrement" })}
              type="button"
            >
              <CaretDownIcon />
            </StyledButton>
          </StyledInputField>
        </>
      )}

      {is12HourFormat && (
        <>
          <VisuallyHidden id={`${ariaId}-ampm`}>{localized("Toggle AM PM")}</VisuallyHidden>

          <StyledInputField>
            <StyledButton
              aria-labelledby={`${ariaId}-ampm`}
              data-ninja-time-picker-ampm-increment=""
              onClick={handleToggleAMPM}
              type="button"
            >
              <CaretUpIcon />
            </StyledButton>

            <StyledSelect
              aria-labelledby={`${ariaId}-ampm`}
              data-ninja-time-picker-ampm-select
              onChange={handleToggleAMPM}
              value={ampm}
            >
              <option>AM</option>
              <option>PM</option>
            </StyledSelect>

            <StyledButton
              aria-labelledby={`${ariaId}-ampm`}
              data-ninja-time-picker-ampm-decrement=""
              onClick={handleToggleAMPM}
              type="button"
            >
              <CaretDownIcon />
            </StyledButton>
          </StyledInputField>
        </>
      )}
    </StyledPopoverContent>
  )

  return (
    <Box position="relative" data-ninja-time-picker="" {...(!isDateTime && { minWidth: "178px" })}>
      <PopoverRoot open={isPopoverOpen}>
        {labelText && (
          <Label
            labelFor={ariaId}
            {...{
              disabled,
              labelText,
              required,
              tooltipText,
            }}
          />
        )}

        <PopoverAnchor>
          <VisuallyHidden id={`${ariaId}-description`}>{localized("Click to open the time picker")}</VisuallyHidden>

          <PickerInputWithActions
            ariaDescribedBy={`${ariaId}-description`}
            ariaErrorId={ariaErrorId || ariaId}
            error={errorMessage || currentErrorMessage}
            disableClear={!mainInputValue || disabled || isPopoverOpen || disableClear}
            id={ariaId}
            inputIcon={{
              ariaLabel: localized("Open the time picker"),
              icon: <ClockIconLight />,
              onBlur: required ? handleBlur : undefined,
              onClick: handleOpen,
            }}
            onClearClick={handleClearClick}
            onClick={handleOpen}
            onKeyDown={e => (isEnterKey(e) || isSpaceKey(e) || isDownKey(e)) && handleOpen()}
            placeholder={
              placeholder
                ? placeholder
                : `HH${hourOnly ? "" : ":MM"}${useTimezone && timezone ? ` (${localized(timezone)})` : ""}`
            }
            ref={inputRef}
            readOnly
            type="text"
            value={mainInputValue && useTimezone ? `${mainInputValue} (${localized(timezone)})` : mainInputValue}
            {...(required && { onBlur: handleBlur })}
            {...(!labelText && { ariaLabel })}
            {...{
              compact,
              disabled,
              isDateTime,
              onFocus,
              required,
              tooltipAlignment,
              tooltipPosition,
              width,
            }}
          />
        </PopoverAnchor>

        {portal ? <PopoverPortal>{popoverContent}</PopoverPortal> : popoverContent}
      </PopoverRoot>
    </Box>
  )
}

TimePicker.propTypes = {
  /**
   * The accessible label for the input when `labelText` is not defined.
   */
  ariaLabel: isRequiredIf(
    PropTypes.string,
    props => !props.hasOwnProperty("labelText"),
    "`ariaLabel` is required when `labelText` is not defined",
  ),
  /**
   * Determines if the component should not expand full width.
   */
  compact: PropTypes.bool,
  /**
   * Disables the clear button when the input is populated.
   */
  disableClear: PropTypes.bool,
  /**
   * The disabled state for the component.
   */
  disabled: PropTypes.bool,
  /**
   * The error message passed from the parent.
   */
  errorMessage: PropTypes.string,
  /**
   * The time format. Defaults to format based on browser locale.
   */
  format: PropTypes.oneOf([12, 24]),
  /**
   * Determines if only the hour can be set in the picker.
   */
  hourOnly: PropTypes.bool,
  /**
   * The label for the component.
   */
  labelText: PropTypes.string,
  /**
   * The locale that will determine the the time format.
   */
  locale: PropTypes.string,
  /**
   * The event handler that runs when the popover closes.
   */
  onClose: PropTypes.func,
  /**
   * The event handler that runs when the popover opens.
   */
  onOpen: PropTypes.func,
  /**
   * The event handler that runs when the time changes.
   */
  onTimeChange: PropTypes.func.isRequired,
  /**
   * The placeholder text for the input.
   */
  placeholder: PropTypes.string,
  /**
   * The horizontal alignment of the popover against the input.
   */
  popoverAlignment: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * The position of the popover relative to the input.
   */
  popoverPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * Sets the required state for the component.
   */
  required: PropTypes.bool,
  /**
   * The timezone used by the component. Defaults to the browser's timezone.
   */
  timezone: PropTypes.string,
  /**
   * The horizontal alignment of tooltips against their icons.
   */
  tooltipAlignment: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * The position of the tooltips relative to their icons.
   */
  tooltipPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * The text for the more information tooltip in the component's label.
   */
  tooltipText: PropTypes.string,
  /**
   * Determines if the default time should be used, even when a value is defined.
   */
  useDefaultTime: PropTypes.bool,
  /**
   * Determines if the component should display the timezone.
   */
  useTimezone: PropTypes.bool,
  /**
   * The value for the component.
   */
  value: PropTypes.instanceOf(Date),
  /**
   * The width of the component when the `compact` prop is true.
   */
  width: PropTypes.number,
}

export default TimePicker
