import { ConfirmationModal, Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { sendActionMultipleDevices } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import {
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  runDeviceSearchToolbarAction,
  showSuccessMessage,
} from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import TypeEmailToConfirm from "js/includes/components/TypeEmailToConfirm"

export const DisownDeviceModal = ({ unmount, nodeIds, onSuccess, runnerActionName }) => {
  const [loading, setLoading] = useMountedState(false)
  const [confirmationEmail, setConfirmationEmail] = useMountedState("")

  const disown = async () => {
    setLoading(true)
    try {
      if (runnerActionName) {
        await runDeviceSearchToolbarAction({ action: runnerActionName })
      } else {
        await sendActionMultipleDevices(nodeIds, { type: "DISOWN" })
      }
      showSuccessMessage(localized("Action sent successfully"))
      onSuccess()
      unmount?.()
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        reportErrorAndShowMessage(error, localizationKey("Error disowning device"))
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <ConfirmationModal
      {...{ unmount }}
      type="danger"
      titleToken={localizationKey("Disown this device?")}
      actionToken={localizationKey("Disown")}
      onConfirm={disown}
      disabled={loading || !confirmationEmail?.isValid}
      onCancel={unmount}
    >
      <Text
        size="sm"
        textWrap
        textWrapLineLimit={3}
        token={localizationKey(
          "This action will disown the device from Apple Business Management and delete it from NinjaOne as well",
        )}
      />
      <Box marginTop={sizer(3)}>
        <TypeEmailToConfirm
          inputLabel={localized("Are you sure you want to proceed?")}
          placeHolderText={localized("Please enter your email")}
          value={confirmationEmail?.value}
          onChange={setConfirmationEmail}
        />
      </Box>
    </ConfirmationModal>
  )
}
