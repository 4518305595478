import moment from "moment-timezone"
import { localized, time } from "@ninjaone/webapp/src/js/includes/common/utils"

export const timezoneMap = {
  current_location: moment.tz.guess(),
}

export const mapCommonLocationsTimezoneToOption = ({ name, timeZoneId: _timezoneId }) => {
  const timezoneId = timezoneMap[_timezoneId] || _timezoneId
  const timezone = timezoneId.startsWith("_") ? null : moment.tz(timezoneId)

  const labelText = timezoneMap[_timezoneId] ? localized(name) : name

  const timezoneAbbr = timezone.zoneAbbr()
  const timezoneAbbrParsed = timezoneAbbr.startsWith("-") ? `UTC ${timezoneAbbr}` : timezoneAbbr

  return {
    labelText,
    value: _timezoneId,
    ...(timezone && {
      timezone: `${timezoneAbbrParsed} - `,
      currentTime: time(timezone),
    }),
  }
}

export const mapLocationsTimezoneToOption = ({ name, timeZoneId: _timezoneId, offset }) => ({
  offset,
  labelText: name,
  value: _timezoneId,
})
