import $ from "jquery"
import _ from "underscore"

const childselectoption = {
  routine: function(el, model) {
    // Defer needed for FF
    _.defer(function() {
      // If there is a selection
      var modelValue = +model.get("value")
      if (modelValue > 0) {
        // If the current selection isn't valid for the current parent selection
        if (
          // eslint-disable-next-line
          model.get("parentOption").get("value") !=
          model
            .get("items")
            .findWhere({ value: modelValue })
            .get("parentValue")
        ) {
          // Unselect the selection of this child select
          $(el)
            .parent()
            .val(-1)

          // Lets also update the model appropriately
          model.set("value", -1)
        }
      }

      // eslint-disable-next-line
      if (model.get("parentOption").get("value") == $(el).data("parent-value")) {
        $(el).removeClass("hide")
        $(el).attr("disabled", false) // For IE and Edge
      } else {
        $(el).addClass("hide")
        $(el).attr("disabled", true) // For IE and Edge
      }
    })
  },

  bind: function(el) {
    return true
  },

  unbind: function(el) {
    return true
  },
}

export default childselectoption
