const compare = function(value, operator, comparison) {
  // Seems like the formatter is being called twice, once with an undefined value
  // eslint-disable-next-line
  if (value == undefined) {
    return false
  }

  value = value.length ? value.length : value

  switch (operator) {
    case "lte":
      return value <= comparison
    case "lt":
      return value < comparison
    case "gte":
      return value >= comparison
    case "gt":
      return value > comparison
    case "mod":
      return value % comparison
    default:
      break
  }
}

export default compare
