import PropTypes from "prop-types"
import { isNil } from "ramda"
import { Text, Checkbox, Tooltip } from "@ninjaone/components"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import tokens from "@ninjaone/tokens"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import { localized } from "js/includes/common/utils"

export const getSuppressOptionsLabels = () => ({
  "notifications": localized("Emails/SMS/Push notifications"),
  "alerts": localized("Condition alerts"),
  "patching": localized("Scheduled/ad hoc patch scanning and updates"),
  "avScans": localized("Scheduled/ad hoc anti-virus scans"),
  "tasks": localized("Automations/tasks"),
  "notificationChannels": localized("Notification channels"),
})

const SuppressOptions = ({ options, onChange }) => {
  const suppressOptionsLabels = getSuppressOptionsLabels()
  const optionsKeys = Object.keys(suppressOptionsLabels)
  return (
    optionsKeys?.length && (
      <Flex flexDirection="column">
        <Flex alignItems="center" gap={tokens.spacing[1]} marginBottom={tokens.spacing[1]}>
          <Text type="body" textWrap bold>
            {localized("Features to suppress")}
          </Text>
          <Tooltip label={localized("Suppressing features prevents spamming of alerts on the dashboard")}>
            <RegularInfoCircleIcon size={"sm"} />
          </Tooltip>
        </Flex>
        {optionsKeys.map(key => {
          if (isNil(options[key])) return null
          return (
            <Checkbox
              key={key}
              label={suppressOptionsLabels[key]}
              checked={options[key]}
              onChange={() =>
                onChange({
                  options: {
                    ...options,
                    [key]: !options[key],
                  },
                })
              }
              disabled={key === "notifications"}
            />
          )
        })}
      </Flex>
    )
  )
}

SuppressOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
}

export default SuppressOptions
