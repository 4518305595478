import { useMemo } from "react"
import { findIndex, find, propEq } from "ramda"
import { Modal, Text, Select, Input } from "@ninjaone/components"
import { DefaultButtonGroup } from "@ninjaone/components/src/ButtonGroup"
import { localizationKey, localized, defaultToDash } from "js/includes/common/utils"
import { useForm } from "js/includes/common/hooks"
import { Box } from "js/includes/components/Styled"
import { formatNodeRoleSelectOptions } from "../utils"

const updateDevices = ({
  unmount,
  selected,
  devicesForReview,
  setDevicesForReview,
  deviceName,
  deviceRole,
  validateReviewPage,
}) => {
  const devicesCopy = [...devicesForReview]
  const updatedIndexes = selected.map(({ ip }) => findIndex(propEq("ip", ip))(devicesForReview))

  updatedIndexes.forEach(index => {
    if (deviceName) devicesCopy[index].hostname = deviceName
    devicesCopy[index].role = deviceRole
  })

  setDevicesForReview(devicesCopy)
  validateReviewPage(devicesForReview)
  unmount()
}

const setInitialRole = (selected, deviceRoles) => {
  if (selected.length > 1) return "default"
  const deviceRole = find(propEq("name", selected[0].role))(deviceRoles)
  return deviceRole?.name ? deviceRole.name : "default"
}

export const DeviceDetailsModal = ({
  selected,
  unmount,
  devicesForReview,
  setDevicesForReview,
  deviceRoles,
  validateReviewPage,
}) => {
  const isMulti = selected.length > 1
  const {
    values: { deviceName, deviceRole },
    onChange,
    validation,
    validateForm,
  } = useForm({
    fields: {
      deviceName: selected[0].hostname ?? "",
      deviceRole: setInitialRole(selected, deviceRoles),
    },
    validate: {
      deviceRole: deviceRole => ({
        message: deviceRole === "default" ? localized("Required") : "",
        success: deviceRole !== "default",
      }),
    },
  })
  const nodeRoleSelectOptions = useMemo(() => formatNodeRoleSelectOptions(deviceRoles), [deviceRoles])

  return (
    <Modal
      id="device-details"
      unmount={unmount}
      titleGroup={{
        titleToken: isMulti ? localizationKey("Edit role") : localizationKey("Edit device details"),
      }}
      buttonRenderer={() => (
        <DefaultButtonGroup
          unmount={unmount}
          actionToken={localizationKey("Apply")}
          onConfirm={() => {
            if (validateForm()) {
              updateDevices({
                selected,
                devicesForReview,
                setDevicesForReview,
                deviceName: isMulti ? "" : deviceName,
                deviceRole,
                unmount,
                validateReviewPage,
              })
            }
          }}
        />
      )}
    >
      {!isMulti && (
        <>
          <Box display="grid" gridTemplateColumns="140px 1fr" gap="4px 8px" marginBottom="24px">
            <Text color="colorTextWeakest" size="sm" token={localizationKey("IP address")} />
            <Text color="colorTextStrong" size="sm">
              {selected[0].ip}
            </Text>
            <Text color="colorTextWeakest" size="sm">
              DNS
            </Text>
            <Text color="colorTextStrong" size="sm">
              {defaultToDash(selected[0].dnsName)}
            </Text>
            <Text color="colorTextWeakest" size="sm" token={localizationKey("Vendor")} />
            <Text color="colorTextStrong" size="sm">
              {defaultToDash(selected[0].vendor)}
            </Text>
            <Text color="colorTextWeakest" size="sm" token={localizationKey("Model")} />
            <Text color="colorTextStrong" size="sm">
              {defaultToDash(selected[0].model)}
            </Text>
            <Text color="colorTextWeakest" size="sm" token={localizationKey("Firmware")} />
            <Text color="colorTextStrong" size="sm">
              {defaultToDash(selected[0].firmware)}
            </Text>
          </Box>

          <Input
            labelText={localized("Device name")}
            value={deviceName}
            onChange={e => onChange("deviceName", e.target.value)}
          />
        </>
      )}

      {isMulti && (
        <Text size="sm" color="colorTextStrong">
          {localized("Select a role for {{numDevices}} devices", {
            numDevices: selected.length,
          })}
        </Text>
      )}

      <Box marginTop={isMulti ? "16px" : "12px"}>
        <Select
          matchWidth
          labelId="device-role"
          labelText={`${localized("Role")} *`}
          value={deviceRole}
          onChange={deviceRole => onChange("deviceRole", deviceRole)}
          options={[
            {
              value: "default",
              labelToken: localizationKey("Select a role"),
            },
            ...nodeRoleSelectOptions,
          ]}
          errorMessage={validation.message?.deviceRole}
        />
      </Box>
    </Modal>
  )
}
