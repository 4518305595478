import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"

const StyledWarningBackground = styled.div`
  padding: ${sizer(2, 3)};
  border-radius: ${sizer(1)};
  text-align: ${({ textAlignment }) => textAlignment};
  ${({ theme, variant }) => getStylesByVariant({ theme, variant })};
`

const getStylesByVariant = ({ theme, variant }) => {
  switch (variant) {
    case "warn":
      return `
        border: 1px solid ${theme.color.yellow};
        border-left: ${sizer(2)} solid ${theme.color.yellow};
        background-color: ${theme.color.lightYellow};
      `
    case "info":
      return `
        border: 1px solid ${theme.color.primary["100"]};
        border-left: ${sizer(2)} solid ${theme.color.primary["100"]};
        background-color: ${theme.color.primary["050"]};
        `
    default:
      return `
          `
  }
}
/**
 * Ninja inline warning component
 */
export default function InlineWarning({ children, variant = "warn", textAlignment = "inherit" }) {
  return <StyledWarningBackground {...{ variant, textAlignment }}>{children}</StyledWarningBackground>
}

InlineWarning.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  textAlignment: PropTypes.string,
}
