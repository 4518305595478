import $ from "jquery"
import { localeIsMeridian } from "js/includes/common/utils"
import moment from "moment"
import { time } from "js/includes/common/utils"

const timepicker = {
  routine: function(el, value) {
    const isDisabled = $(el).data("rivets.binders.timepicker.isDisabled")
    const showMinutes = !value.hideMinutes
    const initialized = $(el).data("rivets.binders.timepicker.initialized")

    if (!isDisabled && value instanceof moment) {
      $(el).data("rivets.binders.timepicker.isDisabled", true)

      if (!initialized) {
        $(el).timepicker({
          showMinutes,
          minuteStep: 5,
          showInputs: false,
          disableFocus: true,
          showMeridian: localeIsMeridian(),
        })
        $(el).data("rivets.binders.timepicker.initialized", true)
      } else {
        $(el).timepicker("remove")
        $(el).timepicker({
          showMinutes,
          showInputs: false,
          disableFocus: true,
        })
      }

      $(el).timepicker("setTime", time(value))
      $(el).data("rivets.binders.timepicker.isDisabled", false)
    }
  },

  bind: function(el) {
    this.callback = ({ time }) => {
      const isDisabled = $(el).data("rivets.binders.timepicker.isDisabled")
      if (!isDisabled) {
        $(el).data("rivets.binders.timepicker.isDisabled", true)
        this.model.set("time", moment(time.value, localeIsMeridian() ? "h:mm A" : "H:mm"))
        $(el).data("rivets.binders.timepicker.isDisabled", false)
      }
    }

    $(el).data("rivets.binders.timepicker.initialized", false)
    $(el).on("changeTime.timepicker", this.callback)
    return true
  },

  unbind: function(el) {
    $(el)
      .timepicker()
      .off("changeTime.timepicker", this.callback)
    $(el).off()
    $(el).timepicker("remove")
    return true
  },
}

export default timepicker
