import { map, compose } from "ramda"
import { HoverDropdown, HoverDropdownItem } from "@ninjaone/components"
import { ExternalLinkIcon } from "@ninjaone/icons"
import { isWindowsDevice, localized } from "js/includes/common/utils"
import DeviceTerminalSubMenu, { defaultDeviceOptions } from "js/includes/components/DeviceTerminalSubMenu"

const cmdOptions = [
  {
    token: "deviceDashboard.system32bit",
    terminalType: "SYSTEM",
    shellPath: "DEFAULT",
  },
  {
    token: "deviceDashboard.system64bit",
    terminalType: "SYSTEM",
    shellPath: "DEFAULT64",
  },
  {
    token: "deviceDashboard.loggedInUser32bit",
    terminalType: "USER",
    shellPath: "DEFAULT",
  },
  {
    token: "deviceDashboard.loggedInUser64bit",
    terminalType: "USER",
    shellPath: "DEFAULT64",
  },
]

const powershellOptions = [
  {
    token: "deviceDashboard.system32bit",
    terminalType: "SYSTEM",
    shellPath: "POWERSHELL",
  },
  {
    token: "deviceDashboard.system64bit",
    terminalType: "SYSTEM",
    shellPath: "POWERSHELL64",
  },
  {
    token: "deviceDashboard.loggedInUser32bit",
    terminalType: "USER",
    shellPath: "POWERSHELL",
  },
  {
    token: "deviceDashboard.loggedInUser64bit",
    terminalType: "USER",
    shellPath: "POWERSHELL64",
  },
]

const windowsOptions = [
  {
    token: "general.cmd",
    options: cmdOptions,
  },
  {
    token: "general.powershell",
    options: powershellOptions,
  },
]

const deviceOptions = {
  ...defaultDeviceOptions,
  WINDOWS: windowsOptions,
}

const DeviceTerminalHoverDropdown = ({ device, onShowCommandLine }) => {
  const renderNavLink = (option, onShowFullWindowCommandLine) => {
    const { token } = option

    return (
      <HoverDropdownItem
        {...{
          token,
          key: token,
          onClick: () => onShowCommandLine(option),
          actionIcons: [
            {
              element: (
                <ExternalLinkIcon
                  {...{
                    size: "xs",
                    color: "text",
                    title: localized("general.openInNewTab"),
                  }}
                />
              ),
              onClick: event => {
                onShowFullWindowCommandLine(event, option)
              },
            },
          ],
        }}
      />
    )
  }

  return (
    <DeviceTerminalSubMenu
      {...{
        device,
        deviceOptions,
        MenuComponent: ({ options, filterArchitectures, onShowFullWindowCommandLine }) =>
          options.map(option => {
            const { nodeClass } = device
            const { token, options } = option

            return isWindowsDevice(nodeClass) ? (
              <HoverDropdown
                key={token}
                buttonRenderer={({ isOpen }) => (
                  <HoverDropdownItem {...{ token, isOpen, showArrow: true, isDropdownButton: true }} />
                )}
                dropdownRenderer={() =>
                  compose(
                    map(option => renderNavLink({ ...option }, onShowFullWindowCommandLine)),
                    filterArchitectures,
                  )(options)
                }
                placement="right"
                fullWidth
              />
            ) : (
              renderNavLink(option, onShowFullWindowCommandLine)
            )
          }),
      }}
    />
  )
}

export default DeviceTerminalHoverDropdown
