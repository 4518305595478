import PropTypes from "prop-types"
import Loading from "js/includes/components/Loading"

const AsyncComponentFallback = ({ fallback: Fallback = Loading }) =>
  typeof Fallback === "function" ? <Fallback /> : Fallback

AsyncComponentFallback.propTypes = {
  fallback: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
}

export default AsyncComponentFallback
