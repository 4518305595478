import { Modal, Text, Checkbox } from "@ninjaone/components"
import { logout } from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const LogoutModal = ({ unmount }) => {
  const [fromAllSessions, setFromAllSessions] = useMountedState(false)
  return (
    <Modal
      titleGroup={{
        titleToken: localizationKey("Logout"),
        descriptionToken: localizationKey("Are you sure you want to logout?"),
      }}
      size="sm"
      unmount={unmount}
      buttons={[
        {
          labelToken: localizationKey("Logout"),
          onClick: () => logout({ fromAllSessions }),
          variant: "primary",
        },
      ]}
      cancelable
    >
      <Checkbox
        {...{
          checked: fromAllSessions,
          onChange: ({ isChecked }) => setFromAllSessions(isChecked),
          label: <Text color="colorTextStrong" size="sm" token={localizationKey("Log out of all active sessions")} />,
        }}
      />
    </Modal>
  )
}

export default LogoutModal
