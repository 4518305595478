import rivets from "rivets"
// adapter for functions to allow chained model fetching from return value
const at = "@"
rivets.adapters[at] = {
  observe: function() {},
  unobserve: function() {},
  set: function() {},
  get: function(model, keypath) {
    keypath = keypath.split(at)
    while (keypath.length > 0) {
      var shift = keypath.shift()
      if (model[shift]) model = model[shift]()
      else return
    }
    return model
  },
}

export default at
