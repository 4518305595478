import pathParse from "path-parse"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"
import * as ninjaIcons from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { Text, AlertMessage } from "@ninjaone/components"
import { faClose } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMountedState } from "js/includes/common/hooks"
import { colors } from "js/includes/common/theme"
import { Box, Flex } from "js/includes/components/Styled"
import { Attachment } from "js/includes/components/Attachment"
import { bytes, localizationKey, localized } from "js/includes/common/utils"
import { readLocalFileAsUrl } from "js/includes/ticketing/editor/shared/utils"
import { validateSizeFile } from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/utils"
import CustomInput from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/CustomInput"
import { findAutomationIconName } from "js/includes/components/scripting/AutomationIcon"

const StyledBoxScript = styled(Flex)`
  border: solid thin ${colors.reactSelectGrey};
  border-radius: ${sizer(1)};
  padding: ${sizer(2, 3)};
  flex-wrap: wrap;
  align-items: center;
  height: ${sizer(14)};
  margin-top: ${sizer(2)};
  color: ${({ theme }) => theme.color.black[100]};
  font-weight: 600;
  text-transform: capitalize;
`
const StyledGreyText = styled(Box)`
  color: ${colors.reactSelectDarkGrey};
  align-self: center;
  > div {
    font-weight: 300 !important;
  }
`
const StyledHelperFilesContainer = styled(Box)`
  display: grid;
  grid-gap: ${sizer(2)};
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: ${sizer(3)};
`

function InstallApplicationAdditionalSettingsTab({
  scriptingLanguages,
  setAdditionalSettings,
  additionalSettings,
  os,
}) {
  const [helperErrorMessage, setHelperErrorMessage] = useMountedState("")
  const [iconErrorMessage, setIconErrorMessage] = useMountedState("")
  const theme = useTheme()
  const { helpers, installerIcon } = additionalSettings

  const onDeleteHelper = data => {
    const { name: dataName, extension: dataExtension } = data.metadata
    const newHelpers = helpers.filter(
      ({ metadata: { name, extension } }) => `${name}.${extension}` !== `${dataName}.${dataExtension}`,
    )

    setAdditionalSettings({ helpers: newHelpers })
  }

  const helperFilesContainer = () => {
    return (
      <>
        {helperErrorMessage && (
          <Box marginTop={sizer(1)}>
            <AlertMessage variant="danger">{helperErrorMessage}</AlertMessage>
          </Box>
        )}
        <StyledHelperFilesContainer>
          {helpers.length > 0 ? (
            helpers.map((attachment, index) => <Attachment {...{ key: index, attachment, onDelete: onDeleteHelper }} />)
          ) : (
            <StyledGreyText>
              <Text token={localizationKey("No files")} />
            </StyledGreyText>
          )}
        </StyledHelperFilesContainer>
      </>
    )
  }

  const installerIconContainer = () => {
    return (
      <Box>
        {iconErrorMessage && (
          <Flex marginTop={sizer(1)}>
            <AlertMessage variant="danger">{iconErrorMessage}</AlertMessage>
          </Flex>
        )}
        {installerIcon ? (
          <Flex alignItems="center">
            <img
              src={installerIcon?.file ? window.URL.createObjectURL(installerIcon.file) : installerIcon?.signedUrl}
              alt="installer icon"
              style={{ maxHeight: "32px" }}
            />
            <Box marginLeft={sizer(2)} marginRight={sizer(2)}>
              {`${installerIcon.metadata.name}.${installerIcon.metadata.extension}`}
            </Box>
            <span className="link btn-link p-r-sm" onClick={removeInstallerIcon}>
              {localizationKey("Remove")}
            </span>
          </Flex>
        ) : (
          <Flex>
            <Box marginRight={sizer(2)}>
              <ninjaIcons.InstallApplicationAutomationIcon />
            </Box>
            <StyledGreyText>
              <Text token={localizationKey("Placeholder icon")} />
            </StyledGreyText>
          </Flex>
        )}
      </Box>
    )
  }

  const removeScript = (option, id) => {
    setAdditionalSettings({ [option]: null })
  }

  const setScriptSelected = (option, selected) => {
    setAdditionalSettings({ [option]: selected })
  }

  const preAndPostScriptContainer = option => {
    const script = additionalSettings[option]

    if (!script) {
      return (
        <StyledGreyText>
          <Text token={localized("No {{option}}-script", { option })} />
        </StyledGreyText>
      )
    }

    const iconName = findAutomationIconName(scriptingLanguages, script.language)
    const Icon = iconName ? ninjaIcons[iconName] : null

    return (
      <StyledBoxScript key={script.id}>
        <Box>
          <Icon />
        </Box>
        <Flex flex={1} marginLeft={sizer(2)}>
          {script.name}
        </Flex>
        <Box textAlign="right">
          <Box onClick={() => removeScript(option, script.id)} cursor="pointer">
            <FontAwesomeIcon icon={faClose} />
          </Box>
        </Box>
      </StyledBoxScript>
    )
  }

  const handleHelperFilesChange = async e => {
    e.preventDefault()
    setHelperErrorMessage("")

    const [file] = e.target.files
    const { success, message } = validateSizeFile(file.size, 100)
    if (success && file) {
      const uri = await readLocalFileAsUrl(file)
      const { name, ext } = pathParse(file.name)
      const attachment = {
        file,
        tempUri: uri,
        uploadStatus: "SUCCESS",
        metadata: {
          name,
          mimeType: file.type,
          size: bytes(file.size),
          extension: ext.replace(".", "").toLowerCase(),
        },
      }

      setAdditionalSettings({ helpers: [...helpers, attachment] })
    } else {
      setHelperErrorMessage(message)
    }
  }

  const removeInstallerIcon = () => {
    setAdditionalSettings({ installerIcon: null })
  }

  const loadIcon = async file => {
    const uri = await readLocalFileAsUrl(file)
    const { name, ext } = pathParse(file.name)
    const attachment = {
      file,
      tempUri: uri,
      uploadStatus: "SUCCESS",
      metadata: {
        name,
        mimeType: file.type,
        size: bytes(file.size),
        extension: ext.replace(".", "").toLowerCase(),
      },
    }

    setAdditionalSettings({ installerIcon: attachment })
  }

  const handleInstallerIconChange = async e => {
    e.preventDefault()
    setIconErrorMessage("")
    const maxSize = 32
    const [file] = e.target.files

    const reader = new FileReader()
    reader.onload = () => {
      let img = new Image()
      img.onload = () => {
        const { width, height } = img
        if (width > maxSize || height > maxSize) {
          setIconErrorMessage(localized("The size should be max {{maxSize}} x {{maxSize}} pixels", { maxSize }))
        } else {
          loadIcon(file)
        }
      }
      img.src = reader.result
    }
    reader.readAsDataURL(file)
  }

  return (
    <Box overflow="auto" height="680px">
      <CustomInput
        theme={theme}
        containerData={helperFilesContainer}
        actionDescription={localizationKey(
          "Files will be downloaded to the same folder as the installer. (5 files max, up to 100MB each)",
        )}
        actionTitle={localizationKey("Upload File")}
        label={localizationKey("Helper files")}
        handleFileChange={handleHelperFilesChange}
        disableAction={helpers.length >= 5}
      />
      <CustomInput
        theme={theme}
        containerData={installerIconContainer}
        actionDescription={localizationKey("Recommended size: 32 x 32 pixels")}
        actionTitle={localizationKey("Customize Icon")}
        label={localizationKey("Installer icon")}
        handleFileChange={handleInstallerIconChange}
        disableAction={false}
      />
      <CustomInput
        theme={theme}
        containerData={() => preAndPostScriptContainer("pre")}
        actionTitle={localizationKey("Select automation")}
        label={localizationKey("Pre-script")}
        prePostScriptTitleModal={localizationKey("Select pre-script automation")}
        setScriptSelected={selected => setScriptSelected("pre", selected)}
        os={os}
      />
      <CustomInput
        theme={theme}
        containerData={() => preAndPostScriptContainer("post")}
        actionTitle={localizationKey("Select automation")}
        label={localizationKey("Post-script")}
        prePostScriptTitleModal={localizationKey("Select post-script automation")}
        setScriptSelected={selected => setScriptSelected("post", selected)}
        os={os}
      />
    </Box>
  )
}

export default connect(state => ({
  scriptingLanguages: state.scripting.languages,
}))(InstallApplicationAdditionalSettingsTab)
