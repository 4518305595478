import { Dispatch, SetStateAction, useCallback, useState } from "react"

import { useMounted } from "./useMounted"

export function useMountedState<T>(initialState: T | (() => T)) {
  const [value, _setValue] = useState<T>(initialState)
  const mounted = useMounted()

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (newValue: SetStateAction<T>) => mounted.current && _setValue(newValue),
    [mounted],
  )

  return [value, setValue]
}
