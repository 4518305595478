import { always, compose, anyPass, propEq } from "ramda"
import { getFirstWithValue } from "./function"

const getIfUft16LittleEndian = function(content) {
  return content.charCodeAt(0) === 255 && content.charCodeAt(1) === 254 && "utf-16le"
}

const getIfUtf16BigEndian = function(content) {
  return content.charCodeAt(0) === 254 && content.charCodeAt(1) === 255 && "utf-16be"
}

const getIfUtf8WithBom = function(content) {
  return content.charCodeAt(0) === 239 && content.charCodeAt(1) === 187 && content.charCodeAt(2) === 191 && "utf-8"
}

const getCharsetForStringContent = getFirstWithValue([
  getIfUft16LittleEndian,
  getIfUtf16BigEndian,
  getIfUtf8WithBom,
  always("utf-16"),
])

const getCharsetForFileStringContent = getFirstWithValue([
  getIfUft16LittleEndian,
  getIfUtf16BigEndian,
  getIfUtf8WithBom,
  always("utf-8"),
])

export function decodeForCharset(stringContent) {
  const charset = getCharsetForStringContent(stringContent)
  return compose(
    bytes => new TextDecoder(charset).decode(bytes),
    content => Uint16Array.from(content, c => c.charCodeAt(0)),
  )(stringContent)
}

export function getCharsetForImportFileStringContent(stringContent) {
  const charset = getCharsetForFileStringContent(stringContent)
  return compose(
    bytes => new TextDecoder(charset).decode(bytes),
    content => Uint8Array.from(content, c => c.charCodeAt(0)),
  )(stringContent)
}

export const isPressedKey = (key, code, whichValue) =>
  anyPass([propEq("key", key), propEq("code", code), propEq("which", whichValue)])

export const isEnterKey = isPressedKey("Enter", "Enter", 13)

export const isDownKey = isPressedKey("ArrowDown", "ArrowDown", 40)

export const isUpKey = isPressedKey("ArrowUp", "ArrowUp", 38)

export const isLeftKey = isPressedKey("ArrowLeft", "ArrowLeft", 37)

export const isRightKey = isPressedKey("ArrowRight", "ArrowRight", 39)

export const isBackspaceKey = isPressedKey("Backspace", "Backspace", 8)

export const isSpaceKey = isPressedKey(" ", "Space", 32)

export const isEscapeKey = isPressedKey("Escape", "Escape", 27)

export const isTabKey = isPressedKey("Tab", "Tab", 9)

export const isDeleteKey = isPressedKey("Delete", "Delete", 46)

export const isHomeKey = isPressedKey("Home", "Home", 36)

export const isEndKey = isPressedKey("End", "End", 35)

export const isPageUpKey = isPressedKey("PageUp", "PageUp", 33)

export const isPageDownKey = isPressedKey("PageDown", "PageDown", 34)

export const isEKey = isPressedKey("e", "KeyE", 69)

export const isMinusKey = isPressedKey("-", "Minus", 189)

export const isForwardSlashKey = isPressedKey("/", "Slash", 191)
