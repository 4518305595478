import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { HoverDropdownItem } from "@ninjaone/components"
import ConfigureMaintenanceButton from "./ConfigureMaintenanceButton"
import DisableMaintenanceButton from "./DisableMaintenanceButton"
import { requestRunSearch } from "js/state/actions/deviceSearch/search"
import { getAllCriteriaRequiredForRunningASearch } from "js/state/selectors/deviceSearch/criteria"
import { refreshCustomerOverview } from "js/includes/common/services/deviceHealth"
import { showMaintenanceModeDisableButton } from "./methods"

class MaintenanceMenuItem extends PureComponent {
  getNodeAttributes = () => {
    return window.controller.attributes.activeTab?.attributes.node?.attributes
  }

  fetchDevicesInMaintenance = async () => {
    const { activeSearch, requestRunSearch, fetchDeviceModel, isInDeviceSearchSelectAllMode } = this.props
    const nodeAttributes = this.getNodeAttributes()
    if (nodeAttributes && fetchDeviceModel && !isInDeviceSearchSelectAllMode) {
      fetchDeviceModel(nodeAttributes.id)
    } else {
      const criteria = getAllCriteriaRequiredForRunningASearch(activeSearch)
      await requestRunSearch(criteria, activeSearch)
    }
    refreshCustomerOverview()
  }

  getNodesData = () => {
    const { filteredSelectedDevices } = this.props
    const [node] = filteredSelectedDevices
    // On device dashboard page, maintenanceMode is set after filteredSelectedDevices gets defined.
    // We need to update the filteredSelectedDevice's node to get the recent maintenanceMode info.
    const nodeAttributes = this.getNodeAttributes()
    const isDeviceDashboardMaintenance = nodeAttributes && nodeAttributes.id === node.id
    if (isDeviceDashboardMaintenance) {
      node.maintenanceMode = nodeAttributes.maintenanceMode
    }
    const deviceName = filteredSelectedDevices.length === 1 && (node.displayName || node.name)
    return { nodes: filteredSelectedDevices, deviceName }
  }

  render() {
    const {
      props: {
        selectedFilteredDevicesIds,
        usesButtonComponent,
        isInDeviceSearchSelectAllMode,
        fromDeviceSearch = false,
        showConfigure,
        showDisable,
      },
      fetchDevicesInMaintenance,
      getNodesData,
    } = this
    const { nodes, deviceName } = getNodesData()

    const menus = {
      configure: fromDeviceSearch ? showConfigure : true,
      disable: fromDeviceSearch ? showDisable : showMaintenanceModeDisableButton(nodes),
    }

    return (
      <>
        {menus.configure && (
          <li>
            <ConfigureMaintenanceButton
              {...{
                nodes,
                fetchDevicesInMaintenance,
                ...(usesButtonComponent && {
                  ButtonComponent: ({ onClick, token }) => <HoverDropdownItem token={token} onClick={onClick} />,
                }),
                isInDeviceSearchSelectAllMode,
                fromDeviceSearch,
              }}
            />
          </li>
        )}
        {menus.disable && (
          <li>
            <DisableMaintenanceButton
              {...{
                nodesIds: selectedFilteredDevicesIds,
                deviceName,
                fetchDevicesInMaintenance,
                ...(usesButtonComponent && {
                  ButtonComponent: ({ onClick, token }) => <HoverDropdownItem token={token} onClick={onClick} />,
                }),
                fromDeviceSearch,
              }}
            />
          </li>
        )}
      </>
    )
  }
}

export default connect(
  state => ({
    activeSearch: state.deviceSearch,
  }),
  {
    requestRunSearch,
  },
)(MaintenanceMenuItem)
