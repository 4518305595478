import styled from "@emotion/styled"
import { Button, IconButton, Modal, Text, Tooltip } from "@ninjaone/components"
import { CopyIcon, ArrowsMaximizeIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import showModal from "js/includes/common/services/showModal"
import { clipboardWriteText, localized } from "js/includes/common/utils"
import { Box, Flex, StyledImage as _StyledImage } from "js/includes/components/Styled"

const borderStyles = {
  borderWidth: "1px",
  borderRadius: "4px",
  borderColor: "lightGray",
  borderStyle: "solid",
}

const StyledImage = styled(_StyledImage)`
  clip-path: inset(${({ clipPathInset }) => clipPathInset});
  transform: scale(${({ scale }) => scale});
`

export function QRCodeImage({ qrCode, codeValue, isIos, jsonValue }) {
  const enlargeImage = () => {
    showModal(<ExpandedQRCodeModal {...{ qrCode, codeValue, jsonValue, isIos }} />)
  }

  return (
    <Box {...borderStyles} height={isIos ? "272px" : "356px"}>
      <StyledImage
        src={qrCode}
        onClick={enlargeImage}
        cursor="pointer"
        clipPathInset={sizer(7)}
        scale={1.19}
        padding={sizer(2, 2, 0, 2)}
        width={isIos ? "232px" : "320px"}
      />
      <Flex justifyContent="space-between" padding={sizer(0, 2, 1, 2)}>
        <Flex justifyContent="flex-start" alignItems="center">
          {isIos ? (
            <CopyCodeIosButton {...{ codeValue, size: "sm" }} />
          ) : (
            <CopyCodeButton {...{ codeValue, jsonValue, size: "sm" }} />
          )}
        </Flex>
        <IconButton handleClick={enlargeImage}>
          <ArrowsMaximizeIcon />
        </IconButton>
      </Flex>
    </Box>
  )
}

export function ExpandedQRCodeModal({ qrCode, codeValue, unmount, jsonValue, isIos }) {
  return (
    <Modal size="md" {...{ unmount }}>
      <Flex flexDirection="column" alignItems="center" marginTop={sizer(9)}>
        <Box {...borderStyles}>
          <StyledImage clipPathInset={sizer(7)} scale={1.12} src={qrCode} />
        </Box>
        <Box marginTop={sizer(3)}>
          <Flex justifyContent="flex-start" alignItems="center">
            {isIos ? <CopyCodeIosButton {...{ codeValue }} /> : <CopyCodeButton {...{ codeValue, jsonValue }} />}
          </Flex>
        </Box>
      </Flex>
    </Modal>
  )
}

function CopyCodeButton({ codeValue, size, jsonValue }) {
  return (
    <>
      <span data-clipboard-text={jsonValue} onClick={() => clipboardWriteText(jsonValue)}>
        <Tooltip label={localized("Copy full DPC payload in JSON format")}>
          <Button
            iconLabel={localized("Copy")}
            variant="tertiary"
            Icon={CopyIcon}
            size="sm"
            disabled={jsonValue === ""}
          >
            <CopyIcon {...{ size }} />
          </Button>
        </Tooltip>
      </span>
      <Text {...{ size, color: "colorTextWeakest" }}>{codeValue}</Text>
    </>
  )
}

function CopyCodeIosButton({ codeValue, size }) {
  return (
    <Flex data-clipboard-text={codeValue} onClick={() => clipboardWriteText(codeValue)} justifyContent="center">
      <Button variant="tertiary" size="sm">
        <CopyIcon {...{ size }} />
        <Box marginLeft={sizer(1)}>
          <Text {...{ size }}>{codeValue}</Text>
        </Box>
      </Button>
    </Flex>
  )
}
