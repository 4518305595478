import { fetchJson } from "js/includes/common/utils"

export const fetchServiceNowConfigurationItemClassMappings = () =>
  fetchJson("/service-now/configuration-item-class-mapping")

export const fetchServiceNowConfigurationItemClassMapping = id =>
  fetchJson(`/service-now/configuration-item-class-mapping/${id}`)

export const deleteServiceNowConfigurationItemClassMapping = id =>
  fetchJson(`/service-now/configuration-item-class-mapping/${id}`, { options: { method: "DELETE" } })

export const updateServiceNowConfigurationItemClassMapping = (id, data) =>
  fetchJson(`/service-now/configuration-item-class-mapping/${id}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(data),
    },
  })

export const createServiceNowConfigurationItemClassMapping = data =>
  fetchJson(`/service-now/configuration-item-class-mapping`, {
    options: {
      method: "POST",
      body: JSON.stringify(data),
    },
  })

export const fetchServiceNowClientMappings = () => fetchJson("/service-now/client-mapping")

export const deleteServiceNowClientMapping = id =>
  fetchJson(`/service-now/client-mapping/${id}`, { options: { method: "DELETE" } })

export const createServiceNowClientMapping = data =>
  fetchJson(`/service-now/client-mapping`, {
    options: {
      method: "POST",
      body: JSON.stringify(data),
    },
  })

export const updateServiceNowClientMapping = (id, data) =>
  fetchJson(`/service-now/client-mapping/${id}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(data),
    },
  })

export const syncServiceNowClientMapping = ids =>
  fetchJson("/service-now/client-mapping/sync", {
    options: {
      method: "POST",
      body: JSON.stringify(ids),
    },
  })

export const fetchServiceNowConfigurationItemClasses = () => fetchJson("/service-now/configuration-item-classes")

export const testIncidentTemplate = ({ incidentTemplateId, organizationId }) =>
  fetchJson(
    `/service-now/mapped-templates/${encodeURIComponent(incidentTemplateId)}/client/${encodeURIComponent(
      organizationId,
    )}/test`,
  )
