import React, { PureComponent } from "react"
import Select from "react-select"
import { has, isEmpty, map } from "ramda"
import { sizer } from "@ninjaone/utils"
import { getLocationsByOrganizationId } from "js/state/actions/deviceSearch/locations"
import { sortListByLabel, sortListByName } from "js/state/helpers/listSort"
import Loading from "js/includes/components/Loading"
import { Box } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"
import { StyledLabel } from "js/includes/components/DeviceModal/style"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const customStyles = {
  option: styles => ({
    ...styles,
    textAlign: "left",
  }),
}

const transformToSelectableObject = location => {
  if (location) {
    return has("value", location) && has("label", location) ? location : { value: location.id, label: location.name }
  }
  return null
}

export default class LocationPicker extends PureComponent {
  state = {
    locations: [],
    loading: false,
  }

  componentDidMount() {
    this._isMounted = true
  }

  async componentDidUpdate(prevProps) {
    const { organization, changeLocation } = this.props
    if (
      (prevProps.organization?.id !== organization?.id || (!organization?.locations && !this.state.locations.length)) &&
      organization?.id &&
      !this.state.loading
    ) {
      try {
        this.setState({ loading: true })
        const locationsResponse = await getLocationsByOrganizationId(organization.id)
        const locations = sortListByLabel(map(transformToSelectableObject)(locationsResponse))
        this._isMounted && this.setState({ locations })
        changeLocation(locations[0])
      } catch (error) {
      } finally {
        this._isMounted && this.setState({ loading: false })
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onSelect = location => this.props.changeLocation(location)

  render() {
    const {
      state: { locations, loading },
      props: {
        organization,
        location,
        isMulti,
        disabled,
        labelAbove,
        required,
        hasMarginTop = true,
        customDropdownOptions,
      },
      onSelect,
    } = this

    return labelAbove ? (
      <Box marginTop={hasMarginTop && sizer(3)} data-testid="location-box">
        <StyledLabel htmlFor="location-selector">
          {localized("Location")}
          {required && " *"}
        </StyledLabel>
        <SearchableDropDown
          {...{
            id: "location-selector",
            disabled: disabled || loading,
            onSelect,
            options: isEmpty(locations)
              ? organization?.locations
                ? map(transformToSelectableObject)(sortListByName(organization.locations))
                : []
              : locations,

            value: transformToSelectableObject(location),
            width: "100%",
            rowHeight: 40,
            keepInView: false,
            searchPlaceholderToken: "addDevices.selectLocation",
            isMulti,
            ...customDropdownOptions,
          }}
        />
      </Box>
    ) : (
      <div className="row location-row m-t-md">
        {loading ? (
          <Loading />
        ) : (
          <>
            <label className="col-xs-3">
              {localized("Location")}
              {required && " *"}
            </label>
            <div className={`col-xs-9 ${disabled ? "not-allowed" : ""}`} data-testid="location-select">
              <Select
                isDisabled={disabled}
                isMulti={isMulti}
                closeMenuOnSelect={!isMulti}
                onChange={onSelect}
                options={
                  isEmpty(locations)
                    ? organization?.locations
                      ? map(transformToSelectableObject)(sortListByName(organization.locations))
                      : []
                    : locations
                }
                value={transformToSelectableObject(location)}
                styles={customStyles}
              />
            </div>
          </>
        )}
      </div>
    )
  }
}
