import { Select, Text } from "@ninjaone/components"
import { localizationKey, localized } from "js/includes/common/utils"
import {
  getCategoriesOptions,
  removeUncategorizedValue,
  uncategorizedIfNoOptionsSelected,
} from "js/includes/configuration/scripting/scriptingUtils"

const idToCategoryText = scriptCategories => categoryId =>
  scriptCategories.find(({ value }) => value === categoryId)?.labelText || ""

const getLabelText = (categories, scriptCategories, labelAsCount) => {
  if (!categories?.length) return localizationKey("Select Categories")
  return labelAsCount
    ? localized("{{count}} selected", { count: categories.length })
    : categories.map(idToCategoryText(scriptCategories)).join(", ")
}

const CategoriesLabel = ({ categories, scriptCategories, labelColor, labelAsCount }) => {
  return (
    <Text size="sm" color={!categories?.length ? labelColor : ""}>
      {getLabelText(categories, scriptCategories, labelAsCount)}
    </Text>
  )
}

const CategoriesSelect = ({
  categories,
  scriptCategories,
  onChange,
  errorMessage,
  labelColor = "gray",
  labelAsCount = true,
  disabled,
  ...props
}) => {
  const options = getCategoriesOptions(scriptCategories)
  return (
    <Select
      {...props}
      disabled={disabled || !options.length}
      options={options}
      onChange={selected => {
        onChange({ categories: uncategorizedIfNoOptionsSelected({ selected, categories }) })
      }}
      value={categories}
      labelRenderer={() => (
        <CategoriesLabel
          {...{ categories: removeUncategorizedValue(categories), scriptCategories, labelAsCount, labelColor }}
        />
      )}
      errorMessage={errorMessage}
      triggerAriaLabel={localized("Categories")}
    />
  )
}

export default CategoriesSelect
