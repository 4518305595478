import PropTypes from "prop-types"
import { AlertMessage } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { localized } from "js/includes/common/utils"

const MaintenanceFooter = ({ hasDeviceTypeMessage = false, hasError }) => {
  return (
    <>
      {hasDeviceTypeMessage ? (
        <Flex marginTop={tokens.spacing[2]}>
          <AlertMessage variant="info">
            {localized("Some settings might not be applicable based upon the device types chosen.")}
          </AlertMessage>
        </Flex>
      ) : null}
      {hasError ? (
        <Flex marginTop={tokens.spacing[2]}>
          <AlertMessage variant="danger">{localized("Something went wrong on our end. Please try again")}</AlertMessage>
        </Flex>
      ) : null}
    </>
  )
}

MaintenanceFooter.propTypes = {
  allNodesSameType: PropTypes.bool,
}

export default MaintenanceFooter
