import React, { useEffect } from "react"
import { localized } from "js/includes/common/utils"
import { FormGroup, FormControl } from "react-bootstrap"
import { useForm } from "js/includes/common/hooks"

export default function Disclaimer({ setValidation, disclaimerToken }) {
  const { values, validation, onChange } = useForm({
    validateOnChange: true,
    fields: {
      email: "",
    },
    validate: {
      email: email => {
        const success =
          window.application
            .get("user")
            .get("email")
            .toUpperCase() === email.toUpperCase()
        const message = !success ? localized("Please enter a valid email address") : ""

        return { success, message }
      },
    },
  })

  useEffect(() => {
    setValidation(validation.success.email)
  }, [validation.success.email, setValidation])

  return (
    <div>
      {disclaimerToken && <div className="m-t-md">{localized(disclaimerToken)}</div>}

      <FormGroup
        className="m-b-lg m-t-md"
        controlId="disclamer-message-dialog"
        validationState={validation.success.email === false ? "error" : null}
      >
        <div className="m-r-md">{localized("Enter your email to confirm:")}</div>

        <div className="m-r-xs">
          <FormControl
            type="text"
            className={validation.success.email === false ? "text-danger" : "text-normal"}
            placeholder={localized("Email")}
            value={values.email}
            onChange={e => onChange("email", e.target.value)}
          />
          <em className="invalid position-absolute">{validation.message.email}</em>
        </div>
      </FormGroup>
    </div>
  )
}
