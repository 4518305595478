import "datatables"
import "datatables-bootstrap"
import "@ninjamsp/ninja-datatables"
import $ from "jquery"
import _ from "underscore"
import { v4 as uuidv4 } from "uuid"
import { setupDataTableClickEvents, destroyDataTableClickEvents } from "../../../includes/common/_data_table"
import { getDataTableTokens } from "js/includes/common/utils"
import fastDeepEqual from "fast-deep-equal"
// Many datatables are bound to a collection thus the routine gets called for each model in the collection
// Thus, this hack with debounce which prevents multiple routines from firing all at once

const datatable = {
  // Very important to have a higher priority than rv-each
  // This allows destroying the dataTable upon a routing before rv-each get a chance to re-draw the rows
  priority: 10000,
  defaultRoutine: function(el, value) {
    const $el = $(el)
    if (value) {
      // Setup click events before paging is setup
      setupDataTableClickEvents($el)

      if (!$el.data("rivets.binders.datatable.table")) {
        const sortColumn = $el.data("sortcolumn") || 0
        const sortOrder = $el.data("sortorder") || "asc"
        const sortEnable = $el.data("sortenable") || true
        let dataTable = $el.DataTable({
          bAutoWidth: false,
          order: [[sortColumn, sortOrder]],
          bSort: sortEnable,
          language: getDataTableTokens(),
        })

        $el.data("rivets.binders.datatable.table", dataTable)
      } else {
        // We will never encounter this use case, leaving here for reference as destroying dataTables is very inefficient
        let dataTable = $el.data("rivets.binders.datatable.table")
        dataTable.clear()
        // In case of a re-draw, rv-each re-renders the rows and thus we need to add them to the dataTable for a dataTable re-draw
        $el.find("tbody tr").each(function(index, tr) {
          dataTable.row.add(tr)
        })
        dataTable.draw()
      }

      // Deep copy the values for a future comparison
      $el.data("rivets.binders.datatable.value", _.toArray(value))
    }
  },

  debouncedRoutineMap: {},

  routine: function(el, value) {
    const $el = $(el)
    if (!$el.data("bound")) {
      let datatableUid = $el.data("rivets.binders.datatable.uid")
      if (!datatableUid) {
        datatableUid = uuidv4()
        $el.data("rivets.binders.datatable.uid", datatableUid)
        datatable.debouncedRoutineMap[datatableUid] = _.debounce(function(el, value) {
          datatable.defaultRoutine(el, value)
        }, 100)
      }
      $el.data("bound", true)
    }

    const dataTable = $el.data("rivets.binders.datatable.table")
    let datatableUid = $el.data("rivets.binders.datatable.uid")
    const dataTableOldValue = $el.data("rivets.binders.datatable.value")

    const debouncedRoutine = datatable.debouncedRoutineMap[datatableUid]

    if (!dataTable) {
      debouncedRoutine(el, value)
    } else if (!fastDeepEqual(value, dataTableOldValue)) {
      dataTable.destroy()
      $el.removeData("rivets.binders.datatable.table")
      debouncedRoutine(el, value)
    }
  },

  unbind: function(el) {
    const $el = $(el)
    const dataTable = $el.data("rivets.binders.datatable.table")

    if (dataTable) {
      dataTable.destroy()
    }

    destroyDataTableClickEvents($el)

    const datatableUid = $el.data("rivets.binders.datatable.uid")
    delete datatable.debouncedRoutineMap[datatableUid]

    $el.removeData()
    $el.off()
  },
}

export default datatable
