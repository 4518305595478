import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { nanoid } from "nanoid"
import { cond, identity, T } from "ramda"
import styled from "@emotion/styled"
import { StyledHr, Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import {
  isDownKey,
  isEnterKey,
  isEscapeKey,
  isLeftKey,
  isRightKey,
  isSpaceKey,
  isTabKey,
  isUpKey,
} from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import OutsideClickAlerter from "../OutsideClickAlerter"

const StyledDropdownContainer = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  outline: none;
  position: absolute;
  top: 38px;
  right: 0;
  padding: ${sizer(1)};
  margin-top: 2px;
  min-width: 320px;
  border-radius: ${sizer(1)};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  z-index: 5000;
  padding-bottom: ${sizer(2)};
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  box-shadow: ${({ theme }) => theme.elevationWeak};
  user-select: none;
`
const StyledMenuItem = styled.div`
  color: ${({ theme }) => theme.colorTextStrong};
  border-radius: ${sizer(1)};
  ${({ theme, isSelected }) =>
    isSelected &&
    `
      background-color: ${theme.colorForegroundHover};
    `}
`

const CustomDropdown = ({ DropdownButton, options, show, closeDropdown, reset, category }) => {
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [previousIndex, setPreviousIndex] = useState(-1)

  const dropdownRef = useRef(null)
  const buttonRef = useRef(null)

  const firstSelectableOptionIndex = useMemo(() => options.findIndex(option => !option.TitleComponent), [options])

  const firstSelectableLabelComponentIndex = useMemo(() => options.findIndex(option => option.LabelComponent), [
    options,
  ])

  const selectedOption = options[currentIndex]

  const handleClose = useCallback(() => {
    setCurrentIndex(-1)
    closeDropdown()
  }, [closeDropdown])

  const handleDown = useCallback(() => {
    if (currentIndex < 0) {
      setCurrentIndex(firstSelectableOptionIndex)
    } else if (currentIndex < options.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }, [firstSelectableOptionIndex, options.length, currentIndex])

  const handleUp = useCallback(() => {
    if (currentIndex > firstSelectableOptionIndex) {
      setCurrentIndex(currentIndex - 1)
    }
  }, [firstSelectableOptionIndex, currentIndex])

  const handleRight = useCallback(() => {
    if (selectedOption?.preventClose) {
      setPreviousIndex(currentIndex)
      selectedOption.action()
      setCurrentIndex(firstSelectableLabelComponentIndex)
    }
  }, [selectedOption, currentIndex, firstSelectableLabelComponentIndex])
  const handleLeft = useCallback(() => {
    if (category) {
      reset()
      setCurrentIndex(previousIndex)
    }
  }, [category, previousIndex, reset])

  const handleEnter = useCallback(() => {
    if (selectedOption) {
      selectedOption.action()

      setCurrentIndex(category ? previousIndex : -1)
      selectedOption.preventClose ? setPreviousIndex(currentIndex) : handleClose()
    }
  }, [handleClose, previousIndex, currentIndex, selectedOption, category])

  const handleKeyPress = useCallback(
    event => {
      event.stopPropagation()
      if (!show) return

      cond([
        [
          isTabKey,
          e => {
            e.preventDefault()
          },
        ],
        [isEscapeKey, handleClose],
        [isEnterKey, handleEnter],
        [isDownKey, handleDown],
        [isUpKey, handleUp],
        [isRightKey, handleRight],
        [isLeftKey, handleLeft],
        [isSpaceKey, handleEnter],
        [T, identity],
      ])(event)
    },
    [handleClose, handleDown, handleEnter, handleLeft, handleRight, handleUp, show],
  )

  useEffect(() => {
    if (show) {
      dropdownRef.current.focus()
      const dropdown = dropdownRef.current
      dropdown.addEventListener("keydown", handleKeyPress)
      return () => dropdown.removeEventListener("keydown", handleKeyPress)
    }
  }, [handleKeyPress, show])

  const handleButtonPress = useCallback(
    e => {
      if (isEnterKey(e) || isSpaceKey(e)) {
        setCurrentIndex(firstSelectableOptionIndex)
      }
    },
    [firstSelectableOptionIndex],
  )

  useEffect(() => {
    if (!show) {
      const button = buttonRef.current
      button.addEventListener("keydown", handleButtonPress)
      return () => button.removeEventListener("keydown", handleButtonPress)
    }
  }, [handleButtonPress, show])

  return (
    <OutsideClickAlerter {...{ handleClickOutside: handleClose }}>
      <Box position="relative">
        <div {...{ "aria-expanded": show, "aria-haspopup": true, "aria-label": "menu-button--menu", ref: buttonRef }}>
          {DropdownButton}
        </div>
        <StyledDropdownContainer
          {...{ show, role: "menu", "aria-labelledby": "menu-button--menu", tabIndex: "-1", ref: dropdownRef }}
        >
          {options.map(
            (
              {
                action = () => {},
                labelToken,
                LabelComponent,
                TitleComponent,
                ButtonComponent,
                splitAfter,
                preventClose = false,
                ...option
              },
              index,
            ) => {
              return (
                <Fragment key={labelToken ? labelToken : nanoid(10)}>
                  {TitleComponent ? (
                    <TitleComponent />
                  ) : ButtonComponent ? (
                    <Flex alignItems="center" margin={sizer(3, 2, 2)}>
                      <StyledMenuItem
                        {...{
                          onClick: () => {
                            action(option)
                          },
                          isSelected: currentIndex === index,
                          onMouseMove: () => setCurrentIndex(index),
                          onMouseLeave: () => setCurrentIndex(-1),
                          role: "menuitem",
                        }}
                      >
                        <ButtonComponent />
                      </StyledMenuItem>
                      <Box marginLeft={sizer(2)}>
                        <Text token={labelToken} size="sm" color="colorTextStrong" bold />
                      </Box>
                    </Flex>
                  ) : (
                    LabelComponent && (
                      <StyledMenuItem
                        {...{
                          onClick: () => {
                            action(option)
                            setCurrentIndex(-1)
                            preventClose ? setPreviousIndex(index) : handleClose()
                          },
                          isSelected: currentIndex === index,
                          onMouseMove: () => setCurrentIndex(index),
                          onMouseLeave: () => setCurrentIndex(-1),
                          role: "menuitem",
                        }}
                        data-testid="menu-item"
                      >
                        <LabelComponent />
                      </StyledMenuItem>
                    )
                  )}
                  {splitAfter && <StyledHr color="colorBorderWeakest" />}
                </Fragment>
              )
            },
          )}
        </StyledDropdownContainer>
      </Box>
    </OutsideClickAlerter>
  )
}

export default CustomDropdown

CustomDropdown.propTypes = {
  DropdownButton: PropTypes.element.isRequired,
  options: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  closeDropdown: PropTypes.func.isRequired,
  reset: PropTypes.func,
  category: PropTypes.string,
}
