import styled from "@emotion/styled"
import { Indicator } from "@radix-ui/react-radio-group"

import { variants } from "./constants"
import { getRadioCircleStyles } from "./utils"

const StyledIndicator = styled(Indicator)`
  width: 100%;
  height: 100%;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    display: block;

    width: 10px;
    height: 10px;

    background-color: ${({ theme }) => theme.colorForegroundSelectedStrongest};
    border-radius: 100%;
  }

  // styling the radio/radio card internal circle for the disabled state
  &[data-disabled] {
    ::after {
      background: ${({ theme }) => theme.colorBackgroundCtaDisabled};
    }
  }
`

const StyledIndicatorContainer = styled.div`
  ${({ theme }) => getRadioCircleStyles({ theme })}
`

function RadioIndicator({ variant }) {
  switch (variant) {
    case variants.CARD:
      return (
        <StyledIndicatorContainer>
          <StyledIndicator />
        </StyledIndicatorContainer>
      )

    default:
      return <StyledIndicator />
  }
}

export default RadioIndicator
