import React from "react"
import { Form } from "react-bootstrap"
import { localized, localizationKey, validations } from "js/includes/common/utils"
import { useForm } from "js/includes/common/hooks"
import { StyledSpan } from "js/includes/components/Styled"
import { StyledCol, StyledFormGroup, StyledModal } from "js/includes/components/Styled/Form"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import ADStyledModalContainer from "../components/ADStyledModalContainer"
import StatusInfoTooltip from "../components/StatusInfoTooltip"

export const getApprovalStatusOptions = () => [
  { value: "NEW", label: localized("Discovered") },
  { value: "PENDING", label: localized("Pending") },
  { value: "APPROVED", label: localized("Approved") },
  { value: "REJECTED", label: localized("Rejected") },
]

export const getApprovalStatusScheduledOptions = () => [
  { value: "", label: localized("None") },
  { value: "PENDING_APPROVAL", label: localized("Pending") },
  { value: "AUTO_APPROVE", label: localized("Approved") },
]

const isNewOrRejected = status => ["NEW", "REJECTED"].includes(status?.value) || !status?.value

export default function SetLocationAndStatusModal({
  unmount,
  onSetLocationAndStatus,
  locations,
  defaultLocation,
  defaultStatus,
  statusOptions,
  showStatusInfoTooltip,
}) {
  const { values, validation, validateForm, onChange } = useForm({
    fields: {
      location: defaultLocation,
      status: defaultStatus,
    },
    validate: {
      location: (val, { status }) => (isNewOrRejected(status) ? { success: true } : { success: !!val }),
      status: validations.required,
    },
  })

  return (
    <Form
      horizontal
      onSubmit={e => {
        e.preventDefault()
        const { location, status } = values
        if (validateForm()) {
          onSetLocationAndStatus({
            location: isNewOrRejected(status) ? undefined : location,
            status: status?.value === "NEW" ? undefined : status,
          })
          unmount()
        }
      }}
    >
      <ADStyledModalContainer>
        <StyledModal
          title={localizationKey("Set Status and Location")}
          close={unmount}
          submittable
          overflow
          saveText={localizationKey("Set")}
          noHeader
          overflowY="visible !important"
          backgroundColor="#fff !important"
          borderRadius="5px"
        >
          <StyledSpan
            fontSize="20px"
            fontWeight="600"
            marginBottom={3}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            {localized("Set Status and Location")}
          </StyledSpan>
          <StyledFormGroup
            validationState={validation.success.status === false ? "error" : null}
            margin="0px !important"
            marginBottom="15px !important"
          >
            <StyledCol>
              <StyledSpan fontSize="14px" marginBottom={1}>
                {localized("Status")}
                {showStatusInfoTooltip && <StatusInfoTooltip />}
              </StyledSpan>
            </StyledCol>
            <StyledCol>
              <SearchableDropDown
                {...{
                  width: "100%",
                  minHeight: "38px",
                  borderRadius: "2px",
                  rowHeight: 35,
                  openOnFocus: true,
                  searchPlaceholderToken: localizationKey("Select one"),
                  validationState: validation.success.status === false ? "error" : null,
                  value: values.status,
                  options: statusOptions || getApprovalStatusOptions(),
                  onSelect: selected => onChange({ status: selected }),
                }}
              />
            </StyledCol>
          </StyledFormGroup>
          <StyledFormGroup
            validationState={validation.success.location === false ? "error" : null}
            margin="0px !important"
          >
            <StyledCol>
              <StyledSpan fontSize="14px" marginBottom={1}>
                {localized("Location")}
              </StyledSpan>
            </StyledCol>
            <StyledCol>
              <SearchableDropDown
                {...{
                  width: "100%",
                  minHeight: "38px",
                  borderRadius: "2px",
                  rowHeight: 35,
                  openOnFocus: true,
                  searchPlaceholderToken: localizationKey("Select"),
                  validationState: validation.success.location === false ? "error" : null,
                  value: isNewOrRejected(values.status) ? null : values.location,
                  options: locations,
                  onSelect: selected => onChange({ location: selected }),
                  valueSelectorKey: "id",
                  searchableKey: "name",
                  disabled: isNewOrRejected(values.status),
                }}
              />
            </StyledCol>
          </StyledFormGroup>
        </StyledModal>
      </ADStyledModalContainer>
    </Form>
  )
}
