import { useState } from "react"
import styled from "@emotion/styled"
import { getTextSize, sizer } from "@ninjaone/utils"
import { ExternalLinkIcon, OpenArrowIcon } from "@ninjaone/icons"
import { Text } from "@ninjaone/components"
import { Box, StyledAnchor } from "js/includes/components/Styled"
import { isEnterKey, localized } from "js/includes/common/utils"

const SectionHeader = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${sizer(2, 1)};
  background: none;
  border: none;
  border-bottom: 1px solid ${({ isExpanded, theme }) => (!isExpanded ? theme.color.black["010"] : "transparent")};

  &:focus-visible {
    outline: 2px auto ${({ theme }) => theme.color.primary["075"]};
    @supports (-moz-appearance: none) {
      outline-offset: -2px;
    }
  }
`

const SectionContent = styled.ul`
  display: ${({ isExpanded, theme }) => (isExpanded ? "flex" : "none")};
  border-bottom: 1px solid ${({ theme }) => theme.color.black["010"]};
  flex-direction: column;
  gap: ${sizer(1)};
  padding: ${sizer(0, 1, 3, 1)};
  margin: 0;
  list-style: none;

  a:focus-visible {
    outline: 2px auto ${({ theme }) => theme.color.primary["075"]};
    @supports (-moz-appearance: none) {
      outline-offset: -2px;
    }
  }
`

export default function Accordion({ items, containerWidth = "100%", defaultExpandedMenu = "" }) {
  const [expandedMenus, setExpandedMenus] = useState([defaultExpandedMenu])
  const isExpandedMenu = menuId => expandedMenus.includes(menuId)
  const toggleExpandMenu = menuId =>
    setExpandedMenus(prevState =>
      !prevState.includes(menuId) ? [...prevState, menuId] : prevState.filter(menu => menu !== menuId),
    )

  return (
    <Box width={containerWidth}>
      {items?.map(({ id, titleToken, subMenu, rendererTitle, rendererSubMenu }) => (
        <section key={id} style={{ display: "flex", flexDirection: "column" }}>
          <SectionHeader aria-controls={id} isExpanded={isExpandedMenu(id)} onClick={() => toggleExpandMenu(id)}>
            {rendererTitle?.() ?? <Text token={titleToken} color="inputText" size="sm" bold />}
            <OpenArrowIcon size="sm" color="inputText" direction="up" inverse={!isExpandedMenu(id)} />
          </SectionHeader>
          <SectionContent id={id} isExpanded={isExpandedMenu(id)}>
            {rendererSubMenu ? (
              <li>{rendererSubMenu()}</li>
            ) : (
              subMenu?.map(({ id, labelToken, action, rendererLink }) => (
                <li key={id}>
                  <StyledAnchor
                    fontSize={getTextSize("sm")}
                    tabIndex={0}
                    onClick={e => {
                      e.preventDefault()
                      action()
                    }}
                    onKeyUp={e => {
                      e.preventDefault()
                      if (isEnterKey(e)) {
                        action()
                      }
                    }}
                  >
                    {rendererLink?.() ?? (
                      <>
                        {localized(labelToken)} <ExternalLinkIcon size="xs" />
                      </>
                    )}
                  </StyledAnchor>
                </li>
              ))
            )}
          </SectionContent>
        </section>
      ))}
    </Box>
  )
}
