import { connect } from "react-redux"
import styled from "@emotion/styled"
import { faApple, faLinux, faWindows } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import Tooltip from "js/includes/components/Tooltip"

const WINDOWS_SERVICE_CATEGORY = 3

const StyledSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`
const ScriptTableRow = ({ script, languages, showSimplifiedLayout, selectedContextDeviceType }) => {
  const { name, categoriesIds, parameters } = script
  let scriptTitle = name
  const language = languages.find(l => l.id === script.language)?.value
  let serviceInfoRow = <div>{language}</div>
  if (categoriesIds.includes(WINDOWS_SERVICE_CATEGORY) && parameters && "start" in parameters) {
    const { displayName, serviceName } = parameters
    const policyStatus = (() => {
      if (language.toLowerCase() === "native" && !serviceName) return localized("Start or Stop")
      return parameters.start ? localized("Start") : localized("Stop")
    })()
    scriptTitle = `${policyStatus} - ${scriptTitle}`
    if (displayName) {
      const tooltipInfo = `${displayName} (${serviceName})`
      serviceInfoRow = (
        <StyledSpan>
          {language && `${language} - `}
          <Tooltip output={tooltipInfo}>
            <span>{displayName}</span>
          </Tooltip>
        </StyledSpan>
      )
    }
  }
  const getScriptLogo = ({ deviceType }) => {
    switch (deviceType.toLowerCase()) {
      case "windows":
        return faWindows
      case "mac":
      case "mac server":
        return faApple
      case "linux":
        return faLinux
      default:
        throw new Error(`Invalid device type: "${deviceType}"`)
    }
  }
  return (
    <div className="full-width text-ellipsis">
      <Tooltip output={name}>
        <b className="text-nowrap">
          <FontAwesomeIcon icon={getScriptLogo({ deviceType: selectedContextDeviceType || script.deviceType })} />
          &nbsp;
          {!selectedContextDeviceType && script.operatingSystems.length > 1 && (
            <>
              <FontAwesomeIcon icon={getScriptLogo({ deviceType: script.operatingSystems[1] })} />
              &nbsp;
            </>
          )}
          {scriptTitle}
        </b>
      </Tooltip>
      {!showSimplifiedLayout && <Box width="100%">{serviceInfoRow}</Box>}
    </div>
  )
}
export default connect(state => ({
  languages: state.scripting.languages,
  selectedContextDeviceType: state.scripting.scriptsSelectorList.deviceType,
}))(ScriptTableRow)
