import styled from "@emotion/styled"
import { OfflineSvg } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import { localized, timestamp, livestamp, isCloudMonitor, isMobileDevice } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"

const getDeviceStatusLabel = ({ nodeClass, isUp, details }) => {
  if (isCloudMonitor(nodeClass)) return null

  if (isUp) {
    return localized("Online")
  } else if (details) {
    const { mdmStatus = "", mdmStatusUpdatedTime = null } = details

    if (isMobileDevice(nodeClass) && mdmStatus === "ERASED") {
      return `${localized("Erased")}${mdmStatusUpdatedTime ? ` (${livestamp(mdmStatusUpdatedTime)})` : ""}`
    }

    return details.submitTime ? `${localized("Offline")} (${livestamp(timestamp(details))})` : null
  } else {
    return localized("Offline")
  }
}

const getIsOnline = ({ nodeClass, isUp }) => (!isCloudMonitor(nodeClass) && isUp ? true : false)

export const OnlineDot = styled.div`
  width: ${sizer(2)};
  height: ${sizer(2)};
  background-color: ${({ theme }) => theme.colorAlertSuccess};
  border-radius: 50%;
`

const StyledStatusName = styled.div`
  font-size: ${getTextSize("xs")};
  line-height: 18px;
  color: ${({ theme }) => theme.colorTextWeakest};
  white-space: nowrap;
`

const GlobalSearchDeviceStatus = ({ data }) => {
  const statusLabel = getDeviceStatusLabel(data)
  const isOnline = getIsOnline(data)

  if (!statusLabel) return null

  return (
    <Flex gap={sizer(1)} alignItems="center">
      {isOnline ? <OnlineDot /> : <OfflineSvg width={8} height={8} />}
      <StyledStatusName>{statusLabel}</StyledStatusName>
    </Flex>
  )
}

export default GlobalSearchDeviceStatus
