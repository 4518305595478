/** @jsxImportSource @emotion/react */
import { memo } from "react"
import { css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMobileAlt, faQrcode, faKey } from "@fortawesome/pro-solid-svg-icons"
import { colors } from "js/includes/common/theme"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const selectOptionStyle = css`
  display: flex;
  align-items: center;

  svg {
    width: 30px !important;
  }

  label {
    margin: 0;
    color: ${colors.ninjaDark};
  }

  .mfa-type-description {
    margin-left: 10px;

    small {
      display: block;
      color: ${colors.ninjaMedium};
    }
  }
`

export const mfaTypes = {
  U2F_TOKEN: {
    icon: faKey,
    titleToken: localizationKey("Hardware-Based Security Key"),
    descriptionToken: localizationKey("Verify via Security Key"),
  },
  TOTP: {
    icon: faQrcode,
    titleToken: localizationKey("Authenticator App"),
    descriptionToken: localizationKey("Get time-based code"),
  },
  SMSOTP: {
    icon: faMobileAlt,
    titleToken: localizationKey("SMS"),
    descriptionToken: localizationKey("Get a verification code via SMS"),
  },
}

export default function MfaSwitchOption({ singleValue }) {
  return memo(props => {
    const { innerRef, innerProps, data, getStyles } = props

    const option = mfaTypes[data.value]
    if (!option && data.value !== null) {
      throw new Error(`Invalid mfa type: ${data}`)
    }

    return !option ? (
      localized("Select MFA method...")
    ) : (
      <div ref={innerRef} {...innerProps} style={getStyles(singleValue ? "singleValue" : "option", props)}>
        <div css={selectOptionStyle}>
          <FontAwesomeIcon icon={option.icon} size="lg" />
          <span className="mfa-type-description">
            <label>{localized(option.titleToken)}</label>
            {!singleValue && <small>{localized(option.descriptionToken)}</small>}
          </span>
        </div>
      </div>
    )
  })
}
