import React, { useCallback, useEffect } from "react"
import { connect } from "react-redux"
import { compose, propEq, find, pick, map, filter, any, omit } from "ramda"
import { Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolder } from "@fortawesome/pro-regular-svg-icons"
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons"
import styled from "@emotion/styled"
import Link from "js/includes/components/Link"
import {
  localized,
  localizationKey,
  validations,
  showSuccessMessage,
  reportErrorAndShowMessage,
  showErrorMessage,
  DOJO_ARTICLES_PREFIX,
  runDeviceSearchToolbarAction,
} from "js/includes/common/utils"
import { Box, StyledSpan } from "js/includes/components/Styled"
import { StyledRow, StyledCol, StyledModal, StyledFormGroup, StyledButton } from "js/includes/components/Styled/Form"
import showModal from "js/includes/common/services/showModal"
import { useForm, useMountedState } from "js/includes/common/hooks"
import { saveDiscoveryJob as _saveDiscoveryJob } from "js/state/actions/customerEditor/discoveryJobs/discoveryJobs"
import { getLocations } from "js/state/actions/locations/locations"
import { tabsHandler } from "js/state/actions/nodeApprovals"
import { setDiscoveryJobsList } from "js/state/actions/customerEditor/discoveryJobs/discoveryJobs"
import { colors } from "js/includes/common/theme"
import {
  fetchDeviceById,
  getOuPathTreeData,
  getOrganization,
  validateDCCredentials,
  validateOUPath as _validateOUPath,
  getDiscoveryJobs,
} from "js/includes/common/client"
import { getOrganizationCredentials } from "js/state/actions/credentials"
import Tooltip from "js/includes/components/Tooltip"
import Spinner from "js/includes/components/Spinner"
import SelectCredentialsModal from "./SelectCredentialsModal"
import SaveDiscoveryJobModal from "./SaveDiscoveryJobModal"
import AdhocConfirmationModal from "./AdhocConfirmationModal"
import AdhocDiscovery from "../components/AdhocDiscovery"
import ScheduledDiscovery from "../components/ScheduledDiscovery"
import DomainControllerOUPathSelector from "../components/DomainControllerOUPathSelector"
import ADStyledModalContainer from "../components/ADStyledModalContainer"

const StyledBodyContainer = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`

const pickIdAndName = pick(["id", "name"])

const getDomainControllerLocationOption = (locationId, locations) => {
  return find(propEq("id", locationId))(locations)
}

export const credentialsErrorTokens = {
  ACCESS_DENIED: localizationKey("Insufficient privilege"),
  REQUIRED_PRIVILEGE_NOT_HELD: localizationKey("Insufficient privilege"),
  INCORRECT_USERNAME_OR_PASSWORD: localizationKey("Invalid credentials"),
}

function ActiveDirectoryDiscoveryJobModal({
  clientId,
  unmount,
  locations,
  getLocations,
  domainController: initialDomainController,
  job,
  jobType: initialJobType,
  saveDiscoveryJob,
  refreshNodeApprovalsTabs,
  runMode,
  setDiscoveryJobsList,
  discoveryJobsList,
  fromDeviceSearch = false,
}) {
  const [isLoadingDevices, setIsLoadingDevices] = useMountedState(false)
  const [wizardStep, setWizardStep] = useMountedState(runMode ? 2 : 1)
  const [devices, setDevices] = useMountedState([])
  const [isValidOuPath, setIsValidOuPath] = useMountedState(true)
  const [ouPathErrorMessage, setOuPathErrorMessage] = useMountedState(true)
  const [isTypingOuPath, setIsTypingOuPath] = useMountedState(false)
  const [defaultLocationOption, setDefaultLocationOption] = useMountedState({})
  const [selectedDevices, setSelectedDevices] = useMountedState({ rows: {}, length: 0 })
  const [credentialsList, setCredentialsList] = useMountedState([])
  const [defaultADCredentialId, setDefaultADCredentialId] = useMountedState()
  const [isLoadingCredentials, setIsLoadingCredentials] = useMountedState(true)
  const [isValidatingCredentials, setIsValidatingCredentials] = useMountedState(false)
  const [credentialsValid, setCredentialsValid] = useMountedState()
  const [credentialsValidationMessage, setCredentialsValidationMessage] = useMountedState()
  const [existingJobsConflictMessage, setExistingJobsConflictMessage] = useMountedState()

  const getStepClassname = step => (step === wizardStep ? "" : "hide")

  useEffect(() => {
    if (!!clientId) {
      getLocations(clientId)
    }
  }, [clientId, getLocations])

  const isEditingScheduledJob = job?.jobType === "SCHEDULED"

  const { values, onChange, validation, validateForm } = useForm({
    fields: {
      jobType: initialJobType || (job ? job.jobType : "AD_HOC"),
      domainController: initialDomainController || null,
      ouPath: job ? job.content.ouPath : null,
      recursiveOuPath: job ? job.content.recursiveOuPath : false,
      schedule: job ? job.content.schedule : null,
      windowsServers: isEditingScheduledJob ? job.content.deployment.windowsServers : false,
      windowsServersLocation: isEditingScheduledJob ? job.content.deployment.windowsServersLocation : {},
      windowsServersApprovalMode: isEditingScheduledJob && job.content.deployment.windowsServersApprovalMode,
      windowsWorkstations: isEditingScheduledJob ? job.content.deployment.windowsWorkstations : false,
      windowsWorkstationsLocation: isEditingScheduledJob ? job.content.deployment.windowsWorkstationsLocation : {},
      windowsWorkstationsApprovalMode: isEditingScheduledJob && job.content.deployment.windowsWorkstationsApprovalMode,
      credentialId: job?.credentialId,
    },
    validate: {
      domainController: validations.required,
      schedule: (val, { jobType }) => (jobType === "AD_HOC" ? { success: true } : { success: !!val }),
    },
  })

  const isAdhocJob = values.jobType === "AD_HOC"
  const saveText =
    wizardStep === 1 ? localizationKey("Next") : isAdhocJob ? localizationKey("Apply") : localizationKey("Save")
  const credentialFromList = find(propEq("id", values.credentialId), credentialsList || [])

  useEffect(() => {
    function checkConflictWithExistingJobs() {
      if (!values.ouPath || !values.domainController?.id) {
        return setExistingJobsConflictMessage(null)
      }

      const dcJobs = discoveryJobsList.filter(dj => dj?.content?.domainController?.id === values.domainController?.id)
      if (!dcJobs.length) {
        return setExistingJobsConflictMessage(null)
      }

      const jobsWithSameOuPath = dcJobs.filter(dj => dj.content?.ouPath === values.ouPath)
      if (jobsWithSameOuPath.length) {
        return setExistingJobsConflictMessage({
          message: localized("Cannot create multiple discovery jobs for the same OU Path."),
          tooltipMessage: `${jobsWithSameOuPath[0].name} ${localized("using")} ${values.ouPath}`,
        })
      }

      if (values.jobType === "AD_HOC") {
        return setExistingJobsConflictMessage(null)
      }

      // Do not allow creating a scheduled DJ when:
      // - creating a recursive scheduled job when a child has already a scheduled job
      // - creating a scheduled job when one of his parents has a recursive job
      if (values.recursiveOuPath) {
        const childrenScheduledJobs = dcJobs.filter(
          dj => dj.jobType === "SCHEDULED" && dj.content?.ouPath?.includes(values.ouPath),
        )
        if (childrenScheduledJobs.length) {
          return setExistingJobsConflictMessage({
            message: localized(
              "Cannot create a recursive job for the selected OU Path. There are scheduled discovery jobs assigned to children OU folders.",
            ),
            tooltipMessage: () => (
              <Box textAlign="left">
                <div>{localized("Conflicting discovery jobs")}:</div>
                {childrenScheduledJobs.map(job => (
                  <li key={job.id}>{job.name}</li>
                ))}
              </Box>
            ),
          })
        }
      }
      const parentRecursiveScheduledJobs = dcJobs.filter(
        dj => dj.jobType === "SCHEDULED" && dj.content?.recursiveOuPath && values.ouPath?.includes(dj.content?.ouPath),
      )
      if (parentRecursiveScheduledJobs.length) {
        return setExistingJobsConflictMessage({
          message: localized(
            "Cannot create a scheduled job for the selected OU Path. There is a recursive scheduled job assigned to a parent OU folder.",
          ),
          tooltipMessage: `${parentRecursiveScheduledJobs[0].name} ${localized("using")} ${values.ouPath}`,
        })
      }
      setExistingJobsConflictMessage(null)
    }
    checkConflictWithExistingJobs()
  }, [
    values.domainController,
    values.ouPath,
    values.jobType,
    discoveryJobsList,
    setExistingJobsConflictMessage,
    values.recursiveOuPath,
  ])

  const shouldDisableSaveBtn = () => {
    if (wizardStep === 1) {
      return (
        !values.domainController ||
        !values.ouPath ||
        !isValidOuPath ||
        isLoadingDevices ||
        (isAdhocJob && !devices?.length) ||
        existingJobsConflictMessage?.message
      )
    }
    if (!credentialFromList || !credentialsValid || isValidatingCredentials) return true
    if (isAdhocJob) {
      const anyNewDeviceHasNewStatus = any(device => device.status === "NEW" && device.newStatus)(devices)
      return !anyNewDeviceHasNewStatus && !runMode
    }
    return !values.schedule || (!values.windowsServers && !values.windowsWorkstations)
  }

  const setAndFormatDevices = useCallback(
    (_devices, previousDevices = []) => {
      const updatedDevices = _devices.map(device => {
        const previousDevice = previousDevices.find(pd => pd.objectGuid && pd.objectGuid === device.objectGuid)
        return {
          ...device,
          status: device.status || "NEW",
          disabled: device.status && device.status !== "NEW",
          newStatus: previousDevice?.newStatus,
          newLocation: previousDevice?.newLocation,
        }
      })
      setDevices(updatedDevices)
    },
    [setDevices],
  )

  const validateOuPath = useCallback(
    async (domainControllerId, ouPath) => {
      try {
        await _validateOUPath(domainControllerId, ouPath)
        return true
      } catch (error) {
        if (error.errorMessage === "INVALID_OU_PATH") {
          showErrorMessage(localized("Invalid OU Path"))
          setOuPathErrorMessage(localizationKey("Invalid OU Path"))
        } else {
          reportErrorAndShowMessage(error, localizationKey("Error validating OU Path"))
          setOuPathErrorMessage(localizationKey("Error validating OU Path"))
        }
      }
      return false
    },
    [setOuPathErrorMessage],
  )

  const fetchDevices = useCallback(
    async (domainController, ouPath, previousDevices, _recursiveOuPath) => {
      setIsLoadingDevices(true)
      setAndFormatDevices([])
      try {
        if (isTypingOuPath || !isValidOuPath) {
          const validOu = await validateOuPath(domainController.id, ouPath)
          setIsValidOuPath(validOu)
          if (!validOu) return
        }
        const response = await getOuPathTreeData(domainController.id, ouPath, _recursiveOuPath)
        const { ouComputers = [] } = response
        setAndFormatDevices(ouComputers, previousDevices)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error loading Active Directory tree"))
      } finally {
        setIsLoadingDevices(false)
        setIsTypingOuPath(false)
      }
    },
    [
      setAndFormatDevices,
      setIsLoadingDevices,
      setIsTypingOuPath,
      setIsValidOuPath,
      isTypingOuPath,
      isValidOuPath,
      validateOuPath,
    ],
  )

  useEffect(() => {
    if (job && job.jobType === "AD_HOC") {
      fetchDevices(job.content.domainController, job.content.ouPath, [], job.content.recursiveOuPath)
    }
  }, [fetchDevices, job])

  useEffect(() => {
    async function fetchDefaultDomainControllerLocation() {
      let locationId = values.domainController.locationId

      if (!locationId) {
        try {
          const { node } = await fetchDeviceById(values.domainController.id)
          if (!node) throw new Error(`Domain controller ${values.domainController.id} not found`)
          locationId = node.locationId
        } catch (error) {
          return reportErrorAndShowMessage(error, localizationKey("Domain controller not found"))
        }
      }

      const domainControllerLocationOption = getDomainControllerLocationOption(locationId, locations)

      setDefaultLocationOption(domainControllerLocationOption)
      !job &&
        onChange({
          windowsServersLocation: domainControllerLocationOption,
          windowsWorkstationsLocation: domainControllerLocationOption,
        })
    }
    if (values.domainController && locations) {
      fetchDefaultDomainControllerLocation()
    }
  }, [values.domainController, locations, setDefaultLocationOption, onChange, job])

  useEffect(() => {
    const getCredentials = async () => {
      const credentials = await getOrganizationCredentials(clientId)
      const usernamePasswordCredentials = filter(propEq("credentialType", "BASIC"))(credentials)
      setCredentialsList(usernamePasswordCredentials)
      setIsLoadingCredentials(false)

      const client = await getOrganization(clientId)
      const adDefaultCredential = client?.credentials?.SR_ACTIVE_DIRECTORY_DISCOVERY
      if (adDefaultCredential) {
        setDefaultADCredentialId(adDefaultCredential.credential_id)
      }
    }

    if (clientId) {
      getCredentials()
    }
  }, [setCredentialsList, setIsLoadingCredentials, setDefaultADCredentialId, clientId])

  useEffect(() => {
    async function validateCredentials() {
      try {
        setIsValidatingCredentials(true)
        await validateDCCredentials(values.domainController.id, values.credentialId)
        setCredentialsValid(true)
        setCredentialsValidationMessage(localizationKey("Credentials are valid"))
      } catch (error) {
        setCredentialsValid(false)
        const errorMessage = credentialsErrorTokens[error.errorMessage]
        if (errorMessage) {
          setCredentialsValidationMessage(errorMessage)
          showErrorMessage(localized(errorMessage))
        } else {
          setCredentialsValidationMessage(localizationKey("Credentials could not be validated"))
          reportErrorAndShowMessage(error, localizationKey("Credentials could not be validated"))
        }
      } finally {
        setIsValidatingCredentials(false)
      }
    }
    if (values.credentialId && values.domainController?.id) {
      validateCredentials()
    }
  }, [
    values.credentialId,
    values.domainController,
    setIsValidatingCredentials,
    setCredentialsValid,
    setCredentialsValidationMessage,
  ])

  useEffect(() => {
    async function fetchDiscoveryJobs() {
      const discoveryJobsList = await getDiscoveryJobs(clientId)
      setDiscoveryJobsList(discoveryJobsList)
    }
    if (!!clientId) {
      fetchDiscoveryJobs()
    }
  }, [clientId, setDiscoveryJobsList])

  const getCredentialsIcon = () => {
    if (isValidatingCredentials || !credentialsValidationMessage) return <Spinner />
    return (
      <Tooltip output={localized(credentialsValidationMessage)}>
        <FontAwesomeIcon
          icon={credentialsValid ? faCheckCircle : faTimesCircle}
          color={credentialsValid ? "green" : "red"}
        />
      </Tooltip>
    )
  }

  const getCandidateNodes = () => {
    return compose(
      map(device => ({
        name: device.name,
        objectGuid: device.objectGuid,
        status: device.newStatus.value,
        location: device.newLocation,
      })),
      filter(device => device.newStatus),
    )(devices)
  }

  const getJobInfo = name => {
    return {
      name,
      clientId,
      jobType: values.jobType,
      runOnce: isAdhocJob && !name,
      content: {
        ouPath: values.ouPath,
        domainController: pickIdAndName(values.domainController || {}),
        recursiveOuPath: values.recursiveOuPath,
        ...(isAdhocJob
          ? {}
          : {
              schedule: omit(["end"], values.schedule),
              deployment: {
                windowsServers: values.windowsServers,
                windowsServersLocation: values.windowsServers
                  ? pickIdAndName(values.windowsServersLocation || {})
                  : null,
                windowsServersApprovalMode: values.windowsServers ? values.windowsServersApprovalMode : null,
                windowsWorkstations: values.windowsWorkstations,
                windowsWorkstationsLocation: values.windowsWorkstations
                  ? pickIdAndName(values.windowsWorkstationsLocation || {})
                  : null,
                windowsWorkstationsApprovalMode: values.windowsWorkstations
                  ? values.windowsWorkstationsApprovalMode
                  : null,
              },
            }),
      },
      ...(isAdhocJob ? { candidateNodes: getCandidateNodes() } : {}),
      credentialId: values.credentialId,
    }
  }

  const saveJob = async (name, id) => {
    const jobInfo = getJobInfo(name)
    try {
      if (fromDeviceSearch) {
        await runDeviceSearchToolbarAction({
          action:
            values.jobType === "AD_HOC"
              ? "RUN_AD_DISCOVERY_JOB_ADHOC_DISCOVERY"
              : "RUN_AD_DISCOVERY_JOB_SCHEDULED_DISCOVERY",
          data: { discoveryJob: jobInfo },
        })
      } else {
        await saveDiscoveryJob(jobInfo, id)
      }
      showSuccessMessage(localized("Saved"))
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        throw error
      }
    }
  }

  const getModalDescription = () => {
    if (wizardStep === 1)
      return localizationKey(
        "Select the Domain Controller and OU Path where the devices where you want to deploy the Ninja agent are located",
      )
    return isAdhocJob
      ? localizationKey("Set Status and Location to New Devices")
      : localizationKey("Set Status, Location, and Schedule")
  }

  return (
    <Form
      horizontal
      onSubmit={async e => {
        e.preventDefault()
        if (wizardStep === 1) {
          setWizardStep(2)
          return
        }
        if (validateForm()) {
          if (job) {
            const updateJob = async () => {
              try {
                await saveJob(job.name, job.id)
                isAdhocJob && refreshNodeApprovalsTabs(clientId)
                unmount()
              } catch (error) {
                reportErrorAndShowMessage(error)
              }
            }
            const candidateNodes = getCandidateNodes()
            if (isAdhocJob && candidateNodes.length) {
              showModal(<AdhocConfirmationModal devices={candidateNodes} saveJob={updateJob} runMode={runMode} />)
            } else {
              updateJob()
            }
          } else if (isAdhocJob) {
            showModal(
              <AdhocConfirmationModal
                devices={getCandidateNodes()}
                saveJob={async jobName => {
                  await saveJob(jobName)
                  refreshNodeApprovalsTabs(clientId)
                  unmount()
                }}
              />,
            )
          } else {
            showModal(
              <SaveDiscoveryJobModal
                saveText={localizationKey("Confirm")}
                saveJob={async jobName => {
                  await saveJob(jobName)
                  unmount()
                }}
              />,
            )
          }
        }
      }}
    >
      <ADStyledModalContainer>
        <StyledModal
          title={localizationKey("Run an AD Discovery Job")}
          height="625px"
          maxHeight="625px !important"
          submittable
          disabledSaveBtn={shouldDisableSaveBtn()}
          disabledCloseBtn={false}
          close={unmount}
          closeText={localizationKey("Cancel")}
          saveText={saveText}
          overflowY="visible !important"
          dialogClassName="m-t-xl modal-lg"
          noHeader
          paddingTop="30px"
          paddingBottom="0"
          backgroundColor="#fff !important"
          borderRadius="5px"
          altBtn={
            wizardStep === 2 &&
            !runMode && {
              text: localizationKey("Back"),
              action: () => setWizardStep(1),
            }
          }
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <StyledSpan fontSize="20px" fontWeight="600" marginBottom={2}>
              {isAdhocJob ? localized("Run an adhoc discovery job") : localized("Run a scheduled discovery job")}
            </StyledSpan>
            <StyledSpan fontSize="15px" textAlign="left">
              {localized(getModalDescription())}
            </StyledSpan>
          </Box>
          <StyledBodyContainer>
            {!runMode && (
              <Box className={getStepClassname(1)} marginTop={6}>
                <DomainControllerOUPathSelector
                  {...{
                    clientId,
                    domainController: values.domainController,
                    ouPath: values.ouPath,
                    recursiveOuPath: values.recursiveOuPath,
                    devices,
                    setDevices: setAndFormatDevices,
                    fetchDevices,
                    isLoadingDevices,
                    setIsLoadingDevices,
                    onSelectDomainController(domainController) {
                      const domainControllerLocationOption = getDomainControllerLocationOption(
                        domainController?.locationId,
                        locations,
                      )
                      onChange({
                        domainController,
                        windowsServersLocation: domainControllerLocationOption,
                        windowsWorkstationsLocation: domainControllerLocationOption,
                        ouPath: null,
                      })
                      setDefaultLocationOption(domainControllerLocationOption)
                    },
                    onSelectOuPath: ouPath => onChange({ ouPath }),
                    onRecursiveOuPathChange: recursiveOuPath => onChange({ recursiveOuPath }),
                    setIsValidOuPath,
                    setIsTypingOuPath,
                    isTypingOuPath,
                    isValidOuPath,
                    ouPathErrorMessage,
                  }}
                />
                {existingJobsConflictMessage?.message && (
                  <Box marginTop={2}>
                    {existingJobsConflictMessage.message}
                    {existingJobsConflictMessage.tooltipMessage && (
                      <Tooltip
                        placement="right"
                        output={
                          typeof existingJobsConflictMessage.tooltipMessage === "function"
                            ? existingJobsConflictMessage.tooltipMessage()
                            : existingJobsConflictMessage.tooltipMessage
                        }
                      />
                    )}
                  </Box>
                )}
              </Box>
            )}
            <Box className={getStepClassname(2)} marginTop={4}>
              <Box
                backgroundColor={colors.ninjaLightYellow}
                border={`1px solid ${colors.ninjaYellow}`}
                borderLeftWidth="8px"
                borderRadius="4px"
                padding="10px"
                textAlign="left"
                fontSize="13px"
                fontWeight="600"
              >
                {localized(
                  "Please enter the Windows domain credentials with enough permissions (usually domain administrator) to use active-push to deploy the Ninja agent. If active-push fails, a Group Policy startup script will be configured to install the Ninja agent.",
                )}
                <Box>
                  <Link href={`${DOJO_ARTICLES_PREFIX}/4405173636237-Active-Directory-Discovery-and-Deployment`}>
                    <div className="btn-link">{localized("See all AD Discovery and Deployment requirements")}</div>
                  </Link>
                </Box>
              </Box>
              <StyledRow
                display="block"
                margin={[4, 0, 2, 0]}
                paddingBottom={4}
                borderBottom={`1px solid ${colors.ninjaLight}`}
                height="75px"
              >
                <StyledCol xs={3} padding={0}>
                  <StyledFormGroup textAlign="left" margin="0 !important">
                    <StyledCol marginBottom="10px !important">
                      <StyledSpan fontSize="16px">{localized("Domain Controller")}</StyledSpan>
                    </StyledCol>
                    <StyledCol>
                      <StyledSpan>{values.domainController?.name}</StyledSpan>
                    </StyledCol>
                  </StyledFormGroup>
                </StyledCol>
                <StyledCol xs={5} padding={0}>
                  <StyledFormGroup textAlign="left" margin="0 !important" width="100%">
                    <StyledCol marginBottom="10px !important">
                      <StyledSpan fontSize="16px">
                        {values.recursiveOuPath && `${localized("Recursive")} `}
                        {localized("OU Path")}
                      </StyledSpan>
                    </StyledCol>
                    <StyledCol alignItems="center">
                      <FontAwesomeIcon icon={faFolder} />
                      <Tooltip output={values.ouPath}>
                        <StyledSpan paddingLeft={1} className="text-ellipsis">
                          {values.ouPath}
                        </StyledSpan>
                      </Tooltip>
                    </StyledCol>
                  </StyledFormGroup>
                </StyledCol>
                <StyledCol xs={4} paddingLeft={6}>
                  <StyledFormGroup textAlign="left" margin="0 !important" width="100%">
                    <StyledCol marginBottom="10px !important">
                      <StyledSpan fontSize="16px">{localized("Credentials")}</StyledSpan>
                    </StyledCol>
                    <StyledCol alignItems="center">
                      {isLoadingCredentials ? (
                        <Spinner />
                      ) : (
                        <>
                          <StyledButton
                            type="button"
                            className="btn btn-link text-ellipsis"
                            onClick={() => {
                              showModal(
                                <SelectCredentialsModal
                                  onSetCredential={credential => onChange({ credentialId: credential?.id })}
                                  credentials={credentialsList}
                                  defaultCredentialId={
                                    (credentialFromList?.name && values.credentialId) || defaultADCredentialId
                                  }
                                  isLoadingCredentials={isLoadingCredentials}
                                  setCredentials={setCredentialsList}
                                  clientId={clientId}
                                />,
                              )
                            }}
                          >
                            {credentialFromList?.name ? credentialFromList.name : localized("Select")}
                          </StyledButton>
                          {credentialFromList?.name && <Box marginLeft={2}>{getCredentialsIcon()}</Box>}
                        </>
                      )}
                    </StyledCol>
                  </StyledFormGroup>
                </StyledCol>
              </StyledRow>
              <StyledRow display="block" padding={3} height="350px">
                {isAdhocJob ? (
                  <AdhocDiscovery
                    {...{
                      devices,
                      locations,
                      defaultLocation: defaultLocationOption,
                      isLoading: isLoadingDevices,
                      selectedDevices,
                      setSelectedDevices,
                      onSetLocationAndStatus: ({ location, status }) => {
                        const updatedDevices = devices.map(device => {
                          return selectedDevices.rows[device.objectGuid]
                            ? {
                                ...device,
                                newStatus: status,
                                newLocation: location && pickIdAndName(location),
                              }
                            : device
                        })
                        setDevices(updatedDevices)
                      },
                      onRefresh: () =>
                        fetchDevices(values.domainController, values.ouPath, [...devices], values.recursiveOuPath),
                    }}
                  />
                ) : (
                  <ScheduledDiscovery
                    {...{
                      values,
                      onChange,
                      validation,
                      locations,
                      defaultLocation: defaultLocationOption,
                    }}
                  />
                )}
              </StyledRow>
            </Box>
          </StyledBodyContainer>
        </StyledModal>
      </ADStyledModalContainer>
    </Form>
  )
}

export default connect(
  ({ locations, customerEditor: { discoveryJobs } }) => ({
    locations: locations.locations,
    discoveryJobsList: discoveryJobs.discoveryJobsList,
  }),
  dispatch => ({
    getLocations: clientId => dispatch(getLocations(clientId)),
    saveDiscoveryJob: (job, id) => dispatch(_saveDiscoveryJob(job, id)),
    refreshNodeApprovalsTabs: tabsHandler(dispatch).refreshTabs,
    setDiscoveryJobsList: list => dispatch(setDiscoveryJobsList(list)),
  }),
)(ActiveDirectoryDiscoveryJobModal)
