import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { HoverDropdownItem } from "@ninjaone/components"
import { submitVMAction as _submitVMAction } from "js/state/actions/virtualization"
import VMJobActions from "js/includes/vmDashboard/common/VMJobActions"
import { faTrash } from "@fortawesome/pro-solid-svg-icons"
import { VM_GUEST_DELETE_ACTION } from "js/includes/vmDashboard/actions"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { isVmHyperVGuest, localized, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import { fetchDeviceById } from "js/includes/common/client"

async function isHostDown(parentNodeId) {
  try {
    const host = await fetchDeviceById(parentNodeId)
    return host?.node?.isDownVmHost
  } catch (error) {
    reportErrorAndShowMessage(error)
    return true
  }
}

class VMJobActionsSubMenu extends PureComponent {
  state = {
    isHostDown: true,
  }

  async componentDidMount() {
    const { node } = this.props
    const _isHostDown = await isHostDown(node.parentNodeId)
    this.setState({ isHostDown: _isHostDown })
  }

  runAction = async action => {
    const { node, submitVMAction } = this.props
    if (!node) {
      return
    }

    const nodeIds = [node.id]

    if (VM_GUEST_DELETE_ACTION.identifier !== action.identifier) {
      submitVMAction(action.identifier, nodeIds)
      return
    }

    const deleteButtonPressed = await ShowMessageDialog({
      icon: { icon: faTrash, type: "critical" },
      title: () => node.netbiosName || localized("Delete"),
      message: localizationKey("Are you sure you want to delete this virtual machine from the VMware Host?"),
      buttons: [
        { id: true, label: localizationKey("Delete"), type: "critical" },
        { id: false, label: localizationKey("Cancel") },
      ],
    })

    if (deleteButtonPressed) {
      submitVMAction(action.identifier, nodeIds)
    }
  }

  render() {
    const { runningAction, guestSystem, node } = this.props
    const { powerState, toolsRunningStatus, toolsVersionStatus } = guestSystem

    if (this.state?.isHostDown) {
      return null
    }

    return (
      <ul>
        <VMJobActions
          disabled={runningAction}
          powerStates={[powerState]}
          toolsRunningStatus={[toolsRunningStatus]}
          toolsVersionStatus={[toolsVersionStatus]}
          onClickAction={this.runAction}
          isVmHyperVGuest={isVmHyperVGuest(node.nodeClass)}
          ButtonComponent={({ token, onClick, disabled }) => (
            <HoverDropdownItem
              {...{
                onClick,
                disabled,
                token,
              }}
            />
          )}
        />
      </ul>
    )
  }
}

export default connect(
  ({ virtualization }) => ({
    runningAction: virtualization.runningVMGuestAction,
  }),
  {
    submitVMAction: _submitVMAction,
  },
)(VMJobActionsSubMenu)
