import styled from "@emotion/styled"
import { resetButtonStyle } from "@ninjaone/components"
import { CloseIcon } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import { localized } from "js/includes/common/utils"

const StyledTag = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: ${sizer(1)};
  height: 20px;
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutral};
  color: ${({ theme }) => theme.colorTextStrong};
  font-size: ${getTextSize("sm")};
  line-height: 20px;
  padding-left: ${sizer(2)};
  padding-right: ${sizer(1)};
  margin-right: ${sizer(2)};
  text-transform: capitalize;
  border-radius: 2px;
  white-space: nowrap;

  && {
    button {
      ${resetButtonStyle}

      svg {
        width: auto;
        padding-right: ${sizer(1)};
        padding-left: ${sizer(1)};
      }
    }
  }
`

const GlobalSearchFilterTag = ({ label, onClose }) => {
  return (
    <StyledTag data-testid="global-search-filter-tag">
      {label}
      <button
        title={localized("Remove filter")}
        onClick={event => {
          event.stopPropagation()
          onClose()
        }}
      >
        <CloseIcon size="sm" />
      </button>
    </StyledTag>
  )
}

export default GlobalSearchFilterTag
