import { Transforms } from "slate"

import { deserialize } from "js/includes/components/RichTextEditor/serializer"

export const withHtml = editor => {
  const { insertData, isInline, isVoid } = editor

  editor.isInline = element => element.type === "link" || isInline(element)

  editor.isVoid = element => element.type === "image" || isVoid(element)

  editor.insertData = data => {
    const html = data.getData("text/html")

    if (html) {
      const parsed = new DOMParser().parseFromString(html, "text/html")
      parsed.body.innerHTML = parsed.body.innerHTML.replace(/<br\s*\/?>/gi, "")
      const fragments = deserialize({ element: parsed.body })
      Transforms.insertFragment(
        editor,
        fragments.filter(fragment => fragment.type !== "image"),
      )
      return
    }

    insertData(data)
  }

  return editor
}
