import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  localized,
  localizationKey,
  readFileAsBinaryString,
  bytes,
  isFileValid,
  localizedF,
} from "js/includes/common/utils"
import { faFileUpload } from "@fortawesome/pro-regular-svg-icons"
import { DropTarget } from "react-dnd"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import styled from "@emotion/styled"
import { colors } from "js/includes/common/theme/colors"

const StyledOverlayContainer = styled.div`
  z-index: 5;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ light }) => (light ? colors.ninjaWhite : "rgba(0, 0, 0, 0.1)")};
  border: ${({ light }) => (light ? `3px dashed ${colors.ninjaMedium}` : `3px solid ${colors.ninjaBlueSaturated}`)};
  border-radius: ${({ light }) => (light ? `5px` : `0px`)};
  opacity: ${({ isOver }) => (isOver ? 1 : 0.6)};
`

const StyledOverlayInfo = styled.div`
  padding: 1.5em;
  margin: 10px;
  color: ${({ light }) => (light ? colors.ninjaDark : colors.white)};
  background-color: ${({ light }) => (light ? `transparent` : colors.ninjaBlueSaturated)};
  box-shadow: ${({ light }) => (light ? "none" : "0px 3px 10px 0px rgba(0, 0, 0, 0.5)")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  flex-direction: column;

  p,
  h3 {
    font-size: 12px;
    margin-bottom: 0;
  }
`

async function onFileAccepted(file, onFileRead) {
  try {
    const fileAsBinaryString = await readFileAsBinaryString(file)
    onFileRead({ fileName: file.name, content: fileAsBinaryString, file })
  } catch (error) {
    throw error
  }
}

function onFileRejected(validFileTypes, { minSize, maxSize, overMaxSize, belowMinSize }) {
  ShowMessageDialog({
    icon: { icon: faInfoCircle, type: "warning" },
    title: localizationKey("Invalid file type"),
    message: () => `${localized("general.youHaveAttemptedToDrop")} ${validFileTypes}`,
    ...(belowMinSize && {
      title: "general.fileTooSmall",
      message: localizedF("general.fileSizeMustBeMoreThanBytes", { size: minSize }),
    }),
    ...(overMaxSize && {
      title: localizationKey("File too large"),
      message: () => `${localized("File exceeds the limit of ")} ${bytes(maxSize, 0)}`,
    }),
    buttons: [{ id: "OK", label: localizationKey("OK") }],
  })
}

class DropZone extends Component {
  static defaultProps = {
    light: false,
  }

  render() {
    const {
      canDrop,
      isOver,
      connectDropTarget,
      children,
      validFileTypes,
      className,
      alwaysShow,
      dropMessageToken,
      icon,
      iconSize,
      light,
    } = this.props
    const isActive = (canDrop && isOver) || alwaysShow

    return connectDropTarget(
      <div className={`position-relative ${className}`}>
        {isActive && (
          <StyledOverlayContainer {...{ isOver, light }}>
            <StyledOverlayInfo {...{ light }}>
              <div className="m-b-xl m-t-xl">
                <FontAwesomeIcon icon={icon || faFileUpload} size={iconSize || "6x"} />
              </div>
              <p>{localized(dropMessageToken)}</p>
              <h3>{validFileTypes.replace(/,/g, " ")}</h3>
            </StyledOverlayInfo>
          </StyledOverlayContainer>
        )}
        {children && React.Children.only(children)}
      </div>,
    )
  }
}

const oneGigabyte = 1073741824

export default DropTarget(
  props => props.accepts,
  {
    drop(props, monitor) {
      const { minSize = 1, maxSize = oneGigabyte, onFileRead, validFileTypes } = props
      const file = monitor.getItem().files[0]
      const belowMinSize = file.size < minSize
      const overMaxSize = file.size > maxSize

      if (!belowMinSize && !overMaxSize && isFileValid(file, validFileTypes)) {
        onFileAccepted(file, onFileRead)
      } else {
        onFileRejected(validFileTypes, { minSize, maxSize, overMaxSize, belowMinSize })
      }
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(DropZone)
