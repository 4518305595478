import React, { memo, useCallback, useEffect } from "react"
import { nanoid } from "nanoid"
import { connect } from "react-redux"
import { Modal, Text } from "@ninjaone/components"
import {
  addSelectedPolicyScript as _addSelectedPolicyScript,
  editSelectedPolicyScript as _editSelectedPolicyScript,
} from "js/state/actions/policyEditor/scripting/selectedPolicyScripts"
import { setSelectedScriptsResultConditionScript } from "js/state/actions/policyEditor/scripting/selectedScriptsResultConditionScript"
import { hide } from "js/state/actions/common/visibility"
import { getDeviceType } from "js/state/helpers/devices"
import { requestAllScriptCategories } from "js/state/actions/scripting/scriptCategories"
import { requestAllScripts } from "js/state/actions/scripting/scripts"
import { setValue } from "js/state/actions/common/value"
import { useMountedState } from "js/includes/common/hooks"
import { isLinuxDevice, isMacDevice, localized, overrideStateProps, user } from "js/includes/common/utils"
import { getNewJobModel } from "js/includes/common/_jobs"
import ScriptsTable from "js/includes/configuration/scripting/ScriptsSubTabModel/ScriptsTable"
import { fetchResources } from "js/includes/components/scripting/utils"
import Indicator from "js/includes/components/Indicator"
import { Box, Flex } from "js/includes/components/Styled"
import showModal from "js/includes/common/services/showModal"
import ParameterModal from "js/includes/components/scripting/ScriptsSelector/ParameterModal"
import { getParameterComponent, setScriptName } from "js/includes/components/scripting/ScriptsSelector/ScriptsTable"
import DeviceTags from "js/includes/components/scripting/DeviceTags"

const getDeviceTypesFromDevicesArray = devices =>
  Array.from(new Set(devices.map(device => getDeviceType(device.nodeClass))))

const AutomationsSelectorModal = ({
  unmount,
  titleText,
  descriptionText,
  nodeRole,
  devices,
  scripts,
  addSelectedPolicyScript,
  hideScriptsRequiredMessage,
  editSelectedPolicyScript,
  conditionScriptSelector,
  setSelectedScriptsResultConditionScript,
  requestAllScriptCategories,
  requestAllScripts,
  shouldFetchScripts = true,
  setParameterModal,
  runAdhocJob,
  defaultAutomationTypes,
  scriptLanguages,
  fromDeviceSearch = false,
  selectedCount,
}) => {
  const [isLoading, setIsLoading] = useMountedState(false)

  const getDefaultDeviceTypes = () => {
    if (nodeRole) return [getDeviceType(nodeRole)]
    return devices?.length > 0 ? getDeviceTypesFromDevicesArray(devices) : []
  }

  const getAutomationTypesExcept = () => [
    ...(isMacDevice(nodeRole) ? ["BinaryRunAutomation"] : []),
    ...(isLinuxDevice(nodeRole) || conditionScriptSelector ? ["BinaryInstallAutomation", "BinaryRunAutomation"] : []),
  ]

  const getAdhocScript = script => {
    const { scriptId } = script

    const { name: scriptName } = scripts.find(s => (s.language === "native" ? s.uid : s.id) === scriptId)
    const jobContent = { scripts: [script] }
    const appUserName = user("getName")

    return getNewJobModel(
      scriptName,
      localized("User {{appUserName}} requested start of automation {{scriptName}}", { appUserName, scriptName }),
      jobContent,
    )
  }

  const applyScript = ({ script, type, automationType }) => {
    if (runAdhocJob) {
      const job = window.jobTypeCollection.findWhere({ identifier: script.scriptId }) || getAdhocScript(script)
      runAdhocJob(job, automationType)
    } else {
      switch (type) {
        case "ADD":
          if (conditionScriptSelector) {
            setSelectedScriptsResultConditionScript(script)
          } else {
            addSelectedPolicyScript(script)
            hideScriptsRequiredMessage()
          }
          break
        case "EDIT":
          if (conditionScriptSelector) {
            setSelectedScriptsResultConditionScript(script)
          } else {
            editSelectedPolicyScript(script)
          }
          break
        default:
          throw new Error(`Invalid type of "${type}"`)
      }
    }
  }

  const fetchAutomationResources = useCallback(async () => {
    await fetchResources({
      setIsLoading,
      requestResources: [requestAllScriptCategories, requestAllScripts],
      errorKey: "state.actions.scriptsTab.errorFetchingScripts",
    })
  }, [requestAllScriptCategories, requestAllScripts, setIsLoading])

  useEffect(() => {
    shouldFetchScripts && fetchAutomationResources()
  }, [shouldFetchScripts, fetchAutomationResources])

  const handleRowSelected = rowData => {
    const afterApply = () => !runAdhocJob && unmount()

    const scriptId = rowData.language === "native" ? rowData.uid : rowData.id
    const {
      deviceType,
      scriptParameters,
      parameters,
      binaryInstallSettings,
      binaryRunSettings,
      scriptVariables,
      description,
      name,
      language,
      defaultRunAs,
      useFirstParametersOptionAsDefault,
    } = rowData
    const runOrInstallSettings = { ...binaryInstallSettings, ...binaryRunSettings }
    const automationType = scriptLanguages.find(item => item.id === language)?.automationType
    const parameterModal = {
      parameterComponent: getParameterComponent(rowData),
      scriptName: name,
      scriptDescription: description,
      scriptLanguage: language,
      scriptId,
      deviceType,
      scriptParameters,
      parameters: {
        ...parameters,
        ...(useFirstParametersOptionAsDefault && { scriptParam: scriptParameters[0] }),
        ...(!conditionScriptSelector && { runAs: runOrInstallSettings?.runAs || defaultRunAs }),
      },
      scriptVariables,
    }

    if (parameterModal.parameterComponent) {
      setParameterModal(parameterModal)
      showModal(
        <ParameterModal
          {...{
            devices,
            applyScript: props => applyScript({ ...props, automationType }),
            isAdhocJob: runAdhocJob,
            onAfterApply: afterApply,
            automationType: automationType,
            fromDeviceSearch,
            selectedCount,
          }}
        />,
        {
          withProvider: true,
        },
      )
    } else {
      const script = {
        id: nanoid(10),
        scriptId,
        scriptName: setScriptName(rowData),
      }
      applyScript({ type: "ADD", script, automationType })
      afterApply()
    }
  }

  return (
    <Modal
      {...{
        size: "lg",
        unmount,
        withCloseX: true,
        titleGroup: {
          titleText: titleText ?? devices ? localized("Automation Library") : localized("Select automation"),
          ...(devices
            ? {
                DescriptionComponent: () => {
                  return (
                    <Flex alignItems="center">
                      <Text size="sm">{localized("Select an automation to run on")}&nbsp;</Text>
                      <DeviceTags {...{ devices, ...(fromDeviceSearch && { totalCount: selectedCount }) }} />
                    </Flex>
                  )
                },
              }
            : { descriptionText }),
        },
        buttonRenderer: () => <span />,
      }}
    >
      <Box width="100%" height="calc(100vh - 300px)">
        {isLoading ? (
          <Indicator />
        ) : (
          <ScriptsTable
            showDescription
            showAddButton={false}
            isEditableRows={false}
            hideSettingsButton
            onRowSelected={handleRowSelected}
            defaultDeviceTypes={getDefaultDeviceTypes()}
            automationTypesExcept={getAutomationTypesExcept()}
            defaultAutomationTypes={defaultAutomationTypes}
          />
        )}
      </Box>
    </Modal>
  )
}

export default connect(
  state => ({
    scripts: state.scripting.scripts,
    scriptLanguages: state.scripting.languages,
    conditionScriptSelector: state.policyEditor.scripting.conditionScriptSelector,
    selectedCount: state.deviceSearch.actionRunnerParams.selectedCount,
  }),
  {
    requestAllScriptCategories,
    requestAllScripts,
    addSelectedPolicyScript: _addSelectedPolicyScript,
    editSelectedPolicyScript: _editSelectedPolicyScript,
    hideScriptsRequiredMessage: hide("scriptsRequiredMessageVisibility"),
    setSelectedScriptsResultConditionScript,
    setParameterModal: setValue("parameterModal"),
  },
  overrideStateProps,
)(memo(AutomationsSelectorModal))
