import { PureComponent } from "react"
import { Col } from "react-bootstrap"
import { sizer } from "@ninjaone/utils"
import { localized } from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { Box } from "js/includes/components/Styled"
import { StyledLabel } from "js/includes/components/DeviceModal/style"

class OrganizationPicker extends PureComponent {
  render() {
    const {
      props: {
        organization,
        defaultFirstValueSelected,
        isMulti,
        showLabel,
        value,
        onFocus,
        openOnFocus,
        autoFocus,
        onClose,
        searchPlaceholderToken,
        disabled,
        validationMessage,
        validationState,
        changeOrganization,
        onSearchTextChange,
        excludeSelectedFromSelectableValues,
        customDropdownOptions,
        searchableKey = "name",
        valueSelectorKey = "name",
        searchParams,
        labelAbove = false,
        validateForm,
        hasMarginTop = true,
        errorMessage = localized("Required"),
        enableClear,
        required = true,
      },
    } = this

    return labelAbove ? (
      <Box marginTop={hasMarginTop ? sizer(3) : "0"} data-testid="organization-box">
        <StyledLabel
          data-testid="organization-label"
          {...{
            htmlFor: "organization-selector",
          }}
        >
          {localized("general.organization")} {required && "*"}
        </StyledLabel>
        <SearchableDropDown
          {...{
            id: "organization-selector",
            isMulti,
            onSelect: changeOrganization,
            onFocus,
            autoFocus,
            value,
            openOnFocus,
            searchableKey,
            valueSelectorKey,
            endpoint: "/query/organizations",
            pageSize: 50,
            searchParams,
            getInitialValue: () => organization?.name,
            keepInView: false,
            width: "100%",
            rowHeight: 40,
            searchPlaceholderToken,
            onClose,
            disabled,
            validationMessage,
            validationState,
            onSearchTextChange,
            excludeSelectedFromSelectableValues,
            ...(defaultFirstValueSelected && { getDefaultSelected: items => items[0] }),
            ...customDropdownOptions,
            onBlur: () => {
              validateForm?.(["organization"])
            },
            errorMessage,
            enableClear,
          }}
        />
      </Box>
    ) : (
      <>
        {showLabel && (
          <Col xs={3}>
            <label id="organization-label">{localized("Organization")}</label>
          </Col>
        )}
        <Col {...{ ...(showLabel ? { xs: 9 } : {}) }} className={disabled ? "not-allowed" : ""}>
          <SearchableDropDown
            {...{
              ariaAttributes: { "aria-labelledby": "organization-label" },
              isMulti,
              onSelect: changeOrganization,
              onFocus,
              autoFocus,
              value,
              openOnFocus,
              searchableKey,
              valueSelectorKey,
              endpoint: "/query/organizations",
              pageSize: 50,
              searchParams,
              getInitialValue: () => organization?.name,
              keepInView: false,
              width: "100%",
              onClose,
              searchPlaceholderToken,
              disabled,
              validationMessage,
              validationState,
              onSearchTextChange,
              excludeSelectedFromSelectableValues,
              ...(defaultFirstValueSelected && { getDefaultSelected: items => items[0] }),
              ...customDropdownOptions,
            }}
          />
        </Col>
      </>
    )
  }
}

OrganizationPicker.defaultProps = {
  showLabel: true,
}

export default OrganizationPicker
