import React, { memo } from "react"
import PropTypes from "prop-types"

import { localized } from "js/includes/common/utils"
import * as actions from "js/includes/vmDashboard/actions"
import { vmActionNameMapper } from "js/includes/vmDashboard/common/VMJobActions"

const VMAction = memo(({ actionName, disabled = false, onClickAction, ButtonComponent }) => {
  function handleClick() {
    const action = actions[`VM_GUEST_${actionName.toUpperCase()}_ACTION`]
    if (!action) {
      throw new Error(`Could not find VM action with name: ${actionName}`)
    }

    onClickAction(action)
  }
  // TODO: Dynamic Token
  const token = vmActionNameMapper[actionName]

  return (
    <li>
      {ButtonComponent ? (
        <ButtonComponent {...{ disabled, token, onClick: handleClick, isDelete: actionName === "delete" }} />
      ) : (
        <button
          className={`btn-link${actionName === "delete" ? " text-danger" : ""}`}
          disabled={disabled}
          onClick={handleClick}
        >
          {localized(token)}
        </button>
      )}
    </li>
  )
})

VMAction.propTypes = {
  disabled: PropTypes.bool,
  actionName: PropTypes.oneOf([
    "restart",
    "power_on",
    "power_off",
    "shutdown",
    "reset",
    "suspend",
    "save",
    "delete",
    "update_tools",
    "pause",
    "resume",
    "start",
    "turn_off",
  ]).isRequired,
  onClickAction: PropTypes.func.isRequired,
}

export default VMAction
