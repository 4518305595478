import styled from "@emotion/styled"
import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import Body from "./Typography/Body"

const StyledContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacing[1]};
  max-height: 300px;
  padding: ${tokens.spacing[2]};
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  list-style: none;
`

const ConfirmationModalList = ({ items = [], maxItemsToDisplay = 9 }) => {
  return (
    <StyledContainer>
      {items.slice(0, maxItemsToDisplay).map(item => {
        return (
          <Body key={item} as="li">
            {item}
          </Body>
        )
      })}

      {items.length > maxItemsToDisplay && (
        <Body as="li">{localized("+{{count}} more", { count: items.length - maxItemsToDisplay })}</Body>
      )}
    </StyledContainer>
  )
}

ConfirmationModalList.propTypes = {
  /**
   * The text items to display in the component.
   */
  items: PropTypes.arrayOf(PropTypes.string),
  /**
   * The maximum number of items to display in the component.
   */
  maxItemsToDisplay: PropTypes.number,
}

export default ConfirmationModalList
