import React, { PureComponent } from "react"
import { pathOr, compose, toPairs } from "ramda"
import SubMenu from "js/includes/components/Menu/SubMenu"
import { localized, sortByCaseInsensitive } from "js/includes/common/utils"
import { getNewJobModel } from "js/includes/common/_jobs"

export default class RunPolicyScheduledScriptsButton extends PureComponent {
  getScheduledScriptJob = scheduledScript => {
    const { actionsetScheduleName: name, scripts } = scheduledScript
    const message = localized("Scheduled automation '{{automationName}}' running on demand", { automationName: name })
    const scheduledScriptJob = getNewJobModel(name, message, { scripts })
    this.props.runScheduledScriptJob(scheduledScriptJob)
  }

  getScheduledScriptsFromPolicy() {
    return compose(toPairs, pathOr({}, ["actionsetSchedules"]))(this.props.commonPolicy)
  }

  handleOnClick = script => {
    this.getScheduledScriptJob(script)
  }

  render() {
    const { justification, customLabel, ButtonComponent } = this.props
    const { handleOnClick } = this
    const scheduledScripts = this.getScheduledScriptsFromPolicy()
    const sortedScripts = sortByCaseInsensitive(([id, script]) => script.actionsetScheduleName)(scheduledScripts)

    return (
      !!scheduledScripts.length &&
      (ButtonComponent ? (
        <ButtonComponent {...{ handleOnClick, sortedScripts }} />
      ) : (
        <SubMenu {...{ justification, label: customLabel || localized("Policies: Scheduled Automations") }}>
          {sortedScripts.map(scheduledScript => {
            const [id, script] = scheduledScript
            return (
              <button key={id} className="btn-link" onClick={() => handleOnClick(script)}>
                {script.actionsetScheduleName}
              </button>
            )
          })}
        </SubMenu>
      ))
    )
  }
}
