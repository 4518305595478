import { memo } from "react"
import { Input } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { Box } from "js/includes/components/Styled"

const DnsIp = memo(({ value, onChange, validateForm, disabled, errorMessage }) => {
  return (
    <Box marginTop={sizer(3)} data-testid="dns-label">
      <Input
        {...{
          labelToken: "editorsCustomerCustomerEditor.dnsIp",
          type: "text",
          value,
          onChange: e => onChange(e.target.value),
          disabled,
          required: true,
          onBlur: () => validateForm(["dnsIp"]),
          errorMessage,
        }}
      />
    </Box>
  )
})

export default DnsIp
