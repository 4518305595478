import React from "react"
import { FormControl, Form } from "react-bootstrap"
import { reportErrorAndShowMessage, validations, showErrorMessage } from "js/includes/common/utils"
import { useForm, useMountedState } from "js/includes/common/hooks"
import { StyledFormGroup, StyledCol, StyledModal } from "js/includes/components/Styled/Form"
import { StyledSpan } from "js/includes/components/Styled"
import ADStyledModalContainer from "../components/ADStyledModalContainer"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export default function SaveDiscoveryJobModal({ unmount, saveJob, saveText }) {
  const {
    values: { jobName },
    validation,
    validateForm,
    onChange,
  } = useForm({
    fields: {
      jobName: "",
    },
    validate: {
      jobName: validations.required,
    },
  })
  const [saving, setSaving] = useMountedState(false)

  return (
    <Form
      onSubmit={async e => {
        e.preventDefault()
        if (validateForm()) {
          try {
            setSaving(true)
            await saveJob(jobName)
            unmount()
          } catch (error) {
            if (error.resultCode === "DOMAIN_CONTROLLER_AND_OU_PATH_ALREADY_IN_USE") {
              showErrorMessage(localized("Domain Controller and OU Path already in use."))
            } else {
              reportErrorAndShowMessage(error)
            }
          } finally {
            setSaving(false)
          }
        }
      }}
    >
      <ADStyledModalContainer>
        <StyledModal
          title={localizationKey("Job Name")}
          close={unmount}
          submittable
          saveText={saveText}
          disabledSaveBtn={saving}
          noHeader
          backgroundColor="#fff !important"
          borderRadius="5px"
        >
          <StyledSpan
            fontSize="20px"
            fontWeight="600"
            margin={[2, 0, 3]}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            {localized("Name discovery job")}
          </StyledSpan>
          <StyledFormGroup
            textAlign="left"
            margin="0px !important"
            validationState={validation.success.jobName === false ? "error" : null}
          >
            <StyledCol>
              <StyledSpan fontSize="14px" marginBottom={1}>
                {localized("Name")}
              </StyledSpan>
            </StyledCol>
            <StyledCol>
              <FormControl
                name="jobName"
                type="text"
                value={jobName}
                {...{ onChange }}
                className={`form-control ${validation.success.jobName === false ? "has-error" : ""}`}
              />
            </StyledCol>
          </StyledFormGroup>
        </StyledModal>
      </ADStyledModalContainer>
    </Form>
  )
}
