import { memo, useEffect } from "react"
import { compose, map } from "ramda"
import tokens from "@ninjaone/tokens"
import { Input, Modal, Switch, Text, TextArea } from "@ninjaone/components"
import {
  defaultToEmpty,
  fetchJson,
  isNilOrEmpty,
  localized,
  localizationKey,
  ninjaReportError,
  showErrorMessage,
  reportErrorAndShowMessage,
  validations,
  getEditorType,
  getNodeRoleLabel,
  getNmsRoles,
  isAndroidMDMFeatureEnabled,
  isAppleMDMFeatureEnabled,
} from "js/includes/common/utils"
import { useForm, useMountedState } from "js/includes/common/hooks"
import { NinjaResponseError } from "js/includes/common/types"
import { fetchPolicy } from "js/state/actions/policyEditor/editor/index"
import SearchablePolicyDropdown from "js/includes/components/SearchablePolicyDropdown"
import { getNodeRoles as _getNodeRoles } from "js/includes/common/client"
import { sortListByLabel } from "js/state/helpers/listSort"
import { Box } from "js/includes/components/Styled"
import { DefaultButtonGroup } from "@ninjaone/components/src/ButtonGroup"
import { StyledLabel } from "@ninjaone/components/src/Input"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const getRoleOptions = () => [
  { value: "WINDOWS_WORKSTATION", label: localized("Windows Desktops and Laptops") },
  { value: "WINDOWS_SERVER", label: localized("Windows Server") },
  { value: "MAC", label: localized("Mac Desktops and Laptops") },
  { value: "MAC_SERVER", label: localized("Mac Server") },
  { value: "LINUX_WORKSTATION", label: localized("Linux Desktops and Laptops") },
  { value: "LINUX_SERVER", label: localized("Linux Server") },
  { value: "NMS", label: "NMS" }, //localization removed
  { value: "VMWARE_VM_GUEST", label: localized("VMWare Virtual Machine Policy") },
  { value: "VMWARE_VM_HOST", label: localized("VMWare Host Policy") },
  { value: "HYPERV_VMM_GUEST", label: localized("Hyper-V Guest VM Policy") },
  { value: "HYPERV_VMM_HOST", label: localized("Hyper-V Host Policy") },
  ...(isAndroidMDMFeatureEnabled() ? [{ value: "ANDROID", label: "Android" }] : []),
  ...(isAppleMDMFeatureEnabled()
    ? [
        { value: "APPLE_IOS", label: "Apple iOS" },
        { value: "APPLE_IPADOS", label: "Apple iPad" },
      ]
    : []),
]

const createSelectValues = compose(
  sortListByLabel,
  map(item => ({
    label: getNodeRoleLabel(item),
    value: item.nodeClass,
    id: item.id,
  })),
)

const getDefaultParentPolicy = () => ({ name: localized("None"), id: null })

function validateName(name) {
  const success = !!name.trim()
  const message = success ? "" : localized("Required")
  return { success, message }
}

function validateNmsOption(nmsOption, { nodeRole }) {
  const success = nodeRole.value === "NMS" ? !!nmsOption : true
  const message = success ? "" : localized("Required")
  return { success, message }
}

function getNodeRole(isCopy, copiedNodeRole) {
  if (isCopy) {
    return copiedNodeRole.startsWith("NMS")
      ? {
          value: "NMS",
          label: "NMS", //localization removed
        }
      : getRoleOptions().find(roleOption => roleOption.value === copiedNodeRole)
  }
  return ""
}

const CreatePolicyModal = memo(
  ({
    unmount: close,
    isCopy = false,
    parentPolicyId,
    copiedDescription,
    copiedNodeRole,
    antivirusProductCode,
    updateTime,
    copiedId,
    copiedNodeRoleId,
  }) => {
    const [disabledSaveBtn, setDisabledSaveBtn] = useMountedState(false)
    const [showNmsOptions, setShowNmsOptions] = useMountedState(copiedNodeRole && copiedNodeRole.startsWith("NMS"))
    const [parentPolicy, setParentPolicy] = useMountedState()
    const [content, setContent] = useMountedState()
    const [nmsOptions, setNmsOptions] = useMountedState([])

    const {
      values: { enabled, name, description, nodeRole, nmsOption, nodeClassDefault },
      validateForm,
      validation,
      onChange,
    } = useForm({
      fields: {
        name: "",
        enabled: true,
        showErrorMessage: "",
        nodeClassDefault: false,
        description: defaultToEmpty(copiedDescription),
        nodeRole: getNodeRole(isCopy, copiedNodeRole),
        nmsOption: "",
      },
      validate: {
        name: validateName,
        nodeRole: validations.required,
        nmsOption: validateNmsOption,
      },
    })

    useEffect(() => {
      const getNodeRoles = async () => {
        try {
          const nodeRoles = await _getNodeRoles()
          const nmsOptions = compose(createSelectValues, getNmsRoles)(nodeRoles)
          setNmsOptions(nmsOptions)

          if (isCopy && copiedNodeRole.startsWith("NMS")) {
            onChange(
              "nmsOption",
              nmsOptions.find(roleOption => roleOption.id === copiedNodeRoleId),
            )
          }
        } catch (error) {
          reportErrorAndShowMessage(error, localizationKey("Error fetching node roles"))
        }
      }

      getNodeRoles()
    }, [copiedNodeRole, copiedNodeRoleId, isCopy, onChange, setNmsOptions])

    const [selectedRole, setSelectedRole] = useMountedState(nmsOption?.value || nodeRole?.value)

    useEffect(() => {
      const fetchSourcePolicy = async () => {
        const sourcePolicy = await fetchPolicy(copiedId)
        setContent(sourcePolicy.content)
      }
      if (isCopy) fetchSourcePolicy()
    }, [isCopy, copiedId, setContent])

    async function save(e) {
      e.preventDefault()
      e.stopPropagation()

      const isValidForm = validateForm()

      if (!isValidForm) {
        return
      }

      try {
        setDisabledSaveBtn(true)
        const response = await fetchJson(`/policy`, {
          options: {
            method: "POST",
            body: JSON.stringify({
              enabled,
              name: name.trim(),
              nodeClassDefault,
              nodeClass: nodeRole.value === "NMS" ? nmsOption.value : nodeRole.value,
              // TODO: The UI is only allowing nms custom roles for now
              ...(nodeRole.value === "NMS" && { nodeRoleId: nmsOption.id }),
              description: description.trim().length > 0 ? description.trim() : null,
              content,
              antivirusProductCode,
              updateTime,
              ...(parentPolicy?.id && { parentPolicyId: parentPolicy.id }),
            }),
          },
        })
        if (response.resultCode === "SUCCESS") {
          close()
          const { value } = nodeRole
          const editorType = getEditorType(value)
          window.location.hash = `#/editor/${editorType}/${response.policyId}`
        } else if (response.resultCode === "POLICY_NAME_ALREADY_IN_USE") {
          showErrorMessage(localized("A policy with this name already exists"))
          setDisabledSaveBtn(false)
        } else {
          setDisabledSaveBtn(false)
          throw new NinjaResponseError(response)
        }
      } catch (error) {
        showErrorMessage(localized("Error saving policy"))
        ninjaReportError(error)
        setDisabledSaveBtn(false)
      }
    }

    function onChangeNodeRole(role) {
      role.value === "NMS" ? setShowNmsOptions(true) : setShowNmsOptions(false)
      onChange("nodeRole", role)
      updatePolicyList(role.value)
    }

    function onChangeNmsOption(role) {
      onChange("nmsOption", role)
      updatePolicyList(role.value)
    }

    function updatePolicyList(role) {
      setParentPolicy(getDefaultParentPolicy())
      setSelectedRole(role)
    }

    return (
      <form onSubmit={save}>
        <Modal
          {...{
            size: "md",
            unmount: close,
            titleGroup: {
              TitleComponent: () => (
                <Text
                  token={isCopy ? localizationKey("Copy policy") : localizationKey("Create a policy")}
                  color="colorTextStrong"
                  size="lg"
                  bold
                />
              ),
            },
            buttonRenderer: () => (
              <Box width="100%">
                <DefaultButtonGroup
                  {...{
                    unmount: close,
                    actionToken: localizationKey("Create"),
                    submit: true,
                    disabled: disabledSaveBtn,
                  }}
                />
              </Box>
            ),
          }}
        >
          <Box display="grid" gridGap={tokens.spacing[4]} paddingBottom={tokens.spacing[2]}>
            <Input
              labelText={`${localized("Name")} *`}
              placeholder={localized("Enter name")}
              value={name}
              maxLength={100}
              onChange={e => onChange("name", e.target.value)}
              errorMessage={validation.message.name}
            />
            <TextArea
              labelText={localized("Description")}
              maxLength={250}
              rows={2}
              value={description}
              placeholder={localized("Enter description (optional)")}
              onChange={e => onChange("description", e.target.value)}
            />
            <div>
              <StyledLabel>{`${localized("Role")} *`}</StyledLabel>
              <SearchableDropDown
                width="100%"
                searchPlaceholderToken={localizationKey("Select role")}
                disabled={isCopy}
                value={nodeRole || {}}
                options={getRoleOptions()}
                onSelect={onChangeNodeRole}
                onBlur={() => validateForm(["nodeRole"])}
                errorMessage={validation.message.nodeRole}
                {...(validation.message.nodeRole && { validationState: "error" })}
                keepDropdownInView
                activeScrollEventListener
                useSelectStyling
              />
            </div>

            {showNmsOptions && (
              <div>
                <StyledLabel>{`${localized("NMS Role")} *`}</StyledLabel>
                <SearchableDropDown
                  width="100%"
                  searchPlaceholderToken={localizationKey("Select NMS role")}
                  disabled={isCopy}
                  value={nmsOption || {}}
                  options={nmsOptions}
                  onSelect={onChangeNmsOption}
                  onBlur={() => validateForm(["nmsOption"])}
                  errorMessage={validation.message.nmsOption}
                  {...(validation.message.nmsOption && { validationState: "error" })}
                  keepDropdownInView
                  activeScrollEventListener
                  useSelectStyling
                />
              </div>
            )}

            <div>
              <StyledLabel>{localized("Parent policy")}</StyledLabel>
              <SearchablePolicyDropdown
                {...{
                  value: parentPolicy,
                  searchPlaceholderToken: localizationKey("Select parent policy"),
                  onSelect: setParentPolicy,
                  defaultOptions: [getDefaultParentPolicy()],
                  disabled: isNilOrEmpty(selectedRole) || nodeRole?.value === "NMS",
                  searchParams: {
                    ...(selectedRole && { nodeClasses: selectedRole }),
                    ...(isCopy && { ids: parentPolicyId }),
                  },
                  customDropdownOptions: {
                    useSelectStyling: true,
                  },
                }}
              />
            </div>

            <Box marginTop="6px">
              <Switch
                {...{
                  checked: enabled,
                  onChange: checked => onChange("enabled", checked),
                  labelText: enabled ? localized("Policy enabled") : localized("Policy disabled"),
                }}
              />
            </Box>
          </Box>
        </Modal>
      </form>
    )
  },
)

export default CreatePolicyModal
