import { Modal, Text } from "@ninjaone/components"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import { Box } from "js/includes/components/Styled"
import {
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  showSuccessMessage,
  isAppleMobileDevice,
  runDeviceSearchToolbarAction,
} from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"
import { Radio } from "js/includes/components/NinjaReactICheck"
import { sendActionMultipleDevices } from "js/includes/common/client"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import Disclaimer from "js/includes/components/Disclaimer"

export default function EraseDeviceModal({
  nodeIds,
  unmount,
  organizationId,
  nodeClass,
  onClose,
  isMultiDevice = false,
  runnerActionName,
}) {
  const [loading, setLoading] = useMountedState(false)
  const [wipe, setWipe] = useMountedState(false)

  const handleConfirmEraseAction = async () => {
    const confirmErase = await ShowMessageDialog({
      icon: { icon: faExclamation, type: "critical" },
      title: localizationKey("Warning! You are about to erase all the information from this device"),
      MessageComponent: props => (
        <Box>
          <span>{localized("This action cannot be undone.")}</span>
          <Disclaimer {...props} />
        </Box>
      ),
      buttons: [
        { id: true, label: localizationKey("Erase"), type: "critical", validate: true },
        { id: false, label: localizationKey("Cancel") },
      ],
    })

    confirmErase && handleEraseAction()
  }

  const handleEraseAction = async () => {
    setLoading(true)

    try {
      if (runnerActionName) {
        await runDeviceSearchToolbarAction({
          action: runnerActionName,
          data: { wipeExternalStorage: isMultiDevice ? false : wipe },
        })
      } else {
        await sendActionMultipleDevices(nodeIds, {
          type: "ERASE_DEVICE",
          wipeExternalStorage: isMultiDevice ? false : wipe,
        })
      }
      showSuccessMessage(localized("The action was sent successfully"))
      onClose?.(organizationId)
      unmount()
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        reportErrorAndShowMessage(error, localizationKey("The action could not be sent"))
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      size="sm"
      titleGroup={{
        titleToken: localizationKey("Erase Device"),
      }}
      unmount={unmount}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Erase"),
          onClick: handleConfirmEraseAction,
          disabled: loading,
        },
      ]}
    >
      {isAppleMobileDevice(nodeClass) || isMultiDevice ? (
        <Text
          token={
            isMultiDevice
              ? localizationKey("Are you sure you want to erase these devices?")
              : localizationKey("Are you sure you want to erase this device?")
          }
        />
      ) : (
        <Box fontSize="14px" textAlign="left">
          <p>{localized("Select the storage that you want to wipe, if required")}</p>
          <div className="m-t-md">
            <Radio
              id="wipeExternalStorage"
              name="wipeExternalStorage"
              value={wipe}
              radioClass="iradio_square-blue"
              label={<span className="m-l-sm">{localized("Wipe External Storage")}</span>}
              onChange={e => setWipe(e.target.checked)}
            />
          </div>
        </Box>
      )}
    </Modal>
  )
}
