import { omit, propEq } from "ramda"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { RegularInfoCircleIconLight } from "@ninjaone/icons"
import { Tags, Text } from "@ninjaone/components"
import { colors } from "js/includes/common/theme"
import { Box } from "js/includes/components/Styled"
import { useMountedState } from "js/includes/common/hooks"
import StickyPopover from "js/includes/components/Popover/StickyPopover"
import { localizationKey, localized } from "js/includes/common/utils"
import { osCredentialOptions } from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/common/credentials"
import Section from "./Section"

const StyledTrigger = styled.span`
  padding-left: ${sizer(1)};
`

const getRunAsLabel = (deviceType, runAs) =>
  osCredentialOptions(deviceType)
    .find(propEq("value", runAs))
    ?.label()

const WRAPPER_TOP_MARGIN = 10

export default function AutomationDescriptionPopover({
  categoriesTags,
  description,
  deviceType,
  runAs,
  scriptParam,
  LegacySection,
}) {
  const [showPopover, setShowPopover] = useMountedState(false)
  const { labels, maxItemsDisplayed } = categoriesTags ?? {}

  const hasScriptParam = scriptParam && scriptParam.length > 0
  const runAsLabel = getRunAsLabel(deviceType, runAs)

  const trigger = (
    <StyledTrigger onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)}>
      <RegularInfoCircleIconLight size="sm" />
    </StyledTrigger>
  )

  return (
    <Box>
      {showPopover ? (
        <StickyPopover
          showPopoverArrow={false}
          style={({ defaultStyle }) => ({
            ...defaultStyle,
            padding: sizer(4),
            border: "none",
            width: 360,
            backgroundColor: "white",
            boxShadow: `0 0 12px 0 rgba(0,0,0,0.05), 0 2px 12px 0 rgba(0,0,0,0.1)`,
            pointerEvents: "none",
            zIndex: 2051,
            maxWidth: "300px",
          })}
          innerStyle={({ defaultInnerStyle }) => omit(["overflowY"], defaultInnerStyle)}
          trigger={trigger}
          customWrapperTop={wrapperBoundaries => wrapperBoundaries.top + WRAPPER_TOP_MARGIN}
        >
          <div>
            <Text color={colors.ninjaBlack} token={localizationKey("Details")} />
            {description && <Section title={localized("Automation description")} content={description} />}
            {LegacySection && <LegacySection />}
            {runAsLabel && <Section title={localized("Run as")} content={runAsLabel} />}
            {hasScriptParam && <Section title={localized("Selected Parameter")} content={scriptParam} />}
            {categoriesTags?.labels.length > 0 && (
              <Section title={localized("Categories")} content={<Tags {...{ labels, maxItemsDisplayed }} />} />
            )}
          </div>
        </StickyPopover>
      ) : (
        trigger
      )}
    </Box>
  )
}

AutomationDescriptionPopover.propTypes = {
  categoriesTags: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.object),
    maxItemsDisplayed: PropTypes.number,
  }),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  runAs: PropTypes.string,
  scriptParam: PropTypes.string,
}
