import { allPass, has } from "ramda"
import { notEqProps } from "js/includes/common/utils/ssrAndWebUtils"

export const propsValueHasChanged = (props, key, prevProps, state) => {
  const notEqProp = notEqProps(key)
  return allPass([has(key), notEqProp(prevProps), notEqProp(state)])(props)
}

export const overrideStateProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
})
