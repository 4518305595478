import { setEditingScript } from "js/state/actions/configuration/scripting/editingScript"
import {
  readFileAsBinaryString,
  getCharsetForImportFileStringContent,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { getScriptLanguageFromFileName } from "./ScriptsSubTabModel/common"

export const chooseFileToImport = () => {
  const importScriptElement = document.getElementById("importScriptsFile")

  importScriptElement.click()
}

export const importFile = async e => {
  const file = e.target.files[0]
  const {
    store: { dispatch },
  } = window
  try {
    const fileAsBinaryString = await readFileAsBinaryString(file)
    const language = getScriptLanguageFromFileName(file.name)
    const { operatingSystems } = language

    dispatch(
      setEditingScript({
        code: getCharsetForImportFileStringContent(fileAsBinaryString),
        language: language.id,
        operatingSystems,
      }),
    )
    window.location.hash = "#/editor/script/import"
  } catch (error) {
    reportErrorAndShowMessage(error)
  }
}
