import { ninjaReportError } from "js/includes/common/utils"

let recaptchaReady = false

function getRecaptchaSiteKey() {
  return window.store.getState().application.recaptchaPublicKey
}

export function addRecaptchaListener() {
  if (window.grecaptcha) return

  const script = document.createElement("script")
  script.src = `https://www.recaptcha.net/recaptcha/enterprise.js?render=${getRecaptchaSiteKey()}`
  script.onload = () => {
    window.grecaptcha.enterprise.ready(() => {
      recaptchaReady = true
    })
  }
  document.head.appendChild(script)
}

export function getRecaptchaToken({ action }) {
  if (window.recaptchaSignature) return window.recaptchaSignature.toString() // Recaptcha token key override, use of signature to bypass.

  if (isRecaptchaReady()) {
    return window.grecaptcha.enterprise.execute(getRecaptchaSiteKey(), { action })
  } else {
    ninjaReportError("recaptcha error: grecaptcha script not avialable")
  }
}

export function isRecaptchaReady() {
  return recaptchaReady
}
