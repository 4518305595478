import React, { useState } from "react"
import { propEq } from "ramda"
import Select from "react-select"
import { Col, ControlLabel, FormGroup, FormControl, Row } from "react-bootstrap"
import Modal from "js/includes/components/Modal"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { getExpirationOptions } from "js/state/actions/remoteSupport"

export default function SupportFolderModal({ unmount, values, handleSave, nameCollisionError }) {
  const options = getExpirationOptions()
  const isEditMode = !!values?.id
  const [name, setName] = useState(values?.name ?? "")
  const [description, setDescription] = useState(values?.description ?? "")
  const [expirationTTL, setExpirationTTL] = useState(values?.expirationTTL ?? 240)
  const [nameError, setNameError] = useState(nameCollisionError ?? false)
  const [saving, setSaving] = useState(false)

  async function handleValidateAndSave() {
    if (!name.trim()) {
      setNameError(true)
    } else {
      setSaving(true)
      setNameError(false)

      try {
        await handleSave({
          id: values.id,
          name: name.trim(),
          description: description.trim(),
          expirationTTL,
        })

        unmount()
      } catch (error) {
        setSaving(false)
        setNameError(true)
      }
    }
  }

  return (
    <Modal
      overflow
      title={isEditMode ? localizationKey("Edit Remote Support Folder") : localizationKey("Add Remote Support Folder")}
      saveText={
        values?.id
          ? nameCollisionError
            ? localizationKey("Upgrade")
            : localizationKey("Apply")
          : localizationKey("Add")
      }
      closeText={localizationKey("Cancel")}
      loading={saving}
      save={handleValidateAndSave}
      close={unmount}
    >
      <FormGroup validationState={nameError ? "error" : null}>
        <Row className="display-flex align-items-center m-b">
          <Col componentClass={ControlLabel} xs={4} className="text-left">
            {localized("Name")} *
          </Col>
          <Col xs={8}>
            <FormControl
              componentClass="input"
              maxLength={100}
              value={name}
              onChange={e => {
                setNameError(false)
                setName(e?.target?.value ?? "")
              }}
              className={`${nameError ? "has-error" : ""}`}
            />
            {nameCollisionError && nameError && (
              <em className="invalid">
                {localized("An Organization with this name already exists. Please choose a different name.")}
              </em>
            )}
          </Col>
        </Row>
      </FormGroup>

      {!nameCollisionError && (
        <>
          <Row className="display-flex align-items-center m-b">
            <Col componentClass={ControlLabel} xs={4} className="text-left">
              {localized("Description")}
            </Col>
            <Col xs={8}>
              <FormControl
                componentClass="input"
                maxLength={100}
                value={description}
                onChange={e => setDescription(e?.target?.value ?? "")}
              />
            </Col>
          </Row>
          <Row className="display-flex align-items-center m-b">
            <Col componentClass={ControlLabel} xs={4} className="text-left">
              {localized("Expiration")}
            </Col>
            <Col xs={8}>
              <Select
                value={options.find(propEq("value", expirationTTL))}
                options={options}
                onChange={selected => setExpirationTTL(selected.value)}
              />
            </Col>
          </Row>
        </>
      )}
    </Modal>
  )
}
