import { compose, map, omit, indexBy, prop, evolve, toPairs, curry, cond, propEq } from "ramda"

export const removeWebProperties = json => {
  for (var key in json) {
    if (!!json[key] && typeof json[key] === "object") {
      if (key === "inheritance") {
        delete json[key].parentPolicySettings
      } else {
        removeWebProperties(json[key])
      }
    } else if (key === "classname") {
      delete json[key]
    }
  }
}

const toObject = compose(map(omit(["identifier"])), indexBy(prop("identifier")), prop("field"))

const toArray = compose(
  map(([identifier, obj]) => {
    return { ...obj, identifier }
  }),
  toPairs,
  prop("field"),
)

const policyFieldConverter = curry((mode, field) =>
  cond([
    [propEq("mode", "server-to-web"), toArray],
    [propEq("mode", "web-to-server"), toObject],
  ])({ mode, field }),
)

const convert = curry((conversionType, policy) => {
  const _policyFieldConverter = policyFieldConverter(conversionType)
  return evolve({
    conditions: _policyFieldConverter,
    actionsetSchedules: _policyFieldConverter,
    monitors: _policyFieldConverter,
    antivirus: {
      scanConfig: {
        scanSetting: {
          scanExceptions: _policyFieldConverter,
        },
      },
    },
    backup: {
      backupPlans: _policyFieldConverter,
    },
  })(policy)
})

export const convertToServerFormat = convert("web-to-server")
export const convertToClientFormat = convert("server-to-web")
