import tokens from "@ninjaone/tokens"

export const shouldIgnoreProps = prop => !["descriptionMode", "labelText", "size", "orientation"].includes(prop)

export const getRadioAriaLabel = ({ label, description }) => (description ? `${label} - ${description}` : label)

export const getSize = ({ size }) => {
  switch (size) {
    case "md":
      return {
        width: "268px",
        height: "237px",
      }

    case "lg":
      return {
        width: "334px",
        height: "220px",
      }

    case "sm":
      return {
        width: "250px",
        height: "204px",
      }

    default:
      return {
        width: "218px",
        height: "121px",
      }
  }
}

export const getRadioCircleStyles = ({ theme }) => {
  return `
    background-color: transparent;
    border-radius: ${tokens.borderRadius[4]};
    border: 1px solid ${theme.colorBorderStrong};

    width: 16px;
    min-width: 16px;

    height: 16px;
    min-height: 16px;

    // styling the selected radio card circle for the disabled state
    &:has([data-state="checked"]):not(:has([data-disabled])) {
      border: 1px solid ${theme.colorBorderDecorativeStrong};
    }

    // styling the radio card circle for the disabled state
    &:has([data-disabled]) {
      border: 1px solid ${theme.colorBorderWeak};
    }

    // styling the radio circle for the disabled state
    &[data-disabled] {
      border: 1px solid ${theme.colorBorderWeak};

      &[data-state="unchecked"] {
        background: ${theme.colorBackgroundInputDisabled};
      }
    }
  `
}
