import PropTypes from "prop-types"
import { connect } from "react-redux"
import * as ninjaIcons from "@ninjaone/icons"
import { ImageResource } from "@ninjaone/components/src/DataTable/Components/ImageResource"
import {
  LEGACY_INSTALL_RUN_SCRIPTS_UIDS,
  INSTALL_MAC_SCRIPT_UID,
  INSTALL_WINDOWS_SCRIPT_UID,
  RUN_SCRIPT_UID,
} from "js/state/selectors/scripting/scriptsSelectorList"

export const findAutomationIconName = (scriptingLanguages, language, uid) => {
  if (LEGACY_INSTALL_RUN_SCRIPTS_UIDS.includes(uid)) {
    return (
      {
        [INSTALL_MAC_SCRIPT_UID]: "InstallApplicationAutomationIcon",
        [INSTALL_WINDOWS_SCRIPT_UID]: "InstallApplicationAutomationIcon",
        [RUN_SCRIPT_UID]: "RunApplicationAutomationIcon",
      }[uid] || "ScriptAutomationIcon"
    )
  }
  const icon = scriptingLanguages.find(lng => lng.id === language)?.icon || {}
  return icon.name || "ScriptAutomationIcon"
}

function AutomationIcon({ automation, scriptingLanguages }) {
  const { language, uid } = automation
  const name = findAutomationIconName(scriptingLanguages, language, uid)
  const Icon = ninjaIcons[name] ?? null

  if (automation.binaryInstallSettings?.customIconAttachmentId)
    return (
      <ImageResource
        size={32}
        attachmentId={automation.binaryInstallSettings.customIconAttachmentId}
        defaultIconName="ScriptAutomationIcon"
      />
    )
  else return <Icon />
}

AutomationIcon.propTypes = {
  automation: PropTypes.object.isRequired,
}

export default connect(
  state => ({
    scriptingLanguages: state.scripting.languages,
  }),
  {},
)(AutomationIcon)
