import { memo } from "react"
import { sizer } from "@ninjaone/utils"
import { Box } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"
import { StyledLabel } from "js/includes/components/DeviceModal/style"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const ModalLabeledSelector = memo(({ token, disabled, onChange, selected, options, testId, required = false }) => {
  const selectorLabel = `${localized(token)}${required ? " *" : ""}`

  return (
    <Box marginTop={sizer(3)} data-testid={`${testId}-box`}>
      <StyledLabel id={`modal-${testId}-selector`} data-testid={`${testId}-label`}>
        {selectorLabel}
      </StyledLabel>
      <SearchableDropDown
        {...{
          disabled,
          onSelect: onChange,
          options,
          value: selected,
          width: "100%",
          minHeight: "42px",
          rowHeight: 40,
          ariaAttributes: { "aria-labelledby": `modal-${testId}-selector` },
        }}
      />
    </Box>
  )
})

export default ModalLabeledSelector
