import { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"

import {
  CalendarCheckIconLight,
  ChevronRightIconLight,
  CloudIconLight,
  DesktopIconLight,
  FileChartPieIconLight,
  GlobeIconLight,
  MemoCircleCheckIconLight,
  MobileIconLight,
  ServerIconLight,
  SiteMapIconLight,
  TableColumnsIconLight,
  TicketIconLight,
  UserHelmetSafetyIconLight,
  UserIconLight,
  RouterIconLight,
} from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"

import showModal from "js/includes/common/services/showModal"
import { GuideTooltip, IconButton, Text } from "@ninjaone/components"
import {
  isFeatureEnabled,
  isUserAllowedToCreateTicket,
  localizationKey,
  localized,
  user,
  isMDMFeatureEnabled,
  isAppleMDMFeatureEnabled,
  isAndroidMDMFeatureEnabled,
} from "js/includes/common/utils"
import AddComputerModalLegacy from "js/includes/components/DeviceModal/AddComputerModal/AddComputerModalLegacy"
import AddComputerModalTokenized from "js/includes/components/DeviceModal/AddComputerModal/AddComputerModalTokenized"
import AddMobileDeviceModal from "js/includes/components/DeviceModal/AddMobileDeviceModal"
import AddVMModal from "js/includes/components/DeviceModal/AddVMModal"
import AddNMSModal from "js/includes/components/DeviceModal/AddNMSModal"
import { Box, Flex } from "js/includes/components/Styled"
import { showCreateTicketModal } from "js/includes/ticketing/CreateTicketModal"
import { getNewTicketPagePath } from "js/includes/ticketing/shared/utils/tickets"

import { closeQuickMenu } from "js/state/actions/general/quickMenus"
import { closeGuideTooltip } from "js/state/actions/general/guideTooltips"
import CustomDropdown from "./CustomDropdown"
import { NetworkDiscoveryModal } from "js/includes/networkDiscovery"
import { NetworkDiscoveryProvider } from "js/includes/networkDiscovery/NetworkDiscoveryContext"
import CreatePolicyModal from "js/includes/editors/Policy/Models/General/CreatePolicyModal"

const RowItem = ({
  token,
  descriptionToken,
  icon,
  hasSubMenu = false,
  addMarginBottom = false,
  addMarginTop = false,
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      cursor="pointer"
      padding={`6px ${sizer(3)}`}
      marginBottom={sizer(addMarginBottom ? 1 : 0)}
      marginTop={sizer(addMarginTop ? 1 : 0)}
      minWidth={220}
    >
      <Flex alignItems="center" marginRight={sizer(2)}>
        <Flex alignItems="center" marginRight={sizer(!!descriptionToken ? 3 : 2)}>
          {icon}
        </Flex>
        <Box>
          <Text {...{ token }} size="sm" />
          {descriptionToken && <Text {...{ token: descriptionToken, color: "colorTextWeakest", size: "xs" }} />}
        </Box>
      </Flex>
      {hasSubMenu && <ChevronRightIconLight size="sm" />}
    </Flex>
  )
}

const openAddComputerModal = () =>
  showModal(isFeatureEnabled("agent_tokenization") ? <AddComputerModalTokenized /> : <AddComputerModalLegacy />)
const openAddMobileDeviceModal = () =>
  showModal(<AddMobileDeviceModal />, { withProvider: true, withReactRouter: true })
const openAddVMModal = () => showModal(<AddVMModal />, { withProvider: true })
const openAddNMSModal = () => showModal(<AddNMSModal />)
const openNetworkDiscoveryModal = () =>
  showModal(
    <NetworkDiscoveryProvider>
      <NetworkDiscoveryModal />
    </NetworkDiscoveryProvider>,
    { withProvider: true },
  )

const openAddPolicyModal = () => showModal(<CreatePolicyModal />)

const getMobileDeviceDescriptionToken = () => {
  switch (true) {
    case isAppleMDMFeatureEnabled() && isAndroidMDMFeatureEnabled():
      return "Apple, Android"
    case isAppleMDMFeatureEnabled():
      return "Apple"
    case isAndroidMDMFeatureEnabled():
      return "Android"
    default:
      return ""
  }
}

const getCategories = (category, setCategory, ticketingEnabledFromSettings) => {
  const showTicketing = ticketingEnabledFromSettings && isUserAllowedToCreateTicket()

  switch (category) {
    case "devices":
      return [
        {
          ButtonComponent: () => <IconButton size="sm" icon={faArrowLeft} />,
          labelToken: localizationKey("Add device"),
          action: () => setCategory(""),
          preventClose: true,
        },
        {
          LabelComponent: () => (
            <RowItem
              {...{
                token: localizationKey("Computer"),
                descriptionToken: localizationKey("Windows, Linux, Mac"),
                icon: <DesktopIconLight fontSize={sizer(5)} />,
              }}
            />
          ),
          action: openAddComputerModal,
        },
        {
          LabelComponent: () => (
            <RowItem
              {...{
                token: localizationKey("Mobile device"),
                descriptionToken: getMobileDeviceDescriptionToken(),
                icon: <MobileIconLight fontSize={sizer(5)} />,
              }}
            />
          ),
          action: openAddMobileDeviceModal,
          show: isMDMFeatureEnabled(),
        },
        {
          LabelComponent: () => (
            <RowItem
              {...{
                token: localizationKey("Virtual infrastructure"),
                descriptionToken: localizationKey("HyperV, VMWare"),
                // TODO: switch this icon to FA5 server icon
                icon: <ServerIconLight fontSize={sizer(5)} />,
              }}
            />
          ),
          action: openAddVMModal,
        },
        {
          LabelComponent: () => (
            <RowItem
              {...{
                token: "globalOptions.cloudMonitor",
                descriptionToken: "globalOptions.cloudMonitorTypes",
                icon: <CloudIconLight fontSize={sizer(5)} />,
              }}
            />
          ),
          action: () => (window.location.hash = "#/editor/cloudMonitor"),
        },
        {
          LabelComponent: () => (
            <RowItem
              {...{
                token: localizationKey("NMS"),
                descriptionToken: localizationKey("Network Management System"),
                icon: <GlobeIconLight fontSize={sizer(5)} />,
              }}
            />
          ),
          action: openAddNMSModal,
          show: !(isFeatureEnabled("nms_network_discovery") && isFeatureEnabled("custom_snmp")),
        },
        {
          LabelComponent: () => (
            <RowItem
              {...{
                token: localizationKey("Run Ad Hoc Network Discovery"),
                descriptionToken: localizationKey("Network management system"),
                icon: <RouterIconLight fontSize={sizer(5)} />,
              }}
            />
          ),
          action: openNetworkDiscoveryModal,
          show: isFeatureEnabled("nms_network_discovery"),
        },
      ]
    case "user":
      return [
        {
          ButtonComponent: () => <IconButton size="sm" icon={faArrowLeft} />,
          labelToken: localizationKey("Add user"),
          action: () => setCategory(""),
          preventClose: true,
        },
        {
          LabelComponent: () => (
            <RowItem
              {...{
                token: localizationKey("Technician"),
                descriptionToken: localizationKey("Add a technician to your team"),
                icon: <UserHelmetSafetyIconLight fontSize={sizer(5)} />,
              }}
            />
          ),
          action: () => (window.location.hash = "#/editor/user"),
          show: user("canCreateUsers"),
        },
        {
          LabelComponent: () => (
            <RowItem
              {...{
                token: localizationKey("End user"),
                descriptionToken: localizationKey("Add an end user for a device"),
                icon: <UserIconLight fontSize={sizer(5)} />,
              }}
            />
          ),
          action: () => (window.location.hash = "#/editor/endUser?showOrganizationPicker=true"),
          show: user("canCRUDEndUserSharing"),
        },
      ]
    default:
      return [
        {
          TitleComponent: () => (
            <Box margin={sizer(2, 3, 2)}>
              <Text token={localizationKey("Add item")} size="sm" color="colorTextStrong" bold />
            </Box>
          ),
        },
        {
          LabelComponent: () => <RowItem {...{ token: localizationKey("Ticket"), icon: <TicketIconLight /> }} />,
          action: () => (window.location.hash = getNewTicketPagePath({ withHashTag: true })),
          show: showTicketing,
        },
        {
          LabelComponent: () => (
            <RowItem {...{ token: localizationKey("Ticket from template"), icon: <TicketIconLight /> }} />
          ),
          action: showCreateTicketModal,
          show: showTicketing,
        },
        {
          LabelComponent: () => (
            <RowItem
              {...{ token: localizationKey("Ticket board"), icon: <TableColumnsIconLight />, addMarginBottom: true }}
            />
          ),
          // TODO: open create new ticketing board
          action: () => (window.location.hash = "#/administration/apps/ticketing"),
          show: showTicketing,

          splitAfter: true,
        },
        {
          LabelComponent: () => (
            <RowItem
              {...{
                token: localizationKey("Device"),
                icon: <DesktopIconLight />,
                addMarginTop: showTicketing,
                hasSubMenu: true,
              }}
            />
          ),
          action: () => setCategory("devices"),
          show: user("canCreateDevices"),
          preventClose: true,
        },
        {
          LabelComponent: () => (
            <RowItem {...{ token: localizationKey("User"), icon: <UserIconLight />, hasSubMenu: true }} />
          ),
          action: () => {
            setCategory("user")
          },
          show: user("canCreateUsers") || user("canCRUDEndUserSharing"),
          preventClose: true,
        },
        {
          LabelComponent: () => <RowItem {...{ token: localizationKey("Report"), icon: <FileChartPieIconLight /> }} />,
          action: () => (window.location.hash = "#/reporting/reports"),
          show: user("canManageReports") && user("canViewAtLeastOneOrganization"),
        },
        {
          LabelComponent: () => <RowItem {...{ token: localizationKey("Organization"), icon: <SiteMapIconLight /> }} />,
          action: () => (window.location.hash = "#/editor/customer"),
          show: user("canCreateCustomers"),
        },
        {
          LabelComponent: () => (
            <RowItem {...{ token: localizationKey("Policy"), icon: <MemoCircleCheckIconLight /> }} />
          ),
          action: openAddPolicyModal,
          show: user("canCreatePolicies"),
        },
        {
          LabelComponent: () => (
            <RowItem {...{ token: localizationKey("Scheduled task"), icon: <CalendarCheckIconLight /> }} />
          ),
          action: () => (window.location.hash = "#/editor/scheduledTask"),
          show: user("canCreateScheduledTasks") && user("canRunAtLeastOneAdhocScript"),
        },
      ]
  }
}

const AddDropdown = ({ ticketingEnabledFromSettings, externalRequestToOpen, showAddDeviceMenuGuideTooltip }) => {
  const [category, setCategory] = useState("")
  const [showDropdown, setShowDropdown] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (externalRequestToOpen) {
      setCategory("user")
    }
  }, [externalRequestToOpen])

  const closeAddDeviceTooltip = () => dispatch(closeGuideTooltip("addDeviceMenu"))
  const tooltipTitle = localized("Task: Add a device")
  const tooltipDescription = localized(
    "Click Device from the dropdown and select the device type you would like to deploy Ninja to.",
  )

  return (
    <CustomDropdown
      {...{
        DropdownButton: (
          <>
            <GuideTooltip
              {...{
                position: "bottom",
                align: "end",
                width: 400,
                title: tooltipTitle,
                iconName: "BulbIconLight",
                textRenderer: () => <p>{tooltipDescription}</p>,
                anchorRenderer: () => (
                  <IconButton
                    {...{
                      icon: faPlus,
                      ...(showAddDeviceMenuGuideTooltip && { "aria-label": localized("Add") }),
                      ...(!showAddDeviceMenuGuideTooltip && { tooltip: showDropdown ? null : localized("Add") }),
                      handleClick: () => {
                        setShowDropdown(prevState => !prevState)
                        showAddDeviceMenuGuideTooltip && closeAddDeviceTooltip()
                      },
                      active: showDropdown,
                      size: "lg",
                    }}
                  />
                ),
                onClose: closeAddDeviceTooltip,
                usesPortal: false,
                alignOffset: -10,
                sideOffset: 10,
                open: showAddDeviceMenuGuideTooltip,
              }}
            />
          </>
        ),
        options: getCategories(category, setCategory, ticketingEnabledFromSettings).filter(
          ({ show = true }) => show === true,
        ),
        show: showDropdown || externalRequestToOpen,
        closeDropdown: () => {
          if (externalRequestToOpen) {
            dispatch(closeQuickMenu("add"))
          }
          setShowDropdown(false)
          setCategory("")
        },
        reset: () => setCategory(""),
        category,
      }}
    />
  )
}
export default connect(
  ({
    ticketing: {
      configurations: { enabled: ticketingEnabledFromSettings },
    },
    general: {
      quickMenus: { add: externalRequestToOpen },
      guideTooltips,
    },
  }) => ({
    externalRequestToOpen,
    ticketingEnabledFromSettings,
    showAddDeviceMenuGuideTooltip: guideTooltips.addDeviceMenu,
  }),
)(AddDropdown)
