import { PureComponent } from "react"
import styled from "@emotion/styled"
import { F, propEq } from "ramda"
import { faEdit, faTimes, faGripLines } from "@fortawesome/pro-solid-svg-icons"
import { Button, Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { VARIANTS } from "@ninjaone/components/src/Button"
import { colors } from "js/includes/common/theme"
import { localized, localizationKey } from "js/includes/common/utils"
import ScriptTableRow from "js/includes/components/scripting/ScriptsTable/ScriptTableRow"
import { noRowsRenderer, headerRenderer } from "js/includes/common/utils/react-virtualized"
import Table from "js/includes/components/Table"
import { Flex, Box, StyledFontAwesomeIcon } from "js/includes/components/Styled"
import { StyledButton } from "js/includes/components/Styled/Form"
import DragableList from "js/includes/components/DragableList"
import AutomationIcon from "js/includes/components/scripting/AutomationIcon"
import AutomationDescriptionPopover from "js/includes/components/scripting/ScriptsTable/AutomationDescription/AutomationDescriptionPopover"
import {
  INSTALL_MAC_SCRIPT_UID,
  INSTALL_WINDOWS_SCRIPT_UID,
  LEGACY_INSTALL_RUN_SCRIPTS_UIDS,
} from "js/state/selectors/scripting/scriptsSelectorList"
import LegacyScriptsDescriptionPopoverSection from "./AutomationDescription/LegacyScriptsDescriptionPopoverSection"

const StyledDraggableListContainer = styled.div`
  flex: auto;
  max-height: ${({ maxHeight }) => maxHeight ?? "initial"};
  ${({ height }) => height && `height: ${height};`}
  padding: ${sizer(2)};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.black["005"]};
  overflow-y: auto;
  cursor: grab;
`

const StyledDraggableAutomations = styled(StyledDraggableListContainer)`
  > div {
    margin-bottom: ${sizer(2)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const StyledDraggableItem = styled(Box)`
  &.dragging {
    cursor: grabbing;
  }

  div.actions {
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    div.actions {
      visibility: visible;
      opacity: 1;
    }
  }
`

export default class ScriptsTable extends PureComponent {
  static defaultProps = {
    isEditingEnabled: F,
    isDeletingEnabled: F,
    isCopyingEnabled: F,
    isEditableRows: false,
  }

  onRowClick = (rowData, e) => {
    e.preventDefault()
    this.props.onRowClick(rowData)
  }

  onDeleteClick = (rowData, e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onDeleteClick(rowData)
  }

  onCopyClick = (rowData, e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onCopyClick(rowData)
  }

  tableColumns = [
    {
      dataKey: "name",
      label: "general.name",
      width: 1,
      flexGrow: 1.5,
      headerRenderer,
      cellRenderer: ({ rowData }) => (
        <div className="border info text-ellipsis overflow-hidden">
          <ScriptTableRow script={rowData} showSimplifiedLayout={this.props.showSimplifiedLayout} />
        </div>
      ),
    },
    ...(this.props.showDescription
      ? [
          {
            dataKey: "description",
            label: "general.description",
            width: 1,
            flexGrow: 2,
            headerRenderer,
            cellRenderer: ({ rowData }) => <span className="two-rows-ellipsis">{rowData.description}</span>,
          },
        ]
      : []),
    ...(this.props.isEditableRows
      ? [
          {
            dataKey: "actions",
            width: 100,
            headerRenderer,
            className: "overflow-visible-important",
            cellRenderer: ({ rowData }) => {
              return (
                <span className="col-xs-3 action-links">
                  {this.props.isEditingEnabled(rowData) && (
                    <button className="btn-link" onClick={e => this.onRowClick(rowData, e)}>
                      {localized("general.edit")}
                    </button>
                  )}
                  {this.props.isCopyingEnabled(rowData) && (
                    <StyledButton type="button" marginRight={2} onClick={e => this.onCopyClick(rowData, e)}>
                      {localized("general.copy")}
                    </StyledButton>
                  )}
                  {this.props.isDeletingEnabled(rowData) && (
                    <button className="btn-link text-danger" onClick={e => this.onDeleteClick(rowData, e)}>
                      {localized("general.delete")}
                    </button>
                  )}
                </span>
              )
            },
          },
        ]
      : []),
  ]

  render() {
    const {
      scriptsList,
      sort,
      sortBy,
      sortDirection,
      disableHeader,
      noRowsLabel,
      showSimplifiedLayout,
      isDraggable,
      onOrderChange,
      selectedContextDeviceType,
    } = this.props

    const scriptsNoRowsRenderer = noRowsRenderer(
      noRowsLabel ?? localizationKey("No automations to display"),
      false,
      "no-padding",
      true,
    )

    const getScriptingLanguage = ({ language, automation, isLegacy }) => {
      const { scriptingLanguages } = this.props
      if (isLegacy) {
        if ([INSTALL_MAC_SCRIPT_UID, INSTALL_WINDOWS_SCRIPT_UID].includes(automation.uid)) {
          return scriptingLanguages.find(propEq("id", "binary_install")).value
        }
        return scriptingLanguages.find(propEq("id", "binary_run")).value
      }
      return scriptingLanguages?.find(l => l.id === language)?.value || ""
    }

    return (
      <Flex height="100%">
        {!isDraggable ? (
          <Table
            {...{
              list: scriptsList,
              columns: this.tableColumns,
              disableHeader,
              sort,
              sortBy,
              sortDirection,
              headerHeight: 35,
              rowHeight: showSimplifiedLayout ? 35 : 65,
              noRowsRenderer: scriptsNoRowsRenderer,
              rowClassName: "horizontal-tile flex-tile overflow-visible-important",
              className: "horizontal-tiles list-group scripts-table",
              onRowClick: ({ event, rowData }) => this.onRowClick(rowData, event),
            }}
          />
        ) : scriptsList.length === 0 ? (
          <Box flex="auto">{scriptsNoRowsRenderer()}</Box>
        ) : (
          <StyledDraggableAutomations maxHeight={300}>
            <DragableList
              elements={scriptsList}
              keyProp="id"
              renderElement={(automation, removeSection, isDragging) => {
                const {
                  name,
                  description,
                  deviceType,
                  operatingSystems,
                  categoriesTags,
                  language,
                  parameters,
                  generateNameWhenSelected,
                  generateDescriptionWhenSelected,
                } = automation
                const { scriptParam, runAs } = parameters || {}
                const isLegacy = LEGACY_INSTALL_RUN_SCRIPTS_UIDS.includes(automation.uid)
                const osLabel = selectedContextDeviceType ? selectedContextDeviceType : operatingSystems[0]
                const secondaryOsLabel =
                  operatingSystems.length > 1 && !selectedContextDeviceType ? `, ${operatingSystems[1]}` : ""
                return (
                  <StyledDraggableItem
                    className={isDragging ? "dragging" : ""}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    backgroundColor="white"
                    border={`solid 1px ${colors.ninjaLight}`}
                    padding={sizer(2)}
                    borderRadius={sizer(1)}
                  >
                    <Flex alignItems="center" minWidth={0}>
                      <StyledFontAwesomeIcon
                        marginRight={2}
                        marginLeft={1}
                        icon={faGripLines}
                        color={colors.ninjaBlack}
                      />
                      <Box minWidth="fit-content">
                        <AutomationIcon {...{ automation }} />
                      </Box>
                      <Box marginLeft={2} textAlign="left" minWidth={0}>
                        <Text size="sm" bold color={colors.ninjaBlack} textWrap textWrapLineLimit={1}>
                          {generateNameWhenSelected?.(parameters) || name}
                        </Text>
                        <Flex alignItems="center">
                          <Text size="xs" color={colors.ninjaMedium} textWrap textWrapLineLimit={1}>
                            {`${getScriptingLanguage({
                              language,
                              automation,
                              isLegacy,
                            })} ${generateDescriptionWhenSelected?.(parameters) || ""}(${osLabel}${secondaryOsLabel})`}
                          </Text>
                          <AutomationDescriptionPopover
                            {...{
                              categoriesTags,
                              description,
                              deviceType,
                              runAs,
                              scriptParam,
                              ...(isLegacy && {
                                LegacySection: () => <LegacyScriptsDescriptionPopoverSection {...{ automation }} />,
                              }),
                            }}
                          />
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex className="actions">
                      {this.props.isEditingEnabled(automation) && !isLegacy && (
                        <Button variant={VARIANTS.ICON} onClick={e => this.onRowClick(automation, e)}>
                          <StyledFontAwesomeIcon icon={faEdit} />
                        </Button>
                      )}
                      {this.props.isDeletingEnabled(automation) && (
                        <Button variant={VARIANTS.ICON} onClick={e => this.onDeleteClick(automation, e)}>
                          <StyledFontAwesomeIcon icon={faTimes} />
                        </Button>
                      )}
                    </Flex>
                  </StyledDraggableItem>
                )
              }}
              onChange={onOrderChange}
            />
          </StyledDraggableAutomations>
        )}
      </Flex>
    )
  }
}
