import { compose, map, split, reject, filter, replace, find, mergeRight, propEq, any, identity } from "ramda"
import {
  validations,
  isValidIpAddress,
  isValidCIDRBlock,
  isValidIpRange,
  sortByFieldNameCaseInsensitive,
  DOJO_ARTICLES_PREFIX,
  localizeMappingName,
} from "js/includes/common/utils"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

export const steps = ["SETUP", "DISCOVERY", "REVIEW"]
export const getNetworkDiscoveryDojoLink = () => `${DOJO_ARTICLES_PREFIX}/17373484004621`

export const splitIpTargets = compose(
  filter(ip => ip),
  split(/[,\n\r]+/g),
  replace(/[ ]+/g, ""),
)

const required = {
  success: false,
  message: localized("Required"),
}

const successful = {
  success: true,
  message: "",
}

export const networkDiscoveryValidations = {
  org: org => validations.required(org),
  location: (location, { org }) => {
    if (org && !location) return required
    return successful
  },
  networkProbe: (networkProbe, { location }) => {
    if (location && !networkProbe) return required
    return successful
  },
  ipTargets: ipTargets => {
    if (!ipTargets.trim().length) return required

    const ips = splitIpTargets(ipTargets)
    const invalidIps = reject(ip => isValidIpAddress(ip) || isValidCIDRBlock(ip) || isValidIpRange(ip))(ips)

    if (invalidIps.length) {
      return {
        message: `${localized("Invalid IP(s): ")}${invalidIps.join(", ")}`,
        success: false,
      }
    }
    return successful
  },
  snmpCredOne: (snmpCredOne, { org }) => {
    if (org && !snmpCredOne) return required
    return successful
  },
}

export const advancedSettingsValidations = {
  pingTimeoutMS: pingTimeoutMS => {
    if (pingTimeoutMS < 1 || pingTimeoutMS > 1000)
      return { success: false, message: localized("Must be between 1 and 1000") }
    return successful
  },
  pingPacketTTL: pingPacketTTL => {
    if (pingPacketTTL < 1 || pingPacketTTL > 255)
      return { success: false, message: localized("Must be between 1 and 255") }
    return successful
  },
  pingsPerNode: pingsPerNode => {
    if (pingsPerNode < 1 || pingsPerNode > 5) return { success: false, message: localized("Must be between 1 and 5") }
    return successful
  },
  delayBetweenPingsMS: delayBetweenPingsMS => {
    if (delayBetweenPingsMS < 1 || delayBetweenPingsMS > 1000)
      return { success: false, message: localized("Must be between 1 and 1000") }
    return successful
  },
}

export const createSelectOptions = compose(
  options => sortByFieldNameCaseInsensitive("label", "ASC", options),
  map(({ id: value, name: label, ...rest }) => ({ ...rest, value, label })),
)

export const formatNodeRoleSelectOptions = compose(
  options => sortByFieldNameCaseInsensitive("labelText", "ASC", options),
  map(({ name, ...rest }) => {
    return { ...rest, value: name, labelText: localizeMappingName(name) }
  }),
  reject(({ name }) => name === "NMS_NETWORK_MANAGEMENT_AGENT"),
)

export const networkDiscoveryDefaults = {
  org: null,
  location: null,
  networkProbe: null,
  ipTargets: "",
  snmpCredOne: null,
  protocolOne: null,
  snmpCredTwo: null,
  protocolTwo: null,
  telnetSshCred: null,
  pingTimeoutMS: 500,
  pingPacketTTL: 127,
  pingsPerNode: 2,
  delayBetweenPingsMS: 300,
  credentials: [],
  networkProbes: [],
  locations: [],
}

export const CHANNEL_NAME = `nm.nms-discovery`

export const getCurrentSubscription = channelName => {
  return window.wamp.globalSubscriptions.find(({ topic }) => topic.includes(channelName))
}

export const mergeDeviceDataFromDelegate = (discoveredDevices, devices) => {
  const devicesCopy = [...devices]
  discoveredDevices.forEach(discoveredDevice => {
    const deviceToUpdateIndex = devices.findIndex(({ ip }) => ip === discoveredDevice.ip)
    if (deviceToUpdateIndex === -1) {
      devicesCopy.push(discoveredDevice)
    } else {
      devicesCopy[deviceToUpdateIndex] = {
        ...devicesCopy[deviceToUpdateIndex],
        ...discoveredDevice,
      }
    }
  })

  return devicesCopy
}

const addFilters = device => {
  const getTypes = ({ ip, type, locationId, nmsDelegateNodeId, ...rest }) => {
    const filterTypes = [...type]
    const hasSucceededCredentials = any(identity, [
      ...(rest?.snmpv1 ? rest.snmpv1 : []),
      ...(rest?.snmpv2 ? rest.snmpv2 : []),
      ...(rest?.snmpv3 ? rest.snmpv3 : []),
      ...(rest?.telnet ? [rest.telnet] : []),
      ...(rest?.ssh ? [rest.ssh] : []),
    ])
    if (rest?.pingMS) filterTypes.push("allResponding")
    if (hasSucceededCredentials) filterTypes.push("allSnmpResponding")
    if (rest?.nodeId) filterTypes.push("existing")
    if (!Object.values(rest).length) filterTypes.push("offline")

    return filterTypes
  }

  return {
    ...device,
    type: [...getTypes(device)],
  }
}

export const mergeAdditionalDeviceData = (devices, devicesWithAdditionalData) => {
  const findMatchingDevice = ip => find(propEq("ip", ip), devicesWithAdditionalData)
  return compose(
    map(addFilters),
    map(([device, additionalData]) => mergeRight(device, additionalData)),
    map(device => [device, findMatchingDevice(device.ip)]),
  )(devices)
}

const getSnmpVersion = version => {
  switch (version) {
    case 1:
      return "V1"
    case 2:
      return "V2"
    case 3:
      return "V3"
    default:
      return ""
  }
}
const getSnmpPropertyName = version => {
  if (version === 1) {
    return "snmpv1"
  } else if (version === 2) {
    return "snmpv2"
  } else if (version === 3) {
    return "snmpv3"
  }
}

export const getSnmpCredentialCol = ({ credential, protocol, theme, cellTypes }) => ({
  Header: `SNMP ${getSnmpVersion(protocol)}`,
  id: `${getSnmpPropertyName(protocol)}-${credential.uid}`,
  accessor: rowData => (rowData?.[getSnmpPropertyName(protocol)]?.includes(credential.value) ? true : null),
  disableSortBy: true,
  tooltipToken: credential.label,
  getCellTypeProps: rowData =>
    rowData?.[getSnmpPropertyName(protocol)]?.includes?.(credential.value)
      ? {
          type: cellTypes.ICON,
          props: {
            svgIconName: "CheckIconSvg",
            fill: theme.color.success["100"],
            isCenter: true,
          },
        }
      : {
          props: {
            isCenter: true,
          },
        },
})
