import React from "react"
import { connect } from "react-redux"
import { F, always } from "ramda"
import ScriptsTable from "js/includes/components/scripting/ScriptsTable"
import { getScriptsSelectorScripts } from "js/state/selectors/scripting/scriptsSelectorList"
import { sort as _sort } from "js/state/actions/common/tableSorting"
import { setValue } from "js/state/actions/common/value"
import { ScriptParameters } from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents"
import { setToFalse } from "js/state/actions/common/boolean"
import { nanoid } from "nanoid"
import { localized } from "js/includes/common/utils"
import ParameterModal from "./ParameterModal"
import showModal from "js/includes/common/services/showModal"

//TODO: duplicate (Currently in use by policies and schedule tasks )
export const getParameterComponent = rowData =>
  (!!(rowData.language !== "native") && ScriptParameters) || rowData.parameterComponent

export const setScriptName = ({ scriptName, name }, values) =>
  scriptName ? scriptName(name, values) : `${localized("Run")} ${name}`

export default connect(
  state => ({
    scriptsList: getScriptsSelectorScripts(state),
    selectedPolicyScripts: state.scripting.scriptsSelectorList.selectedPolicyScripts,
    sortBy: state.scripting.scriptsSelectorList.sort.sortBy,
    sortDirection: state.scripting.scriptsSelectorList.sort.sortDirection,
    isEditingEnabled: F,
    isDeletingEnabled: F,
    isCopyingEnabled: F,
    getRowCursor: always("pointer"),
    onRowClick(rowData) {
      const scriptId = rowData.language === "native" ? rowData.uid : rowData.id
      const { deviceType, scriptParameters, parameters, scriptVariables, description, name, language } = rowData
      const parameterModal = {
        parameterComponent: getParameterComponent(rowData),
        description,
        scriptId,
        deviceType,
        scriptParameters,
        parameters,
        scriptVariables,
        scriptName: name,
        scriptDescription: description, //TODO check if we use this or just "description" see line 37
        scriptLanguage: language,
      }
      const applyScript = this.applyScript
      const isAdhocJob = this.isAdhocJob
      if (parameterModal.parameterComponent) {
        this.setParameterModal(parameterModal)
        showModal(<ParameterModal {...{ applyScript, isAdhocJob }} />, { withProvider: true })
      } else {
        const script = {
          id: nanoid(10),
          scriptId,
          scriptName: setScriptName(rowData),
        }
        applyScript({ type: "ADD", script })
        this.hideScriptSelector()
      }
    },
  }),
  {
    setParameterModal: setValue("parameterModal"),
    sort: _sort("scripting-scriptsSelectorList-sort"),
    hideScriptSelector: setToFalse("showScriptSelectorVisibility"),
  },
)(ScriptsTable)
