import { localized, localizationKey, fetch, fetchJson } from "js/includes/common/utils"
import { setValue } from "js/state/actions/common/value"

export const setScriptCategories = scriptCategories => ({
  type: "SET_SCRIPT_CATEGORIES",
  scriptCategories,
})

const deleteScriptCategory = scriptCategory => ({
  type: "DELETE_SCRIPT_CATEGORY",
  scriptCategory,
})

export const createScript = scriptCategory => ({
  type: "CREATE_SCRIPT_CATEGORY",
  scriptCategory,
})

export const updateScriptCategory = scriptCategory => ({
  type: "UPDATE_SCRIPT_CATEGORY",
  scriptCategory,
})

const internalScriptCategoryNamesKnownTokens = [
  localizationKey("Antivirus"),
  localizationKey("Uncategorized"),
  localizationKey("Hardware"),
  localizationKey("Maintenance"),
  localizationKey("Updates"),
  localizationKey("Mac OS Patching"),
  localizationKey("Linux OS Patching"),
]

const localizeIfInternalCategory = category =>
  category.internal && internalScriptCategoryNamesKnownTokens.includes(category.name)
    ? { ...category, name: localized(category.name) }
    : category

export const requestAllScriptCategories = () => async dispatch => {
  try {
    const categories = await fetchJson("/scripting/categories")
    const localizedCategories = categories.map(localizeIfInternalCategory)
    return dispatch(setScriptCategories(localizedCategories))
  } catch (err) {
    throw err
  }
}

export const requestCreateScriptCategory = scriptCategory => async dispatch => {
  try {
    const newScriptCategory = await fetchJson("/scripting/categories", {
      options: {
        method: "POST",
        body: JSON.stringify(scriptCategory),
      },
    })

    dispatch(createScript(newScriptCategory))
  } catch (err) {
    throw err
  }
}

export const requestUpdateScriptCategory = scriptCategory => async dispatch => {
  try {
    await fetchJson("/scripting/categories", {
      options: {
        method: "PUT",
        body: JSON.stringify(scriptCategory),
      },
    })

    dispatch(updateScriptCategory(scriptCategory))
  } catch (err) {
    throw err
  }
}

export const requestDeleteScriptCategory = scriptCategory => async dispatch => {
  try {
    const response = await fetch(`/scripting/categories/${scriptCategory.id}`, {
      options: {
        method: "DELETE",
      },
    })

    if (response.status === 204) {
      dispatch(deleteScriptCategory(scriptCategory))
      dispatch(setValue("scriptCategoryFilter")(""))
    }
  } catch (err) {
    throw err
  }
}
