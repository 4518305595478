import $ from "jquery"
import _ from "underscore"

const portmap = {
  routine: function(el, value) {
    // Routine gets called after unbind
    // eslint-disable-next-line
    if (value == undefined) {
      return
    }

    _.defer(function() {
      var size = value.length
      var index = $(el).data("index")

      var maxColumns
      if (size <= 8) {
        maxColumns = 8
      } else {
        maxColumns = 16
      }

      $(el).css("width", 100 / maxColumns + "%")

      var maxColumnsDrawn = size < maxColumns ? size : maxColumns

      if (index < maxColumnsDrawn) {
        $(el).addClass("top")
      }

      // eslint-disable-next-line
      if (size <= maxColumns || parseInt(size / maxColumns) == parseInt(index / maxColumns, 10)) {
        $(el).addClass("bottom")
      }

      // eslint-disable-next-line
      if (index % maxColumns == 0) {
        $(el).addClass("left")
      }

      // eslint-disable-next-line
      if ((index + 1) % maxColumnsDrawn == 0) {
        $(el).addClass("right")
      }

      // eslint-disable-next-line
      if (index + 1 == size && size > maxColumns) {
        var emptyColumns = maxColumns - ((index + 1) % maxColumns)
        var clone
        while (emptyColumns-- > 0) {
          $(el).removeClass("right")
          clone = $(el).clone()
          clone.removeClass("left")
          clone.removeAttr("rel") // get rid of tooltips and popovers
          clone
            .find(".status")
            .removeClass()
            .addClass("status")
            .text(" ")
          $(el)
            .parent()
            .append(clone)
        }

        if (clone) {
          clone.addClass("right")
        }
      }
    })
  },

  bind: function(el, value) {},

  unbind: function(el) {},
}

export default portmap
