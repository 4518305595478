import { getLocalStorageParsedObject } from "@ninjaone/webapp/src/js/includes/common/utils"

export const getLocalStorageKeys = tableId => ({
  initialSortBy: `${tableId}.settings.initialSortBy`,
  visibleColumnsIds: `${tableId}.settings.visibleColumnsIds`,
  autoRefreshEnabled: `${tableId}.settings.autoRefreshEnabled`,
})

export const getLocalStorageUserSettings = tableId => ({
  initialSortBy: getLocalStorageParsedObject(getLocalStorageKeys(tableId).initialSortBy),
  visibleColumnsIds: getLocalStorageParsedObject(getLocalStorageKeys(tableId).visibleColumnsIds),
})
