import { compose, groupBy, prop, sortBy } from "ramda"
import { AppleIosIconSvg } from "@ninjaone/icons"
import {
  faBook,
  faBriefcase,
  faFolder,
  faGear,
  faLocationDot,
  faSitemap,
  faTableColumns,
  faTicket,
  faUser,
  faUserHelmetSafety,
} from "@fortawesome/pro-light-svg-icons"
import Fuse from "fuse.js"
import { fetchDevicesByIds, handleQuerySearch } from "js/includes/common/client"
import {
  arrayToMapWithKey,
  getIcon,
  getOverviewLink,
  getPolicyRoute,
  ninjaReportError,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { getFilterOptions } from "./common"
import { getKnowledgeBasePathItems } from "js/includes/common/client/knowledgebase"

export const getInputHasActualValue = input => !!input.trim()?.length

export const getGlobalSearchPageUrl = ({ searchValue, searchFilter, includeOrigin = true }) =>
  `${includeOrigin ? `${window.location.origin}#` : ""}/search?searchValue=${encodeURIComponent(
    searchValue,
  )}&searchFilter=${encodeURIComponent(searchFilter)}`

export const getGlobalSearchItemKey = ({ path, route, to }) => path || route || to

export const getFilterLabel = filter => getFilterOptions().find(({ value }) => value === filter)?.label

export const getData = ({
  type,
  id,
  details: {
    nodeType,
    nodeClass,
    clientId,
    clientName,
    documentTemplateId,
    documentTemplateAllowMultiple,
    lastLoggedInUser,
    route: routeFromDetails,
  } = {},
  to,
  route,
}) => {
  switch (type) {
    case "POLICY":
      return {
        route: getPolicyRoute({ nodeClass, id }),
        icon: faBriefcase,
      }
    case "END_USER":
      return {
        route: `${window.location.origin}/#/editor${
          clientId ? `/customer/${clientId}` : ""
        }/endUser/${id}?showOrganizationPicker=true`,
        icon: faUser,
      }
    case "TECHNICIAN_USER":
      return {
        route: `${window.location.origin}/#/editor/user/${id}`,
        icon: faUserHelmetSafety,
      }
    case "DEVICE":
      return {
        route: `${window.location.origin}/${getOverviewLink({ id, nodeType, nodeClass })}`,
        ...(nodeClass === "APPLE_IOS"
          ? {
              iconRenderer: () => <AppleIosIconSvg />,
            }
          : {
              icon: getIcon(nodeClass),
            }),
        lastLoggedInUser,
      }
    case "GROUP":
      return {
        route: `${window.location.origin}/#/group/${id}`,
        icon: faFolder,
      }
    case "ORGANIZATION":
      return {
        route: `${window.location.origin}/#/customerDashboard/${id}/overview`,
        icon: faSitemap,
      }
    case "LOCATION":
      return {
        route: `${window.location.origin}/#/customerDashboard/${clientId}/location/${id}`,
        icon: faLocationDot,
      }
    case "BOARD":
      return {
        route: `${window.location.origin}/#/ticketing/board/${id}`,
        icon: faTableColumns,
      }
    case "TICKET":
      return {
        route: `${window.location.origin}/#/ticketing/ticket/${id}`,
        icon: faTicket,
      }
    case "KB_DOCUMENT":
      return {
        onClick: async () => {
          const buildKBPath = async () => {
            try {
              const response = await getKnowledgeBasePathItems({ itemId: id, isFile: true })
              const [, ...rest] = response
              window.location.hash = rest.reduce(
                (_path, item, index) => _path + `${item.id}${index === rest.length - 1 ? "/file" : "/"}`,
                `#/${clientId ? `customerDashboard/${clientId}/documentation` : "systemDashboard"}/knowledgeBase/`,
              )
            } catch (e) {
              reportErrorAndShowMessage(e)
            }
          }
          await buildKBPath()
        },
        route: "",
        icon: faBook,
      }
    case "CHECKLIST":
      return {
        route: `${window.location.origin}/#/customerDashboard/${clientId}/documentation/checklists/${id}`,
        icon: faBook,
      }
    case "APPS_AND_SERVICES":
      return {
        route: `${window.location.origin}/#/customerDashboard/${clientId}/documentation/appsAndServices/${
          documentTemplateAllowMultiple ? `${documentTemplateId}/${id}` : `${documentTemplateId}`
        }`,
        icon: faBook,
      }
    case "ADMINISTRATION":
      return { icon: faGear, route: route || to || routeFromDetails }
    default:
      throw new Error(`Invalid type ${type}`)
  }
}

const groupByScore = groupBy(({ score, matchAttr, name }) => (score === 100 ? "exact" : "suggested"))

const sortByScore = sortBy(compose(a => -a, prop("score")))

export const reduceResultData = results =>
  results.reduce((accumulator, result) => {
    try {
      accumulator.push({
        ...result,
        ...result.details,
        ...getData(result),
      })
    } catch (error) {
      ninjaReportError(error)
    }

    return accumulator
  }, [])

export const getGlobalSearchResults = async ({ query, filter, limit, administrationPages }) => {
  const results =
    filter === "administration"
      ? []
      : await getSearchResults({
          query,
          filter,
          limit,
        })

  const adminResults =
    filter === "all" || filter === "administration" ? getAdminSearchResults(query, administrationPages) : []

  const { exact = [], suggested = [] } = compose(
    groupByScore,
    sortByScore,
    reduceResultData,
  )([...results, ...adminResults])

  return { exact, suggested }
}

export const getAdminSearchResults = (searchValue, administrationPages) => {
  const fuse = new Fuse(administrationPages, {
    includeScore: true,
    threshold: 0.2,
    keys: [
      { name: "name", weight: 1 },
      { name: "category", weight: 0.1 },
      { name: "searchAliases", weight: 0.1 },
    ],
    includeMatches: true,
    minMatchCharLength: 3,
  })

  const results = fuse.search(searchValue)

  return results.map(result => {
    const score = (1 - result.score) * 100
    return {
      ...result.item,
      score: score > 99.99 ? 100 : score,
      type: "ADMINISTRATION",
      matchAttr: result?.matches[0].key,
    }
  })
}

const serverFilterTypesMap = {
  devices: ["DEVICE"],
  policies: ["POLICY"],
  organizations: ["ORGANIZATION"],
  groups: ["GROUP"],
  users: ["END_USER", "TECHNICIAN_USER"],
  ticketing: ["BOARD", "TICKET"],
  kb_document: ["KB_DOCUMENT"],
  checklist: ["CHECKLIST"],
  apps_and_services: ["APPS_AND_SERVICES"],
  administration: ["ADMINISTRATION"],
}

export const getSearchResults = async ({ query, filter, limit }) => {
  const filters = serverFilterTypesMap[filter]
  const { items } = await handleQuerySearch({
    query,
    limit,
    ...(filters && { filters }),
  })

  return items
}

export const getFilterFromInput = inputValue =>
  getFilterOptions().find(({ value }) => inputValue.toLowerCase().startsWith(`${value}:`))?.value

const getDeviceIds = recentResults => {
  const ids = []

  recentResults.forEach(recentResult => {
    if (recentResult.type === "DEVICE") {
      ids.push(recentResult.id)
    }
  })

  return ids
}

export const getRecentResultsWithUpToDateDevices = async recentResults => {
  const deviceIds = getDeviceIds(recentResults)

  if (!deviceIds.length) return recentResults

  const { nodes } = await fetchDevicesByIds(deviceIds)
  const devicesMap = arrayToMapWithKey("id", nodes)

  return recentResults.reduce((accumulator, recentItem) => {
    if (recentItem.type === "DEVICE") {
      if (devicesMap[recentItem.id]) {
        accumulator.push({
          ...recentItem,
          details: devicesMap[recentItem.id],
        })
      }
    } else {
      accumulator.push(recentItem)
    }

    return accumulator
  }, [])
}
