import { memo, useEffect } from "react"
import PropTypes from "prop-types"
import { getCustomActions, getMobileDeviceActions } from "js/includes/mobileDashboard/actionModals/util"
import { isMobileAppleDevice, localizationKey, reportErrorAndShowMessage, user } from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"
import MobileDeviceSubMenuButtons from "./MobileDeviceSubMenuButtons"

const MobileDeviceSubMenu = memo(({ id, nodeClass, nodeRoleId }) => {
  const [mobileDevice, setMobileDevice] = useMountedState()
  const [customOwnershipActions, setCustomOwnershipActions] = useMountedState([])
  useEffect(() => {
    ;(async () => {
      try {
        const mobileData = await getMobileDeviceActions(id)
        setMobileDevice(mobileData.mobile.node)
        setCustomOwnershipActions(mobileData.allowedActions)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error loading custom actions"))
      }
    })()
  }, [id, setCustomOwnershipActions, setMobileDevice])
  return (
    <MobileDeviceSubMenuButtons
      actions={getCustomActions(mobileDevice, customOwnershipActions)}
      isAppleDevice={isMobileAppleDevice(nodeClass)}
      nodeIds={[id]}
      userCanDeleteDevice={user("canDeleteDevices", { nodeClass })}
      userCanUpdateDevice={user("canUpdateDevices", { nodeClass, nodeRoleId })}
    />
  )
})

export default MobileDeviceSubMenu

MobileDeviceSubMenu.propTypes = {
  id: PropTypes.number.isRequired,
  nodeClass: PropTypes.string.isRequired,
  nodeRoleId: PropTypes.string.isRequired,
}
