import { fetchJson, rejectInactive } from "js/includes/common/utils"

const BASE_URL = "/psa/product"

export function getProducts({ type } = {}) {
  return fetchJson(`${BASE_URL}${type ? `?type=${type}` : ""}`)
}

export async function getActiveProducts({ type } = {}) {
  const response = await getProducts()
  return rejectInactive(response)
}

export function getProduct(productId) {
  return fetchJson(`${BASE_URL}/${productId}`)
}

export function createProduct({ data }) {
  return fetchJson(`${BASE_URL}`, {
    options: {
      method: "POST",
      body: JSON.stringify(data),
    },
  })
}

export function updateProduct({ productId, data }) {
  return fetchJson(`${BASE_URL}/${productId}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(data),
    },
  })
}

export function activateProduct(productId) {
  return fetchJson(`${BASE_URL}/${productId}/activate`, {
    options: {
      method: "PATCH",
    },
  })
}

export function deactivateProduct(productId) {
  return fetchJson(`${BASE_URL}/${productId}/deactivate`, {
    options: {
      method: "PATCH",
    },
  })
}
