import $ from "jquery"
import _ from "underscore"
import rivets from "rivets"
import { localized } from "js/includes/common/utils"

const tags = {
  routine: function(el, value) {
    var elem = el
    var self = this
    var adapter = rivets.adapters[":"]
    var isDisabled = $(el).data("rivets.binders.tags.isDisabled")
    var create_option = $(elem).data("create-option") ? $(elem).data("create-option") : false
    var allowZero = $(elem).data("allow-zero")
    function setChosenBinder(el, value, chosenBinder) {
      //Numeric Validation
      if ($(elem).data("rv-numeric")) {
        chosenBinder.change(function() {
          var arr = chosenBinder.val() ? chosenBinder.val() : []
          var numberTest = /^\d+$/
          for (var i = 0; i < arr.length; i++) {
            if (
              !numberTest.test(arr[i]) ||
              (allowZero && !(arr[i] >= 0)) ||
              (!allowZero && (!(arr[i] > 0) || arr[i] > 65535))
            ) {
              arr.splice(i, 1)
              i--
            }
          }
          chosenBinder.val(arr).trigger("chosen:updated.chosen")

          $(el).data("rivets.binders.tags.isDisabled", true)
          var paths = self.keypath.split(":")
          adapter.set(self.model, paths[paths.length - 1], arr)
          $(el).data("rivets.binders.tags.isDisabled", false)
        })
      }
      //Ip Validation
      if ($(elem).data("rv-ip")) {
        chosenBinder.change(function() {
          var result
          var arr = chosenBinder.val() ? chosenBinder.val() : []
          for (var i = 0; i < arr.length; i++) {
            result = arr[i].match(
              /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
            )
            if (result) {
              if (result.index) {
                arr.splice(i, 1)
                i--
              }
            } else {
              arr.splice(i, 1)
              i--
            }
          }
          chosenBinder.val(arr).trigger("chosen:updated.chosen")

          $(el).data("rivets.binders.tags.isDisabled", true)
          var paths = self.keypath.split(":")
          adapter.set(self.model, paths[paths.length - 1], arr)
          $(el).data("rivets.binders.tags.isDisabled", false)
        })
      }
      //Disk Volume Validation
      if ($(elem).data("rv-disk-volume")) {
        chosenBinder.change(function() {
          var arr = chosenBinder.val() ? chosenBinder.val() : []
          var filteredArray = _.uniq(
            _.filter(arr, function(volume) {
              if (volume.match(/m\/.*\//)) {
                return true
              } else {
                return volume.length <= 32
              }
            }),
          )
          chosenBinder.val([]).trigger("chosen:updated.chosen")
          chosenBinder.val(filteredArray).trigger("chosen:updated.chosen")
          $(el).data("rivets.binders.tags.isDisabled", true)
          var paths = self.keypath.split(":")
          adapter.set(self.model, paths[paths.length - 1], filteredArray)
          $(el).data("rivets.binders.tags.isDisabled", false)
        })
      }
      //Email Validation
      if ($(elem).data("rv-email")) {
        chosenBinder.change(function() {
          var arr = chosenBinder.val() ? chosenBinder.val() : []
          var filteredArray = _.uniq(
            _.filter(arr, function(item) {
              return item.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              )
            }),
          )
          chosenBinder.val([]).trigger("chosen:updated.chosen")
          chosenBinder.val(filteredArray).trigger("chosen:updated.chosen")
          $(el).data("rivets.binders.tags.isDisabled", true)
          var paths = self.keypath.split(":")
          adapter.set(self.model, paths[paths.length - 1], filteredArray)
          $(el).data("rivets.binders.tags.isDisabled", false)
        })
      }
      //Volume Validation
      if ($(elem).data("rv-volume")) {
        chosenBinder.change(function() {
          var upperCaseArray = []
          if (create_option) {
            upperCaseArray = (chosenBinder.val() || [])
              .filter(function(inputItem) {
                var valid = inputItem.match(/[a-zA-Z]:/)
                return !(
                  !valid ||
                  (valid.index !== 0) |
                    ((inputItem.length !== 2) & ((inputItem[2] !== "\\") | (inputItem.length !== 3)))
                )
              })
              .map(function(item) {
                var upperCaseItem = item.toUpperCase()
                var findDomValue = _.toArray(elem.children).filter(function(subItem) {
                  return subItem.value === upperCaseItem
                })
                if (!findDomValue.length) {
                  $(elem).append(
                    $("<option></option>")
                      .attr("value", upperCaseItem)
                      .text(upperCaseItem),
                  )
                }
                return upperCaseItem
              })
          }
          chosenBinder.val(upperCaseArray).trigger("chosen:updated")

          $(el).data("rivets.binders.tags.isDisabled", true)
          var relativePath = self.observer.key.path
          adapter.set(self.model, relativePath, upperCaseArray)
          $(el).data("rivets.binders.tags.isDisabled", false)
          if (!upperCaseArray.length) {
            self.model.get("errors")["volumes"] = localized("Please enter a valid volume")
            self.model.trigger("change:errors")
          }
        })
      }
      if (create_option) {
        value.forEach(function(val) {
          var findDomValue = _.toArray(elem.children).filter(function(item) {
            return item.value === val
          })
          if (!findDomValue.length) {
            $(elem).append(
              $("<option></option>")
                .attr("value", val)
                .text(val),
            )
          }
        })
      }
      chosenBinder.val(value).trigger("chosen:updated.chosen")
    }

    if (!isDisabled && value != null) {
      $(el).data("rivets.binders.tags.isDisabled", true)
      var initialized = $(el).data("rivets.binders.tags.initialized")
      var chosenBinder
      if (!initialized) {
        _.defer(function() {
          chosenBinder = $(elem).chosen({
            width: "100%",
            create_option: create_option,
            skip_no_results: true,
            disable_search: false,
            display_selected_options: false,
            single_text: localized("Select some options"),
            multiple_text: localized("Select some options"),
            no_results_text: localized("No results match"),
            create_option_text: localized("Add"),
          })
          setChosenBinder(el, value, chosenBinder)
          $(elem).data("rivets.binders.tags.binder", chosenBinder)
          $(elem).data("rivets.binders.tags.initialized", true)
        })
        $(el).data("rivets.binders.tags.initialized", true)
      } else {
        chosenBinder = $(elem).data("rivets.binders.tags.binder")
        setChosenBinder(el, value, chosenBinder)
      }
      $(el).data("rivets.binders.tags.isDisabled", false)
    }
  },

  bind: function(el) {
    var adapter = rivets.adapters[":"]
    var self = this

    this.callback = function(e) {
      $(el).data("rivets.binders.tags.isDisabled", true)
      var paths = self.keypath.split(":")
      var chosenBinder = $(el).data("rivets.binders.tags.binder")
      adapter.set(self.model, paths[paths.length - 1], chosenBinder.val())
      $(el).data("rivets.binders.tags.isDisabled", false)
    }

    $(el).data("rivets.binders.tags.initialized", false)
    $(el).on("change", this.callback)
    return true
  },

  unbind: function(el) {
    $(el).chosen("destroy")
    $(el).off()
    return true
  },
}

export default tags
