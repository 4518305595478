import $ from "jquery"

const parentEnabled = function(el, value) {
  if (value) {
    $(el)
      .parent()
      .removeClass("disabled")
  } else {
    $(el)
      .parent()
      .addClass("disabled")
  }
}

export default parentEnabled
