import $ from "jquery"
import _ from "underscore"

const overviewaction = function(el, model) {
  var clickHandler = function(e) {
    // allow links to open rather than triggering overview action menu
    if (e.target.tagName.toLowerCase() === "a") return

    e.stopPropagation()
    e.preventDefault()
    var dropdownButton = $(el).find(".dropdown-button")
    if (dropdownButton.hasClass("open")) {
      dropdownButton.removeClass("open")
    } else {
      dropdownButton.find("a[data-toggle=dropdown]").dropdown("toggle")
      dropdownButton.addClass("open")
    }
  }

  var mouseLeaveHandler = function(e) {
    var dropdownButton = $(el).find(".dropdown-button")
    if (dropdownButton.hasClass("open")) {
      dropdownButton.removeClass("open")
    }
  }

  _.defer(function() {
    if (model && model.get("animate")) {
      model.unset("animate")
      $(el)
        .css("opacity", "0")
        .animate({ opacity: 1 }, 500)
    }
  })

  $(el)
    .off("click")
    .bind("click", clickHandler)
  $(el)
    .off("mouseleave")
    .bind("mouseleave", mouseLeaveHandler)
}

export default overviewaction
