import Datetime from "react-datetime"
import styled from "@emotion/styled"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"
import { Form, FormGroup, Col, ControlLabel } from "react-bootstrap"
import { useForm } from "js/includes/common/hooks"
import {
  localized,
  localizationKey,
  showSuccessMessage,
  reportErrorAndShowMessage,
  isDateInFuture,
  isDateInFutureWithTimeZone,
  getPreviousDay,
  dateTimeLong,
  getRebootJobId,
} from "js/includes/common/utils"
import Modal from "js/includes/components/Modal"
import Checkbox from "js/includes/components/Checkbox"
import { createTask } from "js/includes/common/client"
import { Flex } from "js/includes/components/Styled"
import TimeZonePicker from "js/includes/components/TimeZonePicker"

const modalValidation = {
  datetime: (datetime, { timeZone }) => {
    const isValidDateTime =
      timeZone === "_Local" ? isDateInFuture(datetime) : isDateInFutureWithTimeZone(datetime, timeZone)

    return {
      success: isValidDateTime,
      message: !isValidDateTime ? localized("Date and time must be in the future") : "",
    }
  },
}

const StyledFlex = styled(Flex)`
  label > label {
    padding-bottom: 7px;
  }
`

const formatTaskPayload = ({ devices, datetime, skipIfOffline, timeZone }) => {
  const { id, nodeClass } = devices[0]
  const devicesCount = devices.length
  const targets = devices.map(({ id }) => ({ targetId: id, scheduledTaskId: 0, targetType: "NODE" }))
  const name =
    devicesCount === 1
      ? localized("Reboot Device ID {{id}} on {{dateTime}}", { id, dateTime: dateTimeLong(datetime) })
      : localized("Reboot {{devicesCount}} Devices on {{dateTime}} {{uid}}", {
          devicesCount,
          dateTime: dateTimeLong(datetime),
          uid: uuidv4(),
        })

  return {
    id: 0,
    name,
    content: {
      skipIfOffline,
    },
    description: "",
    enabled: true,
    runsOnNodeScheduledTask: false,
    adHoc: true,
    scripts: {
      actionList: [
        {
          scriptId: getRebootJobId(nodeClass),
          scriptName: "Run Reboot",
        },
      ],
    },
    schedule: {
      schema: "2.0",
      repeats: "RUN_ONCE",
      repeatsEveryCount: 0,
      runAtMinute: moment(datetime).minutes(),
      runAtHour: moment(datetime).hours(),
      dayOfMonth: moment(datetime).date(),
      startDate: moment(datetime).format("Y-MM-DD"),
      end: {
        type: "AFTER",
        occurenceCount: 1,
        endDate: moment(datetime).format("Y-MM-DD"),
      },
      timeZone,
    },
    targets,
  }
}

const RebootLaterModal = ({ devices, unmount, rebootLaterAction }) => {
  const {
    values: { datetime, skipIfOffline, timeZone },
    validation,
    validateForm,
    onChange,
  } = useForm({
    fields: {
      datetime: moment().seconds(0),
      skipIfOffline: false,
      timeZone: "_Local",
    },
    validate: modalValidation,
  })

  return (
    <Form
      horizontal
      onSubmit={async e => {
        e.preventDefault()
        e.stopPropagation()

        try {
          if (validateForm()) {
            const data = formatTaskPayload({ devices, datetime, skipIfOffline, timeZone })

            if (rebootLaterAction) {
              await rebootLaterAction(data)
            } else {
              await createTask(data)
            }

            showSuccessMessage()
            unmount()
          }
        } catch (error) {
          if (!error.isHandledMfaError || !error.isDevicesCacheExpiredError) {
            reportErrorAndShowMessage(error)
          }
        }
      }}
    >
      <Modal
        submittable
        title={localizationKey("Reboot Schedule")}
        saveText={localizationKey("Set")}
        closeText={localizationKey("Cancel")}
        close={unmount}
        overflow={true}
      >
        <FormGroup validationState={validation.success.datetime === false ? "error" : null}>
          <Col componentClass={ControlLabel} xs={3} className="text-align-left">
            <span>{`${localized("Date")}/${localized("Time")}`}:</span>
          </Col>
          <Col xs={9}>
            <Datetime
              className="dateTimePicker"
              value={datetime}
              onChange={datetime => {
                onChange({
                  datetime: moment(datetime)
                    .seconds(0)
                    .valueOf(),
                })
              }}
              isValidDate={datetime => datetime.isAfter(getPreviousDay())}
            />
            <em className="invalid position-absolute no-margin">{validation.message.datetime}</em>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} xs={3} className="text-align-left">
            <span>{localized("configuration.general.timezone.title")}:</span>
          </Col>
          <Col xs={9}>
            <TimeZonePicker value={timeZone} onChange={timeZone => onChange("timeZone", timeZone)} />
          </Col>
        </FormGroup>
        <StyledFlex alignItems="start" marginLeft="auto">
          <Checkbox
            id="skipOffline"
            checked={skipIfOffline}
            label={localized("devices.skipReboot")}
            onChange={e => onChange({ skipIfOffline: e.target.checked })}
          />
        </StyledFlex>
      </Modal>
    </Form>
  )
}

export default RebootLaterModal
