const concat = function(model, propertyA, propertyB) {
  if (model) {
    var a = model.get(propertyA) ? model.get(propertyA) : propertyA
    var b = model.get(propertyB) ? model.get(propertyB) : propertyB
    return (a || "") + (b ? "-" + b : "")
  } else {
    return false
  }
}

export default concat
