import React, { PureComponent } from "react"
import { always, cond, T, memoizeWith, identity, map, reject, compose, unless, prop, endsWith } from "ramda"
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "@emotion/styled"
import {
  localized,
  localizationKey,
  isMacDevice,
  isLinuxDevice,
  isWindowsDevice,
  isNms,
  ninjaReportError,
} from "js/includes/common/utils"
import { colors } from "js/includes/common/theme"
import { getTerminalWebSocketTechnicianUrl, getTerminalWebSocketNms } from "js/includes/common/client"
import { handleTerminalError, CHARWIDTH, SCROLLBARWIDTH } from "js/includes/components/Terminal"
import SubMenu from "js/includes/components/Menu/SubMenu"
import { NinjaResponseError } from "js/includes/common/types"
import { getNodeDash } from "js/includes/components/DeviceContextMenu/utils"

const StyledIcon = styled(({ clickable, ...props }) => <FontAwesomeIcon {...props} />)`
  cursor: pointer;
  color: ${({ theme }) => theme.color.text};
  padding-top: 10px;
  padding-right: 5px;
  pointer-events: ${({ clickable }) => (clickable ? "auto" : "none")};
  &:hover {
    color: ${colors.ninjaBlueDark};
  }
`

const cliArchitectures = [
  { token: localizationKey("32-bit"), shellPath: "DEFAULT" },
  { token: localizationKey("64-bit"), shellPath: "DEFAULT64" },
]
const powerShellArchitectures = [
  { token: localizationKey("32-bit"), shellPath: "POWERSHELL" },
  { token: localizationKey("64-bit"), shellPath: "POWERSHELL64" },
]

const windowsOptions = [
  {
    tool: "cmdAsSystem",
    token: localizationKey("Cmd.exe as System"),
    terminalType: "SYSTEM",
    architectures: cliArchitectures,
  },
  {
    tool: "cmdAsLoggedOnUser",
    token: localizationKey("Cmd.exe as Logged On User"),
    terminalType: "USER",
    architectures: cliArchitectures,
  },
  {
    tool: "pshAsSystem",
    token: localizationKey("Powershell as System"),
    terminalType: "SYSTEM",
    architectures: powerShellArchitectures,
  },
  {
    tool: "pshAsLoggedOnUser",
    token: localizationKey("Powershell as Logged On User"),
    terminalType: "USER",
    architectures: powerShellArchitectures,
  },
]

const macOptions = [
  { tool: "terminal", token: localizationKey("Terminal as Root"), terminalType: "SYSTEM" },
  { tool: "terminal", token: localizationKey("Terminal"), terminalType: "USER" },
]

const linuxOptions = [
  { tool: "terminal", token: localizationKey("Terminal as Root"), terminalType: "SYSTEM" },
  { tool: "terminal", token: localizationKey("Terminal"), terminalType: "USER" },
]

const nmsOptions = [
  {
    tool: "terminal",
    token: localizationKey("Telnet (Beta)"),
    protocol: "TELNET",
  },
  {
    tool: "terminal",
    token: localizationKey("SSH (Beta)"),
    protocol: "SSH",
  },
]

export const defaultDeviceOptions = {
  WINDOWS: windowsOptions,
  MAC: macOptions,
  LINUX: linuxOptions,
  NMS: nmsOptions,
}

const getOptions = (nodeClass, deviceOptions) =>
  cond([
    [isWindowsDevice, always(deviceOptions.WINDOWS)],
    [isMacDevice, always(deviceOptions.MAC)],
    [isLinuxDevice, always(deviceOptions.LINUX)],
    [isNms, always(deviceOptions.NMS)],
    [T, always([])],
  ])(nodeClass)

const getOsArchitecture = memoizeWith(identity, async deviceId => {
  try {
    const response = await getNodeDash(deviceId, [{ dataspec: "os", limit: 1 }], 0)
    if (response.resultCode === "SUCCESS") {
      return response?.node?.datasets?.[0]?.datapoints?.[0]?.data?.osArchitecture
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    ninjaReportError(error)
  }
})

export default class DeviceTerminalSubMenu extends PureComponent {
  state = { loading: false }

  async componentDidMount() {
    this._isMounted = true
    const { nodeClass, id } = this.props.device
    if (isWindowsDevice(nodeClass)) {
      const architecture = await getOsArchitecture(id)
      this._isMounted && this.setState({ architecture })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onShowCommandLine = (e, option) => {
    e.preventDefault()
    const { terminalType, shellPath, protocol } = option
    this.props.onShowCommandLine({ terminalType, shellPath, protocol })
  }

  onShowFullWindowCommandLine = async (e, option) => {
    e.preventDefault()
    const {
      device: { id, parentNodeId },
    } = this.props
    const delegateNodeId = parseInt(parentNodeId) || undefined

    try {
      this.setState({ loading: true })
      const { terminalType, shellPath, protocol } = option

      if (delegateNodeId) {
        const content = {
          delegateNodeId,
          targetNodeId: id,
          protocol,
          width: Math.floor((window.innerWidth - SCROLLBARWIDTH) / CHARWIDTH),
        }
        const response = await getTerminalWebSocketNms(content)
        const technicianUrl = response?.url
        technicianUrl && window.open(technicianUrl, "_blank", "noreferrer")
      } else {
        const content = {
          user: terminalType,
          shell: shellPath || "DEFAULT",
          nodeId: id,
          width: Math.floor((window.innerWidth - SCROLLBARWIDTH) / CHARWIDTH),
        }
        const response = await getTerminalWebSocketTechnicianUrl(content)
        const technicianUrl = response?.url
        technicianUrl && window.open(technicianUrl, "_blank", "noreferrer")
      }
    } catch (error) {
      if (!error.isHandledMfaError) {
        ninjaReportError(error)
        handleTerminalError(error)
      }
    } finally {
      this.setState({ loading: false })
    }
  }

  getLinkButton = option => (
    <li key={option.token}>
      <button className="btn-link" onClick={e => this.onShowCommandLine(e, option)}>
        {localized(option.token)}
      </button>
      <StyledIcon
        clickable={!this.state.loading}
        icon={faExternalLink}
        size="2x"
        color={colors.ninjaBlueSaturated}
        onClick={e => this.onShowFullWindowCommandLine(e, option)}
      />
    </li>
  )

  render() {
    const {
      device: { nodeClass },
      deviceOptions = defaultDeviceOptions,
      MenuComponent,
    } = this.props

    const { architecture } = this.state
    const options = getOptions(nodeClass, deviceOptions)
    const filterArchitectures = unless(
      () => architecture === "64-bit",
      reject(compose(endsWith("64"), prop("shellPath"))),
    )

    if (MenuComponent)
      return (
        <MenuComponent
          {...{ options, filterArchitectures, onShowFullWindowCommandLine: this.onShowFullWindowCommandLine }}
        />
      )

    if (isWindowsDevice(nodeClass)) {
      return getOptions(nodeClass, deviceOptions).map(({ token, terminalType, architectures }) => (
        <SubMenu label={localized(token)} key={token}>
          {compose(
            map(architecture => this.getLinkButton({ ...architecture, terminalType })),
            filterArchitectures,
          )(architectures)}
        </SubMenu>
      ))
    }

    return options.map(this.getLinkButton)
  }
}
