import TitleGroup from "../TitleGroup"
import Switch from "../Switch"
import { localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

export default function EditorTitle({
  onEnableChange,
  enabled,
  titleToken,
  titleText,
  descriptionToken,
  size,
  disabled,
}) {
  function TitleSwitch() {
    return (
      <Switch
        checked={enabled}
        onChange={onEnableChange}
        disabled={disabled}
        labelToken={enabled ? localizationKey("Enabled") : localizationKey("Disabled")}
      />
    )
  }

  return (
    <TitleGroup
      {...{
        size,
        descriptionToken,
        titleToken,
        titleText,
        TitleTailComponent: onEnableChange && TitleSwitch,
      }}
    />
  )
}
