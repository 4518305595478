import moment from "moment"
import PropTypes from "prop-types"
import { mergeDeepRight } from "ramda"
import { Select, DatePicker, TimePicker } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Flex, Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"
import { getUpdatedDateTimeComponents } from "./dateUtils"

const options = [
  {
    labelToken: localizationKey("Never"),
    value: "NEVER",
  },
  {
    labelToken: localizationKey("Custom date and time"),
    value: "ON",
  },
]

const EndSchedule = ({ schedule: { start, end }, validation, updateEnd }) => {
  const changeEndType = type => {
    const change = mergeDeepRight(end, { type })
    updateEnd(change)
  }

  const changeEndDateTime = (currentUpdateDateTime, updateType) => {
    const updatedDateTimeComponents = getUpdatedDateTimeComponents({
      currentUpdateDateTime,
      currentDateTime: new Date(end?.datetime),
      updateType,
    })
    const updatedDateTimeObj = new Date(...updatedDateTimeComponents)
    updateEnd(mergeDeepRight(end, { datetime: moment(updatedDateTimeObj).valueOf() }))
  }

  const beforeMoment = moment().isAfter(moment(start.datetime)) ? moment() : moment(start.datetime)

  return (
    <Flex flexDirection="column" margin={`${tokens.spacing[2]} 0`}>
      <Select
        value={end?.type}
        labelId="maintenance-end-select"
        labelText={localized("End")}
        options={options}
        onChange={changeEndType}
      />
      {end?.type === "ON" && (
        <Flex justifyContent="space-between" gap={tokens.spacing[2]} width="100%" marginTop={tokens.spacing[2]}>
          <Box flex="1">
            <DatePicker
              labelText={localized("End date")}
              selectedDays={new Date(end?.datetime)}
              disabledDays={{
                before: beforeMoment.toDate(),
              }}
              onDayChange={({ date }) => {
                changeEndDateTime(date, "date")
              }}
              useDropdownNavigation
              disableClear
              fullWidth
              readOnly
              // errorMessage={validation?.message?.start} <--- TODO: unmuting it when Datepicker is fixed
            />
          </Box>
          <Box flex="1">
            <TimePicker
              value={new Date(end?.datetime)}
              placeholder={localized("Select end time")}
              labelText={localized("End time")}
              onTimeChange={date => changeEndDateTime(date, "time")}
              disableClear
              useTimezone
              errorMessage={validation?.message?.end}
            />
          </Box>
        </Flex>
      )}
    </Flex>
  )
}

EndSchedule.propTypes = {
  schedule: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  updateEnd: PropTypes.func.isRequired,
}

export default EndSchedule
