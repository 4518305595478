import ModelBase from "../../../includes/editors/Common/ModelBase"
import _ from "underscore"
import $ from "jquery"
import Backbone from "backbone"
import { showErrorMessage, localized } from "js/includes/common/utils"
import { buildMfaBackboneModelUrl } from "js/includes/common/services/mfa"

export default ModelBase.extend({
  url(useMfaUrl) {
    return buildMfaBackboneModelUrl(this, useMfaUrl)
  },

  unsetFieldsThatAreNoLongerPresent(response) {
    //https://github.com/jashkenas/backbone/issues/2358

    _.chain(_.keys(this.attributes)) // get model attributes keys
      .difference(_.keys(response)) // without response keys
      .each(this.unset.bind(this))
  },

  parse(response) {
    if (response.resultCode === "SUCCESS") {
      const responseObj = response[this.parseParam]
      this.unsetFieldsThatAreNoLongerPresent(responseObj)
      return responseObj
    } else {
      this.unsetFieldsThatAreNoLongerPresent(response)
      return JSON.stringify(response)
    }
  },

  save(showSavingMessage, isThirdPartyEntity) {
    return Backbone.Model.prototype.save.call(this, undefined, {
      success: (model, response, options) => {
        if (response.resultCode === "SUCCESS") {
          if (showSavingMessage) {
            window.showSuccessMessage(localized("Saving..."), undefined, isThirdPartyEntity)
          }
        } else {
          this.onSaveError(options.xhr)
        }
      },
      error: (model, response, options) => {
        this.onSaveError(options.xhr)
      },
    })
  },

  saveAsJson({ json, showSavingMessage, useMfaUrl }) {
    return $.ajax({
      url: this.url(useMfaUrl),
      type: this.get("id") ? "PUT" : "POST",
      dataType: "json",
      contentType: "application/json",
      data: json,
      success: (response, textStatus, jqXHR) => {
        if (response.resultCode === "SUCCESS") {
          if (showSavingMessage) {
            window.showSuccessMessage(localized("Saving..."))
          }
        } else {
          this.onSaveError(jqXHR)
        }
      },
      error: (jqXHR, textStatus, errorThrown) => {
        this.onSaveError(jqXHR)
      },
    })
  },

  onSaveError(xhr) {
    showErrorMessage(`${localized("Error saving")} ${this.infoType}`)
  },

  getName() {
    return this.get("name")
  },

  toJSON() {
    return JSON.parse(JSON.stringify(this.attributes))
  },
})
