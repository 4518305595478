import styled from "@emotion/styled"
import { Text, Tooltip } from "@ninjaone/components"
import { RegularInfoCircleIconLight, SearchIcon as NinjaSearchIcon } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import {
  isFeatureEnabled,
  isTicketingEnabledFromSettings,
  isUserAllowedToViewTicketNavigation,
  localized,
  user,
} from "js/includes/common/utils"
import { GLOBAL_SEARCH_WIDTH, TOP_BAR_HEIGHT } from "js/includes/application/constants"
import { Box } from "js/includes/components/Styled"
import {
  checkDocumentationAnyKnowledgeBaseManagementPermission,
  hasClientChecklistPermission,
  hasDeviceAdministrationAccess,
  hasDocumentationManagementAccess,
  hasOrgViewPermission,
} from "js/includes/common/services/userPermissions"
import { VIEW } from "js/includes/editors/User/Permissions/common"

const filterLabelComponentRenderer = ({ label, tooltipLabel }) => (
  <>
    <Text children={localized(label)} size="sm" />
    <Tooltip
      label={localized(tooltipLabel)}
      position="right"
      align="center"
      sideOffset={16}
      portal={false}
      contentZIndex={10000}
    >
      <RegularInfoCircleIconLight />
    </Tooltip>
  </>
)

export const getFilterOptions = () => [
  {
    value: "all",
    label: localized("All"),
  },
  {
    value: "organizations",
    label: localized("Organizations"),
    description: localized("Type “Organizations:” for shortcut"),
  },
  {
    value: "devices",
    label: localized("Devices"),
    description: localized(
      "Search by Device Name, System Name, BIOS Serial Number, IP Address, Mac Address, Last logged-in User \n\nType “Devices:” for shortcut",
    ),
  },
  ...(hasDeviceAdministrationAccess()
    ? [
        {
          value: "groups",
          label: localized("Groups"),
          description: localized("Type “Groups:” for shortcut"),
        },
      ]
    : []),
  ...(user("canReadAtLeastOnePolicy")
    ? [
        {
          value: "policies",
          label: localized("Policies"),
          description: localized("Type “Policies:” for shortcut"),
        },
      ]
    : []),
  {
    value: "users",
    label: localized("Users"),
    description: localized("Search by name, email \n\nType “Users:” for shortcut"),
  },
  ...(isTicketingEnabledFromSettings() && isUserAllowedToViewTicketNavigation()
    ? [
        {
          value: "ticketing",
          label: localized("Ticketing"),
          description: localized("Search by Ticket ID \n\nType “Ticketing:” for shortcut"),
        },
      ]
    : []),
  ...(isFeatureEnabled("documentation_knowledge_base") && checkDocumentationAnyKnowledgeBaseManagementPermission(VIEW)
    ? [
        {
          value: "kb_document",
          label: localized("KB Articles"),
          description: localized("Search by name \n\nType “KB Articles:” for shortcut"),
        },
      ]
    : []),
  ...(isFeatureEnabled("documentation_checklist") && hasClientChecklistPermission(VIEW) && hasOrgViewPermission()
    ? [
        {
          value: "checklist",
          label: localized("Checklists"),
          description: localized("Search by name \n\nType “Checklists:” for shortcut"),
        },
      ]
    : []),
  ...(isFeatureEnabled("documentation_knowledge_base") &&
  hasDocumentationManagementAccess(VIEW) &&
  hasOrgViewPermission()
    ? [
        {
          value: "apps_and_services",
          label: localized("Documents"),
          description: localized("Search by name \n\nType “Documents:” for shortcut"),
        },
      ]
    : []),
  {
    value: "administration",
    label: localized("Administration"),
    description: localized("Search by Administration \n\nType “Administration:” for shortcut"),
  },
]

export const getFilterSelectOptions = () =>
  getFilterOptions().map(option =>
    option.description
      ? {
          ...option,
          tooltipLabel: option.description,
          ariaDescription: option.description,
          LabelComponent: filterLabelComponentRenderer,
        }
      : {
          ...option,
          labelText: option.label,
        },
  )

export const defaultFilterOptionValue = "all"

export const minimumLoaderTime = 500

export const debounceTimeout = minimumLoaderTime

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: ${TOP_BAR_HEIGHT}px;

  body.ninja-select-open & {
    pointer-events: all;

    *:not([data-ninja-select-content] *) {
      pointer-events: none;
    }
  }
`

export const StyledInput = styled.input`
  width: 100%;
  border: 0;
  color: ${({ theme }) => theme.colorTextStrong};
  font-size: 14px;
  background-color: transparent;
  text-overflow: ellipsis;

  &::placeholder {
    color: ${({ theme }) => theme.colorTextWeakest};
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
  }
`

export const StyledDivider = styled.div`
  width: 1px;
  margin-left: ${sizer(1)};
  margin-right: ${sizer(1)}; // TODO: adjust this once we add final filter dropdown with icon
  height: 100%;
  background-color: ${({ isSearchBarPersisted, theme }) =>
    isSearchBarPersisted ? theme.colorBorderWeak : theme.colorBorderWeakest};
`

export const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  padding: ${sizer(2)};
  border-radius: 4px;
  border: 1px solid transparent;

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &[data-persisted] {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &[data-active] {
    width: ${GLOBAL_SEARCH_WIDTH}px;
    border-color: ${({ theme }) => theme.colorBorderWeak};
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    background-color: ${({ theme }) => theme.colorBackgroundWidget};
  }

  svg {
    color: ${({ theme }) => theme.colorTextStrong};
  }

  body:not(.ninja-select-open) &:not([data-active]):hover {
    cursor: pointer;
  }

  > [data-ninja-tooltip-trigger] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  [data-ninja-tooltip-content].main-tooltip {
    font-size: ${getTextSize("sm")};

    em {
      border-radius: 2px;
      display: inline-flex;
      width: 17px;
      height: 21px;
      align-items: center;
      justify-content: center;
      padding: ${sizer(1, 2)};
      background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralDark};
    }
  }
`

export const SearchIcon = () => (
  <Box marginRight="14px">
    <NinjaSearchIcon />
  </Box>
)

export const ResultsHeader = styled.div`
  display: flex;
  align-items: center;
  height: 33px;
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};
  font-size: ${getTextSize("sm")};
  color: ${({ theme }) => theme.colorTextStrong};
  padding-left: ${({ padding }) => padding || sizer(4)};
  padding-right: ${({ padding }) => padding || sizer(4)};

  ${({ breakoutOfContainer, padding }) =>
    breakoutOfContainer &&
    `
    margin-left: -${padding || sizer(4)};
    margin-right: -${padding || sizer(4)};
  `}
`

export const StyledAlert = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colorTextWeakest};

  svg {
    color: ${({ theme }) => theme.colorTextWeakest};
    font-size: 24px;
    margin-bottom: ${sizer(2)};
  }

  h3 {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-top: ${sizer(1)};
  }

  a {
    color: ${({ theme }) => theme.colorTextAction};
    text-decoration: none;
  }
`

const getPopoverExtraStyles = ({ isLoading, showAlert, isRecentsPopover }) => {
  if (isLoading || isRecentsPopover) {
    return "height: auto;"
  } else if (showAlert) {
    return "height: 200px; display: flex; align-items: center; justify-content: center;"
  }

  return "height: 420px;"
}

export const StyledPopover = styled.div`
  position: absolute;
  top: ${TOP_BAR_HEIGHT}px;
  width: ${GLOBAL_SEARCH_WIDTH}px;
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  z-index: 998;
  box-shadow: 0px 2px 8px 0px rgba(33, 31, 51, 0.1); // elevation 3
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: 4px;
  padding: ${sizer(4)};
  overflow: auto;

  body.ninja-select-open & {
    pointer-events: all !important;
  }

  ${({ isLoading, showAlert, isRecentsPopover }) => getPopoverExtraStyles({ isLoading, showAlert, isRecentsPopover })}
`
