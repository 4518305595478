import { fetchJson } from "js/includes/common/utils"

export const handleQuerySearch = ({ query, limit, filters }) =>
  fetchJson("/qsearch", {
    options: {
      method: "POST",
      body: JSON.stringify({
        query,
        limit,
        filters,
      }),
    },
  })

export const getGlobalSearchRecentResults = () =>
  fetchJson("/qsearch/recent", {
    options: {
      method: "GET",
    },
  })

export const saveGlobalSearchRecentResult = result =>
  fetchJson("/qsearch/recent", {
    options: {
      method: "POST",
      body: JSON.stringify(result),
    },
  })
