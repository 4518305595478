import React from "react"
import { localizationKey, localized } from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import SubMenu from "js/includes/components/Menu/SubMenu"
import ActiveDirectoryDiscoveryJobModal from "js/includes/editors/Customer/Sections/Security/Tabs/modals/ActiveDirectoryDiscoveryJobModal"

export default function RunADDiscoveryJobMenuItem({ device, justification, top, MenuComponent, fromDeviceSearch }) {
  const onAdd = jobType => {
    const clientId = window.deviceList.get(device.id)?.attributes.clientId || device.clientId
    showModal(
      <ActiveDirectoryDiscoveryJobModal
        clientId={clientId}
        domainController={device}
        jobType={jobType}
        fromDeviceSearch={fromDeviceSearch}
      />,
      {
        withProvider: true,
      },
    )
  }

  const dropdownItems = [
    {
      token: localizationKey("Adhoc Discovery"),
      onClick: () => {
        onAdd("AD_HOC")
      },
    },
    {
      token: localizationKey("Scheduled Discovery"),
      onClick: () => {
        onAdd("SCHEDULED")
      },
    },
  ]

  return MenuComponent ? (
    <MenuComponent {...{ dropdownItems }} />
  ) : (
    <SubMenu
      {...{
        justification,
        label: localized("Run an AD Discovery Job"),
        customStyle: { top },
      }}
    >
      {dropdownItems.map(({ token, onClick }) => (
        <li key={token}>
          <button className="btn-link" onClick={onClick}>
            {localized(token)}
          </button>
        </li>
      ))}
    </SubMenu>
  )
}
