import { CUSTOM_ERROR_MESSAGES } from "js/includes/configuration/scripting/ScriptsSubTabModel/common"
import {
  scriptVariablesValidations,
  notRequiredScriptVariablesValidations,
} from "js/includes/configuration/scripting/ScriptsSubTabModel/common"
import { validations, localizationKey, applyMultipleValidations } from "js/includes/common/utils"

export const mapScriptVariablesToFields = variables => {
  return variables
    ? variables.reduce((obj, variable) => {
        if (variable.type === "CHECKBOX") {
          obj[variable.calculatedName] = variable?.defaultValue === "true"
          return obj
        }
        obj[variable.calculatedName] = variable?.defaultValue || ""
        return obj
      }, {})
    : []
}

export const mapScriptVariablesToValidates = variables => {
  return variables
    ? variables.reduce((obj, variable) => {
        if (!variable.required) {
          return notRequiredScriptVariablesValidations[variable.type]
            ? { ...obj, [variable.calculatedName]: notRequiredScriptVariablesValidations[variable.type] }
            : obj
        }
        return {
          ...obj,
          [variable.calculatedName]: applyMultipleValidations([
            validations.required,
            ...(scriptVariablesValidations[variable.type] ? [scriptVariablesValidations[variable.type]] : []),
          ]),
        }
      }, {})
    : []
}

const getUpdatedValue = (updatedValues, { type, calculatedName, defaultValue }) => {
  if (type === "CHECKBOX" && updatedValues.hasOwnProperty(calculatedName)) {
    return String(updatedValues[calculatedName])
  }
  return updatedValues[calculatedName] || defaultValue
}

export const getUpdatedScriptVariables = (scriptVariables, scriptVariablesUpdatedValues, isAdhocJob = false) => {
  return scriptVariables
    ? scriptVariables.reduce((acc, variable) => {
        const { name, calculatedName, type, valueList, required, description, source } = variable
        const updatedValue = getUpdatedValue(scriptVariablesUpdatedValues, variable)
        const value = updatedValue ?? ""
        /* Note: We need to differentiate adhoc script flow + scheduled task script flow. When in scheduled flow, since user can reopen
         * script modal to edit, we can't strip critical details like required & valueList containing dropdown options. We also need to set
         * default value to the current value, so we know to prepopulate with the latest submitted value next time */
        if (!type === "CHECKBOX" && !value && !required && isAdhocJob) return acc
        return isAdhocJob
          ? [
              ...acc,
              {
                name,
                calculatedName,
                type,
                value,
                source,
              },
            ]
          : [
              ...acc,
              {
                name,
                calculatedName,
                type,
                value,
                source,
                valueList,
                required,
                description,
                defaultValue: value,
              },
            ]
      }, [])
    : []
}

const flattenErrorObject = validationMessages => {
  return Object.values(validationMessages).reduce((values, value) => {
    //flattens nested object as `some func` skips obj values
    if (typeof value === "object") {
      return [...values, ...Object.values(value)]
    }
    return [...values, value]
  }, [])
}

const isCustomErrorMessage = message => {
  return Object.values(CUSTOM_ERROR_MESSAGES).some(errorMessage => errorMessage === message)
}

export const getErrorBannerMessages = validationMessages => {
  const errors = flattenErrorObject(validationMessages)
  const displayErrors = errors.reduce((acc, error) => {
    if (!error) return acc
    if (isCustomErrorMessage(error)) return [...acc, error]
    return [...acc, localizationKey("Mandatory value is undefined. Please enter a value.")]
  }, [])
  return displayErrors
}
