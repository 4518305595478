import { useRef, useState, useEffect, useMemo } from "react"
import { formatDate } from "react-day-picker/moment"
import moment from "moment"
import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden"
import { v4 as uuidv4 } from "uuid"
import PropTypes from "prop-types"

import { isRequiredIf } from "@ninjaone/utils"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { CalendarIconLight } from "@ninjaone/icons"
import {
  getInitialDateInputValue,
  parseAndValidateDate,
  parseDateErrorResponse,
  parseAndValidateDateRange,
} from "@ninjaone/utils/helpers"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization/autocomplete"
import {
  isDownKey,
  isEnterKey,
  isSpaceKey,
} from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/charsets"

import DatePickerPopover from "./Components/DatePickerPopover"
import DatePickerDropdownNavigation from "./Components/DatePickerDropdownNavigation"
import { PickerInputWithActions } from "../Components/PickerInputWithActions"
import { Label } from "../../Form/Label"
import { TOKEN_ERROR_MSG_REQUIRED, TOKEN_ERROR_MSG_INVALID } from "../errorMessages"

const MAX_LENGTH_SINGLE_MODE = 10
const MAX_LENGTH_RANGE_MODE = 23
const REGEX_SINGLE_MODE = /^[0-9/.]+$/
const REGEX_RANGE_MODE = /^[0-9/ \-.]+$/

const DatePicker = ({
  ariaErrorId,
  ariaLabel,
  alignPopover,
  collisionBoundary,
  disabled,
  disabledDays,
  disableClear,
  errorMessage: error,
  excludeYear,
  footerRenderer,
  fromMonth,
  fullWidth,
  initialMonth,
  inputProps,
  isDateTime,
  labelToken,
  labelText,
  locale,
  mode,
  onBlur,
  onClose,
  onDayChange,
  onDayMouseEnter,
  onError,
  onFocus,
  onOpen,
  placeholder,
  readOnly,
  renderCustomDropdownNavigation,
  required,
  selectedDays,
  toMonth,
  tooltipAlignment,
  tooltipPosition,
  tooltipText,
  useDropdownNavigation,
  useSameDayRange,
  width,
  yearMinOffset = useDropdownNavigation && 50,
  yearMaxOffset = useDropdownNavigation && 50,
}) => {
  const localeFormat =
    readOnly && excludeYear
      ? moment
          .localeData(locale)
          .longDateFormat("L")
          .replace(/.\bYYYY\b/, "")
      : moment.localeData(locale).longDateFormat("L")
  const [selected, setSelected] = useState(selectedDays)
  const [inputValue, setInputValue] = useState("")
  const [isCalendarOpen, setCalendarOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [month, setMonth] = useState(initialMonth)
  const inputRef = useRef(null)
  const isSingleMode = mode === "single"
  const useCaptionElement = useDropdownNavigation || renderCustomDropdownNavigation

  const inputId = useMemo(() => {
    return uuidv4()
  }, [])

  const handleClose = () => {
    setCalendarOpen(false)
    inputRef.current.focus()
  }

  const handleValidationError = error => {
    const errorMessage = parseDateErrorResponse(error)
    setErrorMessage(localized(errorMessage))
    onError?.(errorMessage)
  }

  const handleInputChange = value => {
    const validationArgs = {
      value,
      localeFormat,
      disabledDays,
      fromMonth,
      toMonth,
      useSameDayRange,
    }

    setInputValue(value)
    if (value === "") {
      setErrorMessage("")
      onError?.(null)
      return
    }

    // trigger an error state if there are disallowed characters before the user has reached the character threshold
    if (
      (isSingleMode && value.length < MAX_LENGTH_SINGLE_MODE) ||
      (!isSingleMode && value.length < MAX_LENGTH_RANGE_MODE)
    ) {
      const regex = isSingleMode ? REGEX_SINGLE_MODE : REGEX_RANGE_MODE
      if (!regex.test(value)) {
        setErrorMessage(localized(TOKEN_ERROR_MSG_INVALID))
        onError?.(TOKEN_ERROR_MSG_INVALID)
      } else {
        setErrorMessage("")
        onError?.(null)
      }
      return
    }

    const parsedDate = isSingleMode ? parseAndValidateDate(validationArgs) : parseAndValidateDateRange(validationArgs)

    if (parsedDate.error) {
      setSelected(null)
      handleValidationError(parsedDate.error)
    } else {
      // When selecting dates through the calendar the underlying package is
      // returning the timestamp as noon. To ensure consistency set the hours
      // to noon when a user types a valid date.
      if (isSingleMode) {
        parsedDate.setHours(12)
      } else {
        parsedDate.from.setHours(12)
        parsedDate.to.setHours(12)
      }

      setSelected(parsedDate)
      setErrorMessage("")
      onError?.(null)
      onDayChange?.(isSingleMode ? { date: parsedDate } : { range: parsedDate })
    }
  }

  const handleClearClick = () => {
    setSelected(null)
    setInputValue("")
    setErrorMessage("")
    onDayChange?.(isSingleMode ? { date: null } : { range: null })
    onError?.(null)
    inputRef.current.focus()
  }

  const handleDaySelect = ({ date, range, modifiers }) => {
    onError?.(null)
    if (isSingleMode) {
      if (!modifiers.selected && date) {
        setInputValue(excludeYear ? formatDate(date, "L", locale).replace(/.\d{4}/, "") : formatDate(date, "L", locale))
        onDayChange?.({ date })
        handleClose()
        setErrorMessage("")
        setSelected(date)
      } else {
        setInputValue("")
      }
    } else {
      setSelected(range)

      if (range.from && range.to) {
        onDayChange?.({ range })
        setInputValue(`${formatDate(range.from, "L", locale)} - ${formatDate(range.to, "L", locale)}`)
        setErrorMessage("")
      }
      // The calendar no longer supports early return for multiple clicks on
      // the same day. The first and second click set the same day selection,
      // but a third click deselects the day (allowing a user to select a
      // completely new date range). Reset the error message and input value
      // and call the event handler with null values to reset events related
      // to calendar selection.
      if (!range.from && !range.to) {
        onDayChange?.({ range })
        setInputValue("")
        setErrorMessage("")
      }
    }
  }

  const handleInputBlur = () => {
    if (isDateTime || isCalendarOpen || (!required && !inputValue)) return

    if (required && inputValue.trim() === "") {
      setErrorMessage(localized(TOKEN_ERROR_MSG_REQUIRED))
      onError?.(TOKEN_ERROR_MSG_REQUIRED)
      return
    }

    const validationArgs = {
      value: inputValue,
      localeFormat,
      disabledDays,
      fromMonth,
      toMonth,
      useSameDayRange,
    }

    // revalidate the date in case the user hasn't reached the character
    // threshold for handleInputChange()
    const parsedDate = isSingleMode ? parseAndValidateDate(validationArgs) : parseAndValidateDateRange(validationArgs)
    parsedDate.error && handleValidationError(parsedDate.error)
  }

  const isOpenKey = e => isEnterKey(e) || isSpaceKey(e) || isDownKey(e)

  const renderCaptionElement = ({ date }) => {
    const handleChange = month => {
      setMonth(month)
    }

    if (renderCustomDropdownNavigation) {
      return renderCustomDropdownNavigation({
        date,
        excludeYear,
        fromMonth,
        locale,
        onChange: handleChange,
        toMonth,
        yearMinOffset,
        yearMaxOffset,
      })
    }

    return (
      <DatePickerDropdownNavigation
        onChange={handleChange}
        {...{ date, excludeYear, locale, yearMinOffset, yearMaxOffset }}
      />
    )
  }

  useEffect(() => {
    setSelected(selectedDays)
    setInputValue(getInitialDateInputValue({ selectedDays, localeFormat }))
    setErrorMessage("")
  }, [localeFormat, selectedDays])

  useEffect(() => {
    setErrorMessage(error)
  }, [error])

  useEffect(() => {
    !selectedDays && initialMonth && setMonth(initialMonth)
  }, [initialMonth, selectedDays])

  return (
    <Box position="relative">
      {(labelText || labelToken) && (
        <Label
          labelFor={inputId}
          {...{
            disabled,
            labelText: labelText ? labelText : localized(labelToken),
            required,
            tooltipText,
          }}
        />
      )}

      <VisuallyHidden id={`${inputId}Description`}>
        {useCaptionElement && readOnly
          ? localized("Click to open the date picker")
          : localized(
              "Enter a date in {{localeFormat}} format or shift focus to the next element to open the date picker",
              { localeFormat: isSingleMode ? localeFormat : `${localeFormat} - ${localeFormat}` },
            )}
      </VisuallyHidden>

      <DatePickerPopover
        anchorRenderer={() => (
          <PickerInputWithActions
            ariaDescribedBy={`${inputId}Description`}
            ariaErrorId={ariaErrorId || `${inputId}Error`}
            disableClear={!inputValue || disabled || disableClear}
            error={errorMessage}
            iconIsTrigger
            inputIcon={{
              ariaLabel: localized("Open the date picker"),
              icon: <CalendarIconLight />,
              onBlur: () => required && handleInputBlur(),
              onClick: () => !disabled && setCalendarOpen(true),
              onKeyDown: e => isOpenKey(e) && setCalendarOpen(true),
            }}
            id={inputId}
            maxLength={isSingleMode ? MAX_LENGTH_SINGLE_MODE : MAX_LENGTH_RANGE_MODE}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            onClearClick={handleClearClick}
            onClick={() => useCaptionElement && readOnly && setCalendarOpen(true)}
            onKeyDown={e => isOpenKey(e) && useCaptionElement && readOnly && setCalendarOpen(true)}
            placeholder={placeholder || (isSingleMode ? localeFormat : `${localeFormat} - ${localeFormat}`)}
            ref={inputRef}
            type="text"
            value={inputValue}
            width={width || 280}
            {...{
              ariaLabel,
              disabled,
              fullWidth,
              isDateTime,
              readOnly,
              required,
              tooltipAlignment,
              tooltipPosition,
            }}
            {...inputProps}
          />
        )}
        captionElement={useCaptionElement ? renderCaptionElement : undefined}
        onDayChange={handleDaySelect}
        onEscapeKeyDown={handleClose}
        onInteractOutside={handleClose}
        onOpenChange={open => (open ? onOpen?.() : onClose?.())}
        open={isCalendarOpen}
        selectedDays={selected}
        {...{
          alignPopover,
          collisionBoundary,
          disabled,
          disabledDays,
          footerRenderer,
          fromMonth,
          initialMonth,
          locale,
          mode,
          month,
          onBlur,
          onDayMouseEnter,
          onFocus,
          toMonth,
          useSameDayRange,
          yearMinOffset,
          yearMaxOffset,
        }}
      />
    </Box>
  )
}

DatePicker.propTypes = {
  /**
   * Aria label used by assistive technology when label is not provided.
   */
  ariaLabel: isRequiredIf(
    PropTypes.string,
    props => !props.hasOwnProperty("labelText") && !props.hasOwnProperty("labelToken"),
    "`ariaLabel` is required when `labelText` and `labelToken` are not provided",
  ),
  /**
   * Horizontal alignment of the popover.
   */
  alignPopover: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * The element used as the collision boundary. By default this is the
   * viewport, though you can provide additional element(s) to be included in
   * this check.
   */
  collisionBoundary: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  /**
   * Controls the disabled state of the component.
   */
  disabled: PropTypes.bool,
  /**
   * Days to disable within the calendar.
   * See [Matching days](https://react-day-picker-v7.netlify.app/docs/matching-days)
   * for a reference of the accepted value types.
   */
  disabledDays: PropTypes.any,
  /**
   * Disables the clear button in the component.
   */
  disableClear: PropTypes.bool,
  /**
   * Sets an error message from the parent component
   */
  errorMessage: PropTypes.string,
  /**
   * Determines if the year should be excluded from the localized date format
   * when `readOnly` is true, and hides the year dropdown when
   * `useDropdownNavigation` is true.
   */
  excludeYear: PropTypes.bool,
  /**
   * Function to render content below the calendar.
   */
  footerRenderer: PropTypes.func,
  /**
   * The first allowed month. Users won’t be able to navigate or interact with
   * the days before it. See also `toMonth`.
   */
  fromMonth: PropTypes.instanceOf(Date),
  /**
   * Allows component to expand to full width of parent container.
   */
  fullWidth: PropTypes.bool,
  /**
   * The month to display in the calendar at first render.
   */
  initialMonth: PropTypes.instanceOf(Date),
  /**
   * Additional props for the input element.
   */
  inputProps: PropTypes.object,
  /**
   * The token for the label.
   */
  labelToken: PropTypes.string,
  /**
   * The text for the label.
   */
  labelText: PropTypes.string,
  /**
   * The locale for the component. Defaults to browser's locale.
   */
  locale: PropTypes.string,
  /**
   * The selection mode for the component. Either a single date or a range
   * of dates.
   */
  mode: PropTypes.oneOf(["single", "range"]),
  /**
   * Event handler run when the popover closes.
   */
  onClose: PropTypes.func,
  /**
   * Event handler run when the input is blurred.
   */
  onBlur: PropTypes.func,
  /**
   * Event handler run when a new day is selected from the calendar.
   */
  onDayChange: PropTypes.func,
  /**
   * Event handler run when the mouse enters the calendar.
   */
  onDayMouseEnter: PropTypes.func,
  /**
   * Event handler run when the component's value does not pass validation.
   */
  onError: PropTypes.func,
  /**
   * Event handler run when the calendar recieves focus.
   */
  onFocus: PropTypes.func,
  /**
   * Event handler run when the popover opens.
   */
  onOpen: PropTypes.func,
  /**
   * The placeholder text for the input.
   */
  placeholder: PropTypes.string,
  /**
   * The required state of the component.
   */
  required: PropTypes.bool,
  /**
   * Sets the value of the input and passed to the calendar to select day(s).
   * See [Matching days](https://react-day-picker-v7.netlify.app/docs/matching-days)
   * for a reference of the accepted value types.
   */
  selectedDays: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
  ]),
  /**
   * The last allowed month. Users won’t be able to navigate or interact with
   * the days after it. See also `fromMonth`.
   */
  toMonth: PropTypes.instanceOf(Date),
  /**
   *  The horizontal alignment of the error and clear tooltips
   */
  tooltipAlignment: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * The position of the tooltip against the icon trigger
   */
  tooltipPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * The text for the more information tooltip in the component's label.
   */
  tooltipText: PropTypes.string,
  /**
   * Sets the ability to select both the start and end date as the same day
   * when the `mode` is `range`.
   */
  useSameDayRange: PropTypes.bool,
}

DatePicker.defaultProps = {
  alignPopover: "start",
  locale: navigator.language,
  mode: "single",
  tooltipAlignment: "end",
  tooltipPosition: "bottom",
}

export default DatePicker
