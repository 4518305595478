import { Tags } from "@ninjaone/components"
import DeviceIcon from "js/includes/components/DeviceIcon"

export default function DeviceTags({ devices, totalCount }) {
  if (!Array.isArray(devices)) devices = [devices]

  const labels = devices.map(({ id, nodeClass, name, systemName }) => ({
    id: id.toString(),
    label: (
      <span>
        <DeviceIcon {...{ nodeClass }} />
        &nbsp;
        {name || systemName}
      </span>
    ),
  }))

  return <Tags maxItemsDisplayed={3} {...{ labels, totalCount }} />
}
