import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import moment from "moment"

export const commonLocations = [
  {
    name: localizationKey("Current Location"),
    timeZoneId: "current_location",
  },
  {
    name: "San Francisco, CA",
    timeZoneId: "America/Los_Angeles",
  },
  {
    name: "Denver, CO",
    timeZoneId: "America/Denver",
  },
  {
    name: "Chicago, IL",
    timeZoneId: "America/Chicago",
  },
  {
    name: "New York, NY",
    timeZoneId: "America/New_York",
  },
]

export const customLocations = [
  {
    name: localizationKey("Local Device Time"),
    timeZoneId: "_Local",
  },
  {
    name: localizationKey("System"),
    timeZoneId: "_Division",
  },
]

export const timeZones = [
  {
    timeZoneId: "Africa/Abidjan",
    name: "Africa/Abidjan",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Accra",
    name: "Africa/Accra",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Addis Ababa",
    name: "Africa/Addis Ababa",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Africa/Algiers",
    name: "Africa/Algiers",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Asmara",
    name: "Africa/Asmara",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Africa/Bamako",
    name: "Africa/Bamako",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Bangui",
    name: "Africa/Bangui",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Banjul",
    name: "Africa/Banjul",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Bissau",
    name: "Africa/Bissau",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Brazzaville",
    name: "Africa/Brazzaville",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Bujumbura",
    name: "Africa/Bujumbura",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Cairo",
    name: "Africa/Cairo",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Casablanca",
    name: "Africa/Casablanca",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Ceuta",
    name: "Africa/Ceuta",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Conakry",
    name: "Africa/Conakry",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Dakar",
    name: "Africa/Dakar",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Dar_es_Salaam",
    name: "Africa/Dar_es_Salaam",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Africa/Djibouti",
    name: "Africa/Djibouti",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Africa/Douala",
    name: "Africa/Douala",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/El_Aaiun",
    name: "Africa/El_Aaiun",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Freetown",
    name: "Africa/Freetown",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Blantyre",
    name: "Africa/Blantyre",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Gaborone",
    name: "Africa/Gaborone",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Harare",
    name: "Africa/Harare",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Johannesburg",
    name: "Africa/Johannesburg",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Kampala",
    name: "Africa/Kampala",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Africa/Khartoum",
    name: "Africa/Khartoum",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Africa/Kigali",
    name: "Africa/Kigali",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Kinshasa",
    name: "Africa/Kinshasa",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Lagos",
    name: "Africa/Lagos",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Libreville",
    name: "Africa/Libreville",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Lome",
    name: "Africa/Lome",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Luanda",
    name: "Africa/Luanda",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Lubumbashi",
    name: "Africa/Lubumbashi",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Lusaka",
    name: "Africa/Lusaka",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Malabo",
    name: "Africa/Malabo",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Maputo",
    name: "Africa/Maputo",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Maseru",
    name: "Africa/Maseru",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Mbabane",
    name: "Africa/Mbabane",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Mogadishu",
    name: "Africa/Mogadishu",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Africa/Monrovia",
    name: "Africa/Monrovia",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Nairobi",
    name: "Africa/Nairobi",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Africa/Ndjamena",
    name: "Africa/Ndjamena",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Niamey",
    name: "Africa/Niamey",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Nouakchott",
    name: "Africa/Nouakchott",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Ouagadougou",
    name: "Africa/Ouagadougou",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Porto-Novo",
    name: "Africa/Porto-Novo",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Sao_Tome",
    name: "Africa/Sao Tome",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Africa/Tripoli",
    name: "Africa/Tripoli",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Africa/Tunis",
    name: "Africa/Tunis",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Africa/Windhoek",
    name: "Africa/Windhoek",
    offset: "UTC +1",
  },
  {
    timeZoneId: "America/Adak",
    name: "America/Adak",
    offset: "UTC -9",
  },
  {
    timeZoneId: "America/Anchorage",
    name: "America/Anchorage",
    offset: "UTC -8",
  },
  {
    timeZoneId: "America/Anguilla",
    name: "America/Anguilla",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Antigua",
    name: "America/Antigua",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Araguaina",
    name: "America/Araguaina",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/Buenos_Aires",
    name: "America/Argentina/Buenos Aires",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/Catamarca",
    name: "America/Argentina/Catamarca",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/Cordoba",
    name: "America/Argentina/Cordoba",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/Jujuy",
    name: "America/Argentina/Jujuy",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/La_Rioja",
    name: "America/Argentina/La Rioja",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/Mendoza",
    name: "America/Argentina/Mendoza",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/Rio_Gallegos",
    name: "America/Argentina/Rio Gallegos",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/Salta",
    name: "America/Argentina/Salta",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/San_Juan",
    name: "America/Argentina/San Juan",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/San_Luis",
    name: "America/Argentina/San Luis",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/Tucuman",
    name: "America/Argentina/Tucuman",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Argentina/Ushuaia",
    name: "America/Argentina/Ushuaia",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Aruba",
    name: "America/Aruba",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Asuncion",
    name: "America/Asuncion",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Atikokan",
    name: "America/Atikokan",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Bahia_Banderas",
    name: "America/Bahia Banderas",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Bahia",
    name: "America/Bahia",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Barbados",
    name: "America/Barbados",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Belem",
    name: "America/Belem",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Belize",
    name: "America/Belize",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Blanc-Sablon",
    name: "America/Blanc-Sablon",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Boa_Vista",
    name: "America/Boa Vista",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Bogota",
    name: "America/Bogota",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Boise",
    name: "America/Boise",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Cambridge_Bay",
    name: "America/Cambridge Bay",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Campo_Grande",
    name: "America/Campo Grande",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Cancun",
    name: "America/Cancun",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Caracas",
    name: "America/Caracas",
    offset: "UTC -4.5",
  },
  {
    timeZoneId: "America/Cayenne",
    name: "America/Cayenne",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Cayman",
    name: "America/Cayman",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Chihuahua",
    name: "America/Chihuahua",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Costa_Rica",
    name: "America/Costa Rica",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Cuiaba",
    name: "America/Cuiaba",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Curacao",
    name: "America/Curacao",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Danmarkshavn",
    name: "America/Danmarkshavn",
    offset: "UTC +0",
  },
  {
    timeZoneId: "America/Dawson_Creek",
    name: "America/Dawson Creek",
    offset: "UTC -7",
  },
  {
    timeZoneId: "America/Dawson",
    name: "America/Dawson",
    offset: "UTC -7",
  },
  {
    timeZoneId: "America/Detroit",
    name: "America/Detroit",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Dominica",
    name: "America/Dominica",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Edmonton",
    name: "America/Edmonton",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Eirunepe",
    name: "America/Eirunepe",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/El_Salvador",
    name: "America/El Salvador",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Fortaleza",
    name: "America/Fortaleza",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Glace_Bay",
    name: "America/Glace Bay",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Godthab",
    name: "America/Godthab",
    offset: "UTC -2",
  },
  {
    timeZoneId: "America/Goose_Bay",
    name: "America/Goose Bay",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Grand_Turk",
    name: "America/Grand Turk",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Grenada",
    name: "America/Grenada",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Guadeloupe",
    name: "America/Guadeloupe",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Guatemala",
    name: "America/Guatemala",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Guayaquil",
    name: "America/Guayaquil",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Guyana",
    name: "America/Guyana",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Halifax",
    name: "America/Halifax",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Havana",
    name: "America/Havana",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Hermosillo",
    name: "America/Hermosillo",
    offset: "UTC -7",
  },
  {
    timeZoneId: "America/Indiana/Indianapolis",
    name: "America/Indiana/Indianapolis",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Indiana/Knox",
    name: "America/Indiana/Knox",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Indiana/Marengo",
    name: "America/Indiana/Marengo",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Indiana/Petersburg",
    name: "America/Indiana/Petersburg",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Indiana/Tell_City",
    name: "America/Indiana/Tell City",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Indiana/Vevay",
    name: "America/Indiana/Vevay",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Indiana/Vincennes",
    name: "America/Indiana/Vincennes",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Indiana/Winamac",
    name: "America/Indiana/Winamac",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Inuvik",
    name: "America/Inuvik",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Iqaluit",
    name: "America/Iqaluit",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Jamaica",
    name: "America/Jamaica",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Juneau",
    name: "America/Juneau",
    offset: "UTC -8",
  },
  {
    timeZoneId: "America/Kentucky/Louisville",
    name: "America/Kentucky/Louisville",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Kentucky/Monticello",
    name: "America/Kentucky/Monticello",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/La_Paz",
    name: "America/La Paz",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Lima",
    name: "America/Lima",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Maceio",
    name: "America/Maceio",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Managua",
    name: "America/Managua",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Manaus",
    name: "America/Manaus",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Marigot",
    name: "America/Marigot",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Martinique",
    name: "America/Martinique",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Matamoros",
    name: "America/Matamoros",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Mazatlan",
    name: "America/Mazatlan",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Menominee",
    name: "America/Menominee",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Merida",
    name: "America/Merida",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Metlakatla",
    name: "America/Metlakatla",
    offset: "UTC -8",
  },
  {
    timeZoneId: "America/Mexico_City",
    name: "America/Mexico City",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Miquelon",
    name: "America/Miquelon",
    offset: "UTC -2",
  },
  {
    timeZoneId: "America/Moncton",
    name: "America/Moncton",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Monterrey",
    name: "America/Monterrey",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Montevideo",
    name: "America/Montevideo",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Montreal",
    name: "America/Montreal",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Montserrat",
    name: "America/Montserrat",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Nassau",
    name: "America/Nassau",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Nipigon",
    name: "America/Nipigon",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Nome",
    name: "America/Nome",
    offset: "UTC -8",
  },
  {
    timeZoneId: "America/Noronha",
    name: "America/Noronha",
    offset: "UTC -2",
  },
  {
    timeZoneId: "America/North_Dakota/Beulah",
    name: "America/North Dakota/Beulah",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/North_Dakota/Center",
    name: "America/North Dakota/Center",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/North_Dakota/New_Salem",
    name: "America/North Dakota/New Salem",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Ojinaga",
    name: "America/Ojinaga",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Panama",
    name: "America/Panama",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Pangnirtung",
    name: "America/Pangnirtung",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Paramaribo",
    name: "America/Paramaribo",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Phoenix",
    name: "America/Phoenix",
    offset: "UTC -7",
  },
  {
    timeZoneId: "America/Port_of_Spain",
    name: "America/Port of Spain",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Port-au-Prince",
    name: "America/Port-au-Prince",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Porto_Velho",
    name: "America/Porto Velho",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Puerto_Rico",
    name: "America/Puerto Rico",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Rainy_River",
    name: "America/Rainy River",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Rankin_Inlet",
    name: "America/Rankin Inlet",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Recife",
    name: "America/Recife",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Regina",
    name: "America/Regina",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Resolute",
    name: "America/Resolute",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Rio_Branco",
    name: "America/Rio Branco",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Santa_Isabel",
    name: "America/Santa Isabel",
    offset: "UTC -7",
  },
  {
    timeZoneId: "America/Santarem",
    name: "America/Santarem",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Santiago",
    name: "America/Santiago",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Santo_Domingo",
    name: "America/Santo Domingo",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Sao_Paulo",
    name: "America/Sao Paulo",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Scoresbysund",
    name: "America/Scoresbysund",
    offset: "UTC +0",
  },
  {
    timeZoneId: "America/Shiprock",
    name: "America/Shiprock",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Sitka",
    name: "America/Sitka",
    offset: "UTC -8",
  },
  {
    timeZoneId: "America/St_Barthelemy",
    name: "America/St Barthelemy",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/St_Johns",
    name: "America/St Johns",
    offset: "UTC -2.5",
  },
  {
    timeZoneId: "America/St_Kitts",
    name: "America/St Kitts",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/St_Lucia",
    name: "America/St Lucia",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/St_Thomas",
    name: "America/St Thomas",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/St_Vincent",
    name: "America/St Vincent",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Swift_Current",
    name: "America/Swift Current",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Tegucigalpa",
    name: "America/Tegucigalpa",
    offset: "UTC -6",
  },
  {
    timeZoneId: "America/Thule",
    name: "America/Thule",
    offset: "UTC -3",
  },
  {
    timeZoneId: "America/Thunder_Bay",
    name: "America/Thunder Bay",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Tijuana",
    name: "America/Tijuana",
    offset: "UTC -7",
  },
  {
    timeZoneId: "America/Toronto",
    name: "America/Toronto",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Tortola",
    name: "America/Tortola",
    offset: "UTC -4",
  },
  {
    timeZoneId: "America/Vancouver",
    name: "America/Vancouver",
    offset: "UTC -7",
  },
  {
    timeZoneId: "America/Whitehorse",
    name: "America/Whitehorse",
    offset: "UTC -7",
  },
  {
    timeZoneId: "America/Winnipeg",
    name: "America/Winnipeg",
    offset: "UTC -5",
  },
  {
    timeZoneId: "America/Yakutat",
    name: "America/Yakutat",
    offset: "UTC -8",
  },
  {
    timeZoneId: "America/Yellowknife",
    name: "America/Yellowknife",
    offset: "UTC -6",
  },
  {
    timeZoneId: "Antarctica/Casey",
    name: "Antarctica/Casey",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Antarctica/Davis",
    name: "Antarctica/Davis",
    offset: "UTC +7",
  },
  {
    timeZoneId: "Antarctica/DumontDUrville",
    name: "Antarctica/DumontDUrville",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Antarctica/Macquarie",
    name: "Antarctica/Macquarie",
    offset: "UTC +11",
  },
  {
    timeZoneId: "Antarctica/Mawson",
    name: "Antarctica/Mawson",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Antarctica/McMurdo",
    name: "Antarctica/McMurdo",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Antarctica/Palmer",
    name: "Antarctica/Palmer",
    offset: "UTC -3",
  },
  {
    timeZoneId: "Antarctica/Rothera",
    name: "Antarctica/Rothera",
    offset: "UTC -3",
  },
  {
    timeZoneId: "Antarctica/South_Pole",
    name: "Antarctica/South Pole",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Antarctica/Syowa",
    name: "Antarctica/Syowa",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Antarctica/Vostok",
    name: "Antarctica/Vostok",
    offset: "UTC +6",
  },
  {
    timeZoneId: "Arctic/Longyearbyen",
    name: "Arctic/Longyearbyen",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Asia/Aden",
    name: "Asia/Aden",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Almaty",
    name: "Asia/Almaty",
    offset: "UTC +6",
  },
  {
    timeZoneId: "Asia/Amman",
    name: "Asia/Amman",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Anadyr",
    name: "Asia/Anadyr",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Asia/Aqtau",
    name: "Asia/Aqtau",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Asia/Aqtobe",
    name: "Asia/Aqtobe",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Asia/Ashgabat",
    name: "Asia/Ashgabat",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Asia/Baghdad",
    name: "Asia/Baghdad",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Bahrain",
    name: "Asia/Bahrain",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Baku",
    name: "Asia/Baku",
    offset: "UTC +4",
  },
  {
    timeZoneId: "Asia/Bangkok",
    name: "Asia/Bangkok",
    offset: "UTC +7",
  },
  {
    timeZoneId: "Asia/Beirut",
    name: "Asia/Beirut",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Bishkek",
    name: "Asia/Bishkek",
    offset: "UTC +6",
  },
  {
    timeZoneId: "Asia/Brunei",
    name: "Asia/Brunei",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Calcutta",
    name: "Asia/Calcutta",
    offset: "UTC +5.5",
  },
  {
    timeZoneId: "Asia/Choibalsan",
    name: "Asia/Choibalsan",
    offset: "UTC +9",
  },
  {
    timeZoneId: "Asia/Chongqing",
    name: "Asia/Chongqing",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Colombo",
    name: "Asia/Colombo",
    offset: "UTC +5.5",
  },
  {
    timeZoneId: "Asia/Damascus",
    name: "Asia/Damascus",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Dhaka",
    name: "Asia/Dhaka",
    offset: "UTC +6",
  },
  {
    timeZoneId: "Asia/Dili",
    name: "Asia/Dili",
    offset: "UTC +9",
  },
  {
    timeZoneId: "Asia/Dubai",
    name: "Asia/Dubai",
    offset: "UTC +4",
  },
  {
    timeZoneId: "Asia/Dushanbe",
    name: "Asia/Dushanbe",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Asia/Gaza",
    name: "Asia/Gaza",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Harbin",
    name: "Asia/Harbin",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Ho_Chi_Minh",
    name: "Asia/Ho Chi Minh",
    offset: "UTC +7",
  },
  {
    timeZoneId: "Asia/Hong_Kong",
    name: "Asia/Hong Kong",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Hovd",
    name: "Asia/Hovd",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Irkutsk",
    name: "Asia/Irkutsk",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Jakarta",
    name: "Asia/Jakarta",
    offset: "UTC +7",
  },
  {
    timeZoneId: "Asia/Jayapura",
    name: "Asia/Jayapura",
    offset: "UTC +9",
  },
  {
    timeZoneId: "Asia/Jerusalem",
    name: "Asia/Jerusalem",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Kabul",
    name: "Asia/Kabul",
    offset: "UTC +4.5",
  },
  {
    timeZoneId: "Asia/Kamchatka",
    name: "Asia/Kamchatka",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Asia/Karachi",
    name: "Asia/Karachi",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Asia/Kashgar",
    name: "Asia/Kashgar",
    offset: "UTC +6",
  },
  {
    timeZoneId: "Asia/Kathmandu",
    name: "Asia/Kathmandu",
    offset: "UTC +5.75",
  },
  {
    timeZoneId: "Asia/Kolkata",
    name: "Asia/Kolkata",
    offset: "UTC +5.5",
  },
  {
    timeZoneId: "Asia/Krasnoyarsk",
    name: "Asia/Krasnoyarsk",
    offset: "UTC +7",
  },
  {
    timeZoneId: "Asia/Kuala_Lumpur",
    name: "Asia/Kuala Lumpur",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Kuching",
    name: "Asia/Kuching",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Kuwait",
    name: "Asia/Kuwait",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Macau",
    name: "Asia/Macau",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Magadan",
    name: "Asia/Magadan",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Asia/Makassar",
    name: "Asia/Makassar",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Manila",
    name: "Asia/Manila",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Muscat",
    name: "Asia/Muscat",
    offset: "UTC +4",
  },
  {
    timeZoneId: "Asia/Nicosia",
    name: "Asia/Nicosia",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Novokuznetsk",
    name: "Asia/Novokuznetsk",
    offset: "UTC +7",
  },
  {
    timeZoneId: "Asia/Novosibirsk",
    name: "Asia/Novosibirsk",
    offset: "UTC +6",
  },
  {
    timeZoneId: "Asia/Omsk",
    name: "Asia/Omsk",
    offset: "UTC +6",
  },
  {
    timeZoneId: "Asia/Oral",
    name: "Asia/Oral",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Asia/Phnom_Penh",
    name: "Asia/Phnom Penh",
    offset: "UTC +7",
  },
  {
    timeZoneId: "Asia/Pontianak",
    name: "Asia/Pontianak",
    offset: "UTC +7",
  },
  {
    timeZoneId: "Asia/Pyongyang",
    name: "Asia/Pyongyang",
    offset: "UTC +8.5",
  },
  {
    timeZoneId: "Asia/Qatar",
    name: "Asia/Qatar",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Qyzylorda",
    name: "Asia/Qyzylorda",
    offset: "UTC +6",
  },
  {
    timeZoneId: "Asia/Rangoon",
    name: "Asia/Rangoon",
    offset: "UTC +6.5",
  },
  {
    timeZoneId: "Asia/Riyadh",
    name: "Asia/Riyadh",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Asia/Sakhalin",
    name: "Asia/Sakhalin",
    offset: "UTC +11",
  },
  {
    timeZoneId: "Asia/Samarkand",
    name: "Asia/Samarkand",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Asia/Seoul",
    name: "Asia/Seoul",
    offset: "UTC +9",
  },
  {
    timeZoneId: "Asia/Shanghai",
    name: "Asia/Shanghai",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Singapore",
    name: "Asia/Singapore",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Taipei",
    name: "Asia/Taipei",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Asia/Tashkent",
    name: "Asia/Tashkent",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Asia/Tbilisi",
    name: "Asia/Tbilisi",
    offset: "UTC +4",
  },
  {
    timeZoneId: "Asia/Tehran",
    name: "Asia/Tehran",
    offset: "UTC +4.5",
  },
  {
    timeZoneId: "Asia/Thimphu",
    name: "Asia/Thimphu",
    offset: "UTC +6",
  },
  {
    timeZoneId: "Asia/Tokyo",
    name: "Asia/Tokyo",
    offset: "UTC +9",
  },
  {
    timeZoneId: "Asia/Ulaanbaatar",
    name: "Asia/Ulaanbaatar",
    offset: "UTC +9",
  },
  {
    timeZoneId: "Asia/Urumqi",
    name: "Asia/Urumqi",
    offset: "UTC +6",
  },
  {
    timeZoneId: "Asia/Vientiane",
    name: "Asia/Vientiane",
    offset: "UTC +7",
  },
  {
    timeZoneId: "Asia/Vladivostok",
    name: "Asia/Vladivostok",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Asia/Yakutsk",
    name: "Asia/Yakutsk",
    offset: "UTC +9",
  },
  {
    timeZoneId: "Asia/Yekaterinburg",
    name: "Asia/Yekaterinburg",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Asia/Yerevan",
    name: "Asia/Yerevan",
    offset: "UTC +4",
  },
  {
    timeZoneId: "Atlantic/Azores",
    name: "Atlantic/Azores",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Atlantic/Bermuda",
    name: "Atlantic/Bermuda",
    offset: "UTC -3",
  },
  {
    timeZoneId: "Atlantic/Canary",
    name: "Atlantic/Canary",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Atlantic/Cape_Verde",
    name: "Atlantic/Cape Verde",
    offset: "UTC -1",
  },
  {
    timeZoneId: "Atlantic/Faroe",
    name: "Atlantic/Faroe",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Atlantic/Madeira",
    name: "Atlantic/Madeira",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Atlantic/Reykjavik",
    name: "Atlantic/Reykjavik",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Atlantic/South_Georgia",
    name: "Atlantic/South Georgia",
    offset: "UTC -2",
  },
  {
    timeZoneId: "Atlantic/St_Helena",
    name: "Atlantic/St Helena",
    offset: "UTC +0",
  },
  {
    timeZoneId: "Atlantic/Stanley",
    name: "Atlantic/Stanley",
    offset: "UTC -3",
  },
  {
    timeZoneId: "Australia/Adelaide",
    name: "Australia/Adelaide",
    offset: "UTC +9.5",
  },
  {
    timeZoneId: "Australia/Brisbane",
    name: "Australia/Brisbane",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Australia/Broken_Hill",
    name: "Australia/Broken Hill",
    offset: "UTC +9.5",
  },
  {
    timeZoneId: "Australia/Currie",
    name: "Australia/Currie",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Australia/Darwin",
    name: "Australia/Darwin",
    offset: "UTC +9.5",
  },
  {
    timeZoneId: "Australia/Eucla",
    name: "Australia/Eucla",
    offset: "UTC +8.75",
  },
  {
    timeZoneId: "Australia/Hobart",
    name: "Australia/Hobart",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Australia/Lindeman",
    name: "Australia/Lindeman",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Australia/Lord_Howe",
    name: "Australia/Lord Howe",
    offset: "UTC +10.5",
  },
  {
    timeZoneId: "Australia/Melbourne",
    name: "Australia/Melbourne",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Australia/Perth",
    name: "Australia/Perth",
    offset: "UTC +8",
  },
  {
    timeZoneId: "Australia/Sydney",
    name: "Australia/Sydney",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Europe/Amsterdam",
    name: "Europe/Amsterdam",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Andorra",
    name: "Europe/Andorra",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Athens",
    name: "Europe/Athens",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Belgrade",
    name: "Europe/Belgrade",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Berlin",
    name: "Europe/Berlin",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Bratislava",
    name: "Europe/Bratislava",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Brussels",
    name: "Europe/Brussels",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Bucharest",
    name: "Europe/Bucharest",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Budapest",
    name: "Europe/Budapest",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Chisinau",
    name: "Europe/Chisinau",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Copenhagen",
    name: "Europe/Copenhagen",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Dublin",
    name: "Europe/Dublin",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Europe/Gibraltar",
    name: "Europe/Gibraltar",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Guernsey",
    name: "Europe/Guernsey",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Europe/Helsinki",
    name: "Europe/Helsinki",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Isle_of_Man",
    name: "Europe/Isle of Man",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Europe/Istanbul",
    name: "Europe/Istanbul",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Jersey",
    name: "Europe/Jersey",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Europe/Kaliningrad",
    name: "Europe/Kaliningrad",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Kiev",
    name: "Europe/Kiev",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Lisbon",
    name: "Europe/Lisbon",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Europe/Ljubljana",
    name: "Europe/Ljubljana",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/London",
    name: "Europe/London",
    offset: "UTC +1",
  },
  {
    timeZoneId: "Europe/Luxembourg",
    name: "Europe/Luxembourg",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Madrid",
    name: "Europe/Madrid",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Malta",
    name: "Europe/Malta",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Mariehamn",
    name: "Europe/Mariehamn",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Minsk",
    name: "Europe/Minsk",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Monaco",
    name: "Europe/Monaco",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Moscow",
    name: "Europe/Moscow",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Oslo",
    name: "Europe/Oslo",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Paris",
    name: "Europe/Paris",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Podgorica",
    name: "Europe/Podgorica",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Prague",
    name: "Europe/Prague",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Riga",
    name: "Europe/Riga",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Rome",
    name: "Europe/Rome",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Samara",
    name: "Europe/Samara",
    offset: "UTC +4",
  },
  {
    timeZoneId: "Europe/San_Marino",
    name: "Europe/San Marino",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Sarajevo",
    name: "Europe/Sarajevo",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Simferopol",
    name: "Europe/Simferopol",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Skopje",
    name: "Europe/Skopje",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Sofia",
    name: "Europe/Sofia",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Stockholm",
    name: "Europe/Stockholm",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Tallinn",
    name: "Europe/Tallinn",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Tirane",
    name: "Europe/Tirane",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Uzhgorod",
    name: "Europe/Uzhgorod",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Vaduz",
    name: "Europe/Vaduz",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Vatican",
    name: "Europe/Vatican",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Vienna",
    name: "Europe/Vienna",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Vilnius",
    name: "Europe/Vilnius",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Volgograd",
    name: "Europe/Volgograd",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Warsaw",
    name: "Europe/Warsaw",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Zagreb",
    name: "Europe/Zagreb",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Europe/Zaporozhye",
    name: "Europe/Zaporozhye",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Europe/Zurich",
    name: "Europe/Zurich",
    offset: "UTC +2",
  },
  {
    timeZoneId: "Indian/Antananarivo",
    name: "Indian/Antananarivo",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Indian/Chagos",
    name: "Indian/Chagos",
    offset: "UTC +6",
  },
  {
    timeZoneId: "Indian/Christmas",
    name: "Indian/Christmas",
    offset: "UTC +7",
  },
  {
    timeZoneId: "Indian/Cocos",
    name: "Indian/Cocos",
    offset: "UTC +6.5",
  },
  {
    timeZoneId: "Indian/Comoro",
    name: "Indian/Comoro",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Indian/Kerguelen",
    name: "Indian/Kerguelen",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Indian/Mahe",
    name: "Indian/Mahe",
    offset: "UTC +4",
  },
  {
    timeZoneId: "Indian/Maldives",
    name: "Indian/Maldives",
    offset: "UTC +5",
  },
  {
    timeZoneId: "Indian/Mauritius",
    name: "Indian/Mauritius",
    offset: "UTC +4",
  },
  {
    timeZoneId: "Indian/Mayotte",
    name: "Indian/Mayotte",
    offset: "UTC +3",
  },
  {
    timeZoneId: "Indian/Reunion",
    name: "Indian/Reunion",
    offset: "UTC +4",
  },
  {
    timeZoneId: "Pacific/Apia",
    name: "Pacific/Apia",
    offset: "UTC +13",
  },
  {
    timeZoneId: "Pacific/Auckland",
    name: "Pacific/Auckland",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Pacific/Chatham",
    name: "Pacific/Chatham",
    offset: "UTC +12.75",
  },
  {
    timeZoneId: "Pacific/Chuuk",
    name: "Pacific/Chuuk",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Pacific/Easter",
    name: "Pacific/Easter",
    offset: "UTC -5",
  },
  {
    timeZoneId: "Pacific/Efate",
    name: "Pacific/Efate",
    offset: "UTC +11",
  },
  {
    timeZoneId: "Pacific/Enderbury",
    name: "Pacific/Enderbury",
    offset: "UTC +13",
  },
  {
    timeZoneId: "Pacific/Fakaofo",
    name: "Pacific/Fakaofo",
    offset: "UTC +13",
  },
  {
    timeZoneId: "Pacific/Fiji",
    name: "Pacific/Fiji",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Pacific/Funafuti",
    name: "Pacific/Funafuti",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Pacific/Galapagos",
    name: "Pacific/Galapagos",
    offset: "UTC -6",
  },
  {
    timeZoneId: "Pacific/Gambier",
    name: "Pacific/Gambier",
    offset: "UTC -9",
  },
  {
    timeZoneId: "Pacific/Guadalcanal",
    name: "Pacific/Guadalcanal",
    offset: "UTC +11",
  },
  {
    timeZoneId: "Pacific/Guam",
    name: "Pacific/Guam",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Pacific/Honolulu",
    name: "Pacific/Honolulu",
    offset: "UTC -10",
  },
  {
    timeZoneId: "Pacific/Johnston",
    name: "Pacific/Johnston",
    offset: "UTC -10",
  },
  {
    timeZoneId: "Pacific/Kiritimati",
    name: "Pacific/Kiritimati",
    offset: "UTC +14",
  },
  {
    timeZoneId: "Pacific/Kosrae",
    name: "Pacific/Kosrae",
    offset: "UTC +11",
  },
  {
    timeZoneId: "Pacific/Kwajalein",
    name: "Pacific/Kwajalein",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Pacific/Majuro",
    name: "Pacific/Majuro",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Pacific/Marquesas",
    name: "Pacific/Marquesas",
    offset: "UTC -9.5",
  },
  {
    timeZoneId: "Pacific/Midway",
    name: "Pacific/Midway",
    offset: "UTC -11",
  },
  {
    timeZoneId: "Pacific/Nauru",
    name: "Pacific/Nauru",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Pacific/Niue",
    name: "Pacific/Niue",
    offset: "UTC -11",
  },
  {
    timeZoneId: "Pacific/Norfolk",
    name: "Pacific/Norfolk",
    offset: "UTC +11",
  },
  {
    timeZoneId: "Pacific/Noumea",
    name: "Pacific/Noumea",
    offset: "UTC +11",
  },
  {
    timeZoneId: "Pacific/Pago_Pago",
    name: "Pacific/Pago Pago",
    offset: "UTC -11",
  },
  {
    timeZoneId: "Pacific/Palau",
    name: "Pacific/Palau",
    offset: "UTC +9",
  },
  {
    timeZoneId: "Pacific/Pitcairn",
    name: "Pacific/Pitcairn",
    offset: "UTC -8",
  },
  {
    timeZoneId: "Pacific/Pohnpei",
    name: "Pacific/Pohnpei",
    offset: "UTC +11",
  },
  {
    timeZoneId: "Pacific/Port_Moresby",
    name: "Pacific/Port Moresby",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Pacific/Rarotonga",
    name: "Pacific/Rarotonga",
    offset: "UTC -10",
  },
  {
    timeZoneId: "Pacific/Saipan",
    name: "Pacific/Saipan",
    offset: "UTC +10",
  },
  {
    timeZoneId: "Pacific/Tahiti",
    name: "Pacific/Tahiti",
    offset: "UTC -10",
  },
  {
    timeZoneId: "Pacific/Tarawa",
    name: "Pacific/Tarawa",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Pacific/Tongatapu",
    name: "Pacific/Tongatapu",
    offset: "UTC +13",
  },
  {
    timeZoneId: "Pacific/Wake",
    name: "Pacific/Wake",
    offset: "UTC +12",
  },
  {
    timeZoneId: "Pacific/Wallis",
    name: "Pacific/Wallis",
    offset: "UTC +12",
  },
]

export function getTimeZone(selectedTimeZoneId) {
  const customLocation = customLocations.find(timezone => timezone.timeZoneId === selectedTimeZoneId)
  const commonLocation = commonLocations.find(timezone => timezone.timeZoneId === selectedTimeZoneId)
  const location = timeZones.find(timezone => timezone.timeZoneId === selectedTimeZoneId)
  const currentTimeZone = commonLocation || customLocation || location || {}
  const timeZoneId = currentTimeZone.timeZoneId === "current_location" ? moment.tz.guess() : currentTimeZone.timeZoneId
  const timeZone = timeZoneId === "_Local" || timeZoneId === "_Division" || !timeZoneId ? null : moment.tz(timeZoneId)
  const timeZoneName = customLocation ? localized(currentTimeZone.name) : currentTimeZone.name
  const abbreviation = timeZone ? timeZone.zoneAbbr() : localized("Local Device Time")
  return { timeZone, timeZoneName, currentTimeZone, abbreviation }
}
