import React from "react"
import { Form } from "react-bootstrap"
import { find, propEq, last } from "ramda"
import { localized, localizationKey, validations } from "js/includes/common/utils"
import { useForm } from "js/includes/common/hooks"
import { StyledCol, StyledFormGroup, StyledModal, StyledButton } from "js/includes/components/Styled/Form"
import { StyledSpan } from "js/includes/components/Styled"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import showModal from "js/includes/common/services/showModal"
import ADStyledModalContainer from "../components/ADStyledModalContainer"
import CreateCredentialsModal from "./CreateCredentialsModal"

export default function SelectCredentialsModal({
  unmount,
  onSetCredential,
  credentials,
  defaultCredentialId,
  isLoadingCredentials,
  setCredentials,
  clientId,
}) {
  const { values, validation, validateForm, onChange } = useForm({
    fields: {
      credentials: credentials || [],
      credential: find(propEq("id", defaultCredentialId), credentials || []) || null,
    },
    validate: {
      credential: validations.required,
    },
  })

  return (
    <Form
      horizontal
      onSubmit={e => {
        e.preventDefault()
        const { credential } = values
        if (validateForm()) {
          onSetCredential(credential)
          unmount()
        }
      }}
    >
      <ADStyledModalContainer>
        <StyledModal
          close={unmount}
          submittable
          overflow
          saveText={localizationKey("Set")}
          noHeader
          backgroundColor="#fff !important"
          borderRadius="5px"
          overflowY="visible !important"
        >
          <StyledSpan
            fontSize="20px"
            fontWeight="600"
            margin={[2, 0, 3]}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            {localized("Select Credentials")}
          </StyledSpan>
          <StyledFormGroup
            validationState={validation.success.credential === false ? "error" : null}
            margin="0px !important"
          >
            <StyledCol display="flex" justifyContent="space-between">
              <StyledSpan fontSize="14px" marginBottom={1}>
                {localized("Credentials")}
              </StyledSpan>
              <StyledButton
                type="button"
                className="btn-link"
                fontSize="14px"
                marginBottom={1}
                onClick={() => {
                  showModal(
                    <CreateCredentialsModal
                      {...{
                        clientId,
                        setCredentials: credentials => {
                          onChange({ credentials, credential: last(credentials) })
                          setCredentials(credentials)
                        },
                      }}
                    />,
                    { withProvider: true },
                  )
                }}
              >
                {localized("Add Credential")}
              </StyledButton>
            </StyledCol>
            <StyledCol>
              <SearchableDropDown
                {...{
                  loading: isLoadingCredentials,
                  width: "100%",
                  value: values.credential,
                  options: values.credentials,
                  rowHeight: 35,
                  openOnFocus: true,
                  searchableKey: "name",
                  valueSelectorKey: "id",
                  searchPlaceholderToken: localizationKey("Select"),
                  rowRenderer: rowData => <StyledSpan>{rowData.name}</StyledSpan>,
                  onSelect: credential => {
                    onChange({ credential })
                  },
                  validationState: validation.success.credential === false ? "error" : null,
                }}
              />
            </StyledCol>
          </StyledFormGroup>
        </StyledModal>
      </ADStyledModalContainer>
    </Form>
  )
}
