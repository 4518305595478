import { getBoardsConfiguration } from "js/includes/common/client"
import { reportErrorAndShowMessage, isUserAllowedToViewBoards, localizationKey } from "js/includes/common/utils"

export const fetchBoardsConfiguration = async dispatch => {
  try {
    const shouldRequest = isUserAllowedToViewBoards()

    if (!shouldRequest) return

    const boardsConfiguration = await getBoardsConfiguration()

    dispatch({
      type: "SET_BOARDS_CONFIGURATION",
      boardsConfiguration,
    })
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error getting boards configuration"))
  }
}
