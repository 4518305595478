import { createElement } from "react"
import { nanoid } from "nanoid"
import { always, cond, equals, partial, path, T } from "ramda"
import { connect } from "react-redux"
import { Form } from "react-bootstrap"
import { ButtonGroup, Modal, Text } from "@ninjaone/components"
import { noop } from "@ninjaone/utils"
import { localized, localizationKey, isNotNilOrEmpty, isValidSecureURL, validations } from "js/includes/common/utils"
import { setValue } from "js/state/actions/common/value"
import { getParameterModalScript } from "js/state/selectors/policyEditor/scripting/scriptModal"
import { setScriptName } from "js/includes/components/scripting/ScriptsSelector/ScriptsTable"
import { useForm } from "js/includes/common/hooks"
import {
  mapScriptVariablesToFields,
  mapScriptVariablesToValidates,
  getUpdatedScriptVariables,
} from "js/includes/components/scripting/ScriptsSelector/ParameterModal/scriptVariableUtils"
import { Flex } from "js/includes/components/Styled"

const fieldRequiredCheck = value => ({ success: isNotNilOrEmpty(value), message: localized("Required") })

const isValidInstallerUrl = value => {
  if (!isNotNilOrEmpty(value)) {
    return { success: false, message: localized("Required") }
  }
  if (!isValidSecureURL(value)) {
    return { success: false, message: localized("Please enter a valid URL") }
  }

  return { success: true }
}

const requiredScriptFields = {
  "a8c2658d-add7-49fa-92f1-caa7e0daf656": ["volumeName", "checkList"],
  "258836d1-5aa1-424e-900a-20cf22b228a3": ["enabled"],
  "e027f5a2-9182-4ea0-8f01-867eb299afd3": ["appName", "exePath", "runAs", "supportedArchitecture"],
  "73b61bdb-8db2-4f54-8546-a194aef954da": ["appName", "msiUrlPath", "runAs", "supportedArchitecture"],
  "cf405def-5ea5-4a1c-b39d-160e6244d510": ["appName", "urlPath", "runAs", "supportedArchitecture"],
  "7dd142b2-ce16-44ff-a1d6-de2c1baa1749": ["serviceName", "start"],
  "2fed9333-c4fd-4e6b-9913-375a8d181376": ["enabled"],
}

const ParameterModal = ({
  devices,
  applyScript,
  parameterModal,
  onAfterApply,
  setParameterModal,
  unmount,
  isAdhocJob = false,
  fromDeviceSearch = false,
  selectedCount,
}) => {
  const modalScriptVariables = parameterModal?.scriptVariables
  const { onChange, resetForm, validateForm, validation, values } = useForm({
    fields: {
      ...parameterModal?.parameters,
      scriptVariables: {
        ...mapScriptVariablesToFields(modalScriptVariables),
      },
    },
    validate: {
      appName: fieldRequiredCheck,
      checkList: fieldRequiredCheck,
      enabled: fieldRequiredCheck,
      exePath: fieldRequiredCheck,
      msiUrlPath: isValidInstallerUrl,
      urlPath: isValidInstallerUrl,
      runAs: validations.required,
      serviceName: fieldRequiredCheck,
      start: fieldRequiredCheck,
      supportedArchitecture: fieldRequiredCheck,
      volumeName: fieldRequiredCheck,
      scriptVariables: {
        ...mapScriptVariablesToValidates(modalScriptVariables),
      },
    },
  })

  const close = () => {
    resetForm()
    setParameterModal(null)
    unmount()
  }

  const updateMsiUrlPath = partial(onChange, ["msiUrlPath"])
  const updateUrlPath = partial(onChange, ["urlPath"])
  const updateFileName = partial(onChange, ["fileName"])

  const getUpdateUrlPath = cond([
    [equals("Windows"), always(updateMsiUrlPath)],
    [equals("Mac"), always(updateUrlPath)],
    [T, noop],
  ])

  const submit = () => {
    const { scriptVariables, ...parameters } = values
    const { scriptId, id } = parameterModal
    const script = {
      scriptName: setScriptName(parameterModal, values),
      scriptVariables: getUpdatedScriptVariables(modalScriptVariables, scriptVariables, isAdhocJob),
      id,
      scriptId,
      parameters,
    }
    if (
      (!requiredScriptFields[parameterModal?.scriptId] && validateForm(["runAs"])) ||
      validateForm(requiredScriptFields[parameterModal?.scriptId])
    ) {
      if (parameterModal.parameters && parameterModal.id) {
        applyScript({ type: "EDIT", script })
      } else {
        const newScript = { ...script, id: nanoid(10) }
        applyScript({ type: "ADD", script: newScript })
        onAfterApply()
      }
      close()
    }
  }

  if (!parameterModal) return null

  const { name, scriptDescription } = parameterModal

  return (
    <Modal
      {...{
        size: "sm",
        unmount: close,
        withCloseX: true,
        titleGroup: {
          titleText: name ?? localized("Add automation"),
          descriptionText: scriptDescription,
        },
        buttonRenderer: () => {
          return (
            <Flex alignItems="center" justifyContent="between">
              {(fromDeviceSearch ? selectedCount : devices?.length) > 1 && (
                <Text size="sm" bold>
                  {localized("{{devicesCount}} devices selected", {
                    devicesCount: fromDeviceSearch ? selectedCount : devices.length,
                  })}
                </Text>
              )}
              <ButtonGroup
                buttons={[
                  { type: "close", onClick: unmount, variant: "secondary" },
                  {
                    type: "save",
                    labelToken: isAdhocJob ? localizationKey("Run") : localizationKey("Apply"),
                    onClick: () => {
                      if (validateForm()) {
                        submit()
                      }
                    },
                  },
                ]}
              />
            </Flex>
          )
        },
      }}
    >
      <Form
        onSubmit={e => {
          e.preventDefault()
          submit()
        }}
      >
        {createElement(parameterModal.parameterComponent, {
          ...{
            onChange,
            updateUrlPath: getUpdateUrlPath(parameterModal.deviceType),
            updateFileName,
            validateForm,
            validation,
            values,
            deviceType: parameterModal.deviceType,
          },
        })}
      </Form>
    </Modal>
  )
}

export default connect(
  state => ({
    initialValues: path(["parameters"], state.policyEditor.scripting.parameterModal),
    parameterModal: getParameterModalScript(state),
  }),
  {
    setParameterModal: setValue("parameterModal"),
  },
)(ParameterModal)
