import { useCallback, useEffect } from "react"
import styled from "@emotion/styled"
import { ButtonGroup, Modal, Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { useMountedState } from "js/includes/common/hooks"
import { colors } from "js/includes/common/theme"
import { localizationKey } from "js/includes/common/utils"
import { getUploadStatusIcon } from "js/includes/components/Attachment"
import { Box, Flex } from "js/includes/components/Styled"
import { uploadFile } from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/utils"

const StyledProgressBar = styled.div`
  padding: 0px;
  width: 100%;
  border-radius: 7px;
  height: 8px;
  margin-top: ${sizer(1)};
  background: rgba(33, 31, 51, 0.1);
`
const StyledProgress = styled.div`
  height: 100%;
  width: ${({ progress }) => progress}%;
  background-color: ${colors.ninjaBlueSaturated};
  border-radius: 7px;
  animation: fill-bar 3s infinite;
`

const UploadingFilesModal = ({ unmount, filesToUpload, cancelUpload, afterUpload }) => {
  const [filesData, setFilesData] = useMountedState([])
  const [completedUploadFiles, setCompletedUploadFiles] = useMountedState([])

  const updateFileData = useCallback(
    (key, id, value) => {
      setFilesData(prev => prev.map(items => (items.key === key ? { ...items, [id]: value } : items)))
    },
    [setFilesData],
  )

  const setProgressData = useCallback(
    (value, key) => {
      updateFileData(key, "progress", value)
    },
    [updateFileData],
  )

  const setSuccess = useCallback(
    (id, key) => {
      setCompletedUploadFiles(prev => [...prev, { key, id }])
    },
    [setCompletedUploadFiles],
  )

  const cancelAction = () => {
    filesData.forEach(({ xhr, metadata }) => xhr.abort())
    unmount()
    cancelUpload()
  }

  useEffect(() => {
    async function activateUploadFile() {
      const files = []

      filesToUpload.forEach(async items => {
        const xhr = await uploadFile(items, setProgressData, setSuccess)
        files.push({ ...items, xhr, progress: 0 })
      })

      setFilesData(files)
    }

    activateUploadFile()
  }, [filesToUpload, setFilesData, setProgressData, setSuccess])

  useEffect(() => {
    if (completedUploadFiles.length === filesToUpload.length) {
      afterUpload(completedUploadFiles)
      unmount()
    }
  }, [completedUploadFiles, filesToUpload, afterUpload, unmount])

  return (
    <Modal
      unmount={cancelAction}
      withCloseX={false}
      size="md"
      titleGroup={{
        titleToken: localizationKey("Uploading files..."),
        descriptionToken: localizationKey(
          "Please keep this pop up open while uploads are in progress or the upload operation may be cancelled.",
        ),
      }}
      buttonRenderer={() => (
        <ButtonGroup
          {...{
            buttons: [
              {
                type: "cancel",
                labelToken: localizationKey("Cancel upload"),
                onClick: cancelAction,
              },
            ],
          }}
        />
      )}
    >
      {filesData.map(fileItems => {
        return (
          <Box key={fileItems.file.name} margin="20px">
            <Flex>
              <Box margin="auto">
                {getUploadStatusIcon(
                  { uploadStatus: fileItems.uploadStatus, extension: fileItems.metadata.extension },
                  {},
                )}
              </Box>
              <Box width="100%" margin={sizer(0, 2)}>
                <Flex>
                  <Box width="90%">
                    <Text size="sm" token={fileItems.metadata.name} bold />
                  </Box>
                  <Box textAlign="end" width="10%">
                    <Text size="sm" token={`${fileItems.progress}%`} />
                  </Box>
                </Flex>
                <StyledProgressBar>
                  <StyledProgress progress={fileItems.progress} />
                </StyledProgressBar>
              </Box>
            </Flex>
          </Box>
        )
      })}
    </Modal>
  )
}

export default UploadingFilesModal
