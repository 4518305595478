import { Text, ConfirmationModal } from "@ninjaone/components"
import { StyledAnchor } from "js/includes/components/Styled"
import { localizationKey, localizedWith } from "js/includes/common/utils"

export const DiscoverySuccessModal = ({ unmount, values: { org, networkProbe }, devicesForReview }) => {
  const href = `/#/${networkProbe.nodeClass.includes("NMS") ? "nmsDashboard" : "deviceDashboard"}/${
    networkProbe.nodeId
  }/overview`
  return (
    <ConfirmationModal
      titleToken={localizationKey("Successfully added devices")}
      actionToken={localizationKey("Go to network probe")}
      closeToken={localizationKey("Close")}
      unmount={unmount}
      onConfirm={() => {
        window.location.href = href
        window.location.reload()
      }}
    >
      <Text textWrap size="sm">
        {devicesForReview.length === 1
          ? localizedWith("1 target device added to <%org>x<%> and will be monitored by <%probe>x<%>", {
              org: org.name,
              probe: () => <StyledAnchor href={href}>{networkProbe.nodeName}</StyledAnchor>,
            })
          : localizedWith(
              "<%numDevices>x<%> target devices added to <%org>x<%> and will be monitored by <%probe>x<%>",
              {
                numDevices: devicesForReview.length,
                org: org.name,
                probe: () => <StyledAnchor href={href}>{networkProbe.nodeName}</StyledAnchor>,
              },
            )}
      </Text>
    </ConfirmationModal>
  )
}
