import { cond, startsWith, always, T } from "ramda"

export const getDeviceType = cond([
  [startsWith("WINDOWS_"), always("Windows")],
  [startsWith("MAC"), always("Mac")],
  [startsWith("LINUX"), always("Linux")],
  [
    T,
    i => {
      throw new Error(`Invalid nodeRole "${i}"`)
    },
  ],
])

export const isDevice = type => ["DEVICE", "VM", "VM_HOST", "VM_GUEST", "MONITOR", "NMS"].includes(type)
