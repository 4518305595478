import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { Item } from "@radix-ui/react-radio-group"

import tokens from "@ninjaone/tokens"

import RadioLabel from "./RadioLabel"
import RadioIndicator from "./RadioIndicator"
import { shouldIgnoreProps, getRadioAriaLabel, getSize } from "./utils"
import { cardContentHeight, cardContentWithDescriptionHeight, variants } from "./constants"

const StyledCardItem = styled(Item, {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  all: unset;

  display: flex;
  flex-direction: column;

  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  border-radius: ${tokens.borderRadius[1]};

  background-color: ${({ theme }) => theme.colorBackgroundWidget};

  min-width: ${({ size }) => getSize({ size }).width};
  width: ${({ size }) => getSize({ size }).width};

  min-height: ${({ size }) => getSize({ size }).height};
  height: ${({ size }) => getSize({ size }).height};

  img {
    border-radius: ${tokens.borderRadius[1]} ${tokens.borderRadius[1]} 0 0;
    background-color: ${({ theme }) => theme.colorBackgroundSubtle};

    max-width: 100%;
    height: calc(
      100% - ${({ descriptionMode }) => (descriptionMode ? cardContentWithDescriptionHeight : cardContentHeight)}
    );

    flex: 1;
    object-fit: fill;
  }

  &:focus {
    outline-offset: -0.5px;
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }

  // styling the radio card circle for the disabled state
  &[data-disabled][data-state="unchecked"] > [data-ninja-radio-card-container] > div {
    background: ${({ theme }) => theme.colorBackgroundInputDisabled};
  }

  // styling the radio card for the hover state
  &:not([data-disabled]):hover > [data-ninja-radio-card-container] {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &[data-state="checked"]:not([data-disabled]) > [data-ninja-radio-card-container] {
    background-color: ${({ theme }) => theme.colorForegroundSelected};
  }
`

const StyledCardContentContainer = styled("div", {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  border-top: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  border-radius: 0 0 ${tokens.borderRadius[1]} ${tokens.borderRadius[1]};

  background-color: ${({ theme }) => theme.colorBackgroundWidget};

  width: 100%;
  padding: ${tokens.spacing[3]};
  height: ${({ descriptionMode }) => (descriptionMode ? cardContentWithDescriptionHeight : cardContentHeight)};

  display: flex;
  align-items: ${({ descriptionMode }) => (!descriptionMode ? "center" : "start")};
  gap: ${tokens.spacing[3]};
`

function RadioCardOption({ label, image, value, description, extraContentRenderer, descriptionMode, disabled, size }) {
  const ariaLabel = getRadioAriaLabel({ label, description })
  const variant = variants.CARD

  return (
    <StyledCardItem {...{ id: value, value, disabled, "aria-label": ariaLabel, descriptionMode, size }}>
      <img {...{ alt: label, src: image }} />

      <StyledCardContentContainer {...{ descriptionMode, "data-ninja-radio-card-container": "" }}>
        <RadioIndicator {...{ variant }} />

        <RadioLabel
          {...{
            value,
            label,
            variant,
            description,
            descriptionMode,
            extraContentRenderer,
          }}
        />
      </StyledCardContentContainer>
    </StyledCardItem>
  )
}

export default RadioCardOption
