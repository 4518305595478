import { memo } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { slice } from "ramda"
import { CaretRightIcon } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"

const StyledBreadcrumbContainer = styled.nav`
  font-size: ${getTextSize("sm")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : sizer(4))};
  width: ${({ width }) => width || "100%"};
  display: flex;
  align-items: center;

  .disabled {
    cursor: default;
    color: ${({ theme }) => theme.colorTextWeakest};
  }

  .breadcrumb-history-root-icon {
    width: 38px;
    line-height: 1;
  }

  .breadcrumb-history-path-wrapper {
    flex: 1;
    min-width: 1px;

    .breadcrumb-history-path {
      width: 100%;
      text-overflow: ellipsis;
      padding: 2px 0;
      display: flex;
      flex-wrap: nowrap;
      margin: 0;

      .active-link {
        color: ${({ theme }) => theme.colorTextWeak};
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      li {
        white-space: nowrap;
      }
    }

    .clickable-link {
      color: ${({ colors, theme }) => (colors ? colors.link : theme.colorTextWeakest)};
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const StyledCaretRightIcon = styled(CaretRightIcon)`
  color: ${({ theme }) => theme.colorTextWeakest};
`

const WizardBreadcrumb = memo(
  ({
    history,
    setHistory,
    handleClick,
    separator,
    rootRenderer,
    width,
    colors,
    marginBottom = sizer(4),
    currentIndex,
  }) => {
    const defaultSeparator = <StyledCaretRightIcon size="xs" />

    const getSeparator = node =>
      separator ? (typeof separator === "function" ? separator(node) : separator) : defaultSeparator

    const _handleClick = ({ node, historyIndex }) => {
      if (handleClick) {
        handleClick({ node, historyIndex })
      } else {
        if (node.path === window.location.hash) return
        setHistory?.(historyIndex === 0 ? [] : slice(0, historyIndex + 1, history))
        window.location.hash = node.path
      }
    }

    const breadcrumbs = history.map((node, historyIndex) => {
      const { name, disabled } = node
      const _separator = history.length > 1 ? getSeparator(node) : ""
      const isLastLink = historyIndex === history.length - 1

      const isActive = currentIndex === historyIndex
      return isActive ? (
        <li className="active-link" key={name}>
          {name}
          {!isLastLink && _separator}
        </li>
      ) : (
        <li key={name} className={isActive ? "active-link" : ""}>
          <a
            href="#!"
            className={disabled ? "disabled" : "clickable-link"}
            onClick={e => {
              e.preventDefault()
              !disabled && _handleClick({ node, historyIndex })
            }}
          >
            {name}
            {!isLastLink && _separator}
          </a>
        </li>
      )
    })

    return (
      <StyledBreadcrumbContainer {...{ width, colors, marginBottom }}>
        {rootRenderer?.()}
        <div data-testid="wizard-breadcrumbs-wrapper" className="breadcrumb-history-path-wrapper">
          <ol data-testid="wizard-breadcrumbs-history" className="breadcrumb-history-path">
            {breadcrumbs}
          </ol>
        </div>
      </StyledBreadcrumbContainer>
    )
  },
)

export default WizardBreadcrumb

WizardBreadcrumb.propTypes = {
  rootRenderer: PropTypes.func,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ),
  setHistory: PropTypes.func,
  handleClick: PropTypes.func,
  separator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  width: PropTypes.string,
  marginBottom: PropTypes.string,
  currentIndex: PropTypes.number,
}
