import { useEffect, useState } from "react"
import { ThemeProvider } from "@emotion/react"

const WindowThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(window.theme)

  useEffect(() => {
    const handleThemeChanged = () => {
      setTheme(window.theme)
    }

    window.addEventListener("themeChanged", handleThemeChanged, false)

    return () => {
      window.removeEventListener("themeChanged", handleThemeChanged, false)
    }
  }, [])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default WindowThemeProvider
