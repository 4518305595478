import { fetchJson } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { includes } from "ramda"

export const checkForAgent = async delegateDeviceId => {
  await fetchJson(`/node/delegate/${delegateDeviceId}/vmm/check`, {
    options: {
      method: "GET",
    },
  })
}

export const statusCheck = async delegateDeviceId => {
  return await window.wamp.call(delegateDeviceId, "vmm.status", [], {})
}

export const checkForConnection = async (clientId, delegate) => {
  const { delegateNodeId, name, hostname, apiPort, credentialId, type } = delegate

  return await fetchJson(`/node/delegate/vmhost/test`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        clientId,
        delegateNodeId,
        name,
        hostname,
        apiPort,
        credentialId,
        type,
      }),
    },
  })
}

export const checkingForAgent = {
  type: "PROGRESS",
  label: localizationKey("Checking for agent"),
}

export const downloading = {
  type: "PROGRESS",
  label: localizationKey("Downloading"),
}

export const installing = {
  type: "PROGRESS",
  label: localizationKey("Installing"),
}

export const installed = {
  type: "PROGRESS",
  label: localizationKey("Installed"),
}

export const starting = {
  type: "PROGRESS",
  label: localizationKey("Starting"),
}

export const testingConnection = {
  type: "PROGRESS",
  label: localizationKey("Testing Connection"),
}

export const testSuccess = {
  type: "TEST",
  success: true,
  label: localizationKey("Test succeeded"),
}

const errorCodeToReadableError = error_code => {
  const errors = {
    esxi_init_timeout: localizationKey("Connection timeout"),
    esxi_unknown: localizationKey("Unknown connection error"),
    unsupported_target: localizationKey("Unsupported agent version"),
    invalid_credentials: localizationKey("Invalid credentials"),
  }
  return errors[error_code]
}

export const testFailed = (params = {}) => {
  const { result_code } = params
  return {
    type: "TEST",
    success: false,
    label: errorCodeToReadableError(result_code) || localizationKey("Test failed"),
  }
}

export const errorInstallingAgent = {
  type: "TEST",
  success: false,
  label: localizationKey("Error installing agent"),
}

export const failedToDownload = {
  type: "TEST",
  success: false,
  label: localizationKey("Failed to download agent"),
}

export const failedToStartService = {
  type: "TEST",
  success: false,
  label: localizationKey("Failed to start service"),
}

export const errorInstallingAgentWithMessage = message => {
  return {
    type: "TEST",
    success: false,
    message,
  }
}

export const errorTestingAgent = {
  type: "TEST",
  success: false,
  label: localizationKey("Error installing agent"),
}

export const possibleErrors = ["vm_host_test_error", "vm_host_test_delegate_error", "vm_host_test_server_error"]

export const installedOrInstalling = stage => includes(stage, ["INSTALLING", "INSTALLED"])
