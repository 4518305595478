import $ from "jquery"
import _ from "underscore"
import { blendColors, date } from "../../../includes/common/utils"

const flot = {
  routine: function(el, value) {
    if (value && Array.isArray(value)) {
      _.defer(function() {
        var context = $(el)
        var chartData = value

        var showTooltip = $(el).data("flot-show-tooltip")
        var statBoardContext = $("#" + $(el).data("flot-colors-context"))
        var tooltipUnit = $(context).data("flot-tooltip-unit")
        var yAxisMax = $(context).data("y-axis-max") ? $(context).data("y-axis-max") : 100
        // eslint-disable-next-line
        var stack = $(context).data("flot-stack") == true

        // Check for empty trend data
        if (!chartData || chartData.length === 0) {
          return
        } else {
          for (let i = 0; i < chartData.length; i++) {
            if (!chartData[i]) {
              return
            }
          }
        }

        // We will only do this for charts with tooltips that use %
        if (showTooltip && !tooltipUnit) {
          // eslint-disable-next-line
          if (yAxisMax != 100) {
            for (let i = 0; i < chartData.length; i++) {
              for (let j = 0; j < chartData[i].length; j++) {
                // Since we show tooltips in %, we need to convert the data
                chartData[i][j][1] = parseInt((chartData[i][j][1] * 100) / yAxisMax, 10)
              }
            }

            // Since we show tooltips in %, we need to use a max value 100
            yAxisMax = 100
          }
        }

        /* chart colors default */
        var $chrt_border_color = "#efefef"
        //var $chrt_grid_color = "#DDD"
        //var $chrt_main = "#E24913";
        /* red       */
        var $chrt_second = "#6595b4"
        /* blue      */
        //var $chrt_third = "#FF9F01";
        /* orange    */
        //var $chrt_fourth = "#7e9d3a";
        /* green     */
        //var $chrt_fifth = "#BD362F";

        var $color1, $color2
        // eslint-disable-next-line
        if (statBoardContext != undefined && statBoardContext.length === 1) {
          $color1 = $(statBoardContext)
            .find(".first-flot-line")
            .first()
            .css("border-left-color")
          $color2 = $(statBoardContext)
            .find(".second-flot-line")
            .first()
            .css("border-left-color")

          if (!$color1 && !$color2) {
            $color1 = context.css("border-color")
            $color2 = $color1 // Not in use today so its OK for it to be the same
          }
        } else {
          $color1 = $(context).css("border-left-color")
          $color2 = blendColors($color1, $chrt_second, 40)
        }

        var showGrid = $(context).data("flot-show-grid") || false

        var tooltipContent = "%y% at %x"
        if (tooltipUnit) {
          tooltipContent = "%y" + tooltipUnit + " at %x"
        }

        const dateFormat = $(context).data("flot-time-format")
        const isDateFormat = dateFormat === "DATE"
        const isDateTimeFormat = dateFormat === "DATE_TIME"

        var options = {
          xaxis: {
            mode: "time",
            timeformat: "%H:%M",
            timezone: "browser",
            tickLength: 5,
            ...(isDateFormat || isDateTimeFormat ? { tickFormatter: date } : {}),
          },
          yaxis: {
            min: 0,
            max: yAxisMax,
          },
          series: {
            stack: stack,
            lines: {
              show: true,
              lineWidth: 1,
              fill: true,
              fillColor: {
                colors: [
                  {
                    opacity: 0.1,
                  },
                  {
                    opacity: 0.15,
                  },
                ],
              },
            },
            shadowSize: 0,
          },
          selection: {
            mode: "x",
          },
          grid: {
            hoverable: showTooltip,
            show: showGrid,
            color: "#999999",
            clickable: false,
            tickColor: $chrt_border_color || "#D4D4D4",
            borderWidth: 0,
            borderColor: $chrt_border_color,
          },
          tooltip: showTooltip,
          tooltipOpts: {
            content: tooltipContent,
            dateFormat: isDateFormat ? "%m/%d" : isDateTimeFormat ? "%I:%M %P %m/%d" : "%I:%M %P",
            defaultTheme: true,
          },
          legend: {
            show: false,
            noColumns: chartData.length,
          },
        }

        var data = []
        var colorsArray = [$color1, $color2]

        if (stack) {
          colorsArray = ["#615192", "#D4C26A", "#AA3C39", "#236467", "#AA6D39"]

          for (let i = 0; i < chartData.length; i++) {
            data.push({
              label: chartData[i][0],
              color: colorsArray[i],
              data: chartData[i][1],
            })
          }
        } else {
          for (let i = 0; i < chartData.length; i++) {
            data.push({
              color: colorsArray[i],
              data: chartData[i],
            })
          }
        }
        setTimeout(() => {
          let flot = context.data("flot")
          if (flot) {
            flot.setData(data)
            flot.setOptions(options)
            flot.setupGrid()
            flot.draw()
          }
          // If we're to draw this flot, make sure the el hasn't been removed from the DOM
          else if ($("body").find(el).length > 0) {
            let flot = $.plot(context, data, options)
            context.data("flot", flot)
          }
        }, 0)
      })
    }
  },

  unbind: function(el) {
    let flot = $(el).data("flot")
    if (flot) {
      flot.shutdown()
    }
    $(el).removeData()
    $(el).empty()
    return true
  },
}

export default flot
