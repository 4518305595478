import { createSelector } from "reselect"
import { compose, prop, propOr, propEq, find } from "ramda"
import { sortListByName } from "js/state/helpers/listSort"
import { useMemo } from "react"

const getTicketSetupValues = state => state.psa.ticketSetupValues

export const ticketTitleContentsList = [
  { name: "Body", id: 6 },
  { name: "Category", id: 4 },
  { name: "Custom text", id: 10 },
  { name: "Device name", id: 3 },
  { name: "Device role", id: 2 },
  { name: "Organization name", id: 1 },
  { name: "Policy name", id: 7 },
  { name: "Private IP", id: 9 },
  { name: "Public IP", id: 8 },
  { name: "Sub-category", id: 5 },
]

export const getTicketTitleHasCustomText = ticketTitleElements =>
  find(
    // 10 === 'Custom Text' option
    val => val && propEq("id", 10, val),
    ticketTitleElements,
  )

export const getConnectWiseFieldOptions = state => name => compose(prop(name), getTicketSetupValues)(state)

export const getServiceBoardList = createSelector(
  [getTicketSetupValues],
  compose(sortListByName, propOr([], "serviceBoardList")),
)

const getInitialServiceBoardStatusList = createSelector([getTicketSetupValues], propOr([], "serviceBoardStatusList"))

const getInitialServiceBoardTeamList = createSelector([getTicketSetupValues], propOr([], "serviceBoardTeamList"))

const getInitialServiceBoardTypeList = createSelector([getTicketSetupValues], propOr([], "serviceBoardTypeList"))

const getInitialServiceBoardSubTypeList = createSelector([getTicketSetupValues], propOr([], "serviceBoardSubTypeList"))

const getInitialServiceItemList = createSelector([getTicketSetupValues], propOr([], "serviceBoardItemList"))

const getAssociationList = createSelector([getTicketSetupValues], prop("serviceBoardTypeSubTypeItemAssociationList"))

export const getServicePriorityList = createSelector(
  [getTicketSetupValues],
  compose(sortListByName, propOr([], "servicePriorityList")),
)

export const getServiceSourceList = createSelector(
  [getTicketSetupValues],
  compose(sortListByName, propOr([], "serviceSourceList")),
)

export const getServiceSeverityList = createSelector(
  [getTicketSetupValues],
  compose(sortListByName, propOr([], "serviceSeverityList")),
)

export const getServiceImpactList = createSelector(
  [getTicketSetupValues],
  compose(sortListByName, propOr([], "serviceImpactList")),
)

const getSelectedServiceBoardFromResponse = state => state.psa.ticketTemplate.serviceBoard
const getSelectedServiceTypeFromResponse = state => state.psa.ticketTemplate.boardType
const getSelectedServiceSubTypeFromResponse = state => state.psa.ticketTemplate.boardSubType

function filterSortByBoard(list, board) {
  return board ? sortListByName(list.filter(({ boardId }) => boardId === board.id)) : []
}

const getServiceBoardStatusListFromResponse = createSelector(
  [getInitialServiceBoardStatusList, getSelectedServiceBoardFromResponse],
  filterSortByBoard,
)

const getServiceBoardTeamListFromResponse = createSelector(
  [getInitialServiceBoardTeamList, getSelectedServiceBoardFromResponse],
  filterSortByBoard,
)

const getServiceTypeListFromResponse = createSelector(
  [getInitialServiceBoardTypeList, getSelectedServiceBoardFromResponse],
  filterSortByBoard,
)

function filterSortByBoardAndType(serviceBoardSubTypeList, board, type) {
  return board && type
    ? sortListByName(
        serviceBoardSubTypeList.filter(
          ({ boardId, typeAssociationIds }) =>
            boardId === board.id &&
            // If there are no type association included, then don't filter by it
            (typeAssociationIds ? typeAssociationIds.includes(type.id) : true),
        ),
      )
    : []
}

const getServiceSubTypeListFromResponse = createSelector(
  [getInitialServiceBoardSubTypeList, getSelectedServiceBoardFromResponse, getSelectedServiceTypeFromResponse],
  filterSortByBoardAndType,
)

function filterSortByBoardAndTypes(serviceItemList, associationList, board, type, subType) {
  return board && type && subType
    ? sortListByName(
        associationList
          ? serviceItemList.filter(
              association =>
                !!associationList.find(
                  ({ itemId, boardId, typeId, subTypeId }) =>
                    association.id === itemId && boardId === board.id && typeId === type.id && subTypeId === subType.id,
                ),
            )
          : serviceItemList,
      )
    : []
}
const getServiceItemListFromResponse = createSelector(
  [
    getInitialServiceItemList,
    getAssociationList,
    getSelectedServiceBoardFromResponse,
    getSelectedServiceTypeFromResponse,
    getSelectedServiceSubTypeFromResponse,
  ],
  filterSortByBoardAndTypes,
)

export const getServiceListsHook = createSelector(
  [
    getInitialServiceBoardStatusList,
    getInitialServiceBoardTeamList,
    getInitialServiceBoardTypeList,
    getInitialServiceBoardSubTypeList,
    getInitialServiceItemList,
    getAssociationList,
  ],
  (
    initialServiceBoardStatusList,
    initialServiceBoardTeamList,
    initialServiceBoardTypeList,
    initialServiceBoardSubTypeList,
    initialServiceItemList,
    associationList,
  ) => {
    const useServiceLists = ({ serviceBoard, boardType, boardSubType }) => ({
      serviceBoardStatusList: useMemo(() => filterSortByBoard(initialServiceBoardStatusList, serviceBoard), [
        serviceBoard,
      ]),
      serviceBoardTeamList: useMemo(() => filterSortByBoard(initialServiceBoardTeamList, serviceBoard), [serviceBoard]),
      serviceTypeList: useMemo(() => filterSortByBoard(initialServiceBoardTypeList, serviceBoard), [serviceBoard]),
      serviceSubTypeList: useMemo(
        () => filterSortByBoardAndType(initialServiceBoardSubTypeList, serviceBoard, boardType),
        [serviceBoard, boardType],
      ),
      serviceItemList: useMemo(
        () => filterSortByBoardAndTypes(initialServiceItemList, associationList, serviceBoard, boardType, boardSubType),
        [serviceBoard, boardType, boardSubType],
      ),
    })
    return useServiceLists
  },
)

export function getConnectWiseTicketValueOptions(state) {
  // TODO: form values are unavailable.. need to update selectors to be able to check pre-initialized
  return key => {
    switch (key) {
      case "serviceBoard":
        return getServiceBoardList(state)
      case "priority":
        return getServicePriorityList(state)
      case "boardType":
        return getServiceTypeListFromResponse(state)
      case "boardSubType":
        return getServiceSubTypeListFromResponse(state)
      case "boardItem":
        return getServiceItemListFromResponse(state)
      case "source":
        return getServiceSourceList(state)
      case "boardTeam":
        return getServiceBoardTeamListFromResponse(state)
      case "severity":
        return getServiceSeverityList(state)
      case "impact":
        return getServiceImpactList(state)
      case "ticketTitleElements":
        return ticketTitleContentsList
      case "boardStatus":
      case "createAndClose":
      case "onReset":
        return getServiceBoardStatusListFromResponse(state)
      default:
        return key
    }
  }
}
