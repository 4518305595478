import { fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export const setOrganizationList = organizations => ({
  type: "SET_ORGANIZATION_LIST",
  organizations,
})

export const removeOrganizationFromList = id => ({
  type: "REMOVE_ORGANIZATION",
  payload: id,
})

export const getAllOrganizations = () => async dispatch => {
  try {
    const clients = await fetchJson(`/client/list`)
    dispatch(setOrganizationList(clients))
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error fetching organizations"))
  }
}
