import {
  fetchJson,
  reportErrorAndShowMessage,
  isUserAllowedToViewTicket,
  localizationKey,
} from "js/includes/common/utils"
import { sortByOrderOrId } from "js/includes/ticketing/shared/utils"

export const addTriggerBoardTab = tab => ({
  type: "ADD_BOARD_TAB",
  tab,
})

export const updateTriggerBoardTab = tab => ({
  type: "UPDATE_BOARD_TAB",
  tab,
})

export const deleteTriggerBoardTab = tab => ({ type: "DELETE_BOARD_TAB", tab })

export const fetchTriggerBoardTabs = async dispatch => {
  try {
    // Server takes care of filtering out boards in this case
    const shouldRequestBoards = isUserAllowedToViewTicket()

    if (!shouldRequestBoards) return

    const response = await fetchJson("/ticketing/trigger/boards")

    const tabs = sortByOrderOrId(response)

    dispatch({
      type: "SET_BOARD_TABS",
      tabs,
    })
  } catch (error) {
    // 403 - ticketing not enabled - discard error
    if (error.response?.status !== 403) {
      reportErrorAndShowMessage(error, localizationKey("Error"))
    }
  }
}

export const fetchTriggerBoards = () => fetchTriggerBoardTabs
