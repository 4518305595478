import React, { memo } from "react"
import { connect } from "react-redux"
import { sizer } from "@ninjaone/utils"
import { Box } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"
import { StyledLabel } from "js/includes/components/DeviceModal/style"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const Credential = memo(({ onChange, value, credentials, disabled, validationState, validateForm }) => {
  return (
    <Box marginTop={sizer(3)} data-testid="credential-label">
      <StyledLabel {...{ htmlFor: "credential-selector", errorMessage: validationState === "error" }}>
        {localized("editorsCustomerCustomerEditor.credential")} *
      </StyledLabel>
      <SearchableDropDown
        {...{
          id: "credential-selector",
          disabled,
          onSelect: onChange,
          options: credentials,
          value,
          searchableKey: "name",
          valueSelectorKey: "id",
          searchPlaceholderToken: "addDevices.selectCredential",
          validationState,
          width: "100%",
          minHeight: "42px",
          rowHeight: 40,
          keepInView: false,
          errorMessage: localized("general.required"),
          onBlur: () => validateForm(["selectedCredential"]),
        }}
      />
    </Box>
  )
})

export default connect(({ credentials }) => ({
  credentials: credentials.credentialsList,
}))(Credential)
