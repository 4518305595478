import { useCallback } from "react"
import { useSelector } from "react-redux"
import { Input } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { useMountedState } from "js/includes/common/hooks"
import { Flex } from "js/includes/components/Styled"
import { emailValidation, localized } from "js/includes/common/utils"

const TypeEmailToConfirm = ({
  value = "",
  onChange,
  placeHolderText,
  inputLabel,
  errorToken = localized("Input does not match logged-in email"),
}) => {
  const [isValidEmail, setIsValidEmail] = useMountedState(true)
  const sessionEmail = useSelector(state => state.session.user.email)

  const handleEmailChange = useCallback(
    newValue => {
      const isValidEmail = emailValidation(newValue)
      const isValidUserEmail = sessionEmail.toUpperCase() === newValue.toUpperCase()
      const isValid = isValidEmail && isValidUserEmail

      setIsValidEmail(isValid)
      onChange?.({
        isValid,
        value: newValue,
      })
    },
    [onChange, setIsValidEmail, sessionEmail],
  )

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" marginTop={sizer(2)}>
        <Input
          labelText={inputLabel || localized("Enter your email to confirm")}
          autoComplete="off"
          required
          name="email"
          placeholder={placeHolderText || localized("Email")}
          value={value}
          errorMessage={isValidEmail ? "" : errorToken}
          onChange={e => handleEmailChange(e.target.value)}
        />
      </Flex>
    </Flex>
  )
}

export default TypeEmailToConfirm
