import Section from "./Section"
import { localized } from "js/includes/common/utils"

const getArchitecture = architecture =>
  ({
    ALL: localized("All"),
    "64_BIT": localized("64-bit"),
    "32_BIT": localized("32-bit"),
  }[architecture] || localized("Unknown"))

export default function LegacyScriptsDescriptionPopoverSection({ automation }) {
  const {
    parameters: { appName, exePath, fileName, msiUrlPath, supportedArchitecture, cmdLineParameters },
  } = automation
  return (
    <>
      {supportedArchitecture && (
        <Section title={localized("Architecture")} content={getArchitecture(supportedArchitecture)} />
      )}
      {appName && <Section title={localized("Name")} content={appName} />}
      {fileName && <Section title={localized("File Name")} content={fileName} />}
      {!fileName && msiUrlPath && <Section title={localized("Installer URL")} content={msiUrlPath} />}
      {cmdLineParameters && <Section title={localized("Parameters")} content={cmdLineParameters} />}
      {exePath && <Section title={localized("Path")} content={exePath} />}
    </>
  )
}
