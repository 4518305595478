import { memo } from "react"
import { HoverDropdown, HoverDropdownItem } from "@ninjaone/components"
import { runAdhocJob } from "js/includes/components/DeviceContextMenu/utils"
import { localizationKey, user } from "js/includes/common/utils"
import { renderJobItems } from "js/includes/common/_jobs"

//TODO add feature flags validations
export default memo(function DeviceSoftwarePatchesSubMenu({ node }) {
  const softwarePatchesEnabled = node?.policyContent?.softwarePatchManagement?.general?.enabled || false
  return (
    <>
      {softwarePatchesEnabled && node.isUp && user("canUpdateDevices", node) ? (
        <HoverDropdown
          buttonRenderer={({ isOpen }) => (
            <HoverDropdownItem token={localizationKey("Software Update")} isOpen={isOpen} showArrow isDropdownButton />
          )}
          dropdownRenderer={() => (
            <ul>
              {renderJobItems(
                "SOFTWARE_UPDATES",
                undefined,
                job => runAdhocJob(job, node),
                ({ jobName, onClick }) => (
                  <HoverDropdownItem plainText={jobName} onClick={onClick} />
                ),
              )}
            </ul>
          )}
          placement="right"
          fullWidth
        />
      ) : null}
    </>
  )
})
