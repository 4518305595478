import React from "react"
import { useMountedState } from "js/includes/common/hooks"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import Modal from "js/includes/components/Modal"
import CredentialModalForm from "js/includes/editors/Customer/Sections/Credentials/CredentialModalForm"

export default function CreateCredentialsModal({ clientId, setCredentials, unmount }) {
  const usernamePasswordType = { label: localized("Username/Password"), value: "BASIC" }

  const [type, setType] = useMountedState(usernamePasswordType)

  const options = [usernamePasswordType]

  return (
    <Modal noFooter tallModal title={localizationKey("New Credential")} close={unmount}>
      <CredentialModalForm
        {...{
          setCredentials,
          type,
          options,
          closeModal: unmount,
          clientId,
          setType,
        }}
      />
    </Modal>
  )
}
