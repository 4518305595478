import { useCallback, useEffect } from "react"
import { useMountedState } from "js/includes/common/hooks"
import { fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"

type Options<T> = {
  initialValue: T
  reportOnError?: boolean
}

export const useFetch = <T>(
  endpoint: string | null,
  { initialValue, reportOnError = true }: Options<T> = {} as Options<T>,
) => {
  const [loading, setLoading] = useMountedState(false)
  const [value, setValue] = useMountedState<T>(initialValue)

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      if (!endpoint) return
      const data: T = await fetchJson(endpoint)
      setValue(data)
    } catch (error) {
      reportOnError && reportErrorAndShowMessage(error)
    } finally {
      setLoading(false)
    }
  }, [setValue, setLoading, endpoint, reportOnError])

  useEffect(() => {
    ;(async () => await fetchData())()
  }, [fetchData])

  return [value, loading, fetchData] as [T, boolean, () => Promise<void>]
}
