import { ninjaReportError } from "js/includes/common/utils/ssrAndWebUtils/sentry"
import { fetchLocalizationResourceWithRetry } from "./utils"

export const initialDefaultLanguage = "en-us"

// localizedTokens key - value Object. Used to support both new and legacy localization
// Where:
// Key - is token: "some.token"
// Value - English text: "Some Token"
// {
//   "actions.add": "Add",
//   "actions.create": "Create",
//   "actions.edit": "Edit",
//   "actions.view": "View",
// ...}
//
let localizedTokens = {}
export const getLocalizedTokens = () => localizedTokens
export const setLocalizedTokens = tokens => (localizedTokens = { ...tokens })
export const loadLocalizedTokens = async () => {
  const _localizedTokensModule = await import(/* webpackChunkName: "en-us.json" */ "@ninjaone/localization/en-us.json")
  return _localizedTokensModule.default
}
export const getEngTextToken = token => localizedTokens[token] ?? token

// nonDefaultLangTokens key - value Object, fetched from server if not default language
// Where:
// Key - is English text: "Some Token"
// Value - Translation: "Aalgún Token"
// {
//   "Add": "Agregar",
//   "Create": "Crear",
//   "Edit": "Editar",
//   "View": "Vista",
// ...}
let nonDefaultLangTokens = {}
export const getNonDefaultLangTokens = () => nonDefaultLangTokens
export const setNonDefaultLangTokens = tokens => (nonDefaultLangTokens = { ...tokens })
export const loadNonDefaultLangTokens = async (environment, localizationFileName) => {
  const response = await fetchLocalizationResourceWithRetry(environment, localizationFileName)
  return await response.json()
}
export const getResultToken = engTextToken => nonDefaultLangTokens[engTextToken] ?? engTextToken

let _isDefaultLanguage = true
export const isDefaultLanguage = () => _isDefaultLanguage
const setIsDefaultLanguage = language => {
  const languagesUsingEnUS = ["en-gb", "en-au", "en-us"]
  _isDefaultLanguage = languagesUsingEnUS.includes(language)
}
const getLocalizationFileName = language => (isDefaultLanguage() ? initialDefaultLanguage : language)

let applicationLanguage = initialDefaultLanguage
export const getApplicationLanguage = () => applicationLanguage
const setApplicationLanguage = language => {
  applicationLanguage = language
  setIsDefaultLanguage(language)
}

export const setLanguageAndTokens = (_applicationLanguage, _localizedTokens = {}, _nonDefaultLangTokens = {}) => {
  setApplicationLanguage(_applicationLanguage)
  setLocalizedTokens(_localizedTokens)
  setNonDefaultLangTokens(_nonDefaultLangTokens)
}

// setLanguage setup's webapp language during initialization, tests, reporting-ssr
// contains logic on loading tokens and localizations depending on default lang or not.
export const setLanguage = async (
  language,
  environment = window.store?.getState()?.application?.environment, // could be custom, from reporting-ssr or test
  nonDefaultLangTokensLoaderFunc = loadNonDefaultLangTokens, // could be custom, from reporting-ssr or test
) => {
  setIsDefaultLanguage(language)

  // additional condition to avoid future problems with reporting-ssr
  if (typeof window !== "undefined" && window.store) {
    window.store.dispatch({ type: "SET_APP_LANGUAGE", language })
  }

  // TODO: remove this block of code after automation team will do migration to the new localization approach
  // because we use setLanguage from reporting-ssr we need additional check for "window" in such manner
  if (typeof window !== "undefined") {
    window.localizedTokens = localizedTokens // Store english language for automation to use.
  }

  try {
    setLocalizedTokens(await loadLocalizedTokens())
    if (!isDefaultLanguage()) {
      const _nonDefaultLangTokens = await nonDefaultLangTokensLoaderFunc(environment, getLocalizationFileName(language))
      setNonDefaultLangTokens(_nonDefaultLangTokens)
    }
    setApplicationLanguage(language)
  } catch (error) {
    ninjaReportError({ error, message: `Unable to find language file for language: ${language}` })
    setApplicationLanguage(initialDefaultLanguage)
  }
}
