import { useMountedState } from "js/includes/common/hooks"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import Modal from "js/includes/components/Modal"
import CredentialModalForm from "js/includes/editors/Customer/Sections/Credentials/CredentialModalForm"

export const AddCredentialModal = ({ clientId, setCredentials, unmount }) => {
  const [type, setType] = useMountedState({})

  return (
    <Modal
      noFooter
      tallModal
      title={localizationKey("New Credential")}
      bsSize="lg"
      overflow={!type.value}
      close={unmount}
    >
      <CredentialModalForm
        {...{
          setCredentials,
          type,
          options: [
            { label: "SNMP V1", value: "SNMPV1" },
            { label: "SNMP V2", value: "SNMPV2" },
            { label: "SNMP V3", value: "SNMPV3" },
            { label: "Telnet/SSH", value: "TELNET_SSH" },
          ],
          closeModal: unmount,
          clientId,
          setType,
        }}
      />
    </Modal>
  )
}
