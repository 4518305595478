import React, { lazy, Suspense } from "react"
import AsyncComponentFallback from "js/includes/components/AsyncComponentFallback"

export default function AsyncComponent({ loader, fallback }) {
  const Component = lazy(loader)
  return props => {
    return (
      <Suspense fallback={<AsyncComponentFallback {...{ fallback }} />}>
        <Component {...props} />
      </Suspense>
    )
  }
}
