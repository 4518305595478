import { useEffect, useRef, useState } from "react"

const observerMap = new Map()

const createObserver = id => {
  const savedInstance = observerMap.get(id)

  if (savedInstance) {
    return savedInstance
  }

  const elements = new Map()

  const observer = new IntersectionObserver(entries => {
    entries.forEach(({ target, isIntersecting }) => {
      const callback = elements.get(target)

      callback?.(isIntersecting)
    })
  })

  const newInstance = {
    id,
    observer,
    elements,
  }

  observerMap.set(id, newInstance)

  return newInstance
}

const observe = (id, element, callback) => {
  const { observer, elements } = createObserver(id)

  if (!elements.has(element)) {
    elements.set(element, callback)
  }

  observer.observe(element)

  return function unobserve() {
    elements.delete(element)
    observer.unobserve(element)

    if (elements.size === 0) {
      observer.disconnect()
      observerMap.delete(id)
    }
  }
}

const useInViewport = ({ id }) => {
  const ref = useRef(null)
  const [inViewport, setInViewport] = useState(false)

  useEffect(() => {
    if (!ref.current) return

    const unobserve = observe(id, ref.current, isIntersecting => {
      setInViewport(isIntersecting)
    })

    return () => unobserve?.()
  }, [id])

  return {
    ref,
    inViewport,
  }
}

export default useInViewport
