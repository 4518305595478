import $ from "jquery"
import rivets from "rivets"
import { v4 as uuidv4 } from "uuid"

const template = {
  bind: function(el) {
    $(el).data("rivets.binders.template.bound", false)
    this.generatedElementId = "rivets-generated-" + uuidv4()
    $(el).data("rivets.binders.template.initialized", false)
    return true
  },

  routine: function(el, value) {
    if (value && !$(el).data("rivets.binders.template.bound")) {
      var generatedElementId = this.generatedElementId
      var templateElementPath = $(el).attr("data-rv-template")
      //var type = $(el).attr('data-rv-type');
      var templateElement = $(templateElementPath)
      var result = $('<div id="' + generatedElementId + '">' + templateElement.html() + "</div>")
      this.templateView = rivets.bind(result, {
        model: value,
      })
      $(el).html(result)
      $(el).data("rivets.binders.template.bound", true)
    }
  },

  unbind: function(el) {
    if ($(el).data("rivets.binders.template.bound")) {
      $("#" + this.generatedElementId).remove()
      if (this.templateView) {
        this.templateView.unbind()
      }
    }
    return true
  },
}

export default template
