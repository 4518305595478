import React, { PureComponent } from "react"
import { compose, map, reject } from "ramda"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarker } from "@fortawesome/pro-solid-svg-icons"
import { SelectIcon } from "@ninjaone/icons"
import { localized, timeZones, customLocations, commonLocations, time, getTimeZone } from "js/includes/common/utils"
import OutsideClickAlerter from "./OutsideClickAlerter"
import moment from "moment"

export default class TimeZonePicker extends PureComponent {
  constructor(props) {
    super(props)
    this.onChangeTimePicker = this.onChangeTimePicker.bind(this)
    this.renderCommonLocations = this.renderCommonLocations.bind(this)
    this.renderCustomLocations = this.renderCustomLocations.bind(this)
    this.renderSelectedTimeZone = this.renderSelectedTimeZone.bind(this)
    this.onToogleTimeZoneOptions = this.onToogleTimeZoneOptions.bind(this)
    this.toggleMoreLocations = this.toggleMoreLocations.bind(this)
  }

  state = {
    value: this.props.value,
    expanded: false,
    showMoreLocation: false,
  }

  timeZoneMap = {
    _Division: window.application.get("timeZone").get("value"),
    current_location: moment.tz.guess(),
  }

  renderCustomLocations(hideLocalDeviceTimeOption) {
    return compose(
      map(({ timeZoneId, name }) => (
        <li className="divider" key={timeZoneId} id={timeZoneId} onClick={e => this.onChangeTimePicker(e, timeZoneId)}>
          <span className="btn no-padding btn-light">
            <span className="tz-common-name leftItemtext">
              {timeZoneId === "_Division" ? `${localized(name)} - ${this.timeZoneMap["_Division"]}` : localized(name)}
            </span>
          </span>
        </li>
      )),
      reject(({ timeZoneId }) => hideLocalDeviceTimeOption && timeZoneId === "_Local"),
    )(customLocations)
  }

  renderCommonLocations() {
    return compose(
      map(({ key, timeZoneId, timeZone, name, currentTime }) => {
        return (
          <li key={key} id={timeZoneId} onClick={e => this.onChangeTimePicker(e, timeZoneId)}>
            <span className="btn no-padding btn-light leftItemtext">
              <span className="tz-timezone">{timeZone}</span>
              <span className="tz-common-name">{name}</span>
            </span>
            <span className="btn no-padding btn-light rigthItemText">
              <span className="tz-current-time">&nbsp;{currentTime}</span>

              {moment.tz.guess() === timeZoneId && key !== "current_location" && (
                <span className="current-location-marker">
                  <FontAwesomeIcon icon={faMapMarker} size="sm" />
                </span>
              )}
            </span>
          </li>
        )
      }),
      map(location => {
        const timeZoneId = location.timeZoneId === "current_location" ? moment.tz.guess() : location.timeZoneId
        const timeZone = timeZoneId.indexOf("_") !== 0 ? moment.tz(timeZoneId) : null

        return {
          key: location.timeZoneId,
          name: location.timeZoneId === "current_location" ? localized(location.name) : location.name,
          isInternal: timeZone === null,
          timeZoneId: timeZoneId,
          timeZone: timeZone ? timeZone.zoneAbbr() + " – " : null,
          currentTime: timeZone ? time(timeZone) : null,
        }
      }),
    )(commonLocations)
  }

  renderMoreLocations() {
    const timeZonesFilter = timeZones.filter(timezone => timezone.timeZoneId !== moment.tz.guess())
    return timeZonesFilter.map(({ timeZoneId, name, offset }) => {
      return (
        <li key={timeZoneId} id={timeZoneId} onClick={e => this.onChangeTimePicker(e, timeZoneId)}>
          <span className="tz-name leftItemtext">{name}</span>
          <span className="tz-offset rigthItemText">&nbsp;{offset}</span>
        </li>
      )
    })
  }

  renderSelectedTimeZone() {
    const { timeZone, timeZoneName, currentTimeZone } = getTimeZone(this.state.value)

    return (
      currentTimeZone && (
        <span className="leftItemtext">
          <span className="tz-timezone">{timeZone ? timeZone.zoneAbbr() + " – " : null}</span>
          <span className="tz-common-name">{timeZoneName}</span>
        </span>
      )
    )
  }

  onChangeTimePicker(e, timeZoneId) {
    const value = this.timeZoneMap[timeZoneId] || timeZoneId

    this.setState({
      value,
      expanded: false,
    })

    this.props.onChange(value)
  }

  toggleMoreLocations(e) {
    this.setState(({ showMoreLocation }) => ({
      showMoreLocation: !showMoreLocation,
    }))
  }

  onToogleTimeZoneOptions() {
    this.setState(({ expanded }) => ({
      expanded: !expanded,
    }))
  }

  render() {
    const { expanded, showMoreLocation } = this.state
    const { disabled, hideLocalDeviceTimeOption } = this.props

    return (
      <div className="time-zone-picker">
        <OutsideClickAlerter
          handleClickOutside={() =>
            this.setState({
              expanded: false,
            })
          }
        >
          <>
            <button
              type="button"
              className="btn form-control"
              disabled={disabled}
              onClick={this.onToogleTimeZoneOptions}
            >
              {this.renderSelectedTimeZone()}
              <SelectIcon color="darkGray" />
            </button>
            {expanded && (
              <div className="time-zones-list">
                <ul ref={r => (this.container = r)}>
                  {this.renderCustomLocations(hideLocalDeviceTimeOption)}
                  {this.renderCommonLocations()}

                  <li
                    className="btn btn-secondary display-flex justify-content-between show-more-locations"
                    onClick={this.toggleMoreLocations}
                  >
                    <span>
                      <b>{!showMoreLocation ? localized("Show More Locations") : localized("Show Less Locations")}</b>
                    </span>
                    <SelectIcon color="darkGray" />
                  </li>

                  {showMoreLocation && this.renderMoreLocations()}
                </ul>
              </div>
            )}
          </>
        </OutsideClickAlerter>
      </div>
    )
  }
}
