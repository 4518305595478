import moment from "moment"
import { localizedF, localized, capitalizeFirstLetter } from "js/includes/common/utils"

export const startOptions = [
  { value: "NOW", text: localizedF("Now") },
  { value: "AT", text: () => capitalizeFirstLetter(localized("at")) },
]

export const endOptions = [
  { value: "NEVER", text: localizedF("Never") },
  { value: "ON", text: () => capitalizeFirstLetter(localized("On")) },
]

export const defaultSchedule = () => ({
  start: {
    type: "NOW",
    datetime: moment()
      .add(1, "minutes")
      .valueOf(),
  },
  end: {
    type: "NEVER",
    datetime: moment()
      .add(6, "minutes")
      .valueOf(),
  },
})

export const commonSuppressOptions = {
  notifications: true,
}

export const macSuppressOptions = {
  alerts: true,
  patching: true,
  tasks: true,
  notificationChannels: true,
}

export const linuxSuppressOptions = {
  alerts: true,
  patching: true,
  tasks: true,
  notificationChannels: true,
}

export const windowsSuppressOptions = {
  alerts: true,
  patching: true,
  avScans: true,
  tasks: true,
  notificationChannels: true,
}

export const vmWareSuppressOptions = {
  alerts: true,
  notificationChannels: true,
}

export const nmsSuppressOptions = {
  alerts: true,
  notificationChannels: true,
}

export const allSuppressOptions = {
  ...commonSuppressOptions,
  ...windowsSuppressOptions,
  ...macSuppressOptions,
  ...linuxSuppressOptions,
  ...vmWareSuppressOptions,
  ...nmsSuppressOptions,
}
