import { $getRoot, $isParagraphNode } from "lexical"

export function $isEditorEmpty() {
  const root = $getRoot()
  const children = root.getChildren()

  if (children.length > 1) {
    return false
  } else {
    if ($isParagraphNode(children[0])) {
      const paragraphChildren = children[0].getChildren()
      return paragraphChildren.length === 0
    } else {
      return false
    }
  }
}
