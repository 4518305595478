import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const countries = [
  { name: localizationKey("Afghanistan"), code: "AF" },
  { name: localizationKey("Åland Islands"), code: "AX" },
  { name: localizationKey("Albania"), code: "AL" },
  { name: localizationKey("Algeria"), code: "DZ" },
  { name: localizationKey("American Samoa"), code: "AS" },
  { name: localizationKey("AndorrA"), code: "AD" },
  { name: localizationKey("Angola"), code: "AO" },
  { name: localizationKey("Anguilla"), code: "AI" },
  { name: localizationKey("Antarctica"), code: "AQ" },
  { name: localizationKey("Antigua and Barbuda"), code: "AG" },
  { name: localizationKey("Argentina"), code: "AR" },
  { name: localizationKey("Armenia"), code: "AM" },
  { name: localizationKey("Aruba"), code: "AW" },
  { name: localizationKey("Australia"), code: "AU" },
  { name: localizationKey("Austria"), code: "AT" },
  { name: localizationKey("Azerbaijan"), code: "AZ" },
  { name: localizationKey("Bahamas"), code: "BS" },
  { name: localizationKey("Bahrain"), code: "BH" },
  { name: localizationKey("Bangladesh"), code: "BD" },
  { name: localizationKey("Barbados"), code: "BB" },
  { name: localizationKey("Belarus"), code: "BY" },
  { name: localizationKey("Belgium"), code: "BE" },
  { name: localizationKey("Belize"), code: "BZ" },
  { name: localizationKey("Benin"), code: "BJ" },
  { name: localizationKey("Bermuda"), code: "BM" },
  { name: localizationKey("Bhutan"), code: "BT" },
  { name: localizationKey("Bolivia"), code: "BO" },
  { name: localizationKey("Bosnia and Herzegovina"), code: "BA" },
  { name: localizationKey("Botswana"), code: "BW" },
  { name: localizationKey("Bouvet Island"), code: "BV" },
  { name: localizationKey("Brazil"), code: "BR" },
  { name: localizationKey("British Indian Ocean Territory"), code: "IO" },
  { name: localizationKey("Brunei Darussalam"), code: "BN" },
  { name: localizationKey("Bulgaria"), code: "BG" },
  { name: localizationKey("Burkina Faso"), code: "BF" },
  { name: localizationKey("Burundi"), code: "BI" },
  { name: localizationKey("Cambodia"), code: "KH" },
  { name: localizationKey("Cameroon"), code: "CM" },
  { name: localizationKey("Canada"), code: "CA" },
  { name: localizationKey("Cape Verde"), code: "CV" },
  { name: localizationKey("Cayman Islands"), code: "KY" },
  { name: localizationKey("Central African Republic"), code: "CF" },
  { name: localizationKey("Chad"), code: "TD" },
  { name: localizationKey("Chile"), code: "CL" },
  { name: localizationKey("China"), code: "CN" },
  { name: localizationKey("Christmas Island"), code: "CX" },
  { name: localizationKey("Cocos (Keeling) Islands"), code: "CC" },
  { name: localizationKey("Colombia"), code: "CO" },
  { name: localizationKey("Comoros"), code: "KM" },
  { name: localizationKey("Congo"), code: "CG" },
  { name: localizationKey("Congo, The Democratic Republic of the"), code: "CD" },
  { name: localizationKey("Cook Islands"), code: "CK" },
  { name: localizationKey("Costa Rica"), code: "CR" },
  { name: localizationKey("Ivory Coast"), code: "CI" },
  { name: localizationKey("Croatia"), code: "HR" },
  { name: localizationKey("Cuba"), code: "CU" },
  { name: localizationKey("Cyprus"), code: "CY" },
  { name: localizationKey("Czech Republic"), code: "CZ" },
  { name: localizationKey("Denmark"), code: "DK" },
  { name: localizationKey("Djibouti"), code: "DJ" },
  { name: localizationKey("Dominica"), code: "DM" },
  { name: localizationKey("Dominican Republic"), code: "DO" },
  { name: localizationKey("Ecuador"), code: "EC" },
  { name: localizationKey("Egypt"), code: "EG" },
  { name: localizationKey("El Salvador"), code: "SV" },
  { name: localizationKey("Equatorial Guinea"), code: "GQ" },
  { name: localizationKey("Eritrea"), code: "ER" },
  { name: localizationKey("Estonia"), code: "EE" },
  { name: localizationKey("Ethiopia"), code: "ET" },
  { name: localizationKey("Falkland Islands (Malvinas)"), code: "FK" },
  { name: localizationKey("Faroe Islands"), code: "FO" },
  { name: localizationKey("Fiji"), code: "FJ" },
  { name: localizationKey("Finland"), code: "FI" },
  { name: localizationKey("France"), code: "FR" },
  { name: localizationKey("French Guiana"), code: "GF" },
  { name: localizationKey("French Polynesia"), code: "PF" },
  { name: localizationKey("French Southern Territories"), code: "TF" },
  { name: localizationKey("Gabon"), code: "GA" },
  { name: localizationKey("Gambia"), code: "GM" },
  { name: localizationKey("Georgia"), code: "GE" },
  { name: localizationKey("Germany"), code: "DE" },
  { name: localizationKey("Ghana"), code: "GH" },
  { name: localizationKey("Gibraltar"), code: "GI" },
  { name: localizationKey("Greece"), code: "GR" },
  { name: localizationKey("Greenland"), code: "GL" },
  { name: localizationKey("Grenada"), code: "GD" },
  { name: localizationKey("Guadeloupe"), code: "GP" },
  { name: localizationKey("Guam"), code: "GU" },
  { name: localizationKey("Guatemala"), code: "GT" },
  { name: localizationKey("Guernsey"), code: "GG" },
  { name: localizationKey("Guinea"), code: "GN" },
  { name: localizationKey("Guinea-Bissau"), code: "GW" },
  { name: localizationKey("Guyana"), code: "GY" },
  { name: localizationKey("Haiti"), code: "HT" },
  { name: localizationKey("Heard Island and Mcdonald Islands"), code: "HM" },
  { name: localizationKey("Holy See (Vatican City State)"), code: "VA" },
  { name: localizationKey("Honduras"), code: "HN" },
  { name: localizationKey("Hong Kong"), code: "HK" },
  { name: localizationKey("Hungary"), code: "HU" },
  { name: localizationKey("Iceland"), code: "IS" },
  { name: localizationKey("India"), code: "IN" },
  { name: localizationKey("Indonesia"), code: "ID" },
  { name: localizationKey("Iran, Islamic Republic Of"), code: "IR" },
  { name: localizationKey("Iraq"), code: "IQ" },
  { name: localizationKey("Ireland"), code: "IE" },
  { name: localizationKey("Isle of Man"), code: "IM" },
  { name: localizationKey("Israel"), code: "IL" },
  { name: localizationKey("Italy"), code: "IT" },
  { name: localizationKey("Jamaica"), code: "JM" },
  { name: localizationKey("Japan"), code: "JP" },
  { name: localizationKey("Jersey"), code: "JE" },
  { name: localizationKey("Jordan"), code: "JO" },
  { name: localizationKey("Kazakhstan"), code: "KZ" },
  { name: localizationKey("Kenya"), code: "KE" },
  { name: localizationKey("Kiribati"), code: "KI" },
  { name: localizationKey("Democratic People'S Republic of North Korea"), code: "KP" },
  { name: localizationKey("Korea, Republic of"), code: "KR" },
  { name: localizationKey("Kuwait"), code: "KW" },
  { name: localizationKey("Kyrgyzstan"), code: "KG" },
  { name: localizationKey("People'S Democratic Republic of Lao"), code: "LA" },
  { name: localizationKey("Latvia"), code: "LV" },
  { name: localizationKey("Lebanon"), code: "LB" },
  { name: localizationKey("Lesotho"), code: "LS" },
  { name: localizationKey("Liberia"), code: "LR" },
  { name: localizationKey("Libyan Arab Jamahiriya"), code: "LY" },
  { name: localizationKey("Liechtenstein"), code: "LI" },
  { name: localizationKey("Lithuania"), code: "LT" },
  { name: localizationKey("Luxembourg"), code: "LU" },
  { name: localizationKey("Macao"), code: "MO" },
  { name: localizationKey("Macedonia, The Former Yugoslav Republic of"), code: "MK" },
  { name: localizationKey("Madagascar"), code: "MG" },
  { name: localizationKey("Malawi"), code: "MW" },
  { name: localizationKey("Malaysia"), code: "MY" },
  { name: localizationKey("Maldives"), code: "MV" },
  { name: localizationKey("Mali"), code: "ML" },
  { name: localizationKey("Malta"), code: "MT" },
  { name: localizationKey("Marshall Islands"), code: "MH" },
  { name: localizationKey("Martinique"), code: "MQ" },
  { name: localizationKey("Mauritania"), code: "MR" },
  { name: localizationKey("Mauritius"), code: "MU" },
  { name: localizationKey("Mayotte"), code: "YT" },
  { name: localizationKey("Mexico"), code: "MX" },
  { name: localizationKey("Micronesia, Federated States of"), code: "FM" },
  { name: localizationKey("Moldova, Republic of"), code: "MD" },
  { name: localizationKey("Monaco"), code: "MC" },
  { name: localizationKey("Mongolia"), code: "MN" },
  { name: localizationKey("Montserrat"), code: "MS" },
  { name: localizationKey("Morocco"), code: "MA" },
  { name: localizationKey("Mozambique"), code: "MZ" },
  { name: localizationKey("Myanmar"), code: "MM" },
  { name: localizationKey("Namibia"), code: "NA" },
  { name: localizationKey("Nauru"), code: "NR" },
  { name: localizationKey("Nepal"), code: "NP" },
  { name: localizationKey("Netherlands"), code: "NL" },
  { name: localizationKey("Netherlands Antilles"), code: "AN" },
  { name: localizationKey("New Caledonia"), code: "NC" },
  { name: localizationKey("New Zealand"), code: "NZ" },
  { name: localizationKey("Nicaragua"), code: "NI" },
  { name: localizationKey("Niger"), code: "NE" },
  { name: localizationKey("Nigeria"), code: "NG" },
  { name: localizationKey("Niue"), code: "NU" },
  { name: localizationKey("Norfolk Island"), code: "NF" },
  { name: localizationKey("Northern Mariana Islands"), code: "MP" },
  { name: localizationKey("Norway"), code: "NO" },
  { name: localizationKey("Oman"), code: "OM" },
  { name: localizationKey("Pakistan"), code: "PK" },
  { name: localizationKey("Palau"), code: "PW" },
  { name: localizationKey("Palestinian Territory, Occupied"), code: "PS" },
  { name: localizationKey("Panama"), code: "PA" },
  { name: localizationKey("Papua New Guinea"), code: "PG" },
  { name: localizationKey("Paraguay"), code: "PY" },
  { name: localizationKey("Peru"), code: "PE" },
  { name: localizationKey("Philippines"), code: "PH" },
  { name: localizationKey("Pitcairn"), code: "PN" },
  { name: localizationKey("Poland"), code: "PL" },
  { name: localizationKey("Portugal"), code: "PT" },
  { name: localizationKey("Puerto Rico"), code: "PR" },
  { name: localizationKey("Qatar"), code: "QA" },
  { name: localizationKey("Reunion"), code: "RE" },
  { name: localizationKey("Romania"), code: "RO" },
  { name: localizationKey("Russian Federation"), code: "RU" },
  { name: localizationKey("RWANDA"), code: "RW" },
  { name: localizationKey("Saint Helena"), code: "SH" },
  { name: localizationKey("Saint Kitts and Nevis"), code: "KN" },
  { name: localizationKey("Saint Lucia"), code: "LC" },
  { name: localizationKey("Saint Pierre and Miquelon"), code: "PM" },
  { name: localizationKey("Saint Vincent and the Grenadines"), code: "VC" },
  { name: localizationKey("Samoa"), code: "WS" },
  { name: localizationKey("San Marino"), code: "SM" },
  { name: localizationKey("Sao Tome and Principe"), code: "ST" },
  { name: localizationKey("Saudi Arabia"), code: "SA" },
  { name: localizationKey("Senegal"), code: "SN" },
  { name: localizationKey("Serbia and Montenegro"), code: "CS" },
  { name: localizationKey("Seychelles"), code: "SC" },
  { name: localizationKey("Sierra Leone"), code: "SL" },
  { name: localizationKey("Singapore"), code: "SG" },
  { name: localizationKey("Slovakia"), code: "SK" },
  { name: localizationKey("Slovenia"), code: "SI" },
  { name: localizationKey("Solomon Islands"), code: "SB" },
  { name: localizationKey("Somalia"), code: "SO" },
  { name: localizationKey("South Africa"), code: "ZA" },
  { name: localizationKey("South Georgia and the South Sandwich Islands"), code: "GS" },
  { name: localizationKey("Spain"), code: "ES" },
  { name: localizationKey("Sri Lanka"), code: "LK" },
  { name: localizationKey("Sudan"), code: "SD" },
  { name: localizationKey("Suriname"), code: "SR" },
  { name: localizationKey("Svalbard and Jan Mayen"), code: "SJ" },
  { name: localizationKey("Swaziland"), code: "SZ" },
  { name: localizationKey("Sweden"), code: "SE" },
  { name: localizationKey("Switzerland"), code: "CH" },
  { name: localizationKey("Syrian Arab Republic"), code: "SY" },
  { name: localizationKey("Taiwan, Province of China"), code: "TW" },
  { name: localizationKey("Tajikistan"), code: "TJ" },
  { name: localizationKey("Tanzania, United Republic of"), code: "TZ" },
  { name: localizationKey("Thailand"), code: "TH" },
  { name: localizationKey("Timor-Leste"), code: "TL" },
  { name: localizationKey("Togo"), code: "TG" },
  { name: localizationKey("Tokelau"), code: "TK" },
  { name: localizationKey("Tonga"), code: "TO" },
  { name: localizationKey("Trinidad and Tobago"), code: "TT" },
  { name: localizationKey("Tunisia"), code: "TN" },
  { name: localizationKey("Turkey"), code: "TR" },
  { name: localizationKey("Turkmenistan"), code: "TM" },
  { name: localizationKey("Turks and Caicos Islands"), code: "TC" },
  { name: localizationKey("Tuvalu"), code: "TV" },
  { name: localizationKey("Uganda"), code: "UG" },
  { name: localizationKey("Ukraine"), code: "UA" },
  { name: localizationKey("United Arab Emirates"), code: "AE" },
  { name: localizationKey("United Kingdom"), code: "GB" },
  { name: localizationKey("United States"), code: "US" },
  { name: localizationKey("United States Minor Outlying Islands"), code: "UM" },
  { name: localizationKey("Uruguay"), code: "UY" },
  { name: localizationKey("Uzbekistan"), code: "UZ" },
  { name: localizationKey("Vanuatu"), code: "VU" },
  { name: localizationKey("Venezuela"), code: "VE" },
  { name: localizationKey("Viet Nam"), code: "VN" },
  { name: localizationKey("Virgin Islands, British"), code: "VG" },
  { name: localizationKey("Virgin Islands, U.S."), code: "VI" },
  { name: localizationKey("Wallis and Futuna"), code: "WF" },
  { name: localizationKey("Western Sahara"), code: "EH" },
  { name: localizationKey("Yemen"), code: "YE" },
  { name: localizationKey("Zambia"), code: "ZM" },
  { name: localizationKey("Zimbabwe"), code: "ZW" },
]

export default countries
