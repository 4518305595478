import $ from "jquery"
import _ from "underscore"

function sidebarClickEvent($content, link, index) {
  const locationHash = $(link).attr("href")
  const headerHeight =
    $(`${locationHash} .section-title`).outerHeight(true) ||
    $(`${locationHash} header h4`).outerHeight(true) +
      $(locationHash)
        .parent(".scrollspy")
        .find(".section-title")
        .outerHeight(true)
  const offset = headerHeight - $content.scrollTop() + 100
  $(link).blur()
  $content.stop().animate(
    {
      scrollTop: index ? $(locationHash).offset().top - offset : 0,
    },
    500,
  )
}

const scrollspy = {
  bind: function(el) {
    var $content = $("#" + $(el).data("content-id"))
    var entityId = window.controller
      .get("activeTab")
      .get("deviceInfo")
      .get("id")
    $content.find(".scrollspy").each(function(index, value) {
      var href = $(value)
        .find("[data-href]")
        .data("href")
      var anchorId = entityId + "-" + href
      $(value).attr("id", anchorId)

      $(el)
        .find("li a[data-target-id=" + href + "]")
        .attr("href", "#" + anchorId)
        .click(function(e) {
          e.preventDefault()
          sidebarClickEvent($content, this, index)
          return false
        })
    })

    _.defer(function() {
      $content.scrollspy({
        target: "#sidebar",
        offset: 40,
      })
    })

    $(el).data("rivets.binders.scrollspy.content", $content)
    $(el).data("rivets.binders.scrollspy.bound", true)
  },

  routine: function(el) {
    var bound = $(el).data("rivets.binders.scrollspy.bound")
    var $newLinks = $(el).find('li a[href="#"]')
    if (bound && $newLinks.length) {
      var $content = $(el).data("rivets.binders.scrollspy.content")
      var entityId = window.controller
        .get("activeTab")
        .get("deviceInfo")
        .get("id")
      $newLinks.each(function(index, link) {
        var $item = $content.find("div.scrollspy[data-scope=" + $(link).data("target-id") + "]")
        var href = $item.find("[data-href]").data("href")
        var anchorId = entityId + "-" + href
        $item.attr("id", anchorId)
        $(link).attr("href", "#" + anchorId)
      })

      // updating all links here to renew offset positions
      $(el)
        .find("li a")
        .each(function(index, link) {
          $(link).click(function(e) {
            e.preventDefault()
            sidebarClickEvent($content, link, index)
            return false
          })
        })

      _.defer(function() {
        $content.scrollspy("refresh")
      })
    }
  },

  unbind: function(el) {
    var bound = $(el).data("rivets.binders.scrollspy.bound")
    if (bound) {
      var $content = $(el).data("rivets.binders.scrollspy.content")
      $(el)
        .find("li a")
        .each(function() {
          $(this)
            .unbind("click")
            .attr("href", "#")
        })
      $content.scrollspy("destroy")
      $(el).removeData()
    }
  },
}

export default scrollspy
