import { useRef } from "react"
import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import * as ninjaIcons from "@ninjaone/icons"
import { Box, Flex } from "js/includes/components/Styled"
import { colors } from "js/includes/common/theme"
import { Text } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import { StyledHiddenInput } from "js/includes/components/RichTextEditor/styled"
import AdditionalInstallApplicationScriptModal from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/AdditionalInstallApplicationScriptModal"

const StyledInputContainer = styled(Flex)`
  background-color: ${colors.ninjaGrey};
  margin: 10px 0;
  padding: 10px;
  & > div {
    width: 50%;
  }
`
const StyledLinkSpan = styled.div`
  text-align: end;
`
const StyledBoxDescription = styled(Box)`
  margin-left: ${sizer(3)};
  font-weight: 300 !important;
  color: ${({ theme }) => theme.color.black[100]};
`
const InputComponent = ({ label, actionTitle, action }) => {
  return (
    <StyledInputContainer color="black">
      <Text token={label} />
      <StyledLinkSpan onClick={action} className="btn-link">
        {!action ? (
          <Flex justifyContent="end" color={colors.reactSelectDarkGrey}>
            <Flex>
              <Box>
                <ninjaIcons.ExclamationCircleLight size="sm" />
              </Box>
              <Box marginLeft={sizer(1)}>{localized("File limit reached")}</Box>
            </Flex>
            <Flex marginLeft={sizer(3)}>
              <Text token={actionTitle} size="sm" />
            </Flex>
          </Flex>
        ) : (
          <Text token={actionTitle} size="sm" />
        )}
      </StyledLinkSpan>
    </StyledInputContainer>
  )
}

const CustomInput = ({
  theme,
  containerData,
  label,
  actionDescription,
  actionTitle,
  handleFileChange,
  disableAction,
  prePostScriptTitleModal,
  setScriptSelected,
  os = [],
}) => {
  const inputRef = useRef(null)

  const uploadFile = () => {
    if (disableAction) {
      return
    }

    inputRef.current.click()
  }

  const showPreScriptAutomation = () => {
    showModal(
      <AdditionalInstallApplicationScriptModal
        titleToken={prePostScriptTitleModal}
        setScriptSelected={setScriptSelected}
        os={os}
      />,
      {
        withProvider: true,
      },
    )
  }

  const handleAction = () => {
    if (!prePostScriptTitleModal) {
      uploadFile()
      return
    }
    showPreScriptAutomation()
  }

  return (
    <Box>
      {handleFileChange && (
        <StyledHiddenInput ref={inputRef} key={Date.now()} onChange={handleFileChange} type="file" />
      )}
      <InputComponent label={label} action={disableAction ? null : handleAction} actionTitle={actionTitle} />
      {actionDescription && (
        <StyledBoxDescription>
          <Text token={actionDescription} />
        </StyledBoxDescription>
      )}
      <Box marginLeft={sizer(3)} marginRight={sizer(3)}>
        {containerData()}
      </Box>
    </Box>
  )
}

export default CustomInput
