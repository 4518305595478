import $ from "jquery"
import "@ninjamsp/ninja-switchery/switchery.min.css"
import Switchery from "@ninjamsp/ninja-switchery"

const switchery = {
  routine: function(el, value) {
    var switchery
    var isDisabled = $(el).data("rivets.binders.switchery.isDisabled")
    var bound = $(el).data("rivets.binders.switchery.bound")
    if (bound && !isDisabled) {
      $(el).data("rivets.binders.switchery.isDisabled", true)
      var initialized = $(el).data("rivets.binders.switchery.initialized")

      if (!initialized) {
        var color = "#337ab7"
        var size = $(el).data("size")
        $(el).attr("checked", value)
        var options = { color: color }
        if (size) {
          options.size = size
        }
        switchery = new Switchery(el, options)

        // small hack to fix issue on rv-each switches where initial value was not displaying properly
        if (value && !switchery.jack.style.left.length) {
          switchery.jack.setAttribute("style", `${switchery.jack.getAttribute("style")} left: ${size ? 13 : 20}px;`)
        }

        $(el).data("rivets.binders.switchery.element", switchery)
        $(el).data("rivets.binders.switchery.initialized", true)
      } else if (el.checked !== value) {
        switchery = $(el).data("rivets.binders.switchery.element")
        if (switchery) {
          switchery.setPosition(true)
          switchery.handleOnchange(true)
        }
      }
      $(el).data("rivets.binders.switchery.isDisabled", false)
    }
  },

  bind: function(el) {
    const model = this.model
    var paths = this.keypath.split(":")

    el.onchange = function(e) {
      var isDisabled = $(el).data("rivets.binders.switchery.isDisabled")
      if (!isDisabled) {
        $(el).data("rivets.binders.switchery.isDisabled", true)
        model.set(paths[paths.length - 1], el.checked)
        $(el).data("rivets.binders.switchery.isDisabled", false)
      }
    }
    $(el).data("rivets.binders.switchery.initialized", false)
    $(el).data("rivets.binders.switchery.bound", true)
  },

  unbind: function(el) {
    var switchery = $(el).data("rivets.binders.switchery.element")
    if (switchery) {
      switchery.destroy()
      el.onchange = null
      $(el).data("rivets.binders.switchery.element", null)
      $(el)
        .next()
        .remove()
    }
    $(el).data("rivets.binders.switchery.bound", false)
  },
}

export default switchery
