import { Text, ConfirmationModal } from "@ninjaone/components"
import { localized, localizationKey, localizedWith } from "js/includes/common/utils"
import { StyledSpan } from "js/includes/components/Styled"

export const AddDevicesConfirmationModal = ({ onConfirm, unmount, devicesForReview, loading, org, location }) => {
  return (
    <ConfirmationModal
      unmount={unmount}
      titleText={
        devicesForReview.length === 1
          ? localized("Add 1 device?")
          : localized("Add {{numDevices}} devices?", { numDevices: devicesForReview.length })
      }
      actionToken={localizationKey("Confirm")}
      onConfirm={() => {
        onConfirm()
        unmount()
      }}
      disabled={loading}
    >
      <Text size="sm" textWrap>
        {devicesForReview.length === 1
          ? localizedWith("You are about to add 1 device to the organization <%orgWithLocation>x<%>.", {
              numDevices: () => devicesForReview.length,
              orgWithLocation: () => <StyledSpan fontWeight="bold">{`${org.name} (${location.label})`}</StyledSpan>,
            })
          : localizedWith(
              "You are about to add <%numDevices>x<%> devices to the organization <%orgWithLocation>x<%>.",
              {
                numDevices: () => devicesForReview.length,
                orgWithLocation: () => <StyledSpan fontWeight="bold">{`${org.name} (${location.label})`}</StyledSpan>,
              },
            )}
      </Text>
    </ConfirmationModal>
  )
}
