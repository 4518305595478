import { useEffect } from "react"
import { DataTable, Text, AlertMessage } from "@ninjaone/components"
import { ExternalLinkIconLight } from "@ninjaone/icons"
import { filterTypes } from "@ninjaone/components/src/DataTable"
import { getNodeRoles } from "js/includes/common/client"
import {
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  getNmsRoles,
  localizeMappingName,
} from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import showModal from "js/includes/common/services/showModal"
import { useMountedState } from "js/includes/common/hooks"
import { DeviceDetailsModal } from "js/includes/networkDiscovery/modals/DeviceDetailsModal"
import { useNetworkDiscovery } from "./NetworkDiscoveryContext"
import { getNetworkDiscoveryDojoLink } from "./utils"
import Link from "js/includes/components/Link"

export const Review = () => {
  const {
    devicesForReview,
    setDevicesForReview,
    validateReviewPage,
    devicesForReviewError,
    numDevicesWithoutRole,
  } = useNetworkDiscovery()
  const [deviceRoles, setDeviceRoles] = useMountedState([])

  useEffect(() => {
    const fetchDeviceRoles = async () => {
      try {
        const deviceRoles = await getNodeRoles()
        setDeviceRoles(getNmsRoles(deviceRoles))
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Failed to retrieve node roles."))
      }
    }

    fetchDeviceRoles()
  }, [setDeviceRoles])

  const columns = [
    {
      Header: "IP/DNS",
      id: "ip",
      accessor: "ip",
      maxWidth: "150px",
      getDescription: ({ dnsName, netbiosName }) => dnsName || "-",
    },
    {
      Header: localized("Device name"),
      id: "hostname",
      accessor: "hostname",
      minWidth: "150px",
      tooltipToken: localizationKey("If no name is provided, the IP will be used for the name."),
    },
    {
      Header: localized("Device role"),
      id: "role",
      accessor: ({ role }) => (role ? localizeMappingName(role) : localized("Unknown")),
      minWidth: "150px",
      maxWidth: "300px",
      getCellTypeProps: ({ role }) => ({
        error: devicesForReviewError && !role,
        required: !role,
      }),
    },
    {
      Header: localized("OS"),
      id: "os",
      accessor: "os",
    },
    {
      Header: localized("Model"),
      id: "model",
      accessor: "model",
    },
    {
      Header: localized("Vendor"),
      id: "vendor",
      accessor: "vendor",
    },
    {
      Header: localized("Firmware"),
      id: "firmware",
      accessor: "firmware",
    },
  ]
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" margin="16px 0px">
        <Text
          size="sm"
          color="colorTextWeakest"
          token={localizationKey(
            "Provide a name and role for the following devices before adding to the organization.",
          )}
        />
        <Link href={getNetworkDiscoveryDojoLink()}>
          {localized("Help")} <ExternalLinkIconLight size="xs" />
        </Link>
      </Flex>
      <DataTable
        tableId="network-discovery-review"
        columns={columns}
        rows={devicesForReview}
        actions={{
          primary: [
            {
              labelToken: localizationKey("Edit"),
              action: ({ selected }) =>
                showModal(
                  <DeviceDetailsModal
                    selected={selected}
                    deviceRoles={deviceRoles}
                    devicesForReview={devicesForReview}
                    setDevicesForReview={setDevicesForReview}
                    validateReviewPage={validateReviewPage}
                  />,
                ),
            },
          ],
          secondary: [
            {
              labelToken: localizationKey("Remove"),
              action: ({ selected }) => {
                const ipToRemove = selected[0].ip
                setDevicesForReview(devices => {
                  const updatedDevices = devices.filter(({ ip }) => ip !== ipToRemove)
                  validateReviewPage(updatedDevices)
                  return updatedDevices
                })
              },
              hideMultiAction: () => true,
              isRed: true,
            },
          ],
          row: {
            action: data =>
              showModal(
                <DeviceDetailsModal
                  selected={[data]}
                  deviceRoles={deviceRoles}
                  devicesForReview={devicesForReview}
                  setDevicesForReview={setDevicesForReview}
                  validateReviewPage={validateReviewPage}
                />,
              ),
          },
        }}
        hideSettingsButton
        filters={{
          primary: [
            {
              name: "type",
              type: filterTypes.SINGLE_SELECT,
              defaultValue: "all",
              labelToken: localizationKey("Type"),
              componentProps: {
                options: [
                  { label: localized("All IPs"), value: "all" },
                  { label: localized("All responding"), value: "allResponding" },
                  { label: localized("All SNMP responding"), value: "allSnmpResponding" },
                  { label: localized("Offline devices"), value: "offline" },
                ],
              },
            },
          ],
        }}
        getCustomRowProps={({ role }) => ({
          error: devicesForReviewError && !role,
          errorTooltipLabel: localized("Device role required"),
        })}
      />

      {devicesForReviewError && (
        <AlertMessage variant="danger">
          {localized(
            "{{numDevices}} device(s) have an unknown role. Please provide required fields before proceeding.",
            {
              numDevices: numDevicesWithoutRole,
            },
          )}
        </AlertMessage>
      )}
    </>
  )
}
