import { always, cond, T } from "ramda"
import styled from "@emotion/styled"
import { Modal, Text } from "@ninjaone/components"
import { ExternalLinkIconLight } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import Link from "js/includes/components/Link"
import {
  isAppInAustraliaInstance,
  isAppInCanadaInstance,
  isAppInEUInstance,
  isAppInUS2Instance,
  localized,
  DOJO_ARTICLES_PREFIX,
  localizationKey,
} from "js/includes/common/utils"
import { StyledSpan } from "js/includes/components/Styled"
import InstallerButtonRow from "js/includes/components/DeviceModal/InstallerButtonRow"

const StyledExternalLinkIcon = styled(ExternalLinkIconLight)`
  margin-left: 2px;
`

const StyledDiv = styled.div`
  font-size: ${getTextSize("sm")};
`

const getInstallerFileName = cond([
  [isAppInAustraliaInstance, always("Ninja_NMS_Setup_oc.exe")],
  [isAppInEUInstance, always("Ninja_NMS_Setup_eu.exe")],
  [isAppInCanadaInstance, always("Ninja_NMS_Setup_ca.exe")],
  [isAppInUS2Instance, always("Ninja_NMS_Setup_us2.exe")],
  [T, always("Ninja_NMS_Setup.exe")],
])
const helpLink = `${DOJO_ARTICLES_PREFIX}/207021723-NMS-Setup`
const getNmsAgentInstallerUrl = () => `https://nmspatcher.ninjarmm.com/patcher/download/${getInstallerFileName()}`
const AddNMSModal = ({ unmount }) => (
  <Modal
    {...{
      unmount,
      withCloseX: true,
      titleGroup: {
        TitleComponent: () => <Text token={localizationKey("Add NMS device")} color="colorTextStrong" size="lg" bold />,
      },
      buttonRenderer: () => <InstallerButtonRow {...{ url: getNmsAgentInstallerUrl() }} />,
    }}
  >
    <Text textWrap color="colorTextStrong" size="sm">
      {localized("Download installer to run the agent on your server.")}
      <StyledSpan marginLeft={sizer(1)}>
        <Link href={helpLink}>
          <StyledDiv fontSize={getTextSize("sm")}>
            {localized("Follow instructions on how to install Ninja on your NMS device")}
            <StyledExternalLinkIcon size="sm" />
          </StyledDiv>
        </Link>
      </StyledSpan>
    </Text>
  </Modal>
)
export default AddNMSModal
