import { useEffect, useCallback } from "react"
import { compose, map, filter, includes } from "ramda"
import { sizer } from "@ninjaone/utils"
import { localized, reportErrorAndShowMessage, sortByFieldNameCaseInsensitive } from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"
import { getNodeRoles } from "js/includes/common/client"
import { getReadableNodeRole } from "js/includes/common/_enums"
import { Box } from "js/includes/components/Styled"
import { StyledLabel } from "js/includes/components/DeviceModal/style"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const getNodeRoleOptions = (nodeRoles, nodeClasses) =>
  compose(
    sortByFieldNameCaseInsensitive("label", "ASC"),
    map(item => ({
      label: getReadableNodeRole(item.name),
      value: item.id || item?.name.toLowerCase(),
    })),
    filter(({ nodeClass }) => includes(nodeClass, nodeClasses)),
  )(nodeRoles)

const NodeRolePicker = ({ nodeClasses = [], selected, setSelected, disabled }) => {
  const [isLoading, setIsLoading] = useMountedState(true)
  const [nodeRoles, setNodeRoles] = useMountedState([])

  const fetchAndSetNodeRoles = useCallback(async () => {
    try {
      const nodeRoleList = await getNodeRoles()
      setNodeRoles(nodeRoleList)
    } catch (error) {
      reportErrorAndShowMessage(error)
    } finally {
      setIsLoading(false)
    }
  }, [setNodeRoles, setIsLoading])

  useEffect(() => {
    fetchAndSetNodeRoles()
  }, [fetchAndSetNodeRoles])

  useEffect(() => {
    if (selected?.nodeClass && !includes(selected.nodeClass, nodeClasses)) {
      setSelected(null)
    }
  }, [selected?.nodeClass, nodeClasses, setSelected])

  const options = [
    { label: localized("Auto"), value: localized("Auto").toLowerCase() },
    ...getNodeRoleOptions(nodeRoles, nodeClasses),
  ]
  const autoOption = options[0]

  return (
    <Box marginTop={sizer(3)}>
      <StyledLabel htmlFor="device-role-selector">{localized("Device role")}</StyledLabel>
      <SearchableDropDown
        {...{
          id: "device-role-selector",
          loading: isLoading,
          options,
          value: selected ?? autoOption,
          onSelect: setSelected,
          disabled,
          width: "100%",
          minHeight: "42px",
          rowHeight: 40,
          keepInView: false,
        }}
      />
    </Box>
  )
}

export default NodeRolePicker
