import { Modal, Text } from "@ninjaone/components"
import { Box } from "js/includes/components/Styled"
import {
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  showSuccessMessage,
  runDeviceSearchToolbarAction,
} from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"
import { sendActionMultipleDevices } from "js/includes/common/client"

export default function ClearPasscodeModal({ nodeIds, unmount, runnerActionName }) {
  const [loading, setLoading] = useMountedState(false)
  const handleClearPasscode = async () => {
    setLoading(true)
    try {
      if (runnerActionName) {
        await runDeviceSearchToolbarAction({ action: runnerActionName })
      } else {
        await sendActionMultipleDevices(nodeIds, { type: "CLEAR_PASSCODE_DEVICE" })
      }
      showSuccessMessage(localized("The action was sent successfully"))
      unmount()
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        reportErrorAndShowMessage(error, localizationKey("The action could not be sent"))
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal
      size="md"
      titleGroup={{
        titleToken: localizationKey("Clear Passcode?"),
      }}
      unmount={unmount}
      cancelable
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Clear Passcode"),
          onClick: handleClearPasscode,
          disabled: loading,
          variant: "danger",
        },
      ]}
    >
      <Box>
        <Text
          textWrap
          token={localizationKey(
            "If you wish to clear the currently set passcode, the end user of the device will be prompted to set a new passcode.",
          )}
        />
      </Box>
    </Modal>
  )
}
