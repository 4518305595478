import styled from "@emotion/styled"
import { Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { colors } from "js/includes/common/theme"

const StyledSection = styled.section`
  padding: ${sizer(3)} 0;
  border-bottom: 1px solid ${colors.lightGrey};
  word-wrap: break-word;

  & > div {
    font-size: 12px !important;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  & > div:last-child {
    margin-top: ${sizer(1)};
  }
`

const Section = ({ title, content }) => {
  return (
    <StyledSection>
      <Text size="sm" color={colors.ninjaMedium}>
        {title}
      </Text>
      {typeof content === "string" ? (
        <Text size="sm" color={colors.ninjaBlack} textWrap>
          {content}
        </Text>
      ) : (
        content
      )}
    </StyledSection>
  )
}

export default Section
