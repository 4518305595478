import React from "react"
import { Form, FormControl } from "react-bootstrap"
import {
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  validations,
  showErrorMessage,
} from "js/includes/common/utils"
import { Box, StyledSpan } from "js/includes/components/Styled"
import { Checkbox } from "js/includes/components/NinjaReactICheck"
import Table from "js/includes/components/Table"
import { StyledFormGroup, StyledCol, StyledModal } from "js/includes/components/Styled/Form"
import { useForm, useMountedState } from "js/includes/common/hooks"
import ADStyledModalContainer from "../components/ADStyledModalContainer"

export default function AdhocConfirmationModal({ unmount, devices, saveJob, runMode }) {
  const [shouldSaveJobWithName, setShouldSaveJobWithName] = useMountedState(false)
  const [saving, setSaving] = useMountedState(false)

  const {
    values: { jobName },
    validation,
    validateForm,
    onChange,
  } = useForm({
    fields: {
      jobName: "",
    },
    validate: {
      jobName: validations.required,
    },
  })

  return (
    <Form
      onSubmit={async e => {
        e.preventDefault()
        if (shouldSaveJobWithName && !validateForm()) return
        try {
          setSaving(true)
          await saveJob(...(shouldSaveJobWithName ? [jobName] : []))
          unmount()
        } catch (error) {
          if (error.resultCode === "DOMAIN_CONTROLLER_AND_OU_PATH_ALREADY_IN_USE") {
            showErrorMessage(localized("Domain Controller and OU Path already in use."))
          } else {
            reportErrorAndShowMessage(error)
          }
        } finally {
          setSaving(false)
        }
      }}
    >
      <ADStyledModalContainer>
        <StyledModal
          noHeader
          close={unmount}
          submittable
          saveText={localizationKey("Yes")}
          closeText={localizationKey("No")}
          disabledSaveBtn={saving}
          backgroundColor="#fff !important"
          borderRadius="5px"
        >
          <Box display="flex" flexDirection="column" alignItems="flex-start" margin={[2, 0, 3]} textAlign="left">
            <StyledSpan fontSize="20px" fontWeight="600" marginBottom={2}>
              {localized("Add Device(s)")}
            </StyledSpan>
            <StyledSpan fontSize="16px">
              {localized("You are about to add the following {{count}} devices to this discovery job.", {
                count: devices.length,
              })}
            </StyledSpan>
          </Box>
          <Box display="flex" flexDirection="column" height={200} width="100%" marginBottom={3}>
            <Table
              {...{
                columns: [
                  {
                    dataKey: "name",
                    width: 1,
                    flexGrow: 1,
                    label: localizationKey("Name"),
                  },
                  {
                    dataKey: "status",
                    width: 1,
                    flexGrow: 1,
                    label: localizationKey("Status"),
                    // TODO: Dynamic Token
                    cellRenderer: ({ cellData }) => <span>{localized(`general.${cellData.toLowerCase()}`)}</span>,
                  },
                  {
                    dataKey: "location",
                    width: 1,
                    flexGrow: 1,
                    label: localizationKey("Location"),
                    cellRenderer: ({ rowData }) => (
                      <span>{rowData.status === "REJECTED" ? "-" : rowData.location.name}</span>
                    ),
                  },
                ],
                list: devices,
                rowHeight: 40,
                headerHeight: 40,
                rowClassName: "table-row p-l-sm",
                disableHeader: false,
              }}
            />
          </Box>
          {!runMode && (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box>
                <Checkbox
                  checkboxClass="icheckbox_square-blue"
                  checked={shouldSaveJobWithName}
                  onChange={() => setShouldSaveJobWithName(!shouldSaveJobWithName)}
                />
                <span className="m-l-sm">{localized("Save discovery job for later use")}</span>
              </Box>
              {shouldSaveJobWithName && (
                <StyledFormGroup
                  textAlign="left"
                  marginTop={3}
                  width="100%"
                  flex={1}
                  validationState={validation.success.jobName === false ? "error" : null}
                >
                  <StyledCol>
                    <FormControl
                      name="jobName"
                      type="text"
                      placeholder={localized("Name")}
                      value={jobName}
                      {...{ onChange }}
                      className={`form-control ${validation.success.jobName === false ? "has-error" : ""}`}
                    />
                  </StyledCol>
                </StyledFormGroup>
              )}
            </Box>
          )}
        </StyledModal>
      </ADStyledModalContainer>
    </Form>
  )
}
