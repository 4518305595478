import { pluck } from "ramda"
import { localized, showErrorMessage, ninjaReportError } from "js/includes/common/utils"

export const setUsersList = usersList => ({
  type: "SET_USERS_LIST",
  usersList,
})

export const requestGetUsersList = () => async dispatch => {
  try {
    await window.userList.fetch()
    dispatch(setUsersList(pluck("attributes", window.userList.getModels())))
  } catch (error) {
    showErrorMessage(localized("Failed to load Users"))
    ninjaReportError(error)
  }
}

export const deleteUser = userId => ({
  type: "DELETE_USER",
  userId,
})
