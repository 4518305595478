import React from "react"
import Tooltip from "js/includes/components/Tooltip"
import { Box } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"

export default function StatusInfoTooltip() {
  return (
    <Tooltip
      tooltipMaxWidth="300px"
      output={
        <Box textAlign="left">
          <div>
            <b>{localized("Discovered")}</b> - {localized("Devices that were newly discovered.")}
          </div>
          <div>
            <b>{localized("Pending")}</b> -{" "}
            {localized("Put a device in a holding state so they can be approved or rejected afterwards.")}
          </div>
          <div>
            <b>{localized("Approved")}</b> -{" "}
            {localized(
              "Device is marked for installation. Active Directory instant push will be attempted. If it fails, a group policy object will be created for deployment on next computer restart.",
            )}
          </div>
          <div>
            <b>{localized("Rejected")}</b> - {localized("Device will be blacklisted.")}
          </div>
        </Box>
      }
      placement="right"
    />
  )
}
