import $ from "jquery"
import _ from "underscore"
import rivets from "rivets"

const popover = {
  routine: function(el, value) {
    _.defer(() => {
      // This is needed as some popovers use objects instead of models ie. values renderd via dot and not colon
      // Changing the value object explicitly will trigger a change event
      $(el).data("rivets.binders.popover.value", value)

      var bound = $(el).data("rivets.binders.popover.bound")
      if (!bound) {
        var templatePath = $(el).attr("data-popover-template")
        if (templatePath) {
          var template = _.template($("#" + templatePath).html())
          $(el).data("content", template())
        }

        $(el)
          .popover()
          .on("shown.bs.popover", function() {
            value = $(el).data("rivets.binders.popover.value")
            var popover = $("#" + $(this).attr("aria-describedby"))
            var binding = rivets.bind($(popover), {
              model: value,
            })
            $(el).data("rivets.binders.popover.binding", binding)
          })
          .on("hidden.bs.popover", function() {
            // Hack to overcome a bug in bootstrap. This avoids a second click to open the popover after it's been hidden once
            // http://stackoverflow.com/questions/32581987/need-click-twice-after-hide-a-shown-bootstrap-popover
            // This is fixed in bootstrap 4.0
            $(el).data("bs.popover").inState = { click: false, hover: false, focus: false }

            var binding = $(el).data("rivets.binders.popover.binding")
            if (binding) {
              binding.unbind()
            }
          })

        $(el).data("rivets.binders.popover.bound", true)
      }
    })
  },
  unbind: function(el) {
    $(el).popover("destroy")
    $(el).data("rivets.binders.popover.bound", false)
    $(el).off()
  },
}

export default popover
