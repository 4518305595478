import { createContext, useContext, useRef, useEffect, useCallback } from "react"
import { equals } from "ramda"
import {
  localized,
  ninjaReportError,
  reportErrorAndShowMessage,
  isNilOrEmpty,
  localizationKey,
} from "js/includes/common/utils"
import { useForm, useMountedState } from "js/includes/common/hooks"
import showModal from "js/includes/common/services/showModal"
import {
  startDiscovery,
  statusCheck,
  installDelegate,
  cancelDiscovery,
  addDevices,
  getAdditionalDevicesData,
} from "js/includes/common/client"
import {
  networkDiscoveryValidations,
  networkDiscoveryDefaults,
  getCurrentSubscription,
  mergeDeviceDataFromDelegate,
  mergeAdditionalDeviceData,
  CHANNEL_NAME,
} from "./utils"
import { ConfirmChanges } from "./modals/ConfirmChanges"
import { DiscoverySuccessModal } from "./modals/DiscoverySuccessModal"

export const NetworkDiscoveryContext = createContext()
const MAX_ATTEMPTS = 10
const TIMEOUT = 5000

export const NetworkDiscoveryProvider = ({ children, unmount }) => {
  const [currentIndex, setCurrentIndex] = useMountedState(0)
  const [loading, setLoading] = useMountedState(false)
  const [errorMessage, setErrorMessage] = useMountedState("")
  const [delegateVersion, setDelegateVersion] = useMountedState("")
  const [discoveryDevices, setDiscoveryDevices] = useMountedState([])
  const [devicesForReview, setDevicesForReview] = useMountedState([])
  const [discoveryComplete, setDiscoveryComplete] = useMountedState(false)
  const [status, setStatus] = useMountedState("")
  const [selectedDevicesError, setSelectedDevicesError] = useMountedState(false)
  const [devicesForReviewError, setDevicesForReviewError] = useMountedState(false)
  const [numDevicesWithoutRole, setNumDevicesWithoutRole] = useMountedState(null)
  const discoveryJobId = useRef(null)
  const savedFormRef = useRef({})
  const isModalMountedRef = useRef(null)
  const { values, onChange, validation, validateForm, resetForm } = useForm({
    fields: networkDiscoveryDefaults,
    validate: networkDiscoveryValidations,
  })
  const onDiscoveryCompleteRef = useRef()
  onDiscoveryCompleteRef.current = () => {
    unsubscribeFromDiscoveryUpdates()
    getAdditionalDeviceInfo()
  }
  const [attempts, setAttempts] = useMountedState(0)

  const getAdditionalDeviceInfo = useCallback(async () => {
    const { org, location, networkProbe } = values
    try {
      const additionalDevicesData = await getAdditionalDevicesData({
        orgId: org.id,
        locationId: location.value,
        nmsDelegateNodeId: networkProbe.nodeId,
        devices: discoveryDevices,
        telnetSshCred: values.telnetSshCred,
      })
      const devicesWithAdditionalData = mergeAdditionalDeviceData(discoveryDevices, additionalDevicesData)
      setDiscoveryDevices(devicesWithAdditionalData)
    } catch (error) {
      ninjaReportError(error)
    } finally {
      setLoading(false)
    }
  }, [discoveryDevices, setDiscoveryDevices, setLoading, values])

  const unsubscribeFromDiscoveryUpdates = useCallback(() => {
    const fullChannelName = `${window.wamp._getAgentId(values.networkProbe?.nodeId)}.${CHANNEL_NAME}`
    const subscription = getCurrentSubscription(fullChannelName)
    subscription && window.wamp.unSubscribeToChannel(subscription)
  }, [values.networkProbe?.nodeId])

  const subscribeToDiscoveryUpdates = async () => {
    try {
      await window.wamp.subscribeToChannel(
        CHANNEL_NAME,
        ([deviceData]) => {
          const { discoveredTargets: discoveryDevicesFromWamp } = JSON.parse(deviceData)
          const discoveryComplete = isNilOrEmpty(discoveryDevicesFromWamp)
          if (discoveryComplete) {
            discoveryJobId.current = null
            return setDiscoveryComplete(true)
          }
          setDiscoveryDevices(discoveryDevices =>
            mergeDeviceDataFromDelegate(discoveryDevicesFromWamp, discoveryDevices),
          )
        },
        values.networkProbe.nodeId,
      )
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error subscribing to discovery updates."))
    }
  }

  const initializeDiscoveryDevices = targetIps => {
    setDiscoveryDevices(
      targetIps.map(ip => ({
        ip,
        type: ["all"],
      })),
    )
  }

  const stopDiscovery = useCallback(async () => {
    if (!discoveryJobId.current) return
    try {
      await cancelDiscovery(discoveryJobId.current)
      setLoading(false)
      unsubscribeFromDiscoveryUpdates()
      discoveryJobId.current = null
    } catch (error) {
      reportErrorAndShowMessage(error)
    }
  }, [setLoading, unsubscribeFromDiscoveryUpdates])

  const resetDiscoveryState = useCallback(() => {
    errorMessage && setErrorMessage("")
    setSelectedDevicesError(false)
    setDiscoveryComplete(false)
    setDevicesForReview([])
    setDiscoveryDevices([])
  }, [
    errorMessage,
    setErrorMessage,
    setSelectedDevicesError,
    setDiscoveryComplete,
    setDevicesForReview,
    setDiscoveryDevices,
  ])

  const restartDiscovery = async () => {
    try {
      resetDiscoveryState()
      setLoading(true)
      subscribeToDiscoveryUpdates()
      const { jobUUID, targetsIPs } = await startDiscovery(values)
      discoveryJobId.current = jobUUID
      initializeDiscoveryDevices(targetsIPs)
    } catch (error) {
      unsubscribeFromDiscoveryUpdates()
      reportErrorAndShowMessage(error, localized("Failed to start network discovery"))
    }
  }

  const checkInstallationStatus = async () => {
    try {
      const {
        args: [step],
        kwargs: { error, version },
      } = await statusCheck(values.networkProbe.nodeId)
      setDelegateVersion(version)
      switch (step) {
        case "OK":
          setStatus(localized("Starting network discovery"))
          subscribeToDiscoveryUpdates()
          return setTimeout(async () => {
            try {
              const { jobUUID, targetsIPs } = await startDiscovery(values)
              discoveryJobId.current = jobUUID
              initializeDiscoveryDevices(targetsIPs)
              setCurrentIndex(1)
            } catch (error) {
              setLoading(false)
              unsubscribeFromDiscoveryUpdates()
              if (error?.resultCode === "number_of_network_scan_targets_exceeded") {
                return setErrorMessage(
                  localized(
                    "Maximum number of targets cannot exceed {{maxDevices}}. Please modify the target list and try again.",
                    { maxDevices: error.errorMessage },
                  ),
                )
              } else if (error?.resultCode === "network_scan_is_in_progress") {
                return setErrorMessage(localized("Network scan still in progress. Please wait and try again."))
              }
              reportErrorAndShowMessage(error, localizationKey("Failed to start network discovery"))
            }
          }, TIMEOUT)
        case "INSTALL_FAILED":
          setLoading(false)
          setErrorMessage(localized("Failed to install delegate. Please try again."))
          return ninjaReportError(error)
        default:
          setStatus(localized("Preparing device for network discovery"))
          return setTimeout(checkInstallationStatus, TIMEOUT)
      }
    } catch (error) {
      if (attempts < MAX_ATTEMPTS) {
        setAttempts(attempt => attempt + 1)
        setTimeout(checkInstallationStatus, TIMEOUT)
      } else {
        setLoading(false)
        unsubscribeFromDiscoveryUpdates()
        reportErrorAndShowMessage(error, localizationKey("Failed to start network discovery"))
      }
    }
  }

  const startDelegateInstallation = async () => {
    if (validateForm()) {
      try {
        resetDiscoveryState()
        setLoading(true)
        setStatus(localized("Preparing device for network discovery"))
        await installDelegate(values.networkProbe.nodeId)
        savedFormRef.current = values
        setTimeout(checkInstallationStatus, TIMEOUT)
      } catch (error) {
        reportErrorAndShowMessage(error, localized("Failed to start network discovery"))
      }
    }
  }

  const addDiscoveryDevices = async () => {
    try {
      setLoading(true)
      await addDevices({
        orgId: values.org.id,
        locationId: values.location.value,
        nmsDelegateNodeId: values.networkProbe.nodeId,
        devices: devicesForReview,
        telnetSshCred: values.telnetSshCred,
      })
      unmount()
      showModal(<DiscoverySuccessModal values={values} devicesForReview={devicesForReview} />)
    } catch (error) {
      setLoading(false)
      reportErrorAndShowMessage(error)
    }
  }

  const validateReviewPage = devicesForReview => {
    const numDevicesWithoutRole = devicesForReview.filter(({ role }) => !role).length
    const hasErrors = !!numDevicesWithoutRole
    setNumDevicesWithoutRole(numDevicesWithoutRole)
    setDevicesForReviewError(hasErrors)
    return hasErrors
  }

  useEffect(() => {
    if (discoveryComplete && !equals(savedFormRef.current, values) && !isModalMountedRef.current) {
      isModalMountedRef.current = true
      showModal(
        <ConfirmChanges
          onConfirm={({ unmount }) => {
            resetDiscoveryState()
            isModalMountedRef.current = false
            unmount()
          }}
          onCancel={({ unmount }) => {
            onChange(savedFormRef.current)
            isModalMountedRef.current = false
            unmount()
          }}
          closeAction={unmount => {
            onChange(savedFormRef.current)
            isModalMountedRef.current = false
            unmount()
          }}
        />,
      )
    }
  }, [resetDiscoveryState, discoveryComplete, onChange, values])

  // Stops discovery when user clicks 'escape' key
  useEffect(() => {
    return () => {
      if (discoveryJobId.current) {
        stopDiscovery()
      }
    }
  }, [stopDiscovery])

  useEffect(() => {
    if (discoveryComplete) {
      onDiscoveryCompleteRef.current()
    }
  }, [discoveryComplete])

  return (
    <NetworkDiscoveryContext.Provider
      value={{
        currentIndex,
        setCurrentIndex,
        values,
        onChange,
        validation,
        validateForm,
        unmount,
        loading,
        setLoading,
        discoveryDevices,
        devicesForReview,
        setDevicesForReview,
        status,
        setStatus,
        startDelegateInstallation,
        errorMessage,
        discoveryComplete,
        setDiscoveryComplete,
        stopDiscovery,
        resetForm,
        restartDiscovery,
        addDiscoveryDevices,
        selectedDevicesError,
        setSelectedDevicesError,
        delegateVersion,
        setDelegateVersion,
        devicesForReviewError,
        numDevicesWithoutRole,
        setDevicesForReviewError,
        validateReviewPage,
      }}
    >
      {children}
    </NetworkDiscoveryContext.Provider>
  )
}

export const useNetworkDiscovery = () => useContext(NetworkDiscoveryContext)
