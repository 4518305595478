import { useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { includes } from "ramda"
import { HyperVGuestIcon, VmGuestIcon } from "@ninjaone/icons"
import { useForm, useMounted, useMountedState } from "js/includes/common/hooks"
import OrganizationPicker from "js/includes/components/OrganizationLocationPicker/OrganizationPicker"
import MonitorDelegatePicker from "js/includes/components/DeviceModal/AddVMModal/MonitorDelegatePicker"
import { fetchCredentials } from "js/state/actions/credentials"
import { isNumber, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import DeviceModal from "js/includes/components/DeviceModal"
import {
  validateApiPort,
  validateCredential,
  validateDnsIp,
  validateMonitorDelegate,
} from "js/includes/editors/Customer/Sections/Virtualization/modals/validation/newHost"
import {
  statusCheck,
  checkForAgent,
  checkForConnection,
  downloading,
  installed,
  installing,
  starting,
  testSuccess,
  testFailed,
  errorInstallingAgent,
  failedToDownload,
  failedToStartService,
  errorInstallingAgentWithMessage,
  possibleErrors,
  testingConnection,
  checkingForAgent,
  errorTestingAgent,
  installedOrInstalling,
} from "js/includes/editors/Customer/Sections/Virtualization/hostTest"
import DnsIp from "js/includes/components/DeviceModal/AddVMModal/DnsIp"
import ApiPort from "js/includes/components/DeviceModal/AddVMModal/ApiPort"
import Credential from "js/includes/components/DeviceModal/AddVMModal/Credential"
import AddVMSuccessModal from "js/includes/components/DeviceModal/AddVMModal/SuccessModal"
import { DOJO_ARTICLES_PREFIX, debugLog, localized, ninjaReportError } from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import { addVMHost } from "js/includes/common/client"

const STATUS_INTERVAL = 5000

const computerOptions = [
  {
    labelText: "Windows Hyper-V",
    value: "HYPERV",
    icon: <HyperVGuestIcon fontSize="20px" />,
  },
  {
    labelText: "VMWare ESXI",
    value: "ESXI",
    icon: <VmGuestIcon fontSize="20px" />,
  },
]

const computerHeadingTokenMapper = {
  HYPERV: "addDevices.hyperVConfiguration",
  ESXI: "addDevices.vmWareConfiguration",
}

const linkMapper = {
  HYPERV: `${DOJO_ARTICLES_PREFIX}/360057099852-Hyper-V-Monitoring`,
  ESXI: `${DOJO_ARTICLES_PREFIX}/360031007591-VMware-Monitoring`,
}

const formValidations = isESXI => {
  return {
    organization: org => {
      return org?.id ? { success: true, message: "" } : { success: false }
    },
    monitorDelegate: validateMonitorDelegate,
    ...(isESXI && {
      dnsIp: validateDnsIp,
      apiPort: validateApiPort,
      selectedCredential: credential => validateCredential(credential?.id),
    }),
  }
}

const AddVMModal = ({ unmount, fetchCredentials }) => {
  const [installerType, setInstallerType] = useMountedState(computerOptions[0].value)
  const [addError, setAddError] = useMountedState("")
  const [isLoading, setIsLoading] = useMountedState(false)
  const [test, setTest] = useMountedState(null)
  const mounted = useMounted()
  let statusInterval = null

  const isESXI = installerType === "ESXI"

  const messageInfo = useMemo(() => {
    return addError
      ? {
          show: true,
          token: addError,
        }
      : test?.type === "PROGRESS"
      ? {}
      : test?.success
      ? {
          show: true,
          token: "editorsCustomerCustomerEditor.testSucceeded",
          variant: "success",
        }
      : test?.success === false
      ? { show: true, token: test.label ?? "addDevices.configurationTestFailed", variant: "danger" }
      : test?.success === false
      ? { show: true, token: test.label ?? "addDevices.configurationTestFailed", variant: "danger" }
      : {
          show: true,
          token: "addDevices.defaultVMConfigurationMessage",
          variant: "info",
        }
  }, [test, addError])

  const {
    values: { organization, monitorDelegate, dnsIp, apiPort, selectedCredential },
    onChange,
    validation,
    validateForm,
    updateValidate,
    resetForm,
  } = useForm({
    fields: {
      organization: null,
      monitorDelegate: null,
      dnsIp: "",
      apiPort: "",
      selectedCredential: null,
    },
    validate: formValidations(isESXI),
  })

  const isDisabled = !organization?.id || isLoading

  useEffect(() => {
    updateValidate(formValidations(isESXI))
  }, [updateValidate, isESXI])

  useEffect(() => {
    if (organization?.id) {
      fetchCredentials(organization.id)
    }
  }, [fetchCredentials, organization])

  const addHost = async () => {
    setIsLoading(true)
    setAddError("")
    try {
      const host = await addVMHost({
        clientId: organization.id,
        delegateNodeId: monitorDelegate.nodeId,
        name: monitorDelegate.dnsName,
        hostname: isESXI ? dnsIp : monitorDelegate.dnsName,
        ...(isESXI && { apiPort, credentialId: selectedCredential.id }),
        type: installerType,
      })
      mounted.current &&
        showModal(
          <AddVMSuccessModal
            {...{ url: `/vmDashboard/${host.id}/overview`, deviceInfo: host, organization, resetData }}
          />,
        )
    } catch (error) {
      if (error.resultCode === "delegate_already_has_existing_host") {
        setAddError(localizationKey("VM Host already exists under the same Monitor Delegate."))
      } else {
        setAddError(localizationKey("Error adding virtual machine host. Please try again."))
      }

      ninjaReportError(error)
    } finally {
      setIsLoading(false)
    }
  }

  function stopPolling() {
    setIsLoading(false)
    clearInterval(statusInterval)
  }

  async function setStep(wampResponse, delegate) {
    const { args, kwargs } = wampResponse
    const [step] = args
    const { stage, error } = kwargs

    if (installedOrInstalling(step)) {
      switch (stage) {
        case "downloading":
          setTest(downloading)
          break
        case "installing":
          setTest(installing)
          break
        case "starting":
          setTest(starting)
          break
        case "installed":
          setTest(installed)
          break
        default:
          throw new Error("Invalid install stage")
      }
    } else if (step === "OK") {
      stopPolling()
      debugLog(`The current Delegate Agent version is: ${kwargs.version}`)

      try {
        setTest(testingConnection)
        setIsLoading(true)
        const { code, params } = await checkForConnection(organization.id, delegate)
        if (includes(code, possibleErrors)) {
          setTest(testFailed(params))
        } else if (code === "vm_host_test_delegate_error_with_message") {
          const { message } = params
          setTest(errorInstallingAgentWithMessage(message))
        } else {
          setTest(testSuccess)
        }
      } catch (error) {
        setTest(testFailed())
        ninjaReportError(error)
      } finally {
        setIsLoading(false)
      }
    } else if (step === "ERROR") {
      stopPolling()
      setTest(errorInstallingAgent)
    } else if (step === "INSTALL_FAILED") {
      stopPolling()
      let test
      switch (error) {
        case "failed_to_download":
          test = failedToDownload
          break
        case "failed_to_start_service":
          test = failedToStartService
          break
        default:
          test = errorInstallingAgent
      }
      setTest(test)
    }
  }

  const testHost = async () => {
    if (validateForm()) {
      const { nodeId: delegateNodeId, dnsName: delegateName } = monitorDelegate
      const values = {
        delegateNodeId,
        delegateName,
        hostname: isESXI ? dnsIp : delegateName,
        ...(isESXI && { apiPort, credentialId: selectedCredential.id }),
        type: installerType,
      }
      if (!delegateNodeId) return
      try {
        setTest(checkingForAgent)
        setIsLoading(true)
        await checkForAgent(delegateNodeId)

        const response = await statusCheck(delegateNodeId)
        await setStep(response, values)

        const { args } = response
        const [step] = args

        if (installedOrInstalling(step)) {
          statusInterval = setInterval(async () => {
            const response = await statusCheck(delegateNodeId)
            await setStep(response, values)
          }, STATUS_INTERVAL)
        }
      } catch (error) {
        setTest(errorTestingAgent)
        setIsLoading(false)
        ninjaReportError(error)
      }
    }
  }

  const resetData = () => {
    resetForm({
      fields: {
        organization: organization || null,
        monitorDelegate: null,
        dnsIp: "",
        apiPort: "",
        selectedCredential: null,
      },
    })
    setTest(null)
    setAddError("")
  }

  return (
    <DeviceModal
      {...{
        unmount,
        titleGroup: {
          titleToken: "addDevices.addVMHost",
          descriptionToken: "addDevices.addVMHostDescription",
          link: linkMapper[installerType],
        },
        buttons: [
          {
            labelToken: "general.testHost",
            disabled: isLoading,
            onClick: testHost,
            variant: "secondary",
          },
          {
            labelToken: "general.addHost",
            disabled: !test?.success || isLoading,
            onClick: addHost,
            variant: "primary",
          },
        ],
        showLoader: isLoading,
        loaderToken: test?.label && !test?.success ? test.label : "addDevices.addingHost",
        options: computerOptions,
        installerType,
        setInstallerType: type => {
          setInstallerType(type)
          resetData()
        },
        optionsTitleToken: computerHeadingTokenMapper[installerType],
        message: messageInfo,
        minHeight: "480px",
        OptionsComponent: (
          <>
            <OrganizationPicker
              {...{
                changeOrganization: org => {
                  onChange({
                    organization: org,
                    monitorDelegate: null,
                    selectedCredential: null,
                  })
                  setTest(null)
                  setAddError("")
                },
                value: organization,
                organization,
                disabled: isLoading,
                openOnFocus: true,
                labelAbove: true,
                validationState: validation.success.organization === false ? "error" : "",
                validateForm,
              }}
            />
            <MonitorDelegatePicker
              {...{
                clientId: organization?.id,
                disabled: isDisabled,
                onChange: val => {
                  onChange("monitorDelegate", val)
                  setTest(null)
                  setAddError("")
                },
                selected: monitorDelegate,
                isHyperV: !isESXI,
                validationState: validation.success.monitorDelegate === false ? "error" : "",
                validateForm,
              }}
            />
            {isESXI && (
              <>
                <DnsIp
                  {...{
                    onChange: val => {
                      onChange("dnsIp", val)
                      setTest(null)
                      setAddError("")
                    },
                    value: dnsIp,
                    disabled: isDisabled,
                    errorMessage: validation.success.dnsIp === false ? localized("general.required") : "",
                    validateForm,
                  }}
                />
                <ApiPort
                  {...{
                    value: apiPort,
                    onChange: val => {
                      const newNum = Number(val)
                      if (isNumber(newNum)) {
                        onChange("apiPort", newNum)
                        setTest(null)
                        setAddError("")
                      }
                    },
                    disabled: isDisabled,
                    errorMessage: validation.success.apiPort === false ? localized("general.required") : "",
                    validateForm,
                  }}
                />
                <Credential
                  {...{
                    value: selectedCredential,
                    onChange: type => {
                      onChange("selectedCredential", type)
                      setTest(null)
                      setAddError("")
                    },
                    disabled: isDisabled,
                    validationState: validation.success.selectedCredential === false ? "error" : "",
                    validateForm,
                  }}
                />
              </>
            )}
          </>
        ),
      }}
    />
  )
}

export default connect(null, {
  fetchCredentials,
})(AddVMModal)
