import moment from "moment"
import { localizedF, serverScheduleDateFormat } from "js/includes/common/utils/ssrAndWebUtils"

export const repeatsOptionsMap = {
  EMPTY: {
    value: "",
    text: localizedF("Select..."),
  },
  DAILY: {
    value: "DAILY",
    text: localizedF("Daily"),
    hasRecurrence: true,
    maxRecurrence: 365,
    recurrenceText: localizedF("day(s)"),
  },
  HOURLY: {
    value: "HOURLY",
    text: localizedF("Hourly"),
    hasRecurrence: true,
    maxRecurrence: 12,
    recurrenceText: localizedF("Hour(s)"),
  },
  WEEKLY: {
    value: "WEEKLY",
    text: localizedF("Weekly"),
    hasRecurrence: true,
    maxRecurrence: 52,
    recurrenceText: localizedF("week(s)"),
  },
  MONTHLY: {
    value: "MONTHLY",
    text: localizedF("Monthly"),
    hasRecurrence: true,
    maxRecurrence: 12,
    recurrenceText: localizedF("month(s)"),
  },
  ON_SYSTEM_STARTUP: {
    value: "ON_SYSTEM_STARTUP",
    text: localizedF("On System Startup"),
  },
  ON_USER_LOGIN: {
    value: "ON_USER_LOGIN",
    text: localizedF("On User Login"),
  },
  NEVER: { value: "NEVER", text: localizedF("Never") },
  EVERY: {
    value: "EVERY",
    text: localizedF("Every"),
  },
}

export const defaultRepeatsOptions = [repeatsOptionsMap.DAILY, repeatsOptionsMap.WEEKLY, repeatsOptionsMap.MONTHLY]

export const defaultEndOptions = [
  { value: "NEVER", text: localizedF("Never") },
  { value: "AFTER", text: localizedF("After") },
  { value: "ON", text: localizedF("On") },
]

export const getDefaultScheduleWithDateFormat = (dateFormat = serverScheduleDateFormat, timezone) => {
  const startDate = timezone ? moment.tz(timezone).format(dateFormat) : moment().format(dateFormat)
  return {
    schema: "2.0",
    dayOfMonth: 1,
    daysOfWeek: ["MON"],
    repeats: "DAILY",
    repeatsEveryCount: 1,
    runAtHour: 9,
    runAtMinute: 0,
    startDate,
    end: {
      type: "NEVER",
      occurenceCount: 1,
      endDate: null,
    },
  }
}

export const defaultSchedule = getDefaultScheduleWithDateFormat(serverScheduleDateFormat)
