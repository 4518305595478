import React from "react"
import _ from "underscore"
import { faExclamationTriangle, faTrash } from "@fortawesome/pro-solid-svg-icons"
import ModelBase from "../../includes/editors/Common/ModelBase"
import {
  DeviceInfoModel,
  APIKeyInfoModel,
  PsaTicketTemplateInfoModel,
  UserInfoModel,
  CustomerInfoModel,
  PolicyInfoModel,
} from "./InfoModels"
import {
  reportErrorAndShowMessage,
  assignValueToPropertiesInObj,
  fetchJson,
  localized,
  localizationKey,
  ninjaReportError,
  isVmMonitorDelegate,
  getEditorType,
  showSuccessMessage,
  localizedWith,
} from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { deleteUser } from "js/state/actions/usersListTab/usersList"
import { removeRecentTab } from "js/state/actions/general/recentTabs"
import { removeFavoriteTab } from "js/state/actions/general/favoriteTabs"
import { getLockhartStatus } from "js/includes/common/backup"
import Disclaimer from "js/includes/components/Disclaimer"
import { fetchCustomerDevicesCount } from "js/state/actions/dashboard/customerOverview"
import { hasDCPendingCandidateNodes, removeDevice } from "js/includes/common/client"
import { deleteUserFromOpenTickets } from "js/state/actions/ticketing"
import { removeOrganizationFromList } from "js/state/actions/organizations"
import { LockhartDataAckMessageComponent } from "js/includes/components/LockhartDataAckMessageComponent"
import ExternalLink from "js/includes/components/ExternalLink"

const InfoModel = ModelBase.extend({
  defaults: {
    preventDeallocation: true,
    deleteBackupDataPermanently: false,
  },
  initialize() {
    if (this instanceof CustomerInfoModel) {
      this.infoType = "customer"
      this.label = () => localized("Organization").toLowerCase()
    } else if (this instanceof DeviceInfoModel) {
      assignValueToPropertiesInObj(this, ["infoType"], "device")
      assignValueToPropertiesInObj(this, ["label"], () => localized("Device").toLowerCase())
    } else if (this instanceof PolicyInfoModel) {
      const type = getEditorType(this.get("nodeClass") || "")
      assignValueToPropertiesInObj(this, ["infoType", "label"], type)
      assignValueToPropertiesInObj(this, ["label"], () => localized("Policy").toLowerCase())
    } else if (this instanceof UserInfoModel) {
      assignValueToPropertiesInObj(this, ["infoType"], "user")
      assignValueToPropertiesInObj(this, ["label"], () => localized("User").toLowerCase())
    } else if (this instanceof PsaTicketTemplateInfoModel) {
      assignValueToPropertiesInObj(this, ["infoType"], "ticket template")
      assignValueToPropertiesInObj(this, ["label"], () => localized("Ticket Template").toLowerCase())
    } else if (this instanceof APIKeyInfoModel) {
      assignValueToPropertiesInObj(this, ["infoType"], "APIKeys")
      assignValueToPropertiesInObj(this, ["label"], () => localized("API Key"))
    }

    _.bindAll(this, "open", "edit", "delete")
  },

  fetch() {
    throw new Error(localized("Please fetch the associated FullModel instead"))
  },

  destroy() {
    throw new Error(localized("Please use the delete function instead"))
  },

  open(e) {
    // If there are no class level overrides, then edit
    this.edit(e)
  },

  edit(e) {
    // If there was a click event
    if (e && e.type === "click") {
      e.preventDefault()
    }

    if (this.infoType === "device") {
      window.location.hash = "#/wizard/cloudMonitor/" + this.get("id")
    } else {
      window.location.hash = "#/editor/" + this.infoType + "/" + this.get("id")
    }
  },

  async delete(e) {
    // Sometimes delete is called via JS and not via a click event
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    this.set("deleteBackupDataPermanently", false)

    var infoModel = this

    if (infoModel.infoType === "user" && infoModel.get("uid") === window.application.get("session").get("appUserUid")) {
      ShowMessageDialog({
        icon: { icon: faExclamationTriangle, type: "critical" },
        title: localizationKey("You can't delete your own user account."),
        buttons: [{ id: "OK", label: localizationKey("OK") }],
      })
    } else if (
      ["policy", "vmpolicy", "androidpolicy", "iospolicy"].includes(infoModel.infoType) &&
      infoModel.get("nodeClassDefault")
    ) {
      ShowMessageDialog({
        icon: { icon: faTrash, type: "critical" },
        title: () => this.getName(),
        message: localizationKey("You can't delete a default policy."),
        buttons: [{ id: "OK", label: localizationKey("OK") }],
      })
    } else if (infoModel.infoType === "ticket template" && infoModel.get("isDefault")) {
      ShowMessageDialog({
        icon: { icon: faTrash, type: "critical" },
        title: () => this.getName(),
        message: localizationKey("You can't delete the default ticket template."),
        buttons: [{ id: "OK", label: localizationKey("OK") }],
      })
    } else {
      const infoType = infoModel.infoType
      const isCustomer = infoType === "customer"
      const isDevice = infoType === "device"
      const infoLabel = typeof infoModel.label === "function" ? infoModel.label() : infoModel.label
      const hasPendingCandidateNodes = isDevice && (await hasDCPendingCandidateNodes(infoModel.id))
      let lockhartInfoMessage = null
      const showGravityZoneWarning =
        isCustomer && window.store.getState().session.divisionConfig.find(({ name }) => name === "GRAVITYZONE")?.enabled

      if (isDevice) {
        const { hasData: hasLockhartData } = await getLockhartStatus({
          nodeId: infoModel.id,
          checkIfEnabledFirst: false,
        })

        if (hasLockhartData) {
          lockhartInfoMessage = localized("There may be backup data stored for this device.")
        }

        if (isVmMonitorDelegate(infoModel.attributes)) {
          return ShowMessageDialog({
            icon: { icon: faTrash, type: "critical" },
            title: () => infoModel.getName(),
            message: localizationKey(
              "This device is configured as a Monitor Delegate for a VM Host. You will not be able to delete it until you delete the underlying VM host from Organization > Virtualization tab.",
            ),
            buttons: [{ id: "OK", label: localizationKey("OK") }],
          })
        }
      }

      if (isCustomer) {
        const { hasData: hasLockhartData } = await getLockhartStatus({
          customerId: this.id,
          checkIfEnabledFirst: false,
        })

        if (hasLockhartData) {
          lockhartInfoMessage = localized("There may be backup data stored for this organization.")
        }
      }

      const totalDevices = isCustomer && (await fetchCustomerDevicesCount(infoModel.id))

      const deleteButtonPressed = await ShowMessageDialog({
        icon: { icon: faTrash, type: "critical" },
        title: () => infoModel.getName(),
        message:
          totalDevices > 0
            ? () =>
                localized(
                  "WARNING! You are about to delete this organization AND {{totalDevices}} device(s) associated with the organization. This action cannot be undone. Do you wish to proceed?",
                  { totalDevices },
                )
            : () => `${localized("Are you sure you want to delete this")} ${infoLabel}?`,
        MessageComponent: props => (
          <>
            {hasPendingCandidateNodes && (
              <p>
                {localized(
                  "This Domain Controller has associated device(s) pending installation and/or discovery job(s) that will also be deleted.",
                )}
              </p>
            )}
            {showGravityZoneWarning && (
              <p>
                {localizedWith(
                  "<%bold>Note<%>: This organization may have some devices with Bitdefender GravityZone installed. Deleting this organization will NOT uninstall GravityZone from them due to security reasons. Also, you will still be charged for these devices until you explicitly uninstall the GravityZone agent from the <%link>GravityZone console<%>.",
                  {
                    bold: ({ localizedText }) => <strong>{localizedText}</strong>,
                    link: ({ localizedText }) => (
                      <ExternalLink url="https://gravityzone.bitdefender.com">{localizedText}</ExternalLink>
                    ),
                  },
                )}
              </p>
            )}
            {lockhartInfoMessage ? (
              <LockhartDataAckMessageComponent
                {...{
                  setPermanentDataDeletionState: deleteBackupDataPermanently =>
                    this.set("deleteBackupDataPermanently", deleteBackupDataPermanently),
                  setValidation: props.setValidation,
                  requireEmail: true,
                  lockhartInfoMessage,
                }}
              />
            ) : (
              <Disclaimer {...props} />
            )}
          </>
        ),
        buttons: [
          { id: "NO", label: localizationKey("No") },
          { id: "YES", label: localizationKey("Delete"), validate: true, type: "critical" },
        ],
      })

      const deleteDeviceOrOrganization = async () => {
        if (isCustomer) {
          const deleteBackupDataPermanently = this.get("deleteBackupDataPermanently")
          try {
            const warnings = await fetchJson(`/client/${this.id}/delete/warning`)

            for (const warning of warnings) {
              const accepted = await ShowMessageDialog({
                icon: { icon: faExclamationTriangle, type: "critical" },
                title: () => warning.message,
                message: infoModel.getWarningMessageToken(warning.code),
                buttons: [
                  { id: false, label: localizationKey("Cancel") },
                  { id: true, label: localizationKey("Continue"), type: "critical" },
                ],
              })

              if (!accepted) {
                return
              }
            }
          } catch (error) {
            ninjaReportError(error)
          }

          if (deleteBackupDataPermanently) {
            const confirmButtonPressed = await ShowMessageDialog({
              icon: { icon: faExclamationTriangle, type: "critical" },
              title: () => `${localized("All data for")} ${infoModel.getName()} ${localized("will be lost")}`,
              message: () =>
                localized(
                  "This customer has {{totalDevices}} device(s). All data for this customer and its device(s) will be deleted.",
                  { totalDevices },
                ),
              buttons: [
                { id: "CANCEL", label: localizationKey("Cancel") },
                { id: "DELETE", label: localizationKey("Delete"), type: "critical" },
              ],
            })
            if (confirmButtonPressed === "DELETE") {
              infoModel.destroyAndUpdateList()
            }
          } else {
            infoModel.destroyAndUpdateList()
          }
        } else {
          infoModel.destroyAndUpdateList()
        }
      }

      if (deleteButtonPressed === "YES") {
        await deleteDeviceOrOrganization()
      }
    }
  },

  getWarningMessageToken(warning) {
    switch (warning) {
      case "TICKETING_TICKET_OPEN":
        return localizationKey(
          "This organization has not-yet-closed tickets attached to it. If you continue, the organization will be removed from those tickets, and they will not have an organization specified.",
        )
      default:
        throw new Error(`Invalid warning: ${warning}`)
    }
  },

  async destroyAndUpdateList() {
    let successfullyDeleted = false
    if (this.infoType === "device") {
      const deleteBackupDataPermanently = this.get("deleteBackupDataPermanently")
      try {
        await removeDevice({ nodeId: this.id, deleteBackupDataPermanently })
        if (deleteBackupDataPermanently) {
          showSuccessMessage(localized("Data deleted successfully"))
        }
        window.deviceList.delete(this.id)
        window.location.hash = "#/deviceSearch"
        successfullyDeleted = true
      } catch (error) {
        if (!error.isHandledMfaError) {
          reportErrorAndShowMessage(error)
        }
      }
    } else {
      await ModelBase.prototype.destroy.call(this)
      if (["policy", "vmpolicy", "androidpolicy", "iospolicy"].includes(this.infoType)) {
        await window.controller.get("activeTab").render(true)
      }
      if (this.infoType === "customer") {
        if (this.get("deleteBackupDataPermanently")) {
          try {
            await fetchJson(`/backup/lockhart/client/${this.id}/delete-revisions`, {
              options: {
                method: "DELETE",
              },
            })

            showSuccessMessage(localized("Data deleted successfully"))
          } catch (error) {
            reportErrorAndShowMessage(error)
          }
        }

        if (window.location.hash.startsWith("#/customerDashboard")) {
          window.location.hash = "#/systemDashboard/overview"
        } else if (window.location.hash.startsWith("#/administration/customers")) {
          window.store.dispatch(removeOrganizationFromList(this.id))
        } else {
          await window.controller.get("activeTab").render(true)
        }
        successfullyDeleted = true
      }
      if (this.infoType === "user") {
        await Promise.all([
          window.store.dispatch(deleteUser(this.id)),
          window.store.dispatch(
            deleteUserFromOpenTickets({
              id: this.id,
              uid: this.attributes.uid,
            }),
          ),
        ])
        window.userList.delete(this.id)
      }
    }
    if (["device", "customer"].includes(this.infoType) && successfullyDeleted) {
      const userTab = { id: this.id, type: this.infoType.toUpperCase() }
      window.store.dispatch(removeRecentTab(userTab))
      window.store.dispatch(removeFavoriteTab(userTab))
    }
  },

  getName() {
    return this.get("name")
  },

  matchesSearchText(searchText) {
    return (
      this.getName()
        .toLowerCase()
        .indexOf(searchText) !== -1
    )
  },
})

export default InfoModel
