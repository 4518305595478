import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { HoverDropdownItem } from "@ninjaone/components"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { initPreselectedTargets as _initPreselectedTargets } from "js/state/actions/scheduledTasks/targets"
import { getSelectedFilteredDevices } from "js/state/selectors/deviceSearch/devices"
import { setFromDeviceSearch as _setFromDeviceSearch } from "js/state/actions/deviceSearch/actionRunnerParams"

class CreateButton extends PureComponent {
  goToCreateTask = e => {
    e.preventDefault()
    const { selectedDevices, initPreselectedTargets, fromDeviceSearch, setFromDeviceSearch } = this.props

    if (fromDeviceSearch) {
      setFromDeviceSearch(true)
    }

    const targets = selectedDevices.map(device => {
      return {
        scheduledTaskId: 0,
        targetType: "NODE",
        targetId: device.id,
        name: device.name,
        clientName: device.clientName,
        nodeClass: device.nodeClass,
      }
    })
    initPreselectedTargets(targets)
    window.location.hash = "#/editor/scheduledTask"
  }

  render() {
    return <HoverDropdownItem onClick={this.goToCreateTask} token={this.props.labelToken} />
  }
}

CreateButton.defaultProps = {
  labelToken: localizationKey("Scheduled Task"),
}

export default connect(
  ({ deviceSearch }, ownProps) => ({
    selectedDevices: ownProps?.devices || getSelectedFilteredDevices(deviceSearch),
  }),
  {
    initPreselectedTargets: _initPreselectedTargets,
    setFromDeviceSearch: _setFromDeviceSearch,
  },
)(CreateButton)
