import { Text, ConfirmationModal } from "@ninjaone/components"
import { localizationKey } from "js/includes/common/utils"

export const ConfirmChanges = ({ onConfirm, onCancel, unmount, closeAction }) => {
  return (
    <ConfirmationModal
      unmount={unmount}
      type="danger"
      titleToken={localizationKey("Confirm changes")}
      actionToken={localizationKey("Confirm")}
      onConfirm={onConfirm}
      onCancel={onCancel}
      closeAction={closeAction}
    >
      <Text
        token={localizationKey(
          "Making any changes will require network discovery to be rerun. Are you sure you want to proceed?",
        )}
        size="sm"
        textWrap
      />
    </ConfirmationModal>
  )
}
