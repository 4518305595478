import { Skeleton } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { Box, Flex } from "js/includes/components/Styled"

const GlobalSearchSkeletons = ({ rowCount = 3 }) => {
  const rows = Array.from({ length: rowCount })

  return (
    <div data-testid="global-search-skeletons">
      {rows.map((row, index) => (
        <Box marginBottom={index < rows.length - 1 ? sizer(3) : 0} key={`skeleton-${index}`}>
          <Flex>
            <Box width="40px">
              <Skeleton display="block" width="100%" height="40px" />
            </Box>
            <Box marginLeft={sizer(2)} width="70%">
              <Skeleton display="block" width="40%" height="16px" marginBottom={sizer(2)} />
              <Skeleton display="block" width="100%" height="16px" />
            </Box>
          </Flex>
        </Box>
      ))}
    </div>
  )
}

export default GlobalSearchSkeletons
