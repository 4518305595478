import { fetchJson } from "js/includes/common/utils"

export function getTerminalWebSocketEndUserUrl(content, linktype = "WEB") {
  return fetchJson(`/end-user/connect/terminal?linktype=${linktype}`, {
    options: {
      method: "POST",
      body: JSON.stringify(content),
    },
  })
}

export function getTerminalWebSocketTechnicianUrl(content, linktype = "WEB") {
  return fetchJson(`/rtc/terminal?linktype=${linktype}`, {
    options: {
      method: "POST",
      body: JSON.stringify(content),
    },
  })
}

export function getTerminalWebSocketNms(content, linktype = "WEB") {
  return fetchJson(`/nms/initiateTerminalSession?linktype=${linktype}`, {
    options: {
      method: "POST",
      body: JSON.stringify(content),
    },
  })
}
