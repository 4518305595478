import { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization"
import { sizer } from "@ninjaone/utils"
import Modal from "../Modal"
import VerticalTabs from "./VerticalTabs"

const StyledContainer = styled.div`
  display: flex;
  height: ${({ minHeight }) => `${minHeight}px`};
  margin: ${sizer(-2, 0, -5)};
  background-color: ${({ theme }) => theme.colorBackground};
`

const VerticalTabsModal = ({
  cancelable,
  closeAction,
  bottomContentRenderer,
  id,
  minHeight = 400,
  onPrimaryButtonClick,
  getActiveTabIndex,
  primaryButtonLabel,
  primaryButtonDisabled = false,
  showPrimaryButton = true,
  tabs,
  tabsAriaLabel,
  titleGroup,
  unmount,
  withCloseX,
  buttonRenderer,
}) => {
  const [animateTabErrors, setAnimateTabErrors] = useState(false)

  const handleSubmit = () => {
    setAnimateTabErrors(true)
    onPrimaryButtonClick()
  }

  return (
    <Modal
      buttons={
        showPrimaryButton
          ? [
              {
                labelToken: primaryButtonLabel ?? localizationKey("Submit"),
                onClick: handleSubmit,
                variant: "primary",
                disabled: primaryButtonDisabled,
              },
            ]
          : []
      }
      size="lg"
      {...{
        cancelable,
        closeAction,
        id,
        titleGroup,
        unmount,
        withCloseX,
        buttonRenderer,
      }}
    >
      <StyledContainer {...{ minHeight }}>
        <VerticalTabs
          ariaLabel={tabsAriaLabel}
          {...{ bottomContentRenderer, tabs, animateTabErrors, setAnimateTabErrors, getActiveTabIndex }}
        />
      </StyledContainer>
    </Modal>
  )
}

VerticalTabsModal.propTypes = {
  /**
   * The renderer function to show content below the tabs list.
   */
  bottomContentRenderer: PropTypes.func,
  /**
   * Determines the label text for the secondary button in the button group.
   */
  cancelable: PropTypes.bool,
  /**
   * Event handler called when the modal is closed.
   */
  closeAction: PropTypes.func,
  /**
   * The id of the component.
   */
  id: PropTypes.string,
  /**
   * The minimum height of the vertical tabs content within the modal.
   */
  minHeight: PropTypes.number,
  /**
   * Event handler called when the primary button is clicked.
   */
  onPrimaryButtonClick: PropTypes.func,
  /**
   * Determines whether the primary button should be shown.
   */
  showPrimaryButton: PropTypes.bool,
  /**
   * Function to act as a callback when the active tab changes returning the active tab index
   */
  getActiveTabIndex: PropTypes.func,
  /**
   * The label text for the primary button. Will default to submit.
   */
  primaryButtonLabel: PropTypes.string,
  /**
   * Determines whether the primary button is disabled
   */
  primaryButtonDisabled: PropTypes.bool,
  /**
   *
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      hasError: PropTypes.bool,
      label: PropTypes.string.isRequired,
      onChange: PropTypes.func,
      renderer: PropTypes.func.isRequired,
      required: PropTypes.bool,
    }),
  ).isRequired,
  /**
   *
   */
  tabsAriaLabel: PropTypes.string.isRequired,
  /**
   * The title and the description for the modal.
   */
  titleGroup: PropTypes.shape({
    /**
     * The title for the modal.
     */
    titleText: PropTypes.string,
    TitleComponent: PropTypes.func,
    /**
     * The description for the modal.
     */
    descriptionText: PropTypes.string,
  }).isRequired,
  /**
   * Event handler run when the component unmounts.
   */
  unmount: PropTypes.func.isRequired,
  /**
   * Adds an 'X' close icon to the modal.
   */
  withCloseX: PropTypes.bool,
  buttonRenderer: PropTypes.func,
}

export default VerticalTabsModal
