import { memo } from "react"
import { sizer } from "@ninjaone/utils"
import { Text, Tooltip } from "@ninjaone/components"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import { Box, Flex, StyledSpan } from "js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"
import { StyledLabel } from "js/includes/components/DeviceModal/style"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const MobileDeviceTypePicker = memo(({ disabled, onChange, selected, options, required }) => {
  const tooltipMessage = localized(
    "Device usage defines whether the device will be used for personal and work or work only. Work only usage will require full device management and will allow for actions like full device wipe and rebooting. Full device wipe and reboot actions are not possible with personally owned devices.",
  )
  return (
    <Box marginTop={sizer(3)} data-testid="mobile-device-box">
      <StyledLabel htmlFor="mobile-device-type-selector">
        <Flex>
          <StyledSpan marginRight={1}>
            {localized("Usage type")}{" "}
            {required && (
              <>
                <span aria-hidden="true">&nbsp;*</span>
                <Text token={localizationKey("Required")} visuallyHidden />
              </>
            )}
          </StyledSpan>
          <Tooltip label={tooltipMessage}>
            <RegularInfoCircleIcon size="sm" />
          </Tooltip>
        </Flex>
      </StyledLabel>
      <SearchableDropDown
        {...{
          id: "mobile-device-type-selector",
          disabled,
          onSelect: onChange,
          options,
          value: selected,
          searchPlaceholderToken: localizationKey("Select usage type"),
          minHeight: "42px",
          width: "100%",
          rowHeight: 40,
          keepInView: false,
        }}
      />
    </Box>
  )
})

export default MobileDeviceTypePicker
