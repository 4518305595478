import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { Box } from "js/includes/components/Styled"

const InstallRunApplicationModalContent = styled(Box)`
  height: ${({ height }) => height || "700px"};
  padding: 0 ${tokens.spacing[1]} 0 ${tokens.spacing[1]};
  overflow-y: auto;
  > .automation-params-container {
    margin-top: ${tokens.spacing[2]};
    > .automation-params-label {
      margin-top: ${tokens.spacing[2]};
      margin-bottom: ${tokens.spacing[1]};
    }
    > .automation-params-input {
      margin-bottom: ${tokens.spacing[2]};
    }
  }
`

export default InstallRunApplicationModalContent
