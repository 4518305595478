import React from "react"
import { localizationKey, localized } from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import EnableMaintenanceModal from "./EnableMaintenanceModal"

const token = localizationKey("Configure")

const ConfigureMaintenanceButton = ({
  nodes,
  fetchDevicesInMaintenance,
  ButtonComponent,
  isInDeviceSearchSelectAllMode,
  fromDeviceSearch,
}) => {
  const onClick = () => {
    showModal(
      <EnableMaintenanceModal
        {...{ nodes, fetchDevicesInMaintenance, isInDeviceSearchSelectAllMode, fromDeviceSearch }}
      />,
      {
        withProvider: true,
      },
    )
  }
  return ButtonComponent ? (
    <ButtonComponent {...{ onClick, token }} />
  ) : (
    <button className="btn-link" onClick={onClick}>
      {localized(token)}
    </button>
  )
}

export default ConfigureMaintenanceButton
