import { upload, fetchJson } from "js/includes/common/utils"

export const patchSAML = (uid, payload) =>
  fetchJson(`/divisionconfig/saml/${uid}/sso`, {
    options: {
      method: "PATCH",
      body: JSON.stringify(payload),
    },
  })

export const toggleSAML = ({ idpUids = [], enable = false }) =>
  fetchJson("/divisionconfig/saml/update-status", {
    options: {
      method: "POST",
      body: JSON.stringify({ idpUids, enable }),
    },
  })

export const deleteSAML = uid =>
  fetchJson(`/divisionconfig/saml/${uid}`, {
    options: {
      method: "DELETE",
    },
  })

export const getSAMLConfig = () => fetchJson("/divisionconfig/saml")

export const getIdentityProvider = id => fetchJson(`/divisionconfig/saml/${id}`)

export const getDomainConfig = () => fetchJson("/divisionconfig/saml/email-domains")

export const testSAMLSkipMFA = ({ uid, skipLoginMFAConfig = null }) =>
  fetchJson(`/divisionconfig/saml/${uid}/skip-mfa-test`, {
    options: {
      method: "POST",
      body: JSON.stringify(skipLoginMFAConfig),
    },
  })

export const createSAMLConfig = payload =>
  fetchJson("/divisionconfig/saml", {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })

export const updateSAMLTestConfig = ({ url, xml, skipLoginMFAConfig = null }) =>
  fetchJson("/divisionconfig/saml/test", {
    options: {
      method: "POST",
      body: JSON.stringify(url ? { metadataUrl: url, skipLoginMFAConfig } : { metadata: xml, skipLoginMFAConfig }),
    },
  })

export const uploadSAMLTestFile = ({ file, skipLoginMFAConfig = null }) =>
  upload("/divisionconfig/saml/test", {
    body: {
      file,
      skipLoginMFAConfig,
    },
  })

export const updateMetadataFile = ({ uid, metadataToken }) =>
  fetchJson(`/divisionconfig/saml/${uid}/metadata`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        metadataToken,
      }),
    },
  })

/* SCIM */
export const toggleSCIM = (uid, enabled) =>
  fetchJson(`/divisionconfig/saml/${uid}/scim`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({ enabled }),
    },
  })

export const getSCIMUserscount = uid => fetchJson(`/integrations/${uid}/scim/data/users/count`)

export const generateToken = uid =>
  fetchJson(`/integrations/${uid}/scim/token`, {
    options: {
      method: "POST",
    },
  })

export const revokeToken = uid =>
  fetchJson(`/integrations/${uid}/scim/token`, {
    options: {
      method: "DELETE",
    },
  })
