import React from "react"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const SearchablePolicyDropdown = ({
  customDropdownOptions,
  value,
  openOnFocus = true,
  dataFilter,
  onSelect,
  searchPlaceholderToken,
  defaultOptions = [],
  searchParams,
  disabled,
}) => {
  return (
    <SearchableDropDown
      {...{
        width: "100%",
        rowHeight: 35,
        pageSize: 50,
        placeholderToken: localizationKey("Search"),
        searchableKey: "name",
        valueSelectorKey: "id",
        endpoint: "/query/policies",
        openOnFocus,
        onSelect,
        keepDropdownInView: true,
        additionalDefaultOptions: defaultOptions,
        disabled,
        searchParams: ({ query }) => ({
          ...(query && { name: query }),
          ...searchParams,
        }),
        value,
        ...(searchPlaceholderToken && { searchPlaceholderToken }),
        ...(dataFilter && { dataFilter }),
        ...customDropdownOptions,
        activeScrollEventListener: true,
      }}
    />
  )
}

export default SearchablePolicyDropdown
