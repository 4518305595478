/** @jsxImportSource @emotion/react */
import { PureComponent } from "react"
import { assoc } from "ramda"
import PropTypes from "prop-types"

import styled from "@emotion/styled"
import { getDivisionTimeZone, localized, serverScheduleDateFormat } from "js/includes/common/utils"
import { defaultRepeatsOptions, defaultEndOptions, getDefaultScheduleWithDateFormat } from "./scheduleDataDefaults"
import ScheduleRepeats from "./ScheduleRepeats"
import ScheduleEnd from "./ScheduleEnd"
import ScheduleStart from "./ScheduleStart"

const ScheduleStyled = styled.div`
  display: flex;
  flex-flow: column;
  overflow: visible;
  height: ${({ fixedHeight }) => (fixedHeight ? "500px" : "")};
`

class Schedule extends PureComponent {
  constructor(props) {
    super(props)
    const { schedule, dateFormat } = this.props
    this.state = schedule ?? getDefaultScheduleWithDateFormat(dateFormat)
  }

  updateSchedule = schedule => {
    this.setState(schedule, () => {
      // Lift state to parent
      if (this.props.onScheduleUpdate) {
        const _schedule = schedule.repeats === "EVERY" ? assoc("scheduleInterval", "HOURLY")(schedule) : schedule
        this.props.onScheduleUpdate(_schedule)
      }
    })
  }

  render() {
    const {
      repeatsOptions = defaultRepeatsOptions,
      repeatsDisabled = false,
      visibleRecurEvery = true,
      endOptions = defaultEndOptions,
      visibleEnd = true,
      visibleTimeZone = true,
      startAtInfo,
      dateFormat = serverScheduleDateFormat,
      isValidStartDate,
      isValidEndDate,
    } = this.props

    const { repeats } = this.state

    return (
      <ScheduleStyled fixedHeight={this.props.fixedHeight}>
        <h3>{localized("Schedule")}</h3>
        <ScheduleRepeats
          {...{
            schedule: this.state,
            updateSchedule: this.updateSchedule,
            repeatsOptions,
            repeatsDisabled,
            visibleRecurEvery,
          }}
        />
        {repeats && repeats !== "NEVER" && (
          <>
            <ScheduleStart
              {...{
                schedule: this.state,
                updateSchedule: this.updateSchedule,
                timeZone: getDivisionTimeZone(),
                visibleTimeZone,
                startAtInfo,
                dateFormat,
                isValidStartDate,
              }}
            />
            {visibleEnd && (
              <ScheduleEnd
                {...{
                  schedule: this.state,
                  updateSchedule: this.updateSchedule,
                  endOptions,
                  dateFormat,
                  isValidEndDate,
                }}
              />
            )}
          </>
        )}
      </ScheduleStyled>
    )
  }
}

Schedule.propTypes = {
  schedule: PropTypes.object,
  fixedHeight: PropTypes.bool,
  onScheduleUpdate: PropTypes.func,
  repeatsOptions: PropTypes.array,
  visibleRecurEvery: PropTypes.bool,
  endOptions: PropTypes.array,
  visibleEnd: PropTypes.bool,
  visibleTimeZone: PropTypes.bool,
  dateFormat: PropTypes.string,
}

Schedule.defaultProps = {
  fixedHeight: true,
}

export default Schedule
