import { useMemo } from "react"
import { HoverDropdown, HoverDropdownItem } from "@ninjaone/components"
import { runAdhocJob } from "./utils"
import showModal from "js/includes/common/services/showModal"
import RunPolicyScheduledScriptsButton from "js/includes/components/Device/Toolbar/RunPolicyScheduledScriptsButton"
import ScriptsFromPolicyModal from "js/includes/components/Device/Toolbar/ScriptsFromPolicyModal"
import AutomationsSelectorModal from "js/includes/editors/Policy/Models/General/AutomationsSelectorModal"
import { localizationKey } from "js/includes/common/utils"
import { getDeviceType } from "js/state/helpers/devices"

const DeviceRunScriptHoverDropdown = ({
  devices,
  commonPolicy,
  loadingCommonPolicy,
  renderFromLibrary,
  renderPolicyScheduled,
  fromDeviceSearch = false,
  showAutomationScript,
  showAutomationNative,
  showInstallApplication,
  showRunApplication,
  showPolicyScheduled,
}) => {
  const displayAutomationModalOnClick = defaultAutomationTypes => {
    showModal(
      <AutomationsSelectorModal
        {...{
          nodeRole: devices[0].nodeClass,
          runAdhocJob: (job, automationType) => runAdhocJob(job, devices, fromDeviceSearch, automationType),
          devices,
          defaultAutomationTypes,
          fromDeviceSearch,
        }}
      />,
      {
        withProvider: true,
      },
    )
  }

  const deviceType = devices ? getDeviceType(devices[0].nodeClass) : null

  const menus = useMemo(
    () => ({
      script: fromDeviceSearch ? showAutomationScript : renderFromLibrary,
      native: fromDeviceSearch ? showAutomationNative : renderFromLibrary,
      installApplication: fromDeviceSearch
        ? showInstallApplication
        : renderFromLibrary && ["Windows", "Mac"].includes(deviceType),
      runApplication: fromDeviceSearch ? showRunApplication : renderFromLibrary && ["Windows"].includes(deviceType),
      policy: fromDeviceSearch ? showPolicyScheduled : renderPolicyScheduled,
    }),
    [
      deviceType,
      fromDeviceSearch,
      renderFromLibrary,
      renderPolicyScheduled,
      showAutomationNative,
      showAutomationScript,
      showInstallApplication,
      showPolicyScheduled,
      showRunApplication,
    ],
  )

  return (
    <>
      <HoverDropdown
        buttonRenderer={({ isOpen }) => (
          <HoverDropdownItem token={localizationKey("Run Automation")} isOpen={isOpen} showArrow isDropdownButton />
        )}
        dropdownRenderer={() => (
          <>
            {menus.script && (
              <HoverDropdownItem
                token={localizationKey("Script")}
                onClick={() => displayAutomationModalOnClick(["ScriptAutomation"])}
              />
            )}
            {menus.native && (
              <HoverDropdownItem
                token={localizationKey("Native")}
                onClick={() => displayAutomationModalOnClick(["NativeAutomation"])}
              />
            )}
            {menus.installApplication && (
              <HoverDropdownItem
                token={localizationKey("Install Application")}
                onClick={() => displayAutomationModalOnClick(["BinaryInstallAutomation"])}
              />
            )}
            {menus.runApplication && (
              <HoverDropdownItem
                token={localizationKey("Run Application")}
                onClick={() => displayAutomationModalOnClick(["BinaryRunAutomation"])}
              />
            )}
            {menus.policy && (
              <RunPolicyScheduledScriptsButton
                {...{
                  commonPolicy,
                  runScheduledScriptJob: job =>
                    runAdhocJob(job, devices, fromDeviceSearch, "RUN_AUTOMATION_FROM_POLICY"),
                  ButtonComponent: ({ handleOnClick, sortedScripts }) => (
                    <HoverDropdownItem
                      token={localizationKey("From Policy")}
                      onClick={() => {
                        showModal(
                          <ScriptsFromPolicyModal
                            {...{
                              scripts: sortedScripts.map(sortedScript => sortedScript[1]),
                              handleOnClick,
                            }}
                          />,
                        )
                      }}
                    />
                  ),
                }}
              />
            )}
          </>
        )}
        loading={loadingCommonPolicy}
        placement="right"
        fullWidth
      />
    </>
  )
}
export default DeviceRunScriptHoverDropdown
