import $ from "jquery"
import _ from "underscore"

const icheckradiobuttons = {
  bind: function(el) {
    const $el = $(el)
    $el.data("rivets.binders.icheckradiobuttons.bound", false)
    $el.data("rivets.binders.icheckradiobuttons.initialized", false)
    return true
  },

  routine: function(el, value) {
    const $el = $(el)
    _.defer(() => {
      if (value && !$el.data("rivets.binders.icheckradiobuttons.bound")) {
        var radioButtons = $el.find(".i-checks")

        this.callback = function(e) {
          e.target.focus()
          var position = radioButtons.index(this)
          var currentValue = value.get("items").models[position].get("value")
          value.set("value", currentValue)
        }

        this.changeValueByModel = function() {
          var items = value.get("items").models
          // eslint-disable-next-line
          var disabled = $el.attr("data-disabled") == "true"

          for (let i = 0; i < items.length; i++) {
            var square_blue = $el.find(".i-checks .iradio_square-blue")
            if ((square_blue.length > 0) & (square_blue.length > i)) {
              square_blue[i].className = disabled ? "iradio_square-blue disabled" : "iradio_square-blue"
              // eslint-disable-next-line
              if (value.get("value") == items[i].get("value")) {
                square_blue[i].className = disabled
                  ? "iradio_square-blue checked disabled"
                  : "iradio_square-blue checked"
              }
            }
          }

          if (disabled) {
            radioButtons.iCheck("disable")
          }
        }

        var initialized = $el.data("rivets.binders.icheckradiobuttons.initialized")
        if (!initialized) {
          radioButtons.iCheck({
            checkboxClass: "icheckbox_square-blue",
            radioClass: "iradio_square-blue",
          })
          if ($el.data("radio-inline")) {
            $el.find(".i-checks.radio").addClass("radio-inline")
          }
          if ($el.data("color-blue")) {
            $el.find(".i-checks").addClass("i-checks-blue")
          }
          $el.data("rivets.binders.icheckbox.value", value)
          radioButtons.on("ifChecked", this.callback)
          this.changeValueByModel()
          $el.data("rivets.binders.icheckradiobuttons.initialized", true)
        }

        value.listenTo(value, "change", this.changeValueByModel)
        $el.data("rivets.binders.icheckradiobuttons.bound", true)
      }
    })
  },

  unbind: function(el) {
    const $el = $(el)
    var value = $el.data("rivets.binders.icheckbox.value")
    if (value) value.stopListening()
    var radioButtons = $el.find(".i-checks")
    radioButtons.off("ifChecked", this.callback)
    radioButtons.iCheck("destroy")
    return true
  },
}

export default icheckradiobuttons
