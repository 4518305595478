import PropTypes from "prop-types"

import { Input, StyledInputLabel } from "@ninjaone/components"

import { Flex } from "js/includes/components/Styled"

const EmailDomainInput = ({
  labelText,
  mandatory,
  onChange,
  value,
  suffix,
  id,
  name,
  placeholder,
  errorMessage,
  disabled,
}) => {
  return (
    <>
      <StyledInputLabel htmlFor={id}>
        {labelText}
        {mandatory ? " *" : ""}
      </StyledInputLabel>
      <Flex>
        <Flex flexDirection="column" flexGrow="1">
          <Input
            id={id}
            name={name}
            ariaLabel={labelText}
            placeholder={placeholder}
            value={value}
            errorMessage={errorMessage}
            onChange={onChange}
            maxLength={50}
            minWidth="100px"
            disabled={disabled}
          />
        </Flex>
        <Flex className="p-l-xs" alignItems="center" wordBreak="break-all">
          {suffix}
        </Flex>
      </Flex>
    </>
  )
}

EmailDomainInput.propTypes = {
  labelText: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  suffix: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
}

export default EmailDomainInput
