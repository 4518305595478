import React, { memo } from "react"
import { connect } from "react-redux"
import { openWakeDeviceModal as _openWakeDeviceModal } from "js/state/actions/deviceSearch/wakeDevice"
import { sendWakeOnLAN } from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import WakeDeviceModal from "js/includes/components/Device/Toolbar/WakeDeviceModal"
import { localizationKey } from "js/includes/common/utils"
import PropTypes from "prop-types"
import { HoverDropdownItem } from "@ninjaone/components"

const DeviceMenuWakeOnLanButton = memo(({ id, openWakeDeviceModal, filteredSelectedDevices }) => {
  const onShowWakeDeviceModal = async () => {
    const { success, fail } = await sendWakeOnLAN(id)
    const deviceResults = { success: success ? [success] : [], fail: fail ? [fail] : [] }
    showModal(<WakeDeviceModal {...{ filteredSelectedDevices }} />, {
      withProvider: true,
    })
    openWakeDeviceModal(deviceResults)
  }

  return <HoverDropdownItem {...{ onClick: onShowWakeDeviceModal, token: localizationKey("Wake-on-LAN") }} />
})

export default connect(undefined, {
  openWakeDeviceModal: _openWakeDeviceModal,
})(DeviceMenuWakeOnLanButton)

DeviceMenuWakeOnLanButton.propTypes = {
  id: PropTypes.number.isRequired,
  openWakeDeviceModal: PropTypes.func.isRequired,
}
