import $ from "jquery"
import rivets from "rivets"

const intltelinput = {
  routine: async function(el, value) {
    var isDisabled = $(el).data("rivets.binders.intltelinput.isDisabled")
    if (!isDisabled) {
      $(el).data("rivets.binders.intltelinput.isDisabled", true)

      var initialized = $(el).data("rivets.binders.intltelinput.initialized")
      if (!initialized) {
        $.when(
          $(el).intlTelInput({
            utilsScript: await import("intl-tel-input/build/js/utils"),
          }),
        ).done(() => {
          $(el).data("rivets.binders.intltelinput.initialized", true)

          // initial phone check
          this.callback()
        })
      }

      if (value) {
        $(el).intlTelInput("setNumber", value)
      }

      $(el).data("rivets.binders.intltelinput.isDisabled", false)
    }
  },

  bind: function(el) {
    var adapter = rivets.adapters[":"]
    var self = this
    var paths = self.keypath.split(":")
    var phoneVar = paths[paths.length - 1]
    var isValid, countryCode, phone

    this.callback = function(e) {
      var isDisabled = $(el).data("rivets.binders.intltelinput.isDisabled")
      if (!isDisabled) {
        $(el).data("rivets.binders.intltelinput.isDisabled", true)
        phone = $(el).intlTelInput("getNumber")
        adapter.set(self.model, phoneVar, phone)
        isValid = $(el).intlTelInput("isValidNumber")
        countryCode = $(el).intlTelInput("getSelectedCountryData").iso2
        adapter.set(self.model, "countryCode", countryCode)
        if (!isValid && phone) {
          self.model.get("errors")[phoneVar] = "Please enter a valid number"
          self.model.trigger("change:errors")
          adapter.set(self.model, "phoneValid", false)
        } else {
          adapter.set(self.model, "phoneValid", true)
        }
        $(el).data("rivets.binders.intltelinput.isDisabled", false)
      }
    }

    this.keyUpCallback = function(e) {
      if ($(el).data("rv-check-on-enter-press") & (e.keyCode === 13)) {
        self.callback()
      }
    }

    $(el).data("rivets.binders.intltelinput.initialized", false)
    $(el).on("change", this.callback)
    $(el).on("countrychange", this.callback)
    $(el).on("keyup", this.keyUpCallback)
  },

  unbind: function(el) {
    $(el).intlTelInput("destroy")
    $(el).off()
    return true
  },
}

export default intltelinput
