import { Modal, Input } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { useForm } from "js/includes/common/hooks"
import { localizationKey, isWholeNumber } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import { advancedSettingsValidations } from "js/includes/networkDiscovery/utils"

const inputs = [
  {
    name: "pingTimeoutMS",
    labelToken: "Ping timeout (ms)",
    max: 1000,
  },
  {
    name: "pingPacketTTL",
    labelToken: "Ping packet TTL",
    max: 255,
  },
  {
    name: "pingsPerNode",
    labelToken: "Pings per node",
    max: 5,
  },
  {
    name: "delayBetweenPingsMS",
    labelToken: "Delay between pings (ms)",
    max: 1000,
  },
]

export const AdvancedSettingsModal = ({
  changeParentValues,
  values: { pingTimeoutMS, pingPacketTTL, pingsPerNode, delayBetweenPingsMS },
  unmount,
}) => {
  const { values, onChange, validateForm, validation } = useForm({
    fields: {
      pingTimeoutMS,
      pingPacketTTL,
      pingsPerNode,
      delayBetweenPingsMS,
    },
    validate: advancedSettingsValidations,
  })

  return (
    <Modal
      id="network-discovery-advanced-settings"
      unmount={unmount}
      titleGroup={{
        titleToken: localizationKey("Advanced settings"),
      }}
      buttons={[
        {
          type: "apply",
          onClick: () => {
            if (validateForm()) {
              changeParentValues({
                ...values,
              })
              unmount()
            }
          },
        },
      ]}
      cancelable
    >
      <Flex flexDirection="column" gap={sizer(2)}>
        {inputs.map(({ labelToken, name }) => (
          <Input
            key={labelToken}
            labelToken={labelToken}
            value={values[name]}
            onChange={e => {
              if (!isWholeNumber(e.target.value)) return
              onChange(name, e.target.value)
            }}
            errorMessage={validation.message?.[name]}
          />
        ))}
      </Flex>
    </Modal>
  )
}
